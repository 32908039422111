import PropTypes from 'prop-types'
import React from 'react'
import sockette from 'sockette'

import storageFactory from '@Common/Services/Storage'
import Logger from '@Common/Utils/Logger'
import config from '@Config'

const Storage = storageFactory()

class WebSocket extends React.Component {
  constructor(props) {
    super(props)
    this.ws = null
    this.heartBeatInterval = 5000
    this.onOpen = this.onOpen.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.onReconnect = this.onReconnect.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onError = this.onError.bind(this)
  }

  shouldComponentUpdate() {
    return false
  }

  componentDidMount() {
    const socketteConfiguration = {
      maxAttempts: 3,
      onopen: this.onOpen,
      onmessage: this.onMessage,
      onreconnect: this.onReconnect,
      onclose: this.onClose,
      onerror: this.onError,
    }
    this.ws = sockette(
      config.wsBasePath + this.props.path + '?token=' + Storage.get('accessToken'),
      socketteConfiguration,
    )
  }

  componentWillUnmount() {
    if (this.ws.readyState !== 1) {
      this.ws.closedBeforeConnection = true
    }
    this.ws.close()
  }

  onOpen(ev) {
    Logger.info('Socket connected!', this.props.name, ev)
    if (this.props.onOpen) this.props.onOpen()
  }

  onError(ev) {
    if (this.ws.closedBeforeConnection) {
      Logger.info('Socket closed before connection, ignoring connection error', this.props.name, ev)
    } else {
      // @TODO redirect to erro page
      Logger.error('Socket error, redirecting to error page', this.props.name, ev)
      this.props.onError(ev)
    }
  }

  onMessage(ev) {
    Logger.debug('Socket received json', this.props.name, JSON.parse(ev.data))
    this.props.onMessage(JSON.parse(ev.data)) // text socket
  }

  onReconnect(ev) {
    Logger.info('Socket reconnecting', this.props.name, ev)
  }

  onClose(ev) {
    Logger.info('Socket closing', this.props.name, ev)
  }

  sendJson(payload) {
    Logger.debug('Socket sending message', this.props.name, payload)
    this.ws.json(payload)
  }

  render() {
    return null
  }
}

WebSocket.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
}

export default WebSocket
