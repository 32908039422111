import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ROLES } from '@Auth/Costants'
import { useEmployeesByRolesQuery } from '@Auth/Services/Api'
import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'
import { withLoader } from '@Common/Utils/HOC'
import { apiList } from '@Core/Services/Api'
import { setStateFromEvent } from '@Common/Utils/Events'

/**
 * Usage:
 *
 *  <OwnerAutocomplete
 *    label={t('buildings:fields.building.__field')}
 *    onChange={setValue} />
 */
const OwnerSelect = ({ value, setValue, ...props }) => {
  const { t } = useTranslation()
  const { data, isLoading } = apiList(useEmployeesByRolesQuery([ROLES.HQ_ADMINISTRATIVE]))
  const options = useMemo(
    () => [
      { label: t('common:ui.All'), value: -1 },
      { label: t('buildings:ui.NoOwner'), value: 0 },
      ...data.map(({ fullName, id: value }) => ({ label: fullName, value })),
    ],
    [data],
  )

  return withLoader(
    <Dropdown required hideAsterisk options={options} value={value} onChange={setStateFromEvent(setValue)} {...props} />,
    isLoading,
    'inline',
    { minHeight: '0', size: 20 },
  )
}

OwnerSelect.propTypes = {
  ...FieldType,
}

export default OwnerSelect
