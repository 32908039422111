import PropTypes from 'prop-types'
import React from 'react'

import Logo from '@Assets/Logo'
import { Container, FormCard, Wrapper } from '@Auth/Components/Styled'
import { Margin } from '@Common/Components/Styles'

export const GenericCardView = ({ children }) => (
  <Wrapper>
    <Container>
      <FormCard>
        <Margin bottom="1rem" left="auto" right="auto">
          <Logo />
        </Margin>
        {children}
      </FormCard>
    </Container>
  </Wrapper>
)

GenericCardView.propTypes = {
  children: PropTypes.node,
}
