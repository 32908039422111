import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom'

import { getLoginRedirectUrl } from '@Auth/Utils'
import { store } from '@Core/Redux/Store'


/**
 * A decorator for guest routes which redirects to home
 * if user is authenticated
 * @param element: the component to render
 */
const GuestRoute = ({ children }) => {
  const state = store.getState()
  if (state.auth?.login?.user) {
    return <Navigate to={{ pathname: getLoginRedirectUrl(state.auth.login.user) }} />
  }

  return children
}

GuestRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default GuestRoute
