import { api, apiQueryString } from '@Core/Services/Api';


const authPrefix = 'auth'
const accountPrefix = 'accounts'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    whoami: builder.mutation({
      query: () => ({
        url: `${authPrefix}/me`,
        providesTags: ['AuthenticatedUser'],
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: `${authPrefix}/token`,
        method: 'POST',
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: `${accountPrefix}/register`,
        method: 'POST',
        body,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (body) => ({
        url: `${accountPrefix}/verify-email`,
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `${authPrefix}/password-reset`,
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ password, token }) => ({
        url: `${authPrefix}/password-reset/confirm`,
        method: 'POST',
        body: { password, token },
      }),
    }),
    validateResetPasswordToken: builder.mutation({
      query: (token) => ({
        url: `${authPrefix}/password-reset/validate-token`,
        method: 'POST',
        body: { token },
      }),
    }),
    users: builder.query({
      query: (qs) => `${authPrefix}/users?${apiQueryString(qs)}`,
      providesTags: ['User'],
    }),
    usersByRoles: builder.query({
      query: (roleIds) => `${authPrefix}/users?groups__id__in=${roleIds.join(',')}`,
      providesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${authPrefix}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User', 'Employee', 'Resident', 'Role', 'Anagraphic'],
    }),
    createUser: builder.mutation({
      query: (user) => ({
        url: `${authPrefix}/users`,
        body: user,
        method: 'POST',
      }),
      invalidatesTags: ['User', 'Role'],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...user }) => ({
        url: `${authPrefix}/users/${id}`,
        body: user,
        method: 'PATCH',
      }),
      invalidatesTags: ['User', 'Role'],
    }),
    changeUserPassword: builder.mutation({
      query: ({ userId, ...payload }) => ({
        url: `${authPrefix}/users/${userId}/change_password`,
        body: payload,
        method: 'PATCH',
      }),
    }),
    roles: builder.query({
      query: () => `${authPrefix}/roles`,
      providesTags: ['Role'],
    }),
    anagraphics: builder.query({
      query: (qs) => `anagraphics?${apiQueryString(qs)}`,
      providesTags: ['Anagraphic'],
    }),
    invitations: builder.query({
      query: (qs) => `${accountPrefix}/invitations?${apiQueryString(qs)}`,
      providesTags: ['Invitation'],
    }),
    invitationByToken: builder.query({
      query: (invitationToken) => `${accountPrefix}/invitations/token/${invitationToken}`,
    }),
    createInvitation: builder.mutation({
      query: (arcadiaId) => ({
        url: `${accountPrefix}/invitations`,
        body: { arcadiaId },
        method: 'POST',
      }),
      invalidatesTags: ['Invitation', 'Anagraphic'],
    }),
    createInvitationBulk: builder.mutation({
      query: (arcadiaIds) => ({
        url: `${accountPrefix}/invitations/bulk`,
        body: arcadiaIds,
        method: 'POST',
      }),
      invalidatesTags: ['Invitation', 'Anagraphic'],
    }),
    retryInvitationBulk: builder.mutation({
      query: (ids) => ({
        url: `${accountPrefix}/invitations/retry`,
        body: ids,
        method: 'POST',
      }),
      invalidatesTags: ['Invitation', 'Anagraphic'],
    }),
    createBuildingInvitation: builder.mutation({
      query: (arcadiaId) => ({
        url: `${accountPrefix}/invitations/invite_building`,
        body: { arcadiaId },
        method: 'POST',
      }),
      invalidatesTags: ['Invitation', 'BranchBuilding', 'Anagraphic'],
    }),
    employees: builder.query({
      query: (qs) => `${accountPrefix}/employees?${apiQueryString(qs)}`,
      providesTags: ['Employee'],
    }),
    employeesByRoles: builder.query({
      query: (roleIds) => `${accountPrefix}/employees?user__is_active=true&user__groups__id__in=${roleIds.join(',')}`,
      providesTags: ['Employee'],
    }),
    createEmployee: builder.mutation({
      query: (employee) => ({
        url: `${accountPrefix}/employees`,
        body: employee,
        method: 'POST',
      }),
      invalidatesTags: ['Employee', 'Role'],
    }),
    updateEmployee: builder.mutation({
      query: ({ id, ...employee }) => ({
        url: `${accountPrefix}/employees/${id}`,
        body: employee,
        method: 'PATCH',
      }),
      invalidatesTags: ['Employee', 'Role'],
    }),
    deleteEmployee: builder.mutation({
      query: (employeeId) => ({
        url: `${accountPrefix}/employees/${employeeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employee', 'Role'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useWhoamiMutation,
  useLoginMutation,
  useRegisterMutation,
  useVerifyEmailMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useValidateResetPasswordTokenMutation,
  useRolesQuery,
  useUsersQuery,
  useUsersByRolesQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useChangeUserPasswordMutation,
  useAnagraphicsQuery,
  useInvitationsQuery,
  useInvitationByTokenQuery,
  useCreateInvitationMutation,
  useCreateInvitationBulkMutation,
  useRetryInvitationBulkMutation,
  useCreateBuildingInvitationMutation,
  useEmployeesQuery,
  useEmployeesByRolesQuery,
  useLazyEmployeesByRolesQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
} = extendedApi
