import { Navigate, Route, Routes } from 'react-router-dom'

import BuildingPodPdrMainView from '@Condo/Views/BuildingPodPdrMainView'
import BuildingPodPdrView from '@Condo/Views/BuildingPodPdrView'
import config from '@Config'

export const PodPdrRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<BuildingPodPdrView />} />
        <Route path=":id/main" element={<BuildingPodPdrMainView />} />
        <Route path="*" element={<Navigate to={config.urls.pageNotFound} replace />} />
      </Route>
    </Routes>
  )
}
