/* eslint-disable react/prop-types */
import { Card, CardContent, Typography } from '@mui/material'

export const ItemCard = (props) => {
  const { title, children } = props
  return (
    <Card variant="outlined" sx={{ height: 1 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  )
}

export const ItemCardTable = (props) => {
  const { children, ...other } = props
  return (
    <ItemCard {...other}>
      <table style={{ width: '100%' }}>{children}</table>
    </ItemCard>
  )
}
