import React from 'react'
import { Box } from '@Common/Components/Styles'
import { Divider, Drawer as MuiDrawer, List, ListItem, useMediaQuery } from '@mui/material'
import { withLoader } from '@Common/Utils/HOC'
import { apiList } from '@Core/Services/Api'
import { closeNotificationsPanel, selectNotificationsPanel } from '@MyEstia/Redux'
import { useNotificationsQuery } from '@Notifications/Services/Api'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Notification from './Notification'

const Drawer = styled(MuiDrawer)`
  .MuiBackdrop-root {
    background-color: transparent;
  }
`

const Container = styled(Box)`
  width: ${(props) => (props.isSmallDevice ? '380px' : '480px')};
  max-width: 100%;
  height: 100%;
`

const NotificationsPanel = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notificationsPanel = useSelector(selectNotificationsPanel)
  const handleClose = () => dispatch(closeNotificationsPanel())
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { data: notifications, isLoading } = apiList(useNotificationsQuery())

  return (
    <Drawer anchor="right" open={notificationsPanel.isOpen} onClose={handleClose}>
      <Container pad="6rem 0 0" background="lightOne" isSmallDevice={isSmallDevice}>
        <Box pad="1rem" width="100%" style={{ boxSizing: 'border-box' }}>
          <h2>{t('common:ui.Notifications')}</h2>
          {withLoader(
            <List sx={{ width: '100%', maxWidth: isSmallDevice ? 360 : 480, bgcolor: 'background.paper' }}>
              {notifications.map((notification, idx) => (
                <React.Fragment key={notification.id}>
                  <Notification notification={notification} />
                  {idx !== notifications.length - 1 && <Divider variant="inset" component="li" />}
                </React.Fragment>
              ))}
              {!notifications.length && <ListItem alignItems="flex-start">{t('common:ui.NoNotifications')}</ListItem>}
            </List>,
            isLoading,
          )}
        </Box>
      </Container>
    </Drawer>
  )
}

export default NotificationsPanel
