import { hasPermission } from '@Common/Utils/Permissions'

export const LabelPermissions = {
  read: hasPermission('services.view_label'),
  create: hasPermission('services.add_label'),
  update: hasPermission('services.change_label'),
  delete: hasPermission('services.delete_label'),
}

export const CategoryPermissions = {
  read: hasPermission('services.view_category'),
  create: hasPermission('services.add_category'),
  update: hasPermission('services.change_category'),
  delete: hasPermission('services.delete_category'),
}

export const ProviderPermissions = {
  read: hasPermission('services.view_provider'),
  create: hasPermission('services.add_provider'),
  update: hasPermission('services.change_provider'),
  delete: hasPermission('services.delete_provider'),
}

export const ServicePermissions = {
  read: hasPermission('services.view_service'),
  create: hasPermission('services.add_service'),
  update: hasPermission('services.change_service'),
  delete: hasPermission('services.delete_service'),
}

export const ExternalPermissions = {
  read: hasPermission('services.view_externalsale'),
  create: hasPermission('services.add_externalsale'),
  update: hasPermission('services.change_externalsale'),
  delete: hasPermission('services.delete_externalsale'),
}
