import { Button, Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { ifElse } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { FixedColumnLeft, FixedColumnRight } from '@Common/Components/FixedColumn'
import FixedFooter from '@Common/Components/FixedFooter'
import { Box } from '@Common/Components/Styles'
import { responseStringError } from '@Common/Utils/Api'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { selectAdminSidebar } from '@Core/Redux/Ui'
import { getAnagraphicInfo, getDisplayName } from '@Residents/Utils'
import { ReportChat } from '@Tickets/Components/ReportChat'
import { useReportQuery, useUpdateReportMutation } from '@Tickets/Services/Api'
import ReportData from '@Tickets/Views/ReportDetailView/ReportData'
import ReportTimeline from '@Tickets/Views/ReportDetailView/ReportTimeline'
import CreateTask from '@Tms/Components/CreateTask'

const Main = styled.main`
  height: 100%;
  margin: -1rem;
`

const reportDetailTop =
  60 /* header height */ +
  52 /* breadcrumbs height */ +
  27 /* Title height */ +
  16 /* container padding */ +
  16 /* bottom spacing */ +
  'px'

const ReportDetailView = ({ isResident, footerHeight }) => {
  const { t } = useTranslation()
  const { reportId } = useParams()
  const user = useCurrentUser()
  const { data: report, isFetching } = useReportQuery(reportId)
  const [updateReport] = useUpdateReportMutation()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { expanded, expandedWidth, collapsedWidth } = useSelector(selectAdminSidebar)
  const [isLoading, setIsLoading] = useState(false)
  const residentName = getDisplayName(report?.anagraphic)

  const taskInitValues = {
    title: report?.title,
    notes: report?.description,
    taskCategory: report?.reportTypeObj.taskCategory,
    branch: report?.building.gruppoEsterno,
    building: `${report?.building?.extra?.id}_branchbuilding`,
    resident: { label: getAnagraphicInfo(report?.anagraphic), value: report?.anagraphic.id },
    assignedTo: user.employeeId,
    report: report?.id,
  }

  useBreadcrumbs([
    { label: t('common:navigation.Tickets'), path: makePath('admin.tickets.reports'), Icon: 'Tickets' },
    { label: t(`tickets:ui.ReportDetail`) },
  ])

  const getSidebarWidth = ifElse(
    () => isSmallDevice,
    () => 0,
    () => (expanded ? expandedWidth : collapsedWidth),
  )

  const handleBackToList = () => history.push(makePath('admin.tickets.reports'))

  const handleCloseTicket = async () => {
    setIsLoading(true)
    const apiResponse = await updateReport({ id: report.id, status: 2 })
    if (!apiResponse.error) {
      toast.success(t('tickets:success:submitSuccess'))
    } else {
      Logger.debug('Close ticket error', apiResponse)
      toast.error(t('tickets:errors:submitError', { error: responseStringError(apiResponse.error) }))
    }
    setIsLoading(false)
  }

  return (
    <Main>
      {withLoader(
        <>
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Typography textAlign="left" fontSize={18} fontWeight="500">
              {`${report?.title} (${report?.code})`}
            </Typography>
            <Typography
              fontSize={18}
              fontWeight="500"
              color={report?.status === 0 ? 'primary.main' : report?.status === 1 ? 'warning.main' : 'success.dark'}
            >
              {t(`tickets:fields.status.${report?.statusLabel}`)}
            </Typography>
          </Stack>
          <Divider />
          <FixedColumnLeft top={reportDetailTop} footerHeight={footerHeight} sidebarWidth={getSidebarWidth()}>
            <Typography mt={1} mb=".5rem">
              {!isResident && (
                <>
                  {t('tickets:detail.CreatedBy')}
                  {`${residentName} - `}
                </>
              )}
              {`${t('tickets:detail.Building')} ${report?.building?.denominazione}`}
            </Typography>
            <Divider />
            <ReportTimeline logs={report?.logs} />
            <Divider />
            <ReportData report={report} isResident={isResident} />
          </FixedColumnLeft>
          <FixedColumnRight top={reportDetailTop} footerHeight={footerHeight} sidebarWidth={getSidebarWidth()}>
            <ReportChat report={report} isResident={isResident} />
          </FixedColumnRight>
          {!isResident && (
            <FixedFooter sidebarWidth={getSidebarWidth()}>
              <Box>
                <div>
                  <Button onClick={handleBackToList} color="darkThree">
                    {t('tickets:detail.BackToList')}
                  </Button>
                  {!report?.taskFlow && report?.status !== 2 && <CreateTask defaultValues={taskInitValues} />}
                  <Button disabled={report?.status === 2} onClick={handleCloseTicket} color="primary">
                    {t('tickets:detail.Close')}
                  </Button>
                </div>
              </Box>
            </FixedFooter>
          )}
        </>,
        isFetching || isLoading,
      )}
    </Main>
  )
}

ReportDetailView.defaultProps = {
  isResident: false,
  footerHeight: '60px',
}

ReportDetailView.propTypes = {
  isResident: PropTypes.bool,
  footerHeight: PropTypes.string,
}

export default ReportDetailView
