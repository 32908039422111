import PropTypes from "prop-types";

const InstalmentsIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 181.5 181.5"
    style={{
      enableBackground: "new 0 0 181.5 181.5",
    }}
    xmlSpace="preserve"
    width={props.width}
    height={props.height}
    {...props}
  >
    <path
      d="M126.7 53.2c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4h-7.2c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4H108c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4h-7.2c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4h-7.2c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4h-7.2c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4h-7.2c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4H61c0-1.7-.2-3.3-.6-4.8-.7-3.2-1.8-3.6-2.2-3.8-.6-.2-1.2-.2-1.8.2-1.6 1.1-2.1 3.9-2.3 6.6 0 .6.4 1.1 1 1.2.6 0 1.1-.4 1.2-1 .3-3.3.8-4.5 1.2-4.9.2.2.5.9.8 2.1.3 1.3.5 2.8.5 4.4h-9.3v62.9c0 4.7 3.9 8.6 8.6 8.6h64.4c4.7 0 8.6-3.9 8.6-8.6V53.2h-4.4zm-6.3 60.2H60.6v-.9h59.8v.9zm0-10.8H60.6v-.9h59.8v.9zm0-10.9H60.6v-.9h59.8v.9zm0-10.8H60.6V80h59.8v.9zm0-10.8H60.6v-.9h59.8v.9z"
      style={{
        fill: "#ffc827",
      }}
    />
    <circle
      cx={90.7}
      cy={89.8}
      r={25.3}
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M97 98.6c-1.3 1.6-3.9 2.7-6.6 2.7-4.6 0-7.3-2.5-8.3-7.2h-2.5l.9-2.8h1.3v-1.2c0-.5 0-1 .1-1.5h-2.1l.9-2.8h1.6c1.2-4.6 4.5-7 9.4-7 1.4 0 3.9.4 4.7.9l-1.4 4c-.6-.4-1.9-.8-3.3-.8-2.1 0-3.7.9-4.6 2.9h7.2l-.9 2.8h-6.9c0 .5-.1.9-.1 1.4v1.2h6.2l-.9 2.8h-4.9c.6 2 1.9 3.2 3.9 3.2 1.7 0 2.8-.7 3.7-1.8l2.6 3.2z"
      style={{
        fill: "#4071b6",
      }}
    />
  </svg>
)

InstalmentsIcon.defaultProps = {
    width: '1em',
    height: '1em',
}

InstalmentsIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}

export default InstalmentsIcon
