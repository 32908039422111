import * as R from 'ramda'

import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'buildings/buildings'
const bbPrefix = 'company/buildings'
const acPrefix = 'accounting/installments'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    buildings: builder.query({
      query: (qs) => `buildings/buildings?${apiQueryString(qs)}`,
      providesTags: ['Building'],
    }),
    building: builder.query({
      query: (arcadiaId) => `buildings/buildings/${arcadiaId}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'Building', id: arcadiaId }],
    }),
    branchesBuildings: builder.query({
      query: (qsObj) => {
        // let qs = '?gruppoEsterno__isnull=false'
        let qs = ''
        if (R.not(R.isEmpty(qsObj))) {
          qs =
            '?' +
            Object.keys(qsObj)
              .map((k) => `${k}=${qsObj[k]}`)
              .join('&')
        }
        return `${bbPrefix}${qs}`
      },
      providesTags: ['BranchBuilding'],
    }),
    branchBuilding: builder.query({
      query: (id) => `${bbPrefix}/${id}`,
      keepUnusedDataFor: 120,
    }),
    branchBuildingFromArcadiaId: builder.query({
      query: (arcadiaId) => `${bbPrefix}/arcadia?arcadia_id=${arcadiaId}`,
    }),
    createBranchBuilding: builder.mutation({
      query: (branchBuilding) => ({
        url: `${bbPrefix}`,
        body: branchBuilding,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { branchId }) => [{ type: 'BranchBuilding', id: branchId }, { type: 'Building' }],
    }),
    createMultiBranchBuilding: builder.mutation({
      query: (body) => ({
        url: `${bbPrefix}/create_multi`,
        body: body,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { branchId }) => [{ type: 'BranchBuilding', id: branchId }, { type: 'Building' }, { type: 'Condo' }],
    }),
    assignBranchBuildingOwners: builder.mutation({
      query: (body) => ({
        url: `${bbPrefix}/owners`,
        body: body,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { branchId }) => [{ type: 'BranchBuilding', id: branchId }, { type: 'Building' }],
    }),
    updateBranchBuilding: builder.mutation({
      query: ({ id, ...branchBuilding }) => ({
        url: `${bbPrefix}/${id}`,
        body: branchBuilding,
        method: 'PATCH',
      }),
      invalidatesTags: ['BranchBuilding', 'Building'],
    }),
    updateBranchBuildingNotes: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${bbPrefix}/${id}/notes`,
        body,
        method: 'PATCH',
      }),
      invalidatesTags: ['BranchBuilding', 'Building'],
    }),
    deleteBranchBuilding: builder.mutation({
      query: (id) => ({
        url: `${bbPrefix}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BranchBuilding', 'Building'],
    }),
    buildingStructure: builder.query({
      query: (arcadiaId) => `${prefix}/${arcadiaId}/structure`,
    }),
    buildingUnit: builder.query({
      query: ({ buildingArcadiaId, housingUnitId }) => `${prefix}/${buildingArcadiaId}/structure?unit=${housingUnitId}`,
    }),
    buildingRepresentatives: builder.query({
      query: ({ buildingArcadiaId, qs }) => `${prefix}/${buildingArcadiaId}/representatives?${apiQueryString(qs)}`,
    }),
    buildingOpenFinancialStatements: builder.query({
      query: ({ buildingArcadiaId, qs }) => {
        return `${prefix}/${buildingArcadiaId}/open_financial_statements?${apiQueryString(qs)}`
      },
    }),
    buildingFacilities: builder.query({
      query: (buildingArcadiaId) => `${prefix}/${buildingArcadiaId}/facilities`,
    }),
    buildingInstallments: builder.query({
      query: ({ buildingArcadiaId, qs }) => {
        return `${acPrefix}?${apiQueryString(qs)}&immobile__id=${buildingArcadiaId}`
      },
    }),
    buildingAnagraphics: builder.query({
      query: (buildingArcadiaId) => {
        return `${prefix}/${buildingArcadiaId}/anagraphics`
      },
    }),
    anagraphicInstallments: builder.query({
      query: ({ anagraphicId, qs }) => {
        return `${acPrefix}?${apiQueryString(qs)}&anagrafica__id=${anagraphicId}`
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useBuildingsQuery,
  useBuildingQuery,
  useLazyBuildingsQuery,
  useBranchesBuildingsQuery,
  useLazyBranchesBuildingsQuery,
  useBranchBuildingQuery,
  useLazyBranchBuildingQuery,
  useCreateBranchBuildingMutation,
  useCreateMultiBranchBuildingMutation,
  useAssignBranchBuildingOwnersMutation,
  useUpdateBranchBuildingMutation,
  useUpdateBranchBuildingNotesMutation,
  useDeleteBranchBuildingMutation,
  useBranchBuildingFromArcadiaIdQuery,
  useBuildingStructureQuery,
  useBuildingUnitQuery,
  useBuildingRepresentativesQuery,
  useBuildingOpenFinancialStatementsQuery,
  useBuildingFacilitiesQuery,
  useBuildingInstallmentsQuery,
  useBuildingAnagraphicsQuery,
  useLazyBuildingAnagraphicsQuery,
  useAnagraphicInstallmentsQuery,
} = extendedApi
