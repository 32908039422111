import AddIcon from '@mui/icons-material/Add'
import MuiFab from '@mui/material/Fab'
import { styled as muistyled } from '@mui/system'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Cursor } from '../../Common/Components/Styles'

const Fab = muistyled(MuiFab)`
    position: ${(props) => (props.className === 'absolute' ? 'absolute' : 'fixed')};
    bottom: ${(props) => props.bottom || '28px'};
    left: ${(props) => props.left || 'auto'};
    right: ${(props) => props.right || '28px'};
`

const AddFab = ({ left, right, bottom, onClick, absolute, size }) => {
  const { t } = useTranslation()
  return (
    <Cursor>
      <Fab
        className={absolute ? 'absolute' : ''}
        color="primary"
        aria-label={t('common:generic.add')}
        onClick={onClick}
        left={left}
        right={right}
        bottom={bottom}
        size={size}
      >
        <AddIcon />
      </Fab>
    </Cursor>
  )
}

AddFab.defaultProps = {
  size: 'medium',
}

AddFab.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  absolute: PropTypes.bool,
  size: PropTypes.string,
}

export default AddFab
