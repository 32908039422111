import i18next from 'i18next'
import PropTypes from 'prop-types'
import { UserType } from '@Auth/Models/User'

export const RoledataType = {
  isRoleSupervisor: PropTypes.bool,
  hqAccidentPractice: PropTypes.bool,
  hqAssurance: PropTypes.bool,
  hqBankReconciliation: PropTypes.bool,
  hqBudget: PropTypes.bool,
  hqBuildingBankAccounts: PropTypes.bool,
  hqBuildingEmployeeManagement: PropTypes.bool,
  hqBuildingFiscalCodes: PropTypes.bool,
  hqChangeOwnershipCounts: PropTypes.bool,
  hqEInvoicingActivation: PropTypes.bool,
  hqFinalBalance: PropTypes.bool,
  hqFtfPayment: PropTypes.bool,
  hqInvoicesReceiptsRegistration: PropTypes.bool,
  hqLegalPractice: PropTypes.bool,
  hqMeetingCall: PropTypes.bool,
  hqMeetingReportSend: PropTypes.bool,
  hqPatrimonialSquaring: PropTypes.bool,
  hqPaymentNotices: PropTypes.bool,
  hqPaymentReminders: PropTypes.bool,
  hqPecSend: PropTypes.bool,
  hqSecurityDossier: PropTypes.bool,
  hqTaxProceduresDeclarationsDeductions: PropTypes.bool,
  hqWaterUtilitiesSplit: PropTypes.bool,
}

export const EmployeeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  burdenCenter: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fullName: PropTypes.string,
  phone: PropTypes.string,
  city: PropTypes.number,
  cityObj: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    province: PropTypes.number.isRequired,
    provinceObj: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      region: PropTypes.number.isRequired,
      regionObj: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }),
    }),
  }),
  address: PropTypes.string,
  cap: PropTypes.string,
  houseNumber: PropTypes.string,
  roledata: PropTypes.shape(RoledataType),
  user: UserType
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('auth:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'user.username',
      label: i18next.t('auth:fields.username'),
    },
    {
      id: 'user.role.name',
      label: i18next.t('auth:fields.role'),
      ordering: false,
    },
    {
      id: 'firstName',
      label: i18next.t('auth:fields.firstName'),
    },
    {
      id: 'lastName',
      label: i18next.t('auth:fields.lastName'),
    },
    {
      id: 'phone',
      label: i18next.t('auth:fields.phone'),
    },
    {
      id: 'burdenCenter',
      label: i18next.t('auth:fields.burdenCenter'),
      numeric: true,
    },
    {
      id: 'fiscalCode',
      label: i18next.t('auth:fields.fiscalCode'),
    },
    {
      id: 'cityObj.name',
      label: i18next.t('auth:fields.city'),
    },
    {
      id: 'cityObj.provinceObj.name',
      label: i18next.t('auth:fields.province'),
    },
    {
      id: 'cityObj.provinceObj.regionObj.name',
      label: i18next.t('auth:fields.region'),
    },
    {
      id: 'address',
      label: i18next.t('auth:fields.address'),
    },
    {
      id: 'houseNumber',
      label: i18next.t('auth:fields.houseNumber'),
      numeric: true,
    },
    {
      id: 'cap',
      label: i18next.t('auth:fields.cap'),
    },
    {
      id: 'dateIn',
      label: i18next.t('auth:fields.dateIn'),
      date: true,
    },
    {
      id: 'dateOut',
      label: i18next.t('auth:fields.dateOut'),
      date: true,
    },
    {
      id: 'oooSince',
      label: i18next.t('auth:fields.oooSince'),
      date: true,
    },
    {
      id: 'oooUntil',
      label: i18next.t('auth:fields.oooUntil'),
      date: true,
    },
    {
      id: 'user.isActive',
      label: i18next.t('auth:fields.active'),
      boolean: true,
    },
  ],
}
