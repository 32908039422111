import PropTypes from 'prop-types'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { store } from '@Core/Redux/Store'

import config from '../../Config'

/**
 * A decorator for private routes which redirects to login
 * if user is not authenticated
 * @param element: the component to render
 * @param permFunction: a function to check user permissions for the view, receives the user object, must return a bool
 * @param actions: actions to be dispatched, in the form [action, shouldDispatch]
 *                 action is the action to dispatch
 *                 shouldDispatch is a function that receives the application state and returns true
 *                 if the action should be dispatched, false otherwise
 */
const PrivateRoute = ({ children, permFunction, actions = [] }) => {
  const state = store.getState()
  if (state.auth?.login?.user && (!permFunction || permFunction(state.auth.login.user))) {
    // actions that needs to be dispatched before entering the view
    if (actions && actions.length) {
      actions.forEach(([action, shouldDispatch]) => {
        if (shouldDispatch(state)) {
          store.dispatch(action)
        }
      })
    }
    // Outlet is the right way to implement PrivateRoute
    // for prevent incompatibility with old code
    // we preserve children properties
    return (
      <>
        <Outlet />
        {children}
      </>
    )
  } else {
    const redirectPath = config.urls.login
    return (
      <Navigate
        to={{
          pathname: redirectPath,
        }}
      />
    )
  }
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  permFunction: PropTypes.func,
  actions: PropTypes.array,
}

export default PrivateRoute
