import { Radio, RadioGroup } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '@Common/Components/Checkbox'
import { Box } from '@Common/Components/Styles'
import { setStateFromCheckboxEvent } from '@Common/Utils/Events'
import { FormControl } from '@Tms/Components/Styled'

const DateTypeOptions = ({ fields, setField, setFields }) => {
  const { t } = useTranslation()

  const handleAllDatesOption = () => setFields({ ...fields, isDateInFuture: false, isDateInPast: false })

  const handleDateOption = (fieldName) =>
    R.pipe(
      R.path(['target', 'checked']),
      R.objOf(fieldName),
      R.mergeRight(fieldName === 'isDateInPast' ? { isDateInFuture: false } : { isDateInPast: false }),
      R.mergeRight(fields),
      setFields,
    )

  return (
    <Box direction="row">
      <Box>
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              checked={!fields.isDateInFuture && !fields.isDateInPast}
              onChange={handleAllDatesOption}
              control={<Radio />}
              label={t('tms:ui.AllDates')}
            />
            <FormControlLabel
              checked={fields.isDateInPast}
              onChange={handleDateOption('isDateInPast')}
              control={<Radio />}
              label={t('tms:fields.isDateInPast')}
            />
            <FormControlLabel
              checked={fields.isDateInFuture}
              onChange={handleDateOption('isDateInFuture')}
              control={<Radio />}
              label={t('tms:fields.isDateInFuture')}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <Checkbox
            label={t('tms:fields.sendCalendar')}
            onChange={setStateFromCheckboxEvent(setField('sendCalendar'))}
          />
        </FormControl>
      </Box>
    </Box>
  )
}

DateTypeOptions.propTypes = {
  fields: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
}

export default DateTypeOptions
