import WarningIcon from '@mui/icons-material/Warning'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import styled from 'styled-components'

export const Chip = styled.div`
  align-items: center;
  background: ${(props) => props.bg};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 22px;
  width: 22px;
`

const AlertChip = () => {
  const theme = useTheme()
  return (
    <Chip bg={theme.palette.warning.main}>
      <WarningIcon sx={{ color: theme.palette.white.main, fontSize: '1rem' }} />
    </Chip>
  )
}

export default AlertChip
