import { checkRequired } from '@Common/Utils/Validation'
import i18next from 'i18next'
import { convertToRaw } from 'draft-js';
import * as R from 'ramda'

export const validate = (fields, setErrors, news) => {
  const err = {}

  const required = ['title', 'abstract', 'date']

  checkRequired(err, required, fields)

  if (fields['image'].length === 0 && !news?.image) {
    err['image'] = i18next.t('common:validation.RequiredField')
  }

  const rawContent = convertToRaw(fields.content.getCurrentContent())
  if (rawContent.blocks.length === 1 && rawContent.blocks[0].text === '') {
    err['content'] = i18next.t('common:validation.RequiredField')
  }

  setErrors(err)
  return R.isEmpty(err)
}
