/* eslint-disable react/prop-types */
import { Box as MuiBox, Grid, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'

import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { Box } from '@Common/Components/Styles'
import { TabPanel } from '@Common/Components/TabPanel'
import { api } from '@Core/Services/Api'
import { SupplierServiceField } from '@Supplier/Components/SupplierServiceField'
import { CondoSupplierCompany } from '@Supplier/Models/CondoSupplierCompany'

import { CondoSupplierCompanyContractsForm } from './CondoSupplierCompanyContractsForm'
import { CondoSupplierCompanyServicesForm } from './CondoSupplierCompanyServicesForm'

export const CondoSupplierCompanyForm = ({ defaultValues }) => {
  const form = useForm({ defaultValues })
  const { t } = useTranslation()

  const isEdit = !!defaultValues?.id || false

  const { buildingId } = useParams()

  const urlSuppliers = `/condo/buildings/${buildingId}/available-suppliers`

  const urlAssociation = generatePath(CondoSupplierCompany.url, {
    buildingId: buildingId,
  })

  const urlDefaultBranches = isEdit
    ? `/condo/buildings/${buildingId}/available-suppliers/${defaultValues.bicertSupplierId}/branches`
    : null

  const [update] = api.useUpdateOrCreateItemMutation()

  const handleSave = (item) => {
    let data = null
    if (!isEdit) {
      data = {
        bicertSupplierId: item.bicertSupplierId,
        bicertSupplierBranchIds: item.bicertSupplierBranchIds,
        contractList: [],
        serviceList: [],
      }
    } else {
      data = {
        ...item,
        bicertSupplierId: defaultValues.bicertSupplierId,
        bicertSupplierBranchIds: defaultValues.bicertSupplierBranchIds,
      }
    }

    return update({ url: urlAssociation, item: data })
  }

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const handleTabChange = (_, idx) => setActiveTabIndex(idx)

  const [urlBranches, setUrlBranches] = useState(urlDefaultBranches)
  const [qsAdditionSupplier, setQsAdditionSupplier] = useState(null)

  useEffect(() => {
    const bicertSupplierId = form.getValues('bicertSupplierId')

    form.setValue('bicertSupplierBranchIds', null)

    if (bicertSupplierId)
      setUrlBranches(`/condo/buildings/${buildingId}/available-suppliers/${bicertSupplierId}/branches`)
  }, [form.watch('bicertSupplierId')])

  useEffect(() => {
    const services = form.getValues('services')

    if (services) {
      setQsAdditionSupplier({ qsAdditions: { services } })
      form.setValue('bicertSupplierId', null)
      form.setValue('bicertSupplierBranchIds', null)
      setUrlBranches(null)
    } else {
      setQsAdditionSupplier({})
      setUrlBranches(null)
    }
  }, [form.watch('services')])

  const contentFiledForm = isEdit ? (
    <Grid item xs={12}>
      <Box>
        <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
          <Tabs value={activeTabIndex} onChange={handleTabChange} style={{ overflow: 'auto' }}>
            <Tab label={t('condosuppliercompany:tabsForm.generic')} />
            <Tab label={t('condosuppliercompany:tabsForm.services')} />
            <Tab label={t('condosuppliercompany:tabsForm.contracts')} />
          </Tabs>
        </MuiBox>
        <TabPanel activeIndex={activeTabIndex} index={0}>
          <Grid container rowSpacing={3} spacing={2}>
            {defaultValues?.bicertSupplierBranchIds && (
              <InputFieldV2
                name="bicertSupplierBranchIds"
                md={12}
                source={urlDefaultBranches}
                disabled={isEdit}
                value={defaultValues?.bicertSupplierBranchIds || []}
              />
            )}
            <InputFieldV2 name="blacklist" md={6} />
            <InputFieldV2 name="notes" />
          </Grid>
        </TabPanel>
        <TabPanel activeIndex={activeTabIndex} index={1}>
          <CondoSupplierCompanyServicesForm setQsAdditionSupplier={setQsAdditionSupplier} />
        </TabPanel>
        <TabPanel activeIndex={activeTabIndex} index={2}>
          <Grid container rowSpacing={3} spacing={2}>
            <CondoSupplierCompanyContractsForm />
          </Grid>
        </TabPanel>
      </Box>
    </Grid>
  ) : (
    <>
      <SupplierServiceField xs={12} />
      <InputFieldV2
        name="bicertSupplierId"
        md={12}
        source={urlSuppliers}
        disabled={isEdit}
        qs={qsAdditionSupplier}
        ordering={'name'}
      />
      {urlBranches && (
        <InputFieldV2 name="bicertSupplierBranchIds" md={12} source={urlBranches} disabled={isEdit || !urlBranches} />
      )}
    </>
  )

  return (
    <FormV2 form={form} onSubmit={handleSave} model={CondoSupplierCompany}>
      {contentFiledForm}
    </FormV2>
  )
}
