import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SearchIcon from '@mui/icons-material/Search'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { defaultTo } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import EmployeeByRoleSelect from '@Auth/Components/EmployeeByRoleSelect'
import { useEmployeesByRolesQuery } from '@Auth/Services/Api'
import { getEmployeeInfo } from '@Auth/Utils'
import BranchSelect from '@Branches/Components/BranchSelect'
import { useBranchesQuery } from '@Branches/Services/Api'
import { useBranchesBuildingsQuery } from '@Buildings/Services/Api'
import Checkbox from '@Common/Components/Checkbox'
import Loader from '@Common/Components/Loader'
import MobileDateRangePicker from '@Common/Components/MobileDateRangePicker'
import Modal from '@Common/Components/Modal'
import { Box, TextNoWrap } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromCheckboxEvent, setStateFromEvent } from '@Common/Utils/Events'
import { useDebounce, useForm } from '@Common/Utils/Hooks'
import { useCondosQuery } from '@Condo/Services/Api'
import { apiList } from '@Core/Services/Api'
import BuildingSelect from '@Tms/Forms/Selects/BuildingSelect'
import CategorySelect from '@Tms/Forms/Selects/CategorySelect'
import PrioritySelect from '@Tms/Forms/Selects/PrioritySelect'
import StatusSelect from '@Tms/Forms/Selects/StatusSelect'
import { selectSearch } from '@Tms/Redux'
import { useCategoriesQuery } from '@Tms/Services/Api'
import { PRIORITIES, STATUS_ENUM, TASK_STATUSES } from '@Tms/Utils'

const getBuildingLabel = (data, value) => {
  const buildingInfos = value.split('_')
  switch (buildingInfos[1]) {
    case 'branchbuilding':
      return data.buildings.find((b) => b.id === +buildingInfos[0])?.raw?.denominazione
    case 'building':
      return data.condos.find((b) => b.id === +buildingInfos[0])?.description
  }
}

const formatFilterValue = (filter, value, data) => {
  switch (filter) {
    case 'deadlineGte':
    case 'deadlineLte':
    case 'createdGte':
    case 'createdLte':
      return dayjs(value).format('YYYY-MM-DD')
    case 'category':
      return data.categories.find((c) => c.id === value).name
    case 'priority':
      return PRIORITIES.find(({ value: v }) => v === value).label
    case 'status':
      return TASK_STATUSES.find(({ value: v }) => v === value).label
    case 'branch':
      return data.branches.find((b) => b.id === value).name
    case 'building':
      return getBuildingLabel(data, value)
    case 'resident':
      return null
    case 'assignedTo':
      return getEmployeeInfo(data.employees.find((e) => e.id === value))
  }
}

const FilterChip = ({ filter, value, onClear }) => {
  const { t } = useTranslation()

  return (
    <Chip
      color="primary"
      variant="outlined"
      onDelete={onClear}
      deleteIcon={<CancelRoundedIcon />}
      sx={{ mr: 1, mt: 1 }}
      size="small"
      label={
        <Box direction="row" align="center" gap="0.5rem">
          {t(`tms:filters.${filter}`)}: {value ?? <Loader size={12} minHeight="60px" />}
        </Box>
      }
    />
  )
}

FilterChip.propTypes = {
  filter: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClear: PropTypes.func,
}

const FilterModal = ({ filters, onClose, onSave }) => {
  const { t } = useTranslation()

  const { fields, setField, setFields } = useForm(
    {
      deadlineGte: defaultTo(null, filters?.deadlineGte),
      deadlineLte: defaultTo(null, filters?.deadlineLte),
      createdGte: defaultTo(null, filters?.createdGte),
      createdLte: defaultTo(null, filters?.createdLte),
      category: defaultTo('', filters?.category),
      priority: defaultTo('', filters?.priority),
      status: defaultTo('', filters?.status),
      branch: defaultTo('', filters?.branch),
      building: defaultTo('', filters?.building),
      resident: defaultTo('', filters?.resident),
      assignedTo: defaultTo('', filters?.assignedTo),
    },
    true,
  )

  const submit = () => onSave(fields)

  const handleBranchChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('branch'),
    R.mergeRight({ building: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const setDateRange = (field) => (range) => {
    setFields({ ...fields, [`${field}Gte`]: range[0], [`${field}Lte`]: range[1] })
  }

  return (
    <Modal title={t('tms:filters.AllFilters')} onClose={onClose} onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MobileDateRangePicker
            startText={t('tms:filters.deadlineGte')}
            endText={t('tms:filters.deadlineLte')}
            range={[fields.deadlineGte, fields.deadlineLte]}
            onChange={setDateRange('deadline')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MobileDateRangePicker
            startText={t('tms:filters.createdGte')}
            endText={t('tms:filters.createdLte')}
            range={[fields.createdGte, fields.createdLte]}
            onChange={setDateRange('created')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CategorySelect
            qs={{ ordering: 'name' }}
            onChange={setStateFromEvent(setField('category'))}
            value={fields.category}
            label={t('tms:filters.category')}
            notRHF
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PrioritySelect
            onChange={setStateFromEvent(setField('priority'))}
            value={fields.priority}
            label={t('tms:filters.priority')}
            notRHF
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatusSelect
            onChange={setStateFromEvent(setField('status'))}
            value={fields.status}
            label={t('tms:filters.status')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BranchSelect
            searchable
            onChange={handleBranchChange}
            value={fields.branch}
            label={t('tms:filters.branch')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BuildingSelect
            onChange={setStateFromEvent(setField('building'))}
            value={fields.building}
            branchId={fields.branch || 0}
            label={t('tms:filters.building')}
            notRHF
          />
        </Grid>
        {/* todo supplier */}
        {/* <Grid item xs={12} md={6}> */}
        {/*   <CategorySelect onChange={setStateFromEvent(setField('category'))} value={fields.category} notRHF /> */}
        {/* </Grid> */}
        <Grid item xs={12} md={6}>
          <EmployeeByRoleSelect
            roles={[]}
            searchable
            label={t('tms:filters.assignedTo')}
            onChange={setStateFromEvent(setField('assignedTo'))}
            value={fields.assignedTo}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

FilterModal.propTypes = {
  filters: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

const removeQuickFilters = (filters) => {
  const modalFilters = { ...filters }
  delete modalFilters?.showCompleted
  delete modalFilters?.onlyMainTasks
  delete modalFilters?.onlyMyTasks

  return modalFilters
}

const TasksFilterForm = ({ filters, onFilter }) => {
  const isInitialMount = React.useRef(true) // avoid double request cause by onSearch call
  const { t } = useTranslation()
  const [filterModal, setFilterModal] = useState({ open: false, filters: removeQuickFilters(filters) })

  // already cached (no additional requests will be performed)
  const { data: categories } = apiList(useCategoriesQuery())
  const { data: branches } = apiList(useBranchesQuery())
  const { data: buildings } = apiList(useBranchesBuildingsQuery({}))
  const { data: condos } = apiList(useCondosQuery({ base: { record_type: 'LOCAL' } }))
  const { data: employees } = apiList(useEmployeesByRolesQuery([]))
  const filterChipsData = { categories, branches, buildings, condos, employees }

  // search tooltip
  const searchPlaceholder = t('common:ui.SearchFields', {
    fields: `${t('tms:fields.title')}, ${t('tms:fields.description')}, ${t('tms:fields.notes')}`,
  })

  // search
  const searchState = useSelector(selectSearch)
  const [searchFilter, setSearchFilter] = React.useState(searchState)
  const debouncedSearchFilter = useDebounce(searchFilter, 400)

  // quick filters
  const { fields: quickFilters, setField: setQuickFilter } = useForm(
    {
      showCompleted: defaultTo(false, filters?.showCompleted),
      onlyMainTasks: defaultTo(false, filters?.onlyMainTasks),
      onlyMyTasks: defaultTo(false, filters?.onlyMyTasks),
    },
    true,
  )

  // just run on update, no mount to avoid calling onSearch if not needed
  // because it causes a new request to be performed (changing qsAdditions)
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      onFilter({ ...quickFilters, ...filterModal.filters, search: debouncedSearchFilter })
    }
  }, [debouncedSearchFilter, JSON.stringify(quickFilters)])

  const handleOpenModal = () => setFilterModal({ ...filterModal, open: true })
  const handleCloseModal = () => setFilterModal({ ...filterModal, open: false })
  const handleSaveFilters = (filters) => {
    // reset quick filters that collide with modal filters
    if (filters.assignedTo) setQuickFilter('onlyMyTasks')(false)
    if (filters.status === STATUS_ENUM.COMPLETED) setQuickFilter('showCompleted')(true)
    // if (filters.status !== STATUS_ENUM.OPEN.COMPLETED) setQuickFilter('showCompleted')(false)

    setFilterModal({ open: false, filters })
    onFilter({ search: debouncedSearchFilter, ...quickFilters, ...filters })
  }

  const resetModalFilter = (filterProp) => () => {
    const filters = { ...filterModal.filters, [filterProp]: '' }
    setFilterModal({ ...filterModal, filters })
    onFilter({ search: debouncedSearchFilter, ...quickFilters, ...filters })
  }

  const getFilterChips = R.pipe(R.prop('filters'), R.reject(R.either(R.isNil, R.isEmpty)), R.toPairs)

  return (
    <>
      <Box width="100%">
        <Box width="100%" direction="row" justify="space-between" align="center">
          <Grid container spacing={1} justifyContent="start" alignItems="end">
            <Grid item xs={12} sm={6} md={3} lg={2} p={0}>
              <Tooltip title={searchPlaceholder}>
                <div>
                  <TextField
                    fullWidth
                    label={t('common:ui.SearchPlaceholder')}
                    value={searchFilter}
                    onChange={setStateFromEvent(setSearchFilter)}
                    style={{ minWidth: '120px' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item>
              <Checkbox
                checked={quickFilters.onlyMainTasks}
                onChange={setStateFromCheckboxEvent(setQuickFilter('onlyMainTasks'))}
                label={<TextNoWrap>{t('tms:filters.ShowOnlyMainTasks')}</TextNoWrap>}
              />
            </Grid>
            <Grid item>
              <Checkbox
                checked={quickFilters.showCompleted}
                onChange={setStateFromCheckboxEvent(setQuickFilter('showCompleted'))}
                label={<TextNoWrap>{t('tms:filters.ShowCompleted')}</TextNoWrap>}
              />
            </Grid>
            <Grid item>
              <Checkbox
                checked={quickFilters.onlyMyTasks}
                onChange={setStateFromCheckboxEvent(setQuickFilter('onlyMyTasks'))}
                label={<TextNoWrap>{t('tms:filters.ShowOnlyMyTasks')}</TextNoWrap>}
              />
            </Grid>
          </Grid>
          <Button
            size="small"
            disableElevation
            variant="contained"
            onClick={handleOpenModal}
            startIcon={<FilterAltIcon></FilterAltIcon>}
          >
            <TextNoWrap>{t('tms:filters.AllFilters')}</TextNoWrap>
          </Button>
        </Box>
        <Box margin="1rem 0 0" width="100%" direction="row" $wrap="wrap" align="flex-end">
          {/* <FilterChipsWrapper> */}
          {getFilterChips(filterModal).map(([filter, value], i) => (
            <React.Fragment key={filter}>
              {i === 0 && <TextNoWrap style={{ marginRight: '0.5rem' }}>Filtri selezionati: </TextNoWrap>}
              <FilterChip
                filter={filter}
                value={formatFilterValue(filter, value, filterChipsData)}
                onClear={resetModalFilter(filter)}
              />
            </React.Fragment>
          ))}
          {/* </FilterChipsWrapper> */}
        </Box>
      </Box>
      {filterModal.open && (
        <FilterModal filters={filterModal.filters} onClose={handleCloseModal} onSave={handleSaveFilters} />
      )}
    </>
  )
}

TasksFilterForm.propTypes = {
  filters: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
}

export default TasksFilterForm
