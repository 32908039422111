import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'branches',
  initialState: { active: null },
  reducers: {
    setActiveBranch: (state, { payload }) => {
      state.active = payload
    },
  },
})

export const { setActiveBranch } = slice.actions

export default slice.reducer

export const selectActiveBranch = (state) => state.branches.active
