import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'

const getTasks = (task, taskDepth, exclude = []) => {
  const tasks = []
  if (taskDepth >= 0) {
    if (!exclude.includes(task.id)) {
      tasks.push({ value: task.id, label: `${task.name} (id: ${task.id})` })
    }
    task.children.forEach((t) => {
      const otherTasks = getTasks(t, taskDepth - 1, exclude)
      tasks.push(...otherTasks)
    })
  }
  return tasks
}

const AncestorAndSiblingTasksSelect = ({ name, control, taskDepth, exclude = [], ...other }) => {
  const { getValues } = useFormContext()
  const values = getValues()
  const [tasks, setTasks] = useState([])

  useEffect(() => {
    const tasks = getTasks(values.mainTask, taskDepth, exclude)
    setTasks(tasks)
  }, [JSON.stringify(values)])

  return <SelectField name={name} control={control} options={tasks} {...other} />
}

AncestorAndSiblingTasksSelect.propTypes = FieldType

export default AncestorAndSiblingTasksSelect
