import { api, apiQueryString } from '@Core/Services/Api'

import { prefix } from './Api'

const prefixInsurances = 'condo/insurances'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    condoInsurancePolicy: builder.query({
      query: ({ qs, condo_id = null }) => {
        if (condo_id) {
          return `${prefix}/${condo_id}/insurances?${apiQueryString(qs)}`
        } else {
          return `${prefixInsurances}?${apiQueryString(qs)}`
        }
      },
      providesTags: ['InsurancePolicy'],
    }),
    insurancePolicyById: builder.query({
      query: (id) => `${prefixInsurances}/${id}`,
      providesTags: ['InsurancePolicy'],
    }),
    createInsurancePolicy: builder.mutation({
      query: (insurancePolicy) => ({
        url: `${prefix}/${insurancePolicy.building}/insurances`,
        body: insurancePolicy,
        method: 'POST',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    updateInsurancePolicy: builder.mutation({
      query: (insurancePolicy) => ({
        url: `${prefix}/${insurancePolicy.building}/insurances/${insurancePolicy.id}`,
        body: insurancePolicy,
        method: 'PATCH',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    updateAllFieldInsurancePolicy: builder.mutation({
      query: (insurancePolicy) => ({
        url: `${prefixInsurances}/${insurancePolicy.id}`,
        body: insurancePolicy,
        method: 'PATCH',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    deleteInsurancePolicy: builder.mutation({
      query: (id) => ({
        url: `${prefixInsurances}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    closeInsurancePolicy: builder.mutation({
      query: (id) => ({
        url: `${prefixInsurances}/${id}/set_status_ceased`,
        method: 'POST',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    reopenInsurancePolicy: builder.mutation({
      query: (id) => ({
        url: `${prefixInsurances}/${id}/set_status_active`,
        method: 'POST',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    importInsurancePolicy: builder.mutation({
      query: (insurancePolicy) => ({
        url: `${prefixInsurances}/excel`,
        body: insurancePolicy,
        method: 'POST',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    insurancePolicyPay: builder.mutation({
      query: (id) => ({
        url: `${prefixInsurances}/${id}/pay`,
        method: 'POST',
      }),
      invalidatesTags: ['InsurancePolicy'],
    }),
    insurancePaymentsLogs: builder.query({
      query: ({ insurance_id }) => {
        return `${prefixInsurances}/${insurance_id}/payments`
      },
      providesTags: ['InsurancePolicy'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCondoInsurancePolicyQuery,
  useInsurancePolicyByIdQuery,
  useCreateInsurancePolicyMutation,
  useUpdateInsurancePolicyMutation,
  useUpdateAllFieldInsurancePolicyMutation,
  useDeleteInsurancePolicyMutation,
  useCloseInsurancePolicyMutation,
  useReopenInsurancePolicyMutation,
  useImportInsurancePolicyMutation,
  useInsurancePolicyPayMutation,
  useInsurancePaymentsLogsQuery,
} = extendedApi
