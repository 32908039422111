import PropTypes from 'prop-types'
import Modal from '@Common/Components/Modal'
import { useTranslation } from 'react-i18next'
import { FormControl, Grid } from '@mui/material'
import TextField from '../../Common/Components/TextField'
import { Label } from '@Common/Components/Styles'
import { useForm } from '../../Common/Utils/Hooks'
import { setStateFromEvent } from '../../Common/Utils/Events'
import { convertServerErrors } from '../../Common/Utils/Form'
import FileField from '../../Common/Components/FileField'
import { Box, Margin } from '../../Common/Components/Styles'
import logger from '@Common/Utils/Logger'
import { providerValidate } from './Validation'
import { ProviderType } from '../Models/Provider'
import * as R from 'ramda'
import React, { useState } from 'react'
import Loader from '@Common/Components/Loader'

export const ProviderForm = ({ onClose, onSave, provider }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fields, setField, errors, setErrors } = useForm(
    {
      name: R.defaultTo('', provider?.name),
      logo: [],
      description: R.defaultTo('', provider?.description),
    },
    true,
  )

  const onDrop = (acceptedFiles) => {
    setField('logo', 'array')(acceptedFiles)
  }

  const submit = async () => {
    logger.debug('Provider form submission, fields:', fields)
    if (providerValidate(fields, setErrors, provider)) {
      setIsSubmitting(true)
      logger.debug('Provider form submission, validation passed, saving')
      const { error, isSuccessful } = await onSave(fields)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('Provider form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('Provider form submission, validation failed')
    }
  }

  const title = provider?.id ? t('services:ui.ModifyProvider') : t('services:ui.AddProvider')

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              required
              label={t('services:fields.provider.Name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              multiline
              required
              label={t('services:fields.provider.Description')}
              value={fields.description}
              onChange={setStateFromEvent(setField('description'))}
              error={!!errors.description}
              helperText={errors.description}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Margin bottom="1rem" top="1rem">
              <Label>{t('services:fields.provider.Logo')} *</Label>
            </Margin>
            {provider?.logo && (
              <Box margin="0 0 1rem">
                {t('common:ui.CurrentImage')}
                {': '}
                <Margin top="1rem">
                  <img src={provider.logo} style={{ maxWidth: '100%' }} />
                </Margin>
              </Box>
            )}
            <FileField
              onDrop={onDrop}
              files={fields.logo}
              accept={'image/jpeg,image/png'}
              warningText={t('news:info.ImageLimit')}
              error={!!errors.logo}
              helperText={errors.logo}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  )
}

ProviderForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  provider: ProviderType,
}

export default ProviderForm
