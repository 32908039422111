import React from 'react'
import Modal from '@Common/Components/Modal'
import { Link } from '@mui/material'
import { useBranchesBuildingsQuery } from '@Buildings/Services/Api'
import { useNavigate } from 'react-router'
import DataTable from '@Common/Components/DataTable'
import BranchBuilding from '@Buildings/Models/BranchBuilding'
import { Box } from '@Common/Components/Styles'
import { getIban } from '@Buildings/Utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makePath } from '@Config'
import { getEmployeeDisplayName } from '@Auth/Utils'

const DISPLAY_COLUMNS = [
  'raw.denominazione',
  'branchObj.name',
  'raw.codiceFiscale',
  'raw.indirizzo.via',
  'raw.indirizzo.civico',
  'raw.indirizzo.comune',
]

const SEARCH_FIELDS = ['raw.denominazione', 'branchObj.name']

const DISABLED_SORT_FIELDS = ['administratorFullName', 'iban']

const ChooseInvitationBuildingModal = ({ onClose }) => {
  const { t } = useTranslation()
  const { data: buildings, isFetching } = useBranchesBuildingsQuery({ is_invited: false })
  const navigate = useNavigate()

  const FIELDS_MAPPING = {
    // eslint-disable-next-line react/display-name
    'raw.denominazione': (record) => (
      <Link
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(makePath('admin.buildingInvitations', { buildingId: record.id }))}
      >
        {record.raw?.denominazione}
      </Link>
    ),
    administratorFullName: (branchBuilding) => getEmployeeDisplayName(branchBuilding?.administratorObj),
    accountingOwnerFullName: (branchBuilding) => getEmployeeDisplayName(branchBuilding?.accountingOwner),
    administrationOwnerFullName: (branchBuilding) => getEmployeeDisplayName(branchBuilding?.administrationOwner),
    accidentsOwnerFullName: (branchBuilding) => getEmployeeDisplayName(branchBuilding?.accidentsOwner),
    iban: (branchBuilding) => getIban(branchBuilding.raw),
  }

  return (
    <Modal title={t('auth:ui.ChooseBuilding')} size="md" onClose={onClose}>
      <Box>
        <DataTable
          name="invitations-branch-buildings"
          data={buildings?.results || []}
          model={BranchBuilding}
          sortField="id"
          sortDirection="desc"
          listDisplay={DISPLAY_COLUMNS}
          fieldsMapping={FIELDS_MAPPING}
          searchFields={SEARCH_FIELDS}
          disableSorting={DISABLED_SORT_FIELDS}
          loading={isFetching}
          noExport
        />
      </Box>
    </Modal>
  )
}

ChooseInvitationBuildingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ChooseInvitationBuildingModal
