import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { ThemeProvider } from '@mui/material'
import dayjs from 'dayjs'
import 'dayjs/locale/it'
import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useGuessThemeMode } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { selectThemeMode } from '@Core/Redux/Ui'

import getTheme from '../Theme'
import GlobalStyles from '../Theme/GlobalStyles'
import { selectStartupComplete } from './Redux/Startup'
import AppRouter from './Router'
import StartupView from './Views/StartupView'

const AppContent = () => {
  const startupComplete = useSelector(selectStartupComplete)
  logger.debug('Startup complete: ', startupComplete)

  return R.ifElse(
    R.identity,
    () => <AppRouter />,
    () => <StartupView />,
  )(startupComplete)
}

const App = () => {
  useGuessThemeMode()
  const mode = useSelector(selectThemeMode)
  const theme = React.useMemo(() => getTheme(mode), [mode])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LocalizationProvider dateAdapter={AdapterDayjs} locale={dayjs.locale('it')}>
        <ToastContainer />
        <AppContent />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
