import i18next from 'i18next'
import * as R from 'ramda'

export const isLegalPerson = R.compose(R.equals(0), parseInt, R.path(['tipo', 'id']))

export const getDisplayName = R.ifElse(
  isLegalPerson,
  R.prop('ragioneSociale'),
  R.pipe(R.defaultTo({}), R.pick(['nome', 'cognome']), R.values, R.join(' ')),
)

export const getFiscalInfo = R.ifElse(isLegalPerson, R.prop('partitaIva'), R.prop('codiceFiscale'))

export const getAnagraphicInfo = R.converge(R.concat, [
  R.pipe(getDisplayName),
  R.pipe(
    getFiscalInfo,
    R.ifElse(R.isEmpty, R.always(''), (v) => ` - ${v}`),
  ),
])

export const getAvatarText = R.ifElse(
  R.compose(R.either(R.isNil, R.isEmpty), R.prop('ragioneSociale')),
  R.pipe(R.defaultTo({}), R.pick(['nome', 'cognome']), R.values, R.map(R.slice(0, 1)), R.join(''), R.toUpper),
  R.compose(R.toUpper, R.slice(0, 2), R.prop('ragioneSociale')),
)

export const displayAddress = (address) => {
  // cap: "10131"
  // civico: "8"
  // comune: "Torino"
  // localita: "Torino"
  // nazione: "Italia"
  // presso: null
  // provincia: "TO"
  // via: "Luigi Ornato"
  let res = address.via
  if (address.civico) res += ' ' + address.civico
  if (address.localita) res += ' ' + address.localita
  res += ', ' + address.comune
  if (address.provincia) res += ' (' + address.provincia + ')'
  if (address.nazione) res += ', ' + address.nazione + '.'
  if (address.presso) res += ' ' + i18next.t('myEstia:ui.AddressAt') + ' ' + address.presso

  return res
}
