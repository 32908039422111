import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { selectCurrentUser } from '@Auth/Redux/Login'
import { canAssignOwners, canViewAllBranches } from '@Auth/Utils'
import { BranchType } from '@Branches/Models/Branch'
import OwnerSelect from '@Buildings/Components/OwnerSelect'
import Building from '@Buildings/Models/Building'
import { BranchBuildingPermissions } from '@Buildings/Permissions'
import { useBuildingsQuery } from '@Buildings/Services/Api'
import { getIban } from '@Buildings/Utils'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { Box } from '@Common/Components/Styles'
import { makePath } from '@Config'
import { apiList } from '@Core/Services/Api'

const FIELDS_MAPPING = {
  iban: (building) => getIban(building),
  referente: R.compose(
    R.join(' '),
    R.paths([
      ['referente', 'nome'],
      ['referente', 'cognome'],
    ]),
  ),
}

const SEARCH_FIELDS = ['denominazione', 'indirizzo.comune']
const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    qs.qsAdditions.denominazione__icontains__or = qs.qsAdditions.search
    qs.qsAdditions.indirizzo__comune__icontains__or = qs.qsAdditions.search
    delete qs.qsAdditions.search
  }

  return qs
}

const DISABLED_SORT_FIELDS = ['iban', 'extra.branchObj.name']

const BranchBuildingsList = ({ branches, onEditNotes, onAssignOwners }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const [selected, setSelected] = React.useState()
  const [owner, setOwner] = useState(-1)

  const DISPLAY_COLUMNS = React.useMemo(() => {
    // default columns for building
    const ld = [
      'denominazione',
      'codiceFiscale',
      'indirizzo.via',
      'indirizzo.civico',
      'indirizzo.comune',
      'extra.administratorObj.fullName',
    ]
    if (branches.length > 1) {
      ld.unshift('extra.branchObj.name')
    }
    return ld
  }, [branches.length])

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        icon: <CorporateFareIcon />,
        label: t('common:actions.Detail'),
        perm: BranchBuildingPermissions.read,
        disabled: (record) => !record?.extra?.localBuildingId,
        tooltip: (record) => (record?.extra?.localBuildingId ? '' : t('buildings:ui.ArcadiaSync')),
      },
      {
        id: 'OWNERS',
        icon: <SupervisorAccountIcon />,
        label: t('buildings:actions.Owners'),
        perm: canAssignOwners,
      },
      {
        id: 'NOTES',
        icon: <NoteAltIcon />,
        label: t('common:actions.Notes'),
        perm: BranchBuildingPermissions.updateNotes,
      },
    ],
    [],
  )

  const bulkActions = React.useMemo(
    () => [
      {
        id: 'OWNERS',
        icon: <SupervisorAccountIcon />,
        label: t('buildings:actions.Owners'),
        perm: canAssignOwners,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, branchBuilding) => {
      switch (actionId) {
        case 'DETAIL':
          navigate(makePath('buildings.detail.main', { buildingId: branchBuilding?.extra?.localBuildingId }))
          break
        case 'OWNERS':
          onAssignOwners(selected)
          break
        case 'NOTES':
          onEditNotes(branchBuilding)
      }
    },
    [selected],
  )

  // default qs
  const qsAdditions = React.useMemo(() => {
    if (canViewAllBranches(user) && branches.length > 1) {
      // optimization, do not perform a select in with hundreds of ids
      return { gruppoEsterno__isnull: false }
    }
    return { gruppoEsterno__in: branches.map(R.prop('id')).join(',') }
  }, [branches.map(R.prop('id')).join('-'), user?.id])

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('branch-buildings'),
      offset: 0,
      ordering: 'denominazione',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, count, isFetching } = apiList(useBuildingsQuery(convertSearchQuery(qs)))

  const buildings = useMemo(() => {
    if (owner === -1) {
      // all buildings
      return data
    } else if (owner === 0) {
      // buildings w/o owner
      return data.filter((b) => {
        const bb = b?.extra
        return [bb.administrationOwner, bb.accidentsOwner, bb.accountingOwner].includes(null)
      })
    } else {
      // buildings with selected owner
      return data.filter((b) => {
        const bb = b?.extra
        return [bb.administrationOwner, bb.accidentsOwner, bb.accountingOwner].includes(owner)
      })
    }
  }, [data, owner])

  return (
    <div>
      <DataTable
        name={`branch-buildings`}
        qs={qs}
        qsAdditions={qsAdditions}
        data={buildings}
        dataCount={count}
        refreshData={refreshData}
        model={Building}
        sortField="id"
        sortDirection="desc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        disableSorting={DISABLED_SORT_FIELDS}
        loading={isFetching}
        actions={actions}
        bulkActions={bulkActions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noExport
      >
        <Box margin="0 1rem" width="100%" direction="row" justify="start">
          <Box width="200px">
            <OwnerSelect label={t('buildings:actions.FilterByOwner')} value={owner} setValue={setOwner} />
          </Box>
        </Box>
      </DataTable>
    </div>
  )
}

BranchBuildingsList.propTypes = {
  branches: PropTypes.arrayOf(BranchType),
  onEditNotes: PropTypes.func,
  onAssignOwners: PropTypes.func,
}

export default BranchBuildingsList
