import ChangePasswordForm from '@Auth/Forms/ChangePasswordForm'
import { useChangeUserPasswordMutation, useDeleteUserMutation } from '@Auth/Services/Api'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import config from '@Config'
import NotificationForm from '@Notifications/Forms/NotificationForm'
import { useCreateResidentsNotificationsMutation } from '@Notifications/Services/Api'
import * as R from 'ramda'
import React from 'react'
import draftToHtml from 'draftjs-to-html'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import ResidentsList from '../Components/ResidentsList'
import ResidentForm from '../Forms/ResidentForm'
import { useUpdateResidentMutation } from '../Services/Api'
import { convertToRaw } from 'draft-js'
import moment from 'moment'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminResidentsView = () => {
  const { t } = useTranslation()
  // api
  const [updateResident] = useUpdateResidentMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [changeUserPassword] = useChangeUserPasswordMutation()
  const [createNotification] = useCreateResidentsNotificationsMutation()

  // form
  const [form, setForm] = React.useState({ isOpen: false, resident: null })
  const [changePasswordForm, setChangePasswordForm] = React.useState({ isOpen: false, resident: null })
  const [notifyForm, setNotifyForm] = React.useState({ isOpen: false, residents: null })

  useBreadcrumbs([{ label: t('common:navigation.Residents'), path: config.urls.admin.residents, Icon: 'Resident' }])

  const handleOpenForm = (resident) => setForm({ isOpen: true, resident })
  const handleCloseForm = () => setForm({ isOpen: false, resident: null })

  const handleOpenChangePasswordForm = (resident) => setChangePasswordForm({ isOpen: true, resident: resident })
  const handleCloseChangePasswordForm = () => setChangePasswordForm({ isOpen: false, resident: null })

  const handleOpenNotifyForm = (residents) => setNotifyForm({ isOpen: true, residents })
  const handleCloseNotifyForm = () => setNotifyForm({ isOpen: false, residents: null })

  const handleUpdateResident = async (fields) => {
    try {
      const response = await updateResident(fields).unwrap()
      toast.success(t('residents:success.ResidentUpdated', { name: fields.user.username }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('residents:errors.UpdateResidentError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (residents) => {
    try {
      const ids = residents.map(R.path(['user', 'id']))
      const promises = ids.map(async (id) => {
        return deleteUser(id).unwrap()
      })
      await Promise.all(promises)
      toast.success(
        t('residents:success.ResidentDeleted', {
          count: residents.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('residents:errors.DeleteResidentError', {
          error: err.data?.detail || t('common:errors.UnknownError'),
          count: residents.length,
        }),
      )
    }
  }

  const handleSaveChangePassword = async (userId, { password }) => {
    try {
      const response = await changeUserPassword({ userId, password }).unwrap()
      toast.success(t('residents:success.ResidentPasswordChanged'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('auth:errors.ChangeUserPasswordError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleNotify = async (fields) => {
    try {
      const response = await createNotification({
        ...fields,
        date: moment(fields.date).format('YYYY-MM-DD'),
        text: draftToHtml(convertToRaw(fields.text.getCurrentContent())),
        residentIds: notifyForm.residents.map(R.path(['extra', 'id'])),
      }).unwrap()
      toast.success(t('residents:success.ResidentNotificationStarted', { count: notifyForm.residents.length }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t('residents:errors.NotifyResidentError', {
          count: notifyForm.residents.length,
          error: responseStringError(error),
        }),
      )
      return { error, isSuccessful: false }
    }
  }

  return (
    <Main>
      <ResidentsList
        onEdit={handleOpenForm}
        onDelete={handleDelete}
        onNotify={handleOpenNotifyForm}
        onChangePassword={handleOpenChangePasswordForm}
      />
      {form.isOpen && <ResidentForm resident={form.resident} onClose={handleCloseForm} onSave={handleUpdateResident} />}
      {notifyForm.isOpen && <NotificationForm onClose={handleCloseNotifyForm} onSave={handleNotify} />}
      {changePasswordForm.isOpen && (
        <ChangePasswordForm
          user={changePasswordForm.resident.user}
          onClose={handleCloseChangePasswordForm}
          onSave={handleSaveChangePassword}
        />
      )}
    </Main>
  )
}

export default AdminResidentsView
