import { FormControl, FormLabel, Grid, RadioGroup } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DatePicker from '@Common/Components/DatePicker'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import Radio from '@Common/Components/Radio'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import CondosSelectField from '@Condo/Components/CondosSelectField'
import { LegalPracticesPresenceTypes, ReconciliationReminderType } from '@Condo/Models/ReconciliationReminder'

import { validate } from './Validation'

const ReconciliationReminderForm = ({ reconciliationReminder, onClose, onSave, showSelectBuilding = true }) => {
  const { t } = useTranslation()

  const title = reconciliationReminder?.id
    ? t('condo:ui.EditReconciliationReminder')
    : t('condo:ui.CreateReconciliationReminder')

  const [isSubmitting, setIsSubmitting] = useState(false)

  // hooks
  const { fields, setField, errors, setErrors, clearError } = useForm(
    {
      ...reconciliationReminder,
      legalPracticesPresence: reconciliationReminder?.legalPracticesPresence || LegalPracticesPresenceTypes[0].id,
    },
    true,
  )

  // handle submit form
  const submit = async () => {
    const isValid = validate(fields, setErrors)

    if (!isValid) return

    setIsSubmitting(true)

    const payload = buildPayload(fields)
    const { error, isSuccessful } = await onSave(payload)

    setIsSubmitting(false)

    if (!isSuccessful) {
      return setErrors(convertServerErrors(error))
    }

    return onClose()
  }

  // format date
  const formatDate = (date) => (date ? dayjs(date).format('YYYY-MM-DD') : null)

  // transform fields for API
  const buildPayload = (fields) => {
    const { lastReconciliationDate, lastReminderDate } = fields
    const payload = {
      ...fields,
      lastReconciliationDate: lastReconciliationDate ? formatDate(lastReconciliationDate) : null,
      lastReminderDate: lastReminderDate ? formatDate(lastReminderDate) : null,
    }

    return payload
  }

  return (
    <Modal title={title} size="lg" onClose={onClose} onSubmit={submit} sx={{ paddingTop: 0 }}>
      {isSubmitting && <Loader overlay />}
      <Box>
        <Grid container rowSpacing={3} spacing={2}>
          {showSelectBuilding && (
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <CondosSelectField
                  value={fields.building}
                  label={t('condo:ui.Buildings')}
                  onChange={setStateFromEvent(setField('building'))}
                  error={!!errors.building}
                  helperText={errors.building}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={fields?.bank}
                label={t('condo:reconciliationReminder.fields.bank')}
                onChange={setStateFromEvent(setField('bank'))}
                onBlur={() => clearError('bank')}
                onInput={() => clearError('bank')}
                error={!!errors.bank}
                helperText={errors.bank}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel>{t('condo:reconciliationReminder.fields.legalPracticesPresence')} *</FormLabel>
              <RadioGroup
                row
                name="legalPracticesPresence"
                value={fields.legalPracticesPresence}
                onChange={setStateFromEvent(setField('legalPracticesPresence'))}
              >
                {LegalPracticesPresenceTypes.map((item) => (
                  <Radio key={item.id} value={item.id} label={item.label} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <DatePicker
                fullWidth
                label={t('condo:reconciliationReminder.fields.lastReconciliationDate')}
                value={fields.lastReconciliationDate || null}
                onChange={setField('lastReconciliationDate')}
                error={!!errors.lastReconciliationDate}
                helperText={errors.lastReconciliationDate}
                maxDate={dayjs()}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <DatePicker
                fullWidth
                label={t('condo:reconciliationReminder.fields.lastReminderDate')}
                value={fields.lastReminderDate || null}
                onChange={setField('lastReminderDate')}
                error={!!errors.lastReminderDate}
                helperText={errors.lastReminderDate}
                maxDate={dayjs()}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                rows={5}
                value={fields?.notes}
                label={t('condo:reconciliationReminder.fields.notes')}
                onChange={setStateFromEvent(setField('notes'))}
                onBlur={() => clearError('notes')}
                onInput={() => clearError('notes')}
                error={!!errors.notes}
                helperText={errors.notes}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

ReconciliationReminderForm.propTypes = {
  reconciliationReminder: ReconciliationReminderType.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showSelectBuilding: PropTypes.bool.isRequired,
}

export { ReconciliationReminderForm }
