import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import { Box } from '@Common/Components/Styles'
import { setStateFromEvent } from '@Common/Utils/Events'

const BuildingDocumentsTypeSelect = ({ selected, setSelected, ...other }) => {
  const { t } = useTranslation()

  return (
    <Box direction="row" align="center">
      <Typography marginRight={3} fontWeight={600} variant="body1">
        {t('documents:ui.DocumentsType')}
      </Typography>
      <Dropdown
        required
        hideAsterisk
        width="220px"
        labelKey="label"
        valueKey="id"
        value={selected}
        onChange={setStateFromEvent(setSelected)}
        {...other}
      />
    </Box>
  )
}

BuildingDocumentsTypeSelect.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
}

export default BuildingDocumentsTypeSelect
