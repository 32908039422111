import { Box, Cursor, Margin } from '@Common/Components/Styles'
import { responseStringError } from '@Common/Utils/Api'
import { Button, Avatar, Icon, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import { useReadNotificationMutation } from '@Notifications/Services/Api'
import { isRead } from '@MyEstia/Utils/Notifications'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import dayjs from 'dayjs'
import * as R from 'ramda'

const Time = styled.time`
  color: ${({ theme }) => theme.palette.accentThree.main};
  display: block;
  margin-top: 0.2rem;
  font-style: italic;
`

const Text = styled.span`
  color: ${({ theme }) => theme.palette.darkThree.main};
  font-size: 0.9rem;
`

const Notification = ({ notification }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState(false)
  const [readNotification] = useReadNotificationMutation()
  const toggleExpand = () => {
    R.not(isRead(notification)) && handleRead()
    setExpanded(!expanded)
  }

  const handleRead = async () => {
    try {
      await readNotification(notification.id).unwrap()
    } catch (error) {
      toast.error(t(`common:errors.ReadNotificationError`, { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  // const handleCancel = async () => {
  //   try {
  //     await cancelNotification(notification.id).unwrap()
  //   } catch (error) {
  //     toast.error(t(`common:errors.CancelNotificationError`, { error: responseStringError(error) }))
  //     return { error, isSuccessful: false }
  //   }
  // }

  const content = () => {
    if (expanded) {
      return (
        <Box>
          <Margin bottom=".5rem">
            <Text dangerouslySetInnerHTML={{ __html: notification.text }} />
          </Margin>
          {notification.link && (
            <Box direction="row" align="center" margin="0 0 .5rem">
              <Button
                variant="contained"
                startIcon={<LinkIcon />}
                size="small"
                onClick={() => window.open(notification.link)}
              >
                {R.defaultTo(notification.link, notification.linkText)}
              </Button>
            </Box>
          )}
          {/*
          <Box direction="row" justify="flex-end" width={'100%'}>
            <Tooltip title={t('common:ui.Remove')}>
              <IconButton onClick={handleCancel}>
                <Clear color="error" />
              </IconButton>
            </Tooltip>
          </Box>
          */}
        </Box>
      )
    } else {
      return (
        <Margin bottom=".5rem">
          <Text
            dangerouslySetInnerHTML={{
              __html:
                notification.text.replace(/<[^>]*>/gi, '').substr(0, 48) + (notification.text.length > 48 ? '...' : ''),
            }}
          />
        </Margin>
      )
    }
  }

  return (
    <ListItem alignItems="flex-start" onClick={toggleExpand}>
      <ListItemAvatar>
        <Avatar>
          <Icon>{notification.topicObj?.materialIcon}</Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Margin bottom=".5rem">
            <Cursor type="pointer" style={{ fontWeight: isRead(notification) ? 'normal' : 'bold' }}>
              {notification.title}
            </Cursor>
            <Time>{dayjs(notification.created).format('LLL')}</Time>
          </Margin>
        }
        secondary={content()}
      />
    </ListItem>
  )
}

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    status: PropTypes.number,
    topicObj: PropTypes.shape({
      materialIcon: PropTypes.string,
      name: PropTypes.string,
    }),
    created: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
  }),
}

export default Notification
