import { useBuildingQuery } from '@Buildings/Services/Api'
import { Margin } from '@Common/Components/Styles'
import { withLoader } from '@Common/Utils/HOC'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { Divider, Typography } from '@mui/material'
import { selectActiveBuildingId, selectActiveHousingUnitId } from '@MyEstia/Redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import styled from 'styled-components'

const Main = styled.div`
  padding: ${({ mobile }) => (mobile ? '.5rem' : '2rem')};
  padding-bottom: 150px;
`

const MyHomeMainView = () => {
  const buildingId = useSelector(selectActiveBuildingId)

  const { data: building, isFetching } = useBuildingQuery(buildingId)
  const housingUnitId = useSelector(selectActiveHousingUnitId)
  const user = useCurrentUser()
  const housingUnit = user?.resident?.raw?.unitaImmobiliari?.filter((u) => u.id === housingUnitId).shift()

  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const { t } = useTranslation()

  return (
    <Main mobile={isSmallDevice}>
      {withLoader(
        <Margin bottom="2rem">
          <Typography variant="h6" margin="1rem" marginBottom="0.5rem" textAlign="left">
            {t('myHome:ui.Building')}
          </Typography>
          <Divider
            sx={{
              borderColor: 'primary.main',
            }}
          />
          <Typography
            variant="subtitle2"
            fontSize={20}
            margin="1rem"
            marginBottom="0.5rem"
            textAlign="left"
            color="primary.main"
          >
            {building?.denominazione}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Indirizzo: </strong>
            {building?.indirizzo?.via +
              ', ' +
              building?.indirizzo?.civico +
              ', ' +
              building?.indirizzo?.comune +
              ', ' +
              building?.indirizzo?.cap}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Scala: </strong>
            {housingUnit?.scala?.descrizione || '-'}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Piano: </strong>
            {housingUnit?.piano || '-'}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Numero unità immobiliare: </strong>
            {housingUnit?.codice || '-'}
          </Typography>

          <Divider
            sx={{
              marginTop: '1rem',
              borderColor: 'primary.main',
            }}
          />
          <Typography
            variant="subtitle2"
            fontSize={20}
            margin="1rem"
            marginBottom="0.5rem"
            textAlign="left"
            color="primary.main"
          >
            {t('myHome:ui.RegistrationData')}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Comune: </strong> {building?.datiCatastali?.comuneCatastale}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Codice comune: </strong> {building?.datiCatastali?.codiceComune}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Sezione urbana: </strong> {building?.datiCatastali?.sezioneUrbana || '-'}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Foglio: </strong>
            {building?.datiCatastali?.foglio || '-'}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Particella: </strong>
            {building?.datiCatastali?.particella || '-'}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Estensione particella: </strong>
            {building?.datiCatastali?.estensioneParticella || '-'}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Subalterno: </strong>
            {building?.datiCatastali?.subalterno || '-'}
          </Typography>

          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Interno: </strong>
            {housingUnit?.interno || '-'}
          </Typography>
          <Divider
            sx={{
              marginTop: '1rem',
              borderColor: 'primary.main',
            }}
          />
          <Typography
            fontSize={20}
            variant="subtitle2"
            margin="1rem"
            marginBottom="0.5rem"
            textAlign="left"
            color="primary.main"
          >
            {t('myHome:ui.Reference')}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Filiale:</strong> {building?.extra?.branchObj?.name}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Indirizzo:</strong> {building?.extra?.branchObj?.address}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Telefono:</strong> {building?.extra?.branchObj?.phone}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Email:</strong> {building?.extra?.branchObj?.email}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>Amministratore referente:</strong>{' '}
            {building?.extra?.administratorObj?.firstName + ' ' + building?.extra?.administratorObj?.lastName}
          </Typography>
          <Typography variant="subtitle1" marginLeft="1rem" textAlign="left">
            <strong>PEC:</strong> estia-amministra@pec.it
          </Typography>
        </Margin>,
        isFetching,
      )}
      {
        // Indirizzo, Numero civico, Interno, Città, CAP
        // Scala, Piano, Numero unità immobiliare
        // Dati catastali
        // Comune catastale, Codice comune, Sezione urbana
        // Foglio, Particella, Estensione particella
        // Subalterno, Interno
        // Referente
        // Nome, Cognome (dal gg/mm/aaaa)
      }
    </Main>
  )
}

export default MyHomeMainView
