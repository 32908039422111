import * as R from 'ramda'
import { useSelector } from 'react-redux'

import { selectBreadcrumbs } from '@Core/Redux/Ui'

import { iconsMap as adminIconMap } from './AdminBreadcrumbs'
import Breadcrumbs from './Breadcrumbs'
import { Box } from './Styles'

const iconsMap = {
  // we want to share the same icons with admin
  ...adminIconMap,
}

const OperationalBreadcrumbs = () => {
  const breadcrumbs = useSelector(selectBreadcrumbs)

  return (
    <Box pad="1rem" background="lightOne">
      <Breadcrumbs items={breadcrumbs.map(R.modify('Icon', (iconName) => iconsMap[iconName]))} />
    </Box>
  )
}

export default OperationalBreadcrumbs
