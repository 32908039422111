import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { defaultTo } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Alert from '@Common/Components/Alert'
import Checkbox from '@Common/Components/Checkbox'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromCheckboxEvent, setStateFromEvent } from '@Common/Utils/Events'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { FormControl } from '@Tms/Components/Styled'
import TaskFieldChoiceList from '@Tms/Components/TaskFieldChoiceList'
import RequiredTypeSelect from '@Tms/Forms/Selects/RequiredTypeSelect'
import DateTypeOptions from '@Tms/Forms/TaskFieldForm/DateTypeOptions'
import FieldTypeSelect from '@Tms/Forms/TaskFieldForm/FieldTypeSelect'
import { TaskFieldType } from '@Tms/Models/TaskField'
import { getEmptyChoice, getNewChoiceId, getNewFieldId } from '@Tms/Utils'

import { validate } from './Validation'

const isSelectField = (type) => type === 2
const isDateField = (type) => type === 3

const TaskFieldForm = ({ field, onSave, onClose }) => {
  const { t } = useTranslation()
  const title = t(`tms:ui.${field?.id ? 'Edit' : 'Create'}TaskField`)
  const { getValues } = useFormContext()
  const [requiredType, setRequiredType] = useState(-1)

  useEffect(() => {
    if (field) {
      setRequiredType(!field.isRequired ? -1 : field.requiredType)
    }
  }, [field])

  const handleRequiredType = (type) => {
    setRequiredType(type)
    setFields({ ...fields, isRequired: type !== -1, requiredType: type !== -1 ? type : null })
  }

  const handleOnAddChoice = () => {
    // adding new field
    const newChoiceId = getNewChoiceId(fields.choices)
    const newChoice = { ...getEmptyChoice(), id: newChoiceId, key: newChoiceId }
    // add new choice to choices array
    setFields({ ...fields, choices: [newChoice, ...fields.choices] })
  }
  const handleOnChangeChoice = (fieldName, choice) => (value) => {
    setFields({
      ...fields,
      choices: fields.choices.map((c) => (c.key === choice.key ? { ...choice, [fieldName]: value } : c)),
    })
  }
  const handleDeleteChoice = ({ key }) => setFields({ ...fields, choices: fields.choices.filter((c) => c.key !== key) })

  const { fields, setField, setFields, errors, setErrors } = useForm(
    {
      id: defaultTo(getNewFieldId(getValues('mainTask')), field?.id),
      name: defaultTo(null, field?.name),
      type: defaultTo(0, field?.type),
      isRequired: defaultTo(false, field?.isRequired),
      requiredType: defaultTo(null, field?.requiredType),
      isInternal: defaultTo(false, field?.isInternal),
      helperText: defaultTo(null, field?.helperText),
      isDateInPast: defaultTo(false, field?.isDateInPast),
      isDateInFuture: defaultTo(false, field?.isDateInFuture),
      sendCalendar: defaultTo(false, field?.sendCalendar),
      choices: defaultTo(
        [],
        field?.choices.map((c) => ({ ...c, key: c.id })),
      ),
    },
    true,
  )

  const submit = () => {
    logger.debug('TaskField form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('TaskField form submission, validation passed, saving')
      onSave(fields, !!field)
    } else {
      logger.debug('TaskField form submission, validation failed')
    }
  }

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      <Box>
        {errors.__form && <Alert level="error">{errors.__form}</Alert>}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl>
              <TextField
                required
                label={t('tms:fields.taskFieldName')}
                value={fields.name}
                onChange={setStateFromEvent(setField('name'))}
                error={!!errors.name}
                helperText={errors.name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <RequiredTypeSelect
                required
                value={requiredType}
                label={t('tms:fields.isRequired.__field')}
                onChange={setStateFromEvent(handleRequiredType)}
                error={!!errors.isRequired}
                helperText={errors.isRequired}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <TextField
                label={t('tms:fields.helperText')}
                value={fields.helperText}
                onChange={setStateFromEvent(setField('helperText'))}
                error={!!errors.helperText}
                helperText={errors.helperText}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth={false}>
              <Checkbox
                checked={fields.isInternal}
                onChange={setStateFromCheckboxEvent(setField('isInternal'))}
                label={t('tms:fields.isInternal')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl>
              <FieldTypeSelect
                required
                value={fields.type}
                label={t('tms:fields.type.__field')}
                onChange={setStateFromEvent(setField('type'))}
                error={!!errors.type}
                helperText={errors.type}
              />
            </FormControl>
          </Grid>
          {isSelectField(fields?.type) && (
            <Grid item xs={12}>
              <TaskFieldChoiceList
                choices={fields.choices}
                onAdd={handleOnAddChoice}
                onChange={handleOnChangeChoice}
                onDelete={handleDeleteChoice}
              />
            </Grid>
          )}
          {isDateField(fields?.type) && (
            <Grid item xs={12}>
              <DateTypeOptions fields={fields} errors={errors} setField={setField} setFields={setFields} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  )
}

TaskFieldForm.propTypes = {
  field: TaskFieldType,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
}

export default TaskFieldForm
