import dayjs from 'dayjs'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import ArcadiaBuildingsList from '@Buildings/Components/ArcadiaBuildingsList'
import BranchBuildingForm from '@Buildings/Forms/BranchBuildingForm'
import {
  useCreateMultiBranchBuildingMutation,
  useDeleteBranchBuildingMutation,
  useUpdateBranchBuildingMutation,
} from '@Buildings/Services/Api'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import config from '@Config'
import NotificationForm from '@Notifications/Forms/NotificationForm'
import { useCreateBuildingsNotificationsMutation } from '@Notifications/Services/Api'

const ArcadiaBuildingsView = () => {
  const { t } = useTranslation()

  // api
  const [createMultiBranchBuilding] = useCreateMultiBranchBuildingMutation()
  const [updateBranchBuilding] = useUpdateBranchBuildingMutation()
  const [deleteBranchBuilding] = useDeleteBranchBuildingMutation()
  const [createNotification] = useCreateBuildingsNotificationsMutation()

  // form
  const [form, setForm] = React.useState({ isOpen: false, buildings: null, isEditing: false })
  const [notifyForm, setNotifyForm] = React.useState({ isOpen: false, buildings: null })

  useBreadcrumbs([
    {
      label: t('common:navigation.BuildingsManagement'),
      path: config.urls.admin.buildings,
      Icon: 'BuildingManagement',
    },
  ])

  const handleOpenCreateForm = (buildings) => setForm({ isOpen: true, buildings, isEditing: false })
  const handleOpenEditForm = (buildings) => setForm({ isOpen: true, buildings, isEditing: true })
  const handleCloseForm = () => setForm({ isOpen: false, buildings: null, isEditing: false })

  const handleOpenNotifyForm = (buildings) => setNotifyForm({ isOpen: true, buildings })
  const handleCloseNotifyForm = () => setNotifyForm({ isOpen: false, buildings: null })

  const handleSave = (fields) => {
    return form.isEditing ? handleUpdateBranchBuilding(fields) : handleCreateBranchBuilding(fields)
  }

  const handleCreateBranchBuilding = async (fields) => {
    try {
      const response = await createMultiBranchBuilding(fields).unwrap()
      toast.success(
        t('buildings:success.BranchBuildingCreated', {
          count: form.buildings.length,
          name: form.buildings[0].denominazione,
        }),
      )
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('buildings:errors.CreateBranchBuildingError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateBranchBuilding = async (fields) => {
    try {
      const response = await updateBranchBuilding(fields).unwrap()
      toast.success(t('buildings:success.BranchBuildingUpdated', { name: form.buildings[0].denominazione }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('buildings:errors.UpdateBranchBuildingError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (building) => {
    try {
      const response = await deleteBranchBuilding(building.extra.id).unwrap()
      toast.success(t('buildings:success.BranchBuildingDeleted', { name: building.denominazione }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('buildings:errors.DeleteBranchBuildingError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleNotify = async (fields) => {
    try {
      const response = await createNotification({
        ...fields,
        date: dayjs(fields.date).format('YYYY-MM-DD'),
        text: draftToHtml(convertToRaw(fields.text.getCurrentContent())),
        arcadiaIds: notifyForm.buildings.map(R.prop('id')),
      }).unwrap()
      toast.success(t('notifications:success.BuildingNotificationStarted', { count: notifyForm.buildings.length }))
      return { response, isSuccessful: true }
    } catch (error) {
      Logger.error('Notify buildings error', error)
      toast.error(
        t('notifications:errors.NotifyBuildingError', {
          count: notifyForm.buildings.length,
          error: responseStringError(error),
        }),
      )
      return { error, isSuccessful: false }
    }
  }

  return (
    <>
      <ArcadiaBuildingsList
        onCreate={handleOpenCreateForm}
        onEdit={handleOpenEditForm}
        onDelete={handleDelete}
        onNotify={handleOpenNotifyForm}
      />
      {form.isOpen && (
        <BranchBuildingForm
          buildings={form.buildings}
          isEditing={form.isEditing}
          onSave={handleSave}
          onClose={handleCloseForm}
        />
      )}
      {notifyForm.isOpen && <NotificationForm onClose={handleCloseNotifyForm} onSave={handleNotify} />}
    </>
  )
}

export default ArcadiaBuildingsView
