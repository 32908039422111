import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTable from '@Common/Components/DataTable'
import { useConfirm } from '@Common/Utils/Hooks'

import Category from '../../Models/Category'
import { CategoryPermissions } from '../../Permissions'
import { useServiceCategoriesQuery } from '../../Services/Api'

const DISPLAY_COLUMNS = ['name', 'slug', 'parentName', 'orderingNumber', 'logo']

const SEARCH_FIELDS = ['name']

const FIELDS_MAPPING = {
  // eslint-disable-next-line react/display-name
  name: (record) => {
    const tree = [...Array(record.parentCount)].map(R.always('&mdash;')).join(' ')
    return <span dangerouslySetInnerHTML={{ __html: `${tree} ${record.name}` }}></span>
  },
  // eslint-disable-next-line react/display-name
  logo: (record) => <img src={record.logo} style={{ height: '40px' }} />,
}

const CategoryList = ({ onEdit, onDelete, onCreateChild }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [manipulatedData, setManipulatedData] = React.useState([])

  const orderData = (data) => {
    const map = R.groupBy((data) => data.parent)
    return mapIterator(map(data), null, 0)
  }

  const mapIterator = (map, index, parentCount) => {
    let result = []
    for (let category in map[index]) {
      let temporary = {}
      temporary.parentCount = parentCount
      for (let attr in map[index][category]) {
        temporary[attr] = map[index][category][attr]
      }
      result.push(temporary)
      result = result.concat(mapIterator(map, map[index][category].id, parentCount + 1))
    }
    return result
  }

  // default qs
  const qs = {
    base: {
      ordering: 'ordering_number',
    },
  }

  // data results length

  // new qs triggers refetch
  const { data, isFetching } = useServiceCategoriesQuery(qs)
  React.useEffect(() => {
    if (data?.results?.length) {
      setManipulatedData(orderData(data?.results))
    }
  }, [JSON.stringify(R.defaultTo('', data?.results))])

  const handleDeleteCategory = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteCategory)
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: CategoryPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: CategoryPermissions.delete,
      },
      {
        id: 'CREATE',
        label: t('common:actions.NewChild'),
        icon: <AddIcon size="small" />,
        perm: CategoryPermissions.create,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, categories) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('services:ui.DeleteCategoriesConfirmationTitle'),
          t('services:ui.DeleteCategoryConfirmationText', { count: selected.length }),
          { selected: selected, field: 'name' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(categories)
      } else if (actionId === 'CREATE') {
        onCreateChild(categories)
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      <DataTable
        name="admin-categories"
        selectable
        data={manipulatedData || []}
        model={Category}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        noExport
        noSettings
        noPagination
        avoidSorting
      />
      {DeleteConfirm}
    </div>
  )
}

CategoryList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreateChild: PropTypes.func.isRequired,
}

export default CategoryList
