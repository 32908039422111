import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { responseStringError } from '@Common/Utils/Api'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import CategoryForm from '@Tms/Forms/CategoryForm'
import {
  useCreateCategoryMutation,
  useDeleteCategoryDraftMutation,
  useLazyCategoryQuery,
  useSaveCategoryDraftMutation,
  useUpdateCategoryMutation,
} from '@Tms/Services/Api'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminTaskCategoryFormView = () => {
  const { t } = useTranslation()
  const { category } = useParams()
  // api
  // const { data, isLoading } = useCategoryQuery(category, { skip: !category })
  const [fetchData, { data, isLoading }] = useLazyCategoryQuery()
  const [createCategory] = useCreateCategoryMutation()
  const [saveCategoryDraft] = useSaveCategoryDraftMutation()
  const [deleteCategoryDraft] = useDeleteCategoryDraftMutation()
  const [updateCategory] = useUpdateCategoryMutation()

  useBreadcrumbs([
    { label: t('common:navigation.Tms'), path: makePath('tms.taskList'), Icon: 'Tms' },
    { label: t('common:navigation.TmsCategories'), path: makePath('tms.categories') },
    {
      label: t(`tms:ui.${category ? 'Edit' : 'Create'}Category`),
      path: makePath('tms.categories'),
    },
  ])

  const handleOnCancel = () => history.push(makePath('tms.categories'))

  // form save
  const handleOnSave = (payload) => (category ? handleUpdateCategory(payload) : handleCreateCategory(payload))

  const handleCreateCategory = async (fields) => {
    try {
      const response = await createCategory(fields).unwrap()
      toast.success(t('tms:success.CategoryCreated', { name: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('tms:errors.CreateCategoryError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateCategory = async (fields) => {
    try {
      const response = await updateCategory(fields).unwrap()
      fetchData(category)
      toast.success(t('tms:success.CategoryUpdated', { name: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('tms:errors.UpdateCategoryError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleSaveCategoryDraft = async (fields) => {
    try {
      const response = await saveCategoryDraft({ ...fields, id: category }).unwrap()
      fetchData(category)
      return { response, isSuccessful: true }
    } catch (error) {
      return { error, isSuccessful: false }
    }
  }

  const handleDeleteCategoryDraft = async () => {
    try {
      Logger.debug('handleDeleteCategoryDraft', category)
      const response = await deleteCategoryDraft(category).unwrap()
      fetchData(category)
      return { response, isSuccessful: true }
    } catch (error) {
      return { error, isSuccessful: false }
    }
  }

  useEffect(() => {
    if (category) fetchData(category)
  }, [category])

  return (
    <Main>
      {withLoader(
        <CategoryForm
          category={data}
          onCancel={handleOnCancel}
          onSave={handleOnSave}
          onAutoSave={handleSaveCategoryDraft}
          onDeleteDraft={handleDeleteCategoryDraft}
        />,
        category && (isLoading || !data),
      )}
    </Main>
  )
}

export default AdminTaskCategoryFormView
