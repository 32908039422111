import PropTypes from 'prop-types'
import styled from 'styled-components'

const Tr = styled.tr`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 0.75rem;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      display: table-row;    
    }
  `}
`

const Th = styled.th`
  padding-right: 1rem;
  text-align: left;
  min-width: 160px;
  vertical-align: top;
  padding-top: 4px;

  ${({ theme, align }) => `
    ${theme.breakpoints.up('md')} {
      text-align: ${align};
    }
  `}
`

const NameValueList = ({ data, nameAlign }) => {
  return (
    <table>
      <tbody>
        {data.map(({ name, value }) => (
          <Tr key={name}>
            <Th align={nameAlign}>{name}</Th>
            <td>{value}</td>
          </Tr>
        ))}
      </tbody>
    </table>
  )
}

NameValueList.defaultProps = {
  width: '100%',
  nameAlign: 'right',
}

NameValueList.propTypes = {
  data: PropTypes.array,
  nameAlign: PropTypes.oneOf(['right', 'left']),
  width: PropTypes.string,
}

export default NameValueList
