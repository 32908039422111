import * as React from 'react'

const WorksIcon = (props) => (
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="-40 -40 280 280"
    style={{
      enableBackground: 'new 0 0 205 200',
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{'.st1{fill:#fcbd2e}'}</style>
    <path
      d="M198.4 40.6c1.6.5 3.2.8 4.8 1.3 5.1 1.6 10.1 2.9 15.2 4.5 5.9 1.9 11.5 3.7 17.3 5.3 4 1.1 7.2 3.2 10.1 6.1 15.7 15.7 31.5 31.5 47.2 47.5 1.9 1.9 1.9 4.5 0 6.4-3.5 3.5-6.9 7.2-10.7 10.7-2.7 2.7-5.6 5.3-8.3 8l-8.8 8.8c-1.9 1.9-4 1.9-5.9 0-6.7-6.7-13.6-13.6-20.3-20.5-1.6-1.6-4-1.6-5.9 0C185.3 167 137.1 215 89 263c-2.4 2.4-4.5 1.9-6.4 0-8.5-8.8-17.3-17.3-26.1-26.1-1.9-1.9-1.9-4.3 0-5.9L201.1 86.5c1.1-1.1 1.9-2.4 1.3-4-.3-.5-.5-1.1-1.1-1.6-6.9-6.9-13.6-13.9-20.5-20.5-2.1-2.1-1.3-5.3.5-6.7.8-.5 1.1-1.3 1.6-1.9 1.6-1.6 3.2-2.9 4.5-4.5 1.3-1.3 2.9-2.9 4.3-4.5 1.6-1.6 3.2-2.9 5.6-2.4 1.1.5 1.1.2 1.1.2z"
      style={{
        fill: '#4071b6',
      }}
      transform="translate(-34.96 -32.803) scale(.81482)"
    />
    <path
      className="st1"
      d="M199.2 165.7c2.9 2.9 5.9 6.1 9.1 9.1l24 24c2.1 2.1 4.8 2.7 7.7 2.1 2.4-.5 4.8-.8 7.2-1.1 3.5-.5 6.7.5 9.9 2.4 3.5 1.9 6.9 3.5 10.7 5.3 2.1 1.1 4.3 2.4 6.4 3.7 2.1 1.3 3.2 3.5 4.3 5.6 1.6 3.2 3.2 6.1 4.5 9.1.8 1.3 1.6 2.9 2.1 4.3.8 1.6 1.6 3.2 2.1 5.1.8 2.1.3 4.5 0 6.7-.3 2.4-.8 4.8-1.1 7.2-.3 1.6-.5 3.2-1.1 4.8-.5 1.9-1.6 2.1-2.9 1.1l-24.8-24.8c-1.3-1.3-3.5-1.3-4.8 0l-20.3 20.3c-1.6 1.6-1.6 3.5 0 5.1l24 24c.8.8 1.6 1.6 1.1 2.7s-1.6 1.3-2.7 1.6c-2.9.5-5.9 1.1-9.1 1.3-2.9.3-5.6.5-8.5.5-1.1 0-1.9-.5-2.9-1.1-4.8-2.4-9.9-4.8-14.7-7.5-1.6-.8-3.2-1.6-4.5-2.7-1.1-.8-2.1-1.9-2.9-3.2-1.9-3.5-3.7-7.2-5.6-10.9-1.3-2.7-2.9-5.6-4.3-8.3-1.1-2.4-1.3-4.8-.5-7.5.5-2.4.8-4.5 1.1-6.9 0-1.1 0-2.1-.3-3.2-.3-2.1-2.1-3.5-3.5-5.1-1.3-1.3-2.7-2.9-4.3-4.3-1.9-1.9-3.5-3.5-5.3-5.1-1.6-1.6-2.9-2.9-4.3-4.5l-8-8c-2.7-2.9-5.6-5.6-8.3-8.5-.3-.3-.5-.5-.8-.5-.5-.3-.5-.8 0-1.1l31.2-31.2c-.2-.2-.2-.2.1-.5zM42.9 88.6c.3-2.4.8-5.3 1.3-8.3.3-2.1.5-4.5 1.1-6.7 0-.8.5-1.6 1.1-1.9.8-.3 1.6 0 2.4.5 4 4 8 8 11.7 12l12.3 12.3c2.1 2.1 4.3 2.1 6.1 0l19.2-19.2c.8-.8 1.3-1.6 1.6-2.7.3-1.3-.3-2.4-1.3-3.2-2.7-2.7-5.3-5.6-8.3-8.3-2.4-2.4-4.5-4.5-6.9-6.7-3.2-3.2-6.4-6.4-9.6-9.9-1.1-1.1-.8-2.1.5-2.7 1.6-.5 3.2-.8 4.8-1.1 3.5-.5 6.9-1.1 10.4-1.6 3.2-.3 5.9.5 8.8 1.9 1.9 1.1 4 2.1 5.9 3.2 3.5 1.9 6.9 3.5 10.1 5.3 2.1 1.1 3.7 3.2 5.1 5.3 2.4 4.3 4.5 8.8 6.7 13.3 1.1 1.9 1.9 4 2.7 5.9.8 2.1.5 4.3.3 6.1-.3 2.7-1.1 5.3-1.1 8-.3 2.1.5 4.3 2.1 5.9l24 24c1.3 1.3 1.3 1.3 0 2.4-1.6 1.6-3.5 3.2-5.1 4.8-2.7 2.7-5.3 5.6-8 8.3-3.2 3.2-6.7 6.7-10.1 9.9-1.6 1.6-3.2 3.5-4.8 5.1l-2.4 2.4c-.5.5-.8.5-1.1 0l-24.5-24.5c-1.9-1.9-4.3-2.7-6.9-2.4-3.5.5-6.9 1.1-10.4 1.1-1.6 0-3.2-.5-4.8-1.1-5.6-2.7-10.7-5.6-16.3-8.3-3.2-1.6-5.3-3.7-6.9-6.7-2.1-4-4-8-6.1-12-1.1-2.1-2.1-4-2.9-6.1-.7-1.1-.7-2.4-.7-4.3z"
      transform="translate(-34.96 -32.803) scale(.81482)"
    />
  </svg>
)

export default WorksIcon
