/* eslint-disable react/prop-types */

/**
 * This component help us to render
 * item label and value from key
 */
import { Check, Close } from '@mui/icons-material'
import { Box, Chip, Grid, Link, Rating, Stack, Tooltip } from '@mui/material'
import { createElement, useState } from 'react'

import { formatter } from '@Common/Utils/Formatters'
import { getItemFieldFromContext } from '@Common/Utils/Hooks'
import { checkValueIsObject } from '@Common/Utils/Types'

export const ItemField = (props) => {
  const { name, label: propsLabel, hideEmpty, containerType, template } = props
  const { label: fieldLabel, value, schema, formattedValue } = getItemFieldFromContext(name)
  const label = propsLabel || fieldLabel

  if (!value && hideEmpty) return null

  return (
    <Field
      value={value}
      label={label}
      type={schema.type}
      formattedValue={formattedValue}
      containerType={containerType}
      template={template || null}
    />
  )
}

export const Field = (props) => {
  const { label, value, type, formattedValue, tiny, template, containerType } = props

  const renderField = (
    <FieldValue
      value={value}
      type={type}
      formattedValue={formattedValue}
      template={template}
      containerType={containerType}
    />
  )

  if (label && !tiny) {
    return (
      <tr>
        <td>
          <strong>{label}</strong>
        </td>
        <td>{renderField}</td>
      </tr>
    )
  }

  return renderField
}

export const FieldValue = (props) => {
  const { type, value, containerType: containerType = Stack, template: Template } = props
  const formattedValue = props.formattedValue || formatter(value, type)
  const [showMore, setShowMore] = useState(false)

  let containerProps = null

  if (containerType === Stack) {
    containerProps = { direction: 'row', spacing: 1 }
  }

  if (containerType === Grid) {
    containerProps = { container: true, direction: 'row', spacing: 1 }
  }

  if (Array.isArray(value)) {
    const lengthElement = value.length
    const limitView = showMore ? lengthElement : 4

    return createElement(
      !showMore ? containerType : Box,
      !showMore ? containerProps : { minWidth: '600px', marginTop: 1 },
      <>
        {value?.slice(0, limitView)?.map((item, index) => {
          const label = <FieldValue key={index} value={item} type={type} template={Template} />

          const returnComponent = (props) => <Chip size="small" key={index} label={label} {...props} />

          if (containerType === Grid) {
            return <Grid item>{returnComponent()}</Grid>
          }

          if (showMore) {
            return returnComponent({
              sx: { marginBottom: 1, marginRight: 1 },
            })
          }

          return returnComponent()
        })}
        {lengthElement > limitView ? (
          <Tooltip title={'Carica altro'}>
            <Chip
              size="small"
              label={'...'}
              onClick={() => {
                setShowMore(true)
              }}
            />
          </Tooltip>
        ) : (
          showMore && (
            <Tooltip title={'Mostra meno'}>
              <Chip
                sx={{ marginBottom: 1, marginRight: 1 }}
                size="small"
                color="info"
                label={'Mostra meno'}
                onClick={() => {
                  setShowMore(false)
                }}
              />
            </Tooltip>
          )
        )}
      </>,
    )
  }

  if (Template) {
    return <Template value={value} />
  }

  if (type === 'rating') {
    return <Rating value={value} readOnly />
  }

  if (type === 'boolean') {
    return value ? <Check color="success" /> : <Close color="error" />
  }

  if (type === 'email') {
    // if over value for prevent render link with wrong value
    return value ? <Link href={`mailto:${value}`}>{value}</Link> : formattedValue
  }

  if (type === 'phone') {
    // if over value for prevent render link with wrong value
    return value ? <Link href={`tel:${value}`}>{value}</Link> : formattedValue
  }

  if (type === 'url') {
    // if over value for prevent render link with wrong value
    return value ? (
      <Link href={value} target="_blank">
        {value}
      </Link>
    ) : (
      formattedValue
    )
  }

  if (checkValueIsObject(formattedValue)) {
    return <>{formattedValue?.metadata?.name || '-'}</>
  }

  return <>{formattedValue}</>
}
