import PropTypes from "prop-types";

const MyHomeIcon = (props) => (
    <svg
        id="Livello_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 181.5 181.5"
        style={{
            enableBackground: "new 0 0 181.5 181.5",
        }}
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        {...props}
    >
        <style>
            {
                ".sth0{fill:#4071b6}.sth1{fill:#060706}.sth2{fill:#fcbd2e}.sth3{fill:#fff}.sth4{fill:#263238}.sth8{fill:#282525}.sth9{fill:#b9c8d3}.sth10{fill:#a83383}.sth11{fill:#3e6fb1}"
            }
        </style>
        <path
            className="sth0"
            d="M107.9 102.6H91.5V83H70.8V58H53.7v41.4h-5V78H33.2v-.4H49V99h4.3V57.7h17.9v24.9h20.6v19.6h15.7V77.6h14.7v10.6h11.2V69.6h10.5v.4h-10.2v18.5h-11.9V77.9h-13.9z"
        />
        <path
            className="sth0"
            d="M75.8 49.4c.4 4.4.2 9.9 2.6 13.8 1.9 3.1 5.3 4.9 8.7 5.8 7.5 2.1 17.4 1.2 21.3-6.5 1.6-3.1 1.1-8.9-2.5-10.6-1.8-.9-3.9-.8-5.8-1.1-2.6-.3-4.6-1.5-6.1-3.6-2.9-4.2-2.9-7.6-4-12.4-.2-.9-.5-1.8-1.6-2.4-.9-.7-2-1.1-3.1-1.2-1-.2-2-.3-3-.1-2.1.5-3.9 2.8-5.5 4.3-1.5 1.5-2.1 2.9-2 5.1.4 2.9.7 5.9 1 8.9z"
        />
        <path
            className="sth1"
            d="M94.1 70c-2.3 0-4.7-.3-7-1-4.1-1.2-7.1-3.2-8.8-5.9-2-3.1-2.2-7.1-2.4-11-.1-1-.1-1.9-.2-2.8l-.3-3c-.2-2-.4-4-.5-6-.2-2.1.4-3.6 2-5.1l.9-.9c1.3-1.4 2.8-3 4.6-3.4.9-.2 1.9-.2 3.1.1 1.2.1 2.3.6 3.2 1.2 1 .7 1.4 1.6 1.6 2.4.3 1.1.5 2.2.6 3.2.6 3.2 1.1 6 3.4 9.2 1.4 2.1 3.4 3.3 6 3.6.5.1.9.1 1.4.1 1.5.1 3 .3 4.4.9 1.3.6 2.3 1.8 2.8 3.5.8 2.3.6 5.3-.4 7.2-1.8 3.5-5.1 5.9-9.4 7-1.6.6-3.3.7-5 .7zM75.8 49.4c.1.9.1 1.9.2 2.8.2 3.8.5 7.8 2.4 10.9 2.1 3.3 5.9 5 8.7 5.8 7.5 2.1 17.4 1 21.3-6.5 1-1.9 1.1-4.8.4-7.1-.6-1.6-1.5-2.8-2.8-3.4-1.4-.6-2.9-.8-4.4-.9-.5 0-.9-.1-1.4-.1-2.7-.3-4.7-1.5-6.1-3.7-2.2-3.2-2.8-6-3.4-9.3-.2-1-.4-2-.6-3.2-.2-.9-.5-1.7-1.5-2.4-.8-.6-1.9-1.1-3.1-1.2-1.1-.3-2.1-.3-3-.1-1.7.4-3.2 2-4.5 3.4l-.9.9c-1.6 1.5-2.1 2.9-1.9 5 .2 2 .4 4 .5 6-.1 1.1 0 2.1.1 3.1z"
        />
        <path
            className="sth2"
            d="M96.4 60.4c1.6 1.8 3.1 3.9 3.6 6 .5 2.2-.2 4.6-2.2 6-1.4.9-3.3 1.3-5.2 1.5-3.6.4-7.3.3-10.9.1 1 2.6 2.1 5.2 3.1 7.8.3.8.7 1.7 1.4 2.4 1.6 1.6 4.3 1.9 6.8 1.9 5.4 0 11-1 14.8-3.8 4.9-3.6 6.1-9.8 3.9-15.1s-7.3-9.6-13.2-12.5c-2.2-1.1-9.3-4.3-11.5-2.7 3 2.9 6.6 5.2 9.4 8.4z"
        />
        <path
            className="sth1"
            d="M93.1 86.2h-.2c-3.3 0-5.5-.7-6.8-1.9-.7-.7-1.1-1.6-1.4-2.4L81.5 74h.1c3.6.1 7.3.2 10.9-.1 1.6-.2 3.7-.5 5.1-1.5 1.9-1.3 2.7-3.5 2.2-5.9-.5-2.3-2.2-4.4-3.6-6-1.8-2-3.9-3.7-5.9-5.4l-3.6-3 .1-.1h.1c2.2-1.6 9.4 1.6 11.5 2.7 6.5 3.2 11.2 7.6 13.3 12.5 2.3 5.6.7 11.7-3.9 15.2-4.3 3.1-10.3 3.8-14.7 3.8zM81.7 74.1l3.1 7.7c.3.8.7 1.7 1.4 2.4 1.2 1.3 3.4 1.9 6.7 1.9h.2c4.4 0 10.3-.7 14.5-3.8 4.6-3.4 6.2-9.5 3.9-15-2-4.9-6.7-9.3-13.2-12.5-2.1-1-9.1-4.2-11.3-2.8 1.1 1 2.3 2 3.5 2.9 2 1.7 4.1 3.4 5.9 5.4 1.4 1.6 3.1 3.7 3.6 6.1s-.3 4.8-2.3 6c-1.5 1-3.6 1.3-5.2 1.5-3.5.4-7.2.3-10.8.2z"
        />
        <path
            className="sth0"
            d="M85.5 136.2c.4-9.1.7-17.4.7-17.5-.1-.6-5.7-44.3-5.7-44.3h17.7s6.3 34.4 7.3 43.3c.3 2.9.4 10.7.4 18.8-6.9-.1-13.6-.2-20.4-.3z"
        />
        <path
            className="sth1"
            d="M105.9 136.6c-7.3-.1-13.9-.2-20.4-.3h-.1v-.1c.6-14.3.7-17.4.7-17.5-.1-.6-5.5-42.5-5.7-44.3v-.1h17.8v.1c.1.3 6.3 34.5 7.3 43.3.2 2.5.4 8.8.4 18.9zm-20.4-.4c6.4.1 13 .2 20.2.3 0-9.9-.1-16.3-.4-18.7-1-8.6-7-41.6-7.3-43.2H80.5c.3 2 5.6 43.7 5.7 44.2l-.7 17.4z"
        />
        <path
            className="sth0"
            d="M88.5 133.1H88v-.5c.1-2.5.1-5 .2-7.5s.2-5 .2-7.5c0-5-.9-9.9-1.6-14.8-.7-5-1.4-9.9-2.1-14.9s-1.3-10-2-15c0-.1.1-.1.1 0 .9 5.6 1.7 11.2 2.6 16.9.7 5 1.4 9.9 2.1 14.9.4 2.5.7 4.9 1 7.4s.4 5 .3 7.4c0 4.5-.2 9-.3 13.6z"
        />
        <path
            className="sth1"
            d="M88.5 133.1h-.6v-.5c0-1.7.1-3.3.1-5l.1-2.5c0-.7 0-1.4.1-2.2.1-1.8.1-3.6.1-5.3 0-4-.6-8.1-1.2-12-.1-.9-.3-1.9-.4-2.8l-.4-2.6c-.6-4-1.2-8.2-1.7-12.3-.7-4.9-1.3-9.7-2-15 0-.1 0-.1.1-.1s.1 0 .2.1l.4 2.4c.7 4.7 1.5 9.6 2.2 14.4.7 4.9 1.4 9.9 2.1 14.9.1.7.2 1.3.3 2 .3 1.8.5 3.6.7 5.4.3 2.3.4 4.7.3 7.5-.1 4.2-.3 8.5-.4 12.6v1zm-.5-.1h.4v-1c.1-4.1.3-8.4.4-12.6.1-2.7 0-5.2-.3-7.4-.2-1.8-.5-3.6-.7-5.4-.1-.7-.2-1.3-.3-2-.7-5-1.4-9.9-2.1-14.9-.7-4.8-1.5-9.7-2.2-14.4l-.3-1.8c.7 5.1 1.3 9.7 1.9 14.4.5 4.1 1.1 8.3 1.7 12.3l.4 2.6c.1.9.3 1.9.4 2.8.6 3.9 1.2 8 1.2 12.1 0 1.8-.1 3.6-.1 5.4 0 .7-.1 1.4-.1 2.2l-.1 2.5c-.1 1.7-.1 3.3-.1 5l-.1.2z"
        />
        <path
            className="sth0"
            d="M89.1 128.3c-.6 1.8-2.1 4.6-4 7.9-7.8-.1-15.5-.3-23.3-.6 3.8-5.7 7.7-11.8 8.2-13.1.6-1.6 2.4-29.6 2.1-30.3-3.1-8.5 1.6-17.7 1.6-17.7H98s-3.1 9.5-6.6 14.4c0 0-.9 35.3-2.3 39.4z"
        />
        <path
            className="sth1"
            d="M85.1 136.3c-8-.1-15.9-.3-23.3-.6h-.1l.1-.1c5-7.5 7.8-12 8.2-13.1.3-.8.9-8.5 1.4-15.5.5-7.8.9-14.5.7-14.8-3.1-8.4 1.5-17.7 1.6-17.8h24.4v.1c0 .1-3.1 9.6-6.6 14.4 0 1-.9 35.4-2.3 39.4-.6 1.6-1.9 4.3-4.1 8zm-23.1-.7c7.4.2 15.2.4 23.1.6 2.1-3.6 3.4-6.3 4-7.9h.1-.1c1.4-4 2.3-39 2.3-39.3 3.3-4.5 6.2-13.3 6.6-14.3H73.8c-.3.6-4.5 9.5-1.5 17.6.1.4-.3 7.8-.7 14.8-.5 6.9-1.1 14.7-1.4 15.5-.5 1-3.2 5.4-8.2 13z"
        />
        <path
            className="sth0"
            d="M73.5 84.5c-.1 1.1 0 2.3.1 3.4.2 1.1.4 2.2.7 3.3.2 1.1.5 2.1.6 3.2.1 1.2-1.4 26.8-1.6 27.9-.1 1.1-.5 2-1.1 3-1.1 2-2.4 3.9-3.6 5.8-1 1.5-2 3.1-3 4.6h-.3c1.3-2 2.6-4 3.9-6.1l1.8-3c.6-.9 1.2-1.9 1.6-2.9.4-1.1.4-2.2.4-3.3 0-1.2 1.4-23.3 1.5-24.5.1-2.3-.6-4.5-1-6.7s-.5-4.4-.2-6.6c.3-2.3 1-4.5 1.7-6.6 0-.1.2-.1.2 0-.2.6-.3 1.1-.5 1.7-.6 2.2-1.1 4.5-1.2 6.8z"
        />
        <path
            className="sth1"
            d="M65.7 135.8h-.5l.1-.1c1.3-1.9 2.6-4 3.9-6.1.7-1.2 1.3-2.1 1.8-3l.2-.3c.5-.8 1-1.7 1.4-2.6.4-1.1.4-2.3.4-3.3 0-.7.5-9.3 1-16.2.3-4.2.5-7.9.5-8.4.1-1.8-.3-3.6-.7-5.3-.1-.5-.2-.9-.3-1.4-.4-2.2-.5-4.4-.2-6.6s1-4.3 1.6-6.4l.1-.1c0-.1.1-.1.2-.1s.1.1.1.2c-.2.6-.3 1.1-.5 1.7-.5 1.9-1.1 4.3-1.2 6.8-.1 1.2 0 2.3.1 3.4.1.9.3 1.8.5 2.7.1.2.1.4.2.7.2 1 .5 2.1.6 3.2.1 1.2-1.4 26.8-1.6 27.9-.1 1-.5 1.9-1.1 3-1 1.7-2.1 3.4-3.2 5.1l-.5.7c-.8 1.3-1.8 2.8-2.9 4.5zm-.3-.1c1.3-1.7 2.2-3.2 3.1-4.5l.5-.7c1.1-1.7 2.2-3.4 3.1-5.1.6-1.1.9-2 1.1-3 .1-1.1 1.6-26.7 1.6-27.9-.1-1.1-.3-2.2-.6-3.2 0-.2-.1-.4-.2-.7-.2-.9-.4-1.8-.5-2.7-.2-1.1-.2-2.2-.1-3.4.1-2.5.7-5 1.2-6.8.1-.5.3-1.1.5-1.7l-.1.3c-.6 2.1-1.3 4.2-1.6 6.4-.3 2.2-.3 4.4.2 6.5.1.5.2.9.3 1.4.4 1.7.8 3.5.7 5.3 0 .5-.2 4.1-.5 8.4-.4 6.9-1 15.4-1 16.1 0 1 0 2.2-.5 3.4-.3.9-.9 1.8-1.4 2.6l-.2.3c-.5.9-1.1 1.8-1.8 3-1.2 2.1-2.5 4.1-3.8 6z"
        />
        <path
            className="sth0"
            d="M83.1 76.5c-.4 1.7-.4 3.9-1.6 5.3-.6.7-1.5 1.1-2.4 1.2-1 .2-2.1.2-3.1.2v.1c1.8.6 4.4.5 5.8-1 .6-.7.9-1.6 1.1-2.5.1-1.2.2-2.3.2-3.3 0-.1 0-.1 0 0z"
        />
        <path
            className="sth1"
            d="M78.2 83.6c-.8 0-1.5-.1-2.2-.3-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1 1.2.1 2.2 0 3.1-.2 1.1-.2 1.8-.6 2.3-1.2.9-1 1.1-2.4 1.3-3.7l.3-1.5c0-.1.1-.1.1-.1.1 0 .1 0 .1.1 0 1-.1 2.1-.3 3.2-.2 1.1-.5 2-1.1 2.6-.9.9-2.3 1.3-3.6 1.3zm-2-.4c1.8.5 4.2.4 5.5-1.1.5-.6.9-1.4 1-2.5.1-1 .2-2 .3-2.9-.1.4-.2.8-.2 1.3-.2 1.4-.4 2.8-1.3 3.8-.5.6-1.3 1-2.4 1.3-.8.1-1.8.2-2.9.1zm6.9-6.7z"
        />
        <path
            className="sth0"
            d="M91.6 89c0-.2-.3-.2-.3 0-.1.8-.1 1.6-.1 2.4 0 .7 0 1.5-.1 2.2 0 1.5-.1 3-.1 4.6-.1 3-.2 6.1-.3 9.1-.1 3-.3 6.1-.4 9.1-.1 1.5-.2 3-.3 4.6-.1 1.5-.2 2.9-.5 4.4-.6 2.9-1.7 5.9-3.3 8.4 0 .1.1.1.1.1 1.6-2.2 2.8-4.7 3.4-7.3.7-2.8.8-5.8.9-8.7.3-6.2.6-12.3.7-18.5 0-1.7.1-3.5.1-5.2v-2.5c.2-.9.2-1.8.2-2.7z"
        />
        <path
            className="sth1"
            d="M86.4 133.9s-.1 0 0 0c-.1-.1-.1-.1-.1-.2 1.6-2.6 2.7-5.5 3.3-8.4.3-1.5.4-3 .5-4.4l.1-1.1c.1-1.1.1-2.3.2-3.5.1-2.9.3-5.9.4-9.1.1-2.7.2-5.6.3-9.1V97c0-1.1.1-2.3.1-3.4V90.7c0-.5 0-1.1.1-1.7 0-.1.1-.2.2-.2s.2.1.2.2c.1.7.1 1.3 0 2V94.2c0 1.8 0 3.5-.1 5.2-.2 6.8-.4 12.8-.7 18.5 0 .4 0 .8-.1 1.2-.1 2.5-.3 5.1-.9 7.6-.6 2.6-1.8 5.1-3.5 7.2.1 0 0 0 0 0zm5-45c-.1.6-.1 1.2-.1 1.7V93.5c0 1.1-.1 2.3-.1 3.4V98c-.1 3.5-.2 6.4-.3 9.1-.1 3.3-.3 6.2-.4 9.1-.1 1.2-.1 2.4-.2 3.5l-.1 1.1c-.1 1.4-.2 2.9-.5 4.4-.5 2.8-1.7 5.7-3.2 8.3 1.6-2.2 2.7-4.6 3.3-7.1.6-2.5.7-5 .9-7.5 0-.4 0-.8.1-1.2.3-5.7.6-11.7.7-18.5 0-1.7.1-3.4.1-5.2v-3.2c0-.5 0-1.2-.2-1.9.1 0 .1 0 0 0z"
        />
        <path
            className="sth0"
            d="M94.7 87.5c-2 .7-4 1.6-5.9 2.6-.1 0 0 .1.1.1 2-.8 4-1.6 5.9-2.5.1-.1 0-.3-.1-.2z"
        />
        <path
            className="sth1"
            d="M88.9 90.3s-.1 0 0 0c-.1-.2-.1-.2 0-.3 1.6-.8 3.7-1.9 5.9-2.6.1 0 .2 0 .3.1 0 .1 0 .2-.1.2-1.5.7-3.1 1.3-4.6 1.9-.6.3-1 .5-1.5.7zm5.8-2.7c-2.2.7-4.3 1.8-5.9 2.6.4-.2.9-.4 1.3-.5 1.5-.6 3.1-1.2 4.6-1.9.1-.2.1-.2 0-.2.1 0 .1 0 0 0z"
        />
        <path
            className="sth0"
            d="M95.3 74.6c0-.1-.1-.1 0 0-.1 2.2-.2 4.2-.4 6.3-.1 1-.9 6.3-2.7 5.9-.3-.1-.4-.3-.5-.8s.1-1.1.2-1.6c.2-1.1.4-2.2.5-3.4.2-2.1.2-4.2-.1-6.3 0-.1-.1-.1-.1 0 0 2.9-.1 5.8-.5 8.7-.2 1.4-1 3.5.6 3.7s2.6-3.3 2.8-4.3c.4-2.6.4-5.5.2-8.2z"
        />
        <path
            className="sth1"
            d="M92.3 87.2c-.4 0-.7-.2-.9-.4-.4-.6-.2-1.7 0-2.6.1-.3.1-.5.1-.7.5-3 .5-6 .5-8.7 0-.1 0-.1.1-.1s.1 0 .1.1c.3 2 .3 4.1.1 6.3-.1 1.1-.2 2.2-.5 3.4 0 .1-.1.3-.1.4-.1.4-.2.8-.1 1.2 0 .4.2.7.4.7.2.1.4 0 .7-.2 1.2-.9 1.8-4.8 1.9-5.7.2-2.2.3-4.1.3-6.3 0-.1 0-.1.1-.1s.1 0 .1.1c.3 3.1.1 5.9-.3 8.3 0 1.2-1 4.3-2.5 4.3zm-.1-12.3c0 2.7-.1 5.7-.5 8.6 0 .2-.1.5-.1.7-.2.9-.4 2 0 2.5.1.2.4.3.7.4 1.5.2 2.5-3.3 2.7-4.2.5-2.4.6-5.1.3-8.2 0 2.1-.1 4-.3 6.2 0 0-.5 4.8-2 5.8-.2.2-.5.2-.8.2-.3-.1-.5-.3-.5-.8 0-.4.1-.8.1-1.2 0-.2.1-.3.1-.4.2-1.2.4-2.3.5-3.4.1-2.3.1-4.3-.2-6.2zm3-.3z"
        />
        <path
            className="sth0"
            d="M92.2 87c-.2.4-.3.7-.4 1.1-.1.3-.2.6-.2.9 0 .1.1.1.1 0 .2-.2.3-.6.4-.9s.3-.7.4-1c.1-.2-.2-.4-.3-.1z"
        />
        <path
            className="sth1"
            d="M91.7 89.1c-.1 0-.1-.1 0 0-.1-.4 0-.7 0-.9v-.1c.1-.4.3-.8.4-1.1.1-.2.2-.2.3-.1.1.1.2.2.1.3-.1.2-.2.5-.3.7 0 .1-.1.2-.1.3 0 .1-.1.1-.1.2-.1.2-.2.5-.3.7.1 0 0 0 0 0zm.7-2.2s-.1 0 0 0c-.2.4-.4.8-.5 1.2v.1c-.1.3-.2.5-.1.8.2-.2.2-.4.3-.7 0-.1.1-.1.1-.2s.1-.2.1-.3c.1-.2.2-.5.3-.7-.1-.1-.1-.2-.2-.2z"
        />
        <path
            className="sth2"
            d="M98.1 74.6H73.8s3.1-16.9 7.3-23.2c1.9-2.9 4.2-2.9 6.4-1.6.5.3 7.7 6.8 8.7 9.4.8 2.2-.6 5.1-.6 5.1l2.5 10.3z"
        />
        <path
            className="sth1"
            d="M98.1 74.6H73.7v-.1c0-.2 3.2-17 7.3-23.2 1.7-2.5 3.9-3.1 6.5-1.6.6.3 7.7 6.9 8.7 9.4.8 2.1-.5 4.9-.6 5.2l2.5 10.3zm-24.3-.1H98l-2.6-10.2s1.4-2.9.6-5.1c-.9-2.5-8.1-9-8.6-9.3-2.5-1.5-4.6-.9-6.3 1.6-4 5.9-7 21.7-7.3 23z"
        />
        <path
            className="sth3"
            d="M90.1 53.8c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.3-.5.5-.5zm-4.7.5c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.4 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm6.5-3.1c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zM94 60.5c0 .3.2.5.5.5s.5-.2.5-.5-.3-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.3-.5-.6-.5c-.2 0-.4.2-.4.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.3-.5-.6-.5c-.2 0-.4.2-.4.5zm-4.4 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-3.9.5c.4 0 .6-.2.6-.5 0-.2-.1-.4-.3-.4l-.3.9zm14.7-3.6c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zM94 66.7c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.4 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm15.1-3.1c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zM94 72.9c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.4 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm19.5-3.1c0 .3.2.5.5.5.1 0 .2 0 .3-.1l-.2-.8h-.1c-.3-.1-.5.1-.5.4zm-4.4 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.3 0c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5zm-4.2.3c.1.1.2.2.3.2.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-.2c0 .3-.1.5-.1.8z"
        />
        <path
            className="sth4"
            d="M87.6 64c1-.1 2-.1 3-.1.5 0 1 .1 1.5.2.2 0 .5.1.7.1h.8c-.2.1-.5.1-.7.1h-.7c-.5 0-1-.1-1.5-.1-1.1 0-2.1-.1-3.1-.2-.1.1-.1 0 0 0zM85.3 65.3l2.4-.6c.4-.1.8-.1 1.2-.1H90.2c-.2 0-.4.1-.6.1s-.4.1-.6.1c-.4.1-.8.1-1.2.2-.9.1-1.7.3-2.5.3 0 .1 0 0 0 0z"
        />
        <path
            className="sth2"
            d="M83 50c.2.4 2.7 2.9 4.7 2.5.6-.1.1-3.7.1-3.7v-.3l.3-3.8-4.7-2.1-.7-.3V46.6c0 1.6.2 3.2.3 3.4z"
        />
        <path
            className="sth1"
            d="M87.2 52.6c-1.9 0-4.1-2.2-4.3-2.6-.1-.2-.3-1.8-.4-3.4V42.3l.8.3 4.8 2.1-.3 4.1c.1.6.5 3.6-.1 3.7-.2.1-.3.1-.5.1zm-4.1-2.7c.2.4 2.7 2.9 4.6 2.5.4-.1.2-2.3 0-3.6v-.3l.2-3.7-4.7-2.1-.6-.2V46.6c.2 1.6.4 3.2.5 3.3z"
        />
        <path
            d="M82.6 44.7c1.5 2.8 4 3.6 5.1 3.8l.3-3.8-4.7-2.1-.7-.3v2.4z"
            style={{
                opacity: 0.31,
                fill: "#263238",
                enableBackground: "new",
            }}
        />
        <path
            className="sth2"
            d="M92.3 37.1c2.9 6-.3 8.4-1.6 9-1.2.6-5.2 2.4-8.8-3.3-3.5-5.7-1.7-8.9.9-10.4 2.7-1.4 6.6-1.3 9.5 4.7z"
        />
        <path
            className="sth1"
            d="M87.8 46.9c-2.2 0-4.2-1.4-5.9-4.1-1.7-2.7-2.3-5.1-1.8-7.1.5-1.8 1.8-2.8 2.7-3.4 1-.6 2.7-1.1 4.5-.5 2 .6 3.7 2.4 5 5.2 1.2 2.4 1.5 4.5.9 6.3s-2 2.6-2.6 2.9c-.9.5-1.9.7-2.8.7zm-2.1-15.1c-1.2 0-2.1.4-2.8.8-1 .5-2.2 1.6-2.7 3.3-.5 1.9.1 4.3 1.8 7 2.3 3.8 5.3 4.9 8.7 3.3.5-.3 1.9-1.1 2.5-2.8s.3-3.8-.9-6.2c-1.4-2.8-3-4.5-5-5.1-.6-.3-1.1-.3-1.6-.3z"
        />
        <path
            className="sth4"
            d="M90.9 36.5c-.1 0-.3.1-.4.1-.2 0-.3.1-.5 0-.1 0-.1-.2-.1-.3.1-.2.3-.3.5-.4.2-.1.4 0 .6.1.2.1.1.4-.1.5zM86.8 38.4c.1-.1.2-.2.4-.3.1-.1.3-.2.3-.4 0-.1 0-.2-.1-.3-.2-.1-.4 0-.6.1s-.3.3-.4.5c-.1.3.2.5.4.4zM88.4 39.4c.2.5.4.9.1 1.3.4-.3.2-.9-.1-1.3z"
        />
        <path
            className="sth4"
            d="M87.9 39.2c-.7.3-.1 1.6.5 1.4.6-.3 0-1.7-.5-1.4z"
        />
        <path
            className="sth4"
            d="M87.6 39.3c-.1.1-.1.3-.3.4-.2.1-.3 0-.5-.1.1.3.3.5.6.4.3-.1.3-.4.2-.7.1 0 .1 0 0 0zM90.7 38.3c.2.4.5.9.9.9-.5.1-.8-.5-.9-.9z"
        />
        <path
            className="sth4"
            d="M90.9 37.7c.6-.3 1.3 1 .7 1.3-.6.3-1.3-1-.7-1.3z"
        />
        <path
            className="sth4"
            d="M91.2 37.7c.1 0 .3.1.4 0s.2-.3.2-.4c.1.2.2.6 0 .7-.2.2-.5 0-.6-.3-.1 0-.1-.1 0 0zM89.9 42.8c.1.1.3.2.5.1.2 0 .3-.2.4-.3 0 .2-.2.4-.4.4-.3.2-.4 0-.5-.2-.1 0 0 0 0 0zM91.2 41.4s-.1.5-.4.9c-.1.1-.3.2-.4.3-.4.1-.5-.2-.6-.5-.1-.2-.1-.5-.1-.5s.7.2 1.5-.2z"
        />
        <path
            d="M90.8 42.3c-.1.1-.3.2-.4.3-.4.1-.5-.2-.6-.5.3-.2.8-.1 1 .2z"
            style={{
                fill: "#f496b7",
            }}
        />
        <path
            className="sth4"
            d="M90.9 40.6s.4.6.5.9l-.1.1c-.5.3-1.1.4-1.6.1v-.1c.5.1 1 0 1.4-.2 0-.1-.7-1-.6-1 .2-.1.5-.1.7-.2-.7-1.2-1.6-2.3-2.4-3.6 0 0 0-.1.1-.1 1 1.1 1.9 2.5 2.7 3.7.2.3-.5.4-.7.4z"
        />
        <path
            className="sth0"
            d="M82.3 42.6c1.5-.6.5-2.9.5-2.9s1.6-.8.7-3.5c0 0 1.4 1.3 3.8.7.5-.1-1.6-.7-1.6-.7s2.6 1 5.9-1.1c.2-.1-1.8-3.7-5-3.8-3.3-.2-5.5 1.5-6.5 4 0 0-1.7 1.7-1.1 3.7.4 2.1 2.7 3.8 3.3 3.6z"
        />
        <path
            className="sth1"
            d="M82.2 42.7c-.2 0-.4-.1-.5-.2-.9-.5-2.4-1.9-2.8-3.5-.5-1.9 1-3.7 1.1-3.8 1.1-2.7 3.5-4.1 6.6-4 3.1.2 5 3.2 5.1 3.8v.2c-2 1.3-3.8 1.4-4.8 1.3.5.2.7.3.7.4 0 .1 0 .1-.1.1-1.9.4-3.2-.3-3.7-.6.7 2.3-.4 3.2-.7 3.4.1.3.5 1.4.1 2.2-.1.3-.4.6-.7.7h-.3zm3.9-11.3c-2.8 0-4.9 1.4-6 3.9 0 0-1.6 1.7-1.1 3.7.4 1.6 1.9 2.9 2.7 3.4.3.1.5.2.6.1.3-.1.5-.3.7-.6.4-.8-.1-2.1-.1-2.2v-.1h.1c.1 0 1.5-.8.6-3.4l-.3-.2.2.2s1.4 1.3 3.7.7c-.1-.1-.8-.3-1.4-.5-.1 0-.2-.1-.2-.1v-.1c.1 0 .1 0 .2.1.7.2 2.9.5 5.6-1.1 0-.1-.1-.5-.6-1-.4-.6-2-2.6-4.4-2.7-.1-.1-.2-.1-.3-.1z"
        />
        <path
            d="M80.7 42.4s-2.3-2.4-1.8-5.8c.4-3.5 4.6-6 8.9-5.1 0 0-5.9.8-7 3.8-1.2 3.1-.1 7.1-.1 7.1z"
            style={{
                fill: "#ffc827",
            }}
        />
        <path
            className="sth2"
            d="M83 45.1h.3c.1 0 .2-.1.3-.1l.3-.3c.1-.1.1-.2.2-.3.1-.2.1-.4.1-.4l-1-1.5s-2.1-1.4-2.8-.6c-.7.8 1.4 3.2 2.6 3.2z"
        />
        <path
            className="sth1"
            d="M83 45.1c-.9 0-2.1-1.2-2.6-2.2-.2-.5-.3-.9 0-1.2.7-.9 2.8.5 2.9.6l1 1.5s0 .2-.1.5c0 .1-.1.2-.2.3l-.3.3c-.1.1-.2.1-.3.1-.2.1-.3.1-.4.1zm-2-3.5c-.2 0-.5.1-.6.2-.2.2-.2.6.1 1 .4.9 1.7 2.1 2.5 2.1h.3c.1 0 .2-.1.3-.1.1-.1.2-.1.3-.2.1-.1.1-.2.2-.3.1-.2.1-.3.1-.4l-1-1.5c-.2 0-1.4-.8-2.2-.8z"
        />
        <path
            className="sth1"
            d="M81 42.5c.8 0 1.5.6 2 1.2-.3-.2-.7-.2-.9.2.3-.2.5-.2.8 0 .2.1.4.3.6.4.1.1.2 0 .1-.1-.4-1-1.6-2-2.6-1.7z"
        />
        <path
            className="sth2"
            d="M83.5 44.4s0-.1 0 0l-.2-.1c-.1-.1-.3-.3-.5-.4-.3-.1-.5-.1-.7 0H82v-.1c.2-.3.4-.4.7-.3-.4-.4-1-.9-1.8-.9 0 0-.1 0-.1-.1 0 0 0-.1.1-.1 1-.3 2.3.7 2.7 1.7 0 .1 0 .1-.1.3.1-.1.1 0 0 0zm-.9-.7c.1 0 .3 0 .4.1.2.1.3.2.5.4l.1.1c-.3-.8-1.2-1.6-2.1-1.7.7.2 1.2.7 1.6 1.1l.3.3-.4-.3c-.3-.2-.5-.2-.7 0h.3zm1 .5zm-1.5-.4zM81 42.6zM82.9 44.8c.1.1.3.2.4.1.1-.1.2-.3.1-.4-.1-.1-.3-.2-.4-.1-.1 0-.1.2-.1.4z"
        />
        <path
            className="sth1"
            d="M83.2 45c-.1 0-.2-.1-.3-.2-.1-.2-.1-.4.1-.5s.4-.1.5.1c.1.1.1.2 0 .3s-.1.2-.2.2c0 .1 0 .1-.1.1zm-.2-.3c.1.1.2.1.3.1.1 0 .1-.1.1-.2v-.2c-.1-.1-.2-.1-.3-.1-.1.2-.2.3-.1.4z"
        />
        <path
            className="sth2"
            d="M82.2 44.6c.1.1.2.1.3.1.1-.1.1-.2.1-.3-.1-.1-.2-.1-.3-.1-.1 0-.2.2-.1.3z"
        />
        <path
            className="sth1"
            d="M82.4 44.7c-.1 0-.2 0-.2-.1s-.1-.1 0-.2c0-.1.1-.1.1-.2.1 0 .1-.1.2 0 .1 0 .1.1.2.1.1.1 0 .3-.1.4h-.2zm0-.4c-.1 0-.1 0 0 0-.1 0-.1.1 0 0l-.1.2c0 .1.1.1.2 0l.1-.1v-.1h-.2z"
        />
        <path
            className="sth2"
            d="M53.6 68.3c-.8 2.7-2.1 5.5-1.6 8.3.6 3.6 3.5 6.5 6.9 8.3 3.4 1.7 7.2 2.4 10.9 3.1.5-2 1.2-3.9 2.2-5.7-3.1-1.8-4.9-5.4-4.4-8.9.4-2.6 1.9-4.9 3.6-7 1.5-1.7 3.1-3.4 4.8-4.9 1.6-1.4 3.4-2.7 4.8-4.3 1-1.1 3.3-4 2-5.6-1.1-1.4-5-.3-6.5-.1-4.8.6-19.5 6.3-22.7 16.8z"
        />
        <path
            className="sth1"
            d="M69.8 88c-3.8-.7-7.6-1.4-11-3.1-3.8-1.9-6.3-5-6.9-8.3-.4-2.3.4-4.5 1.1-6.7.2-.5.4-1.1.5-1.6h.1-.1c1.8-5.7 6.8-9.7 10.8-12.1 4.6-2.7 9.4-4.4 12-4.7.3 0 .6-.1 1.1-.2 1.8-.3 4.6-.9 5.5.3 1.3 1.6-1 4.5-2 5.7s-2.3 2.2-3.5 3.2c-.4.4-.9.7-1.3 1.1-1.7 1.5-3.3 3.1-4.8 4.8-1.5 1.8-3.2 4.1-3.6 6.9-.5 3.4 1.3 7 4.4 8.8h.1v.1c-1.1 1.8-1.9 3.7-2.4 5.8zm10.9-37.1c-1.1 0-2.3.2-3.3.4-.4.1-.8.1-1.1.2-4 .5-19.3 5.9-22.6 16.7-.2.5-.3 1.1-.5 1.6-.7 2.2-1.5 4.4-1.1 6.7.5 3.2 3.1 6.3 6.8 8.2 3.4 1.7 7.2 2.4 10.8 3.1.5-2 1.2-3.9 2.2-5.6-3.1-1.8-4.9-5.5-4.4-8.9.4-2.8 2.2-5.2 3.6-7 1.5-1.7 3.1-3.4 4.8-4.9.4-.4.9-.8 1.3-1.1 1.2-1 2.4-2 3.5-3.2.8-.9 3.3-3.9 2-5.5-.3-.5-1.1-.7-2-.7z"
        />
        <path
            className="sth2"
            d="M75.4 83c.4 0 .7.1 1 .3.2.2.4.4.5.7.3.6.7 1.2 1 1.8.3.7.6 1.4.4 2.2-.2.8-1 1.3-1.7 1.7-1.1.6-2.2 1.3-3.4 1.5-.7.1-1.4.1-2-.2-.6-.3-1.1-.8-1.3-1.5v-.2c0-.1.1-.1.2-.1l1.5-.3c-.9.1-1.6-.7-1.7-1.6-.1-.8.3-1.6.7-2.4.4-.8.8-2.3 1.6-2.3.4 0 1 .3 1.5.3.5.1 1.1.1 1.7.1z"
        />
        <path
            className="sth1"
            d="M72.5 91.3c-.4 0-.9-.1-1.4-.3-.7-.3-1.2-.9-1.3-1.6v-.3c.1-.1.2-.1.2-.1l1-.2c-.2-.1-.4-.1-.6-.3-.4-.3-.7-.8-.7-1.3-.1-.9.3-1.7.7-2.4l.3-.6c.3-.8.7-1.7 1.4-1.7.2 0 .5.1.8.2.2.1.4.1.6.2.3 0 .7.1 1 .1.2 0 .5 0 .7.1.3 0 .7.1 1 .3.2.2.4.4.6.7.3.6.7 1.2 1 1.8.3.7.6 1.5.4 2.2-.2.8-1 1.3-1.7 1.7-1 .6-2.2 1.3-3.4 1.5-.1-.1-.4 0-.6 0zm-.9-2.6-1.5.4c-.1 0-.1 0-.2.1v.2c.1.6.6 1.2 1.2 1.5.7.3 1.5.3 2 .2 1.3-.2 2.4-.9 3.4-1.5.8-.5 1.5-.9 1.7-1.6s-.1-1.4-.4-2.1c-.3-.6-.6-1.2-1-1.8-.2-.3-.3-.5-.5-.6-.3-.2-.7-.3-1-.3-.2 0-.5 0-.7-.1-.3 0-.7 0-1-.1-.2 0-.4-.1-.7-.2-.3-.1-.6-.2-.8-.2-.6 0-1 .9-1.3 1.6l-.3.6c-.3.7-.8 1.5-.7 2.3 0 .5.3.9.7 1.2.4.3.7.4 1.1.4z"
        />
        <path
            className="sth8"
            d="M47.9 139.2c-.2-2.2 0-4.5.4-6.7l.3.1c-.4 2.2-.6 4.4-.4 6.6h-.3zm-2.4-.4c-.1-2 0-4 .2-5.9h.3c-.2 1.9-.3 3.9-.2 5.9h-.3zm-2.5-.7c-.4-1.8-.4-3.8.1-5.5l.3.1c-.5 1.7-.5 3.6-.1 5.4H43z"
        />
        <path className="sth3" d="M50.3 112.8h14.3v27.7H50.3z" />
        <path
            className="sth9"
            d="M64.6 112.8h13v27.7h-13zM77.6 112.8l-6.5-6.7-6.5 6.7z"
        />
        <path
            className="sth8"
            d="M77.7 112.8H64.5l6.6-6.8 6.6 6.8zm-13-.1h12.8l-6.4-6.6-6.4 6.6z"
        />
        <path className="sth10" d="m50.3 112.8 6.6-6.2 14.2-.5-6.5 6.7z" />
        <path
            className="sth8"
            d="M64.6 112.8H50.2l.1-.1 6.6-6.2 14.3-.5-.1.1-6.5 6.7zm-14.2-.1h14.1l6.4-6.6-14 .5-6.5 6.1z"
        />
        <path className="sth11" d="M52.7 116h2.9v7.5h-2.9z" />
        <path
            className="sth8"
            d="M55.6 123.6h-3V116h3v7.6zm-2.9-.1h2.8v-7.4h-2.8v7.4z"
        />
        <path className="sth11" d="M57 116h2.9v7.5H57z" />
        <path
            className="sth8"
            d="M59.9 123.6h-3V116h3v7.6zm-2.9-.1h2.8v-7.4H57v7.4z"
        />
        <path className="sth3" d="M67.6 116h2.9v7.5h-2.9z" />
        <path
            className="sth8"
            d="M70.5 123.6h-3V116h3v7.6zm-2.9-.1h2.8v-7.4h-2.8v7.4z"
        />
        <path className="sth3" d="M67.6 127.5h2.9v7.5h-2.9z" />
        <path
            className="sth8"
            d="M70.5 135h-3v-7.6h3v7.6zm-2.9-.1h2.8v-7.4h-2.8v7.4z"
        />
        <path
            className="sth3"
            d="M67.6 138.9h2.9v1.5h-2.9zM72.4 116h2.9v7.5h-2.9z"
        />
        <path
            className="sth8"
            d="M75.3 123.6h-3V116h3v7.6zm-2.9-.1h2.8v-7.4h-2.8v7.4z"
        />
        <path className="sth11" d="M52.7 125.8h2.9v7.5h-2.9z" />
        <path
            className="sth8"
            d="M55.6 133.3h-3v-7.6h3v7.6zm-2.9 0h2.8v-7.4h-2.8v7.4z"
        />
        <path className="sth11" d="M57 125.8h2.9v7.5H57z" />
        <path
            className="sth8"
            d="M59.9 133.3h-3v-7.6h3v7.6zm-2.9 0h2.8v-7.4H57v7.4z"
        />
        <path className="sth9" d="M107.8 101h13v39.5h-13z" />
        <path
            className="sth3"
            d="M93.9 101h13.8v39.5H93.9zM100.8 94.1l-6.9 6.9h13.9z"
        />
        <path
            className="sth8"
            d="M107.9 101H93.8l7-7 7.1 7zm-13.9-.1h13.6l-6.8-6.8-6.8 6.8z"
        />
        <path className="sth10" d="M100.8 94.1h13.6l6.4 6.9h-13z" />
        <path
            className="sth8"
            d="M120.9 101h-13.2l-7-6.9h13.7l6.5 6.9zm-13.1-.1h12.9l-6.3-6.8H101l6.8 6.8z"
        />
        <path className="sth11" d="M95.8 105h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 107.2H95.7V105H106v2.2zm-10.2-.1h10.1v-2H95.8v2z"
        />
        <path className="sth11" d="M95.8 109.4h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 111.5H95.7v-2.2H106v2.2zm-10.2 0h10.1v-2H95.8v2z"
        />
        <path className="sth11" d="M95.8 113.8h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 115.9H95.7v-2.2H106v2.2zm-10.2-.1h10.1v-2H95.8v2z"
        />
        <path className="sth11" d="M95.8 118.2h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 120.3H95.7v-2.2H106v2.2zm-10.2-.1h10.1v-2H95.8v2z"
        />
        <path className="sth11" d="M95.8 122.6h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 124.7H95.7v-2.2H106v2.2zm-10.2-.1h10.1v-2H95.8v2z"
        />
        <path className="sth11" d="M95.8 126.9h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 129.1H95.7v-2.2H106v2.2zm-10.2-.1h10.1v-2H95.8v2z"
        />
        <path className="sth11" d="M95.8 131.3h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 133.4H95.7v-2.2H106v2.2zm-10.2 0h10.1v-2H95.8v2z"
        />
        <path className="sth11" d="M95.8 135.7h10.1v2.1H95.8z" />
        <path
            className="sth8"
            d="M106 137.8H95.7v-2.2H106v2.2zm-10.2-.1h10.1v-2H95.8v2z"
        />
        <path className="sth9" d="M43 135.2h18.8v5.3H43z" />
        <path
            className="sth3"
            d="M31.2 135.2H43v5.3H31.2zM31.2 135.2l5.9-7.9 5.9 7.9z"
        />
        <path
            className="sth8"
            d="M43.1 135.2h-12l.1-.1 5.9-8 6 8.1zm-11.8-.1h11.6l-5.8-7.8-5.8 7.8z"
        />
        <path className="sth10" d="M37.1 127.3H56l5.8 7.9H43z" />
        <path
            className="sth8"
            d="M61.9 135.2H43l-6-8h19.1l5.8 8zm-18.9-.1h18.7l-5.7-7.8H37.2l5.8 7.8z"
        />
        <path
            className="sth3"
            d="M48 139.5h2.9v1H48zM54.5 139.5h2.9v1h-2.9zM113.4 134.8h18.8v5.7h-18.8z"
        />
        <path
            className="sth9"
            d="M132.2 134.8h11.9v5.7h-11.9zM138.2 129l-6 5.8h12z"
        />
        <path
            className="sth8"
            d="M144.3 134.8h-12.1l6.1-5.9 6 5.9zm-11.9-.1h11.7l-5.8-5.7-5.9 5.7z"
        />
        <path className="sth10" d="m113.4 134.8 6.4-5.8h18.4l-6 5.8z" />
        <path
            className="sth8"
            d="M132.3 134.8h-18.9l.1-.1 6.4-5.8h18.5l-.1.1-6 5.8zm-18.8-.1h18.7l5.9-5.7h-18.3l-6.3 5.7z"
        />
        <path className="sth3" d="M136.8 139.5h2.9v1h-2.9z" />
        <path
            className="sth11"
            d="M124.4 139.5h2.9v1h-2.9zM117.9 139.5h2.9v1h-2.9z"
        />
        <path className="sth9" d="M83.7 125.8h18.8v14.7H83.7z" />
        <path
            className="sth3"
            d="M71.9 125.8h11.8v14.7H71.9zM71.9 125.8l5.9-6 5.9 6z"
        />
        <path
            className="sth8"
            d="M83.8 125.9h-12l.1-.1 5.9-6.1 6 6.2zm-11.8-.1h11.6l-5.8-5.9-5.8 5.9z"
        />
        <path className="sth10" d="M77.8 119.8h19l5.7 6H83.7z" />
        <path
            className="sth8"
            d="M102.7 125.9h-19l-6-6.1h19.1l5.9 6.1zm-18.9-.1h18.7l-5.7-6H77.9l5.9 6z"
        />
        <path className="sth11" d="M73.8 129h2.9v4.2h-2.9z" />
        <path
            className="sth8"
            d="M76.8 133.2h-3V129h3v4.2zm-2.9-.1h2.8V129h-2.8v4.1z"
        />
        <path className="sth11" d="M77.6 129h2.9v4.2h-2.9z" />
        <path
            className="sth8"
            d="M80.6 133.2h-3V129h3v4.2zm-2.9-.1h2.8V129h-2.8v4.1z"
        />
        <path className="sth11" d="M73.8 135h2.9v4.2h-2.9z" />
        <path
            className="sth8"
            d="M76.8 139.2h-3V135h3v4.2zm-2.9-.1h2.8V135h-2.8v4.1z"
        />
        <path className="sth11" d="M77.6 135h2.9v4.2h-2.9z" />
        <path
            className="sth8"
            d="M80.6 139.2h-3V135h3v4.2zm-2.9-.1h2.8V135h-2.8v4.1z"
        />
        <path className="sth3" d="M90.2 132h2.9v4.2h-2.9z" />
        <path
            className="sth8"
            d="M93.1 136.2h-3V132h3v4.2zm-2.9-.1H93V132h-2.8v4.1z"
        />
        <path className="sth3" d="M94 132h2.9v4.2H94z" />
        <path
            className="sth8"
            d="M97 136.2h-3V132h3v4.2zm-2.9-.1h2.8V132h-2.8v4.1z"
        />
        <path className="sth3" d="M90.2 138h2.9v2.5h-2.9zM94 138h2.9v2.5H94z" />
    </svg>
)

MyHomeIcon.defaultProps = {
    width: '1em',
    height: '1em',
}

MyHomeIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}

export default MyHomeIcon
