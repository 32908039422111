import { prop } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import AddFab from '@Common/Components/AddFab'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import { makePath } from '@Config'

import CategoryList from '../Components/admin/AdminCategoryList'
import CategoryForm from '../Forms/CategoryForm'
import { CategoryPermissions } from '../Permissions'
import {
  useCreateServiceCategoriesMutation,
  useDeleteServiceCategoriesMutation,
  useUpdateServiceCategoriesMutation,
} from '../Services/Api'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminCategoryView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [createCategories] = useCreateServiceCategoriesMutation()
  const [updateCategories] = useUpdateServiceCategoriesMutation()
  const [deleteCategories] = useDeleteServiceCategoriesMutation()
  const [form, setForm] = React.useState({ isOpen: false, category: null, parent: null })
  const handleOpenForm = (category) => () => setForm({ isOpen: true, category, parent: null })
  const handleCloseForm = () => setForm({ isOpen: false, category: null, parent: null })

  useBreadcrumbs([
    { label: t('common:navigation.Services'), path: makePath('admin.services.services'), Icon: 'Service' },
    { label: t('common:navigation.Categories'), Icon: 'Tree' },
  ])

  const handleSave = async (fields) => {
    const formData = new FormData()
    formData.append('name', fields.name)
    if (fields.parentId === null) {
      formData.append('parent_key', 'no-parent')
    } else {
      formData.append('parent_key', fields.parentId)
    }
    formData.append('ordering_number', fields.orderingNumber)
    if (fields.logo.length) {
      formData.append('logo', fields.logo[0])
    }
    try {
      const response = form.category?.id
        ? await updateCategories({ slug: form.category.slug, category: formData }).unwrap()
        : await createCategories(formData).unwrap()
      toast.success(t(`services:success.Category${form.category ? 'Updated' : 'Created'}`, { title: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t(`services:errors.${form.category ? 'Update' : 'Create'}CategoryError`, { error: responseStringError(error) }),
      )
      return { error, isSuccessful: false }
    }
  }

  const handleEdit = (category) => setForm({ isOpen: true, category, parent: null })

  const handleCreateChild = (category) => setForm({ isOpen: true, category: null, parent: category })

  const handleDelete = async (categories) => {
    try {
      const slugs = categories.map(prop('slug'))
      const promises = slugs.map(async (slug) => {
        return deleteCategories(slug).unwrap()
      })

      await Promise.all(promises)
      toast.success(
        t('services:success.CategoriesDeleted', {
          count: categories.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('services:errors.DeleteCategoriesError', {
          error: err.data?.detail || t('common:errors.UnknownError'),
          count: categories.length,
        }),
      )
    }
  }
  return (
    <Main>
      <CategoryList onEdit={handleEdit} onDelete={handleDelete} onCreateChild={handleCreateChild} />
      {form.isOpen && (
        <CategoryForm category={form.category} parent={form.parent} onClose={handleCloseForm} onSave={handleSave} />
      )}
      {CategoryPermissions.create(user) && <AddFab onClick={handleOpenForm(null)} />}
    </Main>
  )
}

export { AdminCategoryView }
