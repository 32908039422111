import PropTypes from "prop-types";

const MyEstiaLogo = ({ style, color }) => {
  const styles = {
    fillRule: "evenodd",
    clipRule: "evenodd",
    strokeLinejoin: "round",
    strokeMeterlimit: 2,
    ...style,
  }

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 313 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <g transform="matrix(1,0,0,1,-4963.61,-3135.47)">
        <g transform="matrix(1,0,0,2.50556,4160,3100)">
          <g
            id="LOGO-HEAD"
            transform="matrix(0.787306,0,0,0.80389,285.391,4.9242)"
          >
            <g
              id="Livello-1"
              transform="matrix(0.933122,0,0,0.364738,516.155,-81.8878)"
            >
              <g transform="matrix(1,0,0,1,548.683,338.832)">
                <path
                  d="M0,-61.768L1.058,-61.768C10.077,-61.768 19.096,-61.751 28.115,-61.792C29.116,-61.797 29.316,-61.504 29.315,-60.555C29.286,-40.8 29.286,-21.044 29.317,-1.289C29.318,-0.303 29.054,-0.074 28.091,-0.078C19.111,-0.116 10.131,-0.108 1.151,-0.089C0.34,-0.087 -0.023,-0.082 0.154,-1.168C0.479,-3.179 0.565,-5.23 0.88,-7.251C0.93,-7.576 1.157,-8.068 0.646,-8.267C0.218,-8.435 -0.071,-8.131 -0.352,-7.838C-2.422,-5.687 -4.586,-3.627 -7.116,-2.029C-11.545,0.772 -16.365,1.799 -21.594,1.116C-25.626,0.591 -29.199,-0.895 -32.45,-3.302C-35.319,-5.426 -37.533,-8.111 -39.389,-11.11C-42.178,-15.619 -43.832,-20.56 -44.515,-25.787C-45.402,-32.579 -44.451,-39.171 -41.899,-45.532C-40.348,-49.398 -38.199,-52.913 -35.284,-55.863C-31.427,-59.766 -26.799,-62.271 -21.252,-62.963C-16.415,-63.567 -11.78,-63.014 -7.4,-60.851C-4.557,-59.446 -2.196,-57.406 -0.31,-54.854C0.011,-54.419 0.336,-54.455 0.674,-54.544C1.085,-54.653 0.927,-55.08 0.892,-55.347C0.62,-57.443 0.315,-59.534 0,-61.768M0.921,-31.209C0.964,-32.04 0.828,-32.841 0.679,-33.648C0.15,-36.521 -2.215,-39.033 -5.004,-39.643C-8.59,-40.428 -11.755,-39.336 -13.563,-36.633C-15.559,-33.652 -15.596,-30.433 -14.531,-27.146C-12.799,-21.801 -5.743,-20.154 -1.728,-24.1C0.284,-26.077 0.764,-28.573 0.921,-31.209"
                  fill={color}
                />
              </g>
              <g transform="matrix(1,0,0,1,294.486,302.866)">
                <path
                  d="M0,10.06C-0.703,10.058 -1.15,10.158 -1.001,10.993C-0.653,12.953 -0.795,14.983 0.038,16.879C1.332,19.82 3.097,20.912 6.733,20.839C9.123,20.79 11.44,19.093 12.274,16.619C12.522,15.883 12.828,15.672 13.562,15.674C22.506,15.695 31.45,15.7 40.393,15.669C41.338,15.666 41.515,15.844 41.197,16.768C39.57,21.488 36.805,25.367 32.948,28.535C27.509,33 21.213,35.368 14.366,36.611C10.858,37.248 7.34,37.439 3.823,37.23C-4.417,36.739 -12.234,34.676 -18.948,29.684C-24.822,25.317 -28.557,19.474 -29.901,12.166C-30.266,10.176 -30.506,8.156 -30.484,6.188C-30.43,1.397 -29.669,-3.307 -27.657,-7.737C-26.062,-11.247 -24.008,-14.46 -21.255,-17.137C-16.038,-22.209 -9.759,-25.219 -2.599,-26.543C1.026,-27.213 4.662,-27.379 8.296,-27.153C13.108,-26.855 17.792,-25.795 22.263,-23.911C26.647,-22.065 30.57,-19.517 33.872,-16.089C38.108,-11.692 40.796,-6.456 42.128,-0.496C42.695,2.043 42.775,4.625 43.013,7.194C43.086,7.987 42.874,8.806 42.818,9.615C42.773,10.267 42.284,10.062 41.947,10.064C39.057,10.078 6.952,10.08 0,10.06M12.425,-2.621C13.432,-2.583 13.518,-3.035 13.377,-3.805C13.078,-5.428 12.56,-6.943 11.52,-8.273C8.513,-12.114 2.174,-11.537 0.3,-7.734C-0.408,-6.298 -0.713,-4.821 -0.916,-3.281C-1.004,-2.608 -0.558,-2.641 -0.105,-2.642L12.425,-2.621Z"
                  fill={color}
                />
              </g>
              <g transform="matrix(1,0,0,1,443.714,259.462)">
                <path
                  d="M0,79.289C0.005,80.182 -0.194,80.467 -1.143,80.463C-10.399,80.425 -19.655,80.427 -28.91,80.462C-29.831,80.465 -30.072,80.224 -30.07,79.303C-30.038,67.274 -30.06,55.247 -30.028,43.218C-30.025,42.277 -30.203,41.87 -31.225,41.991C-32.95,42.196 -34.689,42.295 -36.417,42.476C-37.236,42.561 -37.564,42.38 -37.558,41.434C-37.513,34.288 -37.526,27.142 -37.544,19.995C-37.546,19.196 -37.446,18.98 -36.5,19.114C-34.588,19.387 -32.634,19.369 -30.698,19.487C-29.805,19.542 -30.058,18.89 -30.057,18.446C-30.045,12.509 -30.049,6.574 -30.049,0.638C-30.049,-1.15 -30.049,-1.15 -28.269,-1.15C-19.248,-1.15 -10.226,-1.133 -1.205,-1.174C-0.213,-1.178 0.01,-0.901 0.003,0.059C-0.042,6.15 -0.002,12.243 -0.04,18.335C-0.045,19.228 0.145,19.572 1.103,19.501C3.73,19.306 6.374,19.36 8.993,19.026C9.762,18.928 10.079,19.112 10.074,19.994C10.038,27.14 10.034,34.287 10.078,41.433C10.084,42.375 9.76,42.541 8.938,42.482C6.31,42.295 3.675,42.188 1.048,41.987C0.144,41.917 -0.045,42.228 -0.04,43.087L0,79.289Z"
                  fill={color}
                />
              </g>
              <g transform="matrix(1,0,0,1,375.377,340.129)">
                <path
                  d="M0,-64.482C6.455,-64.51 12.268,-63.597 17.932,-61.839C19.754,-61.274 21.556,-60.564 23.226,-59.587C23.592,-59.373 23.876,-59.279 23.638,-58.732C21.414,-53.634 19.209,-48.525 17.027,-43.407C16.729,-42.708 16.542,-42.598 15.841,-43.07C12.788,-45.128 9.464,-46.627 5.82,-47.248C4.159,-47.531 2.452,-47.72 0.759,-47.296C-0.641,-46.944 -1.451,-46.157 -1.523,-45.034C-1.655,-43.005 -1.159,-42.109 0.574,-41.533C2.107,-41.023 3.717,-40.832 5.306,-40.559C10.124,-39.728 14.884,-38.758 19.042,-35.913C22.322,-33.668 24.14,-30.533 24.951,-26.753C25.902,-22.318 25.317,-18.01 23.464,-13.906C22.222,-11.155 20.234,-8.937 17.915,-7.014C13.427,-3.291 8.091,-1.511 2.483,-0.531C-1.14,0.101 -4.816,0.125 -8.523,-0.175C-16.68,-0.834 -24.285,-3.397 -31.778,-6.445C-32.28,-6.65 -32.721,-6.739 -32.356,-7.601C-29.979,-13.216 -27.7,-18.871 -25.397,-24.517C-25.024,-25.432 -24.487,-24.781 -24.174,-24.58C-22.263,-23.347 -20.261,-22.29 -18.235,-21.266C-15.005,-19.632 -11.613,-18.542 -8.003,-18.363C-6.659,-18.296 -5.271,-18.63 -4.207,-19.719C-2.615,-21.35 -3.259,-24.283 -5.387,-25.075C-6.96,-25.661 -8.619,-25.722 -10.242,-26.008C-14.613,-26.779 -18.885,-27.819 -22.595,-30.442C-26.554,-33.241 -28.446,-37.124 -28.739,-41.894C-28.999,-46.127 -27.99,-50.036 -25.637,-53.529C-23.039,-57.386 -19.315,-59.897 -15.075,-61.637C-10.091,-63.682 -4.882,-64.607 0,-64.482"
                  fill={color}
                />
              </g>
              <g transform="matrix(1,0,0,1,454.179,316.115)">
                <path
                  d="M0,1.043C0.236,0.755 0.321,0.634 0.424,0.528C3.413,-2.543 16.432,-16.163 20.028,-19.83C20.809,-20.627 21.536,-21.494 22.31,-22.308C22.653,-22.669 22.907,-22.774 23.143,-22.203C23.226,-22.002 23.458,-21.864 23.622,-21.696C26.613,-18.626 39.436,-5.251 42.892,-1.668C43.678,-0.853 44.472,-0.005 45.34,1.043C43.289,1.043 41.431,1.093 39.578,1.021C38.687,0.987 38.478,1.26 38.482,2.128C38.519,8.963 38.485,15.798 38.523,22.632C38.528,23.54 38.309,23.817 37.372,23.798C34.288,23.736 31.202,23.748 28.117,23.792C27.238,23.804 26.966,23.51 26.981,22.645C27.027,20.068 26.972,17.489 27.013,14.912C27.025,14.169 26.846,13.899 26.052,13.918C23.787,13.971 21.522,13.963 19.257,13.921C18.549,13.908 18.316,14.094 18.329,14.831C18.373,17.486 18.307,20.144 18.367,22.798C18.387,23.682 18.045,23.8 17.284,23.791C14.16,23.753 11.034,23.74 7.911,23.797C7.023,23.814 6.814,23.562 6.819,22.694C6.856,15.86 6.823,9.025 6.859,2.19C6.864,1.291 6.661,0.979 5.714,1.02C3.885,1.099 2.052,1.043 0,1.043"
                  fill={color}
                />
              </g>
              <g transform="matrix(0.00120592,-0.999999,-0.999999,-0.00120592,476.923,256.106)">
                <path
                  d="M-15.225,-15.308C-23.585,-15.477 -30.658,-8.292 -30.608,0.015C-30.559,8.272 -23.775,15.392 -15.225,15.374C-6.9,15.357 0.107,8.572 0.106,-0.067C0.106,-8.395 -6.907,-15.441 -15.225,-15.308"
                  fill={color}
                />
              </g>
            </g>
            <g transform="matrix(5.10737,0,0,1.99637,-3990.47,-460.491)">
              <path
                d="M914.287,250.616L911.539,242.704L911.487,242.704C911.561,243.879 911.597,244.98 911.597,246.009L911.597,250.616L910.191,250.616L910.191,241.142L912.375,241.142L915.006,248.679L915.045,248.679L917.754,241.142L919.944,241.142L919.944,250.616L918.454,250.616L918.454,245.931C918.454,245.46 918.466,244.847 918.489,244.09C918.513,243.334 918.534,242.876 918.551,242.717L918.499,242.717L915.654,250.616L914.287,250.616Z"
                fill={color}
              />
              <path
                d="M921.188,243.449L922.847,243.449L924.306,247.512C924.526,248.091 924.673,248.635 924.746,249.145L924.798,249.145C924.837,248.908 924.908,248.619 925.012,248.28C925.116,247.941 925.664,246.331 926.658,243.449L928.304,243.449L925.239,251.569C924.681,253.059 923.753,253.805 922.452,253.805C922.115,253.805 921.787,253.768 921.467,253.694L921.467,252.489C921.696,252.541 921.957,252.567 922.251,252.567C922.986,252.567 923.502,252.141 923.8,251.29L924.066,250.616L921.188,243.449Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

MyEstiaLogo.defaultProps = {
  color: "#fff",
  style: {},
}

MyEstiaLogo.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default MyEstiaLogo
