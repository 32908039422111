import * as R from 'ramda'
import { ROLES } from '@Auth/Costants'

// receives a permission name as argument and returns a function that requires user as argument
export const hasPermission = (perm) => R.compose(R.not, R.isNil, R.find(R.equals(perm)), R.prop('permissions'))

// receives a role id as argument and returns a function that requires user as argument
export const hasGroup = (roleId) => R.compose(R.not, R.isNil, R.find(R.propEq('id', roleId)), R.prop('groups'))

// returns a function that requires user as argument, when executed checks if the user is a branch level employee
export const isBranchEmployee = R.anyPass([
  hasGroup(ROLES.BRANCH_DIRECTOR),
  hasGroup(ROLES.JUNIOR_ADMIN),
  hasGroup(ROLES.SENIOR_ADMIN),
])

// returns a function that requires user as argument, when executed checks if the user is an HQ Administrative employee
export const isHqAdministrative = hasGroup(ROLES.HQ_ADMINISTRATIVE)

// returns a function that requires user as argument, when executed checks if the user access to operation dashboard on login
export const logsToOperationalDashboard = R.either(isBranchEmployee, isHqAdministrative)

// returns a function that requires user as argument, when executed checks if the user access to admin dashboard on login
export const logsToAdminDashboard = R.complement(logsToOperationalDashboard)
