/* eslint-disable react/prop-types */
import React from 'react'
import { useForm } from 'react-hook-form'

import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { api } from '@Core/Services/Api'
import { SupplierServiceType } from '@Supplier/Models/SupplierServiceType'

export const SupplierServiceTypeForm = ({ defaultValues }) => {
  const form = useForm({ defaultValues })

  const [update] = api.useUpdateOrCreateItemMutation()
  const handleSave = (item) => update({ url: SupplierServiceType.url, item })

  return (
    <FormV2 form={form} onSubmit={handleSave} model={SupplierServiceType}>
      <InputFieldV2 name="name" xs={8} />
      <InputFieldV2 name="status" xs={4} />
      <InputFieldV2 name="activity" xs={12} />
    </FormV2>
  )
}
