import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div``
const Input = styled.input`
  background: ${({ theme, background }) => theme.palette[background].main};
  color: ${({ theme, color }) => theme.palette[color].main};
  border: 0;
  border-radius: 3rem;
  padding: .8rem 1.5rem;

  &:focus-visible {
    outline-width: 0 !important;
  }
`
const Error = styled.div`
  margin-top: .5rem;
  color: ${({ theme, color }) =>theme.palette[color].main} ;
`
const Help = styled.div`
  color: ${({ theme, color }) =>theme.palette[color].main} ;
  margin-top: .5rem;
`

const TextInput = ({ error, errorColor, helperColor, helperText, ...props }) => {
  return (
    <Wrapper>
      <Input {...props} />
      {error && helperText && <Error color={errorColor}>{helperText}</Error>}
      {!error && helperText && <Help color={helperColor}>{helperText}</Help>}
    </Wrapper>
  )
}

TextInput.defaultProps = {
  background: 'whiteBlack',
  color: 'blackWhite',
  errorColor: 'error',
  helperColor: 'info',
}

TextInput.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.bool,
  errorColor: PropTypes.string,
  helperColor: PropTypes.string,
  helperText: PropTypes.string,
}

export default TextInput
