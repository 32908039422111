import { useTranslation } from 'react-i18next'

import { DetailButton } from '@Common/Components/Buttons/DetailButton'

import { SupplierCompanyRatingActions } from './SupplierCompanyRatingActions'

export const SupplierActions = () => {
  const { t } = useTranslation()

  return (
    <>
      <DetailButton label={t('suppliercompany:actionDetail')} />
      <SupplierCompanyRatingActions />
    </>
  )
}
