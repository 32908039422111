import { Box as MuiBox, FormControl, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useTranslation } from 'react-i18next'

import Checkbox from '@Common/Components/Checkbox'
import FileField from '@Common/Components/FileField'
import { Help } from '@Common/Components/Forms'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Box, Cursor, Label, Margin } from '@Common/Components/Styles'
import { TabPanel } from '@Common/Components/TabPanel'
import TextField from '@Common/Components/TextField'
import { setStateFromCheckboxEvent, setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { ServiceType } from '@Services/Models/Service'

import CategorySelect from '../Components/admin/CategorySelect'
import LabelSelect from '../Components/admin/LabelSelect'
import ProviderSelect from '../Components/admin/ProviderSelect'
import { types } from '../Constants'
import { getErroredFieldTab, serviceValidate } from './Validation'

const ServiceForm = ({ service, method, onClose, onSave }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const title = t(`services:ui.${service?.id ? 'Modify' : 'Add'}Service`)
  const { fields, setField, errors, setErrors } = useForm(
    {
      isActive: R.defaultTo(false, service?.isActive),
      title: R.defaultTo('', service?.title),
      category: R.defaultTo('', service?.category),
      listDescription: R.defaultTo('', service?.listDescription),
      provider: R.defaultTo('', service?.provider),
      label: R.defaultTo('', service?.label),
      descriptionText: service
        ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(service.descriptionText)))
        : EditorState.createEmpty(),
      howText: service
        ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(service.howText)))
        : EditorState.createEmpty(),
      whyText: service
        ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(service.whyText)))
        : EditorState.createEmpty(),
      saleMethodUrl: R.defaultTo('', service?.saleMethod?.url),
      saleMethodName: R.defaultTo('', service?.saleMethod?.name),
      saleMethodType: R.defaultTo('', service?.id ? service?.saleMethod?.type : method),
      flier: [],
      listImage: [],
      detailImage: [],
      slug: R.defaultTo('', service?.slug),
      orderingNumber: R.defaultTo('', service?.orderingNumber),
      removeFlier: false,
    },
    true,
  )

  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  const handleActiveTabIndex = () => {
    if (errors && R.keys(errors).length) setActiveTabIndex(getErroredFieldTab(errors))
  }
  React.useEffect(handleActiveTabIndex, [errors])

  const onFlierDrop = (acceptedFiles) => {
    setField('flier', 'array')(acceptedFiles)
  }
  const onListDrop = (acceptedFiles) => {
    setField('listImage', 'array')(acceptedFiles)
  }

  const onDetailDrop = (acceptedFiles) => {
    setField('detailImage', 'array')(acceptedFiles)
  }

  const changeCategory = (category) => {
    setField('category')(category.target.value)
  }
  const changeLabel = (label) => {
    setField('label')(label.target.value)
  }
  const changeProvider = (provider) => {
    setField('provider')(provider.target.value)
  }

  const onDescriptionChange = setField('descriptionText')
  const onHowChange = setField('howText')
  const onWhyChange = setField('whyText')
  const submit = async () => {
    logger.debug('Service form submission, fields:', fields)
    if (serviceValidate(fields, setErrors, service, method)) {
      logger.debug('Service form submission, validation passed, saving')
      setIsSubmitting(true)
      const { error, isSuccessful } = await onSave(fields)
      if (!isSuccessful && error?.status >= 400 && error?.status <= 499) {
        setIsSubmitting(false)
        logger.debug('Service form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('Service form submission, validation failed')
    }
  }
  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
        <Tabs value={activeTabIndex} onChange={(_, idx) => setActiveTabIndex(idx)} style={{ overflow: 'auto' }}>
          <Tab label={t('services:fields.service.ServiceData')} />
          <Tab label={t('services:fields.service.ServiceFiles')} />
          <Tab label={t('services:fields.service.SaleMethod')} />
        </Tabs>
      </MuiBox>
      <TabPanel activeIndex={activeTabIndex} index={0}>
        <Box margin="0 0 1rem">
          <Checkbox
            checked={fields.isActive}
            onChange={setStateFromCheckboxEvent(setField('isActive'))}
            label={t('services:fields.service.IsActive')}
          />
        </Box>
        <Grid container rowSpacing={3} spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                label={t('services:fields.service.Title')}
                value={fields.title}
                onChange={setStateFromEvent(setField('title'))}
                error={!!errors.title}
                helperText={errors.title}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <CategorySelect
                required
                value={fields.category}
                exclude={[]}
                id="0"
                label={t('services:fields.service.Category')}
                onChange={changeCategory}
                error={!!errors.category}
                helperText={errors.category}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <ProviderSelect
                required
                value={fields.provider}
                label={t('services:fields.service.Provider')}
                onChange={changeProvider}
                error={!!errors.provider}
                helperText={errors.provider}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <LabelSelect
                value={fields.label}
                label={t('services:fields.service.Label')}
                onChange={changeLabel}
                error={!!errors.label}
                helperText={errors.label}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                type="number"
                label={t('services:fields.service.OrderingNumber')}
                value={fields.orderingNumber}
                onChange={setStateFromEvent(setField('orderingNumber'))}
                error={!!errors.orderingNumber}
                helperText={errors.orderingNumber ? errors.orderingNumber : t('services:ui.OrderServiceHelper')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                multiline
                required
                label={t('services:fields.service.ListDescription')}
                value={fields.listDescription}
                onChange={setStateFromEvent(setField('listDescription'))}
                error={!!errors.listDescription}
                helperText={errors.listDescription}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Margin bottom="1rem" top="1rem">
              <Label>{t('services:fields.service.DescriptionText')} *</Label>
            </Margin>
            <FormControl fullWidth>
              <Editor
                editorState={fields.descriptionText}
                wrapperClassName="wysiwyg-wrapper"
                editorClassName="wysiwyg-editor"
                onEditorStateChange={onDescriptionChange}
              />
              {errors.descriptionText && <Help error={errors.descriptionText}>{errors.descriptionText}</Help>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Margin bottom="1rem" top="1rem">
              <Label>{t('services:fields.service.HowText')} *</Label>
            </Margin>
            <FormControl fullWidth>
              <Editor
                editorState={fields.howText}
                wrapperClassName="wysiwyg-wrapper"
                editorClassName="wysiwyg-editor"
                onEditorStateChange={onHowChange}
              />
              {errors.howText && <Help error={errors.howText}>{errors.howText}</Help>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Margin bottom="1rem" top="1rem">
              <Label>{t('services:fields.service.WhyText')} *</Label>
            </Margin>
            <FormControl fullWidth>
              <Editor
                editorState={fields.whyText}
                wrapperClassName="wysiwyg-wrapper"
                editorClassName="wysiwyg-editor"
                onEditorStateChange={onWhyChange}
              />
              {errors.whyText && <Help error={errors.whyText}>{errors.whyText}</Help>}
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel activeIndex={activeTabIndex} index={1}>
        <Grid container rowSpacing={3} spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <Margin bottom="1rem" top="1rem">
                <Label>{t('services:fields.service.Flier')} </Label>
              </Margin>
              {service?.flier && !fields.removeFlier && (
                <Box direction="row" margin="0 0 1rem">
                  <Typography marginRight="1rem">{t('common:ui.CurrentFile')}:</Typography>
                  <a href={service.flier} rel="noreferrer" target="_blank" style={{ maxWidth: '100%' }}>
                    {t('services:ui.ShowFlier')}
                  </a>
                  <Tooltip title={t('common:actions.Delete')}>
                    <Typography
                      onClick={() => setField('removeFlier')(true)}
                      marginLeft="0.75rem"
                      color="error"
                      variant="span"
                    >
                      <Cursor type="pointer">&times;</Cursor>
                    </Typography>
                  </Tooltip>
                </Box>
              )}
              <FileField
                onDrop={onFlierDrop}
                files={fields.flier}
                accept={'image/jpeg,image/png,application/pdf'}
                warningText={t('services:info.FlierLimit')}
                error={!!errors.flier}
                helperText={errors.flier}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <Margin bottom="1rem" top="1rem">
                <Label>{t('services:fields.service.ListImage')} *</Label>
              </Margin>
              {service?.listImage && (
                <Box margin="0 0 1rem">
                  {t('common:ui.CurrentImage')}
                  {': '}
                  <Margin top="1rem">
                    <img src={service.listImage} style={{ maxWidth: '100%' }} />
                  </Margin>
                </Box>
              )}
              <FileField
                onDrop={onListDrop}
                files={fields.listImage}
                accept={'image/jpeg,image/png'}
                warningText={t('news:info.ImageLimit')}
                error={!!errors.listImage}
                helperText={errors.listImage}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <Margin bottom="1rem" top="1rem">
                <Label>{t('services:fields.service.DetailImage')} *</Label>
              </Margin>
              {service?.detailImage && (
                <Box margin="0 0 1rem">
                  {t('common:ui.CurrentImage')}
                  {': '}
                  <Margin top="1rem">
                    <img src={service.detailImage} style={{ maxWidth: '100%' }} />
                  </Margin>
                </Box>
              )}
              <FileField
                onDrop={onDetailDrop}
                files={fields.detailImage}
                accept={'image/jpeg,image/png'}
                warningText={t('news:info.ImageLimit')}
                error={!!errors.detailImage}
                helperText={errors.detailImage}
              />
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel activeIndex={activeTabIndex} index={2}>
        {method === types.external && (
          <Grid container rowSpacing={3} spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label={t('services:fields.external.Name')}
                  value={fields.saleMethodName}
                  onChange={setStateFromEvent(setField('saleMethodName'))}
                  error={!!errors.saleMethodName}
                  helperText={errors.saleMethodName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  required
                  label={t('services:fields.external.Url')}
                  value={fields.saleMethodUrl}
                  onChange={setStateFromEvent(setField('saleMethodUrl'))}
                  error={!!errors.saleMethodUrl}
                  helperText={errors.saleMethodUrl}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </Modal>
  )
}

ServiceForm.propTypes = {
  service: ServiceType,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  method: PropTypes.string,
}

export default ServiceForm
