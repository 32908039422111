/* eslint-disable react/prop-types */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'

import { CheckboxFieldV2 } from '@Common/Components/FormsV2/CheckboxFieldV2'
import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { api } from '@Core/Services/Api'
import { CondoSupplierCompany, SupplierAssociationService } from '@Supplier/Models/CondoSupplierCompany'

export const CondoSupplierCompanyServicesForm = ({ defaultValues, setQsAdditionSupplier, isForm = false }) => {
  const { t } = useTranslation()

  const form = useForm({ defaultValues })

  const { buildingId } = useParams()

  const urlAssociation = generatePath(CondoSupplierCompany.url, {
    buildingId: buildingId,
  })

  const [update] = api.useUpdateOrCreateItemMutation()

  const handleSave = (item) => {
    let data = (data = {
      ...item,
      bicertSupplierId: defaultValues.bicertSupplierId,
      bicertSupplierBranchIds: defaultValues.bicertSupplierBranchIds,
    })

    return update({ url: urlAssociation, item: data })
  }

  useEffect(() => {
    const services = form.getValues('services')

    if (services) {
      setQsAdditionSupplier({ qsAdditions: { services } })
    }
  }, [form.watch('services')])

  const result = (children) => {
    if (isForm) {
      return (
        <FormV2 form={form} onSubmit={handleSave} model={CondoSupplierCompany} isTableField={true}>
          <TableContainer>
            <Table sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.activity`)}</TableCell>
                  <TableCell>{t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.name`)}</TableCell>
                  <TableCell>
                    {t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.hasContract`)}
                  </TableCell>
                  <TableCell>
                    {t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.isPrivileged`)}
                  </TableCell>
                  <TableCell>
                    {t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.hasMandateExclusivity`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{children}</TableBody>
            </Table>
          </TableContainer>
        </FormV2>
      )
    } else {
      return (
        <TableContainer>
          <Table sx={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.activity`)}</TableCell>
                <TableCell>{t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.name`)}</TableCell>
                <TableCell>{t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.hasContract`)}</TableCell>
                <TableCell>{t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.isPrivileged`)}</TableCell>
                <TableCell>
                  {t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.hasMandateExclusivity`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
      )
    }
  }

  return result(
    <InputFieldV2
      name="serviceList"
      template={InputsServiceListFields}
      showBtnAdd={false}
      showBtnDelete={false}
      isTableField={true}
    />,
  )
}

export const InputsServiceListFields = ({ index, value }) => {
  return (
    <>
      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {value.activity}
        </TableCell>
        <TableCell component="th" scope="row">
          {value.name}
        </TableCell>
        <TableCell align="right">
          <CheckboxFieldV2 name={`serviceList[${index}].hasContract`} label={''} />
        </TableCell>
        <TableCell align="right">
          <CheckboxFieldV2 name={`serviceList[${index}].isPrivileged`} label={''} />
        </TableCell>
        <TableCell align="right">
          <CheckboxFieldV2 name={`serviceList[${index}].hasMandateExclusivity`} label={''} />
        </TableCell>
      </TableRow>
    </>
  )
}
