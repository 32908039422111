import { useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import AddFab from '@Common/Components/AddFab'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import InsurancePolicyList from '@Condo/Components/InsurancePolicyList'
import InsurancePolicyForm from '@Condo/Forms/InsurancePolicy/InsurancePolicyForm'
import { InsurancePolicyPermissions } from '@Condo/Permissions'
import {
  useUpdateInsurancePolicyMutation,
  useCreateInsurancePolicyMutation,
  useUpdateAllFieldInsurancePolicyMutation,
  useDeleteInsurancePolicyMutation,
  useCloseInsurancePolicyMutation,
  useReopenInsurancePolicyMutation,
} from '@Condo/Services/ApiInsurancePolicy'

const BuildingInsurancePolicyView = () => {
  const user = useCurrentUser()
  const { buildingId } = useParams()
  const { t } = useTranslation()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [isBuildingDetail, setIsBuildingDetail] = useState(false)

  useEffect(() => {
    if (buildingId) {
      setIsBuildingDetail(true)
    }
  }, [buildingId])

  // api
  const [createInsurancePolicy] = useCreateInsurancePolicyMutation()
  const [updateInsurancePolicy] = useUpdateInsurancePolicyMutation()
  const [updateAllFieldInsurancePolicy] = useUpdateAllFieldInsurancePolicyMutation()
  const [deleteInsurancePolicy] = useDeleteInsurancePolicyMutation()
  const [closeInsurancePolicy] = useCloseInsurancePolicyMutation()
  const [reopenInsurancePolicy] = useReopenInsurancePolicyMutation()

  // form
  const [form, setForm] = useState({
    isOpen: false,
    insurancePolicy: {
      building: buildingId ?? null,
    },
    isEditing: false,
  })

  const baseBreadcrumbs = buildingId ? getCondoBreadcrumbFromId(buildingId) : []
  const reconciliationBreadcrumbItem = {
    label: t('common:navigation.InsurancePolicyManagement'),
    Icon: !buildingId && 'BuildingManagement',
  }

  useBreadcrumbs([...baseBreadcrumbs, reconciliationBreadcrumbItem], baseBreadcrumbs)

  const handleOpenCreateForm = (id) =>
    setForm({
      isOpen: true,
      insurancePolicy: { building: id ?? buildingId ?? null },
      isEditing: false,
    })

  const handleOpenEditForm = (insurancePolicy) => {
    setForm({ isOpen: true, insurancePolicy: insurancePolicy, isEditing: true })
  }

  const handleCloseForm = () =>
    setForm({
      isOpen: false,
      insurancePolicy: { building: buildingId ?? null },
      isEditing: false,
    })

  const handleSave = (fields) => {
    return form.isEditing ? handleUpdateInsurancePolicy(fields) : handleCreateInsurancePolicy(fields)
  }

  const handleCreateInsurancePolicy = async (fields) => {
    try {
      const response = await createInsurancePolicy(fields).unwrap()

      toast.success(
        t('condo:success.InsurancePolicyCreated', {
          name: form.insurancePolicy.description,
        }),
      )

      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.CreateInsurancePolicyError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateInsurancePolicy = async (fields) => {
    try {
      const response = !isBuildingDetail
        ? await updateAllFieldInsurancePolicy(fields).unwrap()
        : await updateInsurancePolicy(fields).unwrap()

      toast.success(t('condo:success.InsurancePolicyUpdated', { name: fields.description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.UpdateInsurancePolicyError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (fields) => {
    try {
      const response = await deleteInsurancePolicy(fields[0].id).unwrap()
      toast.success(t('condo:success.InsurancePolicyDeleted', { name: fields[0].description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.DeleteInsurancePolicyError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleCloseInsurance = async (fields) => {
    try {
      const response = await closeInsurancePolicy(fields[0].id).unwrap()
      toast.success(t('condo:success.InsurancePolicyClosed', { name: fields[0].description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.CloseInsurancePolicyError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleReopenInsurance = async (fields) => {
    try {
      const response = await reopenInsurancePolicy(fields[0].id).unwrap()
      toast.success(t('condo:success.InsurancePolicyReopened', { name: fields[0].description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.ReopenInsurancePolicyError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <>
      <InsurancePolicyList
        buildingId={buildingId}
        onEdit={handleOpenEditForm}
        onDelete={handleDelete}
        onCreate={handleOpenCreateForm}
        onCloseInsurance={handleCloseInsurance}
        onReopenInsurance={handleReopenInsurance}
      />

      {form.isOpen && (
        <InsurancePolicyForm
          insurancePolicy={form.insurancePolicy}
          onSave={handleSave}
          onClose={handleCloseForm}
          showSelectBuilding={!isBuildingDetail}
          isEditing={form.isEditing}
        />
      )}

      {InsurancePolicyPermissions.create(user) && (
        <AddFab
          onClick={() => handleOpenCreateForm()}
          right={isBuildingDetail && !isSmallDevice ? '82px' : '28px'}
          bottom={isBuildingDetail && isSmallDevice ? '82px' : '28px'}
        />
      )}
    </>
  )
}

export default BuildingInsurancePolicyView
