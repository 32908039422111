import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { useCondoByIdQuery } from '@Condo/Services/Api'

import EdificesTabs from '../Components/EdificesTabs'

const Main = styled.section``

const BuildingStructureView = () => {
  const { t } = useTranslation()
  const { buildingId } = useParams()
  const { data: building } = useCondoByIdQuery(buildingId)

  useBreadcrumbs(
    [...getCondoBreadcrumbFromId(buildingId), { label: t('common:navigation.BuildingStructure') }],
    building?.description,
    [building],
  )

  return (
    <Main>
      {withLoader(
        () => (
          <>
            <EdificesTabs buildingArcadiaId={building?.arcadiaId} />
          </>
        ),
        !building?.arcadiaId,
      )}
    </Main>
  )
}

export default BuildingStructureView
