// import * as R from 'ramda'
import { hasPermission } from '@Common/Utils/Permissions'
// import { ROLES } from '@Auth/Costants'

export const BranchPermissions = {
  read: hasPermission('company.view_branch'),
  create: hasPermission('company.add_branch'),
  update: hasPermission('company.change_branch'),
}

// export const canAccessBranchesView = R.anyPass([
//   R.prop('isSuperUser'),
//   hasGroup(ROLES.HQ_IT),
//   hasGroup(ROLES.HQ_MAIN_OFFICE),
// ])

export const canAccessBranchesView = BranchPermissions.read

