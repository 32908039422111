import React from 'react'
import PropTypes from 'prop-types'
import { FieldType } from '@Common/Types/Field'
import { useTopicsQuery } from '../Services/Api'
import Dropdown from '@Common/Components/Dropdown'
import * as R from 'ramda'
import { apiList } from '@Core/Services/Api'

const TopicSelect = ({ value, ...other }) => {
  const { data } = apiList(useTopicsQuery())
  const topics = data.map(R.pick(['id', 'name']))

  return (
    <Dropdown
      options={topics}
      labelKey="name"
      valueKey="id"
      value={topics.length > 0 ? value : ''}
      {...other}
    />
  )
}

TopicSelect.propTypes = {
  ...FieldType,
  value: PropTypes.number,
}

export default TopicSelect
