import styled from 'styled-components'

export const TextNoWrap = styled.span`
  white-space: nowrap;
`

export const Cursor = styled.div`
  cursor: ${(props) => props.type};
`

export const Margin = styled.div`
  margin-bottom: ${(props) => props.bottom || 0};
  margin-left: ${(props) => props.left || 0};
  margin-right: ${(props) => props.right || 0};
  margin-top: ${(props) => props.top || 0};
`

export const Box = styled.div`
  ${({ theme, background }) =>
    background && theme
      ? `background: ${theme.palette[background]?.main}; color: ${theme.palette[background]?.contrastText};`
      : ''}
  align-items: ${(props) => props.align || 'flex-start'};
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  flex-grow: ${(props) => props.grow || 0};
  flex-wrap: ${(props) => (props.$wrap ? 'wrap' : 'nowrap')};
  height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => props.minHeight || ''};
  justify-content: ${(props) => props.justify || 'flex-start'};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.pad || '0'};
  gap: ${(props) => props.gap || '0'};
  position: ${(props) => props.position || 'static'};
  width: ${(props) => props.width || 'auto'};
  border-radius: ${(props) => props.radius || '0'};
`

export const FormRow = styled(Box)`
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `}
  > div {
    box-sizing: border-box;
    margin: ${(props) => (props.margin !== undefined ? props.margin : '0 0 2rem 0')};
    padding: 0;
    width: 100%;

    ${({ theme, ...props }) => `
      ${theme.breakpoints.up('md')} {
        width: ${props.columns ? Math.floor(100 / props.columns) + '%' : '100%'};
        &:first-child {
          padding: 0 1rem 0 0 !important;
        }
        &:last-child {
          padding: 0 0 0 1rem;
        }
      }
    `}
  }
`

export const FormCol = styled(Box)`
  flex-direction: column;
  width: 100%;

  > div {
    box-sizing: border-box;
    margin-bottom: 2rem;
  }
`

export const Position = styled.div`
  position: ${({ absolute, fixed }) => (absolute ? 'absolute' : fixed ? 'fixed' : 'static')};
  top: ${({ top }) => top || 'auto'};
  bottom: ${({ bottom }) => bottom || 'auto'};
  left: ${({ left }) => left || 'auto'};
  right: ${({ right }) => right || 'auto'};
`

export const Label = styled.label`
  color: ${({ theme }) => theme.palette.label.main};
`

export const GeneralFilterContainer = styled.div`
  padding-left: 0.5rem;
  width: 100%;
`
