/* eslint-disable react/prop-types */

/**
 * This component get item from the context
 * and render item link
 */
import { ChevronRight } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { isAdminRoute } from '@Common/Utils/Url'

import { ItemContainerContext } from '../ItemContainer'

export const DetailButton = (props) => {
  const { item, model } = useContext(ItemContainerContext)
  const { t } = useTranslation()
  const label = props?.label || t('common:actions.Detail')
  const icon = props?.icon || <ChevronRight />
  const location = useLocation()
  const navigate = useNavigate()

  const itemUrl = `${isAdminRoute() ? '/admin' : ''}${model.url
    .replace('/condo/bicert/', '/supplier/')
    .replace(':id', item?.id)}`

  const url = props?.url || itemUrl

  // we are already in detail and so we don't want to show button
  if (url === location.pathname) return null

  return (
    <Button
      size="small"
      disableElevation
      variant="contained"
      sx={{ marginLeft: '.5rem' }}
      underline="always"
      startIcon={icon}
      onClick={() => navigate(url)}
    >
      <Typography variant="subtitle2" style={{ marginTop: '2px' }}>
        {label}
      </Typography>
    </Button>
  )
}
