import { CardContent, CardHeader } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'
import { Box, Margin } from '@Common/Components/Styles'
import CreateTask from '@Tms/Components/CreateTask'

const BuildingData = ({ data, taskInitValues }) => {
  const { t } = useTranslation()

  return (
    <Margin bottom="2rem">
      <Card variant="outlined">
        <CardHeader
          title={
            <Box direction="row" gap="1rem" align="center">
              {t('buildings:ui.BuildingData')}
              <CreateTask defaultValues={taskInitValues} buttonProps={{ variant: 'outlined' }} />
            </Box>
          }
        ></CardHeader>
        <CardContent>
          <NameValueList data={data} />
        </CardContent>
      </Card>
    </Margin>
  )
}

BuildingData.propTypes = {
  data: PropTypes.array.isRequired,
  taskInitValues: PropTypes.object,
}

export default BuildingData
