/* eslint-disable react/prop-types */

/**
 * We often need to create buttons that open modals.
 * We created this component to avoid having to manually manage the status, etc etc
 */
import { Button, Dialog, DialogTitle, Fab, Tooltip, Typography } from '@mui/material'
import { createContext, useState } from 'react'

export const DialogContext = createContext()

export const ButtonDialog = (props) => {
  const { dialogTitle, title, type, icon, maxWidth, children, tooltip, ...otherProps } = props

  /** handler and dialog state  */
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = (event, reason) => {
    /**
     * Disable backdropClick and escapeKeyDown on close default model
     */
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false)
    }
  }

  /**
   * In some case we need to close the modal outside this componet.
   * We expose methos and some props with context
   */
  const context = {
    title: title,
    handleOpen: handleOpen,
    handleClose: handleClose,
  }

  /** render button based type prop (button or fab) */
  const renderButton = () => {
    if (type === 'fab') {
      return (
        <Fab size="medium" color="primary" {...otherProps} onClick={handleOpen}>
          {icon}
        </Fab>
      )
    }

    return (
      <Button startIcon={icon} {...otherProps} onClick={handleOpen}>
        <Typography variant="subtitle2" sx={{ pt: '2px' }}>
          {title}
        </Typography>
      </Button>
    )
  }

  return (
    <DialogContext.Provider value={context}>
      <Tooltip title={tooltip || ''}>{renderButton()}</Tooltip>
      <Dialog fullWidth maxWidth={maxWidth || 'md'} onClose={handleClose} open={open} repositionOnUpdate={false}>
        <DialogTitle>{dialogTitle || title}</DialogTitle>
        {children}
      </Dialog>
    </DialogContext.Provider>
  )
}
