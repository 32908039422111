/* eslint-disable react/prop-types */

/**
 * This component get item from the context
 * and implements the logic of delete with cofirmation dialog
 */
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { api } from '@Core/Services/Api'

import { ButtonDialog } from '../ButtonDialog'
import { DiscardDialogButton } from './DiscardDialogButton'

export const DeleteButton = (props) => {
  const { url } = props

  const { t } = useTranslation()

  const [deleteItem] = api.useDeleteItemMutation()

  const handleDelete = async () => {
    try {
      await deleteItem(url).unwrap()
      toast.success(t('common:success.GeneralServerSuccess'))
      return Promise.resolve()
    } catch (response) {
      toast.error(t('common:errors.MessageGenericError', { error: response?.data?.detail }))
      return Promise.reject()
    }
  }

  return (
    <ButtonDialog
      size="small"
      disableElevation
      variant="contained"
      sx={{ marginLeft: '.5rem' }}
      icon={<DeleteIcon />}
      title={t('common:actions.Delete')}
      color="error"
    >
      <DialogContent dividers>{t('common:ui.ConfirmMessage')}</DialogContent>
      <DialogActions>
        <DiscardDialogButton />
        <Button onClick={() => handleDelete()} color="error">
          <Typography variant="subtitle2">{t('common:actions.Confirm')}</Typography>
        </Button>
      </DialogActions>
    </ButtonDialog>
  )
}
