import { api } from '@Core/Services/Api'

const prefix = 'location'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    regions: builder.query({
      query: () => `${prefix}/regions`,
    }),
    provinces: builder.query({
      query: () => `${prefix}/provinces`,
    }),
    cities: builder.query({
      query: () => `${prefix}/cities`,
    }),
  }),
  overrideExisting: false,
})

export const { useRegionsQuery, useProvincesQuery, useCitiesQuery } = extendedApi
