import { api, apiQueryString } from '@Core/Services/Api'
const prefix = 'notification-center'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    topics: builder.query({
      query: () => `${prefix}/topics`,
      providesTags: ['NotificationTopic'],
    }),
    notifications: builder.query({
      query: () => `${prefix}/notifications`,
      providesTags: ['Notification'],
    }),
    allNotifications: builder.query({
      query: (qs) => `${prefix}/notifications/all?${apiQueryString(qs)}`,
      providesTags: ['Notification'],
    }),
    readNotification: builder.mutation({
      query: (id) => ({
        url: `${prefix}/notifications/${id}/read`,
        method: 'POST',
      }),
      invalidatesTags: ['Notification'],
    }),
    cancelNotification: builder.mutation({
      query: (id) => ({
        url: `${prefix}/notifications/${id}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Notification'],
    }),
    createResidentsNotifications: builder.mutation({
      query: (notification) => ({
        url: `${prefix}/notifications/notify_residents`,
        body: notification,
        method: 'POST',
      }),
      invalidatesTags: ['Notification'],
    }),
    createBuildingsNotifications: builder.mutation({
      query: (notification) => ({
        url: `${prefix}/notifications/notify_buildings`,
        body: notification,
        method: 'POST',
      }),
      invalidatesTags: ['Notification'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useTopicsQuery,
  useNotificationsQuery,
  useAllNotificationsQuery,
  useReadNotificationMutation,
  useCancelNotificationMutation,
  useCreateResidentsNotificationsMutation,
  useCreateBuildingsNotificationsMutation,
} = extendedApi
