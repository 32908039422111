import React, { useEffect, useState } from 'react'
import { FieldType } from '@Common/Types/Field'

import Dropdown from '@Common/Components/Dropdown'
import { useUnpaginatedServiceProvidersQuery } from '@Services/Services/Api'
import * as R from 'ramda'

const ProviderSelect = ({ value, ...props }) => {
  const { data } = useUnpaginatedServiceProvidersQuery()

  const filterProviders = R.pipe(
    R.defaultTo([]),
    R.map(R.pick(['id', 'name'])),
    R.sortBy(R.prop('name')),

  )

  const [providers, setProviders] = useState([])
  useEffect(() => {
    setProviders(filterProviders(data?.results))
  }, [data?.results, value])

  return (
    <Dropdown
      options={providers}
      labelKey="name"
      valueKey="id"
      value={data?.results.length > 0 ? value : ''}
      {...props}
    />
  )
}

ProviderSelect.propTypes = {
  ...FieldType,
}

export default ProviderSelect
