import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import BuildingDetail from '@Buildings/Components/BuildingDetail'
import { useBuildingQuery } from '@Buildings/Services/Api'
import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import AcquisitionData from '@Common/Components/BuildingDetail/AcquisitionData'
import BranchData from '@Common/Components/BuildingDetail/BranchData'
import Loader from '@Common/Components/Loader'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import CondoBuildingDetail from '@Condo/Components/CondoBuildingDetail'
import { CondoType } from '@Condo/Models/Condo'
import { useCondoByIdQuery } from '@Condo/Services/Api'

const Main = styled.section``

const BuildingMainView = () => {
  const { t } = useTranslation()

  const { buildingId } = useParams()

  const { data: building } = useCondoByIdQuery(buildingId)

  useBreadcrumbs(
    [...getCondoBreadcrumbFromId(buildingId), { label: t('buildings:ui.GeneralData') }],
    building?.description,
    [building],
  )

  const taskInitValues = {
    branch: building?.branch ?? '',
    building: building?.id ? `${building.id}_building` : '',
  }

  if (building?.recordType === 'LOCAL' || building?.recordType === 'INSRT') {
    return (
      <Main>
        {withLoader(
          () => (
            <>
              <CondoBuildingDetail building={building} taskInitValues={taskInitValues} />
            </>
          ),
          !building || R.isEmpty(building),
        )}
      </Main>
    )
  }

  if (building?.recordType === 'CACHE') {
    return (
      <>
        <CachedLocalBuildingDetail arcadiaId={building.arcadiaId} localBuilding={building} />
      </>
    )
  }

  return <Loader></Loader>
}

const CachedLocalBuildingDetail = ({ arcadiaId, localBuilding }) => {
  const { data: building } = useBuildingQuery(arcadiaId)

  const taskInitValues = {
    branch: building?.extra?.branch ?? '',
    building: building?.extra?.id ? `${building.extra.id}_branchbuilding` : '',
  }

  return (
    <Main>
      {withLoader(
        () => (
          <>
            <BuildingDetail building={building} taskInitValues={taskInitValues} />
            <AcquisitionData acquisitionData={localBuilding} />
            <BranchData branchData={building?.extra} />
          </>
        ),
        !building || R.isEmpty(building),
      )}
    </Main>
  )
}

CachedLocalBuildingDetail.propTypes = {
  arcadiaId: PropTypes.string.isRequired,
  localBuilding: CondoType,
}

export default BuildingMainView
