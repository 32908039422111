/* eslint-disable react/prop-types */
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import { EditButton } from '@Common/Components/Buttons/EditButton'
import { ItemContainer } from '@Common/Components/ItemContainer'
import { Page } from '@Common/Components/Page'
import { ItemCard } from '@Common/Components/SectionCard'
import { TabContainer, TabPanel } from '@Common/Components/TabContainer'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { api } from '@Core/Services/Api'
import { CondoSupplierCompanyDetail } from '@Supplier/Components/CondoSupplierCompanyDetail'
import { SupplierCompanyDetailAnagrafic } from '@Supplier/Components/SupplierCompanyDetailAnagrafic'
import { CondoSupplierCompany } from '@Supplier/Models/CondoSupplierCompany'

import { SupplierBranchListView } from './SupplierBranchListView'
import { SupplierCompanyContractListView } from './SupplierCompanyContractListView'
import { SupplierCompanyServiceListView } from './SupplierCompanyServiceListView'
import { SupplierReferentListView } from './SupplierReferentListView'

export const CondoSupplierCompanyDetailView = ({ supplierData }) => {
  const { buildingId, condoSupplierId } = useParams()
  const { t } = useTranslation()

  const { data, isLoading, error } = api.useGetItemQuery(`/condo/buildings/${buildingId}/suppliers/${condoSupplierId}`)

  const [isBuildingDetail, setIsBuildingDetail] = useState(false)

  const baseBreadcrumbs = getCondoBreadcrumbFromId(buildingId)
  const breadcrumbItem = [
    {
      label: t('common:navigation.SupplierManagement'),
    },
    {
      label: data?.name,
      Icon: !buildingId && 'EngineeringIcon',
    },
  ]

  useBreadcrumbs(
    isBuildingDetail ? [...baseBreadcrumbs, ...breadcrumbItem] : breadcrumbItem,
    isBuildingDetail ? baseBreadcrumbs : {},
  )

  useEffect(() => {
    if (buildingId) {
      setIsBuildingDetail(true)
    }
  }, [buildingId])

  return (
    <Page loader={isLoading} error={error}>
      <Stack>
        <ItemContainer item={data} model={CondoSupplierCompany}>
          <Stack direction="row">
            <Box flexGrow={1}>
              <Typography variant="h5">
                <strong>{data?.name}</strong>
              </Typography>
            </Box>
            <EditButton />
          </Stack>
        </ItemContainer>

        <TabContainer>
          <TabPanel title="Anagrafica" id="anagraficad demo">
            <Grid spacing={3} container>
              <Grid item md={12}>
                <CondoSupplierCompanyDetail />
              </Grid>
              <Grid item md={12}>
                <SupplierCompanyDetailAnagrafic item={supplierData} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel title="Contratti attivi">
            <SupplierCompanyContractListView />
          </TabPanel>
          <TabPanel title="Servizi">
            <SupplierCompanyServiceListView />
          </TabPanel>
          <TabPanel title="Sedi">
            <ItemCard>
              <SupplierBranchListView url={`/condo/buildings/${buildingId}/suppliers/${condoSupplierId}/branches`} />
            </ItemCard>
          </TabPanel>
          <TabPanel title="Referenti">
            <ItemCard>
              <SupplierReferentListView url={`/condo/buildings/${buildingId}/suppliers/${condoSupplierId}/referents`} />
            </ItemCard>
          </TabPanel>
        </TabContainer>
      </Stack>
    </Page>
  )
}
