import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ROLES } from '@Auth/Costants'
import { useRolesQuery } from '@Auth/Services/Api'
import CheckboxGroupField from '@Common/Components/Forms/CheckboxGroupField'

const filterRoles = R.pipe(
  R.defaultTo([]),
  R.filter(R.complement(R.propEq('id', ROLES.RESIDENT))),
  R.map(R.pick(['id', 'name'])),
)

const RolesCheckboxes = () => {
  const { data } = useRolesQuery()
  const [roles, setRoles] = useState([])
  const { control } = useFormContext()

  useEffect(() => {
    if (data) setRoles(filterRoles(data.results))
  }, [data?.results])

  return <CheckboxGroupField control={control} options={roles} name="openPrivileges" labelKey="name" valueKey="id" />
}

export default RolesCheckboxes
