import { hasPermission } from '@Common/Utils/Permissions'

export const ReportsPermissions = {
  read: hasPermission('tickets.view_report'),
  create: hasPermission('tickets.add_report'),
  update: hasPermission('tickets.change_report'),
  delete: hasPermission('tickets.delete_report'),
}

export const ReportTypesPermissions = {
  read: hasPermission('tickets.view_reporttype'),
  create: hasPermission('tickets.add_reporttype'),
  update: hasPermission('tickets.change_reporttype'),
  delete: hasPermission('tickets.delete_reporttype'),
}
