import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
`

const Label = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  position: absolute;
  left: 0;
  top: -1.5px;
  color: ${({ theme: { palette } }) => palette.label.main};
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em;
  padding: 0;
`

const Content = styled.div`
  margin-top: ${({ mt }) => mt ?? '16px'};
  position: relative;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  color: currentColor;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  width: 100%;
`

const LabeledBox = ({ label, children, contentProps }) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Content {...contentProps}>{children}</Content>
    </Container>
  )
}

LabeledBox.defaultProps = {
  contentProps: {},
}

LabeledBox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  contentProps: PropTypes.object,
}

export default LabeledBox
