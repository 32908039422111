import * as R from 'ramda'
import React from 'react'

import { getEmployeeInfo } from '@Auth/Utils'
import DataTable from '@Common/Components/DataTable'
import { RunningTaskType } from '@Tms/Models/RunningTask'
import RunningTaskActivity from '@Tms/Models/RunningTaskActivity'

const DISPLAY_FIELDS = ['created', 'description', 'createdBy']

const FIELDS_MAPPING = {
  createdBy: R.pipe(R.prop('createdBy'), getEmployeeInfo),
}

const TaskActivityList = ({ task }) => {
  return (
    <DataTable
      name="tms-task-activity-log"
      data={task.activityLog}
      model={RunningTaskActivity}
      listDisplay={DISPLAY_FIELDS}
      fieldsMapping={FIELDS_MAPPING}
      noPagination
      noBulkSelection
      noSettings
      noToolbar
    />
  )
}

TaskActivityList.propTypes = {
  task: RunningTaskType,
}

export default TaskActivityList
