import { checkColor, checkRequired } from '@Common/Utils/Validation'
import * as R from 'ramda'
import { convertToRaw } from 'draft-js'
import i18next from 'i18next'
import { types } from '../Constants'

export const labelValidate = (fields, setErrors) => {
  const err = {}

  const required = ['name', 'color']

  checkRequired(err, required, fields)
  checkColor(err, fields, 'color')

  setErrors(err)
  return R.isEmpty(err)
}

export const providerValidate = (fields, setErrors, provider) => {
  const err = {}

  const required = ['name', 'description']
  if (!provider?.id) {
    required.push('logo')
  }

  checkRequired(err, required, fields)

  setErrors(err)
  return R.isEmpty(err)
}

export const externalValidate = (fields, setErrors) => {
  const err = {}

  const required = ['name', 'url']

  checkRequired(err, required, fields)

  setErrors(err)
  return R.isEmpty(err)
}

export const categoryValidate = (fields, setErrors, category) => {
  const err = {}

  const required = []
  required.push('name')
  required.push('orderingNumber')
  if (!category?.id) {
    required.push('logo')
  }
  checkRequired(err, required, fields)

  setErrors(err)
  return R.isEmpty(err)
}

export const serviceValidate = (fields, setErrors, service, method) => {
  const err = {}
  let rawContent
  const required = ['title', 'listDescription', 'provider', 'category', 'howText', 'whyText', 'descriptionText']
  if (!service?.id) {
    required.push('detailImage')
    required.push('listImage')
  }
  if (method === types.external) {
    required.push('saleMethodUrl')
  }

  checkRequired(err, required, fields)
  rawContent = convertToRaw(fields?.descriptionText.getCurrentContent())
  if (rawContent.blocks.length === 1 && rawContent.blocks[0].text === '') {
    err['descriptionText'] = i18next.t('common:validation.RequiredField')
  }
  rawContent = convertToRaw(fields?.howText.getCurrentContent())
  if (rawContent.blocks.length === 1 && rawContent.blocks[0].text === '') {
    err['howText'] = i18next.t('common:validation.RequiredField')
  }
  rawContent = convertToRaw(fields?.whyText.getCurrentContent())
  if (rawContent.blocks.length === 1 && rawContent.blocks[0].text === '') {
    err['whyText'] = i18next.t('common:validation.RequiredField')
  }

  setErrors(err)
  return R.isEmpty(err)
}

export const getErroredFieldTab = (errors) => {
  const fieldToTabIndexMap = [
    {
      tab: 0,
      field: 'title',
    },
    {
      tab: 0,
      field: 'descriptionText',
    },
    {
      tab: 0,
      field: 'listDescription',
    },
    {
      tab: 0,
      field: 'howText',
    },
    {
      tab: 0,
      field: 'whyText',
    },
    {
      tab: 0,
      field: 'provider',
    },
    {
      tab: 0,
      field: 'category',
    },
    {
      tab: 0,
      field: 'label',
    },
    {
      tab: 1,
      field: 'listImage',
    },
    {
      tab: 1,
      field: 'detailImage',
    },
    {
      tab: 1,
      field: 'flier',
    },
    {
      tab: 2,
      field: 'saleMethodUrl',
    },
  ]

  const erroredFields = R.filter((f) => R.includes(f.field, R.keys(errors)), fieldToTabIndexMap)

  const { tab } = R.head(R.sort((a, b) => a.tab - b.tab, R.isEmpty(erroredFields) ? [{ tab: 0 }] : erroredFields))

  return tab
}
