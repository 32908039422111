/* eslint-disable react/prop-types */
import { useForm } from 'react-hook-form'

import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { api } from '@Core/Services/Api'
import { SupplierCompany } from '@Supplier/Models/SupplierCompany'

export const SupplierReportCompanyForm = ({ defaultValues }) => {
  const form = useForm({ defaultValues })

  const url = `/condo/bicert/suggest-supplier`

  const [update] = api.useUpdateOrCreateItemMutation()
  const handleSave = (item) => update({ url, item })

  return (
    <FormV2 form={form} onSubmit={handleSave} model={SupplierCompany}>
      <InputFieldV2 name="name" md={6} />
      <InputFieldV2 name="vatNumber" md={6} />
      <InputFieldV2 name="emails" />
      <InputFieldV2 name="telephones" />
      <InputFieldV2 name="mobiles" />
      <InputFieldV2 name="notes" />
    </FormV2>
  )
}
