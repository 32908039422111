import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { EdificeType } from '@Buildings/Models/Edifice'
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { Margin, Box } from '@Common/Components/Styles'
import HousingUnitsList from './HousingUnitsList'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StairsIcon from '@mui/icons-material/Stairs'
import { styled } from '@mui/material/styles'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const EdificeDetail = ({ buildingArcadiaId, edifice }) => {
  const { t } = useTranslation()
  return (
    <Box width='100%' align='stretch'>
      <Margin top="0rem" bottom=".5rem">
        <p><strong>{t('buildings:ui.Code')}</strong>: {edifice.codice}</p>
        <p><strong>{t('buildings:ui.StairsNumber')}</strong>: {edifice.nrScale}</p>
      </Margin>
      {edifice.scale.map((stair, idx) => (
        <Accordion key={idx} defaultExpanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <StairsIcon style={{ marginRight: '.5rem' }} />
            <Box align='center' direction='row'>
              <Typography>
                {stair.descrizione} ({stair.nrUnita} {t('buildings:ui.HousingUnitAbbr')})
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <HousingUnitsList buildingArcadiaId={buildingArcadiaId} stair={stair} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

EdificeDetail.propTypes = {
  edifice: EdificeType,
  buildingArcadiaId: PropTypes.string.isRequired,
}

export default EdificeDetail
