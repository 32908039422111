/* eslint-disable react/prop-types */
import { Navigate } from 'react-router-dom'

import Loader from './Loader'

export const Page = (props) => {
  // loader
  const { children, loader, error } = props

  if (loader) return <Loader />

  if (error) return <Navigate to="/404" replace={true} />

  return <>{children}</>
}
