import { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import config from '@Config/index'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import AddFab from '@Common/Components/AddFab'
import { useCreateBranchMutation, useUpdateBranchMutation } from '../../Services/Api'
import BranchForm from '../../Forms/BranchForm'
import BranchList from '../../Components/BranchList'
import { BranchPermissions } from '../../Permissions'

const Main = styled.main`
  padding-bottom: 5rem;
`

const BranchesView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  // api
  const [createBranch] = useCreateBranchMutation()
  const [updateBranch] = useUpdateBranchMutation()
  // form
  const [form, setForm] = useState({ isOpen: false, branch: null })

  useBreadcrumbs([{ label: t('common:navigation.Branches'), path: config.urls.admin.branches, Icon: 'Branch' }])

  // form state
  const handleOpenForm = () => setForm({ isOpen: true, branch: null })
  const handleCloseForm = () => setForm({ isOpen: false, branch: null })
  const handleOpenEditForm = (branch) => setForm({ isOpen: true, branch })

  // form save
  const handleSave = (fields) => (form.branch?.id ? handleUpdateBranch(fields) : handleCreateBranch(fields))

  const handleCreateBranch = async (fields) => {
    try {
      const response = await createBranch(fields).unwrap()
      toast.success(t('branches:success.BranchCreated', { name: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('branches:errors.CreateBranchError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateBranch = async (fields) => {
    try {
      const response = await updateBranch(fields).unwrap()
      toast.success(t('branches:success.BranchUpdated', { name: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('branches:errors.UpdateBranchError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <Main>
      <BranchList onEdit={handleOpenEditForm} />
      {form.isOpen && <BranchForm branch={form.branch} onSave={handleSave} onClose={handleCloseForm} />}
      {BranchPermissions.create(user) && <AddFab onClick={handleOpenForm} />}
    </Main>
  )
}

export default BranchesView
