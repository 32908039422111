import React from 'react'
import PropTypes from 'prop-types'
import { Cursor } from '@Common/Components/Styles'
import MuiFab from '@mui/material/Fab'
import { styled as muistyled } from '@mui/system'
import { ArrowRightAltRounded } from '@mui/icons-material'
import * as R from 'ramda'

const getSizeProperties = (size) => {
  return R.not(['small', 'medium', 'large'].includes(size))
    ? `width: ${size}; height: ${size}; min-width: ${size}; min-height: ${size};`
    : ''
}

const Fab = muistyled(MuiFab)`
    ${({ size }) => getSizeProperties(size)}
    position: ${props => props.className === 'absolute' ? 'absolute' : 'fixed'};
    bottom: ${props => props.bottom || '0'};
    left: ${props => props.left || 'auto'};
    right: ${props => props.right || '0'};
`

const ArrowFab = ({ left, right, bottom, onClick, absolute, size }) => {
  return (
    <Cursor>
      <Fab
        className={absolute ? 'absolute' : ''}
        color="secondary"
        onClick={onClick}
        left={left}
        right={right}
        bottom={bottom}
        size={size}
      >
        <ArrowRightAltRounded />
      </Fab>
    </Cursor>
  )
}

ArrowFab.defaultProps = {
  size: 'small',
}

ArrowFab.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  absolute: PropTypes.bool,
  size: PropTypes.string,
}

export default ArrowFab
