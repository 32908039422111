import { Search } from '@mui/icons-material'
import { Container, Grid, InputAdornment, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import HomeBuildingIcon from '@Assets/Images/home_building_icon.svg'
import { Box } from '@Common/Components/Styles'
import { withLoader } from '@Common/Utils/HOC'
import { useDebounce } from '@Common/Utils/Hooks'
import CategoryButton from '@MyEstia/Components/Services/ServicesFilters/CategoryButton'
import { useServiceCategoriesQuery } from '@Services/Services/Api'

const SearchBox = styled(TextField)`
  ${({ theme }) => css`
    background: ${theme.palette.lightFive.main};

    .MuiInputBase-input-hieRCO {
      color: ${theme.palette.primary.main};
    }
  `}
  border-radius: 20px;

  .MuiInputBase-root {
    border-radius: 20px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`

const ServicesFilters = ({ onFilter }) => {
  const { t } = useTranslation()
  const route = useParams()
  const selected = useMemo(() => route.service, [route.service])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)

  useEffect(() => {
    onFilter({ search: debouncedSearch, category: selected })
  }, [debouncedSearch, selected])

  const qs = {
    base: {
      ordering: 'ordering_number',
    },
    qsAdditions: {
      depth: 1,
    },
  }

  const { data: categories, isFetching } = useServiceCategoriesQuery(qs)

  return (
    <Box>
      <Container maxWidth="xs">
        <SearchBox
          autoFocus
          value={search}
          size="small"
          placeholder={t('myEstia:services.Search')}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />
      </Container>
      {withLoader(
        <Container maxWidth="md">
          <Grid
            container
            rowSpacing={8}
            spacing={3}
            marginTop={-4}
            sx={{ flexGrow: 1 }}
            justifyContent="center"
          >
            {search.length === 0 && (
              <Grid item xs={6} sm={4} md={3}>
                <CategoryButton
                  to={{ path: 'myEstia.myServices.main', category: 'all' }}
                  caption={t('myEstia:services.All')}
                  selected={selected === 'all'}
                  icon={HomeBuildingIcon}
                />
              </Grid>
            )}
            {categories?.results.map((category, idx) => (
              <Grid key={idx} item xs={6} sm={4} md={3}>
                <CategoryButton
                  to={{ path: 'myEstia.myServices.main', category: category.slug }}
                  caption={category.name}
                  selected={selected === category.slug}
                  icon={category.logo}
                />
              </Grid>
            ))}
          </Grid>
        </Container>,
        isFetching,
      )}
    </Box>
  )
}

export default ServicesFilters

ServicesFilters.propTypes = {
  onFilter: PropTypes.func,
}
