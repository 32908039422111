import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { useTranslation } from 'react-i18next'
import DashboardMapWidget from '@Common/Components/DashboardMapWidget'

const AdminDashboardView = () => {
  const { t } = useTranslation()
  useBreadcrumbs([{ label: t('common:navigation.Dashboard'), Icon: 'Dashboard' }])
  return (
    <div>
      <DashboardMapWidget />
    </div>
  )
}

export default AdminDashboardView
