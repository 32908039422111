import { SupplierReportCompanyForm } from '@Supplier/Forms/SupplierReportCompanyForm'

import { ServiceType } from './ServiceType'
import { SupplierCompanyType } from './SupplierCompanyType'

export const SupplierCompany = {
  name: 'SupplierCompany',
  url: '/condo/bicert/suppliers/:id',
  form: SupplierReportCompanyForm,
  schema: {
    name: { required: true },
    vatNumber: { required: true },
    companyType: { type: 'foreign_key', model: SupplierCompanyType },
    pec: { type: 'email' },
    emails: { multiple: true, type: 'email' },
    telephones: { multiple: true, type: 'phone' },
    mobiles: { multiple: true, type: 'phone' },
    nightIntervention: { type: 'boolean' },
    isInstitutional: { type: 'boolean' },
    status: { required: true, type: 'options', choices: ['DV', 'NC', 'CT', 'C', 'NI'] },
    solidity: { type: 'rating' },
    quality: { type: 'rating' },
    sustainability: { type: 'rating' },
    notes: { type: 'longtext' },
    gracePeriodExpiration: { type: 'date' },
    services: { type: 'foreign_key', multiple: true, model: ServiceType },
  },
}
