import dayjs from 'dayjs'
import i18next from 'i18next'

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'name',
      label: i18next.t('documents:fields.description'),
      filter: {
        type: 'text',
      },
    },
    {
      id: 'year',
      label: i18next.t('documents:fields.year'),
      filter: {
        type: 'date',
        format: 'YYYY',
        serverSide: false, // todo true,
        default: dayjs().format('YYYY'),
      },
    },
    {
      id: 'filesize',
      label: i18next.t('documents:fields.filesize'),
    },
    {
      id: 'id',
      label: i18next.t('common:actions.Download'),
    },
  ],
}
