import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { useCondoByIdQuery } from '@Condo/Services/Api'

import BuildingInstallmentsList from '../Components/BuildingInstallmentsList'

const Main = styled.section``

const BuildingInstallmentsView = () => {
  const { t } = useTranslation()
  const { buildingId } = useParams()
  const { data: building, isFetching } = useCondoByIdQuery(buildingId)

  useBreadcrumbs(
    [...getCondoBreadcrumbFromId(buildingId), { label: t('common:navigation.BuildingInstallments') }],
    building?.description,
    [building],
  )

  return <Main>{withLoader(<BuildingInstallmentsList building={building} />, isFetching)}</Main>
}

export default BuildingInstallmentsView
