import PropTypes from 'prop-types'

export const FieldType = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
}
