export const setStateFromEvent = setter => event => setter(event.target.value)

export const setStateFromIntEvent = setter => event => setter(parseInt(event.target.value))

export const setStateFromCheckboxEvent = setter => event => setter(event.target.checked)

export const toUpperCaseFromEvent = event => (event.target.value = event.target.value.toUpperCase())

/**
 * Wrap a field value inside the DOM event structure evt.target.value
 */
export const wrapValueInEvent = (value) => ({ target: { value } })
