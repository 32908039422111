import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Alert from '@Common/Components/Alert'

const CategoryValidationAlert = ({ errors }) => {
  const { watch } = useFormContext()
  const { t } = useTranslation()
  const [errorList, setErrorList] = useState([])

  const formatErrors = useCallback(
    (errors, prevKey = '') => {
      const errorList = []

      Object.keys(errors).forEach((key) => {
        if (['children', 'openConditions', 'closeConditions'].includes(key)) {
          errors[key].forEach((child) => {
            const childrenErrors = formatErrors(
              child,
              ['openConditions', 'closeConditions'].includes(key) ? `${key}.` : '',
            )
            errorList.push(...childrenErrors)
          })
        } else {
          const error = errors[key]
          const refName = error?.ref?.name
          const regex = refName?.includes('children') ? /^(.*children\.\d+)(.*)$/ : /^(.*)\.(.*)$/
          const idRef = refName?.replace(regex, '$1.id')
          errorList.push(`Task: ${watch(idRef)} - ${t(`tms:validation.fields.${prevKey}${key}`)}: ${error.message}`)
        }
      })

      return errorList
    },
    [watch, t],
  )

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setErrorList(formatErrors(errors))
    }
  }, [errors])

  return (
    <Alert level="error">
      {errorList.map((error, index) => (
        <div key={index}>&bull; {error}</div>
      ))}
    </Alert>
  )
}

CategoryValidationAlert.propTypes = {
  errors: PropTypes.object.isRequired,
}

export default CategoryValidationAlert
