import i18next from 'i18next'
import PropTypes from 'prop-types'

import { CategoryType } from '@Services/Models/Category'
import { LabelType } from '@Services/Models/Label'
import { ProviderType } from '@Services/Models/Provider'

export const ServiceType = PropTypes.shape({
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  listDescription: PropTypes.string.isRequired,
  listImage: PropTypes.string.isRequired,
  detailImage: PropTypes.string.isRequired,
  slug: PropTypes.string,
  category: PropTypes.number,
  provider: PropTypes.number.isRequired,
  label: PropTypes.number,
  descriptionText: PropTypes.string.isRequired,
  howText: PropTypes.string,
  flier: PropTypes.string,
  whyText: PropTypes.string,
  saleMethod: PropTypes.any,
  categoryObj: CategoryType,
  providerObj: ProviderType,
  labelObj: LabelType,
  orderingNumber: PropTypes.number,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('services:fields.service.Id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'title',
      label: i18next.t('services:fields.service.Title'),
    },
    {
      id: 'listDescription',
      label: i18next.t('services:fields.service.ListDescription'),
      ordering: false,
      longtext: true,
    },
    {
      id: 'listImage',
      label: i18next.t('services:fields.service.ListImage'),
      ordering: false,
    },

    {
      id: 'slug',
      label: i18next.t('services:fields.service.Slug'),
    },
    {
      id: 'categoryObj.name',
      label: i18next.t('services:fields.service.Category'),
    },
    {
      id: 'isActive',
      label: i18next.t('services:fields.service.IsActive'),
      boolean: true,
    },
    {
      id: 'saleMethod',
      label: i18next.t('services:fields.service.SaleMethod'),
      ordering: false,
    },
    {
      id: 'orderingNumber',
      label: i18next.t('services:fields.service.OrderingNumber'),
    },
  ],
}
