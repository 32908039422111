/* eslint-disable react/prop-types */

/**
 * Generally in model the type of field it's defined.
 * This generic field select the right field based the type.
 *
 * This allow us to create all form with the same field without specify the type.
 * In specific cases we can bypass this using the field we want directly.
 */
import { useContext } from 'react'

import { ItemContainerContext } from '../ItemContainer'
import { RegionProvinceCitySelectV2 } from '../LocationV2/RegionProvinceCitySelectV2'
import { ArrayField } from './ArrayField'
import { CheckboxFieldV2 } from './CheckboxFieldV2'
import { DatePickerV2 } from './DatePickerV2'
import { RatingFieldV2 } from './RatingFieldV2'
import { SelectFieldV2 } from './SelectFieldV2'
import { SelectFromApiV2 } from './SelectFromApiV2'
import { TextFieldV2 } from './TextFieldV2'

export const InputFieldV2 = (props) => {
  const { name } = props
  const { model } = useContext(ItemContainerContext)
  const schema = model?.schema[name]
  const type = schema?.type || props?.type || null
  const multiple = schema?.multiple || false

  // reference to another model
  if (type === 'foreign_key' && schema?.model?.name === 'city') return <RegionProvinceCitySelectV2 {...props} />
  if (type === 'foreign_key') return <SelectFromApiV2 multiple={multiple} {...props} />
  if (type === 'city') return <RegionProvinceCitySelectV2 {...props} />

  // varius type
  if (type === 'longtext') return <TextFieldV2 rows={4} multiline xs={12} {...props} />
  if (type === 'options') return <SelectFieldV2 {...props} />
  if (type === 'date') return <DatePickerV2 {...props} />
  if (type === 'rating') return <RatingFieldV2 {...props} />
  if (type === 'boolean') return <CheckboxFieldV2 {...props} />
  if (type === 'array') return <ArrayField {...props} />

  // generic
  return <TextFieldV2 multiple={multiple} {...props} />
}
