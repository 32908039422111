import { Button, FormControl, Link, Typography } from '@mui/material'
import * as R from 'ramda'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import Alert from '@Common/Components/Alert'
import Loader from '@Common/Components/Loader'
import { Box, Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import storageFactory from '@Common/Services/Storage'
import { responseStringError } from '@Common/Utils/Api'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { checkRequired } from '@Common/Utils/Validation'
import config from '@Config'
import { history } from '@Core/Redux/Store'

import { setCredentials, setCurrentUser } from '../Redux/Login'
import { useLoginMutation, useWhoamiMutation } from '../Services/Api'
import { getLoginRedirectUrl } from '../Utils'

const Form = styled.div``

const Storage = storageFactory()

export const validate = (fields) => {
  const errors = {}
  checkRequired(errors, ['username', 'password'], fields)
  return { success: R.isEmpty(errors), errors }
}

const LoginForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { fields, setField, errors, setErrors } = useForm(
    {
      username: '',
      password: '',
    },
    true,
  )

  const [login, { isLoading }] = useLoginMutation()
  const [whoami] = useWhoamiMutation()

  const submit = async () => {
    const { success, errors } = validate(fields)
    if (success) {
      try {
        const credentials = await login(fields).unwrap()
        dispatch(setCredentials(credentials))
        Storage.save('refreshToken', credentials.refresh)
        const user = await whoami().unwrap()
        dispatch(setCurrentUser(user))
        const redirectUrl = getLoginRedirectUrl(user)
        logger.debug('Login success, redirecting to ', redirectUrl)
        history.push(redirectUrl)
      } catch (err) {
        logger.debug('Login error', err)
        if (err.status === 400) {
          setErrors(R.ifElse(R.is(Object), R.map(R.head), R.always({}))(err?.data))
        } else {
          setErrors({ ...errors, __form: responseStringError(err) })
        }
      }
    }
  }

  const handleKeyDown = (evt) => {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      submit()
    }
  }

  return (
    <Form>
      {errors.__form && <Alert level="error">{errors.__form}</Alert>}
      <FormControl fullWidth margin="normal">
        <TextField
          value={fields.username}
          label={t('auth:fields.email')}
          onChange={setStateFromEvent(setField('username'))}
          error={!!errors.username}
          helperText={errors.username}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          value={fields.password}
          label={t('auth:fields.password')}
          onChange={setStateFromEvent(setField('password'))}
          onKeyDown={handleKeyDown}
          error={!!errors.password}
          helperText={errors.password}
          type="password"
        />
      </FormControl>

      <Margin top="2rem" bottom="2rem">
        <Box direction="row" justify="center">
          {isLoading && <Loader overlay />}
          {!isLoading && (
            <Button fullWidth variant="contained" onClick={submit}>
              {t('auth:buttons.Login')}
            </Button>
          )}
        </Box>
        <Typography textAlign="center" marginTop={1} fontSize={14} color="text.primary">
          <Trans
            i18nKey="auth:ui.ForgotPasswordLink"
            values={{ linkText: t('auth:actions.ClickHere') }}
            components={[<Link key="forgot-password" component={RouterLink} to={config.urls.password.forgot} />]}
          />
        </Typography>
      </Margin>
      <Typography textAlign="center" color="text.primary">
        <Trans
          i18nKey="auth:ui.LoginSupport"
          values={{ email: config.contacts.supportEmail }}
          components={[<Link key="support-email" href={`mailto:${config.contacts.supportEmail}`} />]}
        />
      </Typography>
    </Form>
  )
}

export default LoginForm
