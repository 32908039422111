import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Card from '@Common/Components/Card'
import { CardContent } from '@mui/material'

const Title = styled.div`
  font-weight: 700;
`

const Ul = styled.ul`
  margin-bottom: 0;
  padding-left: 1.5rem;

  li {
    margin-bottom: .2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const PasswordRules = () => {
  const { t } = useTranslation()

  return (
    <Card background="lightOne" pad="1rem">
      <CardContent>
        <Title>{t('auth:info.PasswordConstraint.title')}</Title>
        <Ul>
          <li>{t('auth:info.PasswordConstraint.rules.minLenght')}</li>
          <li>{t('auth:info.PasswordConstraint.rules.charsAndNumbers')}</li>
          <li>{t('auth:info.PasswordConstraint.rules.uppercase')}</li>
          <li>{t('auth:info.PasswordConstraint.rules.specialChar')}</li>
          <li>{t('auth:info.PasswordConstraint.rules.history')}</li>
        </Ul>
      </CardContent>
    </Card>
  )
}

export default PasswordRules
