import i18next from 'i18next'
import PropTypes from 'prop-types'

import { EmployeeType } from '@Auth/Models/Employee'

export const RunningTaskActivityType = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  createdBy: EmployeeType,
  created: PropTypes.string,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: 'ID',
      numeric: true,
      width: '40px',
    },
    {
      id: 'description',
      label: i18next.t('tms:labels.Description'),
    },
    {
      id: 'createdBy',
      label: i18next.t('tms:labels.Employee'),
    },
    {
      id: 'created',
      label: i18next.t('tms:labels.Date'),
      datetime: true,
    },
  ],
}
