import i18next from 'i18next'
import PropTypes from 'prop-types'

export const AnagraphicListType = PropTypes.shape({
  id: PropTypes.string,
  cognome: PropTypes.string,
  nome: PropTypes.string,
  ragioneSociale: PropTypes.string,
  immobili: PropTypes.arrayOf(PropTypes.shape({
    comune: PropTypes.string,
    denominazione: PropTypes.string,
  })),
  contatti: PropTypes.arrayOf(PropTypes.shape({
    tipo: PropTypes.shape({
      id: PropTypes.number,
      displayValue: PropTypes.string,
      riferimento: PropTypes.string,
    }),
    denominazione: PropTypes.string,
  })),
})

export const AnagraphicList = {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('common:fields.id'),
    },
    {
      id: 'nome',
      label: i18next.t('customerCare:fields.firstName'),
    },
    {
      id: 'cognome',
      label: i18next.t('customerCare:fields.lastName'),
    },
    {
      id: 'ragioneSociale',
      label: i18next.t('customerCare:fields.businessName'),
    },
    {
      id: 'email',
      label: i18next.t('customerCare:fields.email'),
      ordering: false,
    },
    {
      id: 'telefono',
      label: i18next.t('customerCare:fields.phone'),
      ordering: false,
    },
    {
      id: 'immobili',
      label: i18next.t('customerCare:fields.buildings'),
      ordering: false,
    },
    {
      id: 'housingUnits',
      label: i18next.t('customerCare:fields.housingUnits'),
      ordering: false,
    },
  ],
}
