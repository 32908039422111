import PropTypes from 'prop-types'
import { Alert as MuiAlert } from '@mui/material'

// @TODO remove component
const Alert = ({ level, children, ...props }) => {
  return <MuiAlert severity={level} {...props}>{children}</MuiAlert>
}

Alert.propTypes = {
  level: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Alert
