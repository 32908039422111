import { SupplierServiceTypeForm } from '@Supplier/Forms/SupplierServiceTypeForm'

export const SupplierServiceType = {
  name: 'SupplierServiceType',
  url: '/condo/bicert/service-types',
  form: SupplierServiceTypeForm,
  schema: {
    name: { required: true },
    activity: { required: true },
    status: { required: true, type: 'options', choices: ['A', 'I'], defaultValue: 'A' },
  },
}
