import DocumentsIcon from '@Assets/MyEstiaDocuments/DocumentsIcon'
import InstalmentsIcon from '@Assets/MyEstiaDocuments/InstalmentsIcon'
import MyHomeIcon from '@Assets/MyHomeIcon'
import { makePath } from '@Config'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import MyHomeRoundButton from './MyHomeRoundButton'

const Container = styled.div`
  overflow: hidden;
  position: fixed;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 140px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 140px;
    ${({ theme }) => `
      background: ${theme.palette.primary.main};
    `}
    border-radius: 100% 100% 0 0;
    transform: scaleX(1.5);
  }
`

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 1400px;
  z-index: 1;
  padding: .5rem;
  margin-top: 1.5rem;
`

const Footer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { housingUnitId } = useParams()
  const pathname = useSelector(R.path(['router', 'location', 'pathname']))

  const iconDim = '5rem'

  return (
    <Container>
      <Content>
        <Grid container direction="row" justifyContent="space-around">

          <Grid item xs={4}>
            <MyHomeRoundButton
              label={t('myHome:ui.MyHome')}
              labelColor={pathname === makePath('myEstia.myHome.main', { housingUnitId }) ? "secondary.main" : "white.main"}
              icon={<MyHomeIcon height={iconDim} width={iconDim} />}
              selected={true}
              onSelect={() => {
                navigate(makePath('myEstia.myHome.main', { housingUnitId }))
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <MyHomeRoundButton
              label={t('myHome:ui.Installments')}
              labelColor={pathname === makePath('myEstia.myHome.installments', { housingUnitId }) ? "secondary.main" : "white.main"}
              icon={<InstalmentsIcon height={iconDim} width={iconDim} />}
              selected={true}
              onSelect={() => {
                navigate(makePath('myEstia.myHome.installments', { housingUnitId }))
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <MyHomeRoundButton
              label={t('myHome:ui.Documents')}
              labelColor={pathname === makePath('myEstia.myHome.documents', { housingUnitId }) ? "secondary.main" : "white.main"}
              icon={<DocumentsIcon height={iconDim} width={iconDim} />}
              selected={true}
              onSelect={() => {
                navigate(makePath('myEstia.myHome.documents', { housingUnitId, type: 'personal' }))
              }}
            />
          </Grid>

        </Grid>
      </Content>
    </Container>
  )
}

export default Footer
