import { pluck } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import BuildingAnagraphicsList from '@Auth/Components/BuildingAnagraphicsList'
import { useCreateInvitationBulkMutation, useCreateInvitationMutation } from '@Auth/Services/Api'
import { useBranchBuildingQuery } from '@Buildings/Services/Api'
import { responseStringError } from '@Common/Utils/Api'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { makePath } from '@Config'
import Loader from '@Common/Components/Loader'

const AdminBuildingInvitationsView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { buildingId } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  // const [isLoading, setIsLoading] = useState(false)
  // const [createBuildingInvitation] = useCreateBuildingInvitationMutation()
  const [createInvitation] = useCreateInvitationMutation()
  const [createInvitationBulk] = useCreateInvitationBulkMutation()
  const { data: building, isFetching } = useBranchBuildingQuery(buildingId)

  useBreadcrumbs(
    [
      { label: t('common:navigation.Invitations'), path: makePath('admin.invitations'), Icon: 'Invitation' },
      { label: building?.raw?.denominazione },
    ],
    building?.branchObj.name,
    [building],
  )

  /* const handleBuildingInvitation = async () => {
    if (isLoading) return

    logger.debug('Building invitation submit, arcadiaId:', building?.arcadiaId)
    setIsLoading(true)
    try {
      await createBuildingInvitation(building?.arcadiaId).unwrap()
      toast.success(t('auth:success.BuildingInvitationCreated', { name: building?.raw?.denominazione }))
      navigate(makePath('admin.invitations'))
    } catch (error) {
      toast.error(t('auth:errors.CreateInvitationError', { error: responseStringError(error) }))
      setIsLoading(false)
    }
  } */

  const handleAnagraphicInvitation = async (anagraphic) => {
    setIsSubmitting(true)
    logger.debug('Anagraphic invitation submit, arcadiaId:', anagraphic.id)
    try {
      await createInvitation(anagraphic.id).unwrap()
      logger.debug('Anagraphic invitation success')
      toast.success(t('auth:success.InvitationCreated'))
      navigate(makePath('admin.invitations'))
    } catch (error) {
      setIsSubmitting(false)
      logger.debug('Anagraphic invitation failure', error)
      toast.error(t('auth:errors.CreateInvitationError', { error: responseStringError(error) }))
    }
  }

  const handleAnagraphicBulkInvitation = async (anagraphics) => {
    setIsSubmitting(true)
    const arcadiaIds = pluck('id', anagraphics)
    logger.debug('Anagraphic bulk invitation submit, arcadiaIds:', arcadiaIds)
    try {
      await createInvitationBulk({ arcadiaIds }).unwrap()
      logger.debug('Anagraphic bulk invitation success')
      toast.success(t('auth:success.InvitationBulkCreated'))
      navigate(makePath('admin.invitations'))
    } catch (error) {
      setIsSubmitting(false)
      logger.debug('Anagraphic bulk invitation failure', error)
      toast.error(t('auth:errors.CreateInvitationBulkError', { error: responseStringError(error) }))
    }
  }

  return withLoader(
    <div>
      {isSubmitting && <Loader overlay />}
      {/* <Box align="end">
        <Button onClick={handleBuildingInvitation} variant="contained">
          {!isLoading && t('auth:actions.SendBuildingInvitations')}
          {isLoading && (
            <>
              <Margin right=".5rem">{t('auth:ui.InvitationInProgress')}</Margin>
              <CircularProgress size={16} color="secondary" />
            </>
          )}
        </Button>
      </Box> */}
      <BuildingAnagraphicsList
        arcadiaId={building?.arcadiaId}
        onCreateInvitation={handleAnagraphicInvitation}
        onCreateBulkInvitation={handleAnagraphicBulkInvitation}
      />
    </div>,
    isFetching,
  )
}

export default AdminBuildingInvitationsView
