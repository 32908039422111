import { FormControl, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'

import { setStateFromEvent } from '@Common/Utils/Events'

import CitySelect from './CitySelect'
import ProvinceSelect from './ProvinceSelect'
import RegionSelect from './RegionSelect'

const RegionProvinceCitySelect = ({ required, fields, setFields, setField, errors }) => {
  const { t } = useTranslation()

  const region = fields.region || fields?.cityObj?.provinceObj?.region
  const province = fields.province || fields?.cityObj?.province

  const handleRegionChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('region'),
    R.mergeRight({ province: '', city: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const handleProvinceChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('province'),
    R.mergeRight({ city: '' }),
    R.mergeRight(fields),
    setFields,
  )

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <RegionSelect
            required={required}
            value={region}
            onChange={handleRegionChange}
            error={!!errors.region}
            helperText={errors.region}
            label={t('auth:fields.region')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <ProvinceSelect
            required={required}
            value={province}
            onChange={handleProvinceChange}
            error={!!errors.province}
            helperText={errors.province}
            label={t('auth:fields.province')}
            regionId={region}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <CitySelect
            required={required}
            value={fields.city}
            onChange={setStateFromEvent(setField('city'))}
            error={!!errors.city}
            helperText={errors.city}
            label={t('auth:fields.city')}
            provinceId={province}
          />
        </FormControl>
      </Grid>
    </>
  )
}

RegionProvinceCitySelect.propTypes = {
  required: PropTypes.bool,

  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,

  setFields: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
}

export default RegionProvinceCitySelect
