import { Link as MuiLink } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { isAdminRoute } from '@Common/Utils/Url'
import { getBuildingAddress } from '@Condo/Utils'

import GenericCardOutlinedDetail from './GenericCardOutlinedDetail'

const CondoRecapCardDetail = ({ data }) => {
  const { t } = useTranslation()
  const isAdmin = isAdminRoute()

  return (
    <GenericCardOutlinedDetail
      title={t('condo:ui.titleCondo')}
      grid={[
        {
          data: [
            {
              name: t('condo:fields.description'),
              value:
                (
                  <MuiLink component={Link} underline="always" to={`${isAdmin ? '/admin' : ''}/buildings/${data.id}`}>
                    {data.description}
                  </MuiLink>
                ) || '-',
            },
            {
              name: t('condo:fields.fiscalCode'),
              value: data?.fiscalCode || '-',
            },
            {
              name: t('condo:fields.buildingFullAddress'),
              value: getBuildingAddress(data, true) || '-',
            },
            {
              name: t('condo:fields.branchBuildingName'),
              value: data?.branchObj?.name || '-',
            },
            {
              name: t('common:components.BranchData.seniorAdmin'),
              value: data?.referringAdministratorObj
                ? `${data?.referringAdministratorObj?.firstName || '-'} ${
                    data?.referringAdministratorObj?.lastName || '-'
                  } `
                : null || '-',
            },
            {
              name: t('common:components.BranchData.juniorAdmin'),
              value: data?.dedicatedAdministratorObj
                ? `${data?.dedicatedAdministratorObj?.firstName || '-'} ${
                    data?.dedicatedAdministratorObj?.lastName || '-'
                  } `
                : null || '-',
            },
          ],
        },
      ]}
    />
  )
}

CondoRecapCardDetail.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CondoRecapCardDetail
