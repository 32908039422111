import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';



import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server';
import { useConfirm } from '@Common/Utils/Hooks';



import Provider from '../../Models/Provider';
import { ProviderPermissions } from '../../Permissions';
import { useServiceProvidersQuery } from '../../Services/Api';


const DISPLAY_COLUMNS = ['id', 'name', 'logo']

const SEARCH_FIELDS = ['name']

const FIELDS_MAPPING = {
  // eslint-disable-next-line react/display-name
  logo: (record) => <img src={record.logo} style={{ height: '40px' }} />,
}

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    qs.qsAdditions.name__icontains = qs.qsAdditions.search
    delete qs.qsAdditions.search
  }

  return qs
}

const ProviderList = ({ onEdit, onDelete }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-provider'),
      offset: 0,
      ordering: '-id',
    },
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useServiceProvidersQuery(convertSearchQuery(qs))

  const handleDeleteProvider = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteProvider)
  const bulkActions = React.useMemo(() => [{ id: 'DELETE', label: t('services:ui.DeleteSelectedProvider') }], [])
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: ProviderPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: ProviderPermissions.delete,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, labels) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('services:ui.DeleteProvidersConfirmationTitle'),
          t('services:ui.DeleteProviderConfirmationText', { count: selected.length }),
          { selected: selected, field: 'name' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(labels)
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      <DataTable
        name="admin-providers"
        selectable
        qs={qs}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Provider}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        bulkActions={bulkActions}
        fieldsMapping={FIELDS_MAPPING}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      />
      {DeleteConfirm}
    </div>
  )
}

ProviderList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default ProviderList
