import React from 'react'

import { useAnagraphicsQuery } from '@Auth/Services/Api'
import ServerAutoCompleteField from '@Common/Components/ServerAutocompleteField'
import { FieldType } from '@Common/Types/Field'
import { getAnagraphicInfo } from '@Residents/Utils'
import { useTranslation } from 'react-i18next'

/**
 * Usage:
 *
 *  <ArcadiaAnagraphicAutocomplete
 *    label={t('Residents:fields.Resident.__field')}
 *    onChange={setValue} />
 */
const ArcadiaAnagraphicAutocomplete = ({ ...props }) => {
  const { t } = useTranslation()

  const objFunc = function (search) {
    return {
      classe__id__exact: 0,
      ragioneSociale__icontains__or: search,
      nominativo__icontains__or: search,
      codiceFiscale__icontains__or: search,
      partitaIva__icontains__or: search,
    }
  }

  return (
    <ServerAutoCompleteField
      queryFn={useAnagraphicsQuery}
      queryOrdering="id"
      querySearchObjCb={objFunc}
      queryOptionsObjCb={(b) => ({ label: getAnagraphicInfo(b), value: b.id })}
      helperText={props.helperText ?? t('residents:info.ArcadiaAutocompleteHelpText')}
      {...props}
    />
  )
}

ArcadiaAnagraphicAutocomplete.propTypes = {
  ...FieldType,
}

export default ArcadiaAnagraphicAutocomplete
