import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'

import AcquisitionData from '@Common/Components/BuildingDetail/AcquisitionData'
import BranchData from '@Common/Components/BuildingDetail/BranchData'
import BuildingData from '@Common/Components/BuildingDetail/BuildingData'
import BuildingMap from '@Common/Components/BuildingDetail/BuildingMap'
import UrbanPlanningData from '@Common/Components/BuildingDetail/UrbanPlanningData'
import { CondoType } from '@Condo/Models/Condo'
import { getBuildingAddress } from '@Condo/Utils'

const CondoBuildingDetail = ({ building, taskInitValues }) => {
  const { t } = useTranslation()

  return (
    <>
      <BuildingMap
        building={{
          ...building,
          popupDenominazione: building.description,
          address: getBuildingAddress(building),
        }}
        height="300px"
      />
      <BuildingData
        taskInitValues={taskInitValues}
        data={[
          { name: t('buildings:fields.name'), value: R.defaultTo('--', building.description) },
          { name: t('buildings:fields.fiscalCode'), value: R.defaultTo('--', building.fiscalCode) },
        ]}
      />
      <UrbanPlanningData
        urbanData={{
          ...building,
          city: building.cityObj.name,
          province: building.cityObj.provinceObj.name,
        }}
      />
      <AcquisitionData acquisitionData={building} />
      <BranchData branchData={building} />
    </>
  )
}

CondoBuildingDetail.propTypes = {
  building: CondoType.isRequired,
  taskInitValues: PropTypes.object,
}

export default CondoBuildingDetail
