import i18next from 'i18next'
import PropTypes from 'prop-types'

export const NotificationType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  topic: PropTypes.number.isRequired,
  topicObj: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  sender: PropTypes.number,
  senderObj: PropTypes.shape({
    username: PropTypes.string,
  }),
  recipient: PropTypes.number.isRequired,
  recipientObj: PropTypes.shape({
    username: PropTypes.string,
  }).isRequired,
  text: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  link: PropTypes.string,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('common:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'created',
      label: i18next.t('notifications:fields.created'),
      datetime: true,
      ordering: false,
    },
    {
      id: 'title',
      label: i18next.t('notifications:fields.title'),
    },
    {
      id: 'topicObj.name',
      label: i18next.t('notifications:fields.topic'),
    },
    {
      id: 'link',
      label: i18next.t('notifications:fields.link'),
      ordering: false
    },
    {
      id: 'senderObj.username',
      label: i18next.t('notifications:fields.sender'),
    },
    {
      id: 'recipientObj.username',
      label: i18next.t('notifications:fields.recipient'),
    },
    {
      id: 'status',
      label: i18next.t('notifications:fields.status'),
    },
    {
      id: 'canceled',
      label: i18next.t('notifications:fields.canceled'),
      boolean: true,
    },
  ],
}
