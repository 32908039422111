import Grid from '@mui/material/Grid'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/Field'
import { withLoader } from '@Common/Utils/HOC'
import { useCondoSuppliersQuery } from '@Supplier/Services/Api'
import LabeledBox from '@Tms/Components/LabeledBox'
import { SupplierServiceFilter } from '@Tms/Forms/TaskCustomField/SuppliersField/SupplierServiceFilter'

const ShowFiltersButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  position: absolute;
  right: 0;
  top: -1.5px;
  color: ${({ theme: { palette } }) => palette.primary.main};
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em;
  padding: 0;
  z-index: 9;
`

const SuppliersField = ({ label, buildingId, helperText, disabled, ...props }) => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [filters, setFilters] = React.useState({ limit: -1, ordering: 'name' })

  const { data, isLoading } = useCondoSuppliersQuery(
    { buildingId, ...filters },
    {
      skip: !buildingId,
    },
  )

  const toggleShowFilters = () => setShowFilters((prev) => !prev)

  const handleSupplierFilter = ({ servicesActivity, services }) => {
    setFilters({
      limit: -1,
      ordering: 'name',
      ...(servicesActivity && { servicesActivity }),
      ...(services && { services: services.join(',') }),
    })
  }

  useEffect(() => {
    if (data?.results.length > 0) {
      setOptions(
        data?.results?.map(({ id: value, name: label, fiscalCode, vatNumber, statusLong }) => ({
          value,
          label,
          description: t('tms:fields.suppliers.optionDescription', {
            fiscalCode: fiscalCode ?? '-',
            vatNumber: vatNumber ?? '-',
            statusLong,
          }),
        })),
      )
    } else {
      setOptions([])
    }
  }, [data?.results])

  return withLoader(
    <LabeledBox label={showFilters ? label : null} contentProps={{ mt: showFilters ? '16px' : 0 }}>
      {!disabled && buildingId && (
        <ShowFiltersButton onClick={toggleShowFilters}>
          {showFilters ? t('tms:actions.HideSupplierFilters') : t('tms:actions.ShowSupplierFilters')}
        </ShowFiltersButton>
      )}
      <Grid container>
        <Grid item xs={12}>
          {showFilters && <SupplierServiceFilter onFilter={handleSupplierFilter} />}
        </Grid>
        <Grid item xs={12}>
          <SelectField
            fullWidth
            label={showFilters ? t('tms:fields.suppliers.__field') : label}
            options={options}
            multiple
            searchable
            helperText={buildingId ? helperText : t('tms:fields.suppliers.SelectBuildingFirst')}
            disabled={disabled || !buildingId}
            {...props}
          />
        </Grid>
      </Grid>
    </LabeledBox>,
    isLoading,
    'inline',
    { minHeight: '0', size: 20, justifyStart: true },
  )
}

SuppliersField.propTypes = FieldType

export default SuppliersField
