import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'

import DataTable from '@Common/Components/DataTable'
import Branch from '../Models/Branch'
import { BranchPermissions } from '../Permissions'
import { useBranchesQuery } from '../Services/Api'
import * as R from 'ramda'
import { getEmployeeDisplayName } from '@Auth/Utils'

const DISPLAY_COLUMNS = ['id', 'name', 'email', 'code', 'managerObj.summary', 'active']

const SEARCH_FIELDS = ['name']

const FIELDS_MAPPING = {
  'managerObj.summary': R.pipe(R.prop('managerObj'), getEmployeeDisplayName),
  'administrativeObj.summary': R.pipe(R.prop('administrativeObj'), getEmployeeDisplayName),
  'secondaryAdministrativeObj.summary': R.pipe(R.prop('secondaryAdministrativeObj'), getEmployeeDisplayName),
  'seniorAdministrativesObjs.summary': R.pipe(
    R.prop('seniorAdministrativesObjs'),
    R.map(getEmployeeDisplayName),
    R.join(', '),
  ),
  'juniorAdministrativesObjs.summary': R.pipe(
    R.prop('juniorAdministrativesObjs'),
    R.map(getEmployeeDisplayName),
    R.join(', '),
  ),
}

const BranchList = ({ onEdit }) => {
  const { t } = useTranslation()
  const { data, isFetching } = useBranchesQuery()
  const [selected, setSelected] = React.useState()

  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon />,
        perm: BranchPermissions.update,
      },
    ],
    [],
  )

  const handleAction = (_, branch) => {
    onEdit(branch)
  }

  return (
    <div>
      <DataTable
        name="branches"
        data={data?.results || []}
        model={Branch}
        sortField="id"
        sortDirection="desc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noBulkSelection
      />
    </div>
  )
}

BranchList.propTypes = {
  onEdit: PropTypes.func.isRequired,
}

export default BranchList
