import { api, objToQueryString } from '@Core/Services/Api'

const prefix = 'condo/bicert'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    serviceTypes: builder.query({
      query: (qs) => `${prefix}/service-types?${objToQueryString(qs)}`,
      providesTags: [{ type: 'BicertServiceTypes', id: 'LIST' }],
    }),
    suppliers: builder.query({
      query: (qs) => `${prefix}/suppliers?${objToQueryString(qs)}`,
      providesTags: [{ type: 'BicertSuppliers', id: 'LIST' }],
    }),
    condoSuppliers: builder.query({
      query: ({ buildingId, ...qs }) => `condo/buildings/${buildingId}/suppliers?${objToQueryString(qs)}`,
      providesTags: (_, __, { buildingId }) => [{ type: 'CondoSuppliers', id: buildingId }],
    }),
  }),
  overrideExisting: false,
})

export const { useServiceTypesQuery, useSuppliersQuery, useCondoSuppliersQuery } = extendedApi
