import i18next from 'i18next'
import PropTypes from 'prop-types'
import { AnagraphicType } from '@Auth/Models/Anagraphic'

export const InvitationType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  arcadiaId: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  businessName: PropTypes.string,
  fiscalCode: PropTypes.string,
  vatNumber: PropTypes.string,
  status: PropTypes.number,
  notes: PropTypes.string,
  emailsObjs: PropTypes.arrayOf(PropTypes.string),
  raw: AnagraphicType,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('auth:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'arcadiaId',
      label: i18next.t('auth:fields.anagraphicId'),
    },
    {
      id: 'status',
      label: i18next.t('auth:fields.status.__field'),
    },
    {
      id: 'firstName',
      label: i18next.t('auth:fields.firstName'),
    },
    {
      id: 'lastName',
      label: i18next.t('auth:fields.lastName'),
    },
    {
      id: 'fiscalCode',
      label: i18next.t('auth:fields.fiscalCode'),
    },
    {
      id: 'businessName',
      label: i18next.t('auth:fields.anagraphic.companyName'),
    },
    {
      id: 'vatNumber',
      label: i18next.t('auth:fields.anagraphic.vat'),
    },
    {
      id: 'emailsObjs',
      label: i18next.t('auth:fields.email'),
    },
    {
      id: 'notes',
      label: i18next.t('auth:fields.notes'),
    },
    {
      id: 'created',
      label: i18next.t('auth:fields.created'),
      datetime: true,
    },
    {
      id: 'immobili',
      label: i18next.t('auth:fields.buildings'),
      ordering: false,
    },
  ],
}
