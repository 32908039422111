import { useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import AddFab from '@Common/Components/AddFab'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import { ReconciliationReminderList } from '@Condo/Components/ReconciliationReminderList'
import { ReconciliationReminderForm } from '@Condo/Forms/ReconciliationReminder/ReconciliationReminderForm'
import { ReconciliationReminderPermissions } from '@Condo/Permissions'
import {
  useUpdateReconciliationReminderMutation,
  useCreateReconciliationReminderMutation,
  useUpdateAllFieldReconciliationReminderMutation,
  useDeleteReconciliationReminderMutation,
} from '@Condo/Services/Api'

const BuildingReconciliationReminderView = () => {
  const user = useCurrentUser()
  const { buildingId } = useParams()
  const { t } = useTranslation()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const [isBuildingDetail, setIsBuildingDetail] = useState(false)

  useEffect(() => {
    if (buildingId) setIsBuildingDetail(true)
  }, [buildingId])

  // api
  const [createReconciliationReminder] = useCreateReconciliationReminderMutation()
  const [updateReconciliationReminder] = useUpdateReconciliationReminderMutation()
  const [updateAllFieldReconciliationReminder] = useUpdateAllFieldReconciliationReminderMutation()
  const [deleteReconciliationReminder] = useDeleteReconciliationReminderMutation()

  // form
  const [form, setForm] = useState({
    isOpen: false,
    reconciliationReminder: {
      building: buildingId ?? null,
    },
    isEditing: false,
  })

  const baseBreadcrumbs = buildingId ? getCondoBreadcrumbFromId(buildingId) : []
  const reconciliationBreadcrumbItem = {
    label: t('common:navigation.ReconciliationReminderManagement'),
    Icon: !buildingId && 'Paid', // show icon only in root view
  }

  useBreadcrumbs([...baseBreadcrumbs, reconciliationBreadcrumbItem], baseBreadcrumbs)

  const handleOpenCreateForm = (id) =>
    setForm({
      isOpen: true,
      reconciliationReminder: { building: id ?? buildingId ?? null },
      isEditing: false,
    })

  const handleOpenEditForm = (reconciliationReminder) => {
    setForm({ isOpen: true, reconciliationReminder: reconciliationReminder, isEditing: true })
  }

  const handleCloseForm = () =>
    setForm({
      isOpen: false,
      reconciliationReminder: { building: buildingId ?? null },
      isEditing: false,
    })

  const handleSave = (fields) => {
    return form.isEditing ? handleUpdateReconciliationReminder(fields) : handleCreateReconciliationReminder(fields)
  }

  const handleCreateReconciliationReminder = async (fields) => {
    try {
      const response = await createReconciliationReminder(fields).unwrap()

      toast.success(t('condo:success.ReconciliationReminderCreated'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.CreateReconciliationReminderError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateReconciliationReminder = async (fields) => {
    try {
      const response = isBuildingDetail
        ? await updateReconciliationReminder(fields).unwrap()
        : await updateAllFieldReconciliationReminder(fields).unwrap()

      toast.success(t('condo:success.ReconciliationReminderUpdated'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.UpdateReconciliationReminderError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (fields) => {
    try {
      const response = await deleteReconciliationReminder(fields[0].id).unwrap()
      toast.success(t('condo:success.ReconciliationReminderDeleted'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.DeleteReconciliationReminderError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <>
      <ReconciliationReminderList
        buildingId={buildingId}
        onEdit={handleOpenEditForm}
        onDelete={handleDelete}
        onCreate={handleOpenCreateForm}
      />

      {form.isOpen && (
        <ReconciliationReminderForm
          reconciliationReminder={form.reconciliationReminder}
          onSave={handleSave}
          onClose={handleCloseForm}
          showSelectBuilding={!isBuildingDetail}
        />
      )}

      {ReconciliationReminderPermissions.create(user) && (
        <AddFab
          onClick={() => handleOpenCreateForm()}
          right={isBuildingDetail && !isSmallDevice ? '82px' : '28px'}
          bottom={isBuildingDetail && isSmallDevice ? '82px' : '28px'}
        />
      )}
    </>
  )
}

export { BuildingReconciliationReminderView }
