import { Controller } from 'react-hook-form'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/ReactHookFormField'

const SelectField = ({ name, control, rules, defaultValue, ...other }) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        const resultValue = other?.options?.length ? value ?? (other.multiple ? [] : '') : other.multiple ? [] : ''
        return <Dropdown onChange={onChange} value={resultValue} {...other} />
      }}
    />
  )
}

SelectField.propTypes = FieldType

export default SelectField
