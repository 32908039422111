import i18next from 'i18next'
import PropTypes from 'prop-types'

import { ArcadiaEnumType } from '../../Common/Types/EnumType'

export const InstallmentType = PropTypes.shape({
  immobileId: PropTypes.string,
  immobile: PropTypes.shape({
    denominazione: PropTypes.string,
    codiceUnivoco: PropTypes.number,
    id: PropTypes.string,
  }),
  bilancio: PropTypes.shape({
    stato: ArcadiaEnumType,
    tipo: ArcadiaEnumType,
    descrizione: PropTypes.string,
    id: PropTypes.string,
  }),
  anagrafica: PropTypes.shape({
    id: PropTypes.string,
    nominativo: PropTypes.string,
  }),
  ui: PropTypes.shape({
    codice: PropTypes.string,
    descrizione: PropTypes.string,
    edificio: PropTypes.string,
    scala: PropTypes.string,
    id: PropTypes.string,
  }),
  scadenza: PropTypes.string,
  descrizione: PropTypes.string,
  importo: PropTypes.number,
  residuo: PropTypes.number,
  incassato: PropTypes.number,
  canPay: PropTypes.bool,
  incassi: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string,
      importo: PropTypes.number,
    }),
  ),
  id: PropTypes.string,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'immobile.nominativo',
      label: i18next.t('buildings:fields.building.__field'),
    },
    {
      id: 'bilancio.stato.displayValue',
      label: i18next.t('buildings:fields.financialStatement.status'),
    },
    {
      id: 'bilancio.tipo.displayValue',
      label: i18next.t('buildings:fields.financialStatement.type'),
    },
    {
      id: 'bilancio.descrizione',
      label: i18next.t('buildings:fields.financialStatement.__field'),
    },
    {
      id: 'anagrafica.nominativo',
      label: i18next.t('buildings:fields.registryName'),
    },
    {
      id: 'ui.codice',
      label: i18next.t('buildings:fields.housingUnit.code'),
    },
    {
      id: 'ui.descrizione',
      label: i18next.t('buildings:fields.housingUnit.__field'),
    },
    {
      id: 'ui.edificio.descrizione',
      label: i18next.t('buildings:fields.edifice.__field'),
    },
    {
      id: 'ui.scala.descrizione',
      label: i18next.t('buildings:fields.stair'),
    },
    {
      id: 'scadenza',
      label: i18next.t('buildings:fields.expiration'),
      date: true,
    },
    {
      id: 'descrizione',
      label: i18next.t('buildings:fields.description'),
    },
    {
      id: 'importo',
      label: i18next.t('buildings:fields.amount'),
      numeric: true,
    },
    {
      id: 'residuo',
      label: i18next.t('buildings:fields.residue'),
      numeric: true,
    },
    {
      id: 'incassato',
      label: i18next.t('buildings:fields.cached'),
      numeric: true,
    },
    {
      id: 'incassi',
      label: i18next.t('buildings:fields.takings'),
    },
    {
      id: 'canPay',
      label: 'Paga',
    },
  ],
}
