import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { useLazyBranchesBuildingsQuery } from '@Buildings/Services/Api'
import Dropdown from '@Common/Components/Dropdown'
import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/Field'
import { useLazyCondosQuery } from '@Condo/Services/Api'

const getMergedBuildings = (branchBuildings, condos) => {
  return branchBuildings.results
    .map((bb) => ({ id: `${bb.id}_branchbuilding`, denominazione: bb.raw?.denominazione }))
    .concat(condos.results.map(({ id, description }) => ({ id: `${id}_building`, denominazione: description })))
}

const BuildingSelect = ({ control, name, branchId, notRHF, ...other }) => {
  const [fetchBranchBuildings] = useLazyBranchesBuildingsQuery()
  const [fetchCondos] = useLazyCondosQuery()
  const [buildings, setBuildings] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(async () => {
    let branchBuildings, condos
    setIsLoading(true)
    if (branchId === 0) {
      branchBuildings = await fetchBranchBuildings({}).unwrap()
      condos = await fetchCondos({ base: { record_type: 'LOCAL' } }).unwrap()
    } else {
      branchBuildings = await fetchBranchBuildings({ branch__id: branchId }).unwrap()
      condos = await fetchCondos({ base: { branch__in: branchId, record_type: 'LOCAL' } }).unwrap()
    }
    setIsLoading(false)
    setBuildings(getMergedBuildings(branchBuildings, condos))
  }, [branchId])

  return (
    <>
      {!notRHF && (
        <SelectField
          name={name}
          control={control}
          searchable
          width="100%"
          options={isLoading ? null : buildings}
          labelKey="denominazione"
          valueKey="id"
          {...other}
        />
      )}
      {notRHF && (
        <Dropdown
          options={isLoading ? null : buildings}
          onChange={other.onChange}
          searchable
          width="100%"
          labelKey="denominazione"
          valueKey="id"
          value={buildings?.length ? other.value : ''}
          {...other}
        />
      )}
    </>
  )
}

BuildingSelect.defaultProps = {
  branchId: 0,
  notRHF: false,
}

BuildingSelect.propTypes = {
  ...FieldType,
  branchId: PropTypes.number,
  notRHF: PropTypes.bool,
}
export default BuildingSelect
