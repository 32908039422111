import { Navigate, Route, Routes } from 'react-router-dom'

import config from '@Config'
import { CertificationDocumentTypeListView } from '@Supplier/Views/CertificationDocumentTypeListView'
import { SupplierCompanyDetailView } from '@Supplier/Views/SupplierCompanyDetailView'
import { SupplierCompanyListViewMain } from '@Supplier/Views/SupplierCompanyListViewMain'
import { SupplierCompanyTypeListView } from '@Supplier/Views/SupplierCompanyTypeListView'
import { SupplierServiceTypeListView } from '@Supplier/Views/SupplierServiceTypeListView'

export const SupplierRouter = () => {
  return (
    <Routes>
      <Route path="suppliers/">
        <Route index element={<SupplierCompanyListViewMain />} />
        <Route path=":supplierId" element={<SupplierCompanyDetailView />} />
        <Route path=":supplierId/:condoSupplierId" element={<SupplierCompanyDetailView />} />
        <Route path="*" element={<Navigate to={config.urls.pageNotFound} replace />} />
      </Route>
      <Route path="document-types/*">
        <Route index element={<CertificationDocumentTypeListView />} />
      </Route>
      <Route path="service-types/*">
        <Route index element={<SupplierServiceTypeListView />} />
      </Route>
      <Route path="supplier-types/*">
        <Route index element={<SupplierCompanyTypeListView />} />
      </Route>
    </Routes>
  )
}
