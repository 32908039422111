import { FormGroup } from '@mui/material'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import Checkbox from '@Common/Components/Checkbox'
import { setStateFromCheckboxEvent } from '@Common/Utils/Events'

const CheckboxGroupField = ({ name, options, control, labelKey, valueKey }) => {
  const handleChange = (onChange, values, newValue) => (checked) => {
    if (checked) {
      onChange([...values, newValue])
    } else {
      onChange(values.filter((value) => value !== newValue))
    }
  }

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            {options.map((option) => (
              <Checkbox
                key={option[valueKey]}
                value={option[valueKey]}
                checked={value.includes(option[valueKey])}
                onChange={setStateFromCheckboxEvent(handleChange(onChange, value, option[valueKey]))}
                label={option[labelKey]}
              />
            ))}
          </>
        )}
      />
    </FormGroup>
  )
}

CheckboxGroupField.defaultProps = {
  labelKey: 'label',
  valueKey: 'value',
}

CheckboxGroupField.propTypes = {
  options: PropTypes.array,
  control: PropTypes.any,
  setValue: PropTypes.func,
  name: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
}

export default CheckboxGroupField
