import { useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import styled, { css } from 'styled-components'

import DeviceOrientation, { OnlyPortraitAlert, Orientation } from '@Common/Components/ScreenOrientation'

import MyHomeBottombar from '../Components/MyHomeBottombar'
import MyHomeSidebar from '../Components/MyHomeSidebar'

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
  justify-content: center;
  height: 100%;
`

const Main = styled.main`
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    color: ${theme.palette.background.constrastText};
  `}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

const MobileMain = styled.main`
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    color: ${theme.palette.background.constrastText};
  `}
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

const Layout = ({ children }) => {
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Wrapper mobile={isSmallDevice} data-sc="wrapper">
      {!isSmallDevice && (
        <>
          <MyHomeSidebar />
          <Main>{children}</Main>
        </>
      )}

      {isSmallDevice && (
        <>
          <MobileMain>{children}</MobileMain>
          <MyHomeBottombar />
        </>
      )}
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

const MyHomeLayout = ({ children }) => {
  return (
    <>
      <MobileView>
        <DeviceOrientation lockOrientation={'portrait'}>
          {/* Will stay in DOM, but is only visible in landscape */}
          <Orientation orientation="landscape">
            <OnlyPortraitAlert />
          </Orientation>
          {/* Will stay in DOM, but is only visible in portrait */}
          <Orientation orientation="portrait">
            <Layout>{children}</Layout>
          </Orientation>
        </DeviceOrientation>
      </MobileView>
      <BrowserView>
        <Layout>{children}</Layout>
      </BrowserView>
    </>
  )
}

MyHomeLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default MyHomeLayout
