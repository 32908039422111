/**
 * The colors must be written in one of the two following formats
 *
 * 1. fixed color
 * <code>
 *   {
 *      main: string,
 *      contrastText?: string,
 *      ...other
 *    }
 * </code>
 *
 * 2. color by theme mode
 * <code>
 *   {
 *      light: string,
 *      dark: string,
 *      contrastText?: string,
 *      ...other
 *    }
 * </code>
 *
 * When defined in format 2, the "main" field will
 * automatically assume the correct value based on the active theme mode.
 *
 * contrastText field is optional, if it is not specified it will be automatically
 * calculated using the getContrastText function provided by mui theme object
 */
export default {
  primary: {
    // light: '#3e6eb4',
    // dark: '#1c4c92',
    main: '#3e6eb4',
    dark: '#050b11',
  },
  primaryDark: {
    main: '#102037',
    contrastText: '#fff',
  },
  secondary: {
    main: '#ffbf2e',
  },
  info: {
    main: '#8caad2',
  },
  success: {
    main: '#00c782',
    dark: '#008556',
    light: '#86f7d0',
  },
  warning: {
    main: '#ffa914',
    light: '#fcc565',
  },
  error: {
    main: '#fe4040',
    light: '#fa8e8e',
  },
  errorLight: {
    main: '#ffa5a5',
    light: '#ffa5a5',
  },
  contrastLight: {
    main: '#f3f3f3',
    contrastText: '#000',
  },
  whiteBlack: {
    light: '#fff',
    dark: '#000',
  },
  blackWhite: {
    light: '#000',
    dark: '#fff',
  },
  accentOne: {
    light: '#3E6EB4',
    dark: '#a9c0e2',
  },
  accentTwo: {
    light: '#866cab',
    dark: '#d6cde3',
  },
  accentThree: {
    light: '#8caad2',
    dark: '#4674b2',
  },
  accentFour: { main: '#71c877' },
  accentFive: {
    light: '#ffbf2e',
    dark: '#bb7b0a',
  },
  neutralOne: { main: '#6c8794' },
  neutralTwo: { main: '#859ba6' },
  neutralThree: { main: '#9dafb8' },
  neutralFour: { main: '#b6c3ca' },
  lightOne: {
    light: '#f8f8f8',
    dark: '#242424',
  },
  lightTwo: {
    light: '#f2f2f2',
    //dark: '#202020',
    dark: '#112439',
  },
  lightThree: {
    light: '#ededed',
    dark: '#121212',
  },
  lightFour: { main: '#dadada' },
  lightFive: { main: '#efefef' },
  baseBg: {
    light: '#fff',
    dark: '#000',
  },
  darkOne: {
    light: '#0d1c2f',
    dark: '#232323',
  },
  darkTwo: {
    light: '#102136',
    dark: '#151515',
  },
  darkThree: {
    main: '#606b79',
  },
  darkFour: {
    main: '#88909b',
  },
  white: {
    main: '#fff',
  },
  black: {
    main: '#000',
  },
  background: {
    light: '#fff',
    //dark: '#303030',
    dark: '#152c46',
  },
  navbar: {
    light: '#3E6EB4',
    dark: '#202020',
    shadow: '#000',
  },
  breadcrumbs: {
    light: '#a9c0e2',
    dark: '#292929',
  },
  navLink: {
    main: '#244068',
  },
  navLinkActive: {
    main: '#3e6eb4',
  },
  buildingNav: {
    light: '#6d91c4',
    dark: '#373737',
    contrastText: '#fff',
  },
  buildingLink: {
    light: '#3e6eb4',
    dark: '#5e7eac',
  },
  buildingLinkActive: {
    light: '#2B4D7D',
    dark: '#174079',
  },
  sidebar: {
    light: '#0d1c2f',
    dark: '#232323',
    contrastText: '#fff',
  },
  sidebarActive: {
    light: '#183457',
    dark: '#183457',
    contrastText: '#fff',
  },
  sidebarHasActive: {
    light: '#3a5679',
    dark: '#3a5679',
    contrastText: '#fff',
  },
  footer: {
    light: '#f7f7f7',
    dark: '#444',
  },
  label: {
    light: 'rgba(0,0,0,0.6)',
    dark: 'rgba(255,255,255,0.7)',
  },
  dataTableTr: {
    light: '#eff3f8',
    dark: '#0f2134',
  },
  text: {
    light: '#3e6eb4',
    dark: '#fff',
  },
  navigationCircle: {
    light: '#3E6EB4',
    dark: '#3570b0',
  },
  newsletter: {
    light: '#3e6eb4',
    dark: '#152c46',
  },
  footerBackground: {
    light: '#102037',
    dark: '#050b11',
  },
  sidebarButton: {
    light: '#f2f2f2',
    dark: '#fff',
  },
  onHoverButton: {
    light: '#e3e3e1',
    dark: '#f2f0e6',
  },
  chatItems: {
    light: '#fff',
    dark: '#242424',
  },
}
