import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  let err = {}

  let required = ['building', 'bank', 'legalPracticesPresence', 'lastReconciliationDate']

  checkRequired(err, required, fields)

  setErrors(err)

  return R.isEmpty(err)
}
