import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from '@Common/Components/Modal'
import { FacilityType } from '@Buildings/Models/Facility'
import FacilityDetailData from '@Buildings/Components/FacilityDetailData'
// import FacilityDocuments from '@Buildings/Components/FacilityDocuments'
import { Margin } from '@Common/Components/Styles'

const FacilityDetailModal = ({ facility, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('buildings:ui.Facility', { description: facility.descrizione })}
      width="xlarge"
      onClose={onClose}
    >
      <Margin bottom="3rem">
        <FacilityDetailData facility={facility} />
      </Margin>
      {/*<FacilityDocuments facility={facility} />*/}
    </Modal>
  )
}

FacilityDetailModal.propTypes = {
  facility: FacilityType,
  onClose: PropTypes.func.isRequired,
}

export default FacilityDetailModal
