/* eslint-disable react/prop-types */
import { Grid } from '@mui/material'
import { useContext } from 'react'

import { getItemFieldChoices, getItemFieldFromContext, useMuiGrid } from '@Common/Utils/Hooks'

import SelectField from '../Forms/SelectField'
import { ItemContainerContext } from '../ItemContainer'
import { useDefaultFieldProps } from './FormUtilsV2'

export const SelectFieldV2 = (props) => {
  const { model } = useContext(ItemContainerContext)
  const gridProps = useMuiGrid(props)
  const defaultProps = useDefaultFieldProps(props.name, props)
  const field = getItemFieldFromContext(props.name)
  const options = field?.schema?.type === 'options' ? getItemFieldChoices(props.name, model) : props.options

  if (!props.options && options && options.length > 0 && options.length !== 2) {
    // Add a default options for clear
    options.unshift({ id: 0, label: '--' })
  }

  return (
    <Grid item {...gridProps}>
      <SelectField defaultValue={props?.inputProps?.defaultValue} options={options} {...defaultProps} {...props} />
    </Grid>
  )
}
