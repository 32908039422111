import { Add, Info } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Stack, Tab, Tabs } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import MuiBox from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiFormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import RoleSelect from '@Auth/Components/RoleSelect'
import { RoledataType } from '@Auth/Models/Employee'
import { hasRoledata } from '@Auth/Utils'
import { Help } from '@Common/Components/Forms'
import EditorField from '@Common/Components/Forms/EditorField'
import RadioGroupField from '@Common/Components/Forms/RadioGroupField'
import TextField from '@Common/Components/Forms/TextField'
import TimeRangeField from '@Common/Components/Forms/TimeRangeField'
import { Box, Label, Margin, TextNoWrap } from '@Common/Components/Styles'
import { useConfirm } from '@Common/Utils/Hooks'
import { camelToSnakeCase } from '@Common/Utils/Strings'
import { FormControl, SectionTitle } from '@Tms/Components/Styled'
import TaskFieldList from '@Tms/Components/TaskFieldList'
import { useTaskChildrenFormField, useTaskFieldsFormField } from '@Tms/Forms/CategoryForm/useCategoryForm'
import TaskCloseConditionList from '@Tms/Forms/CloseConditionForm/TaskCloseConditionList'
import TaskOpenConditionList from '@Tms/Forms/OpenConditionForm/TaskOpenConditionList'
import AncestorTasksSelect from '@Tms/Forms/Selects/AncestorTasksSelect'
import PrioritySelect from '@Tms/Forms/Selects/PrioritySelect'
import TaskFieldForm from '@Tms/Forms/TaskFieldForm'

import DeadlineConditionsSelect from './DeadlineConditionSelect'
import TaskDateFieldSelect from './TaskDateFieldSelect'


const Ul = styled.div`
  padding-top: 16px;
`

const Li = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-left: 30px;

  &:before {
    position: absolute;
    top: 40px;
    left: 15px;
    width: 15px;
    height: 1px;
    margin: auto;
    content: '';
    background-color: #666;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    width: 1px;
    height: calc(100% + 40px);
    content: '';
    background-color: #666;
  }

  &:last-child:after {
    height: 40px;
  }
`

const TabPanel = styled.div`
  display: ${({ activeIndex, index }) => (activeIndex === index ? 'block' : 'none')};
`

const roledataNames = Object.keys(RoledataType).filter((name) => name !== 'isRoleSupervisor')
const getIndex = (obj, i) => obj[i] ?? {}

const TaskForm = ({ prefix = '', depth = 0, index, isMainTask, isReadOnly, onDelete }) => {
  const { t } = useTranslation()
  // const [expanded, setExpanded] = useState(isMainTask)
  const [activeTab, setActiveTab] = useState(0)
  const [fieldForm, setFieldForm] = useState({ open: false, field: null })
  const {
    watch,
    resetField,
    formState: { errors },
  } = useFormContext()
  const { fields, control, addNewTask, removeTask, inputPath } = useTaskChildrenFormField(prefix)
  const { fields: taskFields, addNewTaskField, updateTaskField, removeTaskField } = useTaskFieldsFormField(prefix)
  const mainTaskId = watch(`mainTask.id`)
  const taskName = watch(`${inputPath}name`)
  const taskId = watch(`${inputPath}id`)
  const enableAssignToAdmin = watch('isAdminSelectEnabled')
  const enableAssignToOwner = watch('isBuildingEnabled')
  const deadlineCondition = watch(`${inputPath}deadlineCondition`)
  const deadlineTask = watch(`${inputPath}deadlineTask`)
  const isFormInitialized = useRef(false)

  useEffect(() => {
    // using ref to avoid resetting the field during form initialization
    if (isFormInitialized.current) {
      resetField(`${inputPath}deadlineTask`, { keepDirty: false, keepTouched: false, defaultValue: '' })
    } else {
      isFormInitialized.current = true
    }
  }, [deadlineCondition])

  const hasErrors = useCallback(() => {
    if (errors) {
      const currentTaskErrors = inputPath.slice(0, -1).split('.').reduce(getIndex, errors)
      if (Array.isArray(currentTaskErrors)) {
        return false
      }
      const errorFieldNames = Object.keys(currentTaskErrors)
      return errorFieldNames.includes('children') ? errorFieldNames.length > 1 : errorFieldNames.length > 0
    }
    return false
  }, [errors])

  const getErrorMessage = useCallback(
    (field) => (errors ? inputPath.slice(0, -1).split('.').reduce(getIndex, errors)?.[field]?.message : ''),
    [errors],
  )

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(removeTask)
  const handleDeleteChildTask = (index, name) => {
    openDeleteConfirm(
      index, // payload
      t('tms:ui.DeleteTaskConfirmationTitle'),
      t('tms:ui.DeleteTaskConfirmationText'),
      { selected: [{ name }], field: 'name' },
    )
  }

  const handleOnAddField = () => setFieldForm({ open: true, field: null })
  const handleOnEditField = (field) => setFieldForm({ open: true, field })
  const handleDeleteField = ({ id }) => removeTaskField(id)
  const handleSaveField = (field, isUpdate) => {
    isUpdate ? updateTaskField(field) : addNewTaskField(field)
    setFieldForm({ open: false, field: null })
  }

  const anyError = hasErrors()
  const nameError = anyError ? getErrorMessage('name') : ''
  const descriptionError = anyError ? getErrorMessage('description') : ''
  const roleError = anyError ? getErrorMessage('role') : ''
  const minutesToDeadlineError = anyError ? getErrorMessage('minutesToDeadline') : ''
  const deadlineTaskError = anyError ? getErrorMessage('deadlineTask') : ''
  const deadlineFieldError = anyError ? getErrorMessage('deadlineField') : ''

  return (
    <Ul>
      <Accordion square disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box direction="row" align="center">
            <Typography mr={1} color={anyError && 'error.main'}>
              Task
            </Typography>
            <span style={{ marginBottom: '4px' }}>|</span>
            <Typography fontWeight="bold" ml={1} color={anyError && 'error.main'}>
              {taskName && `${taskName} -`}{' '}
              <Typography variant="span" color="neutralThree.main">
                {taskId}
              </Typography>
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
            <Tabs
              variant="scrollable"
              value={activeTab}
              onChange={(_, idx) => setActiveTab(idx)}
              style={{ overflow: 'auto' }}
            >
              <Tab label={t('tms:tabs.GeneralInfo')} />
              <Tab label={t('tms:tabs.CustomFields')} />
              <Tab label={t('tms:tabs.DeadlineConditions')} />
              {!isMainTask && <Tab label={t('tms:tabs.OpenConditions')} />}
              {!isMainTask && <Tab label={t('tms:tabs.CloseConditions')} />}
            </Tabs>
          </MuiBox>
          <TabPanel activeIndex={activeTab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <FormControl>
                  <TextField
                    required
                    disabled={isReadOnly}
                    name={`${inputPath}name`}
                    control={control}
                    label={t('tms:fields.taskName')}
                    error={!!nameError}
                    helperText={nameError}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <PrioritySelect
                    required
                    disabled={isReadOnly}
                    control={control}
                    label={t('tms:fields.defaultPriority')}
                    name={`${inputPath}defaultPriority`}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Margin top="1rem">
                  <Label>{t('tms:fields.description')} *</Label>
                </Margin>
                {isReadOnly && (
                  <Box background="lightOne" width="100%">
                    <span dangerouslySetInnerHTML={{ __html: watch(`${inputPath}description`) }} />
                  </Box>
                )}
                {!isReadOnly && (
                  <FormControl fullWidth>
                    <EditorField
                      disabled={isReadOnly}
                      name={`${inputPath}description`}
                      control={control}
                      wrapperClassName="wysiwyg-wrapper"
                      editorClassName="wysiwyg-editor"
                    />
                    <Help error>{descriptionError}</Help>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <SectionTitle>{t('tms:ui.AssignTo')}</SectionTitle>
                <FormControl>
                  <RadioGroupField
                    disabled={isReadOnly}
                    name={`${inputPath}assignTo`}
                    control={control}
                    options={[
                      { value: 'role', label: t('tms:fields.role') },
                      { value: 'branch_administrative', label: t('tms:fields.assignToBranchAdministrative') },
                      ...(enableAssignToAdmin ? [{ value: 'admin', label: t('tms:fields.assignToAdmin') }] : []),
                      { value: 'who_opens_it', label: t('tms:fields.assignToWhoOpensIt') },
                      ...(enableAssignToOwner
                        ? [
                            { value: 'administration_owner', label: t('tms:fields.assignToAdministrationOwner') },
                            { value: 'accidents_owner', label: t('tms:fields.assignToAccidentsOwner') },
                            { value: 'accounting_owner', label: t('tms:fields.assignToAccountingOwner') },
                          ]
                        : []),
                    ]}
                    row
                  />
                </FormControl>
              </Grid>
              {watch(`${inputPath}assignTo`) === 'role' && (
                <>
                  <Grid item xs={12} md={4}>
                    <FormControl margin="none">
                      <Controller
                        name={`${inputPath}role`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <RoleSelect
                            required
                            disabled={isReadOnly}
                            value={value}
                            label={t('tms:fields.role')}
                            onChange={onChange}
                            error={!!roleError}
                            helperText={roleError}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {hasRoledata(watch(`${inputPath}role`)) && (
                    <Grid item xs={12}>
                      <MuiFormControl>
                        <RadioGroupField
                          disabled={isReadOnly}
                          name={`${inputPath}roleData`}
                          control={control}
                          options={roledataNames.map((name) => ({
                            value: camelToSnakeCase(name),
                            label: t(`auth:fields.roledata.${name}`),
                          }))}
                          row
                        />
                      </MuiFormControl>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </TabPanel>
          <TabPanel activeIndex={activeTab} index={1}>
            <TaskFieldList
              isReadOnly={isReadOnly}
              fields={taskFields}
              onAdd={handleOnAddField}
              onEdit={handleOnEditField}
              onDelete={handleDeleteField}
            />
          </TabPanel>
          <TabPanel activeIndex={activeTab} index={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box direction="row" align="flex-end" justify="flex-start">
                  <TimeRangeField
                    disabled={isReadOnly}
                    label={t('tms:fields.deadline')}
                    name={`${inputPath}minutesToDeadline`}
                    control={control}
                    unitFieldName={`${inputPath}deadlineUnit`}
                    error={!!minutesToDeadlineError}
                    helperText={minutesToDeadlineError}
                  />
                  <Typography mr={2} ml={2}>
                    <TextNoWrap>
                      {t('tms:ui.startingFrom')} {isMainTask && t('tms:fields.deadlineCondition.currentTaskOpening')}
                    </TextNoWrap>
                  </Typography>
                  {!isMainTask && (
                    <DeadlineConditionsSelect
                      required
                      disabled={isReadOnly}
                      name={`${inputPath}deadlineCondition`}
                      label={t('tms:fields.deadlineCondition.__field')}
                      control={control}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {[0, 1, 2].includes(deadlineCondition) && (
                  <AncestorTasksSelect
                    required
                    disabled={isReadOnly}
                    label={t('tms:ui.Tasks')}
                    name={`${inputPath}deadlineTask`}
                    taskDepth={depth}
                    exclude={deadlineCondition === 1 ? [mainTaskId] : []}
                    // defaultValue={deadlineTask}
                    control={control}
                    error={!!deadlineTaskError}
                    helperText={deadlineTaskError}
                  />
                )}
              </Grid>
              {deadlineCondition === 2 && deadlineTask && (
                <Grid item xs={12} md={6}>
                  <TaskDateFieldSelect
                    disabled={isReadOnly}
                    name={`${inputPath}deadlineField`}
                    label={t('tms:fields.deadlineField')}
                    control={control}
                    taskId={deadlineTask}
                    error={!!deadlineFieldError}
                    helperText={deadlineFieldError}
                  />
                </Grid>
              )}
            </Grid>
          </TabPanel>
          {!isMainTask && (
            <>
              <TabPanel activeIndex={activeTab} index={3}>
                <Box direction="row" align="center" margin="0 0 1rem">
                  <Info fontSize="small" color="neutralOne" />
                  <Typography ml={1}>
                    <Trans i18nKey="tms:info.OpenConditionsHelpText" />
                  </Typography>
                </Box>
                <TaskOpenConditionList prefix={prefix} taskDepth={depth} taskId={taskId} isReadOnly={isReadOnly} />
              </TabPanel>
              <TabPanel activeIndex={activeTab} index={4}>
                <Box direction="row" align="center" margin="0 0 1rem">
                  <Info fontSize="small" color="neutralOne" />
                  <Typography ml={1}>{t('tms:info.CloseConditionsHelpText')}</Typography>
                </Box>
                <TaskCloseConditionList prefix={prefix} taskDepth={depth} taskId={taskId} isReadOnly={isReadOnly} />
              </TabPanel>
            </>
          )}
          {!isReadOnly && (
            <Stack direction="row" justifyContent="end" spacing={2} marginTop="1rem">
              {!isMainTask && (
                <Button size="small" color="darkThree" onClick={() => onDelete(index, taskName)}>
                  {t('common:actions.Delete')}
                </Button>
              )}
              <Button size="small" disableElevation variant="contained" onClick={addNewTask} startIcon={<Add />}>
                {t('tms:actions.AddSubtask')}
              </Button>
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
      {fields.map((child, index) => (
        <Li key={child.id}>
          <TaskForm
            prefix={`${prefix}children.${index}.`}
            depth={depth + 1}
            index={index}
            isReadOnly={isReadOnly}
            onDelete={handleDeleteChildTask}
          />
        </Li>
      ))}
      {fieldForm.open && (
        <TaskFieldForm
          field={fieldForm.field}
          onSave={handleSaveField}
          onClose={() => setFieldForm({ open: false, field: null })}
        />
      )}
      {DeleteConfirm}
    </Ul>
  )
}

TaskForm.propTypes = {
  isMainTask: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  prefix: PropTypes.string,
  depth: PropTypes.number,
  index: PropTypes.number,
  onDelete: PropTypes.func,
}

export default TaskForm
