import PropTypes from 'prop-types'

export const CityType = PropTypes.shape({
  province: PropTypes.number.isRequired,
  provinceObj: PropTypes.shape({
    region: PropTypes.number.isRequired,
    regionObj: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
})
