import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useBuildingFacilitiesQuery } from '@Buildings/Services/Api'
import FacilityDetailModal from '@Buildings/Components/FacilityDetailModal'
import Facility from '@Buildings/Models/Facility'
import DataTable from '@Common/Components/DataTable'
import { Visibility } from '@mui/icons-material'
import * as R from 'ramda'

const DISPLAY_COLUMNS = [
  'tipologia',
  'descrizione',
  'matricola',
  'edificio',
  'scala',
  'fornitori',
]
const SEARCH_FIELDS = [
  'tipologia',
  'descrizione',
  'matricola',
  'edificio',
]
const FIELDS_MAPPING = {
  'fornitori': R.pipe(R.prop('fornitori'), R.map(R.path(['fornitore', 'nominativo'])), R.join(', '))
}

const FacilityList = ({ buildingArcadiaId }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useBuildingFacilitiesQuery(buildingArcadiaId)
  const [selected, setSelected] = React.useState([])

  const [detailItem, setDetailItem] = React.useState(null)
  const handleCloseDetail = () => setDetailItem(null)
  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        label: t('common:actions.Detail'),
        icon: <Visibility size="small" />,
      },
    ],
    [],
  )

  const handleAction = (_, facility) => {
    setDetailItem(facility)
  }

  return (
    <div>
      <DataTable
        name="facilities"
        data={data || []}
        model={Facility}
        sortField="id"
        sortDirection="desc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        actions={actions}
        loading={isLoading}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noExport
      />
      {detailItem && <FacilityDetailModal facility={detailItem} onClose={handleCloseDetail} />}
    </div>
  )
}

FacilityList.propTypes = {
  buildingArcadiaId: PropTypes.string,
}

export default FacilityList
