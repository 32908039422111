import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'

import { ItemContainer } from '@Common/Components/ItemContainer'
import { ItemField } from '@Common/Components/ItemField'
import { ItemCardTable } from '@Common/Components/SectionCard'
import { api } from '@Core/Services/Api'
import { CondoSupplierCompany } from '@Supplier/Models/CondoSupplierCompany'

export const CondoSupplierCompanyDetail = () => {
  const { buildingId, condoSupplierId } = useParams()

  const { data } = api.useGetItemQuery(`/condo/buildings/${buildingId}/suppliers/${condoSupplierId}`)

  return (
    <ItemContainer item={data} model={CondoSupplierCompany}>
      <Grid spacing={3} container>
        <Grid item md={12}>
          <ItemCardTable title="Associazione Condominio">
            <ItemField name="blacklist" />
            <ItemField name="notes" />
          </ItemCardTable>
        </Grid>
      </Grid>
    </ItemContainer>
  )
}
