import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, FormControl } from '@mui/material'
import TextField from '../../Common/Components/TextField'
import BranchSelect from '@Branches/Components/BranchSelect'
import { setStateFromEvent } from '../../Common/Utils/Events'
import styled from 'styled-components'
import { useForm } from '../../Common/Utils/Hooks'

const Section = styled.section``
const SubmitRow = styled.div`
  margin-top: 1rem;
  text-align: center;
`

const SearchBuildingForm = ({ onSubmit }) => {
  const { t } = useTranslation()
  const { fields, setField } = useForm(
    {
      buildingName: '',
      fiscalCode: '',
      city: '',
      address: '',
      branch: '',
    },
    true,
  )

  const handleKeyDown = (evt) => {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      submit()
    }
  }

  const submit = () => onSubmit(fields)

  return (
    <Section onKeyDown={handleKeyDown}>
      <FormControl fullWidth>
        <TextField
          label={t('customerCare:fields.buildingName')}
          value={fields.buildingName}
          onChange={setStateFromEvent(setField('buildingName'))}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t('customerCare:fields.fiscalCode')}
          value={fields.fiscalCode}
          onChange={setStateFromEvent(setField('fiscalCode'))}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t('customerCare:fields.city')}
          value={fields.city}
          onChange={setStateFromEvent(setField('city'))}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t('customerCare:fields.address')}
          value={fields.address}
          onChange={setStateFromEvent(setField('address'))}
        />
      </FormControl>
      <FormControl fullWidth>
        <BranchSelect
          value={fields.branch}
          label={t('customerCare:fields.branch')}
          onChange={setStateFromEvent(setField('branch'))}
        />
      </FormControl>
      <SubmitRow>
        <Button variant="outlined" size="small" onClick={submit}>
          {t('common:actions.Search')}
        </Button>
      </SubmitRow>
    </Section>
  )
}

SearchBuildingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SearchBuildingForm
