import { Link as MuiLink } from '@mui/material'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { Link } from 'react-router-dom'

import { makePath } from '@Config'

export const getBuildingAddress = (building, full = false) => {
  if (!full) {
    return `${building.address} ${building.houseNumber}, ${building.cap} ${building.cityObj.name}` || null
  }

  return (
    `${building.address} ${building.houseNumber}, ${building.cityObj?.name} (${building.cityObj?.provinceObj?.abbreviation})` ||
    null
  )
}

export const formatDate = (date, format = 'DD/MM/YYYY') => (date ? dayjs(date).format(format) : null)

export const formatCurrency = (value) =>
  value ? `${Number(value).toLocaleString(i18next.language, { minimumFractionDigits: 2, currency: 'EUR' })} €` : null

export const genericConvertSearchQuery = (qs, SEARCH_FIELDS) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${field}__icontains__or`] = qs.qsAdditions.search
    })

    delete qs.qsAdditions.search
  }

  return qs
}

export const getFieldsMappingCondo = (isAdmin) => {
  return {
    // eslint-disable-next-line react/display-name
    buildingDescription: (record) => {
      if (record?.buildingObj?.id) {
        return (
          <MuiLink
            component={Link}
            underline="always"
            to={`${isAdmin ? '/admin' : ''}/buildings/${record?.buildingObj?.id}`}
          >
            {record?.buildingObj?.description}
          </MuiLink>
        )
      }
      return null
    },
    buildingFullAddress: (record) => {
      return (
        `${record?.buildingObj?.address} ${record?.buildingObj?.houseNumber}, ${record?.buildingObj?.cityObj?.name} (${record?.buildingObj?.cityObj?.provinceObj?.abbreviation})` ||
        null
      )
    },
    buildingFiscalCode: (record) => {
      return record?.buildingObj?.fiscalCode
    },
    branchBuildingName: (record) => {
      return record?.buildingObj?.branchObj?.name || null
    },
  }
}

export const getDisplayColumnsCondo = () => {
  return ['buildingDescription', 'buildingFullAddress', 'buildingFiscalCode', 'branchBuildingName']
}

export const getSearchFieldsColumnsCondo = () => {
  return [
    'building__description',
    'building__fiscal_code',
    'building__city__name',
    'building__branch__name',
    'building__referring_administrator__first_name',
    'building__referring_administrator__last_name',
    'building__dedicated_administrator__first_name',
    'building__dedicated_administrator__last_name',
  ]
}

export const getGenericConfigColumnsCondo = () => {
  return [
    {
      id: 'buildingDescription',
      label: i18next.t('condo:fields.buildingDescription'),
      ordering: 'building__description',
    },
    {
      id: 'buildingFullAddress',
      label: i18next.t('condo:fields.buildingFullAddress'),
      ordering: false,
    },
    {
      id: 'buildingFiscalCode',
      label: i18next.t('condo:fields.buildingFiscalCode'),
      ordering: 'building__fiscal_code',
    },
    {
      id: 'branchBuildingName',
      label: i18next.t('condo:fields.branchBuildingName'),
      ordering: 'building__branch__name',
    },
  ]
}

export const getDetailPathEntity = (isAdmin, entityId, entityType, buildingId = null) => {
  const basePath = isAdmin ? 'admin.' : ''

  let detailPath = null

  if (buildingId) {
    detailPath = makePath(`${basePath}buildings.detail.${entityType}.detail.main`, {
      buildingId: buildingId,
      id: entityId,
    })
  } else {
    detailPath = makePath(`${basePath}${entityType}.detail.main`, { id: entityId })
  }

  return detailPath
}
