import { isAdminRoute } from '@Common/Utils/Url'

import { BranchBuildingsView } from './BranchBuildingsView'
import BuildingsView from './BuildingsView'

export const BuildingsListView = () => {
  const isAdmin = isAdminRoute()

  // in admin route, we can list all buildings
  if (isAdmin) {
    return <BuildingsView />
  }
  // in NOT admin route, we can list only branch buildings
  return <BranchBuildingsView />
}
