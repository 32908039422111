import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Grid, Link, List, ListItem, ListItemText, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Margin } from '@Common/Components/Styles'
import { makePath } from '@Config'
import { ReportType } from '@Tickets/Models/Report'

const ReportData = ({ isResident, report }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} my={2}>
        <Typography fontSize={14} fontWeight="500">
          {t('tickets:fields.type')}
        </Typography>
        <Typography fontSize={14} maxHeight={120} overflow="hidden auto">
          {report.reportTypeObj.name}
        </Typography>
      </Grid>
      {!isResident && report.taskFlow && (
        <Grid item xs={12} md={6} my={2}>
          <Typography fontSize={14} fontWeight="500">
            {t('tickets:detail.RelatedTask')}
          </Typography>
          <RouterLink to={makePath('tms.taskDetail', { taskId: report.taskFlow.mainRunningTask.id })}>
            <Box direction="row" align="center">
              <Margin right="5px">{report.taskFlow.mainRunningTask.title}</Margin>
              <OpenInNewIcon fontSize="1rem" />
            </Box>
          </RouterLink>
        </Grid>
      )}
      <Grid item xs={12} my={2}>
        <Typography fontSize={14} fontWeight="500">
          {t('tickets:detail.Description')}
        </Typography>
        <Typography fontSize={14} maxHeight={120} overflow="hidden auto">
          {report.description}
        </Typography>
      </Grid>
      {report.attachmentObjs?.length && (
        <Grid item xs={12}>
          <Typography fontSize={14} fontWeight="500">
            {t('tickets:detail.Attachments')}
          </Typography>
          <List dense sx={{ listStyleType: 'disc', paddingLeft: '30px', paddingTop: 0 }}>
            {report.attachmentsObjs?.map((file, index) => (
              <ListItem key={index} sx={{ display: 'list-item', padding: 0, flexDirection: 'row' }}>
                <ListItemText>
                  <Link
                    variant="h1"
                    marginBottom="0.3rem"
                    fontSize={12}
                    textAlign="left"
                    href={file.file}
                    target="_blank"
                  >
                    {file.fileName}
                  </Link>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  )
}

ReportData.defaultProps = {
  isResident: false,
}

ReportData.propTypes = {
  isResident: PropTypes.bool,
  report: ReportType,
}

export default ReportData
