import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import TextField from '@Common/Components/Forms/TextField'
import { Link } from '@Common/Components/Navigation/Styled'
import LabeledBox from '@Tms/Components/LabeledBox'

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  
  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const pattern = {
  value: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*$/,
  message: i18next.t('tms:validation.InvalidUrl'),
}

const UrlField = ({ isTaskUpdate, rules, ...fieldProps }) => {
  const [hasLinkAppearance, setHasLinkAppearance] = useState(isTaskUpdate && fieldProps.defaultValue)

  const textFieldRef = useRef(null)

  const { trigger, watch } = useFormContext()
  const currentValue = watch(fieldProps.name)

  const isEditable = useMemo(() => !fieldProps.disabled, [fieldProps.disabled])

  const onManualEdit = () => {
    setHasLinkAppearance(false)
    textFieldRef.current?.focus()
  }

  const validateOnFocusOut = () =>
    trigger(fieldProps.name).then((valid) => {
      if (valid && currentValue && isTaskUpdate) {
        setHasLinkAppearance(true)
      }
    })

  useEffect(() => {
    validateOnFocusOut()
  }, [])

  return (
    <>
      {hasLinkAppearance && (
        <LabeledBox label={fieldProps.label}>
          <LinkContainer>
            <Link href={currentValue} target="_blank">
              {currentValue}
            </Link>
            {isEditable && (
              <IconButton onClick={onManualEdit} size="small">
                <EditIcon />
              </IconButton>
            )}
          </LinkContainer>
        </LabeledBox>
      )}

      <TextField
        inputRef={textFieldRef}
        rules={{ ...rules, pattern }}
        onBlur={validateOnFocusOut}
        style={{ display: hasLinkAppearance ? 'none' : 'block' }}
        {...fieldProps}
      />
    </>
  )
}

UrlField.propTypes = TextField.propTypes & { isTaskUpdate: PropTypes.bool }

export default UrlField
