import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { makePath } from '@Config'
import CustomerCare from '@MyEstia/Components/CustomerCare'
import NotificationsPanel from '@MyEstia/Components/NotificationsPanel'
import NotificationsWebSocket from '@MyEstia/Components/NotificationsWebSocket'
import { selectActiveHousingUnitId } from '@MyEstia/Redux'

import MyEstiaFooter from '../Components/MyEstiaFooter'
import MyEstiaNavbar from '../Components/MyEstiaNavbar'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
`

const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  margin-top: 120px;
  width: 100%;
`

const Main = styled.main`
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    color: ${theme.palette.background.constrastText};
  `}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`

const MyEstiaLayout = ({ children }) => {
  const housingUnitId = useSelector(selectActiveHousingUnitId)
  const [open, setOpen] = useState(false)
  const pathname = useSelector(R.path(['router', 'location', 'pathname']))
  const myHomePath = makePath('myEstia.myHome.main', { housingUnitId })
  const ticketPath = 'ticket'
  const ticketRegex = /.*ticket\/\d+\/?$/gm

  const showFooter = (pathname) => {
    const notOnMyHome = !pathname.replace(/\/$/, '').includes(myHomePath.replace(/\/$/, ''))
    const onTicketRouter = pathname.replace(/\/$/, '').includes(ticketPath)
    const notOnTicketDetail = !pathname.replace(/\/$/, '').includes(ticketPath + '/')

    return notOnMyHome || (onTicketRouter && notOnTicketDetail)
  }

  return (
    <Wrapper>
      <MyEstiaNavbar />
      <Container>
        <Main>{children}</Main>
        {!ticketRegex.test(pathname) && <CustomerCare open={open} setOpen={setOpen} />}
      </Container>
      {showFooter(pathname) && <MyEstiaFooter />}
      <NotificationsPanel />
      <NotificationsWebSocket />
    </Wrapper>
  )
}

MyEstiaLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default MyEstiaLayout
