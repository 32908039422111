import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from '@Common/Utils/Hooks'
import { Button, FormControl, Grid, IconButton, InputAdornment, Link, Typography } from '@mui/material'
import { Box, Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import Alert from '@Common/Components/Alert'
import Loader from '@Common/Components/Loader'
import { useRegisterMutation } from '../../Services/Api'
import config from '@Config'
import { responseStringError } from '@Common/Utils/Api'
import logger from '@Common/Utils/Logger'
import * as R from 'ramda'
import { setStateFromCheckboxEvent, setStateFromEvent, toUpperCaseFromEvent } from '@Common/Utils/Events'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InvitationType } from '@Auth/Models/Invitation'
import Checkbox from '@Common/Components/Checkbox'
import { validate } from '@Auth/Forms/RegistrationForm/Validation'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { convertServerErrors } from '@Common/Utils/Form'
import { isLegalPerson } from '@Residents/Utils'

const Container = styled.div``

const Form = styled.div``

const RegistrationForm = ({ invitation, invitationToken }) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const [registrationCompleted, setRegistrationCompleted] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const { fields, setField, errors, setErrors } = useForm(
    {
      fiscalCode: R.defaultTo(null, isLegalPerson(invitation.raw) ? null : invitation.fiscalCode),
      vatNumber: R.defaultTo(null, isLegalPerson(invitation.raw) ? invitation.vatNumber : null),
      username: R.defaultTo(null, invitation.emailsObjs[0]),
      phone: null,
      password: null,
      passwordRepeat: null,
      token: R.defaultTo(null, invitationToken),
      terms: false,
      privacy: false,
    },
    true,
  )

  const [register, { isLoading }] = useRegisterMutation()

  const submit = async () => {
    if (validate(fields, setErrors, invitation)) {
      try {
        await register(buildPayload(fields)).unwrap()
        setRegistrationCompleted(true)
      } catch (err) {
        logger.debug('Register error', err)
        if (err.status === 400 && !err.data.token) {
          setErrors(convertServerErrors(err))
        } else if (err.data.token) {
          setErrors({ ...errors, __form: err.data.token })
        } else {
          setErrors({ ...errors, __form: responseStringError(err) })
        }
      }
    }
  }

  const buildPayload = ({ username, password, phone, token, terms, privacy, fiscalCode, vatNumber }) => ({
    username,
    password,
    token,
    fiscalCode,
    vatNumber,
    resident: {
      phone,
      terms,
      privacy,
    },
  })

  return (
    <Container>
      {registrationCompleted && (
        <Margin bottom="2rem">
          <Typography fontSize={18} align="center">
            {t('auth:success.ResidentRegistrationCompleted', { email: fields.username })}
          </Typography>
        </Margin>
      )}
      {!registrationCompleted && (
        <Form>
          <Margin bottom="2rem">
            <Typography fontSize={18} align="center">
              {t('auth:ui.RegisterFormText')}
            </Typography>
          </Margin>
          {errors.__form && <Alert level="error">{errors.__form}</Alert>}
          <Grid container columnSpacing={2}>
            {isLegalPerson(invitation.raw) && (
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    disabled
                    value={invitation.businessName}
                    label={t('auth:fields.anagraphic.companyName')}
                    error={!!errors.businessName}
                    helperText={errors.businessName}
                  />
                </FormControl>
              </Grid>
            )}
            {!isLegalPerson(invitation.raw) && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      disabled
                      value={invitation.firstName}
                      label={t('auth:fields.firstName')}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      disabled
                      value={invitation.lastName}
                      label={t('auth:fields.lastName')}
                      onChange={setStateFromEvent(setField('lastName'))}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          <FormControl fullWidth margin="normal">
            {isLegalPerson(invitation.raw) && (
              <TextField
                required
                value={fields.vatNumber}
                label={t('auth:fields.anagraphic.vat')}
                onChange={setStateFromEvent(setField('vatNumber'))}
                error={!!errors.vatNumber}
                helperText={errors.vatNumber}
                onInput={toUpperCaseFromEvent}
              />
            )}
            {!isLegalPerson(invitation.raw) && (
              <TextField
                required
                value={fields.fiscalCode}
                label={t('auth:fields.fiscalCode')}
                onChange={setStateFromEvent(setField('fiscalCode'))}
                error={!!errors.fiscalCode}
                helperText={errors.fiscalCode}
                onInput={toUpperCaseFromEvent}
              />
            )}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              required
              value={fields.phone}
              label={t('auth:fields.phone')}
              onChange={setStateFromEvent(setField('phone'))}
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              required
              value={fields.username}
              label={t('auth:fields.email')}
              onChange={setStateFromEvent(setField('username'))}
              error={!!errors.username}
              helperText={errors.username}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              required
              value={fields.password}
              label={t('auth:fields.password')}
              onChange={setStateFromEvent(setField('password'))}
              error={!!errors.password}
              helperText={errors.password}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              required
              value={fields.passwordRepeat}
              label={t('auth:fields.repeatPassword')}
              onChange={setStateFromEvent(setField('passwordRepeat'))}
              error={!!errors.passwordRepeat}
              helperText={errors.passwordRepeat}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Margin top="1rem">
            <Checkbox
              label={
                <Trans
                  i18nKey="auth:fields.terms"
                  components={[
                    <Link key="terms" target="_blank" href="https://estia.homes/termini-e-condizioni-app/" />,
                  ]}
                />
              }
              checked={fields.terms}
              onChange={setStateFromCheckboxEvent(setField('terms'))}
            />
            {!!errors.terms && (
              <Typography fontSize={14} color="error.main">
                {errors.terms}
              </Typography>
            )}
          </Margin>

          <Margin top="1rem">
            <Checkbox
              label={
                <Trans
                  i18nKey="auth:fields.privacy"
                  components={[
                    <Link key="privacy-policy" target="_blank" href="https://estia.homes/privacy-policy-app/" />,
                  ]}
                />
              }
              checked={fields.privacy}
              onChange={setStateFromCheckboxEvent(setField('privacy'))}
            />
            {!!errors.privacy && (
              <Typography fontSize={14} color="error.main">
                {errors.privacy}
              </Typography>
            )}
          </Margin>
          <Margin top="2rem" bottom="2rem">
            <Box direction="row" justify="center">
              {isLoading && <Loader overlay />}
              {!isLoading && (
                <Button fullWidth variant="contained" onClick={submit}>
                  {t('auth:buttons.Register')}
                </Button>
              )}
            </Box>
          </Margin>
        </Form>
      )}
      <Typography textAlign="center" color="text.primary">
        <Trans
          i18nKey="auth:ui.LoginSupport"
          values={{ email: config.contacts.supportEmail }}
          components={[<Link key="support-email" href={`mailto:${config.contacts.supportEmail}`} />]}
        />
      </Typography>
    </Container>
  )
}

RegistrationForm.propTypes = {
  invitation: InvitationType,
  invitationToken: PropTypes.string.isRequired,
}

export default RegistrationForm
