import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'

import Dropdown from '@Common/Components/Dropdown'
import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/Field'
import { withLoader } from '@Common/Utils/HOC'
import { useCategoriesQuery } from '@Tms/Services/Api'

const buildPath = (category) => {
  if (!category.parent) return category
  const parent = buildPath(category.parent)
  return { ...category, name: `${parent.name} / ${category.name}` }
}

const CategorySelect = ({ control, name, id, qs, notRHF, ...other }) => {
  const { data } = useCategoriesQuery(qs)
  const [categories, setCategories] = useState(null)
  const filterCategories = R.pipe(
    R.defaultTo([]),
    R.filter(R.both(R.complement(R.propEq('id', id)), R.propEq('isActive', true))),
  )

  useEffect(() => {
    if (data) {
      const categories = filterCategories(data?.results).map(buildPath)
      setCategories(categories)
    }
  }, [data?.results])

  return withLoader(
    <>
      {!notRHF && (
        <SelectField
          name={name}
          control={control}
          searchable
          width="100%"
          options={categories}
          labelKey="name"
          valueKey="id"
          {...other}
        />
      )}
      {notRHF && (
        <Dropdown
          options={categories}
          onChange={other.onChange}
          searchable
          width="100%"
          labelKey="name"
          valueKey="id"
          value={other?.options?.length ? other.value : ''}
          {...other}
        />
      )}
    </>,
    !categories,
    'inline',
    { minHeight: '0', size: 20, justifyStart: true },
  )
}

CategorySelect.defaultProps = {
  id: 0,
  qs: {},
  notRHF: false,
}

CategorySelect.propTypes = {
  ...FieldType,
  qs: PropTypes.object,
  notRHF: PropTypes.bool,
}

export default CategorySelect
