import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import PrivateRoute from '@Common/Components/PrivateRoute'
import AdminLayout from '@Common/Layouts/AdminLayout'
import OperationalLayout from '@Common/Layouts/OperationalLayout'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { logsToOperationalDashboard } from '@Common/Utils/Permissions'
import config from '@Config'
import { TaskCategoryPermissions } from '@Tms/Permissions'
import AdminTaskCategoriesView from '@Tms/Views/AdminTaskCategoriesView'
import AdminTaskCategoryFormView from '@Tms/Views/AdminTaskCategoryFormView'
import TaskDetailView from '@Tms/Views/TaskDetailView'
import TasksView from '@Tms/Views/TasksView'

const TmsRoutes = () => (
  <Routes>
    <Route
      exact
      path={config.urls.tms.taskList}
      element={
        <PrivateRoute>
          <TasksView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.tms.taskDetail}
      element={
        <PrivateRoute>
          <TaskDetailView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.tms.categories}
      element={
        <PrivateRoute permFunction={TaskCategoryPermissions.read}>
          <AdminTaskCategoriesView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.tms.createCategory}
      element={
        <PrivateRoute permFunction={TaskCategoryPermissions.create}>
          <AdminTaskCategoryFormView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.tms.editCategory}
      element={
        <PrivateRoute permFunction={TaskCategoryPermissions.update}>
          <AdminTaskCategoryFormView />
        </PrivateRoute>
      }
    />
    <Route path="*" element={<Navigate to={config.urls.tms.taskList} replace />} />
  </Routes>
)

export const TmsRouter = () => {
  const user = useCurrentUser()
  if (logsToOperationalDashboard(user)) {
    return (
      <OperationalLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
        <TmsRoutes />
      </OperationalLayout>
    )
  }
  return (
    <AdminLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
      <TmsRoutes />
    </AdminLayout>
  )
}

export default TmsRouter
