/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material'

export const ChipStatusItem = ({ text, color }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        verticalAlign: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          backgroundColor: `${color}.dark`,
          borderRadius: 20,
          marginRight: 1,
        }}
      />
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: `${color}.dark`,
          margin: 0,
          paddingTop: '2px',
        }}
        noWrap={true}
      >
        {text}
      </Typography>
    </Box>
  )
}
