import { FormControl, Grid, Box as MuiBox, RadioGroup, FormLabel, InputAdornment } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import DatePicker from '@Common/Components/DatePicker'
import Dropdown from '@Common/Components/Dropdown'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import Radio from '@Common/Components/Radio'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import CondosSelectField from '@Condo/Components/CondosSelectField'
import { InsuranceIndexType, InsurancePolicyType, InsurancePremiumPeriodicity } from '@Condo/Models/InsurancePolicy'

import { formatDate, validate } from './Validation'

const InsurancePolicyForm = ({ insurancePolicy, onClose, onSave, showSelectBuilding = true }) => {
  const { t } = useTranslation()

  const name = insurancePolicy?.number || ''
  const title = insurancePolicy?.id ? t('condo:ui.EditInsurancePolicy', { name }) : t('condo:ui.CreateInsurancePolicy')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { fields, setField, setFields, errors, setErrors } = useForm(
    {
      ...insurancePolicy,
      indexed: insurancePolicy?.indexed || InsuranceIndexType[0].id,
      premiumPeriodicity: insurancePolicy?.premiumPeriodicity || '1Y',
      effectiveDate: formatDate(insurancePolicy.effectiveDate),
      expirationDate: formatDate(insurancePolicy.expirationDate),
      lastPremiumPaymentDate: formatDate(insurancePolicy.lastPremiumPaymentDate),
      nextPremiumPaymentDate: formatDate(insurancePolicy.nextPremiumPaymentDate),
      finalCancellationDate: formatDate(insurancePolicy.finalCancellationDate),
    },
    true,
  )

  const submit = async () => {
    const isValid = validate(fields, setErrors)

    if (!isValid) return

    setIsSubmitting(true)

    const payload = buildPayload(fields)
    const { error, isSuccessful } = await onSave(payload)

    setIsSubmitting(false)

    if (!isSuccessful) {
      return setErrors(convertServerErrors(error))
    }

    return onClose()
  }

  /**
   * Quando cambia la data di decorrenza di una polizza,
   * a quella data aggiungiamo 12 mesi e la impostiamo come data di scadenza
   */
  useEffect(() => {
    const effectiveDate = formatDate(fields?.effectiveDate)
    const expirationDate = formatDate(dayjs(effectiveDate).add(12, 'month'))
    setFields({ ...fields, expirationDate })
  }, [fields.effectiveDate])

  /**
   * Quando cambiano la data di scadenza di una polizza oppure in numero giorni di preavviso,
   * alla data di scadenza togliamo i giorni di prevviso e impostiamo
   * la data ultima per la disdetta
   */
  useEffect(() => {
    const expirationDate = formatDate(fields?.expirationDate)
    const cancellationNoticeDays = fields.cancellationNoticeDays
    const finalCancellationDate = formatDate(dayjs(expirationDate).subtract(cancellationNoticeDays, 'days'))
    setFields({ ...fields, finalCancellationDate })
  }, [fields.cancellationNoticeDays, fields.expirationDate])

  /**
   * Quando cambiano la data scadenza ultimo premio pagato oppure la periodicità,
   * alla data di scadenza ultimo premio dobbiamo sommare la periodicità
   */
  useEffect(() => {
    const periodicitiesMonth = { '1M': 1, '2M': 2, '3M': 3, '4M': 4, '6M': 6, '1Y': 12 }
    const month = periodicitiesMonth[fields.premiumPeriodicity]
    const lastPremiumPaymentDate = formatDate(fields?.lastPremiumPaymentDate)
    const nextPremiumPaymentDate = lastPremiumPaymentDate
      ? formatDate(dayjs(lastPremiumPaymentDate).add(month, 'month'))
      : fields.nextPremiumPaymentDate

    setFields({ ...fields, nextPremiumPaymentDate })
  }, [fields.lastPremiumPaymentDate, fields.premiumPeriodicity])

  // transform fields for API
  const buildPayload = (fields) => {
    const payload = {
      ...fields,
      effectiveDate: formatDate(fields.effectiveDate),
      expirationDate: formatDate(fields.expirationDate),
      lastPremiumPaymentDate: formatDate(fields.lastPremiumPaymentDate),
      nextPremiumPaymentDate: formatDate(fields.nextPremiumPaymentDate),
      finalCancellationDate: formatDate(fields.finalCancellationDate),
    }

    return payload
  }

  return (
    <Modal title={title} size="lg" onClose={onClose} onSubmit={submit} sx={{ paddingTop: 0 }}>
      {isSubmitting && <Loader overlay />}
      <Box>
        <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
          <Grid container rowSpacing={3} spacing={2}>
            {showSelectBuilding && (
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <CondosSelectField
                    value={fields.building}
                    label={t('condo:ui.Building')}
                    onChange={setStateFromEvent(setField('building'))}
                    error={!!errors.building}
                    helperText={errors.building}
                    required
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={fields?.company}
                  label={t('condo:insurancePolicy.fields.company')}
                  onChange={setStateFromEvent(setField('company'))}
                  error={!!errors.company}
                  helperText={errors.company}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={fields?.number}
                  label={t('condo:insurancePolicy.fields.number')}
                  onChange={setStateFromEvent(setField('number'))}
                  error={!!errors.number}
                  helperText={errors.number}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  value={fields?.premium}
                  label={t('condo:insurancePolicy.fields.premium')}
                  onChange={setStateFromEvent(setField('premium'))}
                  error={!!errors.premium}
                  helperText={errors.premium}
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Dropdown
                  options={InsurancePremiumPeriodicity}
                  labelKey="label"
                  valueKey="id"
                  value={fields?.premiumPeriodicity}
                  label={t('condo:insurancePolicy.fields.premiumPeriodicity')}
                  onChange={setStateFromEvent(setField('premiumPeriodicity'))}
                  error={!!errors.premiumPeriodicity}
                  helperText={errors.premiumPeriodicity}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  value={fields?.effectiveDate}
                  label={t('condo:insurancePolicy.fields.effectiveDate')}
                  onChange={setField('effectiveDate')}
                  error={!!errors.effectiveDate}
                  helperText={errors.effectiveDate}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  value={fields?.expirationDate}
                  label={t('condo:insurancePolicy.fields.expirationDate')}
                  onChange={setField('expirationDate')}
                  error={!!errors.expirationDate}
                  helperText={errors.expirationDate}
                  required
                  minDate={fields?.effectiveDate ? dayjs(fields?.effectiveDate) : null}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={fields?.cancellationNoticeDays}
                  label={t('condo:insurancePolicy.fields.cancellationNoticeDays')}
                  onChange={setStateFromEvent(setField('cancellationNoticeDays'))}
                  error={!!errors.cancellationNoticeDays}
                  helperText={errors.cancellationNoticeDays}
                  type="number"
                  inputProps={{ min: 0, step: 1 }}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  value={fields?.finalCancellationDate}
                  label={t('condo:insurancePolicy.fields.finalCancellationDate')}
                  onChange={setField('finalCancellationDate')}
                  error={!!errors.finalCancellationDate}
                  helperText={errors.finalCancellationDate}
                  maxDate={fields?.expirationDate ? dayjs(fields?.expirationDate) : null}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  value={fields?.lastPremiumPaymentDate}
                  label={t('condo:insurancePolicy.fields.lastPremiumPaymentDate')}
                  onChange={setField('lastPremiumPaymentDate')}
                  error={!!errors.lastPremiumPaymentDate}
                  helperText={errors.lastPremiumPaymentDate}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  value={fields?.nextPremiumPaymentDate}
                  label={t('condo:insurancePolicy.fields.nextPremiumPaymentDate')}
                  onChange={setField('nextPremiumPaymentDate')}
                  error={!!errors.nextPremiumPaymentDate}
                  helperText={errors.nextPremiumPaymentDate}
                  required
                  minDate={fields?.lastPremiumPaymentDate ? dayjs(fields?.lastPremiumPaymentDate) : null}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel>{t('condo:insurancePolicy.fields.indexed')} *</FormLabel>
                <RadioGroup
                  row
                  name="indexed"
                  onChange={setStateFromEvent(setField('indexed'))}
                  error={!!errors.indexed}
                  helperText={errors.indexed}
                  value={fields.indexed}
                  required
                >
                  {InsuranceIndexType.map((item) => (
                    <Radio key={item.id} value={item.id} label={item.label} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  value={fields?.insuredValue}
                  label={t('condo:insurancePolicy.fields.insuredValue')}
                  onChange={setStateFromEvent(setField('insuredValue'))}
                  error={!!errors.insuredValue}
                  helperText={errors.insuredValue}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  multiline
                  rows={5}
                  value={fields?.notes}
                  label={t('condo:insurancePolicy.fields.notes')}
                  onChange={setStateFromEvent(setField('notes'))}
                  error={!!errors.notes}
                  helperText={errors.notes}
                />
              </FormControl>
            </Grid>
          </Grid>
        </MuiBox>
      </Box>
    </Modal>
  )
}

InsurancePolicyForm.propTypes = {
  insurancePolicy: InsurancePolicyType.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showSelectBuilding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
}

export default InsurancePolicyForm
