import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UnsavedChangesDialog = ({ open, onCancel, onSaveForLater, onDiscardChanges }) => {
  const { t } = useTranslation()
  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle>{t('tms:ui.UnsavedChangesTitle')}</DialogTitle>
      <DialogContent dividers>
        {t('tms:ui.UnsavedChangesText')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          {t('tms:actions.ContinueEditing')}
        </Button>
        <Button onClick={onSaveForLater} color="primary">
          {t('tms:actions.SaveForLater')}
        </Button>
        <Button onClick={onDiscardChanges} color="primary">
          {t('tms:actions.DiscardChanges')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UnsavedChangesDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveForLater: PropTypes.func.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
}

export default UnsavedChangesDialog
