import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import HousingUnit, { HousingUnitType } from '@Buildings/Models/HousingUnit'
import DataTable from '@Common/Components/DataTable'
import { getDisplayName } from '@Residents/Utils'

import HousingUnitModal from './HousingUnitModal'

const DISPLAY_COLUMNS = ['descrizione', 'anagraphics', 'codice', 'interno']

const SEARCH_FIELDS = ['descrizione', 'codice']

const FIELDS_MAPPING = {
  anagraphics: R.pipe(
    R.prop('composizioni'),
    R.pluck('anagrafica'),
    R.map(
      R.ifElse(
        R.pathEq(['classe', 'id'], 64),
        (a) =>
          R.concat(
            R.join('', [
              '<b>Gruppo</b>',
              R.ifElse(
                R.pipe(R.prop('nominativo'), R.isNil),
                '',
                R.compose(R.concat(' <i>'), R.flip(R.concat)('</i>'), R.prop('nominativo')),
              )(a),
              ': <br>',
            ]),
          )(R.pipe(R.prop('componentiGruppo'), R.map(getDisplayName), R.join(', '))(a)),
        getDisplayName,
      ),
    ),
    R.join(', '),
    (fieldContent) => <span dangerouslySetInnerHTML={{ __html: fieldContent }}></span>,
  ),
}

const HousingUnitsList = ({ buildingArcadiaId, stair }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState()
  const [detailItem, setDetailItem] = React.useState(null)
  const handleCloseDetail = () => setDetailItem(null)
  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        label: t('common:actions.Detail'),
        icon: <AddCircleOutlineIcon size="small" />,
      },
    ],
    [],
  )

  const handleAction = (_, housingUnit) => {
    setDetailItem(housingUnit)
  }

  return (
    <>
      <DataTable
        name="housing-units"
        data={stair.unita}
        model={HousingUnit}
        sortField="ordinamento"
        sortDirection="asc"
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        fieldsMapping={FIELDS_MAPPING}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noBulkSelection
      />
      {detailItem && (
        <HousingUnitModal
          buildingArcadiaId={buildingArcadiaId}
          housingUnitId={detailItem.id}
          onClose={handleCloseDetail}
        />
      )}
    </>
  )
}

HousingUnitsList.propTypes = {
  buildingArcadiaId: PropTypes.string.isRequired,
  stair: PropTypes.shape({
    unita: PropTypes.arrayOf(HousingUnitType),
  }),
}

export default HousingUnitsList
