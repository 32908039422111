import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import styled from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: ${props => props.justifyStart ? 'flex-start' : 'center'};
  min-height: ${props => props.overlay ? 'auto' : props.minHeight};
  ${props => props.overlay ? 'background: rgba(255, 255, 255, .7)' : ''};
  ${props => props.overlay ? (props.absolute ? 'position: absolute' : 'position: fixed') : ''};
  ${props => props.overlay ? 'top: 0' : ''};
  ${props => props.overlay ? 'bottom: 0' : ''};
  ${props => props.overlay ? 'right: 0' : ''};
  ${props => props.overlay ? 'left: 0' : ''};
  ${props => props.overlay ? 'z-index: 10000' : ''};
`

const Loader = ({ minHeight, size, color, overlay, absolute, skeleton, justifyStart }) => {
  return (
    <Wrapper minHeight={minHeight} overlay={overlay} absolute={absolute} justifyStart={justifyStart}>
      {skeleton && (
        <div style={{ width: '100%' }}>
          <Skeleton/>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
      {!skeleton && (
        <CircularProgress color={color} size={size}></CircularProgress>
      )}
    </Wrapper>
  )
}

Loader.defaultProps = {
  minHeight: '0',
  size: 40,
  skeleton: false,
  justifyStart: false,
  color: 'primary',
}

Loader.propTypes = {
  minHeight: PropTypes.string,
  size: PropTypes.number,
  overlay: PropTypes.bool,
  absolute: PropTypes.bool,
  skeleton: PropTypes.bool,
  justifyStart: PropTypes.bool,
  color: PropTypes.string,
}

export default Loader
