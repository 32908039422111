import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getCondoAsideMenu } from '@Buildings/Components/BuildingAside'
import { Box } from '@Common/Components/Styles'
import { useCondoByIdQuery } from '@Condo/Services/Api'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.buildingNav.main};
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  z-index: 1000;
`

const Item = styled.div`
  text-align: center;
  font-size: 0.8rem;
`
const Link = styled.div``

const BuildingBottombar = () => {
  const { buildingId } = useParams()
  const { t } = useTranslation()
  const makeBuildingPath = (path) => makePath(path, { buildingId })
  const { data: building } = useCondoByIdQuery(buildingId)
  const items = getCondoAsideMenu(building)

  const navigateTo = (path) => () => {
    history.push(path)
  }

  return (
    <Container>
      <Box direction="row" justify="space-around" align="center" width="100%" background="buildingNav">
        {items.map(({ path, label, Icon }, idx) => (
          <Item key={idx}>
            <Icon style={{ fontSize: '30px' }} onClick={navigateTo(makeBuildingPath(path))} />
            <Link onClick={navigateTo(makeBuildingPath(path))} margin="0 .5rem 0 0">
              {t(label)}
            </Link>
          </Item>
        ))}
      </Box>
    </Container>
  )
}

export default BuildingBottombar
