import { SvgIcon } from '@mui/material'
import React from 'react'

const TmsTaskCategoryIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <path d="M17.49,6.34s0,0,0,0V1.17c0-.64-.52-1.17-1.17-1.17H1.17C.52,0,0,.52,0,1.17V17.5l4.67-4.67h6.24c.05-3.59,2.98-6.49,6.58-6.49Z"/>
    <path d="M20.75,13.25c.02-.16,.03-.32,.03-.49s-.01-.33-.04-.49l1.06-.82c.09-.07,.12-.21,.06-.32l-1-1.73c-.06-.11-.19-.15-.31-.11l-1.24,.5c-.26-.2-.54-.36-.84-.49l-.19-1.32c-.02-.13-.12-.21-.25-.21h-2c-.12,0-.22,.09-.24,.21l-.19,1.32c-.31,.13-.59,.3-.84,.49l-1.24-.5c-.11-.04-.24,0-.31,.11l-.99,1.73c-.06,.11-.04,.24,.06,.32l1.06,.82c-.03,.16-.05,.33-.05,.49s.01,.33,.04,.49l-1.06,.82c-.09,.07-.12,.21-.06,.32l1,1.73c.06,.11,.19,.15,.31,.11l1.24-.5c.26,.2,.54,.36,.84,.49l.19,1.32c.03,.12,.12,.21,.25,.21h2c.12,0,.23-.09,.24-.21l.19-1.32c.31-.12,.59-.29,.84-.49l1.24,.5c.11,.04,.24,0,.31-.11l1-1.73c.06-.11,.04-.24-.06-.32l-1.05-.82Zm-3.72,1.39c-1.03,0-1.88-.84-1.88-1.88s.84-1.88,1.88-1.88,1.88,.84,1.88,1.88-.84,1.88-1.88,1.88Z"/>
  </SvgIcon>
))

TmsTaskCategoryIcon.displayName = 'TmsTaskCategoryIcon'

export default TmsTaskCategoryIcon;
