import { CardContent, Grid, CardHeader, CircularProgress, Button, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { getInsurancePolicyBreadcrumbs } from '@Buildings/Utils'
import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useConfirm, useCurrentUser } from '@Common/Utils/Hooks'
import ChipInsurancePolicyStatus from '@Condo/Components/ChipInsurancePolicyStatus'
import CondoRecapCardDetail from '@Condo/Components/CondoRecapCardDetail'
import GenericCardOutlinedDetail from '@Condo/Components/GenericCardOutlinedDetail'
import InsurancePaymentsLogsList from '@Condo/Components/InsurancePaymentsLogsList'
import { checkIsClosed } from '@Condo/Models/InsurancePolicy'
import { InsuranceIndexType, InsurancePremiumPeriodicity } from '@Condo/Models/InsurancePolicy'
import { InsurancePolicyPaymentsLogPermissions, InsurancePolicyPermissions } from '@Condo/Permissions'
import {
  useCloseInsurancePolicyMutation,
  useInsurancePolicyByIdQuery,
  useInsurancePolicyPayMutation,
  useReopenInsurancePolicyMutation,
} from '@Condo/Services/ApiInsurancePolicy'
import { formatCurrency, formatDate } from '@Condo/Utils'

const Main = styled.section``

const BuildingInsurancePolicyMainView = () => {
  const user = useCurrentUser()
  const { t } = useTranslation()

  const { id } = useParams()

  const { data } = useInsurancePolicyByIdQuery(id)

  const [insurancePolicyPay] = useInsurancePolicyPayMutation()
  const [closeInsurancePolicy] = useCloseInsurancePolicyMutation()
  const [reopenInsurancePolicy] = useReopenInsurancePolicyMutation()

  useBreadcrumbs(
    [...getInsurancePolicyBreadcrumbs(data), { label: t('buildings:ui.GeneralData') }],
    data?.buildingObj?.description,
    [data],
  )

  const handleCloseInsurance = async (data) => {
    try {
      const response = await closeInsurancePolicy(data.id).unwrap()
      toast.success(t('condo:success.InsurancePolicyClosed', { name: data.description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.CloseInsurancePolicyError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleReopenInsurance = async (data) => {
    try {
      const response = await reopenInsurancePolicy(data.id).unwrap()
      toast.success(t('condo:success.InsurancePolicyReopened', { name: data.description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.ReopenInsurancePolicyError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleSetPayed = async (data) => {
    try {
      const response = await insurancePolicyPay(data.id).unwrap()
      toast.success(t('common:success.GeneralServerSuccess'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('common:errors.GeneralServerError'))
      return { error, isSuccessful: false }
    }
  }

  const [openClosedConfirm, SetClosedConfirm] = useConfirm(handleCloseInsurance)
  const [openReopenConfirm, SetReopenConfirm] = useConfirm(handleReopenInsurance)

  const [openSetPayedConfirm, SetPayedConfirm] = useConfirm(handleSetPayed)

  if (!data) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <>
      <Main>
        <Stack direction="column" justifyContent="flex-end">
          <Stack direction="row" justifyContent="flex-end">
            {!checkIsClosed(data) && InsurancePolicyPaymentsLogPermissions.create(user) && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() =>
                  openSetPayedConfirm(
                    data,
                    t('condo:insurancePolicy.confirmDialog.titleSetPayed'),
                    t('condo:insurancePolicy.confirmDialog.subTitleSetPayed'),
                  )
                }
              >
                {t('condo:insurancePolicy.confirmDialog.titleSetPayed')}
              </Button>
            )}

            {!checkIsClosed(data) && InsurancePolicyPermissions.update(user) && (
              <Button
                color="error"
                variant="outlined"
                onClick={() =>
                  openClosedConfirm(
                    data,
                    t('condo:ui.CloseInsurancePolicyConfirmationTitle'),
                    t('condo:ui.CloseInsurancePolicyConfirmationText'),
                  )
                }
              >
                {t('condo:ui.CloseInsurancePolicyConfirmationTitle')}
              </Button>
            )}

            {checkIsClosed(data) && InsurancePolicyPermissions.update(user) && (
              <Button
                color="error"
                variant="outlined"
                onClick={() =>
                  openReopenConfirm(
                    data,
                    t('condo:ui.ReopenInsurancePolicyConfirmationTitle'),
                    t('condo:ui.ReopenInsurancePolicyConfirmationText'),
                  )
                }
              >
                {t('condo:ui.ReopenInsurancePolicyConfirmationTitle')}
              </Button>
            )}
          </Stack>

          <GenericCardOutlinedDetail
            title={t('condo:insurancePolicy.detail.titleInsurancePolicy')}
            grid={[
              {
                data: [
                  {
                    name: t('condo:insurancePolicy.fields.status'),
                    value: <ChipInsurancePolicyStatus status={data?.status} />,
                  },
                  {
                    name: t('condo:insurancePolicy.fields.number'),
                    value: data?.number || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.company'),
                    value: data?.company || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.insuredValue'),
                    value: formatCurrency(data?.insuredValue) || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.indexed'),
                    value: InsuranceIndexType.find((x) => x.id === data?.indexed)?.label || '-',
                  },
                ],
              },
              {
                data: [
                  {
                    name: t('condo:insurancePolicy.fields.effectiveDate'),
                    value: formatDate(data?.effectiveDate) || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.expirationDate'),
                    value: formatDate(data?.expirationDate) || '-',
                  },
                ],
              },
            ]}
          />

          <CondoRecapCardDetail data={data.buildingObj} />

          <GenericCardOutlinedDetail
            title={t('condo:insurancePolicy.detail.titlePremium')}
            grid={[
              {
                data: [
                  {
                    name: t('condo:insurancePolicy.fields.premiumPeriodicity'),
                    value: InsurancePremiumPeriodicity.find((x) => x.id === data?.premiumPeriodicity)?.label || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.premium'),
                    value: formatCurrency(data?.premium) || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.lastPremiumPaymentDate'),
                    value: formatDate(data?.lastPremiumPaymentDate) || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.nextPremiumPaymentDate'),
                    value: formatDate(data?.nextPremiumPaymentDate) || '-',
                  },
                ],
              },
            ]}
          />

          <GenericCardOutlinedDetail
            title={t('condo:insurancePolicy.detail.titleCancellation')}
            grid={[
              {
                data: [
                  {
                    name: t('condo:insurancePolicy.fields.cancellationNoticeDays'),
                    value: data?.cancellationNoticeDays || '-',
                  },
                  {
                    name: t('condo:insurancePolicy.fields.finalCancellationDate'),
                    value: formatDate(data?.finalCancellationDate) || '-',
                  },
                ],
              },
            ]}
          />

          <Card variant="outlined">
            <CardHeader title={t('condo:insurancePolicy.detail.titleOther')} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                  <NameValueList
                    data={[
                      {
                        name: t('condo:insurancePolicy.fields.notes'),
                        value: data?.notes || '-',
                      },
                      {
                        name: t('condo:insurancePolicy.fields.lastUpdateUser'),
                        value: data?.lastUpdateUserObj?.username || '-',
                      },
                      {
                        name: t('condo:ui.LastUpdateDate'),
                        value: data?.modified ? dayjs(data?.modified).format('DD/MM/YYYY HH:MM') : '-',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {InsurancePolicyPaymentsLogPermissions.read(user) && (
            <Card variant="outlined">
              <CardHeader title={t('condo:insurancePolicy.detail.titleLogsPayment')} />
              <CardContent>
                <InsurancePaymentsLogsList insuranceId={data.id} />
              </CardContent>
            </Card>
          )}
        </Stack>
      </Main>
      {SetClosedConfirm}
      {SetReopenConfirm}
      {SetPayedConfirm}
    </>
  )
}

export default BuildingInsurancePolicyMainView
