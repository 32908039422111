import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useBuildingUnitQuery } from '../Services/Api'
import Modal from '@Common/Components/Modal'
import HousingUnitGeneralData from './HousingUnitGeneralData'
import HousingUnitCadastralData from './HousingUnitCadastralData'
import HousingUnitCompositions from './HousingUnitCompositions'
import { withLoader } from '@Common/Utils/HOC'

const BuildingDetailModal = ({ buildingArcadiaId, housingUnitId, onClose }) => {
  const { t } = useTranslation()
  const { data: unit, isFetching } = useBuildingUnitQuery({ buildingArcadiaId, housingUnitId })

  return (
    <Modal
      title={unit ? t('buildings:ui.HousingUnitDetailTile', { description: unit.descrizione }) : ''}
      size="lg"
      onClose={onClose}
      cancelLabel={t('common:actions.Close')}
    >
      {withLoader(
        <>
          <HousingUnitGeneralData unit={unit} />
          <HousingUnitCadastralData unit={unit} />
          <HousingUnitCompositions unit={unit} />
        </>,
        isFetching,
      )}
    </Modal>
  )
}

BuildingDetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  buildingArcadiaId: PropTypes.string.isRequired,
  housingUnitId: PropTypes.string.isRequired,
}

export default BuildingDetailModal
