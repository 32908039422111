import FormControlLabel from '@mui/material/FormControlLabel'
import MuiRadio from '@mui/material/Radio'
import { useTheme } from '@mui/material/styles'
import React from 'react'

import { FieldType } from '../Types/Field'

/**
 * A wrpper to make it easy to add functionality, i.e. clearable
 */
const Radio = ({ label, style, ...props }) => {
  const theme = useTheme()
  return (
    <FormControlLabel
      control={<MuiRadio {...props} />}
      label={label}
      style={{
        ...style,
        color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
        letterSpacing: '0.00938em',
      }}
    />
  )
}

Radio.propTypes = FieldType

export default Radio
