import MuiDatePicker from '@mui/lab/DatePicker'
import PropTypes from 'prop-types'
import React from 'react'

import { FieldType } from '../Types/Field'
import TextField from './TextField'

const DatePicker = ({ value, onChange, maxDate, minDate, views, inputFormat, ...other }) => {
  return (
    <MuiDatePicker
      value={value}
      views={views}
      inputFormat={inputFormat}
      maxDate={maxDate}
      minDate={minDate}
      onChange={(newValue) => {
        onChange(newValue)
      }}
      renderInput={(params) => <TextField {...params} {...other} />}
    />
  )
}

DatePicker.defaultProps = {
  inputFormat: 'DD/MM/YYYY',
}

DatePicker.propTypes = {
  ...FieldType,
  inputFormat: PropTypes.string,
}

export default DatePicker
