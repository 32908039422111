import { useAnagraphicsQuery } from '@Auth/Services/Api'
import ServerAutoCompleteField from '@Common/Components/ServerAutocompleteField'
import { FieldType } from '@Common/Types/Field'
import React from 'react'

/**
 * Usage:
 *
 *  <ResidentAutocomplete
 *    label={t('Residents:fields.Resident.__field')}
 *    onChange={setValue} />
 */
const ResidentAutocomplete = ({ ...props }) => {
  const objFunc = function(search) {
    return {
      "riferimentoEsterno__isnull": false,
      "ragioneSociale__icontains__or": search,
      "nominativo__icontains__or": search
    };
  };

  return (
    <ServerAutoCompleteField
      queryFn={useAnagraphicsQuery}
      queryOrdering='id'
      querySearchObjCb={objFunc}
      queryOptionsObjCb={(b) => ({ label: b.nominativo, value: b.extra?.id })}
      {...props}
    />
  )
}

ResidentAutocomplete.propTypes = {
  ...FieldType,
}

export default ResidentAutocomplete
