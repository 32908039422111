import React from 'react'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'
import { withLoader } from '@Common/Utils/HOC'

import { useCondosQuery } from '../Services/Api'

const CondosSelectField = ({ value, qs, ...props }) => {
  // default order is by description but we can override this by props
  const queryString = { ...qs, base: { ordering: qs?.base?.ordering || 'description', ...qs?.base } }

  const { data, isFetching } = useCondosQuery(queryString)

  const options = data?.results?.map((item) => {
    return {
      value: item.id,
      label: item.description,
      // build condo description (fiscal code and branch name)
      description: [item?.fiscalCode, item?.branchObj?.name].filter((i) => i).join(' - '),
    }
  })

  return withLoader(
    <Dropdown options={options} searchable value={data?.results.length > 0 ? value : ''} {...props} />,
    isFetching,
    null,
    { minHeight: 0 },
  )
}

CondosSelectField.propTypes = { ...FieldType }

export default CondosSelectField
