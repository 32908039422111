import i18next from 'i18next'
import PropTypes from 'prop-types'

import { ListRunningTaskType } from '@Tms/Models/RunningTask'
import { TaskCategoryParentType } from '@Tms/Models/TaskCategory'

export const ReportType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  userObj: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
  }),
  building: PropTypes.shape({
    codiceUnivoco: PropTypes.number,
    denominazione: PropTypes.string,
    gruppoEsterno: PropTypes.string,
    id: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  reportTypeObj: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  status: PropTypes.number.isRequired,
  anagraphic: PropTypes.shape({
    cognome: PropTypes.string,
    nome: PropTypes.string,
  }),
  taskFlow: PropTypes.shape({
    taskCategory: TaskCategoryParentType,
    mainTask: ListRunningTaskType,
  }),
})

export default {
  primaryKey: 'code',
  columns: [
    {
      id: 'code',
      label: i18next.t('tickets:fields.code'),
      width: '40px',
    },
    {
      id: 'created',
      label: i18next.t('tickets:fields.dateTime'),
      datetime: true,
    },
    {
      id: 'userObj',
      label: i18next.t('tickets:fields.sender'),
    },
    {
      id: 'building',
      label: i18next.t('tickets:fields.building'),
    },
    {
      id: 'title',
      label: i18next.t('tickets:fields.title'),
    },
    {
      id: 'reportTypeObj.name',
      label: i18next.t('tickets:fields.type'),
    },
    {
      id: 'status',
      label: i18next.t('tickets:fields.status.__field'),
    },
    {
      id: 'lastUserCC',
      label: i18next.t('tickets:fields.lastUserCC'),
    },
  ],
}
