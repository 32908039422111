import { CardContent, CardHeader, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import FinancialStatementsList from '@Buildings/Components/FinancialStatementsList'
import RepresentativesList from '@Buildings/Components/RepresentativesList'
import { BuildingType } from '@Buildings/Models/Building'
import Card from '@Common/Components/Card'
import { Margin } from '@Common/Components/Styles'

const BuildingInfoSection = ({ building }) => {
  const { t } = useTranslation()

  return (
    <Margin bottom="2rem">
      <Card variant="outlined">
        <CardHeader title={t('buildings:ui.OtherInformations')} />
        <CardContent>
          <CardHeader variant="h6">{t('buildings:ui.AccessInstructions')}</CardHeader>
          {building?.istruzioniAccesso || '-'}
        </CardContent>
      </Card>
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} xl={6}>
          <Card variant="outlined">
            <CardHeader title={t('buildings:ui.Representative')} />
            <CardContent>
              <RepresentativesList buildingArcadiaId={building.id} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Card variant="outlined">
            <CardHeader title={t('buildings:ui.OpenFinancialStatements')} />
            <CardContent>
              <FinancialStatementsList buildingArcadiaId={building.id} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Margin>
  )
}

BuildingInfoSection.propTypes = {
  building: BuildingType.isRequired,
}

export default BuildingInfoSection
