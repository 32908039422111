import i18next from 'i18next'
import PropTypes from 'prop-types'

export const DocumentType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  endDate: PropTypes.string,
  filesize: PropTypes.string,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'name',
      label: i18next.t('documents:fields.description'),
    },
    {
      id: 'year',
      label: i18next.t('documents:fields.year'),
    },
    {
      id: 'filesize',
      label: i18next.t('documents:fields.filesize'),
    },
    {
      id: 'id',
      label: i18next.t('common:actions.Download'),
    },
  ],
}
