import React from 'react'
import styled from 'styled-components'
import { useBreadcrumbs } from '../../../Common/Utils/Hooks'
import NotificationsList from '../../Components/NotificationsList'
import NotificationsWebSocket from '@Notifications/Components/NotificationsWebSocket'
import { useTranslation } from 'react-i18next'
import config from '../../../Config'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminNotificationsView = () => {
  const { t } = useTranslation()

  useBreadcrumbs([{ label: t('common:navigation.Notifications'), path: config.urls.admin.notifications, Icon: 'Notifications' }])

  return (
    <Main>
      <NotificationsList />
      <NotificationsWebSocket />
    </Main>
  )
}

export default AdminNotificationsView
