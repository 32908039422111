import i18next from 'i18next'
import PropTypes from 'prop-types'
import { UserType } from '@Auth/Models/User'

export const ResidentType = PropTypes.shape({
  id: PropTypes.number,
  phone: PropTypes.string,
  user: UserType,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('residents:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'user.username',
      label: i18next.t('residents:fields.email'),
    },
    {
      id: 'phone',
      label: i18next.t('residents:fields.phone'),
    },
    {
      id: 'user.isActive',
      label: i18next.t('residents:fields.active'),
      boolean: true,
    },
    {
      id: 'raw.id',
      label: i18next.t('auth:fields.anagraphicId'),
      ordering: false,
    },
    {
      id: 'raw.classe.displayValue',
      label: i18next.t('auth:fields.anagraphic.class'),
      ordering: false,
    },
    {
      id: 'raw.tipo.displayValue',
      label: i18next.t('auth:fields.anagraphic.type'),
      ordering: false,
    },
    {
      id: 'raw.titolo',
      label: i18next.t('auth:fields.anagraphic.title'),
      ordering: false,
    },
    {
      id: 'raw.nominativo',
      label: i18next.t('auth:fields.anagraphic.name'),
      ordering: false,
    },
    {
      id: 'raw.cognome',
      label: i18next.t('auth:fields.lastName'),
      ordering: false,
    },
    {
      id: 'raw.nome',
      label: i18next.t('auth:fields.firstName'),
      ordering: false,
    },
    {
      id: 'raw.ragioneSociale',
      label: i18next.t('auth:fields.anagraphic.companyName'),
      ordering: false,
    },
    {
      id: 'raw.partitaIva',
      label: i18next.t('auth:fields.anagraphic.vat'),
      ordering: false,
    },
    {
      id: 'raw.codiceFiscale',
      label: i18next.t('auth:fields.fiscalCode'),
      ordering: false,
    },
    {
      id: 'immobili',
      label: i18next.t('residents:fields.buildings'),
      ordering: false,
    },
  ],
}
