import { Grid, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import AccountingIcon from '@Assets/MyEstiaDocuments/AccountingIcon'
import ContractsIcon from '@Assets/MyEstiaDocuments/ContractsIcon'
import GeneralIcon from '@Assets/MyEstiaDocuments/GeneralIcon'
import InvoicesIcon from '@Assets/MyEstiaDocuments/InvoicesIcon'
import MeetingsIcon from '@Assets/MyEstiaDocuments/MeetingsIcon'
import PersonalIcon from '@Assets/MyEstiaDocuments/PersonalIcon'
import SecurityIcon from '@Assets/MyEstiaDocuments/SecurityIcon'
import WorksIcon from '@Assets/MyEstiaDocuments/WorksIcon'
import { Margin } from '@Common/Components/Styles'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import {
  useLazyAccidentsDocumentsQuery,
  useLazyAsbestosDocumentsQuery,
  useLazyContractsDocumentsQuery,
  useLazyFacilitiesDocumentsQuery,
  useLazyFinancialStatementsDocumentsQuery,
  useLazyGenericsDocumentsQuery,
  useLazyInvoicesDocumentsQuery,
  useLazyLeasesDocumentsQuery,
  useLazyLegalPracticesDocumentsQuery,
  useLazyMeetingsDocumentsQuery,
  useLazyPersonalDocumentsQuery,
  useLazySecurityDocumentsQuery,
  useLazyFinancialDocumentsQuery,
  useLazyWorksDocumentsQuery,
} from '@Documents/Services/Api'
import { selectActiveBuildingId } from '@MyEstia/Redux'
import DocumentsList from '@MyHome/Components/DocumentsList'
import MyHomeRoundButton from '@MyHome/Components/MyHomeRoundButton'

const Main = styled.div`
  padding: ${({ mobile }) => (mobile ? '.5rem' : '2rem')};
  padding-bottom: 150px;
`

const SubmenuItem = styled.div`
  width: 100%;
  padding: 6px 16px;
`

const FETCH_MAP = {
  personal: useLazyPersonalDocumentsQuery,
  meetings: useLazyMeetingsDocumentsQuery,
  extraordinaryworks: useLazyWorksDocumentsQuery,
  contracts: useLazyContractsDocumentsQuery,
  accidents: useLazyAccidentsDocumentsQuery,
  facilities: useLazyFacilitiesDocumentsQuery,
  generics: useLazyGenericsDocumentsQuery,
  leases: useLazyLeasesDocumentsQuery,
  legalpractices: useLazyLegalPracticesDocumentsQuery,
  financialstatements: useLazyFinancialStatementsDocumentsQuery,
  tax: useLazyFinancialDocumentsQuery,
  security: useLazySecurityDocumentsQuery,
  asbestos: useLazyAsbestosDocumentsQuery,
  invoices: useLazyInvoicesDocumentsQuery,
}

const DOWNLOAD_PATH_MAP = {
  personal: 'personal',
  meetings: 'meetings',
  extraordinaryworks: 'extraordinaryworks',
  contracts: 'contracts',
  accidents: 'general/accidents',
  facilities: 'general/facilities',
  generics: 'general/generics',
  leases: 'general/leases',
  legalpractices: 'general/legalpractices',
  financialstatements: 'accounting/financialstatements',
  tax: 'accounting/financial',
  security: 'security',
  asbestos: 'security/asbestos',
  invoices: 'invoices',
}

const MyHomeDocumentsView = () => {
  const buildingId = useSelector(selectActiveBuildingId)
  const { housingUnitId } = useParams()
  const { type } = useParams()

  const { t } = useTranslation()
  const user = useCurrentUser()
  const [selected, setSelected] = React.useState('personal')
  const navigate = useNavigate()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const handleNavigation = (path, args) => () => navigate(makePath(path, args))

  const handleSelectCategory = React.useCallback((category) => () => setSelected(category), [setSelected])

  useEffect(() => {
    if (type) {
      setSelected(type)
    }
  }, [type])

  return (
    <Main mobile={isSmallDevice}>
      <Margin bottom="2rem" top={isSmallDevice ? '2rem' : 0}>
        <Grid container rowSpacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.Personal')}
                  icon={<PersonalIcon height="5rem" width="5rem" />}
                  selected={selected === 'personal'}
                  onSelect={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'personal' })}
                />
              </Grid>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.Meetings')}
                  icon={<MeetingsIcon height="5rem" width="5rem" />}
                  selected={selected === 'meetings'}
                  onSelect={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'meetings' })}
                />
              </Grid>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.General')}
                  icon={<GeneralIcon height="5rem" width="5rem" />}
                  selected={selected.startsWith('general')}
                  onSelect={handleSelectCategory}
                  hasSubmenu
                  submenuItems={[
                    <SubmenuItem
                      key="accidents"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'accidents' })}
                    >
                      {t('myHome:ui.general.Accidents')}
                    </SubmenuItem>,
                    <SubmenuItem
                      key="facilities"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'facilities' })}
                    >
                      {t('myHome:ui.general.Facilities')}
                    </SubmenuItem>,
                    <SubmenuItem
                      key="generics"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'generics' })}
                    >
                      {t('myHome:ui.general.Generics')}
                    </SubmenuItem>,
                    <SubmenuItem
                      key="leases"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'leases' })}
                    >
                      {t('myHome:ui.general.Leases')}
                    </SubmenuItem>,
                    <SubmenuItem
                      key="legalpractices"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'legalpractices' })}
                    >
                      {t('myHome:ui.general.LegalPractices')}
                    </SubmenuItem>,
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.Accounting')}
                  icon={<AccountingIcon height="5rem" width="5rem" />}
                  selected={selected.startsWith('accounting')}
                  hasSubmenu
                  submenuItems={[
                    <SubmenuItem
                      key="financialstatements"
                      onClick={handleNavigation('myEstia.myHome.documents', {
                        housingUnitId,
                        type: 'financialstatements',
                      })}
                    >
                      {t('myHome:ui.accounting.FinancialStatements')}
                    </SubmenuItem>,
                    <SubmenuItem
                      key="tax"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'tax' })}
                    >
                      {t('myHome:ui.accounting.Tax')}
                    </SubmenuItem>,
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.Security')}
                  icon={<SecurityIcon height="5rem" width="5rem" />}
                  selected={selected.startsWith('security')}
                  hasSubmenu
                  submenuItems={[
                    <SubmenuItem
                      key="security"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'security' })}
                    >
                      {t('myHome:ui.security.Generics')}
                    </SubmenuItem>,
                    <SubmenuItem
                      key="asbestos"
                      onClick={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'asbestos' })}
                    >
                      {t('myHome:ui.security.Asbestos')}
                    </SubmenuItem>,
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.ExtraordinaryWorks')}
                  icon={<WorksIcon height="5rem" width="5rem" />}
                  selected={selected === 'extraordinaryworks'}
                  onSelect={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'extraordinaryworks' })}
                />
              </Grid>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.Contracts')}
                  icon={<ContractsIcon height="5rem" width="5rem" />}
                  selected={selected === 'contracts'}
                  onSelect={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'contracts' })}
                />
              </Grid>
              <Grid item xs={3}>
                <MyHomeRoundButton
                  label={t('myHome:ui.Invoices')}
                  icon={<InvoicesIcon height="5rem" width="5rem" />}
                  selected={selected === 'invoices'}
                  onSelect={handleNavigation('myEstia.myHome.documents', { housingUnitId, type: 'invoices' })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Margin>
      <DocumentsList
        type={DOWNLOAD_PATH_MAP[selected]}
        lazyFetchFn={FETCH_MAP[selected]}
        fetchId={selected === 'personal' ? user?.resident?.raw?.id : buildingId}
      />
    </Main>
  )
}

export default MyHomeDocumentsView
