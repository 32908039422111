/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AddButton } from '@Common/Components/Buttons/AddButton'
import { DataTableV3 } from '@Common/Components/DataTableV3'
import { SearchField } from '@Common/Components/FormsV2/SearchField'
import { FieldValue } from '@Common/Components/ItemField'
import { CondoSupplierCompanyActions } from '@Supplier/Components/CondoSupplierCompanyActions'
import { SupplierServiceField } from '@Supplier/Components/SupplierServiceField'
import { CondoSupplierCompany, CondoSupplierCompanyByService } from '@Supplier/Models/CondoSupplierCompany'

export const CondoSupplierCompanyByServiceListView = () => {
  const { buildingId } = useParams()
  const { t } = useTranslation()

  const FIELDS_MAPPING = {
    supplier: (record) => {
      return <FieldValue value={record?.supplier.name} />
    },
    blacklist: (record) => {
      return <FieldValue type="boolean" value={record?.supplier.blacklist} />
    },
  }

  return (
    <>
      <DataTableV3
        url={`/condo/buildings/${buildingId}/suppliers_by_service`}
        model={CondoSupplierCompanyByService}
        renderFilters={
          <>
            <SupplierServiceField />
            <SearchField xs={4} />
          </>
        }
        renderActions={<CondoSupplierCompanyActions />}
        defaultOrdering="created"
        columns={['activity', 'name', 'supplier', 'blacklist', 'hasContract', 'isPrivileged', 'hasMandateExclusivity']}
        fieldsMapping={FIELDS_MAPPING}
      />
      <AddButton
        model={CondoSupplierCompany}
        title={t(`${CondoSupplierCompany.name.toLowerCase()}:associateNewSupplierToCondo`)}
      />
    </>
  )
}
