import PropTypes from 'prop-types'
import React from 'react'
import { Outlet } from 'react-router-dom'
import styled, { css } from 'styled-components'

import RegistrationNavbar from '@Common/Components/RegistrationNavbar'
import MyEstiaFooter from '@MyEstia/Components/MyEstiaFooter'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
`

const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  margin-top: 100px;
  width: 100%;
`

const Main = styled.main`
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    color: ${theme.palette.background.constrastText};
  `}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

const RegistrationLayout = ({ children }) => {
  return (
    <Wrapper>
      <RegistrationNavbar />
      <Container>
        <Main>
          <Outlet />
          {children}
        </Main>
      </Container>
      <MyEstiaFooter />
    </Wrapper>
  )
}

RegistrationLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default RegistrationLayout
