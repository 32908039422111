import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Loader from '@Common/Components/Loader'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import getStorage from '@Common/Services/Storage'
import { useCurrentUser, useDebounce } from '@Common/Utils/Hooks'
import { camelToSnakeCase } from '@Common/Utils/Strings'
import config from '@Config'

import { Cursor } from '../Styles'
import ExportAction from './ExportAction'
import SettingsDialog from './SettingsDialog'
import { SettingsIcon, Toolbar, Tr } from './Styled'
import { createColumnsProps, getDisplayValue } from './Utils'

const storageService = getStorage()

const MuiDataTable = ({
  name,
  autoWidth,
  refreshData,
  data,
  dataCount,
  loading,
  qsAdditions,
  idField,
  selectable,
  selected,
  onSelect,
  model,
  defaultListPerPage,
  listDisplay,
  fieldsMapping,
  searchFields,
  qs,
  actions,
  bulkActions,
  onAction,
  noSettings,
  noExport,
  onExpandRow,
  disableSorting,
  disableCheckbox,
  useDjangoOrdering,
  children,
  customEmptyText,
  searchFieldsStyles,
  toolbarContent,
  actionsV2,
}) => {
  const { t } = useTranslation()

  const storageKey = `${name}_DATA_TABLE_SETTINGS`
  // check storage for invalidation
  if (config.localStorageInvalidation[storageKey] && storageService.get(storageKey)) {
    const storageTimestamp = storageService.getNested(storageKey, 'timestamp', 0)
    if (storageTimestamp < config.localStorageInvalidation[storageKey]) {
      storageService.remove(storageKey)
      toast.warn(t('common:ui.ForceResetDatatableSettingsWarning'))
    }
  }

  // first fetch
  const [firstFetch, setFirstFetch] = React.useState(false)
  // logged user
  const user = useCurrentUser()
  // primary key
  const primaryKeyValue = (item) =>
    idField
      .split(',')
      .map((f) => item[f])
      .join('-')

  // list per page
  const [listPerPage, setListPerPage] = React.useState(
    parseInt(storageService.getNested(storageKey, 'listPerPage', defaultListPerPage)),
  )

  // pagination
  const [page, setPage] = React.useState(0)

  // search
  const [searchFilter, setSearchFilter] = React.useState('')
  const debouncedSearchFilter = useDebounce(searchFilter, 400)
  const handleSearchFilter = (value) => {
    R.pipe(R.prop('target'), R.prop('value'), setSearchFilter)(value)
    setPage(0)
  }

  // sorting
  const getOrderingField = ({ id, ordering }) => {
    if (ordering) return ordering
    if (useDjangoOrdering) {
      const parts = id.split('.')
      return parts.map((f) => camelToSnakeCase(f.replace('Obj', ''))).join('__')
    }
    return id
  }

  const [sort, setSort] = React.useState({
    field: qs.base.ordering.replace('-', ''),
    direction: qs.base.ordering[0] === '-' ? 'desc' : 'asc',
  })

  const handleSortChange = (column) => () => {
    if (column.ordering === false) return
    const field = getOrderingField(column)
    setSort({
      field,
      direction: sort.field === field && sort.direction === 'asc' ? 'desc' : 'asc',
    })
  }

  // reset page when changing filtering
  React.useEffect(() => {
    setPage(0)
  }, [JSON.stringify(qsAdditions)])

  React.useEffect(() => {
    // data are first fetched by the component using datatable
    if (firstFetch) {
      refreshData({
        base: {
          limit: listPerPage,
          offset: page * listPerPage,
          ordering: (sort.direction === 'desc' ? '-' : '') + sort.field,
        },
        qsAdditions: {
          ...qsAdditions,
          search: debouncedSearchFilter,
        },
      })
    } else {
      setFirstFetch(true)
    }
    if (onExpandRow) {
      setOpenRow(null)
    }
  }, [JSON.stringify(qsAdditions), page, sort, listPerPage, debouncedSearchFilter])

  // clean selected when data change
  const previousData = useRef()
  React.useEffect(() => {
    if (selected.length && previousData.current !== undefined && previousData.current.length > 0) {
      onSelect([])
    }
    previousData.current = data
  }, [data])

  // collapsable
  const [openRow, setOpenRow] = React.useState(null)

  // columns
  const [columnsProps, setColumnProps] = React.useState([])
  React.useEffect(() => {
    setColumnProps(
      R.ifElse(
        R.isNil,
        () => createColumnsProps(model.columns, listDisplay),
        R.identity,
      )(storageService.getNested(storageKey, 'columns')),
    )
  }, [storageKey, listDisplay])

  // columns selection
  const handleSelectColumn = (id) => (event) => {
    setColumnProps(
      columnsProps.map((column) =>
        R.ifElse(
          R.equals(id),
          R.always({ ...column, visible: R.prop('checked')(event.target) }),
          R.always(column),
        )(column.id),
      ),
    )
  }

  // search tooltip
  const searchPlaceholder = t('common:ui.SearchFields', {
    fields: columnsProps
      .filter(R.compose(R.not, R.equals(-1), R.flip(R.indexOf)(searchFields), R.prop('id')))
      .map(R.pipe(R.prop('label'), R.toLower))
      .join(', '),
  })

  // settings
  const saveSettings = () => {
    storageService.save(storageKey, {
      timestamp: new Date().getTime(),
      columns: columnsProps,
      listPerPage,
    })
    setSettingsDialogIsOpen(false)
  }

  const resetSettings = () => {
    storageService.remove(storageKey)
    setColumnProps(createColumnsProps(model.columns, listDisplay))
    setListPerPage(defaultListPerPage)
    setSettingsDialogIsOpen(false)
  }

  // columns to be displayed
  const displayColumns = columnsProps.filter((c) => c.visible)

  // settings
  const [settingsDialogIsOpen, setSettingsDialogIsOpen] = React.useState(false)
  const handleOpenSettings = React.useCallback(() => setSettingsDialogIsOpen(true), [setSettingsDialogIsOpen])

  // selection
  const selectedIds = selected?.map((item) => primaryKeyValue(item))
  // page selection
  const handleSelectPage = (event) =>
    onSelect(R.ifElse(R.prop('checked'), R.always(displayData.map((r) => r)), R.always([]))(event.target))
  // record selection
  const handleSelectRecord = (record) => (event) =>
    onSelect(
      R.ifElse(
        R.prop('checked'),
        R.always([...selected, record]),
        R.always(selected.filter(R.compose(R.not, R.equals(primaryKeyValue(record)), primaryKeyValue))),
      )(event.target),
      record,
      event.target.checked,
    )

  // display
  const displayData = data

  // selection
  const enabledActions =
    actions && actions.length ? actions.filter((action) => !action.perm || action.perm(user, selected[0])) : []

  const enabledBulkActions =
    bulkActions && bulkActions.length
      ? bulkActions.filter((action) => !action.perm || action.perm(user, selected[0]))
      : []

  const enableSelection = selectable || !!enabledActions.length || !!enabledBulkActions.length

  // col span calculation
  const colSpan = displayColumns.length + (enableSelection ? 1 : 0) + (onExpandRow ? 1 : 0)

  const handleAction =
    (action, bulk = false) =>
    () => {
      onAction(action.id, bulk ? undefined : selected[0])
    }

  const getTooltip = (action) => {
    if (typeof action.tooltip === 'string') return action.tooltip
    if (typeof action.tooltip === 'function') return action.tooltip(selected[0])
    return ''
  }

  return (
    <>
      {(children || !!searchFields.length) && (
        <Box justify="space-between" direction="row" align="flex-end" margin="0 0 1rem" style={searchFieldsStyles}>
          {!!searchFields.length && (
            <Tooltip title={searchPlaceholder}>
              <div>
                <TextField
                  label={t('common:ui.SearchPlaceholder')}
                  value={searchFilter}
                  onChange={handleSearchFilter}
                  style={{ minWidth: '120px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Tooltip>
          )}
          {children}
        </Box>
      )}
      {(enableSelection || !noSettings || !noExport) && (
        <Toolbar>
          <Box direction="row" align="center" minHeight={'37px'}>
            {enableSelection && (
              <Typography component="div">
                {t('common:ui:numSelected', { count: selected.length, total: dataCount })}
              </Typography>
            )}
            <Box direction="row" margin="0 0 0 1rem">
              {actionsV2}
              {selected.length === 1 &&
                enabledActions
                  .filter((action) => !action.cond || action.cond(selected[0]))
                  .map((action) => {
                    /**
                     * we need to wrap Button in a span element
                     * otherwise when the button is disabled,
                     *  the tooltip doesn't appear
                     *  @see: https://mui.com/material-ui/react-tooltip/#disabled-elements
                     */
                    return (
                      <Tooltip key={action.id} title={getTooltip(action)}>
                        <span>
                          <Button
                            size="small"
                            disableElevation
                            variant="contained"
                            onClick={handleAction(action)}
                            sx={{ marginLeft: '.5rem' }}
                            startIcon={action.icon}
                            disabled={action?.disabled && action?.disabled(selected[0])}
                          >
                            {action.label}
                          </Button>
                        </span>
                      </Tooltip>
                    )
                  })}
              {selected.length > 1 &&
                enabledBulkActions
                  .filter((action) => !action.cond || action.cond(selected[0]))
                  .map((action) => {
                    /**
                     * we need to wrap Button in a span element
                     * otherwise when the button is disabled,
                     *  the tooltip doesn't appear
                     *  @see: https://mui.com/material-ui/react-tooltip/#disabled-elements
                     */
                    return (
                      <Tooltip key={action.id} title={getTooltip(action)}>
                        <span>
                          <Button
                            size="small"
                            disableElevation
                            variant="contained"
                            onClick={handleAction(action)}
                            sx={{ marginLeft: '.5rem' }}
                            startIcon={action.icon}
                            disabled={action?.disabled && action?.disabled(selected[0])}
                          >
                            {action.label}
                          </Button>
                        </span>
                      </Tooltip>
                    )
                  })}
            </Box>
          </Box>
          <div
            style={{
              display: 'flex',
              verticalAlign: 'middle',
            }}
          >
            {toolbarContent}

            {!noExport && (
              <ExportAction
                name={name}
                displayColumns={displayColumns}
                sortedData={displayData}
                fieldsMapping={fieldsMapping}
                marginRight={noSettings ? 0 : '.5rem'}
              />
            )}

            {!noSettings && (
              <div style={{ margin: 'auto', marginLeft: 4 }}>
                <Cursor>
                  <Tooltip title={t('common:ui.Settings')}>
                    <SettingsIcon onClick={handleOpenSettings} />
                  </Tooltip>
                </Cursor>
              </div>
            )}
          </div>
        </Toolbar>
      )}
      <TableContainer style={{ maxWidth: '100%', overflow: 'auto' }}>
        <Table style={autoWidth ? { width: 'auto' } : {}} size="small">
          <TableHead key="thead">
            <TableRow key="thead-row">
              {enableSelection && (
                <TableCell padding="checkbox" style={{ width: '20px' }} key="col-selection">
                  {(selectable || !!bulkActions.length) && typeof selectable !== 'function' && (
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < displayData.count}
                      checked={
                        displayData.length !== 0 &&
                        displayData.filter((item) => selectedIds.indexOf(primaryKeyValue(item)) === -1).length === 0
                      }
                      onChange={handleSelectPage}
                    />
                  )}
                </TableCell>
              )}
              {displayColumns.map((column) => {
                let minWidth = 120

                if (column.longtext) minWidth = 240
                if (column.width) minWidth = column.width

                return (
                  <TableCell
                    key={`col-${column.id}`}
                    align={column.numeric || column.currency ? 'right' : column.centered ? 'center' : 'left'}
                    sortDirection={sort.field === column.id ? sort.direction : false}
                    style={{ width: column.width ? column.width : 'auto', minWidth }}
                  >
                    {disableSorting.indexOf(column.id) !== -1 ? (
                      column.label
                    ) : (
                      <TableSortLabel
                        disabled={column.ordering === false}
                        active={sort.field === getOrderingField(column)}
                        direction={sort.field === getOrderingField(column) ? sort.direction : 'asc'}
                        onClick={handleSortChange(column)}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              })}
              {onExpandRow && <TableCell key="col-collapse" style={{ width: '30px' }} />}
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody key="tbody">
              {displayData.map((record, index) => {
                return (
                  <React.Fragment key={`row-${index}`}>
                    <Tr data-no-border={!!onExpandRow} hover $even={index % 2 === 0}>
                      {(enableSelection || selectable) && (
                        <TableCell padding="checkbox">
                          {((enableSelection && (typeof selectable !== 'function' || selectable(record))) ||
                            (selectable && (selectable === true || selectable(record)))) && (
                            <Checkbox
                              checked={
                                selected.filter(R.compose(R.equals(primaryKeyValue(record)), primaryKeyValue))
                                  .length === 1
                              }
                              onChange={handleSelectRecord(record)}
                              disabled={disableCheckbox(record)}
                            />
                          )}
                        </TableCell>
                      )}
                      {displayColumns.map((column) => {
                        let tooltip = ''
                        if (column.longtext && record[column.id]?.length > 160) {
                          tooltip = record[column.id]
                        }

                        return (
                          <TableCell
                            key={`col-${column.id}`}
                            align={column.numeric || column.currency ? 'right' : column.centered ? 'center' : 'left'}
                          >
                            <Tooltip title={tooltip} placement="top">
                              <span>{getDisplayValue(fieldsMapping, record, column.id, column)}</span>
                            </Tooltip>
                          </TableCell>
                        )
                      })}
                      {onExpandRow && (
                        <TableCell align="right" key={`col-expand-${index}`}>
                          <IconButton size="small" onClick={() => setOpenRow(openRow === index ? null : index)}>
                            {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                      )}
                    </Tr>
                    {onExpandRow && (
                      <TableRow key={`expand-${index}`}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                          <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                            {onExpandRow(record)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
              {displayData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={colSpan} align="center">
                    <Typography color="dark-4">{customEmptyText || t('common:ui.NoResults')}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading && <Loader minHeight="100px" />}
      <TablePagination
        rowsPerPageOptions={[listPerPage]}
        component="div"
        count={dataCount}
        rowsPerPage={listPerPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
      />
      {settingsDialogIsOpen && (
        <SettingsDialog
          columns={columnsProps}
          open={settingsDialogIsOpen}
          handleClose={() => setSettingsDialogIsOpen(false)}
          handleReset={resetSettings}
          handleSaveAndClose={saveSettings}
          onSelect={handleSelectColumn}
          onSort={setColumnProps}
          listPerPage={listPerPage}
          onListPerPageChange={setListPerPage}
        />
      )}
    </>
  )
}

MuiDataTable.defaultProps = {
  idField: 'id',
  qsAdditions: {},
  autoWidth: false,
  fieldsMapping: {},
  actions: [],
  bulkActions: [],
  searchFields: [],
  searchFieldsStyles: {},
  selected: [],
  defaultListPerPage: 10,
  disableSorting: [],
  disableCheckbox: R.always(false),
  useDjangoOrdering: false,
  customEmptyText: null,
}

MuiDataTable.propTypes = {
  name: PropTypes.string.isRequired,
  refreshData: PropTypes.func,
  autoWidth: PropTypes.bool,
  idField: PropTypes.string,
  data: PropTypes.array,
  dataCount: PropTypes.number,
  loading: PropTypes.bool,
  qsAdditions: PropTypes.object,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  model: PropTypes.object.isRequired,
  listDisplay: PropTypes.array.isRequired,
  searchFields: PropTypes.array,
  searchFieldsStyles: PropTypes.any,
  defaultListPerPage: PropTypes.number,
  qs: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  onAction: PropTypes.func,
  noExport: PropTypes.bool,
  noSettings: PropTypes.bool,
  fieldsMapping: PropTypes.object,
  onExpandRow: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disableSorting: PropTypes.arrayOf(PropTypes.string),
  disableCheckbox: PropTypes.func,
  useDjangoOrdering: PropTypes.bool,
  customEmptyText: PropTypes.string,
  toolbarContent: PropTypes.oneOfType([PropTypes.children, PropTypes.node]),
  actionsV2: PropTypes.children,
}

export default React.memo(MuiDataTable)

export const getDefaultListPerPage = (name, defaultListPerPage = 10) =>
  parseInt(storageService.getNested(`${name}_DATA_TABLE_SETTINGS`, 'listPerPage', defaultListPerPage))
