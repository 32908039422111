import i18next from 'i18next'
import PropTypes from 'prop-types'

import { getGenericConfigColumnsCondo } from '@Condo/Utils'

export const PodPdrType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  building: PropTypes.number.isRequired,
  oldBuilding: PropTypes.number.isRequired,
  stairN: PropTypes.string.isRequired,
  podNumber: PropTypes.string.isRequired,
  podSupplyType: PropTypes.string.isRequired,
  podSupplier: PropTypes.string.isRequired,
  pdrNumber: PropTypes.string,
  pdrSupplier: PropTypes.string,
  notes: PropTypes.string,
})

export const PodSupplyTypes = [
  { id: 'COMM', label: i18next.t('condo:podpdr.podSupplyType.COMM') },
  { id: 'LIFT', label: i18next.t('condo:podpdr.podSupplyType.LIFT') },
  { id: 'HEAT', label: i18next.t('condo:podpdr.podSupplyType.HEAT') },
  { id: 'OTHR', label: i18next.t('condo:podpdr.podSupplyType.OTHR') },
]

export const PodPdr = {
  primaryKey: 'id',
  columns: [
    ...getGenericConfigColumnsCondo(),
    {
      id: 'stairN',
      label: i18next.t('condo:podpdr.fields.stairN'),
      width: 'auto',
    },
    {
      id: 'podNumber',
      label: i18next.t('condo:podpdr.fields.podNumber'),
    },
    {
      id: 'podSupplyType',
      label: i18next.t('condo:podpdr.fields.podSupplyType'),
    },
    {
      id: 'podSupplier',
      label: i18next.t('condo:podpdr.fields.podSupplier'),
    },
    {
      id: 'pdrNumber',
      label: i18next.t('condo:podpdr.fields.pdrNumber'),
    },
    {
      id: 'pdrSupplier',
      label: i18next.t('condo:podpdr.fields.pdrSupplier'),
    },
    {
      id: 'notes',
      label: i18next.t('condo:podpdr.fields.notes'),
      ordering: false,
      longtext: true,
    },
  ],
}
