import React from 'react'
import { useBreadcrumbs } from '../../../Common/Utils/Hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCcAnagraphicQuery } from '../../Services/Api'
import { getDisplayName } from '@Residents/Utils'
import { makePath } from '@Config'
import ResidentContacts from '@Residents/Components/ResidentContacts'
import ResidentBuilding from '@CustomerCare/Components/ResidentBuilding'
import ResidentNotes from '../../Components/ResidentNotes'
import ResidentInstallments from '../../../Residents/Components/ResidentInstallments'
import { TabPanel } from '@Common/Components/TabPanel'
import styled from 'styled-components'
import { withLoader } from '../../../Common/Utils/HOC'
import { Box, Margin } from '../../../Common/Components/Styles'
import { Grid } from '@mui/material'
import { Cake } from '@mui/icons-material'
import { Tabs, Tab, Box as MuiBox } from '@mui/material'
import moment from 'moment'
import ResidentDocuments from '@Residents/Components/ResidentDocuments'

const Main = styled.div`
  flex-grow: 1;
  padding: 2rem;
`

const Aside = styled.div`
  background: ${({ theme }) => theme.palette.lightOne.main};
  height: 100%;
  position: relative;
`

const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  margin-top: 0;
`

const AnagraphicView = () => {
  const { t } = useTranslation()
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const { residentId } = useParams()
  const { data: resident, isFetching } = useCcAnagraphicQuery(residentId)

  useBreadcrumbs(
    [
      { label: t('common:navigation.CustomerCare'), path: makePath('customerCare.main'), Icon: 'SupportAgent' },
      { label: t('customerCare:ui.Anagraphic') },
      { label: getDisplayName(resident) },
    ],
    [resident],
  )

  return withLoader(
    () => (
      <Grid container>
        <Grid item xs={12} md={9}>
          <Main>
            <Box direction='row' justify='space-between'>
              <Title>{getDisplayName(resident)}</Title>
              <Box direction='row' align='center'>
                <Margin right='.5rem'><Cake color="primary" /></Margin>
                {resident.dataNascita ? moment(resident.dataNascita).format('LL') : t('common:ui.na')}
              </Box>
            </Box>
            <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
              <Tabs value={activeTabIndex} onChange={(_, idx) => setActiveTabIndex(idx)}>
                <Tab label={t('common:ui.Main')} key={0} />
                <Tab label={t('common:ui.Installments')} key={1} />
                <Tab label={t('common:ui.PersonalDocuments')} key={2} />
              </Tabs>
            </MuiBox>
            <TabPanel activeIndex={activeTabIndex} index={0}>
              <Margin bottom="2rem">
                <ResidentContacts contacts={resident.contatti} />
              </Margin>
              <Box direction='row'>
                {resident.composizioni.map((composition, idx) => (
                  <Margin bottom='1rem' right='1rem' key={idx}>
                    <ResidentBuilding {...composition} />
                  </Margin>
                ))}
              </Box>
            </TabPanel>
            <TabPanel activeIndex={activeTabIndex} index={1}>
                <ResidentInstallments resident={resident} />
            </TabPanel>
            <TabPanel activeIndex={activeTabIndex} index={2}>
                <ResidentDocuments resident={resident} />
            </TabPanel>
          </Main>
        </Grid>
        <Grid item xs={12} md={3}>
          <Aside>
            <ResidentNotes resident={resident} />
          </Aside>
        </Grid>
      </Grid>
    ),
    isFetching,
  )
}

export default AnagraphicView
