import * as R from 'ramda'
import React, { useEffect, useState } from 'react'

import { FieldType } from '@Common/Types/Field'

import { useRegionsQuery } from '../../Services/Api/LocationApi'
import Dropdown from '../Dropdown'

const RegionSelect = ({ onChange, value, ...other }) => {
  const { data } = useRegionsQuery()
  const [regions, setRegions] = useState([])

  useEffect(() => {
    if (data) setRegions(data.results.map(R.pick(['id', 'name'])))
  }, [data])

  return (
    <Dropdown
      options={regions}
      labelKey="name"
      valueKey="id"
      value={regions.length > 0 ? value : ''}
      onChange={onChange}
      searchable
      {...other}
    />
  )
}

export default RegionSelect

RegionSelect.propTypes = {
  ...FieldType,
}
