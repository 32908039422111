import * as R from 'ramda'

export const hasBuildingUnpaidInstallments = R.pipe(
  R.filter(
    R.compose(
      R.not,
      R.equals(0),
      R.prop('residuo'),
    )
  ),
  R.length,
  R.flip(R.gt)(1),
)
