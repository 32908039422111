import * as R from 'ramda'

import { checkEmailAddress, checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = [
    'name',
    'code',
    'phone',
    'email',
    'active',
    'manager',
    'city',
    'province',
    'region',
    'address',
    'houseNumber',
    'cap',
    'lat',
    'lng',
  ]

  checkRequired(err, required, fields)
  checkEmailAddress(err, fields, 'email')

  setErrors(err)
  return R.isEmpty(err)
}

export const getErroredFieldTab = (errors) => {
  const fieldToTabIndexMap = [
    {
      tab: 0,
      field: 'name',
    },
    {
      tab: 0,
      field: 'code',
    },
    {
      tab: 0,
      field: 'phone',
    },
    {
      tab: 0,
      field: 'email',
    },
    {
      tab: 0,
      field: 'activationDate',
    },
    {
      tab: 0,
      field: 'manager',
    },
    {
      tab: 1,
      field: 'region',
    },
    {
      tab: 1,
      field: 'province',
    },
    {
      tab: 1,
      field: 'city',
    },
    {
      tab: 1,
      field: 'cap',
    },
    {
      tab: 1,
      field: 'address',
    },
    {
      tab: 1,
      field: 'houseNumber',
    },
    {
      tab: 1,
      field: 'locality',
    },
    {
      tab: 1,
      field: 'internalNumber',
    },
    {
      tab: 1,
      field: 'lat',
    },
    {
      tab: 1,
      field: 'lng',
    },
    {
      tab: 2,
      field: 'seniorAdministratives',
    },
    {
      tab: 3,
      field: 'juniorAdministratives',
    },
  ]

  const { tab } = R.head(R.sort(
    (a, b) => a.tab - b.tab,
    R.filter(f => R.includes(f.field, R.keys(errors)), fieldToTabIndexMap),
  )) ?? { tab: 0 }

  return tab
}
