import { ChevronRight, Edit, Block, LockOpen } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { FormControl, Stack, useMediaQuery, Link as MuiLink } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { getEmployeeDisplayName } from '@Auth/Utils'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import DatePicker from '@Common/Components/DatePicker'
import Dropdown from '@Common/Components/Dropdown'
import { ExportExcelButton } from '@Common/Components/ExportExcelButton'
import { ImportExcelButton } from '@Common/Components/ImportExcelButton'
import { GeneralFilterContainer } from '@Common/Components/Styles'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useConfirm, useCurrentUser } from '@Common/Utils/Hooks'
import { isAdminRoute } from '@Common/Utils/Url'
import {
  InsuranceIndexType,
  InsurancePolicy,
  InsurancePremiumPeriodicity,
  InsuranceStatusChiusa,
  InsuranceStatusType,
} from '@Condo/Models/InsurancePolicy'
import { InsurancePolicyPermissions } from '@Condo/Permissions'
import { useCondoInsurancePolicyQuery } from '@Condo/Services/ApiInsurancePolicy'
import {
  formatDate,
  genericConvertSearchQuery,
  getDetailPathEntity,
  getDisplayColumnsCondo,
  getFieldsMappingCondo,
  getSearchFieldsColumnsCondo,
} from '@Condo/Utils'
import { makePath } from '@Config'

import { selectActiveBranch } from '../../Branches/Redux'
import ButtonDialogWithOutCondoReference from './ButtonDialogWithOutCondoReference'
import ChipInsurancePolicyStatus from './ChipInsurancePolicyStatus'

const InsurancePolicyList = ({
  onEdit,
  onCreate,
  onDelete,
  onCloseInsurance,
  onReopenInsurance,
  buildingId = null,
}) => {
  const user = useCurrentUser()

  const branch = useSelector(selectActiveBranch)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isAdmin = isAdminRoute()
  const showImport = buildingId ? false : isAdmin
  const showExport = !buildingId

  const dtName = showExport ? 'all-insurance-policy' : 'condo-building-insurance-policy'
  const basePath = isAdmin ? 'admin.' : ''

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const FIELDS_MAPPING = {
    // eslint-disable-next-line react/display-name
    number: (record) => {
      return (
        <MuiLink
          component={Link}
          underline="always"
          to={getDetailPathEntity(isAdmin, record.id, 'insurancePolicy', buildingId)}
        >
          {record?.number}
        </MuiLink>
      )
    },
    premiumPeriodicity: (record) => InsurancePremiumPeriodicity.find((x) => x.id === record.premiumPeriodicity)?.label,
    // eslint-disable-next-line react/display-name
    status: (record) => {
      return <ChipInsurancePolicyStatus status={record.status} />
    },
    indexed: (record) => InsuranceIndexType.find((x) => x.id === record.indexed)?.label,
    administratorFullName: R.pipe(R.path(['buildingReferringAdministratorObj']), getEmployeeDisplayName),
    lastUpdateUser: (record) => record?.lastUpdateUserObj?.username || '-',
    ...getFieldsMappingCondo(isAdmin),
  }

  const SEARCH_FIELDS = [
    'status',
    'company',
    'number',
    'premium',
    'premiu_periodicity',
    'effective_date',
    'expiration_date',
    'cancellation_notice_days',
    'final_cancellation_date',
    'last_premium_payment_date',
    'next_premium_payment_date',
    'ceased',
    'indexed',
    'insured_value',
    'notes',
    ...getSearchFieldsColumnsCondo(),
  ]

  const DATE_SEARCH_FIELDS = [
    'effective_date__gte',
    'effective_date__lte',
    'next_premium_payment_date__gte',
    'next_premium_payment_date__lte',
  ]

  const DISPLAY_COLUMNS = React.useMemo(() => {
    const columns = ['status', 'number', 'company', 'effectiveDate', 'nextPremiumPaymentDate', 'premium']

    if (!buildingId) {
      return [...columns, ...getDisplayColumnsCondo(), 'administratorFullName']
    }

    return columns
  })

  const [selected, setSelected] = React.useState([])

  // #region Filters Field State
  const [statusFilter, setStatusFilter] = React.useState(0)
  const [datesFilter, setDatesFilter] = React.useState({})

  const changeDatesFilter = (field, value) => {
    setDatesFilter({
      ...datesFilter,
      [field]: value,
    })
  }

  const renderDateFilter = (
    <>
      {DATE_SEARCH_FIELDS.map((field, index) => {
        return (
          <FormControl key={index} fullWidth>
            <DatePicker
              label={t(`condo:insurancePolicy.filters.${field}`)}
              value={datesFilter[field] || null}
              onChange={(date) => changeDatesFilter(field, date)}
              error={false}
              helperText={null}
            />
          </FormControl>
        )
      })}
    </>
  )
  // #endregion Filters Field State

  const qsAdditions = React.useMemo(() => {
    let obj = {
      status: statusFilter,
      ceased: false,
      show_relevant_first: true,
    }

    if (statusFilter === 0) {
      delete obj.status
    }

    if (datesFilter) {
      DATE_SEARCH_FIELDS.forEach((key) => {
        let data = null

        if (datesFilter[key]) {
          data = datesFilter[key].isValid() ? formatDate(datesFilter[key], 'YYYY-MM-DD') : null

          if (!obj[key]) {
            delete obj[key]
          }
        }

        if (data) {
          obj = { ...obj, [key]: data }
        } else if (obj[key]) {
          delete obj[key]
        }
      })
    }

    if ((!showImport && buildingId) || statusFilter !== 0) {
      delete obj.ceased
    }

    if (branch) {
      obj = { ...obj, building__branch__in: [branch.id] }
    }

    return obj
  }, [branch, statusFilter, datesFilter])

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage(dtName),
      offset: 0,
      ordering: '-',
      show_relevant_first: 1,
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  const { data, isFetching } = useCondoInsurancePolicyQuery({
    condo_id: buildingId ?? null,
    qs: genericConvertSearchQuery(qs, SEARCH_FIELDS),
  })

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        icon: <ChevronRight />,
        label: t('common:actions.Detail'),
        perm: InsurancePolicyPermissions.read,
      },
      {
        id: 'EDIT',
        icon: <Edit />,
        label: t('common:actions.Edit'),
        perm: InsurancePolicyPermissions.update,
        cond: (record) => !record?.ceased,
      },
      {
        id: 'CLOSE_INSURANCE',
        label: t('common:actions.Close'),
        icon: <Block size="small" />,
        perm: InsurancePolicyPermissions.update,
        cond: (record) => !record?.ceased,
      },
      {
        id: 'OPEN_INSURANCE',
        label: t('common:actions.Reopen'),
        icon: <LockOpen size="small" />,
        perm: InsurancePolicyPermissions.update,
        cond: (record) => record?.ceased,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: InsurancePolicyPermissions.delete,
        cond: (record) => record?.status !== InsuranceStatusChiusa.id,
      },
    ],
    [data?.count],
  )

  const handleAction = React.useCallback(
    (actionId, insurancePolicy) => {
      let detailPath = null

      switch (actionId) {
        case 'EDIT':
          onEdit(insurancePolicy)
          break
        case 'DETAIL':
          if (buildingId) {
            detailPath = makePath(`${basePath}buildings.detail.insurancePolicy.detail.main`, {
              buildingId: buildingId,
              id: insurancePolicy.id,
            })
          } else {
            detailPath = makePath(`${basePath}insurancePolicy.detail.main`, { id: insurancePolicy.id })
          }

          navigate(detailPath)
          break

        case 'DELETE':
          openDeleteConfirm(
            selected,
            t('condo:ui.DeleteInsurancePolicyConfirmationTitle'),
            t('condo:ui.DeleteInsurancePolicyConfirmationText', { count: selected.length }),
            { selected: selected, field: 'number' },
          )
          break

        case 'CLOSE_INSURANCE':
          openCloseInsuranceConfirm(
            selected,
            t('condo:ui.CloseInsurancePolicyConfirmationTitle'),
            t('condo:ui.CloseInsurancePolicyConfirmationText', { count: selected.length }),
            { selected: selected, field: 'number' },
          )
          break

        case 'OPEN_INSURANCE':
          openReopenInsuranceConfirm(
            selected,
            t('condo:ui.ReopenInsurancePolicyConfirmationTitle'),
            t('condo:ui.ReopenInsurancePolicyConfirmationText', { count: selected.length }),
            { selected: selected, field: 'number' },
          )
          break
      }
    },
    [selected],
  )

  const handleDeleteProvider = async () => {
    onDelete(selected)
  }

  const handleCloseInsuranceProvider = async () => {
    onCloseInsurance(selected)
  }

  const handleReopenInsuranceProvider = async () => {
    onReopenInsurance(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteProvider)
  const [openCloseInsuranceConfirm, CloseInsuranceConfirm] = useConfirm(handleCloseInsuranceProvider)
  const [openReopenInsuranceConfirm, ReopenInsuranceConfirm] = useConfirm(handleReopenInsuranceProvider)

  const toolbar = (
    <>
      {InsurancePolicyPermissions.create(user) && showExport && (
        <ButtonDialogWithOutCondoReference
          onCreate={onCreate}
          referenceType={'insurance'}
          title={t('condo:ui.CondoWithoutInsurancePolicy')}
          titleBtnCreate={t('condo:ui.CreateInsurancePolicy')}
        />
      )}
      {showExport && <ExportExcelButton defaultFilename="polizze" exportApi="condo/insurances/excel" qs={qs} />}
      {InsurancePolicyPermissions.create(user) && showImport && (
        <ImportExcelButton importApi="condo/insurances/excel" />
      )}
    </>
  )

  return (
    <div>
      <DataTable
        name={dtName}
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={InsurancePolicy}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        noExport
        useDjangoOrdering
        searchFieldsStyles={{
          flexDirection: largeScreen ? 'row' : 'column',
        }}
        toolbarContent={toolbar}
      >
        <GeneralFilterContainer>
          <Stack justify="flex" direction={largeScreen ? 'row' : 'column'} align="flex-end" display="flex">
            <Dropdown
              style={{ width: !buildingId ? '100%' : '150px' }}
              options={InsuranceStatusType}
              labelKey="label"
              valueKey="id"
              onChange={setStateFromEvent(setStatusFilter)}
              value={statusFilter}
              emptyValue={0}
              emptyLabel={t('condo:insurancePolicy.status.active')}
              label={t('common:ui.State')}
            />
            {!buildingId && renderDateFilter}
          </Stack>
        </GeneralFilterContainer>
      </DataTable>
      {DeleteConfirm}
      {CloseInsuranceConfirm}
      {ReopenInsuranceConfirm}
    </div>
  )
}

InsurancePolicyList.propTypes = {
  buildingId: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCloseInsurance: PropTypes.func.isRequired,
  onReopenInsurance: PropTypes.func.isRequired,
}

export default InsurancePolicyList
