import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DataTable from '@Common/Components/DataTable'
import { Box } from '@Common/Components/Styles'
import { useTaskOpenConditionFormField } from '@Tms/Forms/CategoryForm/useCategoryForm'
import OpenConditionTypeSelect from '@Tms/Forms/OpenConditionForm/OpenConditionTypeSelect'
import AncestorAndSiblingTasksSelect from '@Tms/Forms/Selects/AncestorAndSiblingTasksSelect'
import TaskCustomFieldChoiceSelect from '@Tms/Forms/TaskFieldForm/TaskCustomFieldChoiceSelect'
import TaskCustomFieldSelect from '@Tms/Forms/TaskFieldForm/TaskCustomFieldSelect'
import TaskOpenCondition from '@Tms/Models/TaskOpenCondition'
import { TaskCategoryPermissions } from '@Tms/Permissions'
import { getSelectTypeFields } from '@Tms/Utils'

const DISPLAY_COLUMNS = ['conditionType', 'conditionTask', 'conditionField', 'conditionFieldChoice']

const TaskOpenConditionList = ({ prefix, taskDepth, taskId, isReadOnly }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState()
  const { fields, addOpenCondition, removeOpenCondition, control, inputPath } = useTaskOpenConditionFormField(prefix)
  const {
    watch,
    formState: { errors },
  } = useFormContext()
  const excluded = taskDepth > 1 ? [0] : []

  const FIELD_MAPPING = {
    // eslint-disable-next-line react/display-name
    conditionType: (_, __, index) => (
      <Stack direction="row" alignItems="end" spacing={1}>
        <Typography fontWeight="bold">{t('tms:ui.If').toUpperCase()}</Typography>
        <OpenConditionTypeSelect
          disabled={isReadOnly}
          control={control}
          name={`${inputPath}.${index}.conditionType`}
          excluded={excluded}
          required
        />
      </Stack>
    ),
    // eslint-disable-next-line react/display-name
    conditionTask: (_, __, index) => {
      const mainTaskId = watch('mainTask.id')
      const conditionType = watch(`${inputPath}.${index}.conditionType`)
      const excluded = conditionType === 1 ? [taskId, mainTaskId] : [taskId]
      return (
        <Stack direction="row" alignItems="end" spacing={1}>
          <Typography fontWeight="bold">{t('tms:ui.Task').toUpperCase()}</Typography>
          <AncestorAndSiblingTasksSelect
            disabled={isReadOnly}
            width="100%"
            control={control}
            exclude={excluded}
            name={`${inputPath}.${index}.conditionTask`}
            taskDepth={taskDepth}
            required
            error={!!errors?.[`${inputPath}.${index}.conditionTask`]}
            // helperText={condition.error}
          />
        </Stack>
      )
    },
    // eslint-disable-next-line react/display-name
    conditionField: (record, _, index) => {
      const taskId = watch(`${inputPath}.${index}.conditionTask`)
      const conditionType = watch(`${inputPath}.${index}.conditionType`)
      return (
        conditionType === 3 &&
        taskId && (
          <Stack direction="row" alignItems="end" spacing={1}>
            <Typography fontWeight="bold">{t('tms:ui.Field').toUpperCase()}</Typography>
            <TaskCustomFieldSelect
              disabled={isReadOnly}
              name={`${inputPath}.${index}.conditionField`}
              selectFieldsFn={getSelectTypeFields}
              taskId={taskId}
              control={control}
              valueKey="id"
              labelKey="name"
            />
          </Stack>
        )
      )
    },
    // eslint-disable-next-line react/display-name
    conditionFieldChoice: (record, _, index) => {
      const taskId = watch(`${inputPath}.${index}.conditionTask`)
      const fieldId = watch(`${inputPath}.${index}.conditionField`)
      const conditionType = watch(`${inputPath}.${index}.conditionType`)
      return (
        conditionType === 3 &&
        taskId &&
        fieldId && (
          <Stack direction="row" alignItems="end" spacing={1}>
            <Typography fontWeight="bold">{t('tms:ui.Choice').toUpperCase()}</Typography>
            <TaskCustomFieldChoiceSelect
              disabled={isReadOnly}
              name={`${inputPath}.${index}.conditionFieldChoice`}
              control={control}
              taskId={taskId}
              fieldId={fieldId}
            />
          </Stack>
        )
      )
    },
  }

  const actions = useMemo(
    () => [
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: R.either(TaskCategoryPermissions.update, TaskCategoryPermissions.create),
      },
    ],
    [],
  )

  const handleAction = useCallback((_, { id }) => removeOpenCondition(id), [selected])

  return (
    <div>
      {!isReadOnly && (
        <Box margin="0 0 0.4rem" align="end">
          <Button size="small" disableElevation variant="contained" onClick={addOpenCondition} startIcon={<AddIcon />}>
            {t('tms:actions.AddCondition')}
          </Button>
        </Box>
      )}
      <DataTable
        noHeader
        name="tms-task-field-open-conditions"
        data={fields}
        model={TaskOpenCondition}
        sortField="id"
        sortDirection="asc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELD_MAPPING}
        actions={actions}
        onAction={handleAction}
        selectable={!isReadOnly}
        selected={selected}
        onSelect={setSelected}
        noPagination
        noExport
        noSettings
        noBulkSelection
      />
    </div>
  )
}

TaskOpenConditionList.propTypes = {
  prefix: PropTypes.string,
  taskDepth: PropTypes.number,
  taskId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isReadOnly: PropTypes.bool,
}

export default TaskOpenConditionList
