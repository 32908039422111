import styled from 'styled-components'
import { Card as MuiCard } from '@mui/material'

const Card = styled(MuiCard)`
  min-width: ${({ minwidth }) => minwidth || '10rem'};
  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
  height: ${({ height }) => height || 'auto'};
  padding: ${({ pad }) => pad || '0'};
  ${({ theme, background }) => {
    return background ? `background-color: ${theme.palette[background].main}` : ''
  }}
`

export default Card
