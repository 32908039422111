/* eslint-disable react/prop-types */
import { useParams } from 'react-router-dom'

import { DataTableV3 } from '@Common/Components/DataTableV3'
import { SearchField } from '@Common/Components/FormsV2/SearchField'
import { RegionProvinceCitySelectV2 } from '@Common/Components/LocationV2/RegionProvinceCitySelectV2'
import { SupplierBranch } from '@Supplier/Models/SupplierBranch'

export const SupplierBranchListView = ({ url }) => {
  const { supplierId } = useParams()
  const localUrl = url || `/condo/bicert/suppliers/${supplierId}/branches`

  const renderFilters = (
    <>
      <SearchField />
      <RegionProvinceCitySelectV2
        name={`city`}
        nameCityProvince={`province`}
        nameCityRegion={`region`}
        defaultValueRegion={null}
        defaultValueProvince={null}
        defaultValueCity={null}
        autocompleteFromCity={true}
        nameCityCap="cap"
        xs={2}
        sm={2}
        required={false}
      />
    </>
  )

  const FIELDS_MAPPING = {
    province: (record) => record?.cityObj?.provinceObj?.name || '-',
  }

  return (
    <>
      <DataTableV3
        url={localUrl}
        model={SupplierBranch}
        renderFilters={renderFilters}
        columns={[
          'branchType',
          'city.name',
          'province',
          'cap',
          'address',
          'houseNumber',
          'emails',
          'mobiles',
          'telephones',
          'branchReferents',
          'services',
        ]}
        defaultOrdering="email"
        disableSorting={['province', 'services', 'branchReferents']}
        selectable={false}
        fieldsMapping={FIELDS_MAPPING}
      />
    </>
  )
}
