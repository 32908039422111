import { Menu, MenuItem } from '@mui/material'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { FieldType } from '@Common/Types/Field'
import { minutesToTimeRange, timeRangeToMinutes } from '@Common/Utils/Datetime'
import { wrapValueInEvent } from '@Common/Utils/Events'

const Button = styled.div`
  background: ${({ theme }) => theme.palette.lightTwo.main};
  border-radius: 0.8rem;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  user-select: none;
`

export const unitsMap = {
  m: i18next.t('common:fields.min'),
  h: i18next.t('common:fields.hours'),
  d: i18next.t('common:fields.days'),
  w: i18next.t('common:fields.weeks'),
}

const TimeRangeField = ({ label, defaultUnit, value, onChange, required, error, helperText, ...other }) => {
  const [unit, setUnit] = React.useState(defaultUnit)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event) => {
    !other.disabled && setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickUnit = (unit) => () => {
    setUnit(unit)
    onChange(wrapValueInEvent(null), unit)
    handleClose()
  }

  const handleChange = (evt) => {
    onChange(wrapValueInEvent(timeRangeToMinutes(evt.target.value, unit)), unit)
  }

  return (
    <Box direction="row" align="flex-start">
      <TextField
        label={label}
        required={required}
        value={minutesToTimeRange(value, unit)}
        onChange={handleChange}
        helperText={helperText}
        error={error}
        type="number"
        inputProps={{ min: 0 }}
        {...other}
      />
      <Button
        variant="outlined"
        onClick={handleOpen}
        style={{
          position: 'relative',
          top: '14px',
          textAlign: 'center',
          pointerEvents: other.disabled ? 'none' : 'auto',
        }}
      >
        {unitsMap[unit]}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClickUnit('m')}>{unitsMap.m}</MenuItem>
        <MenuItem onClick={handleClickUnit('h')}>{unitsMap.h}</MenuItem>
        <MenuItem onClick={handleClickUnit('d')}>{unitsMap.d}</MenuItem>
        <MenuItem onClick={handleClickUnit('w')}>{unitsMap.w}</MenuItem>
      </Menu>
    </Box>
  )
}

TimeRangeField.defaultProps = {
  defaultUnit: 'd',
  required: false,
}

TimeRangeField.propTypes = {
  ...FieldType,
  defaultUnit: PropTypes.string,
  valueTo: PropTypes.number,
}

export default TimeRangeField
