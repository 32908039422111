import { Search } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import { InputAdornment, ListSubheader, Stack, TextField, Typography } from '@mui/material'
import MuiBox from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { Box } from '@Common/Components/Styles'
import { setStateFromEvent } from '@Common/Utils/Events'

import { FieldType } from '../Types/Field'
import logger from '../Utils/Logger'
import Loader from './Loader'

const Clear = styled(ClearIcon)`
  color: ${(props) => props.color};
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 20px;
`

const HelperText = styled(FormHelperText)`
  ${({ error, theme }) => error && `color: ${theme.palette.error}`}
`

const Dropdown = ({
  label,
  value,
  onChange,
  required,
  error,
  options,
  labelKey,
  descriptionKey,
  valueKey,
  helperText,
  requestAction,
  width,
  readonly,
  multiple,
  disabled,
  emptyLabel,
  emptyValue,
  clearable,
  searchable,
  hideAsterisk,
  ...other
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [searchText, setSearchText] = React.useState('')

  React.useEffect(() => {
    if (options === undefined && requestAction) {
      logger.debug(`Dropdown ${label}, undefined options, dispatching action`, requestAction())
      dispatch(requestAction())
    }
  }, [])

  const val = R.ifElse(R.isNil, () => '', R.identity)(value)

  const more = multiple
    ? {
        // eslint-disable-next-line
        renderValue: (selected) => (
          <MuiBox sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected
              .filter((value) => options.filter((o) => o[valueKey] === value).length)
              .map((value) => (
                <Chip key={value} label={R.find(R.propEq(valueKey, value))(options)[labelKey]} />
              ))}
          </MuiBox>
        ),
      }
    : {}

  return (
    <FormControl style={{ width }} variant="standard">
      {label && (
        <InputLabel>
          {label}
          {required && !hideAsterisk && ' *'}
        </InputLabel>
      )}
      <Select
        value={val}
        onChange={onChange}
        style={{ width }}
        required
        readOnly={readonly}
        multiple={multiple}
        disabled={disabled}
        error={error}
        variant="standard"
        {...other}
        {...more}
      >
        {searchable && (
          <ListSubheader>
            <TextField
              autoFocus
              value={searchText}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={setStateFromEvent(setSearchText)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation()
                }
              }}
            />
          </ListSubheader>
        )}
        {options && !required && <MenuItem value={emptyValue}>{emptyLabel}</MenuItem>}
        {options &&
          options
            .filter((option) => {
              const label = option[labelKey]?.toLowerCase()
              const description = option[descriptionKey]?.toLowerCase()
              const searched = searchText?.toLowerCase()

              if (label && label.includes(searched)) return true
              if (description && description.includes(searched)) return true
              return false
            })
            .map((opt) => (
              <MenuItem key={`opt-${opt[valueKey]}`} value={opt[valueKey]}>
                <Stack spacing={0}>
                  <Box>
                    {opt.icon ? opt.icon : null} {opt[labelKey]}
                  </Box>
                  {opt[descriptionKey] && <Typography variant="caption">{opt[descriptionKey]}</Typography>}
                </Stack>
              </MenuItem>
            ))}
        {!options && (
          <MenuItem value="">
            <Box direction="row" align="inherit">
              <Loader size={16} />
              <span style={{ marginLeft: '.5rem' }}>{t('common:ui.Loading')}</span>
            </Box>
          </MenuItem>
        )}
      </Select>
      {clearable && R.not(R.equals('', val)) && (
        <Clear color={theme.palette.contrastHigh.main} onClick={() => onChange({ target: { value: '' } })} />
      )}
      {helperText && (
        <HelperText variant="standard" error={error}>
          {helperText}
        </HelperText>
      )}
    </FormControl>
  )
}

Dropdown.defaultProps = {
  width: '100%',
  disabled: false,
  readonly: false,
  multiple: false,
  emptyLabel: '--',
  emptyValue: '',
  clearable: false,
  labelKey: 'label',
  descriptionKey: 'description',
  valueKey: 'value',
}

Dropdown.propTypes = {
  ...FieldType,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  requestAction: PropTypes.func,
  width: PropTypes.string,
  labelKey: PropTypes.string,
  descriptionKey: PropTypes.string,
  valueKey: PropTypes.string,
  emptyLabel: PropTypes.string,
  emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideAsterisk: PropTypes.bool,
}

export default Dropdown
