import React from 'react'
import ServerAutoCompleteField from '@Common/Components/ServerAutocompleteField'
import { FieldType } from '@Common/Types/Field'
import { useReportTypesQuery } from '../Services/Api'
import * as R from 'ramda'

/**
 * Usage:
 *
 *  <ReportTypeAutocomplete
 *    label={t('ReportTypes:fields.ReportType.__field')}
 *    onChange={setValue} />
 */
const ReportTypeAutocomplete = ({ ...props }) => {
  return (
    <ServerAutoCompleteField
      queryFn={useReportTypesQuery}
      queryOrdering='name'
      querySearchObjCb={R.objOf('name')}
      queryOptionsObjCb={(b) => ({ label: b.name, value: b.id })}
      {...props}
    />
  )
}

ReportTypeAutocomplete.propTypes = {
  ...FieldType,
}

export default ReportTypeAutocomplete
