import PropTypes from 'prop-types'
import Modal from '@Common/Components/Modal'
import { useTranslation } from 'react-i18next'
import { Label, Margin } from '@Common/Components/Styles'

import { FormControl, Grid } from '@mui/material'
import TextField from '@Common/Components/TextField'
import { useForm } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import logger from '@Common/Utils/Logger'
import { labelValidate } from './Validation'
import { LabelType } from '../Models/Label'
import * as R from 'ramda'
import { ChromePicker } from 'react-color'
import React, { useState } from 'react'
import Loader from '@Common/Components/Loader'

export const LabelForm = ({ onClose, onSave, label }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fields, setField, errors, setErrors } = useForm(
    {
      name: R.defaultTo('', label?.name),
      color: R.defaultTo('', label?.color),
    },
    true,
  )

  const submit = async () => {
    logger.debug('Label form submission, fields:', fields)
    if (labelValidate(fields, setErrors, label)) {
      setIsSubmitting(true)
      logger.debug('Label form submission, validation passed, saving')
      const { error, isSuccessful } = await onSave(fields)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('Label form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('Label form submission, validation failed')
    }
  }

  const title = label?.id ? t('services:ui.ModifyLabel') : t('services:ui.AddLabel')

  const handleChange = (color) => setField('color')(color.hex)

  return (
    <Modal title={title} size='xs' onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              required
              label={t('services:fields.label.Name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Margin bottom='1rem' top='1rem'>
              <Label>{t('services:fields.label.Color')} *</Label>
            </Margin>
            <ChromePicker color={fields.color} width='100%' disableAlpha={true} onChange={handleChange}></ChromePicker>
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  )
}

LabelForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  label: LabelType,
}
