import React from 'react'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'
import { TASK_STATUSES } from '@Tms/Utils'

const StatusSelect = ({ onChange, value, ...other }) => {
  return <Dropdown options={TASK_STATUSES} onChange={onChange} width="100%" value={value} {...other} />
}

StatusSelect.propTypes = FieldType

export default StatusSelect
