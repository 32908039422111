import { Grid } from '@mui/material';
import PropTypes from 'prop-types';


const PasswordGrid = ({ fields, rules, columns }) => {
  return (
    <>
      <Grid item xs={columns.xs} sm={columns.sm}>
        {fields}
      </Grid>
      <Grid item xs={columns.xs} sm={columns.sm}>
        {rules}
      </Grid>
    </>
  )
}

PasswordGrid.defaultProps = {
  columns: {
    xs: 12,
    sm: 6,
  },
}

PasswordGrid.propTypes = {
  fields: PropTypes.node.isRequired,
  rules: PropTypes.node.isRequired,
  columns: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
  }),
}

export default PasswordGrid
