import React from 'react'
import {
  useCreateServiceLabelsMutation,
  useUpdateServiceLabelsMutation,
  useDeleteServiceLabelsMutation,
} from '../Services/Api'
import AddFab from '@Common/Components/AddFab'
import styled from 'styled-components'
import { useCurrentUser, useBreadcrumbs } from '../../Common/Utils/Hooks'
import { LabelPermissions } from '../Permissions'
import LabelList from '../Components/admin/AdminLabelList'
import { LabelForm } from '../Forms/LabelForm'
import { useTranslation } from 'react-i18next'
import { responseStringError } from '../../Common/Utils/Api'
import { toast } from 'react-toastify'
import * as R from 'ramda'
import { makePath } from '@Config'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminLabelView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [createLabels] = useCreateServiceLabelsMutation()
  const [updateLabels] = useUpdateServiceLabelsMutation()
  const [deleteLabels] = useDeleteServiceLabelsMutation()
  const [form, setForm] = React.useState({ isOpen: false, label: null })
  const handleOpenForm = (label) => () => setForm({ isOpen: true, label })
  const handleCloseForm = () => setForm({ isOpen: false, label: null })


  useBreadcrumbs([{ label: t('common:navigation.Services'), path: makePath('admin.services.services'), Icon: 'Service' },
  { label: t('common:navigation.Labels'),  Icon: 'Offer' }]
  )
  const handleSave = async (fields) => {
    const formData = new FormData()
    formData.append('name', fields.name)
    formData.append('color', fields.color)
    try {
      const response = form.label
        ? await updateLabels({ id: form.label.id, label: formData }).unwrap()
        : await createLabels(formData).unwrap()
      toast.success(t(`services:success.Label${form.label ? 'Updated' : 'Created'}`, { title: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t(`services:errors.${form.label ? 'Update' : 'Create'}LabelError`, { error: responseStringError(error) }),
      )
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (labels) => {
    try {
      const ids = labels.map(R.prop('id'))
      const promises = ids.map(async (id) => {
        return deleteLabels(id).unwrap()
      })

      await Promise.all(promises)
      toast.success(
        t('services:success.LabelsDeleted', {
          count: labels.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('services:errors.DeleteLabelsError', {
          error: err.data?.detail || t('common:errors.UnknownError'),
          count: labels.length,
        }),
      )
    }
  }

  return (
    <Main>
      <LabelList onEdit={(label) => setForm({ isOpen: true, label })} onDelete={handleDelete} />
      {form.isOpen && <LabelForm label={form.label} onClose={handleCloseForm} onSave={handleSave} />}
      {LabelPermissions.create(user) && <AddFab onClick={handleOpenForm(null)} />}
    </Main>
  )
}

export { AdminLabelView }
