import i18next from 'i18next'
import PropTypes from 'prop-types'

export const NewsType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  abstract: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('news:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'abstract',
      label: i18next.t('news:fields.abstract'),
    },
    {
      id: 'date',
      label: i18next.t('news:fields.date'),
      date: true,
    },
    {
      id: 'featured',
      label: i18next.t('news:fields.featured'),
      boolean: true,
    },
    {
      id: 'published',
      label: i18next.t('news:fields.published'),
      boolean: true,
    },
    {
      id: 'title',
      label: i18next.t('news:fields.title'),
    },
  ],
}
