import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CONTACT_TYPE } from '@Buildings/Constants'
import { isAdminRoute } from '@Common/Utils/Url'
import { useCondoByIdQuery } from '@Condo/Services/Api'
import { makePath } from '@Config'

export const getDefaultBankAccount = R.ifElse(
  R.compose(R.isEmpty, R.prop('contoCorrentePredefinito')),
  R.compose(R.head, R.defaultTo([]), R.prop('contiCorrenti')),
  R.prop('contoCorrentePredefinito'),
)

export const getBuildingAddress = R.pipe(
  R.prop('indirizzo'),
  R.defaultTo({}),
  R.pick(['via', 'civico', 'cap', 'comune']),
  R.values,
  R.insert(2, ','),
  R.join(' '),
  R.replace(/ ,/, ','),
)

export const getBuildingAddressWithoutNumber = R.pipe(
  R.prop('indirizzo'),
  R.defaultTo({}),
  R.pick(['via', 'cap', 'comune']),
  R.values,
  R.insert(1, ','),
  R.join(' '),
  R.replace(/ ,/, ','),
)

export const getBuildingShortAddress = R.pipe(
  R.prop('indirizzo'),
  R.defaultTo({}),
  R.pick(['cap', 'comune']),
  R.values,
  R.join(' '),
)

export const getIban = R.compose(R.prop('codiceIBAN'), getDefaultBankAccount)

export const getPhoneFromContacts = R.compose(
  R.prop('riferimento'),
  R.find(R.pathEq(['tipo', 'id'], CONTACT_TYPE.PHONE)),
)

export const getEmailFromContacts = R.compose(
  R.prop('riferimento'),
  R.find(R.pathEq(['tipo', 'id'], CONTACT_TYPE.EMAIL)),
)

export const getAllEmailsFromContacts = R.compose(
  R.join(' - '),
  R.map(R.prop('riferimento')),
  R.filter(R.pathEq(['tipo', 'id'], CONTACT_TYPE.EMAIL)),
)

export const getSupplierSummary = R.pipe(
  R.pick(['nome', 'cognome', 'ragioneSociale']),
  R.values,
  R.ifElse(
    (v) => R.isNil(v[2]),
    (v) => v,
    R.pipe(R.insert(2, '('), R.insert(4, ')')),
  ),
  R.join(' '),
  R.replace(/\( (.*) \)/, '($1)'),
  R.trim,
)

export const getSuppliersSummary = R.pipe(R.map(getSupplierSummary), R.join(' - '))

export const getCondoBreadcrumbFromId = (buildingId) => {
  const { t } = useTranslation()
  const basePath = isAdminRoute() ? 'admin.' : ''
  const { data: building } = useCondoByIdQuery(buildingId, { skip: !buildingId })

  if (!building) {
    return []
  }

  return [
    { label: t('buildings:ui.Buildings'), path: makePath(`${basePath}buildings.list`), Icon: 'Building' },
    {
      label: building?.description,
      path: makePath(`${basePath}buildings.detail.main`, {
        buildingId: building.id,
      }),
    },
  ]
}

export const getReconciliationReminderBreadcrumbs = (data) => {
  const { t } = useTranslation()
  const basePath = isAdminRoute() ? 'admin.' : ''

  return [
    {
      label: t('common:navigation.ReconciliationReminderManagement'),
      path: makePath(`${basePath}reconciliationReminders.reconciliationReminder`),
      Icon: 'Paid',
    },
    {
      label: t('condo:ui.ReconciliationReminder'),
      path: makePath(`${basePath}reconciliationReminders.detail.main`, { id: data?.id }),
    },
  ]
}

export const getPodPdrBreadcrumbs = (data) => {
  const { t } = useTranslation()

  const { buildingId } = useParams()

  const basePath = []

  if (isAdminRoute()) basePath.push('admin')

  if (buildingId) basePath.push('buildings.detail')

  basePath.push('podpdrs')

  const baseBreadcrumb = [
    {
      label: t('common:navigation.PodPdrManagement'),
      path: makePath([...basePath, 'list'].join('.'), {
        buildingId: buildingId,
      }),
      Icon: !buildingId && 'GasMeter',
    },
    {
      label: t('condo:ui.PodPdr'),
      path: makePath([...basePath, 'detail', 'main'].join('.'), { id: data?.id, buildingId: buildingId }),
    },
  ]

  return [...getCondoBreadcrumbFromId(buildingId), ...baseBreadcrumb]
}

export const getInsurancePolicyBreadcrumbs = (data) => {
  const { t } = useTranslation()

  const { buildingId } = useParams()

  const basePath = []

  if (isAdminRoute()) basePath.push('admin')

  if (buildingId) basePath.push('buildings.detail')

  basePath.push('insurancePolicy')

  const baseBreadcrumb = [
    {
      label: t('common:navigation.InsurancePolicyManagement'),
      path: makePath([...basePath, 'list'].join('.'), {
        buildingId: buildingId,
      }),
      Icon: !buildingId && 'HealthAndSafety',
    },
    {
      label: t('condo:ui.InsurancePolicy', { name: data?.number }),
      path: makePath([...basePath, 'detail', 'main'].join('.'), { id: data?.id, buildingId: buildingId }),
    },
  ]

  return [...getCondoBreadcrumbFromId(buildingId), ...baseBreadcrumb]
}
