import ErrorIcon from '@mui/icons-material/Error'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { Backdrop, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment'
import * as R from 'ramda'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useAnagraphicInstallmentsQuery } from '@Buildings/Services/Api'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { selectActiveHousingUnitId } from '@MyEstia/Redux'
import MyHomeInstallmentsSearchForm from '@MyHome/Forms/MyHomeInstallmentsSearchForm'
import Installment from '@MyHome/Models/InstallmentsList'
import PaymentMethodSelectButton from '@Payments/Components/PaymentMethodSelect'
import StatusPaymentDialog from '@Payments/Components/StatusPaymentDialog'

const DISPLAY_COLUMNS = ['descrizione', 'importo', 'residuo', 'incassato', 'scadenza', 'status', 'canPay']

const Main = styled.div`
  padding: ${({ mobile }) => (mobile ? '.5rem' : '2rem')};
  padding-bottom: 150px;
`

const Chip = styled.div`
  ${({ theme, ...props }) => css`
    ${props.variant === 'success' && `background-color: ${theme.palette.success.dark};`}
    ${props.variant === 'warning' && `background-color: ${theme.palette.warning.main};`}
    ${props.variant === 'error' && `background-color: ${theme.palette.error.main};`}
    ${props.variant === 'primary' && `background-color: ${theme.palette.primary.main};`}
    border-radius: 15px;
    display: inline-block;
    text-align: center;
    padding: 0.5rem 1rem;
    min-width: 80px;
    ${props.variant === 'success' && `color: ${theme.palette.contrastLight.main};`}
    ${props.variant === 'warning' && `color: ${theme.palette.contrastLight.contrastText};`}
    ${props.variant === 'error' && `color: ${theme.palette.contrastLight.main};`}
    ${props.variant === 'primary' && `color: ${theme.palette.contrastLight.main};`}
  `}
`

const CustomBadge = (props) => {
  const { t } = useTranslation()

  let title = null

  switch (props.variant) {
    case 'success':
      // Pagato
      title = t('myHome:ui.Paid')
      break
    case 'warning':
      // In corso
      title = t('myHome:ui.paymentInProgress')
      break
    case 'primary':
      // Da Pagare
      title = t('myHome:ui.ToBePaid')
      break
    case 'error':
      // In corso
      title = t('myHome:ui.Expired')
      break
  }

  return <Chip variant={props.variant}>{title}</Chip>
}

CustomBadge.propTypes = {
  variant: 'success' | 'warning' | 'error' | 'primary',
}

const MyHomeInstallmentsView = () => {
  const { t } = useTranslation()
  const housingUnitId = useSelector(selectActiveHousingUnitId)
  const user = useCurrentUser()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [qsAdditions, setQsAdditions] = useState({ ui__id: housingUnitId })

  // Recupero lo stato se è stato effettuato un pagamento
  const [searchParams, setSearchParams] = useSearchParams()

  const removeQueryParams = () => {
    const param = searchParams.get('status-payment')

    if (param) {
      searchParams.delete('status-payment')
      setSearchParams(searchParams)
    }
  }

  const FIELDS_MAPPING = {
    // eslint-disable-next-line react/display-name
    status: (record) => {
      let variant = 0

      switch (record.status) {
        case 0:
          // Da Pagare
          variant = 'primary'
          break
        case 1:
          // In corso
          variant = 'warning'
          break
        case 2:
          // Pagato
          variant = 'success'
          break
        case 3:
        case 4:
          // Errore e Scaduto
          variant = 'error'
          break
      }

      if (record.status === 3 || record.status === 4) {
        variant = record?.residuo <= 0 ? 'success' : variant
      }

      return <CustomBadge variant={variant} />
    },
    residuo: R.pipe(R.prop('residuo'), R.toString, R.concat('€ ')),
    incassato: R.pipe(R.prop('incassato'), R.toString, R.concat('€ ')),
    importo: R.pipe(R.prop('importo'), R.toString, R.concat('€ ')),
    // eslint-disable-next-line react/display-name
    canPay: (record) => {
      if (
        record?.immobile?.stripeAccountConnectId &&
        record?.contoCorrenteIncasso?.stripeBankAccountId &&
        record.canPay &&
        (record.status === 0 || record.status === 4 || Number(record.residuo) !== 0)
      ) {
        return <PaymentMethodSelectButton onPay={(value) => handleClickPay(value)} rata={record} />
      }
    },
  }

  const [qs, setQs] = useState({
    base: {
      limit: getDefaultListPerPage('building-installments'),
      offset: 0,
      ordering: '-scadenza',
    },
    qsAdditions,
  })

  const { data, isFetching } = useAnagraphicInstallmentsQuery({ anagraphicId: user.resident.raw.id, qs })

  const refreshData = (data) => {
    setQs(data)
  }

  const handleSearch = (fields) => {
    const params = { ui__id: housingUnitId }

    if (fields.descrizione) {
      params.descrizione__icontains__or = fields.descrizione
    }
    if (fields.scadenza) {
      switch (fields.scadenza) {
        case 'expired':
          params.residuo__gt = 0
          params.scadenza__lt = moment().format('YYYY-MM-DD')
          break
        case 'not_expired':
          params.scadenza__gte = moment().format('YYYY-MM-DD')
          break
        default:
          break
      }
    }

    setQsAdditions(params)
  }

  const [isOpeningSessionStripe, setIsOpeningSessionStripe] = useState(false)
  const handleClickPay = useCallback(
    async (url) => {
      if (isOpeningSessionStripe) return

      setIsOpeningSessionStripe(true)

      window.open(url, '_self')

      setTimeout(() => {
        setIsOpeningSessionStripe(false)
      }, 300)
    },
    [isOpeningSessionStripe],
  )

  console.log(data?.results)

  return (
    <>
      <Backdrop
        sx={{ backgroundColor: 'rgba(255,255,255,0.6)', color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpeningSessionStripe}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Main mobile={isSmallDevice}>
        <DataTable
          name="building-installments"
          selectable={false}
          qs={qs}
          qsAdditions={qsAdditions}
          data={data?.results || []}
          dataCount={data?.count || 0}
          refreshData={refreshData}
          model={Installment}
          listDisplay={DISPLAY_COLUMNS}
          fieldsMapping={FIELDS_MAPPING}
          loading={isFetching}
          useDjangoOrdering
          noBulkSelection
          noExport
          noSettings
        >
          <MyHomeInstallmentsSearchForm onSearch={handleSearch} />
        </DataTable>
      </Main>

      {searchParams.get('status-payment') === 'success' && (
        <StatusPaymentDialog open={true} onCancel={removeQueryParams}>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h1" textAlign="center">
              <ThumbUpIcon color="success" fontSize="155px" />
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Typography variant="h5" textAlign="center" sx={{ verticalAlign: 'middle' }}>
              {t('payments:success.paymentTitle')}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
            <Typography
              variant="span"
              textAlign="center"
              sx={{ verticalAlign: 'middle', color: (theme) => theme.palette.grey[700] }}
            >
              {t('payments:success.paymentSubTitle')}
            </Typography>
          </Grid>
        </StatusPaymentDialog>
      )}

      {searchParams.get('status-payment') === 'error' && (
        <StatusPaymentDialog open={true} onCancel={removeQueryParams}>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h1" textAlign="center">
              <ErrorIcon color="error" fontSize="155px" />
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Typography variant="h5" textAlign="center" sx={{ verticalAlign: 'middle' }}>
              {t('payments:errors.paymentTitle')}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
            <Typography
              variant="span"
              textAlign="center"
              sx={{ verticalAlign: 'middle', color: (theme) => theme.palette.grey[700] }}
            >
              {t('payments:errors.paymentSubTitle')}
            </Typography>
          </Grid>
        </StatusPaymentDialog>
      )}

      {searchParams.get('status-payment') === 'in-progress' && (
        <StatusPaymentDialog open={true} onCancel={removeQueryParams}>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h1" textAlign="center">
              <ReportProblemIcon color="primary" fontSize="155px" />
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Typography variant="h5" textAlign="center" sx={{ verticalAlign: 'middle' }}>
              {t('payments:inProgress.paymentTitle')}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
            <Typography
              variant="span"
              textAlign="center"
              sx={{ verticalAlign: 'middle', color: (theme) => theme.palette.grey[700] }}
            >
              {t('payments:inProgress.paymentSubTitle')}
            </Typography>
          </Grid>
        </StatusPaymentDialog>
      )}
    </>
  )
}

export default MyHomeInstallmentsView
