import { Navigate, Route, Routes } from 'react-router-dom'

import { BuildingReconciliationReminderView } from '@Condo/Views/BuildingReconciliationReminderView'
import config from '@Config'

export const ReconciliationReminderRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<BuildingReconciliationReminderView />} />
        <Route path="*" element={<Navigate to={config.urls.pageNotFound} replace />} />
      </Route>
    </Routes>
  )
}
