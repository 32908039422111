import { CircularProgress, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'

import { useStripeAccountExternalAccountsQuery } from '../Services/Api'

export default function StripeAccountConnectExternalAccountsSelect({
  stripeAccountId,
  selectedExternalAccountId,
  value,
  ...other
}) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const { data } = useStripeAccountExternalAccountsQuery(stripeAccountId)
  const [externalsAccounts, setExternalsAccounts] = useState([])

  useEffect(() => {
    if (data) {
      console.log('setExternalsAccounts', data)
      let dataRes = JSON.parse(JSON.stringify(data))

      dataRes.forEach((element) => {
        element.bankName = element.bankName + ' (•••• ' + element.last4 + ')'
      })

      setExternalsAccounts(dataRes.map(R.pick(['id', 'bankName'])))
      setIsLoading(false)
    } else {
      setExternalsAccounts([])
      setIsLoading(true)
    }
  }, [data, selectedExternalAccountId])

  console.log('value', value)

  if (!isLoading) {
    return (
      <Dropdown
        options={externalsAccounts}
        labelKey="bankName"
        valueKey="id"
        label={t('payments:ui.selectStripeConnectBankAccount')}
        value={externalsAccounts.length > 0 ? value : null}
        {...other}
      />
    )
  }

  return (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  )
}

StripeAccountConnectExternalAccountsSelect.propTypes = {
  stripeAccountId: PropTypes.string,
  ...FieldType,
}
