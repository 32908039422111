import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useBreadcrumbs, useCurrentUser, useLocalStorage } from '@Common/Utils/Hooks'
import { CondosPermissions } from '@Condo/Permissions'
import CondosView from '@Condo/Views/CondosView'
import config from '@Config'

import ArcadiaBuildingsView from './ArcadiaBuildingsView'

const BuildingsView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()

  const handleTabChange = (event, newValue) => setTab(newValue)
  const [tab, setTab] = useLocalStorage('BuildingsView-tabSelected', 'buildings')

  if (!CondosPermissions.read(user) && tab === 'condos') {
    setTab('buildings')
  }

  useBreadcrumbs([
    {
      label: t('common:navigation.BuildingsManagement'),
      path: config.urls.admin.buildings,
      Icon: 'BuildingManagement',
    },
  ])

  return (
    <TabContext value={tab}>
      <TabList onChange={handleTabChange}>
        <Tab label="Arcadia" value="buildings" />
        {CondosPermissions.read(user) && <Tab label="Locali" value="condos" />}
      </TabList>

      <TabPanel value="buildings" sx={{ px: 0 }}>
        <ArcadiaBuildingsView />
      </TabPanel>
      {CondosPermissions.read(user) && (
        <TabPanel value="condos" sx={{ px: 0 }}>
          <CondosView />
        </TabPanel>
      )}
    </TabContext>
  )
}

export default BuildingsView
