import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  useCcCreateResidentNoteMutation,
  useCcDeleteResidentNoteMutation,
  useCcResidentNotesQuery,
} from '../Services/Api'
import { useConfirm } from '@Common/Utils/Hooks'
import AddFab from '@Common/Components/AddFab'
import ResidentNoteForm from '../Forms/ResidentNoteForm'
import { toast } from 'react-toastify'
import { responseStringError } from '@Common/Utils/Api'
import styled from 'styled-components'
import { apiList } from '../../Core/Services/Api'
import Loader from '../../Common/Components/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { IconButton } from '@mui/material'

const Container = styled.div``

const Title = styled.h3`
  background: ${({ theme }) => theme.palette.accentFive.main};
  padding: 1rem;
  margin: 0;
`

const Note = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.lightTwo.main};
  border-left: 5px solid ${({ theme }) => theme.palette.lightFour.main};
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`

const NoteBody = styled.div`
  flex-grow: 1;
`
const NoteActions = styled.div``

const NoteDate = styled.time`
  font-size: 0.9rem;
  font-style: italic;
`
const NoteText = styled.p`
  margin: 1rem 0 0;
`

const ResidentNotes = ({ resident }) => {
  const { t } = useTranslation()
  const [form, setForm] = React.useState({ isOpen: false, note: null })
  const [createNote] = useCcCreateResidentNoteMutation()
  const [deleteNote] = useCcDeleteResidentNoteMutation()
  const { data: notes, isFetching } = apiList(useCcResidentNotesQuery(resident.id))

  const handleOpenForm = () => setForm({ isOpen: true, note: null })
  const handleCloseForm = () => setForm({ isOpen: false, note: null })
  const handleSaveNote = async (fields) => {
    try {
      const response = await createNote({ ...fields, anagraphicArcadiaId: resident.id }).unwrap()
      toast.success(t('customerCare:success.ResidentNoteCreated'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('customerCare:errors.CreateResidentNoteError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDeleteNote = async (note) => {
    try {
      await deleteNote({ id: note.id, anagraphicArcadiaId: resident.id }).unwrap()
      toast.success(t('customerCare:success.ResidentNoteDeleted'))
    } catch (error) {
      toast.error(t('customerCare:errors.DeleteResidentNoteError', { error: responseStringError(error) }))
    }
  }
  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteNote)
  const handleOpenDeleteConfirm = (note) => () => {
    openDeleteConfirm(
      note, // payload
      t('customerCare:ui.DeleteResidentNoteConfirmationTitle'),
      t('customerCare:ui.DeleteResidentNoteConfirmationText'),
    )
  }

  return (
    <Container>
      <Title>{t('customerCare:ui.Notes')}</Title>
      {isFetching && <Loader overlay absolute />}
      {notes.map((note, idx) => (
        <Note key={idx}>
          <NoteBody>
            <NoteDate>{moment(note.created).format('LLL')}</NoteDate>
            <NoteText>{note.note}</NoteText>
          </NoteBody>
          <NoteActions>
            <IconButton color="primary" onClick={handleOpenDeleteConfirm(note)}>
              <DeleteIcon />
            </IconButton>
          </NoteActions>
        </Note>
      ))}
      {form.isOpen && <ResidentNoteForm note={form.note} onClose={handleCloseForm} onSave={handleSaveNote} />}
      <AddFab onClick={handleOpenForm} absolute bottom="10px" right="10px" />
      {DeleteConfirm}
    </Container>
  )
}

ResidentNotes.propTypes = {
  resident: PropTypes.object,
}

export default ResidentNotes
