import { Grid } from '@mui/material'
import { FormControl, Link } from '@mui/material'
import { useMediaQuery, Typography } from '@mui/material'
import i18next from 'i18next'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import TransparentIcon from '@Assets/Images/newsletter_icon.png'
import NewsletterIcon from '@Assets/Images/newsletter_icon.svg'
import Checkbox from '@Common/Components/Checkbox'
import { Margin } from '@Common/Components/Styles'
import { setStateFromEvent, setStateFromCheckboxEvent } from '@Common/Utils/Events'
import Button from '@MyEstia/Components/Button'
import TextInput from '@MyEstia/Components/TextInput'

import Alert from '../../Common/Components/Alert'
import { useForm } from '../../Common/Utils/Hooks'
import { checkRequired, checkEmailAddress } from '../../Common/Utils/Validation'
import { useSubscribeMutation } from '../Services/Api'

const Form = styled.div``
const SubButton = styled(Button)`
  width: 200px;
  height: 48px;
  border-radius: 100px;
`

const Bludiv = styled.div`
  background-color: ${({ theme }) => theme.palette.newsletter.main};
  padding-bottom: 67px;
  background-size: 250px 200px;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 35px;
  h1 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 39px;
    color: white;
  }

  position: relative;

  .MuiFilledInput-root-dwcfCJ {
    //textinput;
    height: 48px;
    background: white;
  }
  .dYMNZH {
    // checkbox color;
    color: #fff;
  }
  .fLRtBW {
    // checkbox text color;
    color: #fff;
  }
`

const Image = styled(Grid)`
  margin-left: 30px;
  background-repeat: no-repeat;
  background-position: right;
  background-image: url(${NewsletterIcon});
  margin-left: ${({ $mobile }) => ($mobile ? 0 : '30px')};
  background-size: ${({ $mobile }) => ($mobile ? 0 : '200px')};
  display: ${({ $mobile }) => ($mobile ? 'none' : 'block')};
`

const Terms = styled.span`
  color: ${({ theme }) => theme.palette.white.main};
  text-decoration: none;
`

const PrivacyPolicyLink = styled(Link)`
  color: ${({ theme }) => theme.palette.white.main};
  text-decoration: underline;
`

export const validate = (fields, setErrors) => {
  const errors = {}

  checkRequired(errors, ['email'], fields)
  checkEmailAddress(errors, fields, 'email')
  if (!fields.terms) {
    errors.terms = i18next.t('newsletter:validation.TermsRequired')
  }
  setErrors(errors)

  return { success: R.isEmpty(errors), errors }
}

const Widget = () => {
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const { t } = useTranslation()
  const { fields, setField, errors, setErrors } = useForm(
    {
      email: '',
      terms: false,
    },
    true,
  )

  const [subscribe] = useSubscribeMutation()

  const submit = async () => {
    const { success } = validate(fields, setErrors)
    if (success) {
      try {
        await subscribe(fields).unwrap()
        toast.success(t('newsletter:comments.Success'))
      } catch (err) {
        if (err.status === 400) {
          toast.error(t('newsletter:comments.Existing'))
        } else {
          toast.error(t('newsletter:comments.Failure'))
        }
      }
    }
  }

  return (
    <Bludiv
      style={{
        textAlign: isSmallDevice ? 'center' : 'left',
        backgroundImage: isSmallDevice ? `url(${TransparentIcon})` : 'none',
      }}
    >
      <Grid container spacing={4}>
        <Image item xs={0} md={3} $mobile={isSmallDevice}></Image>
        <Grid item xs={12} md={8}>
          <h1 style={{ padding: '0 1rem', marginBottom: '.5rem' }}>Iscriviti alla nostra Newsletter</h1>
          <Form>
            {errors.__form && <Alert level="error">{errors.__form}</Alert>}

            <FormControl fullWidth margin="normal">
              <Grid container>
                <Grid item xs={12} md={7}>
                  <Margin bottom="1.5rem">
                    <TextInput
                      style={{ width: isSmallDevice ? '55%' : '80%' }}
                      value={fields.email}
                      placeholder={t('auth:fields.email')}
                      background="white"
                      color="black"
                      onChange={setStateFromEvent(setField('email'))}
                      error={!!errors.email}
                      helperText={errors.email}
                      errorColor="errorLight"
                    />
                  </Margin>
                  <Checkbox
                    style={{ maxWidth: isSmallDevice ? '50%' : '100%', color: '#fff' }}
                    label={
                      <Terms>
                        <Trans
                          i18nKey="newsletter:PrivacyPolicy"
                          components={[
                            <PrivacyPolicyLink
                              key="privacy-policy"
                              target="_blank"
                              href="https://estia.homes/privacy-policy-app/"
                            />,
                          ]}
                        />
                      </Terms>
                    }
                    value={fields.terms}
                    sx={{
                      '&.Mui-checked': {
                        color: '#fff',
                      },
                    }}
                    checked={fields.terms}
                    onChange={setStateFromCheckboxEvent(setField('terms'))}
                  />

                  {!!errors.terms && <Typography color="errorLight.light">{errors.terms}</Typography>}
                </Grid>
                <Grid item xs={12} md={4}>
                  <SubButton
                    fullWidth
                    style={{ marginTop: isSmallDevice ? '20px' : 0 }}
                    variant="contained"
                    onClick={submit}
                  >
                    {t('newsletter:buttons.Subscribe')}{' '}
                  </SubButton>
                </Grid>
              </Grid>
            </FormControl>
          </Form>
        </Grid>
      </Grid>
    </Bludiv>
  )
}

export default Widget
