import i18next from 'i18next'
import * as R from 'ramda'

import { checkRequired, nothing, wrap } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = ['password', 'passwordRepeat']

  checkRequired(err, required, fields)

  const setPasswordDoesntMatch = (err, field) => {
    err[field] = i18next.t('auth:validation.PasswordsDoesntMatch')
  }

  R.ifElse(
    R.compose(R.not, R.equals(R.prop('passwordRepeat', fields)), R.prop('password')),
    wrap(setPasswordDoesntMatch, err, 'password'),
    nothing,
  )(fields)

  setErrors(err)
  return R.isEmpty(err)
}
