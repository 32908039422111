import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Telegram as TelegramIcon,
  YouTube as YouTubeIcon,
} from '@mui/icons-material'
import styled from 'styled-components'
import { Grid, Link, Typography, useMediaQuery } from '@mui/material'
import Logo from '@Assets/Logo'

import { Box, Margin } from '@Common/Components/Styles'

const Container = styled.div`
  background: transparent;
  overflow-x: hidden;
  position: relative;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4572b5;
  height: ${({ mobile }) => (mobile ? '360px' : '360px')};
  margin-top: -50px;

  &:before {
    background-color: #102037;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.palette.footerBackground.main};
    border-radius: 100% 100% 0 0;
    transform: scaleX(1.5);
    height: ${({ mobile }) => (mobile ? '360px' : '360px')};
  }
`

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 1400px;
  z-index: 1;
  margin: 4rem 0 0 0;
  padding: 0 4rem 3rem;
`

const SocialSection = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 0 3rem;
  margin: auto;
`

const Icon = styled.div`
  padding: 0.5rem;
  border-radius: 50%;
  color: #efefef;
  border: 1px solid #efefef;
  display: flex;

  &:hover {
    color: #c6c6c6;
    border: 1px solid #c6c6c6;
  }
`

const Footer = () => {
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Container mobile={isSmallDevice}>
      <Content>
        <Grid container direction="row" justifyContent="space-around">
          <Grid
            item
            xs={12}
            md={4}
            style={{ order: isSmallDevice ? 1 : 0, textAlign: isSmallDevice ? 'center' : 'left' }}
          >
            <Margin bottom="1rem">
              <Logo width="150px" />
            </Margin>
            <Typography color="primary.main">Via Pisa 29, Torino 10152</Typography>
            <Typography color="primary.main">Estia &reg; marchio registrato</Typography>
            <Typography color="primary.main">Piva: 12471240015</Typography>
            <Typography color="primary.main">Capitale Sociale i.v. € 150.000,00</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <SocialSection>
              <Link href="https://www.facebook.com/EstiaSpA" target="_blank">
                <Icon>
                  <FacebookIcon />
                </Icon>
              </Link>
              <Link href="https://www.instagram.com/estia_spa/" target="_blank">
                <Icon>
                  <InstagramIcon />
                </Icon>
              </Link>
              <Link href="https://www.linkedin.com/company/estia-spa/" target="_blank">
                <Icon>
                  <LinkedInIcon />
                </Icon>
              </Link>
              <Link href="https://www.youtube.com/channel/UCofhLCKEWXioxQmw1EFLh5g" target="_blank">
                <Icon>
                  <YouTubeIcon />
                </Icon>
              </Link>
              <Link href="https://t.me/EstiaSpAAmministrazioneCondomini" target="_blank">
                <Icon>
                  <TelegramIcon />
                </Icon>
              </Link>
            </SocialSection>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              order: isSmallDevice ? 1 : 0,
              alignItems: isSmallDevice ? 'center' : 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              align="flex-end"
              height="100%"
              style={{ justifyContent: 'flex-end', alignItems: isSmallDevice ? 'center' : 'flex-end' }}
            >
              <Typography>Copyright &copy; 2022 Estia</Typography>
            </Box>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default Footer
