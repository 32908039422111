import React from 'react'
import { Controller } from 'react-hook-form'

import Checkbox from '@Common/Components/Checkbox'
import { FieldType } from '@Common/Types/ReactHookFormField'
import { setStateFromCheckboxEvent } from '@Common/Utils/Events'

export const CheckboxField = ({ name, control, label, rules, defaultValue, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Checkbox checked={value} onChange={setStateFromCheckboxEvent(onChange)} label={label} {...other} />
      )}
    />
  )
}

CheckboxField.propTypes = FieldType

export default CheckboxField
