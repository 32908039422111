import * as React from 'react'

const AccountingIcon = (props) => (
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="-20 -25 307 307"
    style={{
      enableBackground: 'new 0 0 307 200',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.st1{fill:#fdbd2e}'}</style>
    <path
      d="M-1132.7-16.1c-15.5 0-31.3 2.2-46.8-1.1-6.3-1.1-11.9-13.4-17.6-20.7 4.8-8.4 8.9-22.4 14.3-25.2 45.3-20.7 88.3-63.2 136.3-58.8 25.3 2.2 50.4 11.2 75.7 17.3V-3.2c-7.8-2.2-14.9-5.6-22.4-5.6-16.7.6-34.3-1.1-50.4 5.6-28.6 11.8-57 28.5-85 44.8-16.7 9.5-32.5 8.4-48.3-3.9-40.8-31.9-81.7-63.8-122.3-95.7-3.3-2.8-6.6-6.2-9.2-10.1-8.1-11.8-7.8-25.2 1.8-32.5 5.7-4.5 12.5-7.3 18.2-5 30.4 12.3 60.5 26.3 90.9 39.7-10.1 42.5-5.1 58.8 19.1 59.9 15.5 1.1 30.7-2.2 46.2-3.9-.2-1.7-.2-4-.5-6.2z"
      style={{
        fill: '#3f71b6',
      }}
      transform="matrix(.89437 0 0 .47889 1175.28 177.071)"
    />
    <path
      className="st1"
      d="M-1188.2-147c-23.3 0-42-34.1-42.3-77.2-.3-43.6 19.4-80 42.6-79.5 23 .6 41.7 35.8 41.7 78.9-.3 43.1-18.7 77.8-42 77.8zm8.4-63.8c-10.1 24.1-16.1 3.9-23-7.8-9.8 35.3 14.9 29.7 13.7 53.2 6.9-17.3 17-35.8 15.5-49.2-1.5-14.5-14-25.2-21.8-38.1 8.6-27.4 12.5 9.5 19.4 5 1.8-15.7 1.8-15.7-14.9-46.4-4.5 16.8-13.4 35.8-11.6 48.7 2.4 13.9 14.6 22.8 22.7 34.6zM-1078.8-279.1c-.3 43.6-25.3 75-46.5 58.2-3.9-2.8-6.6-11.8-8.6-19-2.4-9-2.7-19.6-5.4-28.5-4.5-15.7-9.8-29.7-2.1-47.6 8.3-19 18.5-29.1 31.3-28 16.4 2.2 31.6 34.1 31.3 64.9zm-34 49.8c4.8-16.2 12.8-31.3 11.6-41.4-1.5-12.3-11.3-20.7-17.3-31.3.6-2.2 1.2-3.9 1.5-6.2 4.8 2.8 9.5 5.6 14.3 9 .9-1.1 1.8-2.2 2.4-3.4-3.9-10.6-8.1-20.7-12.8-33-2.7 7.3-4.5 12.9-6.6 17.9-9.2 20.1-8.6 25.2 3.9 38.6 3 3.4 5.4 9 8.1 14-1.2 2.2-2.7 6.7-3.6 6.2-3.6-1.7-6.9-5-10.1-7.8-1.5-1.1-2.7-2.2-3.9-3.9-.9 1.7-2.1 3.4-3 5 4.8 10 9.6 21.2 15.5 36.3z"
      transform="matrix(.89437 0 0 .47889 1175.28 177.071)"
    />
    <path
      className="st1"
      d="M-1165.5-323.9c0 25.7-10.7 47-24.5 47.6-13.4.6-24.7-20.7-24.7-46.4s10.7-47 24.5-47c13.3-.6 24.7 20.1 24.7 45.8zm-23.9 36.4c3-12.3 8.1-22.4 7.5-31.3-.6-7.8-7.5-14-11.6-21.3.6-1.7 1.2-2.8 1.8-4.5 3 2.2 6.3 4.5 13.4 9.5-5.1-12.9-8.1-19.6-10.7-26.3-16.1 21.8-7.2 34.7 3 51.5-4.5-1.1-8.9-2.2-16.1-3.9 4.7 10.1 7.9 16.8 12.7 26.3z"
      transform="matrix(.89437 0 0 .47889 1175.28 177.071)"
    />
  </svg>
)

export default AccountingIcon
