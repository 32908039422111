import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'tickets'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    reports: builder.query({
      query: (qs) => `${prefix}/reports?${apiQueryString(qs)}`,
      providesTags: ['Report'],
    }),
    report: builder.query({
      query: (id) => `${prefix}/reports/${id}`,
      providesTags: (_, __, { id }) => [{ type: 'Report', id }],
    }),
    updateReport: builder.mutation({
      query: ({ id, status, answer }) => ({
        url: `${prefix}/reports/${id}/status`,
        body: { status, answer },
        method: 'POST',
      }),
      invalidatesTags: ['Report'],
    }),
    reportTypes: builder.query({
      query: () => `${prefix}/report-types`,
      providesTags: ['ReportType'],
    }),
    createReportType: builder.mutation({
      query: (reportType) => ({
        url: `${prefix}/report-types`,
        body: reportType,
        method: 'POST',
      }),
      invalidatesTags: ['ReportType'],
    }),
    updateReportType: builder.mutation({
      query: ({ id, reportType }) => ({
        url: `${prefix}/report-types/${id}`,
        body: reportType,
        method: 'PUT',
      }),
      invalidatesTags: ['ReportType'],
    }),
    deleteReportType: builder.mutation({
      query: (id) => ({
        url: `${prefix}/report-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ReportType'],
    }),
    createReport: builder.mutation({
      query: (report) => ({
        url: `${prefix}/reports`,
        body: report,
        method: 'POST',
      }),
      invalidatesTags: ['Report'],
    }),
    reportMessages: builder.query({
      query: (id) => `${prefix}/reports/${id}/messages`,
      providesTags: ['ReportMessage'],
    }),
    createReportMessage: builder.mutation({
      query: ({ reportId, message }) => ({
        url: `${prefix}/reports/${reportId}/messages`,
        body: { message },
        method: 'POST',
      }),
      invalidatesTags: ['ReportMessage'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useReportsQuery,
  useReportQuery,
  useUpdateReportMutation,
  useCreateReportMutation,
  useReportTypesQuery,
  useCreateReportTypeMutation,
  useUpdateReportTypeMutation,
  useDeleteReportTypeMutation,
  useReportMessagesQuery,
  useCreateReportMessageMutation,
} = extendedApi
