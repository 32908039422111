import i18next from 'i18next'
import PropTypes from 'prop-types'

import { getGenericConfigColumnsCondo } from '@Condo/Utils'

export const InsurancePolicyType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  building: PropTypes.number.isRequired,
  oldBuilding: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  premium: PropTypes.number.isRequired,
  premiumPeriodicity: PropTypes.string.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  cancellationNoticeDays: PropTypes.string.isRequired,
  finalCancellationDate: PropTypes.string,
  lastPremiumPaymentDate: PropTypes.string,
  nextPremiumPaymentDate: PropTypes.string.isRequired,
  ceased: PropTypes.string,
  indexed: PropTypes.string,
  insuredValue: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  lastUpdateUser: PropTypes.string.isRequired,
  status: PropTypes.string,
})

export const InsurancePolicyPaymentsLogsType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  insurance: PropTypes.number.isRequired,
  premiumPaymentDueDate: PropTypes.string.isRequired,
  premiumPaymentEffectiveDate: PropTypes.string,
  amount: PropTypes.string.isRequired,
  user: PropTypes.any.isRequired,
})

export const InsurancePremiumPeriodicity = [
  { id: '1Y', label: i18next.t('condo:insurancePolicy.premiumPeriodicity.1Y') },
  { id: '1M', label: i18next.t('condo:insurancePolicy.premiumPeriodicity.1M') },
  { id: '2M', label: i18next.t('condo:insurancePolicy.premiumPeriodicity.2M') },
  { id: '3M', label: i18next.t('condo:insurancePolicy.premiumPeriodicity.3M') },
  { id: '4M', label: i18next.t('condo:insurancePolicy.premiumPeriodicity.4M') },
  { id: '6M', label: i18next.t('condo:insurancePolicy.premiumPeriodicity.6M') },
]

export const InsuranceIndexType = [
  { id: 'N', label: i18next.t('condo:insurancePolicy.indexed.N') },
  { id: 'Y', label: i18next.t('condo:insurancePolicy.indexed.Y') },
]

export const InsuranceStatusChiusa = {
  id: 'chiusa',
  label: i18next.t('condo:insurancePolicy.status.chiusa'),
}

export const InsuranceStatusScaduta = {
  id: 'scaduta',
  label: i18next.t('condo:insurancePolicy.status.scaduta'),
}

export const InsuranceStatusInScadenza = {
  id: 'in_scadenza',
  label: i18next.t('condo:insurancePolicy.status.in_scadenza'),
}

export const InsuranceStatusInCorso = {
  id: 'in_corso',
  label: i18next.t('condo:insurancePolicy.status.in_corso'),
}

export const InsuranceStatusType = [
  InsuranceStatusScaduta,
  InsuranceStatusInScadenza,
  InsuranceStatusInCorso,
  InsuranceStatusChiusa,
]

export const InsurancePolicy = {
  primaryKey: 'id',
  columns: [
    ...getGenericConfigColumnsCondo(),
    {
      id: 'company',
      label: i18next.t('condo:insurancePolicy.fields.company'),
    },
    {
      id: 'number',
      label: i18next.t('condo:insurancePolicy.fields.number'),
    },
    {
      id: 'premium',
      label: i18next.t('condo:insurancePolicy.fields.premium'),
      currency: true,
    },
    {
      id: 'premiumPeriodicity',
      label: i18next.t('condo:insurancePolicy.fields.premiumPeriodicity'),
      ordering: false,
    },
    {
      id: 'effectiveDate',
      label: i18next.t('condo:insurancePolicy.fields.effectiveDate'),
      date: true,
    },
    {
      id: 'expirationDate',
      label: i18next.t('condo:insurancePolicy.fields.expirationDate'),
      date: true,
    },
    {
      id: 'cancellationNoticeDays',
      label: i18next.t('condo:insurancePolicy.fields.cancellationNoticeDays'),
      numeric: true,
    },
    {
      id: 'finalCancellationDate',
      label: i18next.t('condo:insurancePolicy.fields.finalCancellationDate'),
      date: true,
    },
    {
      id: 'lastPremiumPaymentDate',
      label: i18next.t('condo:insurancePolicy.fields.lastPremiumPaymentDate'),
      date: true,
    },
    {
      id: 'nextPremiumPaymentDate',
      label: i18next.t('condo:insurancePolicy.fields.nextPremiumPaymentDate'),
      date: true,
    },
    {
      id: 'indexed',
      label: i18next.t('condo:insurancePolicy.fields.indexed'),
    },
    {
      id: 'insuredValue',
      label: i18next.t('condo:insurancePolicy.fields.insuredValue'),
      currency: true,
    },
    {
      id: 'notes',
      label: i18next.t('condo:insurancePolicy.fields.notes'),
      longtext: true,
    },
    {
      id: 'lastUpdateUser',
      label: i18next.t('condo:insurancePolicy.fields.lastUpdateUser'),
    },
    {
      id: 'status',
      label: i18next.t('condo:insurancePolicy.fields.status'),
      ordering: false,
    },
    {
      id: 'administratorFullName',
      label: i18next.t('condo:insurancePolicy.fields.administrator.__field'),
      ordering: false,
    },
  ],
}

export const InsurancePolicyPaymentsLogs = {
  primaryKey: 'id',
  columns: [
    {
      id: 'insurance',
      label: i18next.t('condo:insurancePolicy.paymentsLogs.fields.insurance'),
      ordering: false,
    },
    {
      id: 'premiumPaymentDueDate',
      label: i18next.t('condo:insurancePolicy.paymentsLogs.fields.premiumPaymentDueDate'),
      date: true,
      ordering: false,
    },
    {
      id: 'premiumPaymentEffectiveDate',
      label: i18next.t('condo:insurancePolicy.paymentsLogs.fields.premiumPaymentEffectiveDate'),
      datetime: true,
      ordering: false,
    },

    {
      id: 'amount',
      label: i18next.t('condo:insurancePolicy.paymentsLogs.fields.amount'),
      currency: true,
      ordering: false,
    },

    {
      id: 'user',
      label: i18next.t('condo:insurancePolicy.paymentsLogs.fields.user'),
      ordering: false,
    },
  ],
}

export const checkIsClosed = (insurance) => {
  return InsuranceStatusChiusa.id === insurance.status
}

export const checkIsScaduta = (insurance) => {
  return InsuranceStatusScaduta.id === insurance.status
}

export const checkIsInScadenza = (insurance) => {
  return InsuranceStatusInScadenza.id === insurance.status
}

export const checkIsInCorso = (insurance) => {
  return InsuranceStatusInCorso.id === insurance.status
}
