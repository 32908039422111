import { InputAdornment } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setStateFromEvent } from '@Common/Utils/Events'

import Modal from './Modal'
import TextField from './TextField'

const DownloadFileModal = ({ defaultFilename, ext = 'csv', onExport, onClose }) => {
  const { t } = useTranslation()

  const [filename, setFilename] = useState(`${dayjs().format('DD_MM_YYYY-HH_mm')}-${defaultFilename}`)

  const handleOnExport = () => {
    onExport(`${filename}.${ext}`)
  }

  return (
    <Modal
      title={t('common:ui.Export')}
      size="sm"
      onClose={onClose}
      onSubmit={handleOnExport}
      submitLabel={t('common:actions.Export')}
      sx={{ paddingTop: 0 }}
    >
      <TextField
        fullWidth
        InputProps={{ endAdornment: <InputAdornment position="end">.{ext}</InputAdornment> }}
        label={t('common:ui.FileName')}
        value={filename}
        onChange={setStateFromEvent(setFilename)}
      />
    </Modal>
  )
}

DownloadFileModal.defailtProps = {
  ext: 'csv',
}
DownloadFileModal.propTypes = {
  defaultFilename: PropTypes.string.isRequired,
  ext: PropTypes.string,
  onExport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DownloadFileModal
