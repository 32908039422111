import i18next from 'i18next'
import PropTypes from 'prop-types'

import { AnagraphicType } from '@Auth/Models/Anagraphic'
import { EmployeeType } from '@Auth/Models/Employee'
import { BranchType } from '@Branches/Models/Branch'
import { BranchBuildingType } from '@Buildings/Models/BranchBuilding'
import { CondoType } from '@Condo/Models/Condo'
import { RunningTaskActivityType } from '@Tms/Models/RunningTaskActivity'
import { TaskType } from '@Tms/Models/Task'
import { TaskCategoryListItemType } from '@Tms/Models/TaskCategory'
import { TaskFieldChoiceType, TaskFieldType } from '@Tms/Models/TaskField'
import { STATUS_ENUM } from '@Tms/Utils'

const listTask = {
  id: PropTypes.number,
  title: PropTypes.string,
  parent: PropTypes.string,
  category: PropTypes.string,
  assignedTo: EmployeeType,
  deadline: PropTypes.string,
  code: PropTypes.string,
  isCompleted: PropTypes.bool,
  isProcessing: PropTypes.bool,
}

const treeTask = {
  id: PropTypes.number,
  title: PropTypes.string,
  deadline: PropTypes.string,
  status: PropTypes.oneOf(Object.values(STATUS_ENUM)),
  isCompleted: PropTypes.bool,
  isProcessing: PropTypes.bool,
  assignedTo: PropTypes.number,
  priority: PropTypes.number,
}

treeTask.children = PropTypes.arrayOf(PropTypes.shape(treeTask))

export const RunningTaskFieldUtilitiesType = PropTypes.shape({
  customOneBillingCycle: PropTypes.number,
  customOneLastBillDate: PropTypes.string,
  customOneType: PropTypes.string,
  customTwoBillingCycle: PropTypes.number,
  customTwoLastBillDate: PropTypes.string,
  customTwoType: PropTypes.string,
  customThreeBillingCycle: PropTypes.number,
  customThreeLastBillDate: PropTypes.string,
  customThreeType: PropTypes.string,
  powerBillingCycle: PropTypes.number,
  powerLastBillDate: PropTypes.string,
  noGasUtility: PropTypes.bool,
  gasBillingCycle: PropTypes.number,
  gasLastBillDate: PropTypes.string,
  noWaterUtility: PropTypes.bool,
  waterBillingCycle: PropTypes.number,
  waterLastBillDate: PropTypes.string,
})

export const RunningTaskFieldType = PropTypes.shape({
  choice: PropTypes.number,
  choiceObj: TaskFieldChoiceType,
  dateValue: PropTypes.string,
  file: PropTypes.string,
  fileName: PropTypes.string,
  justification: PropTypes.string,
  taskFieldTemplate: PropTypes.number,
  taskFieldTemplateObj: TaskFieldType,
  employee: PropTypes.number,
  employeeObj: EmployeeType,
  resident: PropTypes.string,
  residentObj: AnagraphicType,
  textValue: PropTypes.string,
  urlValue: PropTypes.string,
  buildingId: PropTypes.number,
  buildingType: PropTypes.number,
  buildingObj: PropTypes.oneOfType([BranchBuildingType, CondoType]),
  buildingTypeValue: PropTypes.oneOf(['building', 'branchbuilding']),
  utilities: RunningTaskFieldUtilitiesType,
})

const detailTask = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  code: PropTypes.string,
  priority: PropTypes.number,
  notes: PropTypes.string,
  assignedTo: PropTypes.number,
  assignedToObj: EmployeeType,
  deadline: PropTypes.string,
  isCompleted: PropTypes.bool,
  isProcessing: PropTypes.bool,
  processingAt: PropTypes.string,
  completedAt: PropTypes.string,
  fields: PropTypes.arrayOf(RunningTaskFieldType),
  parent: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  taskFlowObj: PropTypes.shape({
    id: PropTypes.number,
    taskCategory: TaskCategoryListItemType,
    branch: BranchType,
    building: PropTypes.oneOfType([BranchBuildingType, CondoType]),
    buildingType: PropTypes.oneOf(['building', 'branchbuilding']),
    resident: AnagraphicType,
    admin: EmployeeType,
    attachments: PropTypes.array,
    mainRunningTask: PropTypes.shape(treeTask),
    mainRunningTaskTemplate: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
  report: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    code: PropTypes.string,
  }),
  activityLog: PropTypes.arrayOf(RunningTaskActivityType),
  taskTemplateObj: TaskType,
}

export const ListRunningTaskType = PropTypes.shape(listTask)
export const RunningTaskType = PropTypes.shape(detailTask)
export const RunningTreeTaskType = PropTypes.shape(treeTask)

export const RunningTreeTask = {
  primaryKey: 'id',
  columns: [
    {
      id: 'index',
      label: i18next.t('tms:fields.index'),
    },
    {
      id: 'title',
      label: i18next.t('tms:fields.title'),
    },
    {
      id: 'assignedTo',
      label: i18next.t('tms:fields.assignedTo'),
      ordering: false,
    },
    {
      id: 'deadline',
      label: i18next.t('tms:fields.deadline'),
      date: true,
    },
    {
      id: 'status',
      label: i18next.t('tms:fields.status'),
      ordering: false,
    },
    {
      id: 'priority',
      label: i18next.t('tms:fields.priority.__field'),
    },
  ],
}

export default {
  primaryKey: 'code',
  columns: [
    {
      id: 'code',
      label: i18next.t('tms:fields.code'),
    },
    {
      id: 'title',
      label: i18next.t('tms:fields.title'),
    },
    {
      id: 'parent',
      label: i18next.t('tms:fields.parentTask'),
    },
    {
      id: 'category',
      label: i18next.t('tms:fields.category'),
    },
    {
      id: 'assignedTo',
      label: i18next.t('tms:fields.assignedTo'),
      ordering: false,
    },
    {
      id: 'deadline',
      label: i18next.t('tms:fields.deadline'),
      date: true,
    },
    {
      id: 'created',
      label: i18next.t('tms:fields.openedAt'),
      date: true,
    },
    {
      id: 'processingAt',
      label: i18next.t('tms:fields.processingAt'),
      date: true,
    },
    {
      id: 'completedAt',
      label: i18next.t('tms:fields.completedAt'),
      date: true,
    },
    {
      id: 'status',
      label: i18next.t('tms:fields.status'),
    },
    {
      id: 'priority',
      label: i18next.t('tms:fields.priority.__field'),
    },
    {
      id: 'building',
      label: i18next.t('tms:fields.building'),
      emptyValue: '-',
      ordering: false,
    },
  ],
}
