import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import config from '@Config'

import getStorage from '../../../Common/Services/Storage'
import { useCurrentUser } from '../../Utils/Hooks'
import Loader from '../Loader'
import { Cursor } from '../Styles'
import ExportAction from './ExportAction'
import SettingsDialog from './SettingsDialog'
import { SelectAll, SelectAllLink, SettingsIcon, Toolbar, Tr } from './Styled'
import { createColumnsProps, getDisplayValue, getValue } from './Utils'

const storageService = getStorage()

const MuiDataTable = ({
  name,
  autoWidth,
  data,
  idField,
  selectable,
  selectOnRowClick,
  selected,
  onSelect,
  model,
  defaultListPerPage,
  listDisplay,
  fieldsMapping,
  searchFields,
  sortField,
  sortDirection,
  actions,
  bulkActions,
  onAction,
  noSettings,
  noPagination,
  noToolbar,
  noExport,
  onExpandRow,
  disableSorting,
  disableCheckbox,
  avoidSorting,
  loading,
  children,
  customEmptyText,
  noHeader,
}) => {
  const { t } = useTranslation()

  const storageKey = `${name}_DATA_TABLE_SETTINGS`
  // check storage for invalidation
  if (config.localStorageInvalidation[storageKey] && storageService.get(storageKey)) {
    const storageTimestamp = storageService.getNested(storageKey, 'timestamp', 0)
    if (storageTimestamp < config.localStorageInvalidation[storageKey]) {
      storageService.remove(storageKey)
      toast.warn(t('common:ui.ForceResetDatatableSettingsWarning'))
    }
  }

  // logged user
  const user = useCurrentUser()
  // primary key
  const primaryKeyValue = (item) =>
    idField
      .split(',')
      .map((f) => item[f])
      .join('-')

  // clean selected when data change
  const previousData = useRef()
  React.useEffect(() => {
    if (selected.length && previousData.current !== undefined && previousData.current.length > 0) {
      onSelect([])
    }
    previousData.current = data
  }, [data])

  // collapsable
  const [openRow, setOpenRow] = React.useState(null)

  // list per page
  const [listPerPage, setListPerPage] = React.useState(
    storageService.getNested(storageKey, 'listPerPage', defaultListPerPage),
  )

  // columns
  const [columnsProps, setColumnProps] = React.useState([])
  React.useEffect(() => {
    setColumnProps(
      R.ifElse(
        R.isNil,
        () => createColumnsProps(model.columns, listDisplay),
        R.identity,
      )(storageService.getNested(storageKey, 'columns')),
    )
  }, [storageKey, listDisplay])

  // columns selection
  const handleSelectColumn = (id) => (event) => {
    setColumnProps(
      columnsProps.map((column) =>
        R.ifElse(
          R.equals(id),
          R.always({ ...column, visible: R.prop('checked')(event.target) }),
          R.always(column),
        )(column.id),
      ),
    )
  }

  // settings
  const saveSettings = () => {
    storageService.save(storageKey, {
      timestamp: new Date().getTime(),
      columns: columnsProps,
      listPerPage,
    })
    setSettingsDialogIsOpen(false)
  }

  const resetSettings = () => {
    storageService.remove(storageKey)
    setColumnProps(createColumnsProps(model.columns, listDisplay))
    setListPerPage(defaultListPerPage)
    setSettingsDialogIsOpen(false)
  }

  // columns to be displayed
  const displayColumns = columnsProps.filter((c) => c.visible)

  // settings
  const [settingsDialogIsOpen, setSettingsDialogIsOpen] = React.useState(false)
  const handleOpenSettings = React.useCallback(() => setSettingsDialogIsOpen(true), [setSettingsDialogIsOpen])

  // selection
  const selectedIds = selected?.map((item) => primaryKeyValue(item))
  // page selection
  const handleSelectPage = (event) =>
    onSelect(R.ifElse(R.prop('checked'), R.always(displayData.map((r) => r)), R.always([]))(event.target))
  // record selection
  const handleSelectRecord = (record) => (event) =>
    onSelect(
      R.ifElse(
        R.prop('checked'),
        R.always([...selected, record]),
        R.always(selected.filter(R.compose(R.not, R.equals(primaryKeyValue(record)), primaryKeyValue))),
      )(event.target),
      record,
      event.target.checked,
    )
  // all items selection
  const handleSelectAll = () => onSelect(data.map((record) => record))
  // clear selection
  const handleClearSelection = () => onSelect([])

  // search
  const [searchFilter, setSearchFilter] = React.useState('')
  const handleSearchFilter = R.pipe(R.prop('target'), R.prop('value'), setSearchFilter)

  // sorting
  const [sort, setSort] = React.useState({ field: sortField, direction: sortDirection })
  const handleSortChange = (field) => () =>
    setSort({ field, direction: sort.field === field && sort.direction === 'asc' ? 'desc' : 'asc' })

  // sort filter and paginate
  // sort
  const sortedData = avoidSorting
    ? data
    : [...data].sort((a, b) => {
        return R.ifElse(R.isNil, R.always(1), () =>
          R.ifElse(
            R.isNil,
            R.always(-1),
            () => (getValue(b, sort.field) < getValue(a, sort.field) ? 1 : -1) * (sort.direction === 'asc' ? 1 : -1),
          )(getValue(b, sort.field)),
        )(getValue(a, sort.field))
      })
  // filter
  const filteredData = sortedData.filter((record) => {
    if (R.isEmpty(searchFilter)) return true
    return R.pipe(
      R.filter(
        R.pipe(R.split('.'), R.flip(R.path)(record), R.defaultTo(''), R.toLower, R.includes(R.toLower(searchFilter))),
      ),
      R.length,
      R.flip(R.gt)(0),
    )(searchFields)
  })

  // pagination
  const [page, setPage] = React.useState(0)
  // reset on data change
  React.useEffect(() => setPage(0), [filteredData.length, listPerPage])
  const displayData = noPagination ? filteredData : filteredData.slice(page * listPerPage, (page + 1) * listPerPage)

  // selection
  const enabledActions =
    actions && actions.length ? actions.filter((action) => !action.perm || action.perm(user, selected[0])) : []

  const enabledBulkActions =
    bulkActions && bulkActions.length
      ? bulkActions.filter((action) => !action.perm || action.perm(user, selected[0]))
      : []
  const enableSelection =
    selectable === false ? false : selectable || !!enabledActions.length || !!enabledBulkActions.length

  // col span calculation
  const colSpan = displayColumns.length + (enableSelection ? 1 : 0) + (onExpandRow ? 1 : 0)

  // search tooltip
  const searchPlaceholder = t('common:ui.SearchFields', {
    fields: columnsProps
      .filter(R.compose(R.not, R.equals(-1), R.flip(R.indexOf)(searchFields), R.prop('id')))
      .map(R.pipe(R.prop('label'), R.toLower))
      .join(', '),
  })

  const handleAction =
    (action, bulk = false) =>
    () => {
      onAction(action.id, bulk ? undefined : selected[0])
      // onSelect([])
    }

  const getTooltip = (action) => {
    if (typeof action.tooltip === 'string') return action.tooltip
    if (typeof action.tooltip === 'function') return action.tooltip(selected[0])
    return ''
  }

  return (
    <>
      {(children || !!searchFields.length) && (
        <Box justify="space-between" direction="row" margin="0 0 1rem">
          {!!searchFields.length && (
            <Tooltip title={searchPlaceholder}>
              <div>
                <TextField
                  label={t('common:ui.SearchPlaceholder')}
                  value={searchFilter}
                  onChange={handleSearchFilter}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Tooltip>
          )}
          {children}
        </Box>
      )}
      {!noToolbar && (enableSelection || !noSettings || !noExport) && (
        <Toolbar>
          <Box direction="row">
            {enableSelection && (
              <Typography component="div">
                {t('common:ui:numSelected', { count: selected.length, total: filteredData.length })}
              </Typography>
            )}
            {enableSelection && selected.length === displayData.length && selected.length !== data.length && (
              <SelectAll component="div">
                <SelectAllLink onClick={handleSelectAll}>{t('common:ui:SelectAll')}</SelectAllLink>
              </SelectAll>
            )}
            {enableSelection && !!selected.length && (
              <SelectAll component="div">
                <SelectAllLink onClick={handleClearSelection}>{t('common:ui:ClearSelection')}</SelectAllLink>
              </SelectAll>
            )}
          </Box>
          <Box direction="row" margin="0 0 0 1rem">
            {selected.length === 1 &&
              enabledActions
                .filter((action) => !action.cond || action.cond(selected[0]))
                .map((action) => {
                  /**
                   * we need to wrap Button in a span element
                   * otherwise when the button is disabled,
                   *  the tooltip doesn't appear
                   *  @see: https://mui.com/material-ui/react-tooltip/#disabled-elements
                   */
                  return (
                    <Tooltip key={action.id} title={getTooltip(action)}>
                      <span>
                        <Button
                          size="small"
                          disableElevation
                          variant="contained"
                          key={action.id}
                          onClick={handleAction(action)}
                          sx={{ marginLeft: '.5rem' }}
                          startIcon={action.icon}
                          disabled={action?.disabled && action.disabled(selected[0])}
                        >
                          {action.label}
                        </Button>
                      </span>
                    </Tooltip>
                  )
                })}
            {selected.length > 1 &&
              enabledBulkActions
                .filter((action) => !action.cond || action.cond(selected[0]))
                .map((action) => {
                  /**
                   * we need to wrap Button in a span element
                   * otherwise when the button is disabled,
                   *  the tooltip doesn't appear
                   *  @see: https://mui.com/material-ui/react-tooltip/#disabled-elements
                   */
                  return (
                    <Tooltip key={action.id} title={getTooltip(action)}>
                      <span>
                        <Button
                          size="small"
                          disableElevation
                          variant="contained"
                          key={action.id}
                          onClick={handleAction(action)}
                          sx={{ marginLeft: '.5rem' }}
                          startIcon={action.icon}
                          disabled={action?.disabled && action.disabled(selected[0])}
                        >
                          {action.label}
                        </Button>
                      </span>
                    </Tooltip>
                  )
                })}
          </Box>
          <Box direction="row" margin="0 0 0 auto">
            {!noExport && (
              <ExportAction
                name={name}
                displayColumns={displayColumns}
                sortedData={sortedData}
                fieldsMapping={fieldsMapping}
                marginRight={noSettings ? 0 : '.5rem'}
              />
            )}
            {!noSettings && (
              <Cursor>
                <Tooltip title={t('common:ui.Settings')}>
                  <SettingsIcon onClick={handleOpenSettings} />
                </Tooltip>
              </Cursor>
            )}
          </Box>
        </Toolbar>
      )}
      <TableContainer style={{ maxWidth: '100%', overflow: 'auto' }}>
        <Table style={autoWidth ? { width: 'auto' } : {}} size="small">
          {!noHeader && (
            <TableHead key="thead">
              <TableRow key="thead-row">
                {enableSelection && (
                  <TableCell padding="checkbox" style={{ width: '20px' }} key="col-selection">
                    {(selectable || !!bulkActions.length) && (
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < displayData.count}
                        checked={
                          displayData.length !== 0 &&
                          displayData.filter((item) => selectedIds.indexOf(primaryKeyValue(item)) === -1).length === 0
                        }
                        onChange={handleSelectPage}
                      />
                    )}
                  </TableCell>
                )}
                {displayColumns.map((column) => {
                  return (
                    <TableCell
                      key={`col-${column.id}`}
                      align={column.numeric || column.currency ? 'right' : column.centered ? 'center' : 'left'}
                      sortDirection={sort.field === column.id ? sort.direction : false}
                      style={{ width: column.width ? column.width : 'auto' }}
                    >
                      {disableSorting.indexOf(column.id) !== -1 || avoidSorting ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          active={sort.field === column.id}
                          direction={sort.field === column.id ? sort.direction : 'asc'}
                          onClick={handleSortChange(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  )
                })}
                {onExpandRow && <TableCell key="col-collapse" style={{ width: '30px' }} />}
              </TableRow>
            </TableHead>
          )}
          {!loading && (
            <TableBody key="tbody">
              {displayData.map((record, index) => {
                const isSelected =
                  selected?.filter(R.compose(R.equals(primaryKeyValue(record)), primaryKeyValue)).length === 1
                return (
                  <React.Fragment key={`row-${index}`}>
                    <Tr
                      data-no-border={!!onExpandRow}
                      $even={index % 2 === 0}
                      onClick={() => {
                        return selectOnRowClick
                          ? handleSelectRecord(record)({ target: { checked: !isSelected } })
                          : null
                      }}
                    >
                      {(enableSelection || selectable) && (
                        <TableCell padding="checkbox">
                          {((enableSelection && (typeof selectable !== 'function' || selectable(record))) ||
                            (selectable && (selectable === true || selectable(record)))) && (
                            <Checkbox
                              checked={isSelected}
                              onChange={handleSelectRecord(record)}
                              disabled={disableCheckbox(record)}
                            />
                          )}
                        </TableCell>
                      )}
                      {displayColumns.map((column) => {
                        return (
                          <TableCell
                            key={`col-${column.id}`}
                            align={column.numeric || column.currency ? 'right' : column.centered ? 'center' : 'left'}
                          >
                            {getDisplayValue(fieldsMapping, record, column.id, column, index)}
                          </TableCell>
                        )
                      })}
                      {onExpandRow && (
                        <TableCell align="right" key={`col-expand-${index}`}>
                          <IconButton size="small" onClick={() => setOpenRow(openRow === index ? null : index)}>
                            {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                      )}
                    </Tr>
                    {onExpandRow && (
                      <TableRow key={`expand-${index}`}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                          <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                            {onExpandRow(record)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
              {displayData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={colSpan} align="center">
                    <Typography color="dark-4">{customEmptyText || t('common:ui.NoResults')}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading && <Loader minHeight="100px" />}
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={[listPerPage]}
          component="div"
          count={filteredData.length}
          rowsPerPage={listPerPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
        />
      )}
      {settingsDialogIsOpen && (
        <SettingsDialog
          columns={columnsProps}
          open={settingsDialogIsOpen}
          handleClose={() => setSettingsDialogIsOpen(false)}
          handleReset={resetSettings}
          handleSaveAndClose={saveSettings}
          onSelect={handleSelectColumn}
          onSort={setColumnProps}
          listPerPage={listPerPage}
          onListPerPageChange={setListPerPage}
          noPagination={noPagination}
        />
      )}
    </>
  )
}

MuiDataTable.defaultProps = {
  idField: 'id',
  autoWidth: false,
  sortDirection: 'asc',
  fieldsMapping: {},
  selected: [],
  actions: [],
  bulkActions: [],
  searchFields: [],
  loading: false,
  defaultListPerPage: 10,
  disableSorting: [],
  disableCheckbox: R.always(false),
  customEmptyText: null,
}

MuiDataTable.propTypes = {
  name: PropTypes.string.isRequired,
  autoWidth: PropTypes.bool,
  idField: PropTypes.string,
  data: PropTypes.array.isRequired,
  selected: PropTypes.array,
  selectOnRowClick: PropTypes.bool,
  onSelect: PropTypes.func,
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  model: PropTypes.object.isRequired,
  listDisplay: PropTypes.array.isRequired,
  searchFields: PropTypes.array,
  defaultListPerPage: PropTypes.number,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  avoidSorting: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  onAction: PropTypes.func,
  noExport: PropTypes.bool,
  noToolbar: PropTypes.bool,
  noSettings: PropTypes.bool,
  noPagination: PropTypes.bool,
  noBulkSelection: PropTypes.bool,
  fieldsMapping: PropTypes.object,
  onExpandRow: PropTypes.func,
  disableSorting: PropTypes.arrayOf(PropTypes.string),
  disableCheckbox: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  customEmptyText: PropTypes.string,
  noHeader: PropTypes.bool,
}

export default React.memo(MuiDataTable)
