import * as React from 'react'

const ContractsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-40 -50 250 300"
    style={{
      enableBackground: 'new 0 0 177 200',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M194.8 37.8 56.7 70.3c-5.3 1.2-9.1 5.5-10.1 10.6-.5 1.9-.5 3.9 0 5.8L86.8 259c1.7 7.2 9.1 11.8 16.3 10.1l138.1-32.8c1.9-.5 3.6-1.2 5-2.4 4.1-3.1 6.2-8.4 5-14L211.1 47.7c-1.7-7.2-9.1-11.6-16.3-9.9zm24.6 175.9-107 25.3c-3.6 1-7.2-1.4-8.1-5.1s1.4-7.2 5-8.2l107-25.3c3.6-1 7.2 1.4 8.1 5.1.8 3.6-1.4 7.3-5 8.2zm-7.9-33.2-107 25.3c-3.6 1-7.2-1.4-8.1-5.1-1-3.6 1.4-7.2 5-8.2l107-25.3c3.6-1 7.2 1.4 8.1 5.1 1 3.8-1.1 7.4-5 8.2zm-7.6-33-107 25.3c-3.6 1-7.2-1.4-8.1-5.1-1-3.6 1.4-7.2 5-8.2l107-25.3c3.6-1 7.2 1.4 8.1 5.1 1 3.6-1.4 7.2-5 8.2zm-7.7-33.3-107 25.3c-3.6 1-7.2-1.4-8.1-5.1-1-3.6 1.4-7.2 5-8.2l107-25.3c3.6-1 7.2 1.4 8.1 5.1 1 3.9-1.4 7.5-5 8.2zm-7.6-33-107 25.3c-3.6 1-7.2-1.4-8.1-5.1-1-3.6 1.4-7.2 5-8.2l107-25.3c3.6-1 7.2 1.4 8.1 5.1.7 3.6-1.4 7.2-5 8.2z"
      style={{
        fill: '#fcbd2e',
      }}
      transform="matrix(.86193 0 0 .86192 -39.87 -32.28)"
    />
  </svg>
)

export default ContractsIcon
