import i18next from 'i18next'
import PropTypes from 'prop-types'

export const TaskFieldChoiceType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.any,
  isJustificationRequired: PropTypes.bool,
  notesText: PropTypes.string,
})

export const TaskFieldChoice = {
  primaryKey: 'key',
  columns: [
    {
      id: 'value',
      label: i18next.t('tms:fields.choiceValue'),
    },
    {
      id: 'isJustificationRequired',
      label: i18next.t('tms:fields.isJustificationRequired'),
    },
    {
      id: 'notesText',
      label: i18next.t('tms:fields.notesText'),
    },
  ],
}

export const TaskFieldType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  isInternal: PropTypes.bool,
  isRequired: PropTypes.bool,
  requiredType: PropTypes.oneOf([0, 1]),
  helperText: PropTypes.string,
  isDateInPast: PropTypes.bool,
  isDateInFuture: PropTypes.bool,
  sendCalendar: PropTypes.bool,
  created: PropTypes.string,
  choices: PropTypes.arrayOf(TaskFieldChoiceType),
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'name',
      label: i18next.t('tms:fields.name'),
    },
    {
      id: 'type',
      label: i18next.t('tms:fields.type.__field'),
    },
    {
      id: 'isInternal',
      label: i18next.t('tms:fields.isInternal'),
      boolean: true,
    },
    {
      id: 'isRequired',
      label: i18next.t('tms:fields.isRequired.__field'),
      boolean: true
    },
    {
      id: 'requiredType',
      label: i18next.t('tms:fields.requiredType'),
    },
    {
      id: 'created',
      label: i18next.t('tms:fields.created'),
      date: true,
    },
  ],
}
