import PropTypes from 'prop-types'
import { Person, Email, LocalPhone, Fax, PhoneAndroid, SupportAgent, Edit } from '@mui/icons-material'
import { Avatar, Card, CardHeader, IconButton } from '@mui/material'

const iconMap = {
  0: Person,
  10: LocalPhone,
  20: Email,
  30: Email,
  40: Fax,
  50: PhoneAndroid,
  60: SupportAgent,
}

const ContactCard = ({ contact, onEdit }) => {
  const Icon = iconMap[contact.tipo.id]
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <Icon />
          </Avatar>
        }
        title={contact.riferimento}
        action={onEdit ? 
          <IconButton onClick={onEdit}>
            <Edit />
          </IconButton> : null
        }
      />
    </Card>
  )
}

ContactCard.propTypes = {
  contact: PropTypes.shape({
    riferimento: PropTypes.string,
    tipo: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  onEdit: PropTypes.func,
}

export default ContactCard
