import WebSocket from '@Common/Components/WebSocket'
import Logger from '@Common/Utils/Logger'
import config from '@Config'
import { api } from '@Core/Services/Api'
import { useDispatch } from 'react-redux'


// @TODO use progress?
const NotificationsWebSocket = () => {
  const dispatch = useDispatch()

  const handleError = (err) => {
    Logger.error('WS error', err)
  }

  const handleMessage = ({ message }) => {
    Logger.debug('WS message', message)
    if (message?.event === 'complete') {
      dispatch(api.util.invalidateTags(['Notification']))
    }
  }

  return (
    <WebSocket
      name="notifications"
      path={config.wsUrls.notifications}
      onError={handleError}
      onMessage={handleMessage}
    />
  )
}

export default NotificationsWebSocket
