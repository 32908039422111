import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import TaskIcon from '@mui/icons-material/Task'
import { Button, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { makePath } from '@Config'
import { selectPathname } from '@Core/Redux/Store'
import { selectThemeMode } from '@Core/Redux/Ui'
import { selectActiveHousingUnitId } from '@MyEstia/Redux'

const myHomePathRegex = /^\/my-estia\/([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12})(\/(.*))?$/su

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: ${({ mobile, pathname }) => (mobile ? (myHomePathRegex.test(pathname) ? '160px' : '20px') : '40px')};
  right: ${({ mobile }) => (mobile ? '10px' : '40px')};
  border-radius: 35px;
  cursor: pointer;
  z-index: 99;

  ${({ theme, ...props }) => css`
    background-color: ${theme.palette.primary.main};
    ${props.open
      ? `
        padding-left: 15px;
        padding-right: 5px;
        padding-top: 30px;
        padding-bottom: 5px;
        width: 200px;
        justify-content: flex-end;
        align-items: end;
    `
      : `
        padding: 15px;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        `}
  `}
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 17px solid ${({ theme }) => theme.palette.primary.main};
    border-top: 15px solid ${({ theme }) => theme.palette.primary.main};
    border-bottom: 18px solid transparent;
    right: 38px;
    bottom: -15px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 40px;
    width: 10px;
    background: ${({ theme }) => theme.palette.primary.main};
    bottom: -12px;
    right: 48px;
    border-radius: 50%;
    -webkit-transform: rotate(150deg);
    transform: rotate(140deg);
  }
`

const HelpButton = styled(Button)`
  padding: 3px 12px 3px 12px;
  min-height: 50px;
  width: 100%;
  text-transform: none;
  margin-bottom: 20px;
  border-radius: 20px;
  ${({ theme }) => css`
    background-color: transparent;
    color: ${theme.palette.accentFive.light};
    text-align: left;
  `}
`

const CustomerCare = (props) => {
  const themeMode = useSelector(selectThemeMode)
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  const navigate = useNavigate()
  const pathname = useSelector(selectPathname)
  const housingUnitId = useSelector(selectActiveHousingUnitId)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.setOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  return (
    <Wrapper
      themeMode={themeMode}
      onClick={() => props.setOpen(!props.open)}
      open={props.open}
      className="btm-right-in"
      ref={wrapperRef}
      mobile={isSmallDevice}
      pathname={pathname.toString()}
    >
      {props.open && (
        <>
          <HelpButton onClick={() => navigate(makePath('myEstia.myHome.ticket.new', { housingUnitId }))}>
            <SupportAgentIcon sx={{ width: '50px', height: '50px', marginRight: '10px', color: 'accentFive.light' }} />
            <Typography fontWeight="bold">{t('tickets:newTicket.main')}</Typography>
          </HelpButton>
          <HelpButton onClick={() => navigate(makePath('myEstia.myHome.ticket.list', { housingUnitId }))}>
            <TaskIcon sx={{ width: '50px', height: '50px', marginRight: '10px', color: 'accentFive.light' }} />
            <Typography fontWeight="bold">{t('tickets:ticketList.main')}</Typography>
          </HelpButton>
        </>
      )}
      <SupportAgentIcon sx={{ width: '60px', height: '60px', color: 'blackWhite.dark' }} />
    </Wrapper>
  )
}

CustomerCare.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default CustomerCare
