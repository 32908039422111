import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { NewsPermissions } from '../Permissions'
import { useNewsQuery } from '../Services/Api'
import { useConfirm } from '@Common/Utils/Hooks'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import News from '../Models/News'

const DISPLAY_COLUMNS = [
  'title',
  'date',
  'featured',
  'published',
]

const SEARCH_FIELDS = ['title']

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    qs.qsAdditions.title__icontains = qs.qsAdditions.search
    delete qs.qsAdditions.search
  }

  return qs
}

const NewsList = ({ onEdit, onDelete }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-news'),
      offset: 0,
      ordering: '-date',
    },
  })

  const refreshData = React.useCallback((data) => {
    setQs(data)
  }, [setQs])

  // new qs triggers refetch
  const { data, isFetching } = useNewsQuery(convertSearchQuery(qs))

  const handleDeleteNews = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteNews)
  const bulkActions = React.useMemo(() => [{ id: 'DELETE', label: t('news:ui.DeleteSelectedNews'), perm: NewsPermissions.delete }], [])
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: NewsPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: NewsPermissions.delete,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, news) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('news:ui.DeleteNewsConfirmationTitle'),
          t('news:ui.DeleteNewsConfirmationText', { count: selected.length }),
          { selected: selected, field: 'title' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(news)
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      <DataTable
        name="admin-news"
        qs={qs}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={News}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        bulkActions={bulkActions}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      />
      {DeleteConfirm}
    </div>
  )
}

NewsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default NewsList
