import React from 'react'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { getEmailFromContacts, getPhoneFromContacts } from '@Buildings/Utils'
import { useBuildingRepresentativesQuery } from '../Services/Api'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import Representative from '@Buildings/Models/Representative'

const DISPLAY_COLUMNS = [
  'tipo.displayValue',
  'fullName',
  'email',
  'phone',
]

const FIELDS_MAPPING = {
  fullName: R.compose(R.join(' '), R.paths([['anagrafica', 'nome'], ['anagrafica', 'cognome']])),
  email: representative => getEmailFromContacts(representative.anagrafica.contatti),
  phone: representative => getPhoneFromContacts(representative.anagrafica.contatti),
}

const listName = 'building-representatives'

const RepresentativesList = ({ buildingArcadiaId }) => {
  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage(listName),
      offset: 0,
      ordering: 'anagrafica.tipo',
    },
  })
  const refreshData = React.useCallback((data) => {
    setQs(data)
  }, [setQs])

  // new qs triggers refetch
  const { data, isLoading } = useBuildingRepresentativesQuery({ buildingArcadiaId, qs })

  return (
    <DataTable
      name={listName}
      qs={qs}
      sortable={false}
      data={data?.results || []}
      dataCount={data?.count || 0}
      refreshData={refreshData}
      model={Representative}
      listDisplay={DISPLAY_COLUMNS}
      fieldsMapping={FIELDS_MAPPING}
      searchFields={[]}
      sortField="anagrafica.tipo"
      loading={isLoading}
      noBulkSelection
      noSettings
      noExport
    />
  )
}

RepresentativesList.propTypes = {
  buildingArcadiaId: PropTypes.string.isRequired,
}

export default RepresentativesList
