import { ForwardToInbox } from '@mui/icons-material'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Checkbox from '@Common/Components/Checkbox'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Dropdown from '@Common/Components/Dropdown'
import { TextNoWrap } from '@Common/Components/Styles'
import { setStateFromCheckboxEvent, setStateFromEvent } from '@Common/Utils/Events'
import { camelToSnakeCase } from '@Common/Utils/Strings'

import { INVITATION_RETRY_STATUSES, INVITATION_STATUSES } from '../Costants'
import Invitation from '../Models/Invitation'
import { useInvitationsQuery } from '../Services/Api'

const FilterContainer = styled.div`
  padding-left: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  
  > * {
    padding-right: 1rem;
  }
`

const DISPLAY_COLUMNS = [
  'id',
  'firstName',
  'lastName',
  'fiscalCode',
  'businessName',
  'vatNumber',
  'status',
  'emailsObjs',
  'notes',
  'immobili',
  'created',
]

const SEARCH_FIELDS = ['firstName', 'lastName', 'fiscalCode', 'businessName', 'vatNumber']

const statusOptions = R.map(
  (id) => ({ id, label: i18next.t(`auth:fields.status.${INVITATION_STATUSES[id]}`) }),
  R.keys(INVITATION_STATUSES),
)

const FIELDS_MAPPING = {
  status: (r) => i18next.t(`auth:fields.status.${INVITATION_STATUSES[r.status]}`),
  emailsObjs: R.pipe(R.prop('emailsObjs'), R.join(' - ')),
  immobili: (record) => record.raw.immobili.map(R.prop('denominazione')).join(', '),
}

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${camelToSnakeCase(field)}__icontains__or`] = qs.qsAdditions.search
    })
    delete qs.qsAdditions.search
  }

  if (qs.qsAdditions.statusFilter) {
    qs.qsAdditions.status = qs.qsAdditions.statusFilter
  }
  if (qs.qsAdditions.hideActiveAccounts) {
    qs.qsAdditions.status__neq = R.invertObj(INVITATION_STATUSES).INVITATION_SUCCESS
  }
  delete qs.qsAdditions.statusFilter
  delete qs.qsAdditions.hideActiveAccounts

  return qs
}

const InvitationsList = ({ onRetryInvitation }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [statusFilter, setStatusFilter] = React.useState(null)
  const [hideActiveAccounts, setHideActiveAccounts] = React.useState(false)

  const qsAdditions = React.useMemo(
    () => ({
      statusFilter,
      hideActiveAccounts,
    }),
    [statusFilter, hideActiveAccounts],
  )

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-invitations'),
      offset: 0,
      ordering: '-id',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useInvitationsQuery(convertSearchQuery(qs))

  const bulkActions = React.useMemo(
    () => [
      {
        id: 'RETRY_INVITE',
        label: t('auth:actions.RetryInviteSelected'),
        icon: <ForwardToInbox size="small" />,
      },
    ],
    [],
  )

  const actions = React.useMemo(
    () => [
      {
        id: 'RETRY_INVITE',
        label: t('auth:actions.RetryInvite'),
        icon: <ForwardToInbox size="small" />,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(() => onRetryInvitation(selected), [selected])

  const checkSelectable = R.pipe(
    R.prop('status'),
    R.flip(R.includes)(R.map(parseInt, R.keys(INVITATION_RETRY_STATUSES))),
  )

  return (
    <div>
      <DataTable
        name="admin-invitations"
        selectable={checkSelectable}
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Invitation}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        fieldsMapping={FIELDS_MAPPING}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        bulkActions={bulkActions}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      >
        <FilterContainer>
          <Dropdown
            width="170px"
            options={statusOptions}
            labelKey="label"
            valueKey="id"
            onChange={setStateFromEvent(setStatusFilter)}
            value={statusFilter}
            label={t('auth:fields.status.__field')}
          />
          <Checkbox
            checked={hideActiveAccounts}
            onChange={setStateFromCheckboxEvent(setHideActiveAccounts)}
            label={<TextNoWrap>{t('auth:ui.HideActiveAccounts')}</TextNoWrap>}
          />
        </FilterContainer>
      </DataTable>
    </div>
  )
}

InvitationsList.propTypes = {
  onRetryInvitation: PropTypes.func.isRequired,
}

export default InvitationsList
