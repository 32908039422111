import { Box as MuiBox, Button, FormControl, Tab, Tabs } from '@mui/material'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EmployeeByRoleSelect from '@Auth/Components/EmployeeByRoleSelect'
import { ROLES } from '@Auth/Costants'
import JuniorAdminsList from '@Branches/Forms/BranchForm/JuniorAdminsList'
import SeniorAdminsList from '@Branches/Forms/BranchForm/SeniorAdminsList'
import { BranchPermissions } from '@Branches/Permissions'
import Alert from '@Common/Components/Alert'
import Checkbox from '@Common/Components/Checkbox'
import DatePicker from '@Common/Components/DatePicker'
import CitySelect from '@Common/Components/Location/CitySelect'
import ProvinceSelect from '@Common/Components/Location/ProvinceSelect'
import RegionSelect from '@Common/Components/Location/RegionSelect'
import Modal from '@Common/Components/Modal'
import { Box, Margin } from '@Common/Components/Styles'
import { TabPanel } from '@Common/Components/TabPanel'
import TextField from '@Common/Components/TextField'
import { useCitiesQuery } from '@Common/Services/Api/LocationApi'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { fetchGeoLocation } from '@Common/Utils/Geolocation'
import { useCurrentUser, useForm } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import logger from '@Common/Utils/Logger'
import { apiList } from '@Core/Services/Api'

import MapField from '../../../Common/Components/MapField'
import { BranchType } from '../../Models/Branch'
import { getErroredFieldTab, validate } from './Validation'
import Loader from '@Common/Components/Loader'

const emptyArray = []

const BranchForm = ({ branch, onClose, onSave }) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const title = t(`branches:ui.${branch?.id ? 'Edit' : 'Create'}Branch`)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { data: cities } = apiList(useCitiesQuery())

  const [selectedSeniorAdmins, setSelectedSeniorAdmins] = React.useState(branch?.seniorAdministrativesObjs || [])
  const [selectedJuniorAdmins, setSelectedJuniorAdmins] = React.useState(branch?.juniorAdministrativesObjs || [])

  React.useEffect(() => logger.debug('branch', branch), [branch])

  const { fields, setField, setFields, errors, setErrors } = useForm(
    {
      name: R.defaultTo('', branch?.name),
      code: R.defaultTo('', branch?.code),
      phone: R.defaultTo('', branch?.phone),
      email: R.defaultTo('', branch?.email),
      lat: R.defaultTo(null, branch?.lat),
      lng: R.defaultTo(null, branch?.lng),
      active: R.defaultTo(true, branch?.active),
      activationDate: R.defaultTo(null, branch?.activationDate),
      manager: R.defaultTo('', branch?.manager), // user id
      administrative: R.defaultTo('', branch?.administrative), // user id
      secondaryAdministrative: R.defaultTo('', branch?.secondaryAdministrative), // user id
      city: R.defaultTo('', branch?.city),
      province: R.defaultTo('', branch?.cityObj?.province),
      region: R.defaultTo('', branch?.cityObj?.provinceObj.region),
      address: R.defaultTo('', branch?.address),
      houseNumber: R.defaultTo('', branch?.houseNumber),
      cap: R.defaultTo('', branch?.cap),
      locality: R.defaultTo('', branch?.locality),
      internalNumber: R.defaultTo('', branch?.internalNumber),
    },
    true,
  )

  const handleRegionChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('region'),
    R.mergeRight({ province: '', city: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const handleProvinceChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('province'),
    R.mergeRight({ city: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const handleActiveTabIndex = () => {
    if (errors && R.keys(errors).length) setActiveTabIndex(getErroredFieldTab(errors))
  }

  const submit = async () => {
    logger.debug('Branch form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('Branch form submission, validation passed, saving')
      setIsSubmitting(true)
      const branch = buildBranchPayload(fields)
      const { error, isSuccessful } = await onSave(branch)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('Branch form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('Branch form submission, validation failed')
    }
  }

  const buildBranchPayload = (fields) => {
    const payload = {
      ...fields,
      seniorAdministratives: R.map(R.prop('id'), selectedSeniorAdmins),
      juniorAdministratives: R.map(R.prop('id'), selectedJuniorAdmins),
      activationDate: fields.activationDate ? moment(fields.activationDate).format('YYYY-MM-DD') : null,
    }

    if (branch) {
      // we are editing an existing user
      return { ...branch, ...payload }
    }

    return payload
  }

  const handleTabChange = (_, idx) => {
    setActiveTabIndex(idx)
  }
  useEffect(handleActiveTabIndex, [errors])

  const handleCalculateLatLng = async () => {
    const cityName = R.prop('name', R.find(R.propEq('id', fields.city), cities))

    const address = R.pipe(
      R.pick(['address', 'houseNumber', 'city', 'cap']),
      R.modify('city', R.always(cityName)),
      R.values,
      R.filter(R.compose(R.not, R.either(R.isEmpty, R.isNil))),
      R.join(' '),
    )(fields)

    const data = await fetchGeoLocation(address)
    if (data.length) {
      setFields({ ...fields, lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) })
    } else {
      Logger.warning('Cannot fetch geolocation coordinates')
    }
  }

  const handleChangeLatLng = (position) => {
    setFields({ ...fields, lat: position[0], lng: position[1] })
  }

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Box>
        {errors.__form && <Alert level="error">{errors.__form}</Alert>}
        <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
          <Tabs value={activeTabIndex} onChange={handleTabChange} style={{ overflow: 'auto' }}>
            <Tab label={t('branches:tabs.branchData')} />
            <Tab label={t('branches:tabs.addressData')} />
            {BranchPermissions.create(user) && [
              <Tab key="seniorTab" label={t('branches:tabs.seniorAdmins')} />,
              <Tab key="juniorTab" label={t('branches:tabs.juniorAdmins')} />,
            ]}
          </Tabs>
        </MuiBox>
        <TabPanel activeIndex={activeTabIndex} index={0}>
          <Box margin="0 0 1rem">
            <Checkbox
              checked={fields.active}
              onChange={R.pipe(R.path(['target', 'checked']), setField('active'))}
              label={t('branches:fields.active')}
            />
          </Box>
          <Grid container rowSpacing={3} spacing={2}>
            {BranchPermissions.create(user) && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      value={fields.name}
                      onChange={setStateFromEvent(setField('name'))}
                      label={t('branches:fields.name')}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      value={fields.code}
                      onChange={setStateFromEvent(setField('code'))}
                      label={t('branches:fields.code')}
                      error={!!errors.code}
                      helperText={errors.code}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  value={fields.email}
                  onChange={setStateFromEvent(setField('email'))}
                  label={t('branches:fields.email')}
                  error={!!errors.email}
                  helperText={errors.email}
                  type="email"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  value={fields.phone}
                  onChange={setStateFromEvent(setField('phone'))}
                  label={t('branches:fields.phone')}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </FormControl>
            </Grid>
            {BranchPermissions.create(user) && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <EmployeeByRoleSelect
                      required
                      value={fields.manager}
                      onChange={setStateFromEvent(setField('manager'))}
                      label={t('branches:fields.manager')}
                      roles={[ROLES.BRANCH_DIRECTOR]}
                      error={!!errors.manager}
                      helperText={errors.manager}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <DatePicker
                      label={t('branches:fields.activationDate')}
                      value={fields.activationDate}
                      onChange={setField('activationDate')}
                      error={!!errors.activationDate}
                      helperText={errors.activationDate}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <EmployeeByRoleSelect
                      value={fields.administrative}
                      onChange={setStateFromEvent(setField('administrative'))}
                      label={t('branches:fields.administrative')}
                      roles={[ROLES.HQ_ADMINISTRATIVE]}
                      error={!!errors.administrative}
                      helperText={errors.administrative}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <EmployeeByRoleSelect
                      value={fields.secondaryAdministrative}
                      onChange={setStateFromEvent(setField('secondaryAdministrative'))}
                      label={t('branches:fields.secondaryAdministrative')}
                      roles={[ROLES.HQ_ADMINISTRATIVE]}
                      error={!!errors.secondaryAdministrative}
                      helperText={errors.secondaryAdministrative}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </TabPanel>
        <TabPanel activeIndex={activeTabIndex} index={1}>
          <Grid container rowSpacing={3} spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <RegionSelect
                  value={fields.region}
                  onChange={handleRegionChange}
                  error={!!errors.region}
                  helperText={errors.region}
                  label={t('auth:fields.region')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <ProvinceSelect
                  value={fields.province}
                  onChange={handleProvinceChange}
                  error={!!errors.province}
                  helperText={errors.province}
                  label={t('auth:fields.province')}
                  regionId={fields.region}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <CitySelect
                  value={fields.city}
                  onChange={setStateFromEvent(setField('city'))}
                  error={!!errors.city}
                  helperText={errors.city}
                  label={t('auth:fields.city')}
                  provinceId={fields.province}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  value={fields.address}
                  onChange={setStateFromEvent(setField('address'))}
                  label={t('branches:fields.address')}
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={fields.locality}
                  onChange={setStateFromEvent(setField('locality'))}
                  label={t('branches:fields.locality')}
                  error={!!errors.locality}
                  helperText={errors.locality}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={fields.internalNumber}
                  onChange={setStateFromEvent(setField('internalNumber'))}
                  label={t('branches:fields.internalNumber')}
                  error={!!errors.internalNumber}
                  helperText={errors.internalNumber}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  value={fields.houseNumber}
                  onChange={setStateFromEvent(setField('houseNumber'))}
                  label={t('branches:fields.houseNumber')}
                  error={!!errors.houseNumber}
                  helperText={errors.houseNumber}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  value={fields.cap}
                  onChange={setStateFromEvent(setField('cap'))}
                  label={t('branches:fields.cap')}
                  error={!!errors.cap}
                  helperText={errors.cap}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Margin top="2rem" style={{ width: '100%' }}>
            <Box direction="row" justify="center">
              <Button
                onClick={handleCalculateLatLng}
                variant="contained"
                style={{ marginBottom: '1rem' }}
                disabled={!fields.city}
              >
                {t('common:ui.CalculateLatLng')}
              </Button>
            </Box>
            {errors.lat && <Alert severity="error">{t('common:validation:LatLngRequired')}</Alert>}
            <MapField
              latLng={fields.lat ? [fields.lat, fields.lng] : undefined}
              onChangePosition={handleChangeLatLng}
            />
          </Margin>
        </TabPanel>
        {BranchPermissions.create(user) && [
          <TabPanel activeIndex={activeTabIndex} index={2} key="tabSenior">
            <SeniorAdminsList
              branchSeniorAdmins={branch?.seniorAdministrativesObjs || emptyArray}
              selected={selectedSeniorAdmins}
              onSelect={setSelectedSeniorAdmins}
            />
          </TabPanel>,
          <TabPanel activeIndex={activeTabIndex} index={3} key="tabJunior">
            <JuniorAdminsList
              branchJuniorAdmins={branch?.juniorAdministrativesObjs || emptyArray}
              selected={selectedJuniorAdmins}
              onSelect={setSelectedJuniorAdmins}
            />
          </TabPanel>,
        ]}
      </Box>
    </Modal>
  )
}

BranchForm.propTypes = {
  branch: BranchType,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default BranchForm
