/* eslint-disable react/prop-types */
import StarIcon from '@mui/icons-material/Star'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonDialog } from '@Common/Components/ButtonDialog'
import { ItemContainerContext } from '@Common/Components/ItemContainer'
import { SupplierCompanyRatingForm } from '@Supplier/Forms/SupplierCompanyRatingForm'

export const SupplierCompanyRatingActions = () => {
  const { item } = useContext(ItemContainerContext)
  const { t } = useTranslation()

  return (
    <ButtonDialog
      size="small"
      disableElevation
      variant="contained"
      sx={{ marginLeft: '.5rem' }}
      title={t('suppliercompany:changeRating')}
      icon={<StarIcon />}
    >
      <SupplierCompanyRatingForm defaultValues={item} />
    </ButtonDialog>
  )
}
