import React from 'react'

import { AddButton } from '@Common/Components/Buttons/AddButton'
import { DataTableV3 } from '@Common/Components/DataTableV3'
import { SearchField } from '@Common/Components/FormsV2/SearchField'
import { SupplierCompanyTypeActions } from '@Supplier/Components/SupplierCompanyTypeActions'
import { SupplierCompanyType } from '@Supplier/Models/SupplierCompanyType'

export const SupplierCompanyTypeListView = () => {
  const renderFilters = (
    <>
      <SearchField xs={3} />
    </>
  )

  return (
    <>
      <DataTableV3
        url={SupplierCompanyType.url}
        model={SupplierCompanyType}
        renderFilters={renderFilters}
        renderActions={<SupplierCompanyTypeActions />}
      />
      <AddButton model={SupplierCompanyType} />
    </>
  )
}
