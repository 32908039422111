import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@Common/Components/Styles'
import { BuildingType } from '@Buildings/Models/Building'
import Alert from '@Common/Components/Alert'
import Modal from '@Common/Components/Modal'
import TextField from '@Common/Components/TextField'
import { FormControl } from '@mui/material'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import logger from '@Common/Utils/Logger'
import Loader from '@Common/Components/Loader'

const NotesForm = ({ building, onClose, onSave }) => {
  const { t } = useTranslation()
  const title = t('buildings:ui.BranchBuildingNotes', { name: building.denominazione })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { fields, setField, errors, setErrors } = useForm({ notes: R.defaultTo('', building.extra.notes) }, true)

  const submit = async () => {
    logger.debug('BranchBuilding notes form submission, fields:', fields)
    setIsSubmitting(true)
    const { error, isSuccessful } = await onSave({ id: building.extra.id, ...fields })
    if (!isSuccessful && error?.status === 400) {
      setIsSubmitting(false)
      logger.debug('BranchBuilding notes form submission api error', error)
      setErrors(convertServerErrors(error))
    } else {
      onClose()
    }
  }

  return (
    <Modal title={title} size="xs" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Box>
        {errors.__form && <Alert level="error">{errors.__form}</Alert>}
        <FormControl fullWidth>
          <TextField
            multiline
            rows={10}
            value={fields.notes}
            label={t('buildings:fields.notes')}
            onChange={setStateFromEvent(setField('notes'))}
            placeholder={t('buildings:ui.AddNotes')}
            error={!!errors.notes}
            helperText={errors.notes}
          />
        </FormControl>
      </Box>
    </Modal>
  )
}

NotesForm.propTypes = {
  building: BuildingType.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default NotesForm
