import { api, apiQueryString, objToQueryString } from '@Core/Services/Api'
import logger from '@Common/Utils/Logger'

const prefix = 'tms'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    categories: builder.query({
      query: (qs) => `${prefix}/categories?${objToQueryString(qs)}`,
      providesTags: [{ type: 'TmsTaskCategory', id: 'LIST' }],
    }),
    category: builder.query({
      query: (id) => `${prefix}/categories/${id}`,
      providesTags: (_, __, { id }) => [{ type: 'TmsTaskCategory', id }],
    }),
    createCategory: builder.mutation({
      query: (category) => ({
        url: `${prefix}/categories`,
        method: 'POST',
        body: category,
      }),
      invalidatesTags: [{ type: 'TmsTaskCategory', id: 'LIST' }],
    }),
    updateCategory: builder.mutation({
      query: ({ id, ...category }) => ({
        url: `${prefix}/categories/${id}`,
        method: 'PUT',
        body: category,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'TmsTaskCategory', id }, { type: 'TmsTaskCategory', id: 'LIST' }],
    }),
    isActiveCategory: builder.mutation({
      query: ({ id, isActive }) => ({
        url: `${prefix}/categories/${id}/is-active`,
        method: 'PUT',
        body: { isActive },
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'TmsTaskCategory', id }, { type: 'TmsTaskCategory', id: 'LIST' }],
    }),
    duplicateCategory: builder.mutation({
      query: (id) => ({
        url: `${prefix}/categories/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'TmsTaskCategory', id: 'LIST' }],
    }),
    saveCategoryDraft: builder.mutation({
      query: ({ id, ...payload }) => {
        logger.debug('saveCategoryDraft', { id, payload })
        return ({
          url: `${prefix}/categories/${id}/draft`,
          body: payload,
          method: 'POST',
        })
      },
      invalidatesTags: (_, __, { id, ...rest }) => {
        logger.debug('updateCategory', { _, __, id, rest })
        return [{ type: 'TmsTaskCategory', id }]
      },
    }),
    deleteCategoryDraft: builder.mutation({
      query: (id) => ({
          url: `${prefix}/categories/${id}/draft`,
          method: 'DELETE',
        })
      // invalidatesTags: (_, __, { id }) => [{ type: 'TmsTaskCategory', id }],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `${prefix}/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'TmsTaskCategory', id }, 'TmsTaskCategory'],
    }),
    createTaskFlow: builder.mutation({
      query: (formData) => ({
        url: `${prefix}/tasks/flows`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['TmsTask', 'Report', 'TmsTaskCategory'],
    }),
    tasks: builder.query({
      query: (qs) => `${prefix}/tasks?${apiQueryString(qs)}`,
      providesTags: ['TmsTask'],
    }),
    taskStats: builder.query({
      query: (qs) => `${prefix}/tasks/stats?${objToQueryString(qs)}`,
    }),
    task: builder.query({
      query: (taskId) => `${prefix}/tasks/${taskId}`,
      providesTags: (_, __, { id }) => [{ type: 'TmsTask', id }],
    }),
    updateTask: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${prefix}/tasks/${id}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: (_, error) => error ? [] : ['TmsTask'],
    }),
    activateProcessing: builder.mutation({
      query: ({ id }) => ({
        url: `${prefix}/tasks/${id}/activate-processing`,
        method: 'PATCH',
      }),
      invalidatesTags: ['TmsTask'],
    }),
    deleteTask: builder.mutation({
      query: (id) => ({
        url: `${prefix}/tasks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TmsTask', 'TmsTaskCategory'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCategoriesQuery,
  useCategoryQuery,
  useLazyCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useSaveCategoryDraftMutation,
  useDeleteCategoryDraftMutation,
  useIsActiveCategoryMutation,
  useDuplicateCategoryMutation,
  useDeleteCategoryMutation,
  useCreateTaskFlowMutation,
  useTasksQuery,
  useLazyTaskStatsQuery,
  useTaskQuery,
  useUpdateTaskMutation,
  useActivateProcessingMutation,
  useDeleteTaskMutation,
} = extendedApi
