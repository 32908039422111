import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import BallotIcon from '@mui/icons-material/Ballot'
import BuildIcon from '@mui/icons-material/Build'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import EngineeringIcon from '@mui/icons-material/Engineering'
import FlagIcon from '@mui/icons-material/Flag'
import ForumIcon from '@mui/icons-material/Forum'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import GasMeterIcon from '@mui/icons-material/GasMeter'
import GroupIcon from '@mui/icons-material/Group'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import ListIcon from '@mui/icons-material/List'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import PaidIcon from '@mui/icons-material/Paid'
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { MenuItem, MenuList, Paper, useMediaQuery } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { ReportsPermissions, ReportTypesPermissions } from '@Tickets/Permissions'
import { TaskCategoryPermissions, TaskFlowPermissions, TaskPermissions } from 'Tms/Permissions'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import TmsMyTasksIcon from '@Assets/Icons/TmsMyTasksIcon'
import TmsTaskCategoryIcon from '@Assets/Icons/TmsTaskCategoryIcon'
import { EmployeesPermissions, InvitationsPermissions } from '@Auth/Permissions'
import { canAccessBranchesView } from '@Branches/Permissions'
import { setActiveBranch } from '@Branches/Redux'
import { useBranchesQuery } from '@Branches/Services/Api'
import { canAccessBuildingsView } from '@Buildings/Permissions'
import { InsurancePolicyPermissions, PodPdrPermissions, ReconciliationReminderPermissions } from '@Condo/Permissions'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { apiList } from '@Core/Services/Api'
import { CustomerCarePermissions } from '@CustomerCare/Permissions'
import { NewsPermissions } from '@News/Permissions'
import { NotificationsPermissions } from '@Notifications/Permissions'
import { ResidentsPermissions } from '@Residents/Permissions'
import { CategoryPermissions, LabelPermissions, ProviderPermissions, ServicePermissions } from '@Services/Permissions'

import { selectAdminSidebar, toggleAdminSidebar } from '../../Core/Redux/Ui'
import { useCurrentUser } from '../Utils/Hooks'
import { Item, Link, Nav as BaseNav, Separator } from './Navigation/Styled'
import { logsToAdminDashboard } from '@Common/Utils/Permissions'

const Fixed = styled.div`
  display: contents;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  width: ${({ width }) => width};
  transition: 0.2s width linear;
`

const Nav = styled(BaseNav)`
  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  padding: 1rem 0;
  transition: 0.2s all linear;
`

export const PopoverMenu = styled(Paper)`
  position: absolute !important;
  top: 0;
  z-index: 100;
  ${(props) => (props.right ? 'right: 100%' : 'left: 100%')};
`

export const PopoverIcon = styled.div`
  margin-right: 0.5rem;

  svg {
    margin: 0;
    min-width: 20px !important;
    width: 20px !important;
  }
`

const items = [
  {
    path: makePath('admin.dashboard'),
    label: 'common:navigation.Dashboard',
    perm: () => true,
    Icon: DashboardIcon,
    exact: true,
  },
  {
    path: makePath('admin.employees'),
    label: 'common:navigation.Employees',
    perm: EmployeesPermissions.read,
    Icon: GroupIcon,
  },
  {
    path: makePath('admin.residents'),
    label: 'common:navigation.Residents',
    perm: ResidentsPermissions.read,
    Icon: BallotIcon,
  },
  {
    path: makePath('customerCare.main'),
    label: 'common:navigation.CustomerCare',
    perm: CustomerCarePermissions.read,
    Icon: SupportAgentIcon,
  },
  {
    path: makePath('admin.branches'),
    label: 'common:navigation.Branches',
    perm: canAccessBranchesView,
    Icon: PersonPinCircleIcon,
  },
  {
    path: makePath('admin.buildings.list'),
    label: 'common:navigation.BuildingsManagement',
    perm: canAccessBuildingsView,
    Icon: DomainAddIcon,
    isActive: (pathname) => {
      return (
        pathname.includes(makePath('admin.buildings.list')) ||
        pathname.includes(makePath('admin.podpdrs.podPdrs')) ||
        pathname.includes(makePath('admin.insurancePolicy.insurancePolicy')) ||
        pathname.includes(makePath('admin.reconciliationReminders.reconciliationReminders'))
      )
    },
    children: [
      {
        path: makePath('admin.buildings.list'),
        label: 'common:navigation.List',
        exact: true,
        perm: canAccessBuildingsView,
        Icon: ListIcon,
      },
      {
        path: makePath('admin.podpdrs.podPdrs'),
        label: 'common:navigation.PodPdrManagement',
        perm: PodPdrPermissions.read,
        Icon: GasMeterIcon,
      },
      {
        path: makePath('admin.insurancePolicy.insurancePolicy'),
        label: 'common:navigation.InsurancePolicyManagement',
        perm: InsurancePolicyPermissions.read,
        Icon: HealthAndSafetyIcon,
      },
      {
        path: makePath('admin.reconciliationReminders.reconciliationReminders'),
        label: 'common:navigation.ReconciliationReminderManagementShortLabel',
        perm: ReconciliationReminderPermissions.read,
        Icon: PaidIcon,
      },
    ],
  },
  {
    path: makePath('admin.supplier.supplier'),
    label: 'common:navigation.SupplierCompanyLabel',
    perm: canAccessBuildingsView,
    Icon: EngineeringIcon,
    isActive: (pathname) => {
      return pathname.includes('/admin/supplier/suppliers')
    },
  },
  {
    path: makePath('admin.invitations'),
    label: 'common:navigation.Invitations',
    perm: InvitationsPermissions.read,
    Icon: ForwardToInboxIcon,
  },
  {
    path: makePath('admin.news'),
    label: 'common:navigation.News',
    perm: NewsPermissions.read,
    Icon: NewspaperIcon,
  },
  {
    path: makePath('admin.notifications'),
    label: 'common:navigation.Notifications',
    perm: NotificationsPermissions.create,
    Icon: CircleNotificationsIcon,
  },
  {
    path: makePath('admin.services.services'),
    label: 'common:navigation.Services',
    perm: R.anyPass([
      LabelPermissions.read,
      CategoryPermissions.read,
      ProviderPermissions.read,
      ServicePermissions.read,
    ]),
    Icon: CleaningServicesIcon,
    isActive: (pathname) => pathname.includes(makePath('admin.services.services')),
    children: [
      {
        path: makePath('admin.services.services'),
        label: 'common:navigation.List',
        exact: true,
        perm: ServicePermissions.read,
        Icon: ListIcon,
      },
      {
        path: makePath('admin.services.categories'),
        label: 'common:navigation.Categories',
        perm: CategoryPermissions.read,
        Icon: AccountTreeIcon,
      },
      {
        path: makePath('admin.services.labels'),
        label: 'common:navigation.Labels',
        perm: LabelPermissions.read,
        Icon: LocalOfferIcon,
      },
      {
        path: makePath('admin.services.providers'),
        label: 'common:navigation.Providers',
        perm: ProviderPermissions.read,
        Icon: BuildIcon,
      },
    ],
  },
  {
    path: makePath('admin.tickets.reports'),
    label: 'common:navigation.Tickets',
    perm: ReportsPermissions.read,
    Icon: FlagIcon,
    isActive: (pathname) => pathname.includes(makePath('admin.tickets.reports')),
    children: [
      {
        path: makePath('admin.tickets.reports'),
        label: 'common:navigation.List',
        perm: ReportsPermissions.read,
        Icon: ListIcon,
      },
      {
        path: makePath('admin.tickets.types'),
        label: 'common:navigation.ReportTypes',
        perm: ReportTypesPermissions.read,
        Icon: BuildIcon,
      },
    ],
  },
  {
    path: makePath('tms.root'),
    label: 'common:navigation.Tms',
    perm: R.both(R.anyPass([TaskCategoryPermissions.read, TaskFlowPermissions.read, TaskPermissions.read]), logsToAdminDashboard),
    Icon: ForumIcon,
    isActive: (pathname) => pathname.includes(makePath('tms.root')),
    children: [
      {
        path: makePath('tms.taskList'),
        label: 'common:navigation.TmsTasks',
        perm: TaskPermissions.read,
        Icon: TmsMyTasksIcon,
      },
      {
        path: makePath('tms.categories'),
        label: 'common:navigation.TmsCategories',
        perm: R.anyPass([TaskCategoryPermissions.create, TaskCategoryPermissions.update, TaskCategoryPermissions.delete]),
        Icon: TmsTaskCategoryIcon,
      },
    ],
  },
]

const AdminSidebar = ({ fill, onItemClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useCurrentUser()
  const pathname = useSelector(R.path(['router', 'location', 'pathname']))
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [hoverMenu] = React.useState({})
  const status = useSelector(selectAdminSidebar)
  const { data: branches } = apiList(useBranchesQuery())

  const ToggleIcon = status.expanded ? ArrowBackIosNewIcon : ArrowForwardIosIcon
  const toggleStatus = () => dispatch(toggleAdminSidebar())

  const navigateTo = (path) => () => {
    onItemClick && onItemClick()
    history.push(path)
  }

  React.useEffect(() => {
    if (branches.length === 1) {
      dispatch(setActiveBranch(branches[0]))
    }
  }, [branches])

  return (
    <Nav
      $fill={fill}
      background="sidebar"
      width={status.expanded ? status.expandedWidth : status.collapsedWidth}
      mobile={isSmallDevice}
    >
      <Fixed width={status.expanded ? status.expandedWidth : status.collapsedWidth}>
        <Item direction="row" expanded={status.expanded} style={{ marginTop: 0 }}>
          <ToggleIcon onClick={toggleStatus} style={{ fontSize: '30px' }} />
        </Item>
        <Separator />
        {items.map(({ path, label, perm, Icon, exact, children, isActive }, idx) => {
          const active = exact ? pathname === path : pathname.includes(path)
          const hasActive = isActive && isActive(pathname)
          return perm(user) ? (
            <Fragment key={idx}>
              <Item
                direction="row"
                align="center"
                justify="flex-start"
                expanded={status.expanded}
                active={!children?.length && active}
                hasActive={hasActive}
                astyle={idx >= items.length - 1 ? { bottom: 57, position: 'fixed', width: 'inherit' } : {}}
              >
                <Tooltip title={status.expanded ? '' : t(label)} placement="right">
                  <Icon style={{ fontSize: '30px' }} onClick={navigateTo(path)} />
                </Tooltip>
                {status.expanded && (
                  <Link onClick={navigateTo(path)} margin="0 0 0 .5rem">
                    {t(label)}
                  </Link>
                )}
                {children?.length && hoverMenu[path]?.isOpen && !isActive(pathname) && (
                  <PopoverMenu elevation={3}>
                    <MenuList>
                      {children
                        .filter((child) => child.perm(user))
                        .map(({ path, Icon, label }, idx) => (
                          <MenuItem onClick={navigateTo(path)} dense key={idx}>
                            <PopoverIcon>
                              <Icon onClick={navigateTo(path)} />
                            </PopoverIcon>{' '}
                            {t(label)}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </PopoverMenu>
                )}
              </Item>
              {children?.length &&
                isActive(pathname) &&
                children
                  .filter((child) => child.perm(user))
                  .map(({ path, Icon, label, exact }, idx) => {
                    const active = exact ? pathname === path : pathname.includes(path)
                    return (
                      <Tooltip title={status.expanded ? '' : t(label)} placement="right" key={idx}>
                        <Item
                          sub
                          active={active}
                          hasActive={hasActive}
                          direction="row"
                          align="center"
                          justify="flex-start"
                          onClick={navigateTo(path)}
                        >
                          <Icon style={{ fontSize: '24px' }} />
                          {status.expanded && (
                            <Link sub onClick={navigateTo(path)} margin="0 0 0 .5rem">
                              {t(label)}
                            </Link>
                          )}
                        </Item>
                      </Tooltip>
                    )
                  })}
            </Fragment>
          ) : null
        })}
      </Fixed>
    </Nav>
  )
}

AdminSidebar.defaultProps = {
  fill: false,
}

AdminSidebar.propTypes = {
  fill: PropTypes.bool,
  onItemClick: PropTypes.func,
}

export default AdminSidebar
