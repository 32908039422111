import PrivateRoute from '@Common/Components/PrivateRoute'
import ScrollToTop from '@Common/Components/ScrollToTop'
import logger from '@Common/Utils/Logger'
import config from '@Config'
import { history, selectPathname } from '@Core/Redux/Store'
import { selectActiveHousingUnitId } from '@MyEstia/Redux'
import { NewsListView } from '@MyEstia/Views/NewsListView'
import ProfileView from '@MyEstia/Views/ProfileView'
import { ServiceDetailView } from '@MyEstia/Views/ServiceDetailView'
import MyHomeRouter from '@MyHome/Router/MyHomeRouter'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import MyEstiaLayout from '../Layouts/MyEstiaLayout'
// Views
import { HomeView } from '../Views/HomeView'
import { ServicesView } from '../Views/ServicesView'

export const MyEstiaRouter = () => {
  const pathname = useSelector(selectPathname)
  const activeHousingUnitId = useSelector(selectActiveHousingUnitId)

  useEffect(() => {
    logger.debug(pathname, activeHousingUnitId)
    const regex = /^\/my-estia\/([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12})(\/(.*))?$/us
    if (regex.test(pathname?.toString() || '')) {
      logger.debug('Found my home path, checking housing unit...')
      const matches = regex.exec(pathname?.toString() || '')
      if (matches[1] !== null && activeHousingUnitId !== null && matches[1] !== activeHousingUnitId) {
        logger.debug('Path housing unit', matches[1], 'differs from active housing unit', activeHousingUnitId)
        const newLocation = pathname.replace(regex, `/my-estia/${activeHousingUnitId}$2`)
        logger.debug('Updating pathname to:', newLocation)
        history.push(newLocation)
      }
    }
  }, [pathname, activeHousingUnitId])

  return (
    <MyEstiaLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
      <ScrollToTop />
      <Routes>
        <Route
          path={config.urls.myEstia.home}
          element={
            <PrivateRoute>
              <HomeView />
            </PrivateRoute>
          }
        />
        <Route
          path={config.urls.myEstia.news}
          element={
            <PrivateRoute>
              <NewsListView />
            </PrivateRoute>
          }
        />
        <Route
          path={config.urls.myEstia.profile}
          element={
            <PrivateRoute>
              <ProfileView />
            </PrivateRoute>
          }
        />
        <Route path={config.urls.myEstia.myHome.base} element={<MyHomeRouter />} />
        <Route path={config.urls.myEstia.myServices.main} element={<ServicesView />} />
        <Route path={config.urls.myEstia.myServices.serviceDetail.main} element={<ServiceDetailView />} />
      </Routes>
    </MyEstiaLayout>
  )
}

export default MyEstiaRouter
