/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AddButton } from '@Common/Components/Buttons/AddButton'
import { DataTableV3 } from '@Common/Components/DataTableV3'
import { FieldValue } from '@Common/Components/ItemField'
import { ChipStatusSupplierCompany } from '@Supplier/Components/ChipStatusSupplierCompany'
import { CondoSupplierCompanyActions } from '@Supplier/Components/CondoSupplierCompanyActions'
import { CondoSupplierCompany } from '@Supplier/Models/CondoSupplierCompany'

export const CondoSupplierCompanyListView = ({ renderFilters }) => {
  const { buildingId } = useParams()
  const { t } = useTranslation()

  const FIELDS_MAPPING = {
    status: (record) => {
      return <ChipStatusSupplierCompany value={record.status} />
    },
    services: (record) => {
      return <FieldValue type="foreign_key" value={record?.serviceList || []} />
    },
  }

  return (
    <>
      <DataTableV3
        url={`/condo/buildings/${buildingId}/suppliers`}
        model={CondoSupplierCompany}
        renderFilters={renderFilters}
        renderActions={<CondoSupplierCompanyActions />}
        defaultOrdering="created"
        columns={['name', 'blacklist', 'notes', 'services']}
        fieldsMapping={FIELDS_MAPPING}
      />
      <AddButton
        model={CondoSupplierCompany}
        title={t(`${CondoSupplierCompany.name.toLowerCase()}:associateNewSupplierToCondo`)}
      />
    </>
  )
}
