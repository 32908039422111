import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'services/services'
const labelPrefix = 'services/labels'
const providerPrefix = 'services/providers'
const categoryPrefix = 'services/categories'
const externalPrefix = 'services/external'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    services: builder.query({
      query: (qs) => `${prefix}?${apiQueryString(qs)}`,
      providesTags: ['Services'],
    }),
    service: builder.query({
      query: (slug) => `${prefix}/${slug}`,
    }),
    createServices: builder.mutation({
      query: (services) => ({
        url: `${prefix}`,
        body: services,
        method: 'POST',
      }),
      invalidatesTags: ['Services'],
    }),
    updateServices: builder.mutation({
      query: ({ slug, service }) => ({
        url: `${prefix}/${slug}`,
        body: service,
        method: 'PATCH',
      }),
      invalidatesTags: ['Services'],
    }),
    deleteServices: builder.mutation({
      query: (slug) => ({
        url: `${prefix}/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Services'],
    }),
    serviceLabels: builder.query({
      query: (qs) => `${labelPrefix}?${apiQueryString(qs)}`,
      providesTags: ['Labels'],
    }),
    unpaginatedServiceLabels: builder.query({
      query: () => `${labelPrefix}`,
      providesTags: ['Labels'],
    }),
    createServiceLabels: builder.mutation({
      query: (labels) => ({
        url: `${labelPrefix}`,
        body: labels,
        method: 'POST',
      }),
      invalidatesTags: ['Labels'],
    }),
    updateServiceLabels: builder.mutation({
      query: ({ id, label }) => ({
        url: `${labelPrefix}/${id}`,
        body: label,
        method: 'PATCH',
      }),
      invalidatesTags: ['Labels'],
    }),
    deleteServiceLabels: builder.mutation({
      query: (id) => ({
        url: `${labelPrefix}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Labels'],
    }),
    serviceCategories: builder.query({
      query: (qs) => `${categoryPrefix}?${apiQueryString(qs)}`,
      providesTags: ['Categories'],
    }),
    unpaginatedServiceCategories: builder.query({
      query: () => `${categoryPrefix}`,
      providesTags: ['Categories'],
    }),
    createServiceCategories: builder.mutation({
      query: (categories) => ({
        url: `${categoryPrefix}`,
        body: categories,
        method: 'POST',
      }),
      invalidatesTags: ['Categories'],
    }),
    updateServiceCategories: builder.mutation({
      query: ({ slug, category }) => ({
        url: `${categoryPrefix}/${slug}`,
        body: category,
        method: 'PATCH',
      }),
      invalidatesTags: ['Categories'],
    }),
    deleteServiceCategories: builder.mutation({
      query: (slug) => ({
        url: `${categoryPrefix}/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Categories'],
    }),
    serviceProviders: builder.query({
      query: (qs) => `${providerPrefix}?${apiQueryString(qs)}`,
      providesTags: ['Providers'],
    }),
    unpaginatedServiceProviders: builder.query({
      query: () => `${providerPrefix}`,
      providesTags: ['Providers'],
    }),
    createServiceProviders: builder.mutation({
      query: (providers) => ({
        url: `${providerPrefix}`,
        body: providers,
        method: 'POST',
      }),
      invalidatesTags: ['Providers'],
    }),
    updateServiceProviders: builder.mutation({
      query: ({ id, provider }) => ({
        url: `${providerPrefix}/${id}`,
        body: provider,
        method: 'PATCH',
      }),
      invalidatesTags: ['Providers'],
    }),
    deleteServiceProviders: builder.mutation({
      query: (id) => ({
        url: `${providerPrefix}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Providers'],
    }),
    externalSales: builder.query({
      query: (qs) => `${externalPrefix}?${apiQueryString(qs)}`,
      providesTags: ['ExternalSales'],
    }),
    createExternalSales: builder.mutation({
      query: (externalSales) => ({
        url: `${externalPrefix}`,
        body: externalSales,
        method: 'POST',
      }),
      invalidatesTags: ['ExternalSales'],
    }),
    updateExternalSales: builder.mutation({
      query: ({ id, externalSale }) => ({
        url: `${externalPrefix}/${id}`,
        body: externalSale,
        method: 'PATCH',
      }),
      invalidatesTags: ['ExternalSales'],
    }),
    deleteExternalSales: builder.mutation({
      query: (id) => ({
        url: `${externalPrefix}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ExternalSales'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useServicesQuery,
  useServiceQuery,
  useCreateServicesMutation,
  useUpdateServicesMutation,
  useDeleteServicesMutation,
  useServiceLabelsQuery,
  useUnpaginatedServiceLabelsQuery,
  useCreateServiceLabelsMutation,
  useUpdateServiceLabelsMutation,
  useDeleteServiceLabelsMutation,
  useServiceCategoriesQuery,
  useUnpaginatedServiceCategoriesQuery,
  useCreateServiceCategoriesMutation,
  useUpdateServiceCategoriesMutation,
  useDeleteServiceCategoriesMutation,
  useServiceProvidersQuery,
  useUnpaginatedServiceProvidersQuery,
  useCreateServiceProvidersMutation,
  useUpdateServiceProvidersMutation,
  useDeleteServiceProvidersMutation,
  useExternalSalesQuery,
  useCreateExternalSalesMutation,
  useUpdateExternalSalesMutation,
  useDeleteExternalSalesMutation,
} = extendedApi
