import i18next from 'i18next'
import PropTypes from 'prop-types'

export const LabelType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('services:fields.label.Id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'name',
      label: i18next.t('services:fields.label.Name'),
    },
    {
      id: 'color',
      label: i18next.t('services:fields.label.Color'),
    },
  ],
}
