import i18next from 'i18next'
import * as yup from 'yup'

import { validationSchema as taskValidationSchema } from '@Tms/Forms/TaskForm/Validation'

export const required = i18next.t('common:validation.RequiredField')

export const validationSchema = yup.object().shape({
  name: yup.string().required(required),
  openPrivileges: yup.array().min(1),
  mainTask: taskValidationSchema,
})
