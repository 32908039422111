import { Search } from '@mui/icons-material'
import { Container, InputAdornment, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Box } from '@Common/Components/Styles'
import { useDebounce } from '@Common/Utils/Hooks'

const SearchBox = styled(TextField)`
  ${({ theme }) => css`
    background: ${theme.palette.lightFive.main};

    .MuiInputBase-input-hieRCO {
      color: ${theme.palette.primary.main};
    }
  `}
  border-radius: 20px;

  .MuiInputBase-root {
    border-radius: 20px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`

const NewsSearch = ({ onSearch }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)

  useEffect(() => {
    onSearch(debouncedSearch)
  }, [debouncedSearch])

  return (
    <Box>
      <Container maxWidth="xs">
        <SearchBox
          autoFocus
          value={search}
          size="small"
          placeholder={t('common:actions.Search')}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />
      </Container>
    </Box>
  )
}

export default NewsSearch

NewsSearch.propTypes = {
  onSearch: PropTypes.func,
}
