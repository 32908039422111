import i18next from 'i18next'
import PropTypes from 'prop-types'
import { BranchBuilding } from '@Buildings/Models/BranchBuilding'

export const BankAccountType = PropTypes.shape({
  stripeBankAccountId: PropTypes.string,
  istituto: PropTypes.string,
  filiale: PropTypes.string,
  codiceIBAN: PropTypes.string,
  id: PropTypes.string,
})

export const AddressType = PropTypes.shape({
  presso: PropTypes.string,
  via: PropTypes.string,
  civico: PropTypes.string,
  localita: PropTypes.string,
  cap: PropTypes.string,
  comune: PropTypes.string,
  provincia: PropTypes.string,
  nazione: PropTypes.string,
})

export const BuildingSummaryType = PropTypes.shape({
  id: PropTypes.string,
  denominazione: PropTypes.string,
  codiceUnivoco: PropTypes.number,
  riferimentoEsterno: PropTypes.string,
  gruppoEsterno: PropTypes.string,
})

export const Building = {
  id: PropTypes.string.isRequired,
  codiceFiscale: PropTypes.string,
  codiceUnivoco: PropTypes.number,
  contoCorrentePredefinito: BankAccountType,
  contiCorrenti: PropTypes.arrayOf(BankAccountType),
  denominazione: PropTypes.string,
  nrEdifici: PropTypes.number,
  nrScale: PropTypes.number,
  nrUi: PropTypes.number,
  istruzioniAccesso: PropTypes.string,
  datiCatastali: PropTypes.shape({
    codiceComune: PropTypes.string,
    comuneCatastale: PropTypes.string,
    sezioneUrbana: PropTypes.string,
    foglio: PropTypes.string,
    particella: PropTypes.string,
    estensioneParticella: PropTypes.string,
    subalterno: PropTypes.string,
  }),
  indirizzo: AddressType,
  referente: PropTypes.shape({
    cognome: PropTypes.string,
    nome: PropTypes.string,
  }),
  titolo: PropTypes.string,
}

export const BuildingType = PropTypes.shape({
  ...Building,
  extra: PropTypes.shape(BranchBuilding),
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'denominazione',
      label: i18next.t('buildings:fields.name'),
    },
    {
      id: 'codiceFiscale',
      label: i18next.t('buildings:fields.fiscalCode'),
    },
    {
      id: 'iban',
      label: i18next.t('buildings:fields.defaultBankAccount.iban'),
      ordering: false,
    },
    {
      id: 'indirizzo.via',
      label: i18next.t('buildings:fields.address'),
    },
    {
      id: 'indirizzo.civico',
      label: i18next.t('buildings:fields.houseNumber'),
    },
    {
      id: 'indirizzo.cap',
      label: i18next.t('buildings:fields.cap'),
    },
    {
      id: 'indirizzo.comune',
      label: i18next.t('buildings:fields.city'),
    },
    {
      id: 'indirizzo.provincia',
      label: i18next.t('buildings:fields.province'),
    },
    {
      id: 'indirizzo.presso',
      label: i18next.t('buildings:fields.careOf'),
    },
    {
      id: 'indirizzo.località',
      label: i18next.t('buildings:fields.locality'),
    },
    {
      id: 'extra.administratorObj.fullName',
      label: i18next.t('buildings:fields.administrator.__field'),
      ordering: false,
    },
    {
      id: 'extra.accountingOwnerObj.fullName',
      label: i18next.t('buildings:fields.accountingOwner'),
    },
    {
      id: 'extra.administrationOwnerObj.fullName',
      label: i18next.t('buildings:fields.administrationOwner'),
    },
    {
      id: 'extra.accidentsOwnerObj.fullName',
      label: i18next.t('buildings:fields.accidentsOwner'),
    },
    {
      id: 'nrEdifici',
      label: i18next.t('buildings:fields.edificesNumber'),
    },
    {
      id: 'nrScale',
      label: i18next.t('buildings:fields.stairsNumber'),
    },
    {
      id: 'nrUi',
      label: i18next.t('buildings:fields.housingUnitNumber'),
    },
    {
      id: 'referente',
      label: i18next.t('buildings:fields.contactPerson'),
      ordering: false,
    },
    {
      id: 'titolo',
      label: i18next.t('buildings:fields.title'),
    },
    {
      id: 'extra.branchObj.name',
      label: i18next.t('buildings:fields.branch.__field'),
      ordering: false,
    },
  ],
}
