import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, useDebounce } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import { Grid, FormControl } from '@mui/material'
import TextField from '@Common/Components/TextField'
import Dropdown from '@Common/Components/Dropdown'

const MyHomeInstallmentsSearchForm = ({ onSearch }) => {
  const isInitialMount = React.useRef(true) // avoid double request cause by onSearch call
  const { t } = useTranslation()
  const { fields, setField } = useForm(
    {
      descrizione: '',
      scadenza: '',
    },
    true,
  )
  const debouncedFields = useDebounce(fields, 300)

  // just run on update, no mount to avoid calling onSearch if not needed
  // because it causes a new request to be performed (changing qsAdditions)
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      onSearch(fields)
    }
  }, [JSON.stringify(debouncedFields)])

  return (
    <Grid container rowSpacing={3} spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <TextField
            label={t('myHome:fields.Description')}
            value={fields.descrizione}
            onChange={setStateFromEvent(setField('descrizione'))}
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <Dropdown
            options={[{id: "paid", descrizione: t("myHome:fields.Paid")}, {id: "expired", descrizione: t("myHome:ui.Expired")}, {id: "due", descrizione: t("myHome:ui.ToBePaid")}]}
            labelKey="descrizione"
            valueKey="id"
            onChange={setStateFromEvent(setField('stato'))}
            value={fields.stato}
            label={t('myHome:fields.Status')}
          />
        </FormControl>
      </Grid> */}
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <Dropdown
            clearable={false}
            options={[{id: "expired", descrizione: t("myHome:fields.Expired")}, {id: "not_expired", descrizione: t("myHome:fields.NotExpired")}]}
            labelKey="descrizione"
            valueKey="id"
            onChange={setStateFromEvent(setField('scadenza'))}
            value={fields.scadenza}
            label={t('myHome:fields.Deadline')}
          />
        </FormControl>
      </Grid>
      
    </Grid>
  )
}

MyHomeInstallmentsSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
}

export default MyHomeInstallmentsSearchForm
