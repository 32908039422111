import i18next from 'i18next'
import PropTypes from 'prop-types'

import { EmployeeType } from '@Auth/Models/Employee'

const RoleSupervisorType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
})

export const RoleType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  supervisor: RoleSupervisorType,
})

export const UserType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  employee: EmployeeType,
  role: RoleType,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('auth:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'username',
      label: i18next.t('auth:fields.username'),
    },
    {
      id: 'role.name',
      label: i18next.t('auth:fields.role'),
      ordering: false,
    },
    {
      id: 'employee.firstName',
      label: i18next.t('auth:fields.firstName'),
    },
    {
      id: 'employee.lastName',
      label: i18next.t('auth:fields.lastName'),
    },
    {
      id: 'employee.phone',
      label: i18next.t('auth:fields.phone'),
    },
    {
      id: 'employee.burdenCenter',
      label: i18next.t('auth:fields.burdenCenter'),
      numeric: true,
    },
    {
      id: 'employee.fiscalCode',
      label: i18next.t('auth:fields.fiscalCode'),
    },
    {
      id: 'employee.cityObj.name',
      label: i18next.t('auth:fields.city'),
    },
    {
      id: 'employee.cityObj.provinceObj.name',
      label: i18next.t('auth:fields.province'),
    },
    {
      id: 'employee.cityObj.provinceObj.regionObj.name',
      label: i18next.t('auth:fields.region'),
    },
    {
      id: 'employee.address',
      label: i18next.t('auth:fields.address'),
    },
    {
      id: 'employee.houseNumber',
      label: i18next.t('auth:fields.houseNumber'),
      numeric: true,
    },
    {
      id: 'employee.cap',
      label: i18next.t('auth:fields.cap'),
    },
    {
      id: 'employee.dateIn',
      label: i18next.t('auth:fields.dateIn'),
      date: true,
    },
    {
      id: 'employee.dateOut',
      label: i18next.t('auth:fields.dateOut'),
      date: true,
    },
    {
      id: 'isActive',
      label: i18next.t('auth:fields.active'),
      boolean: true,
    },
  ],
}
