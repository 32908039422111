import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DatePickerField from '@Common/Components/Forms/DatePickerField'

const LastBillDateField = ({ name, control, ...props }) => {
  const { t } = useTranslation()

  return (
    <DatePickerField
      name={name}
      control={control}
      label={t('tms:fields.utilities.lastBillDate')}
      // error={!!errors.deadline}
      // helperText={errors.deadline?.message}
      defaultValue={null}
      {...props}
    />
  )
}

LastBillDateField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  isTaskUpdate: PropTypes.bool,
  errors: PropTypes.object,
}

export default LastBillDateField
