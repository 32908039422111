import { CardContent, Grid, CardHeader, CircularProgress } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getPodPdrBreadcrumbs } from '@Buildings/Utils'
import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'
import { Margin } from '@Common/Components/Styles'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import CondoRecapCardDetail from '@Condo/Components/CondoRecapCardDetail'
import { PodSupplyTypes } from '@Condo/Models/PodPdr'
import { usePodPdrByIdQuery } from '@Condo/Services/Api'

const Main = styled.section``

const BuildingPodPdrMainView = () => {
  const { t } = useTranslation()

  const { id } = useParams()

  const { data } = usePodPdrByIdQuery(id)

  useBreadcrumbs(
    [...getPodPdrBreadcrumbs(data), { label: t('buildings:ui.GeneralData') }],
    data?.buildingObj?.description,
    [data],
  )

  if (!data) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Main>
      <Margin bottom="2rem">
        <CondoRecapCardDetail data={data.buildingObj} />
      </Margin>
      <Margin bottom="2rem">
        <Card variant="outlined">
          <CardHeader title={t('condo:podpdr.titlePod')} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <NameValueList
                  data={[
                    {
                      name: t('condo:podpdr.fields.podSupplyType'),
                      value: data?.podSupplyType
                        ? PodSupplyTypes.find((x) => x.id === data?.podSupplyType).label
                        : null || '-',
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <NameValueList
                  data={[
                    {
                      name: t('condo:podpdr.fields.podSupplier'),
                      value: data?.podSupplier || '-',
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <NameValueList
                  data={[
                    {
                      name: t('condo:podpdr.fields.podNumber'),
                      value: data?.podNumber || '-',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Margin>
      <Margin bottom="2rem">
        <Card variant="outlined">
          <CardHeader title={t('condo:podpdr.titlePdr')} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <NameValueList
                  data={[
                    {
                      name: t('condo:podpdr.fields.pdrNumber'),
                      value: data?.pdrNumber || '-',
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <NameValueList
                  data={[
                    {
                      name: t('condo:podpdr.fields.pdrSupplier'),
                      value: data?.pdrSupplier || '-',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Margin>

      {(data?.notes || data?.lastUpdateUser || data?.modified) && (
        <Margin bottom="2rem">
          <Card variant="outlined">
            <CardHeader title={t('condo:podpdr.titleOther')} />
            <CardContent>
              {data?.notes && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <NameValueList
                      data={[
                        {
                          name: t('condo:podpdr.fields.notes'),
                          value: data?.notes || '-',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              )}
              {data?.lastUpdateUser && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <NameValueList
                      data={[
                        {
                          name: t('condo:podpdr.fields.lastUpdateUser'),
                          value: data?.lastUpdateUserObj?.username || '-',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              )}
              {data?.modified && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <NameValueList
                      data={[
                        {
                          name: t('condo:podpdr.fields.modified'),
                          value:
                            t('condo:podpdr.fields.modifiedValue', {
                              data: dayjs(data?.modified).format('DD/MM/YYYY'),
                            }) + dayjs(data?.modified).format('HH:mm'),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Margin>
      )}
    </Main>
  )
}

export default BuildingPodPdrMainView
