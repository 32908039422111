import React from 'react'
import Menu from '@Common/Components/Menu'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { ArrowDropDown } from '@mui/icons-material'
import { selectActiveHousingUnit, selectHousingUnits, setActiveHousingUnitId } from '../Redux'
import styled, { css } from 'styled-components'

const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
  `}
  margin: 0;
  padding: 0.2rem 0.5rem 0.2rem 1rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: none;
  font-size: 1.2rem;
`

const switchLabel = (housingUnit) => `${housingUnit?.immobile?.denominazione} - ${housingUnit?.codice}`

const MyEstiaHousingUnitSwitch = () => {
  const dispatch = useDispatch()
  const housingUnits = useSelector(selectHousingUnits)
  const housingUnit = useSelector(selectActiveHousingUnit)

  React.useMemo(() => {
    if (!housingUnit) {
      dispatch(setActiveHousingUnitId(housingUnits[0]?.id))
    }
  }, [])

  const handleHousingUnitClick = (housingUnit) => () => {
    dispatch(setActiveHousingUnitId(housingUnit.id))
  }

  const menuItems = housingUnits.map((housingUnit) => ({
    label: switchLabel(housingUnit),
    onClick: handleHousingUnitClick(housingUnit),
  }))

  let labelContent = <CircularProgress size={10} />
  if (housingUnits.length && housingUnit) {
    labelContent = switchLabel(housingUnit)
  }

  return (
    <Menu
      label={
        <Label>
          {labelContent}
          <ArrowDropDown color="white" />
        </Label>
      }
      items={menuItems}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    />
  )
}

export default MyEstiaHousingUnitSwitch
