/* eslint-disable react/prop-types */
import { FormHelperText, Grid, Rating, Typography } from '@mui/material'
import { useContext } from 'react'
import { Controller } from 'react-hook-form'

import { useMuiGrid } from '@Common/Utils/Hooks'

import { ItemContainerContext } from '../ItemContainer'
import { useDefaultFieldProps } from './FormUtilsV2'

export const RatingFieldV2 = (props) => {
  const { control, rules } = useContext(ItemContainerContext)
  const gridProps = useMuiGrid(props)
  const { name } = props
  const defaultProps = useDefaultFieldProps(name, props)

  return (
    <Grid item {...gridProps}>
      <Typography component="legend">{defaultProps.label}</Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <>
            <Rating value={value} onChange={(_, newValue) => onChange(newValue)} />
            <FormHelperText error={defaultProps.error}>{defaultProps.error || defaultProps.helperText}</FormHelperText>
          </>
        )}
      />
    </Grid>
  )
}
