import { hasGroup, hasPermission } from '@Common/Utils/Permissions'
import * as R from 'ramda'
import { ROLES } from '@Auth/Costants'


export const TaskCategoryPermissions = {
  read: hasPermission('tms.view_taskcategory'),
  create: hasPermission('tms.add_taskcategory'),
  update: hasPermission('tms.change_taskcategory'),
  delete: hasPermission('tms.delete_taskcategory'),
}

export const TaskPermissions = {
  read: hasPermission('tms.view_runningtask'),
  export: R.anyPass([hasGroup(ROLES.HQ_IT), hasGroup(ROLES.HQ_MAIN_OFFICE)]),
  create: hasPermission('tms.add_runningtask'),
  update: hasPermission('tms.change_runningtask'),
  delete: hasPermission('tms.delete_runningtask'),
}

export const TaskFlowPermissions = {
  read: hasPermission('tms.view_runningtaskflow'),
  create: hasPermission('tms.add_runningtaskflow'),
  update: hasPermission('tms.change_runningtaskflow'),
  delete: hasPermission('tms.delete_runningtaskflow'),
}
