import i18next from 'i18next'
import PropTypes from 'prop-types'

export const ProviderType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('services:fields.provider.Id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'name',
      label: i18next.t('services:fields.provider.Name'),
    },
    {
      id: 'logo',
      label: i18next.t('services:fields.provider.Logo'),
      ordering: false,
    },
    {
      id: 'description',
      label: i18next.t('services:fields.provider.Description'),
      ordering: false,
    },
  ],
}
