import styled from 'styled-components'

export const Container = styled.div``
export const Header = styled.header`
  position: fixed;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.navbar.main};
  box-shadow: ${({ theme }) => theme.palette.navbar.shadow} 0px 0px 1rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  width: 100%;
  max-width: 100%;
  height: 60px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 6rem 0 3rem;
  text-align: center;
`

export const Image = styled.img`
  max-width: 100%;
  width: 600px;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 2rem 0;
  text-align: center;
`
