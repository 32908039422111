/* eslint-disable react/prop-types */
import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from '@Auth/Redux/Login'
import { canViewAllBranches } from '@Auth/Utils'
import { selectActiveBranch } from '@Branches/Redux'
import { genericConvertSearchQuery } from '@Condo/Utils'

import { SearchableList } from '../../Common/Components/SearchableList'
import { useCondosQuery } from '../Services/Api'

const CondoSearchableList = ({ query, actions }) => {
  let branches = useSelector(selectActiveBranch) || []

  if (typeof branches === 'object') {
    branches = [branches]
  }

  const user = useSelector(selectCurrentUser)

  const qsAdditions = React.useMemo(() => {
    if (canViewAllBranches(user)) {
      return {
        ...query?.qsAdditions,
      }
    }

    return { branch__in: branches.map(R.prop('id')).join(','), ...query?.qsAdditions }
  }, [branches.map(R.prop('id')).join('-'), user?.id])

  const [qs] = React.useState({
    base: {
      ordering: query?.base?.ordering || 'description',
      ...query?.base,
    },
    qsAdditions,
  })

  const { data } = useCondosQuery(genericConvertSearchQuery(qs))

  const formatCondo = (option) => ({
    value: option.id,
    label: option.description,
    description: [option?.fiscalCode, option?.branchObj?.name].filter((i) => i).join(' - '),
  })

  const options = data?.results.map(formatCondo)

  return <SearchableList options={options} actions={actions} />
}

export { CondoSearchableList }
