import { Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Box, Cursor } from '@Common/Components/Styles'

import { FieldType } from '../Types/Field'
import { humanizeSize } from '../Utils/Files'
import { Help } from './Forms'

const DropZone = styled.div`
  align-items: center;
  background: ${(props) => (props.isDragActive ? props.$onDragPalette.main : props.palette.main)};
  border: 2px dashed ${(props) => (props.error ? '#f44336' : props.theme.palette.neutralTwo.main)};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  cursor: pointer;
`

const WarningText = styled.div`
  text-align: center;
  margin: auto;
  font-size: 0.8rem;
  ${({ theme }) =>
    css`
      color: ${theme.palette.darkThree.main};
    `}
`

// eslint-disable-next-line no-unused-vars
const FileField = ({ onDrop, multiple, files, accept, maxFiles, maxSize, error, helperText, warningText }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple, accept, maxFiles, maxSize })

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((file, idx) => idx !== index)
    onDrop(newFiles)
  }

  return (
    <>
      <DropZone
        {...getRootProps()}
        isDragActive={isDragActive}
        error={error}
        palette={theme.palette.lightOne}
        $onDragPalette={theme.palette.lightTwo}
      >
        <input {...getInputProps()} />
        <Box align="center">
          {isDragActive ? t(`common:ui.DropFile${multiple ? 's' : ''}`) : t(`common:ui.DnDFile${multiple ? 's' : ''}`)}
          {warningText && <WarningText>{warningText}</WarningText>}
        </Box>
      </DropZone>
      {helperText && <Help error={error}>{helperText}</Help>}
      {files.length !== 0 && (
        <div style={{ marginTop: '2rem' }}>
          <strong>{t(`common:ui.SelectedFile${multiple ? 's' : ''}`)}:</strong>
          <ul>
            {files.map((f, idx) => (
              <li key={'file-' + idx}>
                <Box direction="row">
                  {f.name} ({humanizeSize(f.size)})
                  <Tooltip title={t('common:actions.Delete')}>
                    <Typography onClick={() => handleRemoveFile(idx)} marginLeft="0.75rem" color="error" variant="span">
                      <Cursor type="pointer">&times;</Cursor>
                    </Typography>
                  </Tooltip>
                </Box>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

FileField.defaultProps = {
  multiple: false,
  accept: null,
  maxFiles: null,
  maxSize: null,
}

FileField.propTypes = {
  ...FieldType,
  onDrop: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func,
  multiple: PropTypes.bool,
  files: PropTypes.array,
  accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
}

export default FileField
