import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import CreditCardIcon from '@mui/icons-material/CreditCard'

import PopoverSepaPayment from '@Payments/Components/PopoverSepaPayment'

const urlsPodPdrs = {
  base: 'podpdrs',
  list: '',
  main: 'main',
  detail: {
    base: ':id/*',
    main: 'main',
  },
}

const urlsReconciliationReminder = {
  base: 'reconciliation-reminders',
  list: '',
}

const urlsInsurancePolicy = {
  base: 'insurance-policy',
  list: '',
  main: 'main',
  detail: {
    base: ':id/*',
    main: 'main',
  },
}

const urlsSupplier = {
  base: 'supplier',
  list: '',
  main: 'main',
  detail: {
    base: ':id/*',
    main: 'main',
  },
}

const urlsBuilding = {
  base: '/buildings/*',
  list: '',
  detail: {
    base: ':buildingId/*',
    main: 'main',
    structure: 'structure',
    facilities: 'facilities',
    installments: 'installments',
    documents: 'documents',
    residents: 'residents',
    podpdrs: {
      ...urlsPodPdrs,
      podpdrs: '',
      base: 'podpdrs/',
    },
    insurancePolicy: {
      ...urlsInsurancePolicy,
      insurancePolicy: '',
      base: 'insurance-policy/',
    },
    reconciliationReminders: {
      ...urlsReconciliationReminder,
      reconciliationReminders: '',
    },
    supplier: {
      ...urlsSupplier,
      base: 'supplier/*',
      supplier: 'suppliers',
    },
  },
}

const config = {
  logger: {
    level: process.env.REACT_APP_LOGGER_LEVEL, // DEBUG, INFO, WARNING, ERROR
  },
  payment: {
    methods: [
      {
        id: 'card',
        name: 'payments:ui.creditCard',
        short_name: 'payments:ui.creditCard',
        subtitle: '',
        icon: <CreditCardIcon />,
      },
      {
        id: 'sepa_debit',
        name: 'payments:ui.sepaDirectDebit',
        short_name: 'payments:ui.sepaDirectDebit',
        subtitle: '',
        icon: <AccountBalanceIcon />,
        popover: <PopoverSepaPayment />,
      },
      {
        id: 'bank',
        name: 'payments:ui.bankTransfer',
        short_name: 'payments:ui.bankTransfer',
        subtitle: '',
        icon: <AccountBalanceWalletIcon />,
      },
    ],
  },
  apiBasePath: process.env.REACT_APP_API_BASE_PATH,
  wsBasePath: process.env.REACT_APP_WS_BASE_PATH,
  wsUrls: {
    notifications: '/notification-center',
    reportChat: '/report-chat/:reportId',
  },
  urls: {
    home: '/',
    login: '/login',
    register: '/register/:invitationToken',
    confirmRegistration: '/confirm-registration/:token',
    password: {
      forgot: '/forgot-password',
      reset: '/reset-password/:token',
    },
    admin: {
      base: '/admin/*',
      dashboard: '',
      employees: 'employees',
      residents: 'residents',
      branches: 'branches',
      buildings: {
        ...urlsBuilding,
        base: 'buildings/*',
        buildings: '',
      },
      invitations: 'invitations',
      notifications: 'notifications',
      buildingInvitations: 'invitations/building/:buildingId',
      anagraphicsInvitations: 'invitations/anagraphics',
      news: 'news',
      services: {
        base: 'services/*',
        services: '',
        providers: 'providers',
        labels: 'labels',
        categories: 'categories',
      },
      tickets: {
        base: 'reports/*',
        reports: '',
        report: ':reportId',
        types: 'types',
      },
      podpdrs: {
        ...urlsPodPdrs,
        base: 'podpdrs/*',
        podPdrs: '',
      },
      insurancePolicy: {
        ...urlsInsurancePolicy,
        base: 'insurance-policy/*',
        insurancePolicy: '',
      },
      reconciliationReminders: {
        ...urlsReconciliationReminder,
        base: 'reconciliation-reminders/*',
        reconciliationReminders: '',
      },
      supplier: {
        ...urlsSupplier,
        base: 'supplier/*',
        supplier: 'suppliers',
      },
    },
    buildings: urlsBuilding,
    podpdrs: {
      ...urlsPodPdrs,
      base: '/podpdrs/*',
      podPdrs: '',
    },
    insurancePolicy: {
      ...urlsInsurancePolicy,
      base: '/insurance-policy/*',
      insurancePolicy: '',
    },
    reconciliationReminders: {
      ...urlsReconciliationReminder,
      base: '/reconciliation-reminders/*',
      reconciliationReminders: '',
    },
    supplier: {
      ...urlsSupplier,
      base: '/supplier/*',
      supplier: '',
      list: 'suppliers',
    },
    customerCare: {
      base: '/customer/care/*',
      main: '',
      anagraphic: 'anagraphic/:residentId',
    },
    tms: {
      base: '/tms/*',
      root: '',
      taskList: 'tasks',
      taskDetail: 'tasks/:taskId',
      categories: 'categories',
      createCategory: 'categories/new',
      editCategory: 'categories/:category',
    },
    myEstia: {
      base: '/my-estia/*',
      home: '',
      profile: 'profile',

      services: {
        base: '/services/*',
        main: '',
        detail: 'serviceId/',
        category: 'categoryId/',
      },
      myHome: {
        base: ':housingUnitId/*',
        main: '',
        installments: 'installments',
        documents: 'documents/:type',
        ticket: {
          base: 'ticket/*',
          list: '',
          detail: ':reportId',
          new: 'new',
        },
      },
      news: 'news',
      myServices: {
        main: 'services/:service',
        serviceDetail: {
          main: 'detail/:category/:service',
        },
      },
    },
    maintenance: '/503',
    serverError: '/500/:error',
    pageNotFound: '/404',
    forbidden: '/403',
    successPayment: '/success-payment',
    errorPayment: '/error-payment',
  },
  contacts: {
    supportEmail: 'support@estia.homes',
  },
  localStorageInvalidation: {
    // [datatable name]: Date getTime
    'admin-invitations_DATA_TABLE_SETTINGS': 1656339027908,
    'admin-news_DATA_TABLE_SETTINGS': 1655146800000,
    'admin-notifications_DATA_TABLE_SETTINGS': 1655146800000,
    'admin-services_DATA_TABLE_SETTINGS': 1655146800000,
    'admin-providers_DATA_TABLE_SETTINGS': 1655146800000,
    'admin-labels_DATA_TABLE_SETTINGS': 1655146800000,
    'admin-categories_DATA_TABLE_SETTINGS': 1655146800000,
    'admin-condo-buildings_DATA_TABLE_SETTINGS': 1657026000000,
    'operational-building-residents_DATA_TABLE_SETTINGS': 1656339027908,
    'customercare-search-anagraphics_DATA_TABLE_SETTINGS': 1657026000000,
    'condo-buildings_DATA_TABLE_SETTINGS': 1657026000000,
    'condo-building-podpdrs_DATA_TABLE_SETTINGS': 1657026000000,
    'all-podpdrs_DATA_TABLE_SETTINGS': 1657026000000,
    'branch-condo_DATA_TABLE_SETTINGS': 1657026000000,
    'admin-condos_DATA_TABLE_SETTINGS': 1657026000000,
    'all-insurance-policy_DATA_TABLE_SETTINGS': 1657026000000,
    'condo-building-insurance-policy_DATA_TABLE_SETTINGS': 1657026000000,
    'reconciliation-reminder_DATA_TABLE_SETTINGS': 1657026000000,
    'tms-running-tasks_DATA_TABLE_SETTINGS': 1683853259000,
  },
}

export default config

export const makePath = (selector, context) => {
  const parts = selector.split('.')
  parts.pop() // remove leaf
  const parents = []
  parts.reduce((acc, curr) => {
    if (acc[curr]?.base) {
      parents.push(acc[curr]?.base)
    }
    return acc ? acc[curr] : null
  }, config.urls)

  let path = selector.split('.').reduce((acc, curr) => (acc ? acc[curr] : null), config.urls)

  // compose path
  path = parents.join('').replace(/\*/g, '') + (path ? `${path}` : '')

  // apply context
  if (context) {
    Object.keys(context).forEach((key) => {
      path = path.replace(`:${key}`, context[key])
    })
  }

  return path
}
