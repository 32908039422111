import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Box, FormControl, Grid, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Checkbox from '@Common/Components/Checkbox'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import DatePicker from '@Common/Components/DatePicker'
import { TextNoWrap } from '@Common/Components/Styles'
import { setStateFromCheckboxEvent } from '@Common/Utils/Events'
import { useConfirm, useCurrentUser } from '@Common/Utils/Hooks'
import { isAdminRoute } from '@Common/Utils/Url'
import { CondoAcquisitionMode } from '@Condo/Models/Condo'
import { LegalPracticesPresenceTypes, ReconciliationReminder } from '@Condo/Models/ReconciliationReminder'
import { ReconciliationReminderPermissions } from '@Condo/Permissions'
import { useCondoReconciliationReminderQuery } from '@Condo/Services/Api'
import { getDisplayColumnsCondo, getFieldsMappingCondo } from '@Condo/Utils'

import { selectActiveBranch } from '../../Branches/Redux'
import ButtonDialogWithOutCondoReference from './ButtonDialogWithOutCondoReference'

const FilterContainer = styled.div`
  padding-left: 0.5rem;
  flex-grow: 1;
`

const SEARCH_FIELDS = ['building__description', 'building__fiscal_code', 'bank', 'notes']

const ReconciliationReminderList = ({ onEdit, onDelete, onCreate, buildingId = null }) => {
  const user = useCurrentUser()

  const branch = useSelector(selectActiveBranch)
  const isAdmin = isAdminRoute()

  const tableName = isAdmin ? 'admin-reconciliation-reminder' : 'reconciliation-reminder'

  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])

  const [legalPracticesPresenceFilter, setLegalPracticesPresenceFilter] = React.useState(false)
  const [lastReconciliationDateFrom, setLastReconciliationDateFrom] = React.useState(null)
  const [lastReconciliationDateTo, setLastReconciliationDateTo] = React.useState(null)
  const [lastReminderDateFrom, setLastReminderDateFrom] = React.useState(null)
  const [lastReminderDateTo, setLastReminderDateTo] = React.useState(null)

  const FIELDS_MAPPING = {
    ...getFieldsMappingCondo(isAdmin),
    buildingAcquisitionMode: (record) =>
      CondoAcquisitionMode.find((x) => x.id === record?.buildingObj?.acquisitionMode)?.label,
    legalPracticesPresence: (record) =>
      LegalPracticesPresenceTypes.find((x) => x.id === record.legalPracticesPresence)?.label,
    status: (
      // eslint-disable-next-line react/display-name
      r,
    ) => {
      // see api for implementation detail python
      // https://github.com/estia-homes/estia-server/tree/master/estia-server/condo/serializers.py

      if (r.inEvidence === 'Y') {
        return (
          <Tooltip title={t('condo:reconciliationReminder.statusMessage.Y')}>
            <ReportProblemIcon color="warning" />
          </Tooltip>
        )
      }

      if (r.inEvidence === 'YY') {
        return (
          <Tooltip title={t('condo:reconciliationReminder.statusMessage.YY')}>
            <ReportProblemIcon color="error" />
          </Tooltip>
        )
      }

      return ''
    },
  }

  const convertSearchQuery = (qs) => {
    qs = JSON.parse(JSON.stringify(qs))

    if (qs.qsAdditions?.search) {
      SEARCH_FIELDS.forEach((field) => {
        qs.qsAdditions[`${field}__icontains__or`] = qs.qsAdditions.search
      })

      delete qs.qsAdditions.search
    }

    if (qs.qsAdditions.legalPracticesPresenceFilter) {
      qs.qsAdditions['legal_practices_presence'] = 'Y'
      delete qs.qsAdditions.legalPracticesPresenceFilter
    }

    /**
     * This code is REALLY BAD, for some reason at the moment unknown (maybe redux),
     * if we generalize it into a function it goes in an infinite loop.
     * We leave this for the moment but refactoring should be done
     */

    if (qs.qsAdditions.lastReconciliationDateFrom) {
      qs.qsAdditions['last_reconciliation_date__gte'] = dayjs(qs.qsAdditions.lastReconciliationDateFrom).format(
        'YYYY-MM-DD',
      )
      delete qs.qsAdditions.lastReconciliationDateFrom
    }

    if (qs.qsAdditions.lastReconciliationDateTo) {
      qs.qsAdditions['last_reconciliation_date__lte'] = dayjs(qs.qsAdditions.lastReconciliationDateTo).format(
        'YYYY-MM-DD',
      )
      delete qs.qsAdditions.lastReconciliationDateTo
    }

    if (qs.qsAdditions.lastReminderDateFrom) {
      qs.qsAdditions['last_reminder_date__gte'] = dayjs(qs.qsAdditions.lastReminderDateFrom).format('YYYY-MM-DD')
      delete qs.qsAdditions.lastReminderDateFrom
    }

    if (qs.qsAdditions.lastReminderDateTo) {
      qs.qsAdditions['last_reminder_date__lte'] = dayjs(qs.qsAdditions.lastReminderDateTo).format('YYYY-MM-DD')
      delete qs.qsAdditions.lastReminderDateTo
    }

    if (qs.qsAdditions.buildingBranchIn && branch) {
      qs.qsAdditions['building__branch__in'] = [branch.id]
      delete qs.qsAdditions.buildingBranchIn
    }

    return qs
  }

  const DISPLAY_COLUMNS = React.useMemo(() => {
    let columns = [
      'buildingAcquisitionMode',
      'bank',
      'legalPracticesPresence',
      'lastReconciliationDate',
      'lastReminderDate',
      'notes',
    ]

    if (!buildingId) {
      columns = getDisplayColumnsCondo().concat(columns)
    }

    columns.unshift('status')

    return columns
  })

  // default qs
  const qsAdditions = React.useMemo(() => {
    return {
      buildingBranchIn: branch?.id ? [branch.id] : null,
      legalPracticesPresenceFilter,
      lastReconciliationDateFrom,
      lastReconciliationDateTo,
      lastReminderDateFrom,
      lastReminderDateTo,
    }
  }, [
    branch,
    legalPracticesPresenceFilter,
    lastReconciliationDateFrom,
    lastReconciliationDateTo,
    lastReminderDateFrom,
    lastReminderDateTo,
  ])

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage(tableName),
      offset: 0,
      ordering: 'last_reconciliation_date',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback((data) => setQs(data), [setQs])

  // new qs triggers refetch
  const { data, isFetching } = useCondoReconciliationReminderQuery({
    condo_id: buildingId ?? null,
    qs: convertSearchQuery(qs),
  })

  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        icon: <Edit />,
        label: t('common:actions.Edit'),
        perm: ReconciliationReminderPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: ReconciliationReminderPermissions.delete,
      },
    ],
    [data?.count],
  )

  const handleAction = React.useCallback(
    (actionId, reconciliationReminder) => {
      switch (actionId) {
        case 'EDIT':
          onEdit(reconciliationReminder)
          break
        case 'DELETE':
          openDeleteConfirm(
            selected, // payload
            t('condo:ui.DeleteReconciliationReminderConfirmationTitle'),
            t('condo:ui.DeleteReconciliationReminderConfirmationText', { count: selected.length }),
          )
          break
      }
    },
    [selected],
  )

  const handleDeleteProvider = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteProvider)

  const renderDateFilter = (
    <Box mb={2}>
      <Grid container spacing={3} columns={{ xs: 1, md: 2, lg: 4 }}>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <DatePicker
              label={t('condo:reconciliationReminder.filters.lastReconciliationDate.from')}
              value={lastReconciliationDateFrom || null}
              onChange={(date) => setLastReconciliationDateFrom(date)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <DatePicker
              label={t('condo:reconciliationReminder.filters.lastReconciliationDate.to')}
              value={lastReconciliationDateTo || null}
              onChange={(date) => setLastReconciliationDateTo(date)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <FormControl fullWidth>
            <DatePicker
              label={t('condo:reconciliationReminder.filters.lastReminderDate.from')}
              value={lastReminderDateFrom || null}
              onChange={(date) => setLastReminderDateFrom(date)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <DatePicker
              label={t('condo:reconciliationReminder.filters.lastReminderDate.to')}
              value={lastReminderDateTo || null}
              onChange={(date) => setLastReminderDateTo(date)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )

  const toolbar = (
    <>
      {ReconciliationReminderPermissions.create(user) && !buildingId && (
        <ButtonDialogWithOutCondoReference
          onCreate={onCreate}
          referenceType={'reconciliation'}
          title={t('condo:ui.CondoWithoutPaymentReminderDate')}
          titleBtnCreate={t('condo:ui.CreateReconciliationReminder')}
        />
      )}
    </>
  )

  return (
    <div>
      {!buildingId && renderDateFilter}

      <DataTable
        name={tableName}
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={ReconciliationReminder}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={buildingId ? [] : SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
        toolbarContent={toolbar}
      >
        {!buildingId && (
          <FilterContainer>
            <Checkbox
              checked={legalPracticesPresenceFilter}
              onChange={setStateFromCheckboxEvent(setLegalPracticesPresenceFilter)}
              label={<TextNoWrap>{t('condo:reconciliationReminder.filters.legalPracticesPresenceFilter')}</TextNoWrap>}
            />
          </FilterContainer>
        )}
      </DataTable>
      {DeleteConfirm}
    </div>
  )
}

ReconciliationReminderList.propTypes = {
  buildingId: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
}

export { ReconciliationReminderList }
