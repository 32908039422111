import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Main = styled.main`
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    color: ${theme.palette.background.constrastText};
  `}
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
`

const MyHomeTmsLayout = ({ children }) => {
  return (
    <Wrapper>
      <Main>{children}</Main>
    </Wrapper>
  )
}

MyHomeTmsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default MyHomeTmsLayout
