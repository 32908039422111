import dayjs from 'dayjs'
import i18next from 'i18next'
import * as R from 'ramda'

import { checkNumber, checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  let err = {}

  let required = [
    'building',
    'company',
    'number',
    'premium',
    'premiumPeriodicity',
    'effectiveDate',
    'expirationDate',
    'cancellationNoticeDays',
    'nextPremiumPaymentDate',
  ]

  checkNumber(err, fields, 'cancellationNoticeDays')

  checkRequired(err, required, fields)

  if (dayjs(fields.expirationDate)?.isBefore(fields.effectiveDate, 'days')) {
    err['expirationDate'] = i18next.t('condo:insurancePolicy.errors.expirationDate')
  }

  if (dayjs(fields.finalCancellationDate)?.isAfter(fields.expirationDate, 'days')) {
    err['finalCancellationDate'] = i18next.t('condo:insurancePolicy.errors.finalCancellationDate')
  }

  if (dayjs(fields.nextPremiumPaymentDate)?.isBefore(fields.lastPremiumPaymentDate, 'days')) {
    err['nextPremiumPaymentDate'] = i18next.t('condo:insurancePolicy.errors.nextPremiumPaymentDate')
  }

  setErrors(err)
  return R.isEmpty(err)
}

export const clearError = (field, errors, setErrors) => {
  setErrors({ ...errors, ...{ [field]: null } })
}

export const formatDate = (date) => (date ? dayjs(date).format('YYYY-MM-DD') : null)
