import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CopyIcon from '@mui/icons-material/FileCopy'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import DataTable from '@Common/Components/DataTable'
import { useConfirm } from '@Common/Utils/Hooks'
import { apiList } from '@Core/Services/Api'
import TaskCategory from '@Tms/Models/TaskCategory'
import { TaskCategoryPermissions } from '@Tms/Permissions'
import { useCategoriesQuery } from '@Tms/Services/Api'

const StatusBadge = styled(Chip)`
  ${({ theme, variant }) => css`
    text-align: center;
    ${variant === 'draft' && `color: white;`}
    ${variant === 'draft' && `background-color: ${theme.palette.neutralTwo.main};`}
    ${variant === 'ready' && `background-color: ${theme.palette.success.main};`}
    ${variant === 'used' && `background-color: ${theme.palette.warning.main};`}
  `}
`

const DISPLAY_COLUMNS = ['id', 'name', 'parent.name', 'created', 'modified', 'isActive']
const SEARCH_FIELDS = ['name', 'parent.name']

const TaskCategoryList = ({ onEdit, onDuplicate, onDelete, onChangeIsActive }) => {
  const { t } = useTranslation()
  const { data: categories, isFetching } = apiList(useCategoriesQuery())
  const [selected, setSelected] = React.useState()

  const handleDeleteCategory = async (category) => {
    onDelete(category)
  }
  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteCategory)

  const FIELDS_MAPPING = {
    // eslint-disable-next-line react/display-name
    isActive: (c) => (
      <StatusBadge
        variant={!c.isActive ? 'draft' : c.hasTaskFlows ? 'used' : 'ready'}
        size="small"
        label={t(`tms:ui.Status${!c.isActive ? 'Draft' : c.hasTaskFlows ? 'InUse' : 'Ready'}`)}
      />
    ),
  }

  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon />,
        perm: TaskCategoryPermissions.update,
      },
      {
        id: 'DUPLICATE',
        label: t('common:actions.Duplicate'),
        icon: <CopyIcon />,
        perm: TaskCategoryPermissions.create,
      },
      {
        id: 'ACTIVATE',
        label: t('tms:actions.Activate'),
        icon: <CheckIcon size="small" />,
        perm: TaskCategoryPermissions.update,
      },
      {
        id: 'DEACTIVATE',
        label: t('tms:actions.Deactivate'),
        icon: <ClearIcon size="small" />,
        perm: TaskCategoryPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: R.useWith(R.and, [TaskCategoryPermissions.delete, R.propEq('hasTaskFlows', false)]),
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, category) => {
      switch (actionId) {
        case 'DELETE':
          openDeleteConfirm(
            category, // payload
            t('tms:ui.DeleteCategoryConfirmationTitle'),
            t('tms:ui.DeleteCategoryConfirmationText'),
            { selected: selected, field: 'name' },
          )
          break
        case 'EDIT':
          onEdit(category)
          break
        case 'DUPLICATE':
          onDuplicate(category.id)
          break
        case 'ACTIVATE':
          onChangeIsActive(category.id, true)
          break
        case 'DEACTIVATE':
          onChangeIsActive(category.id, false)
          break
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      <DataTable
        name="tms-task-categories"
        data={categories}
        model={TaskCategory}
        sortField="id"
        sortDirection="desc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noBulkSelection
        noExport
      />
      {DeleteConfirm}
    </div>
  )
}

TaskCategoryList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeIsActive: PropTypes.func.isRequired,
}

export default TaskCategoryList
