import { Button as MuiButton } from '@mui/material'
import styled from 'styled-components'

const StyledButton = styled(MuiButton)`
  color: #000;
  border-radius: 20px;
`

const Button = (props) => {
  return <StyledButton color="secondary" {...props} />
}

export default Button
