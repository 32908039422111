import PropTypes from 'prop-types'

const Logo = ({ fill, width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314 84.18" width={width} height={height}>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <g id="logo">
          <path fill={fill}
            /* eslint-disable-next-line max-len */
                d="M285.6,51.62a10,10,0,0,0-.24-2.44,7.71,7.71,0,0,0-5.68-6c-3.59-.79-6.75.31-8.56,3-2,3-2,6.2-1,9.49a7.83,7.83,0,0,0,12.81,3c2-2,2.49-4.47,2.64-7.11m-.92-30.56h1.06c9,0,18,0,27.06,0,1,0,1.2.29,1.2,1.24q0,29.63,0,59.26c0,1-.26,1.22-1.23,1.21q-13.47,0-26.94,0c-.81,0-1.17,0-1-1.08.32-2,.41-4.06.72-6.08.05-.32.28-.82-.23-1s-.72.14-1,.43a36.69,36.69,0,0,1-6.76,5.81A21.17,21.17,0,0,1,263.09,84a22.9,22.9,0,0,1-10.86-4.42,27.41,27.41,0,0,1-6.94-7.81,36.57,36.57,0,0,1-5.12-14.67,38.8,38.8,0,0,1,2.61-19.75A30.72,30.72,0,0,1,249.4,27a23.4,23.4,0,0,1,14-7.1A23.91,23.91,0,0,1,277.28,22a19.49,19.49,0,0,1,7.09,6,.8.8,0,0,0,1,.31c.41-.11.25-.54.22-.81-.28-2.09-.58-4.18-.9-6.42" />
          <path fill={fill}
            /* eslint-disable-next-line max-len */
                d="M42.91,44.24c1,0,1.09-.41,1-1.18A10,10,0,0,0,42,38.59c-3-3.84-9.35-3.26-11.22.54a13.79,13.79,0,0,0-1.22,4.45c-.09.68.36.64.81.64ZM30.49,56.93c-.71,0-1.15.09-1,.93.35,2,.21,4,1,5.88,1.3,2.95,3.06,4,6.7,4a6.14,6.14,0,0,0,5.54-4.22,1.13,1.13,0,0,1,1.29-.94q13.41,0,26.83,0c.94,0,1.12.18.8,1.1A25.79,25.79,0,0,1,63.43,75.4C58,79.87,51.7,82.23,44.85,83.48a45,45,0,0,1-10.54.62c-8.24-.5-16.06-2.56-22.77-7.55A27.38,27.38,0,0,1,.58,59a32,32,0,0,1-.58-6A34.3,34.3,0,0,1,2.83,39.13a30.5,30.5,0,0,1,6.4-9.4,35.35,35.35,0,0,1,18.66-9.41,44.76,44.76,0,0,1,10.89-.61,42.73,42.73,0,0,1,14,3.24,34.51,34.51,0,0,1,11.61,7.83,32.07,32.07,0,0,1,8.25,15.59,60.37,60.37,0,0,1,.89,7.69,15.39,15.39,0,0,1-.2,2.42c0,.65-.53.45-.87.45C69.54,56.94,37.44,57,30.49,56.93Z" />
          <path fill={fill}
            /* eslint-disable-next-line max-len */
                d="M179.71,82.75c0,.89-.19,1.18-1.14,1.18q-13.87-.06-27.77,0c-.92,0-1.16-.23-1.16-1.15,0-12,0-24.06.05-36.09,0-.94-.18-1.35-1.2-1.23-1.73.21-3.46.31-5.19.49-.82.08-1.15-.1-1.14-1,0-7.15,0-14.3,0-21.44,0-.8.1-1,1-.88,1.92.27,3.87.25,5.81.37.89.05.64-.6.64-1q0-8.91,0-17.81c0-1.79,0-1.79,1.78-1.79,9,0,18,0,27.06,0,1,0,1.21.27,1.21,1.23-.05,6.09,0,12.19-.05,18.28,0,.89.19,1.23,1.15,1.16,2.62-.19,5.27-.14,7.89-.47.77-.1,1.08.08,1.08,1q-.06,10.71,0,21.44c0,.94-.32,1.1-1.14,1-2.63-.18-5.26-.29-7.89-.49-.9-.07-1.09.24-1.09,1.1Z" />
          <path fill={fill}
            /* eslint-disable-next-line max-len */
                d="M111.38,19.65a58.42,58.42,0,0,1,17.93,2.64,25.89,25.89,0,0,1,5.29,2.25c.37.22.65.31.42.86q-3.35,7.65-6.62,15.32c-.29.7-.48.81-1.18.34a25.6,25.6,0,0,0-10-4.18,12.32,12.32,0,0,0-5.06-.05c-1.4.36-2.21,1.14-2.29,2.27-.13,2,.37,2.92,2.1,3.5a31.49,31.49,0,0,0,4.73,1c4.82.83,9.58,1.8,13.74,4.65a14.16,14.16,0,0,1,5.91,9.16,20.28,20.28,0,0,1-1.49,12.84,19.54,19.54,0,0,1-5.55,6.9c-4.49,3.72-9.82,5.5-15.43,6.48a43.55,43.55,0,0,1-11,.35c-8.15-.66-15.76-3.22-23.25-6.27-.5-.2-.94-.29-.58-1.15,2.38-5.62,4.66-11.27,7-16.92.37-.91.91-.26,1.22-.06a65.08,65.08,0,0,0,5.94,3.31,25.28,25.28,0,0,0,10.23,2.91,4.82,4.82,0,0,0,3.8-1.36A3.35,3.35,0,0,0,106,59.05a27.62,27.62,0,0,0-4.86-.93c-4.37-.77-8.64-1.81-12.35-4.43a14.29,14.29,0,0,1-6.14-11.45,18.32,18.32,0,0,1,3.1-11.64A22.55,22.55,0,0,1,96.3,22.49a37.53,37.53,0,0,1,15.08-2.84" />
          <path fill={fill}
            /* eslint-disable-next-line max-len */
                d="M190.18,61.16c.24-.29.32-.41.42-.52,3-3.07,16-16.69,19.61-20.36.78-.79,1.5-1.66,2.28-2.47.34-.36.6-.47.83.1.09.2.32.34.48.51,3,3.07,15.82,16.44,19.27,20,.79.81,1.58,1.66,2.45,2.71-2.05,0-3.91.05-5.76,0-.89,0-1.1.23-1.1,1.1,0,6.84,0,13.67,0,20.51,0,.9-.21,1.18-1.15,1.16-3.08-.06-6.17,0-9.25,0-.88,0-1.15-.29-1.14-1.15.05-2.58,0-5.16,0-7.73,0-.75-.16-1-1-1-2.26.06-4.53,0-6.79,0-.71,0-.95.17-.93.91,0,2.65,0,5.31,0,8,0,.89-.33,1-1.09,1-3.12,0-6.25-.06-9.37,0-.89,0-1.1-.23-1.09-1.1,0-6.84,0-13.67,0-20.51,0-.89-.2-1.21-1.15-1.17-1.83.08-3.66,0-5.71,0" />
          <path fill={fill} d="M228.21,15.35A15.34,15.34,0,1,1,213,0a15.39,15.39,0,0,1,15.22,15.35" />
        </g>
      </g>
    </g>
  </svg>
)

Logo.defaultProps = {
  fill: '#3e6eb4',
  width: '100%',
  height: '100%',
}

Logo.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default Logo
