import PropTypes from 'prop-types'
import React from 'react'

import { useEmployeesByRolesQuery } from '@Auth/Services/Api'
import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'

const EmployeeByRoleSelect = ({ roles, guessByName, value, labelCallback, ...props }) => {
  const { data } = useEmployeesByRolesQuery(roles)

  React.useEffect(() => {
    if (guessByName) {
      guessByName(data?.results)
    }
  }, [data])

  const options = data?.results.map((e) => ({
    id: e.id,
    username: labelCallback ? labelCallback(e) : e.firstName + ' ' + e.lastName,
  }))

  return (
    <Dropdown
      options={options || []}
      labelKey="username"
      valueKey="id"
      value={options?.length > 0 ? value : ''}
      {...props}
    />
  )
}

EmployeeByRoleSelect.propTypes = {
  ...FieldType,
  roles: PropTypes.array.isRequired,
  guessByName: PropTypes.func,
  labelCallback: PropTypes.func,
}

export default EmployeeByRoleSelect
