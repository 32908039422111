/* eslint-disable react/prop-types */
import { Grid } from '@mui/material'
import { Controller } from 'react-hook-form'

import { useMuiGrid } from '@Common/Utils/Hooks'

import Checkbox from '../Checkbox'
import { useDefaultFieldProps } from './FormUtilsV2'

export const CheckboxFieldV2 = (props) => {
  const { name, control, ...other } = props
  const gridProps = useMuiGrid(props)
  const defaultProps = useDefaultFieldProps(props.name, props)

  return (
    <Grid item {...gridProps}>
      <Controller
        name={name}
        control={control}
        defaultValue={other?.defaultChecked || false}
        render={({ field: { onChange, value } }) => (
          <Checkbox checked={value} onChange={onChange} {...defaultProps} {...other} />
        )}
      />
    </Grid>
  )
}
