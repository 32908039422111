import React from 'react'
import { useCreateNewsMutation, useUpdateNewsMutation, useDeleteNewsMutation } from '../../Services/Api'
import AddFab from '@Common/Components/AddFab'
import styled from 'styled-components'
import { useCurrentUser, useBreadcrumbs } from '../../../Common/Utils/Hooks'
import { NewsPermissions } from '../../Permissions'
import NewsList from '../../Components/NewsList'
import NewsForm from '../../Forms/NewsForm'
import { useTranslation } from 'react-i18next'
import { responseStringError } from '../../../Common/Utils/Api'
import config from '../../../Config'
import moment from 'moment'
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html'
import { toast } from 'react-toastify'
import * as R from 'ramda'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminNewsView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [createNews] = useCreateNewsMutation()
  const [updateNews] = useUpdateNewsMutation()
  const [deleteNews] = useDeleteNewsMutation()
  const [form, setForm] = React.useState({ isOpen: false, news: null })
  const handleOpenForm = (news) => () => setForm({ isOpen: true, news })
  const handleCloseForm = () => setForm({ isOpen: false, news: null })


  useBreadcrumbs([{ label: t('common:navigation.News'), path: config.urls.admin.news, Icon: 'Newspaper' }])

  const handleSave = async (fields) => {
    const formData = new FormData()
    formData.append('title', fields.title)
    if (fields.image.length) {
      formData.append('image', fields.image[0])
    }
    formData.append('abstract', fields.abstract)
    formData.append('date', moment(fields.date).format('YYYY-MM-DD'))
    formData.append('content', draftToHtml(convertToRaw(fields.content.getCurrentContent())))
    formData.append('featured', fields.featured)
    formData.append('published', fields.published)

    try {
      const response = form.news
        ? await updateNews({id: form.news.id, news: formData }).unwrap()
        : await createNews(formData).unwrap()
      toast.success(t(`news:success.News${form.news ? 'Updated' : 'Created'}`, { title: fields.title }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t(`news:errors.${form.news ? 'Update' : 'Create'}NewsError`, { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (news) => {
    try {
      const ids = news.map(R.prop('id'))
      const promises = ids.map(async (id) => {
        return deleteNews(id).unwrap()
      })
      await Promise.all(promises)
      toast.success(
         t('news:success.NewsDeleted', {
          count: news.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('news:errors.DeleteNewsError', {
          error: err.data?.detail || t('common.errors.UnknownError'),
          count: news.length,
        }),
      )
    }
  }

  return (
    <Main>
      <NewsList onEdit={(news) => setForm({ isOpen: true, news })} onDelete={handleDelete} />
      {form.isOpen && (
        <NewsForm news={form.news} onClose={handleCloseForm} onSave={handleSave} />
      )}
      {NewsPermissions.create(user) && <AddFab onClick={handleOpenForm(null)} />}
    </Main>
  )
}

export default AdminNewsView
