import { api } from '@Core/Services/Api'

const prefix = 'documents'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    financialStatementsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/accounting/financialstatements/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'AccountingDocuments', id: arcadiaId }],
    }),
    financialDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/accounting/financial/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'AccountingDocuments', id: arcadiaId }],
    }),
    contractsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/contracts/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'ContractsDocuments', id: arcadiaId }],
    }),
    worksDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/extraordinaryworks/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'WorksDocuments', id: arcadiaId }],
    }),
    accidentsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/general/accidents/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'GeneralDocuments', id: arcadiaId }],
    }),
    facilitiesDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/general/facilities/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'GeneralDocuments', id: arcadiaId }],
    }),
    genericsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/general/generics/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'GeneralDocuments', id: arcadiaId }],
    }),
    leasesDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/general/leases/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'GeneralDocuments', id: arcadiaId }],
    }),
    legalPracticesDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/general/legalpractices/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'GeneralDocuments', id: arcadiaId }],
    }),
    personalDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/personal/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'PersonalDocuments', id: arcadiaId }],
    }),
    invoicesDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/invoices/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'InvoicesDocuments', id: arcadiaId }],
    }),
    meetingsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/meetings/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'InvoicesDocuments', id: arcadiaId }],
    }),
    securityDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/security/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'SecurityDocuments', id: arcadiaId }],
    }),
    asbestosDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/security/asbestos/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'SecurityDocuments', id: arcadiaId }],
    }),
    supplierQuotesDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/supplier-quotes/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'SupplierQuotesDocuments', id: arcadiaId }],
    }),
    buildingFinancialStatementsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/accounting/building-financialstatements/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'BuildingFinancialStatementsDocuments', id: arcadiaId }],
    }),
    buildingBankStatementsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/building-bank-statements/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'BuildingBankStatementsDocuments', id: arcadiaId }],
    }),
    residentsBankStatementsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/residents-bank-statements/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'ResidentsBankStatementsDocuments', id: arcadiaId }],
    }),
    residentsReceiptsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/residents-receipts/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'ResidentsReceiptsDocuments', id: arcadiaId }],
    }),
    employeesDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/employees/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'EmployeesDocuments', id: arcadiaId }],
    }),
    shipmentSlipsDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/shipment-slips/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'ShipmentSlipsDocuments', id: arcadiaId }],
    }),
    residentsPersonalDocuments: builder.query({
      query: ({ arcadiaId, year }) => `${prefix}/residents-personal/${arcadiaId}${yearQs(year)}`,
      providesTags: (_, __, arcadiaId) => [{ type: 'ResidentsPersonalDocuments', id: arcadiaId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useFinancialStatementsDocumentsQuery,
  useLazyFinancialStatementsDocumentsQuery,
  useTaxDocumentsQuery,
  useLazyFinancialDocumentsQuery,
  useContractsDocumentsQuery,
  useLazyContractsDocumentsQuery,
  useWorksDocumentsQuery,
  useLazyWorksDocumentsQuery,
  useAccidentsDocumentsQuery,
  useLazyAccidentsDocumentsQuery,
  useFacilitiesDocumentsQuery,
  useLazyFacilitiesDocumentsQuery,
  useGenericsDocumentsQuery,
  useLazyGenericsDocumentsQuery,
  useLeasesDocumentsQuery,
  useLazyLeasesDocumentsQuery,
  useLegalPracticesDocumentsQuery,
  useLazyLegalPracticesDocumentsQuery,
  usePersonalDocumentsQuery,
  useLazyPersonalDocumentsQuery,
  useInvoicesDocumentsQuery,
  useLazyInvoicesDocumentsQuery,
  useMeetingsDocumentsQuery,
  useLazyMeetingsDocumentsQuery,
  useSecurityDocumentsQuery,
  useLazySecurityDocumentsQuery,
  useAsbestosDocumentsQuery,
  useLazyAsbestosDocumentsQuery,
  useSupplierQuotesDocumentsQuery,
  useLazySupplierQuotesDocumentsQuery,
  useBuildingFinancialStatementsDocumentsQuery,
  useLazyBuildingFinancialStatementsDocumentsQuery,
  useBuildingBankStatementsDocumentsQuery,
  useLazyBuildingBankStatementsDocumentsQuery,
  useResidentsBankStatementsDocumentsQuery,
  useLazyResidentsBankStatementsDocumentsQuery,
  useResidentsReceiptsDocumentsQuery,
  useLazyResidentsReceiptsDocumentsQuery,
  useEmployeesDocumentsQuery,
  useLazyEmployeesDocumentsQuery,
  useShipmentSlipsDocumentsQuery,
  useLazyShipmentSlipsDocumentsQuery,
  useResidentsPersonalDocumentsQuery,
  useLazyResidentsPersonalDocumentsQuery,
} = extendedApi

const yearQs = (year) => (year ? `?year=${year}` : '')
