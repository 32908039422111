import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Logo from '@Assets/Logo'
import { Box } from '@Common/Components/Styles'
import { Cursor } from '@Common/Components/Styles'
import { useCurrentUser } from '@Common/Utils/Hooks'
import config, { makePath } from '@Config'
import { history } from '@Core/Redux/Store'

import { Container, Header, Title } from '../Components/ErrorPages'

const PageSuccessPaymentView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()

  const navigateToHome = () => {
    history.push(user.resident ? makePath('myEstia.home') : config.urls.home)
  }

  return (
    <Container>
      <Header>
        <Cursor type="pointer" onClick={navigateToHome}>
          <Logo fill="white" height="40px" />
        </Cursor>
      </Header>
      <Title>Success</Title>
      {/* <Box direction="row" justify="center">
        <Image src={Error404} />
      </Box> */}
      <Box direction="row" justify="center" margin="3rem 0">
        <Button variant="contained" onClick={navigateToHome}>
          {t('common:ui.ReturnHome')}
        </Button>
      </Box>
    </Container>
  )
}

export default PageSuccessPaymentView
