import i18next from 'i18next'
import PropTypes from 'prop-types'

export const BuildingListType = PropTypes.shape({
  id: PropTypes.string,
  denominazione: PropTypes.string,
  codiceFiscale: PropTypes.string,
  indirizoo: PropTypes.shape({
    presso: PropTypes.string,
    via: PropTypes.string,
    civico: PropTypes.string,
    localita: PropTypes.string,
    cap: PropTypes.string,
    comune: PropTypes.string,
    provincia: PropTypes.string,
    nazione: PropTypes.string,
    gruppoEsterno: PropTypes.string,
  })
})

export const BuildingList = {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('common:fields.id'),
    },
    {
      id: 'denominazione',
      label: i18next.t('customerCare:fields.name'),
    },
    {
      id: 'codiceFiscale',
      label: i18next.t('customerCare:fields.fiscalCode'),
    },
    {
      id: 'comune',
      label: i18next.t('customerCare:fields.city'),
      ordering: false,
    },
    {
      id: 'indirizzo',
      label: i18next.t('customerCare:fields.address'),
      ordering: false,
    },
    {
      id: 'extra.branchObj.name',
      label: i18next.t('customerCare:fields.branch'),
      ordering: false,
    },
  ],
}
