import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'startup',
  initialState: { complete: false },
  reducers: {
    setComplete: (state) => {
      state.complete = true
    },
  },
})

export const { setComplete } = slice.actions

export default slice.reducer

export const selectStartupComplete = (state) => state.startup.complete
