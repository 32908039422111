import { CertificationDocumentTypeForm } from '@Supplier/Forms/CertificationDocumentTypeForm'

export const CertificationDocumentType = {
  name: 'CertificationDocumentType',
  url: '/condo/bicert/document-types',
  form: CertificationDocumentTypeForm,
  schema: {
    name: { required: true },
    description: { type: 'longtext' },
    required: { required: true, type: 'boolean', defaultChecked: false, defaultValue: false },
    expirationDays: { type: 'number' },
    expiration: { type: 'boolean' },
    expirationNotification: { type: 'boolean' },
    reminderFrequency: {},
    status: { required: true, type: 'options', choices: ['A', 'S'], defaultValue: 'A' },
  },
}
