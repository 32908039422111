import { Button, FormControl, Grid, Link, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import Alert from '@Common/Components/Alert'
import Loader from '@Common/Components/Loader'
import { Box, Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { responseStringError } from '@Common/Utils/Api'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import config from '@Config'
import { history } from '@Core/Redux/Store'

import PasswordGrid from '../../Components/PasswordGrid'
import PasswordRules from '../../Components/PasswordRules'
import { validate } from '../../Forms/ResetPasswordForm/Validation'
import { useResetPasswordMutation, useValidateResetPasswordTokenMutation } from '../../Services/Api'

const Form = styled.div``

const ResetPasswordForm = () => {
  const { token } = useParams()
  const { t } = useTranslation()
  const { fields, setField, errors, setErrors } = useForm(
    {
      password: null,
      passwordRepeat: null,
      token,
    },
    true,
  )

  // eslint-disable-next-line no-unused-vars
  const [validateToken, { isLoading: isValidating }] = useValidateResetPasswordTokenMutation()
  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  React.useEffect(() => {
    const validateResetToken = async () => {
      try {
        await validateToken(token).unwrap()
      } catch (err) {
        toast.warning(t('auth:errors.ResetPasswordTokenExpired'))
        history.push(config.urls.password.forgot)
      }
    }
    validateResetToken()
  }, []) // component did mount

  const submit = async () => {
    if (validate(fields, setErrors)) {
      try {
        await resetPassword(fields).unwrap()
        logger.debug('Reset password success')
        toast.success(t('auth:success.ResetPasswordCompleted'))
        history.push(config.urls.login)
      } catch (err) {
        logger.debug('Login error', err)
        if (err.status === 400) {
          setErrors(convertServerErrors(err))
        } else {
          setErrors({ ...errors, __form: responseStringError(err) })
        }
      }
    }
  }

  const handleKeyDown = (evt) => {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      submit()
    }
  }

  return (
    <Box>
      {(isValidating || isLoading) && <Loader overlay />}
      <Form>
        {errors.__form && <Alert level="error">{errors.__form}</Alert>}
        <Grid container spacing={5} alignItems="center">
          <PasswordGrid
            columns={{ sm: 12 }}
            fields={[
              <FormControl fullWidth key="password" sx={{ marginBottom: '1.5rem' }}>
                <TextField
                  required
                  fullWidth
                  value={fields.password}
                  onChange={setStateFromEvent(setField('password'))}
                  error={!!errors.password}
                  helperText={errors.password}
                  label={t('auth:fields.password')}
                  type="password"
                />
              </FormControl>,
              <FormControl fullWidth key="passwordRepeat">
                <TextField
                  required
                  fullWidth
                  value={fields.passwordRepeat}
                  onChange={setStateFromEvent(setField('passwordRepeat'))}
                  error={!!errors.passwordRepeat}
                  helperText={errors.passwordRepeat}
                  label={t('auth:fields.repeatPassword')}
                  type="password"
                  onKeyDown={handleKeyDown}
                />
              </FormControl>,
            ]}
            rules={<PasswordRules />}
          />
        </Grid>

        <Margin top="2rem" bottom="2rem">
          <Box direction="row" justify="center">
            {!isLoading && (
              <Button fullWidth variant="contained" onClick={submit}>
                {t('auth:buttons.SaveNewPassword')}
              </Button>
            )}
          </Box>
        </Margin>
        <Typography textAlign="center" color="text.primary">
          <Trans
            i18nKey="auth:ui.LoginSupport"
            values={{ email: config.contacts.supportEmail }}
            components={[<Link key="support-email" href={`mailto:${config.contacts.supportEmail}`} />]}
          />
        </Typography>
      </Form>
    </Box>
  )
}

export default ResetPasswordForm
