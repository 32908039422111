import React from 'react'
import { Controller } from 'react-hook-form'

import DatePicker from '@Common/Components/DatePicker'
import { FieldType } from '@Common/Types/ReactHookFormField'

const DatePickerField = ({ name, control, rules, defaultValue, ...other }) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => <DatePicker onChange={onChange} value={value} {...other} />}
    />
  )
}

DatePickerField.propTypes = FieldType

export default DatePickerField
