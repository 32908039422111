import { createSlice } from '@reduxjs/toolkit'

import storageFactory from '@Common/Services/Storage'

const Storage = storageFactory()

const slice = createSlice({
  name: 'ui',
  initialState: {
    breadcrumbs: [],
    mobileMenuIsOpen: false,
    themeMode: Storage.get('themeMode', 'light'),
    adminSidebar: { expanded: false, expandedWidth: '220px', collapsedWidth: '62px' },
    operationalSidebar: { expanded: false, expandedWidth: '220px', collapsedWidth: '62px'  },
    buildingAside: { expanded: false, expandedWidth: '220px', collapsedWidth: '62px'  },
  },
  reducers: {
    setBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = payload
    },
    toggleMobileMenu: (state) => {
      state.mobileMenuIsOpen = !state.mobileMenuIsOpen
    },
    toggleThemeMode: (state) => {
      const newMode = state.themeMode === 'light' ? 'dark' : 'light'
      Storage.save('themeMode', newMode)
      state.themeMode = newMode
    },
    setThemeMode: (state, { payload }) => {
      state.themeMode = payload
    },
    toggleAdminSidebar: (state) => {
      state.adminSidebar.expanded = !state.adminSidebar.expanded
    },
    toggleOperationalSidebar: (state) => {
      state.operationalSidebar.expanded = !state.operationalSidebar.expanded
    },
    toggleBuildingAside: (state) => {
      state.buildingAside.expanded = !state.buildingAside.expanded
    },
  },
})

export const {
  setBreadcrumbs,
  toggleMobileMenu,
  toggleThemeMode,
  setThemeMode,
  toggleAdminSidebar,
  toggleOperationalSidebar,
  toggleBuildingAside,
} = slice.actions

export default slice.reducer

export const selectBreadcrumbs = (state) => state.ui.breadcrumbs
export const selectMobileMenuIsOpen = (state) => state.ui.mobileMenuIsOpen
export const selectThemeMode = (state) => state.ui.themeMode
export const selectAdminSidebar = (state) => state.ui.adminSidebar
export const selectOperationalSidebar = (state) => state.ui.operationalSidebar
export const selectBuildingAside = (state) => state.ui.buildingAside
