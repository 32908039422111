import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'
import { flattenTasks, TASK_FIELD_TYPE_IDS } from '@Tms/Utils'

const getDateFields = (mainTask, taskId) => {
  const task = flattenTasks(mainTask).find((t) => t.id === taskId)
  return task?.fields.filter((f) => f.type === TASK_FIELD_TYPE_IDS.date).map(({ id, name }) => ({ id, name }))
}

const TaskDateFieldSelect = ({ name, control, taskId, ...other }) => {
  const { getValues } = useFormContext()
  const values = getValues()
  const [fields, setFields] = useState([])

  useEffect(() => {
    setFields(getDateFields(values.mainTask, taskId) ?? [])
  }, [JSON.stringify(values)])

  return (
    <SelectField name={name} control={control} options={fields} valueKey="id" labelKey="name" {...other} />
  )
}

TaskDateFieldSelect.propTypes = FieldType

export default TaskDateFieldSelect
