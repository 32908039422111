import * as R from 'ramda'

const flattenObj = (obj) =>
  R.flatten(
    R.keys(obj).map(
      R.ifElse(
        R.compose(R.is(Array), R.flip(R.prop)(obj)),
        (k) => R.objOf(k, R.head(obj[k])),
        R.compose(flattenObj, R.flip(R.prop)(obj)),
      ),
    ),
  )

export const convertServerErrors = R.pipe(
  R.prop('data'),
  R.ifElse(
    R.both(R.is(Object), R.compose(R.not, Array.isArray)),
    flattenObj,
    R.compose(R.objOf('__form'), R.head),
  ),
  R.mergeAll,
)
