import PropTypes from 'prop-types'
import { ArcadiaEnumType } from '@Common/Types/EnumType'

export const ContactType = PropTypes.shape({
  id: PropTypes.string,
  tipo: ArcadiaEnumType,
  riferimento: PropTypes.string,
  pubblico: PropTypes.bool,
  comunicazioni: PropTypes.bool,
})
