import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Modal, Typography } from '@mui/material'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { makePath } from '@Config'
import Button from '@MyEstia/Components/Button'

const SuccessModal = ({ open, report, themeMode, housingUnitId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(makePath('myEstia.myHome.ticket.list', { housingUnitId }))
  }

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    width: '40%',
    backgroundColor: themeMode === 'dark' ? 'blackWhite.light' : 'blackWhite.dark',
    padding: '20px',
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <CheckCircleOutlineIcon sx={{ fontSize: 60, color: themeMode === 'dark' ? 'success.main' : 'success.dark' }} />
        <Typography
          textAlign="center"
          fontSize={16}
          fontWeight={'500'}
          margin="2rem"
          color={themeMode === 'light' ? 'accentOne.light' : 'accentOne.dark'}
        >
          {t('tickets:successModal.title', { code: report.code })}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ borderRadius: '20px', padding: '3px 12px 3px 12px', textTransform: 'none', width: '30%' }}
          onClick={handleClose}
        >
          {t('tickets:successModal.ok')}
        </Button>
      </Box>
    </Modal>
  )
}

SuccessModal.propTypes = {
  themeMode: PropTypes.string,
  housingUnitId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  report: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }),
}

export default SuccessModal
