import PropTypes from 'prop-types'
import React from 'react'

import FinancialStatement from '@Buildings/Models/FinancialStatement'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'

import { useBuildingOpenFinancialStatementsQuery } from '../Services/Api'

const DISPLAY_COLUMNS = ['descrizione', 'inizio', 'fine', 'stato.displayValue']

const listName = 'building-representatives'

const FinancialStatementList = ({ buildingArcadiaId }) => {
  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage(listName),
      offset: 0,
      ordering: 'tipo',
    },
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isLoading } = useBuildingOpenFinancialStatementsQuery({ buildingArcadiaId, qs })

  return (
    <DataTable
      name={listName}
      qs={qs}
      sortable={false}
      data={data?.results || []}
      dataCount={data?.count || 0}
      refreshData={refreshData}
      model={FinancialStatement}
      listDisplay={DISPLAY_COLUMNS}
      searchFields={[]}
      loading={isLoading}
      noBulkSelection
      noSettings
      noExport
    />
  )
}

FinancialStatementList.propTypes = {
  buildingArcadiaId: PropTypes.string.isRequired,
}

export default FinancialStatementList
