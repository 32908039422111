import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'

import { DeleteButton } from '@Common/Components/Buttons/DeleteButton'
import { DetailButton } from '@Common/Components/Buttons/DetailButton'
import { EditButton } from '@Common/Components/Buttons/EditButton'
import { ItemContainerContext } from '@Common/Components/ItemContainer'
import { isAdminRoute } from '@Common/Utils/Url'
import { CondoSupplierCompany } from '@Supplier/Models/CondoSupplierCompany'

export const CondoSupplierCompanyActions = () => {
  const { item } = useContext(ItemContainerContext)
  const { buildingId } = useParams()
  const { t } = useTranslation()

  const deleteUrl = generatePath(CondoSupplierCompany.url, {
    buildingId,
  })

  const basePath = isAdminRoute() ? '/admin' : ''

  const detailUrl = generatePath('/buildings/:buildingId/supplier/suppliers/:supplierId/:condoSupplierId', {
    buildingId: buildingId,
    supplierId: item.bicertSupplierId,
    condoSupplierId: item.id,
  })

  return (
    <>
      <DeleteButton url={`${deleteUrl}/${item.id}`} />
      <DetailButton url={`${basePath}${detailUrl}`} label={t('suppliercompany:actionDetail')} />
      <EditButton
        dialogTitle={`${t('common:actions.Edit')} ${t(
          `${CondoSupplierCompany.name.toLowerCase()}:model.verbose_name`,
        )}: ${item.name}`}
      />
    </>
  )
}
