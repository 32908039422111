import i18next from 'i18next'
import PropTypes from 'prop-types'
import { ArcadiaEnumType } from '@Common/Types/EnumType'
import { ResidentType } from '@Residents/Models/Resident'

export const BuildingAnagraphicType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  classe: ArcadiaEnumType,
  tipo: ArcadiaEnumType,
  titolo: PropTypes.string,
  nominativo: PropTypes.string,
  cognome: PropTypes.string,
  nome: PropTypes.string,
  ragioneSociale: PropTypes.string,
  partitaIva: PropTypes.string,
  codiceFiscale: PropTypes.string,
  email: PropTypes.arrayOf(PropTypes.string),
  gruppoEsterno: PropTypes.any,
  riferimentoEsterno: PropTypes.any,
  extra: ResidentType,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('auth:fields.anagraphicId'),
    },
    {
      id: 'classe.displayValue',
      label: i18next.t('auth:fields.anagraphic.class'),
    },
    {
      id: 'tipo.displayValue',
      label: i18next.t('auth:fields.anagraphic.type'),
    },
    {
      id: 'titolo',
      label: i18next.t('auth:fields.anagraphic.title'),
    },
    {
      id: 'nominativo',
      label: i18next.t('auth:fields.anagraphic.name'),
    },
    {
      id: 'cognome',
      label: i18next.t('auth:fields.lastName'),
    },
    {
      id: 'nome',
      label: i18next.t('auth:fields.firstName'),
    },
    {
      id: 'ragioneSociale',
      label: i18next.t('auth:fields.anagraphic.companyName'),
    },
    {
      id: 'partitaIva',
      label: i18next.t('auth:fields.anagraphic.vat'),
    },
    {
      id: 'codiceFiscale',
      label: i18next.t('auth:fields.fiscalCode'),
    },
    {
      id: 'email',
      label: i18next.t('auth:fields.anagraphicEmails'),
    },
    {
      id: 'extra.id',
      label: i18next.t('residents:fields.id'),
      numeric: true,
      width: '40px',
      ordering: false,
    },
    {
      id: 'extra.user.username',
      label: i18next.t('residents:fields.email'),
      ordering: false,
    },
    {
      id: 'extra.phone',
      label: i18next.t('residents:fields.phone'),
      ordering: false,
    },
    {
      id: 'extra.user.isActive',
      label: i18next.t('residents:fields.active'),
      boolean: true,
      ordering: false,
    },
    {
      id: 'extra.user.id',
      label: i18next.t('residents:ui.RegisteredOnMyEstia'),
      boolean: true,
      ordering: false,
    },
  ],
}
