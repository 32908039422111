import { CircularProgress, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'

import { useStripeAccountsQuery } from '../Services/Api'

const StripeAccountConnectSelect = ({
  selectedUserId,
  onStripeAccountsFilter,
  value,
  extraOptions,
  onStopLoad,
  ...other
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  const { data } = useStripeAccountsQuery()
  const [stripeAccounts, setStripeAccount] = useState([])

  const selectedUserFilter = (b) => b.id === selectedUserId

  useEffect(() => {
    if (data && selectedUserId) {
      let filteredStripeAccounts = data.filter(selectedUserFilter).map(R.pick(['id', 'name']))

      if (extraOptions) {
        filteredStripeAccounts = extraOptions.concat(filteredStripeAccounts)
      }

      setStripeAccount(filteredStripeAccounts)
      filteredStripeAccounts.length > 0 && onStripeAccountsFilter(filteredStripeAccounts)
      setIsLoading(false)
    } else if (data) {
      setStripeAccount(extraOptions ? extraOptions.concat(data) : data)
      setIsLoading(false)
    } else {
      setStripeAccount(extraOptions ?? [])
    }
  }, [data, selectedUserId])

  useEffect(() => {
    onStopLoad(isLoading)
  }, [isLoading])

  if (!isLoading) {
    return (
      <>
        <Dropdown
          options={stripeAccounts}
          labelKey="name"
          valueKey="id"
          label={t('payments:ui.selectStripeAccount')}
          value={stripeAccounts.length > 0 ? value : null}
          {...other}
        />
      </>
    )
  } else {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  }
}

StripeAccountConnectSelect.propTypes = {
  selectedUserId: PropTypes.string,
  onStripeAccountsFilter: PropTypes.func,
  onFinishLoading: PropTypes.func,
  extraOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
    }),
  ),
  ...FieldType,
}

export default StripeAccountConnectSelect
