import React from 'react'
import { useTranslation } from 'react-i18next'
import Error500 from '@Assets/Images/error500.svg'
import { Box } from '@Common/Components/Styles'
import Logo from '@Assets/Logo'
import { Cursor } from '@Common/Components/Styles'
import config, { makePath } from '@Config'
import { useParams } from 'react-router-dom'
import { history } from '@Core/Redux/Store'
import { Button } from '@mui/material'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { Container, Header, Title, Image, Text } from '../Components/ErrorPages'

const ServerErrorView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { error } = useParams()

  const navigateToHome = () => {
    history.push(user.resident ? makePath('myEstia.home') : config.urls.home)
  }

  let errorDescription
  switch (error) {
    default:
      errorDescription = 'GeneralServerError'
  }

  return (
      <Container>
        <Header>
          <Cursor type='pointer' onClick={navigateToHome}>
            <Logo fill="white" height="40px" />
          </Cursor>
        </Header>
        <Title>
          {t('common:ui.ServerErrorTitle')}
        </Title>
        <Box direction='row' justify='center'>
          <Image src={Error500} />
        </Box>
        <Text>
          {t(`common:errors.${errorDescription}`)}
        </Text>
        <Box direction='row' justify='center' margin='3rem 0'>
          <Button variant='contained' onClick={navigateToHome}>{t('common:ui.ReturnHome')}</Button>
        </Box>
      </Container>
  )
}

export default ServerErrorView
