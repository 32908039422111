import DragIcon from '@mui/icons-material/DragIndicator'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Draggable } from 'react-smooth-dnd'
import styled from 'styled-components'

import { Cursor, Margin } from '@Common/Components/Styles'
import { setStateFromIntEvent } from '@Common/Utils/Events'

import { FormControl } from '../Forms'
import TextField from '../TextField'

const Row = styled.div`
  align-items: center;
  border: 1px solid ${(props) => props.bg};
  border-bottom: 0px solid;
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  padding: 0;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.bg};
  }
`

const SettingsDialog = ({
  open,
  handleClose,
  handleReset,
  handleSaveAndClose,
  columns,
  onSelect,
  onSort,
  listPerPage,
  onListPerPageChange,
  noPagination,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const selected = columns.filter(R.prop('visible')).map(R.prop('id'))

  const handleDrop = ({ removedIndex, addedIndex }) => {
    const copy = [...columns]
    onSort(
      R.ifElse(
        R.isNil,
        R.always(columns),
        R.compose(R.always(copy), R.always(copy.splice(addedIndex, 0, copy.splice(removedIndex, 1)[0]))),
        R.always(columns),
      )(removedIndex),
    )
  }

  const sortedColumns = columns.sort((a, b) => {
    if (a.visible && !b.visible) {
      return -1
    } else if (b.visible && !a.visible) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('common:ui.Settings')}</DialogTitle>
      <DialogContent>
        {!noPagination && (
          <FormControl>
            <TextField
              label={t('common:ui.ItemsPerPage')}
              value={listPerPage}
              onChange={setStateFromIntEvent(onListPerPageChange)}
              type="number"
            />
          </FormControl>
        )}
        <Margin bottom="1.5rem" top="1.5rem">
          <DialogContentText>{t('common:ui:ColumnsSettings')}</DialogContentText>
        </Margin>
        <Container onDrop={handleDrop}>
          {sortedColumns.map((column) => {
            return (
              <Draggable key={`row-${column.id}`}>
                <Row bg={theme.palette.contrastLight.main}>
                  <div>
                    <Checkbox checked={selected.indexOf(column.id) !== -1} onChange={onSelect(column.id)} />
                    <span>{column.label}</span>
                  </div>
                  <Cursor type="move">
                    <DragIcon />
                  </Cursor>
                </Row>
              </Draggable>
            )
          })}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="secondary">
          {t('common:actions.Reset')}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t('common:actions.Close')}
        </Button>
        <Button onClick={handleSaveAndClose} color="primary">
          {t('common:actions.SaveAndClose')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SettingsDialog.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      visible: PropTypes.bool,
    }),
  ),
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSaveAndClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  listPerPage: PropTypes.number.isRequired,
  onListPerPageChange: PropTypes.func.isRequired,
  noPagination: PropTypes.bool,
}

export default SettingsDialog
