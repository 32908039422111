import PropTypes from 'prop-types'

import SelectField from '@Common/Components/Forms/SelectField'
import { BILLING_CYCLES } from '@Tms/Utils'
import { useTranslation } from 'react-i18next'

const BillingCycleField = ({ name, control, ...props }) => {
  const { t }  = useTranslation()

  return (
    <SelectField
      label={t('tms:fields.utilities.billingCycle.__field')}
      name={name}
      control={control}
      options={BILLING_CYCLES}
      {...props}
    />
  )
}

BillingCycleField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
}

export default BillingCycleField
