import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNotificationsQuery } from '@Notifications/Services/Api'
import { logout } from '@Auth/Redux/Login'
import { history } from '@Core/Redux/Store'
import config, { makePath } from '@Config'
import MyEstiaLogo from '../../Assets/MyEstiaLogo'
import Menu from '@Common/Components/Menu'
import Avatar from '@Residents/Components/Avatar'
import { Avatar as MuiAvatar, Badge } from '@mui/material'
import { DarkMode, LightMode, Lock, Logout, Notifications, Person } from '@mui/icons-material'
import { selectThemeMode, toggleThemeMode } from '@Core/Redux/Ui'
import { Cursor, Position } from '@Common/Components/Styles'
import MyEstiaHousingUnitSwitch from '@MyEstia/Components/MyEstiaHousingUnitSwitch'
import { Link } from 'react-router-dom'
import { apiList } from '@Core/Services/Api'
import { isRead } from '@MyEstia/Utils/Notifications'
import * as R from 'ramda'
import { toggleNotificationsPanel } from '@MyEstia/Redux'
import { useChangeUserPasswordMutation } from '@Auth/Services/Api'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { toast } from 'react-toastify'
import { responseStringError } from '@Common/Utils/Api'
import ChangePasswordForm from '@Auth/Forms/ChangePasswordForm'
import styled, { css } from 'styled-components'

const Header = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.white.main};
  background: transparent;
  width: 100%;
  max-width: 100%;
  height: 120px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ theme, ...props }) => css`
      background-color: ${props.themeMode === "light" ? `${theme.palette.primary.main}` : `${theme.palette.primary.dark}`}
    `};
    border-radius: 0 0 130% 130%;
    transform: scaleX(1.1) scaleY(1.5) translateY(-22px);
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 0rem;
`

const NotificationAvatar = styled(MuiAvatar)`
  background: ${({ theme }) => theme.palette.secondary.main};
`

const MyEstiaNavbar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const themeMode = useSelector(selectThemeMode)
  const user = useCurrentUser()
  const { data: notifications } = apiList(useNotificationsQuery())
  const [changeUserPassword] = useChangeUserPasswordMutation()
  const [changePasswordForm, setChangePasswordForm] = useState({
    isOpen: false,
    user: null,
  })

  const toggleTheme = () => dispatch(toggleThemeMode())

  const handleToggleNotificationsPanel = () => dispatch(toggleNotificationsPanel())

  const handleLogout = () => {
    dispatch(logout())
    location.assign(config.urls.login)
  }

  const handleProfile = () => {
    history.push(makePath('myEstia.profile'))
  }

  const handleChangePassword = () => {
    setChangePasswordForm({ isOpen: true, user: user })
  }
  const handleCloseChangePasswordForm = () => setChangePasswordForm({ isOpen: false, user: null })

  const userMenuItems = [
    { label: t('auth:actions.ChangePassword'), onClick: handleChangePassword, icon: <Lock /> },
    { label: t('auth:actions.Profile'), onClick: handleProfile, icon: <Person /> },
    {
      label: t('auth:actions.SwitchMode', {
        mode: themeMode === 'light' ? 'dark' : 'light',
      }),
      onClick: toggleTheme,
      icon: themeMode === 'light' ? <DarkMode /> : <LightMode />,
    },
    { label: t('auth:actions.Logout'), onClick: handleLogout, icon: <Logout /> },
  ]

  const handleSaveChangePassword = async (userId, { password }) => {
    try {
      const response = await changeUserPassword({ userId, password }).unwrap()
      toast.success(t('auth:success.EmployeePasswordChanged'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t('auth:errors.ChangeUserPasswordError', {
          error: responseStringError(error),
        }),
      )
      return { error, isSuccessful: false }
    }
  }

  return (
    <>
      <Header themeMode={themeMode}>
        <Position absolute top="10px" left="10px">
          <Menu
            label={<Avatar color={themeMode === 'light' ? "primaryDark" : "secondary"} />}
            items={userMenuItems}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          />
        </Position>
        <Content>
          <Link to={config.urls.myEstia.home}>
            <MyEstiaLogo style={{ width: '150px' }} />
          </Link>
          <MyEstiaHousingUnitSwitch />
        </Content>
        <Position absolute top="10px" right="20px">
          <Badge
            badgeContent={notifications.filter(R.compose(R.not, isRead)).length}
            color="neutralTwo"
            overlap="circular"
          >
            <Cursor type="pointer">
              <NotificationAvatar onClick={handleToggleNotificationsPanel}>
                <Notifications color="black" />
              </NotificationAvatar>
            </Cursor>
          </Badge>
        </Position>
      </Header>
      {changePasswordForm.isOpen && (
        <ChangePasswordForm
          user={changePasswordForm.user}
          onClose={handleCloseChangePasswordForm}
          onSave={handleSaveChangePassword}
        />
      )}
    </>
  )
}

export default MyEstiaNavbar
