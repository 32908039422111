/* eslint-disable react/prop-types */

/**
 * FooterForm is a component that help us to display
 * generic footer by default with disable and loading state over buttons, etc etc.
 * The cancel button is display only if we find us in a dialog
 */
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Stack } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DialogContext } from '../ButtonDialog'
import { DiscardDialogButton } from '../Buttons/DiscardDialogButton'

export const FormFooterV2 = (props) => {
  const { t } = useTranslation()
  const form = useFormContext()
  const { children } = props

  const isSubmitting = form?.formState?.isSubmitting

  /**
   * Since we often use forms in modals, we check here if we are in the context of a modal.
   * If we are in a modal, we display the cancel button by default.
   */
  const dialogContext = useContext(DialogContext)

  const discardButton = dialogContext && <DiscardDialogButton />

  const submitButton = (
    <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
      {t('common:actions.Save')}
    </LoadingButton>
  )

  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="flex-end">
        <Stack direction="row" spacing={2}>
          {children}
          {discardButton}
          {submitButton}
        </Stack>
      </Box>
    </Grid>
  )
}
