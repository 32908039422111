import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'customercare'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ccSearchAnagraphics: builder.query({
      query: (qs) => `${prefix}/anagraphics?${apiQueryString(qs)}`,
      providesTags: ['CustomerCareAnagraphic'],
    }),
    ccSearchBuildings: builder.query({
      query: (qs) => `${prefix}/buildings?${apiQueryString(qs)}`,
      providesTags: ['CustomerCareBuilding'],
    }),
    ccAnagraphic: builder.query({
      query: (arcadiaId) => `${prefix}/anagraphics/${arcadiaId}`,
      providesTags: (_, __, arcadiaId) => [{type: 'CustomerCareAnagraphic', id: arcadiaId }],
    }),
    ccResidentNotes: builder.query({
      query: (arcadiaId) => `${prefix}/resident/notes?anagraphic_arcadia_id=${arcadiaId}`,
      providesTags: (_, __, arcadiaId) => [{type: 'ResidentNote', id: arcadiaId }],
    }),
    ccCreateResidentNote: builder.mutation({
      query: (body) => ({
        url: `${prefix}/resident/notes`,
        body,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { anagraphicArcadiaId }) => [{ type: 'ResidentNote', id: anagraphicArcadiaId }],
    }),
    ccDeleteResidentNote: builder.mutation({
      query: ({ id }) => ({
        url: `${prefix}/resident/notes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { anagraphicArcadiaId }) => [{ type: 'ResidentNote', id: anagraphicArcadiaId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useLazyCcSearchAnagraphicsQuery,
  useCcSearchAnagraphicsQuery,
  useCcSearchBuildingsQuery,
  useCcAnagraphicQuery,
  useCcResidentNotesQuery,
  useCcCreateResidentNoteMutation,
  useCcDeleteResidentNoteMutation,
} = extendedApi
