import { ArrowDropDown } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import { defaultTo, find, propEq } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Menu from '@Common/Components/Menu'
import { withLoader } from '@Common/Utils/HOC'
import { history } from '@Core/Redux/Store'
import { apiList } from '@Core/Services/Api'

import { selectActiveBranch, setActiveBranch } from '../Redux'
import { useBranchesQuery } from '../Services/Api'

const Badge = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.black.main};
  `}
  border-radius: 20px;
  margin: 0;
  padding: 0.2rem 0.5rem 0.2rem 1rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
`

const OperationalBranchSwitch = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data: branches, isFetching, isLoading } = apiList(useBranchesQuery({ active: true }))
  const branch = useSelector(selectActiveBranch)

  const handleBranchClick = (branch) => () => dispatch(setActiveBranch(branch))

  const menuItems = defaultTo([], branches).map((branch) => ({
    label: branch.name,
    onClick: handleBranchClick(branch),
  }))

  menuItems.unshift({
    label: t('branches:ui.AllBranches'),
    onClick: handleBranchClick(null),
  })

  React.useEffect(() => {
    if (history.location.pathname !== '/customer/care/' && branches.length === 1) {
      dispatch(setActiveBranch(branches[0]))
    }
  }, [isLoading])

  let badgeContent = <CircularProgress size={10} />
  if (branches.length && branch) {
    badgeContent = defaultTo({}, find(propEq('id', branch.id))(branches)).name
  } else if (!branch) {
    badgeContent = t('branches:ui.AllBranches')
  }

  return withLoader(
    <Menu
      label={
        <Badge>
          {badgeContent}
          <ArrowDropDown />
        </Badge>
      }
      items={menuItems}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    />,
    isFetching,
    'inline',
    { minHeight: '0', size: 20, color: 'secondary' },
  )
}

export default OperationalBranchSwitch
