import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import React from 'react'

const StatusPaymentDialog = ({ open, title, text, onCancel, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="xs"
      sx={{
        paddingBottom: 3,
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pl: 8, pb: 4, pr: 8 }}>
        {text}
        {children}
      </DialogContent>
      {/* <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={onCancel} variant={'contained'} disableElevation>
          {t('common:actions.Close')}
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

StatusPaymentDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
}

export default StatusPaymentDialog
