import React from 'react'
import { Controller } from 'react-hook-form'

import FileField from '@Common/Components/FileField'
import { FieldType } from '@Common/Types/ReactHookFormField'
import { Cursor } from '@Common/Components/Styles'

const FileDropzoneField = ({ name, control, rules, defaultValue, ...other }) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Cursor type="pointer">
          <FileField onDrop={onChange} files={value} {...other} />
        </Cursor>
      )}
    />
  )
}

FileDropzoneField.propTypes = FieldType

export default FileDropzoneField
