import { Navigate, Route, Routes } from 'react-router-dom'

import BuildingInsurancePolicyMainView from '@Condo/Views/BuildingInsurancePolicyMainView'
import BuildingInsurancePolicyView from '@Condo/Views/BuildingInsurancePolicyView'
import config from '@Config'

export const InsurancePolicyRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<BuildingInsurancePolicyView />} />
        <Route path=":id/main" element={<BuildingInsurancePolicyMainView />} />
        <Route path="*" element={<Navigate to={config.urls.pageNotFound} replace />} />
      </Route>
    </Routes>
  )
}
