import { Button, Menu as MuiMenu, MenuItem } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import React from 'react'
import PropTypes from 'prop-types'

const Menu = ({ label, items, ...other }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleItemClick = item => () => {
    item.onClick()
    handleClose()
  }

  return (
    <div>
      <Button sx={{ minWidth: 'initial' }} onClick={handleClick}>
        {label}
      </Button>
      <MuiMenu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        {...other}
      >
        {items.map((item, i) => (
          <MenuItem onClick={handleItemClick(item)} key={i}>
            {item.icon && (
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
            )}
            {item.label}
          </MenuItem>
        ))}
      </MuiMenu>
    </div>
  )
}

Menu.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      onClick: PropTypes.func.isRequired,
    }),
  ),
}

export default Menu
