/* eslint-disable react/prop-types */

/**
 * Close dialog button
 */
import { Button } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { DialogContext } from '../ButtonDialog'

export const DiscardDialogButton = () => {
  const { t } = useTranslation()
  const { handleClose } = useContext(DialogContext)

  return (
    <Button autoFocus onClick={handleClose} color="primary">
      {t('common:actions.Cancel')}
    </Button>
  )
}
