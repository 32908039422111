import { Add, ChevronRight } from '@mui/icons-material'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Modal from '@Common/Components/Modal'
import { isAdminRoute } from '@Common/Utils/Url'
import { makePath } from '@Config'

import { CondoSearchableList } from './CondosSearchableList'

const ButtonDialogWithOutCondoReference = ({ onCreate, referenceType, title, titleBtnCreate }) => {
  const navigate = useNavigate()

  const isAdmin = isAdminRoute()
  const basePath = isAdmin ? 'admin.' : ''

  const [showBiuildingWithoutReferenceModal, setShowBiuildingWithoutReferenceModal] = useState(false)
  const handleOpenBiuildingWithoutReferenceModal = () => setShowBiuildingWithoutReferenceModal(true)
  const handleCloseBiuildingWithoutReferenceModal = () => setShowBiuildingWithoutReferenceModal(false)
  const { t } = useTranslation()

  return (
    <>
      <Button size="small" disableElevation onClick={() => setShowBiuildingWithoutReferenceModal(true)} sx={{ px: 2 }}>
        {t(title)}
      </Button>
      {showBiuildingWithoutReferenceModal && (
        <Modal
          title={t(title)}
          onClose={handleOpenBiuildingWithoutReferenceModal}
          footer={<Button onClick={handleCloseBiuildingWithoutReferenceModal}>{t('common:actions.Close')}</Button>}
        >
          <CondoSearchableList
            query={{ qsAdditions: { no_info_on: referenceType } }}
            actions={[
              {
                icon: <Add />,
                tooltip: t(titleBtnCreate),
                onClick: (item) => {
                  handleCloseBiuildingWithoutReferenceModal()
                  onCreate(item.value)
                },
              },
              {
                icon: <ChevronRight />,
                tooltip: t('condo:ui.BuildingData'),
                onClick: (item) => navigate(makePath(`${basePath}buildings.detail.main`, { buildingId: item.value })),
              },
            ]}
          />
        </Modal>
      )}
    </>
  )
}

ButtonDialogWithOutCondoReference.propTypes = {
  onCreate: PropTypes.func.isRequired,
  referenceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleBtnCreate: PropTypes.string.isRequired,
}

export default ButtonDialogWithOutCondoReference
