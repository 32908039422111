import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("/fonts/Avenir-Regular.ttf") format("truetype");
    font-weight: normal;
  }

  @font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("/fonts/Avenir-Italic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("/fonts/Avenir-Bold.otf") format("opentype");
    font-weight: 500;
  }

  @font-face {
    font-family: "Avenir";
    src: local("Avenir"),
    url("/fonts/Avenir-BoldItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }

  body {
    background-color: ${({ theme }) => theme.palette.background.main};
    margin: 0;
    padding: 0;
    font-family: "Avenir", "Roboto", "Helvetica", "Arial", sans-serif;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .wysiwyg-wrapper {
    height: 220px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .wysiwyg-editor {
    height: 200px;
    padding: 5px 5px 20px;
    background: ${({ theme }) => theme.palette.lightOne.main};
    border-radius: 4px;
  }

  .DraftEditor-root {
    height: auto !important;
  }
  
  a[href] {
    color: #3E6EB4
  }

  /**
   * Scrollbar style
   */

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.2;
  }


`

export default GlobalStyle
