import { Button, InputAdornment } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Loader from '@Common/Components/Loader'
import { downloadFile } from '@Common/Services/Api/Api'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useAccessToken } from '@Common/Utils/Hooks'

import Modal from './Modal'
import TextField from './TextField'

export const ExportExcelButton = ({ defaultFilename, exportApi, qs }) => {
  const { t } = useTranslation()
  const accessToken = useAccessToken()

  const [dialog, setDialog] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const [filename, setFilename] = useState(`${dayjs().format('DD_MM_YYYY-HH_mm')}-${defaultFilename}`)

  const handleClose = () => setDialog(false)
  const handleOpen = () => setDialog(true)

  const handleExport = () => {
    setIsExporting(true)
    const params = new URLSearchParams(qs?.qsAdditions).toString()
    const url = params ? `${exportApi}?${params}` : exportApi
    return downloadFile(accessToken, url, `${filename}.xlsx`)
      .then(handleClose)
      .catch((response) => {
        toast.error(t('common:errors.MessageGenericError', { error: response?.data?.detail || '' }))
      })
      .finally(() => setIsExporting(false))
  }

  const renderDialog = (
    <Modal
      title={t('common:actions.Export')}
      size="sm"
      onClose={handleClose}
      onSubmit={handleExport}
      submitLabel={t('common:actions.Export')}
      sx={{ paddingTop: 0 }}
    >
      <TextField
        fullWidth
        InputProps={{ endAdornment: <InputAdornment position="end">.xlsx</InputAdornment> }}
        label={t('common:ui.FileName')}
        value={filename}
        onChange={setStateFromEvent(setFilename)}
      />
    </Modal>
  )

  return (
    <>
      {isExporting && <Loader overlay />}
      <Button onClick={handleOpen}>{t('common:actions.Export')}</Button>
      {dialog && renderDialog}
    </>
  )
}

ExportExcelButton.propTypes = {
  defaultFilename: PropTypes.string.isRequired,
  exportApi: PropTypes.string.isRequired,
  qs: PropTypes.any,
}
