import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import DataTable from '@Common/Components/DataTable'
import { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { Margin } from '@Common/Components/Styles'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { selectThemeMode } from '@Core/Redux/Ui'
import { selectActiveHousingUnitId } from '@MyEstia/Redux'
import Ticket from '@MyHome/Models/Ticket'
import { useReportsQuery } from '@Tickets/Services/Api'

const Main = styled.div`
  padding: 2rem 10%;
`
const DISPLAY_COLUMNS = ['created', 'title', 'reportTypeObj.name', 'stato', 'dettaglio']

const MyHomeTicketsView = () => {
  const themeMode = useSelector(selectThemeMode)
  const housingUnitId = useSelector(selectActiveHousingUnitId)
  const { t } = useTranslation()

  const FIELDS_MAPPING = {
    // eslint-disable-next-line react/display-name
    stato: (record) => (
      <Typography
        fontSize={16}
        fontWeight={'500'}
        color={
          record.statusLabel === 'inserita'
            ? 'primary.main'
            : record.statusLabel === 'in carico'
            ? 'warning.main'
            : 'success.dark'
        }
      >
        {t(`tickets:fields.status.${record.statusLabel}`)}
      </Typography>
    ),
    // eslint-disable-next-line react/display-name
    dettaglio: (report) => (
      <Button
        variant="contained"
        color="secondary"
        sx={{ borderRadius: '20px', padding: '3px 12px 3px 12px', textTransform: 'none', float: 'right' }}
        onClick={() => {
          history.push(makePath('myEstia.myHome.ticket.detail', { housingUnitId, reportId: report.id }))
        }}
      >
        {t('tickets:ticketList.Detail')}
      </Button>
    ),
  }

  const [qs, setQs] = useState({
    base: {
      limit: getDefaultListPerPage('reports'),
      offset: 0,
      ordering: '-created',
    },
    qsAdditions: {},
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  const convertSearchQuery = (qs) => {
    if (qs.qsAdditions?.search) {
      delete qs.qsAdditions.search
    }

    return qs
  }

  const { data, isFetching } = useReportsQuery(convertSearchQuery(qs))

  return (
    <>
      <Main>
        <Margin bottom="2rem">
          <Typography
            textAlign="center"
            fontSize={16}
            fontWeight={'500'}
            margin="2rem"
            color={themeMode === 'light' ? 'accentOne.light' : 'accentOne.dark'}
          >
            {t('tickets:ticketList.title')}
          </Typography>

          <DataTable
            name="tickets"
            qs={qs}
            data={data?.results || []}
            refreshData={refreshData}
            model={Ticket}
            listDisplay={DISPLAY_COLUMNS}
            fieldsMapping={FIELDS_MAPPING}
            searchFields={[]}
            disableSorting={[]}
            loading={isFetching}
            useDjangoOrdering
            noBulkSelection
            noExport
            noToolbar
          />
        </Margin>
      </Main>
    </>
  )
}

export default MyHomeTicketsView
