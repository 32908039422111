import DeleteIcon from '@mui/icons-material/Delete'
import { Button } from '@mui/material'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { isNil } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import TmsMyTasksIcon from '@Assets/Icons/TmsMyTasksIcon'
import { getEmployeeInfo } from '@Auth/Utils'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import ExportModal from '@Common/Components/ExportModal'
import Loader from '@Common/Components/Loader'
import { useConfirm, useCurrentUser } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { makePath } from '@Config'
import { apiList, downloadFile } from '@Core/Services/Api'
import { PriorityBadge, StatusBadge } from '@Tms/Components/Styled'
import TasksFilterForm from '@Tms/Forms/TasksFilterForm'
import RunningTask from '@Tms/Models/RunningTask'
import { TaskPermissions } from '@Tms/Permissions'
import { selectAllFiltersFlattened, selectSearch, selectSorting, setAllFilters, setSorting } from '@Tms/Redux'
import { useTasksQuery } from '@Tms/Services/Api'
import { getTaskStatus, PRIORITY_ENUM, STATUS_ENUM } from '@Tms/Utils'

const FIELDS_MAPPING = {
  status: (task) => {
    const status = getTaskStatus(task)
    return (
      <StatusBadge
        status={status}
        size="small"
        label={i18next.t(
          `tms:ui.Status${
            status === STATUS_ENUM.COMPLETED
              ? 'Completed'
              : status === STATUS_ENUM.EXPIRED
              ? 'Expired'
              : status === STATUS_ENUM.PROCESSING
              ? 'Processing'
              : 'Open'
          }`,
        )}
      />
    )
  },
  priority: (task) => {
    return (
      <PriorityBadge
        priority={task.priority}
        size="small"
        label={i18next.t(
          `tms:fields.priority.${
            task.priority === PRIORITY_ENUM.LOW ? 'low' : task.priority === PRIORITY_ENUM.MEDIUM ? 'medium' : 'high'
          }`,
        )}
      />
    )
  },
  // eslint-disable-next-line
  title: ({ id: taskId, title }) => <Link to={makePath('tms.taskDetail', { taskId })}>{title}</Link>,
  assignedTo: R.pipe(R.prop('assignedTo'), getEmployeeInfo),
}

const DISPLAY_COLUMNS = [
  'category',
  'title',
  'assignedTo',
  'building',
  'created',
  'deadline',
  'status',
  'priority',
  'parent',
]

const TaskList = ({ onShow, onDelete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useCurrentUser()
  const filters = useSelector(selectAllFiltersFlattened)
  const search = useSelector(selectSearch)
  const sorting = useSelector(selectSorting)
  const [selected, setSelected] = React.useState()
  const [downloadModal, setDownloadModal] = React.useState(false)
  const [showLoader, setShowLoader] = React.useState(false)

  const buildFilterPayload = React.useCallback((fields) => {
    const params = {}

    // search
    if (fields.search) {
      params.title__icontains__or = fields.search
      params.description__icontains__or = fields.search
      params.notes__icontains__or = fields.search
    }

    // modal filters
    if (fields.deadlineGte) params.deadline__date__gte = fields.deadlineGte.format('YYYY-MM-DD')
    if (fields.deadlineLte) params.deadline__date__lte = fields.deadlineLte.format('YYYY-MM-DD')
    if (fields.createdGte) params.created__date__gte = fields.createdGte.format('YYYY-MM-DD')
    if (fields.createdLte) params.created__date__lte = fields.createdLte.format('YYYY-MM-DD')
    if (fields.category) params.task_flow__task_category__id = fields.category
    if (!isNil(fields.priority)) params.priority = fields.priority
    if (fields.branch) params.task_flow__branch__id = fields.branch
    if (fields.building) {
      const buildingInfos = fields.building.split('_')
      params.task_flow__building_id = buildingInfos[0]
      params.task_flow__building_type__model = buildingInfos[1]
    }
    if (fields.assignedTo) params.assigned_to__id = fields.assignedTo
    if (!isNil(fields.status)) {
      if (fields.status === STATUS_ENUM.OPEN) {
        params.status__in = `${STATUS_ENUM.OPEN},${STATUS_ENUM.EXPIRED},${STATUS_ENUM.PROCESSING}`
        delete params.status
      } else {
        params.status = fields.status
      }
    }

    // quickFilters
    if (!fields.showCompleted) {
      params.is_completed = false
    } else {
      // We keep query param sorted to avoid cache issues
      if (params.status__in) {
        const statuses = params.status__in.split(',').concat(STATUS_ENUM.COMPLETED).sort()
        params.status__in = statuses.join(',')
      } else if (params.status && params.status !== STATUS_ENUM.COMPLETED) {
        const statuses = [params.status, STATUS_ENUM.COMPLETED].sort()
        params.status__in = statuses.join(',')
        delete params.status
      }
    }

    if (fields.onlyMainTasks) params.parent__empty = true
    if (fields.onlyMyTasks) params.assigned_to__id = user.employeeId ?? 0

    return params
  }, [])

  React.useEffect(() => {
    setQsAdditions(buildFilterPayload({ ...filters, search }))
  }, [filters, search])

  // filters query string state
  const [qsAdditions, setQsAdditions] = React.useState(buildFilterPayload(filters))

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('tms-running-tasks'),
      offset: 0,
      ordering: sorting,
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      data.base.ordering = data.base.ordering.replace('code', 'id')
      setQs(() => {
        dispatch(setSorting(data.base.ordering))
        return data
      })
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data: tasks, count, isFetching } = apiList(useTasksQuery(qs))

  const handleFilters = ({ showCompleted, onlyMainTasks, onlyMyTasks, search, ...filters }) => {
    dispatch(
      setAllFilters({
        quickFilters: { showCompleted, onlyMainTasks, onlyMyTasks },
        filters,
        search,
      }),
    )
  }

  const handleDeleteTask = async (task) => {
    setShowLoader(true)
    await onDelete(task)
    setShowLoader(false)
  }
  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteTask)

  const handleOpenExportModal = () => setDownloadModal(true)
  const handleCloseExportModal = () => setDownloadModal(false)
  const handleCvsExport = async (fileName) => {
    setShowLoader(true)
    const params = new URLSearchParams(qs?.qsAdditions).toString()
    const url = params ? `/tms/tasks/export-csv?${params}` : '/tms/tasks/export-csv'
    logger.debug('handleCvsExport', url, fileName)
    await downloadFile(url, fileName)
    handleCloseExportModal()
    setShowLoader(false)
  }

  const actions = React.useMemo(
    () => [
      {
        id: 'SHOW',
        label: t('common:actions.Detail'),
        icon: <TmsMyTasksIcon />,
        perm: () => true, //TaskCategoryPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: () => true, // TaskCategoryPermissions.delete,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, task) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          task, // payload
          t('tms:ui.DeleteRunningTaskConfirmationTitle'),
          t('tms:ui.DeleteRunningTaskConfirmationText'),
          { selected: selected, field: 'title' },
        )
      } else if (actionId === 'SHOW') {
        onShow(task)
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      {showLoader && <Loader overlay />}
      <DataTable
        name="tms-running-tasks"
        qs={qs}
        qsAdditions={qsAdditions}
        data={tasks}
        model={RunningTask}
        dataCount={count}
        refreshData={refreshData}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        {...(TaskPermissions.export(user) && {
          toolbarContent: <Button onClick={handleOpenExportModal}>{t('common:actions.Export')}</Button>,
        })}
        useDjangoOrdering
        noBulkSelection
        noExport
      >
        <TasksFilterForm filters={filters} onFilter={handleFilters} />
      </DataTable>
      {DeleteConfirm}
      {downloadModal && (
        <ExportModal defaultFilename="tms-task" onExport={handleCvsExport} onClose={handleCloseExportModal} />
      )}
    </div>
  )
}

TaskList.propTypes = {
  onShow: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default TaskList
