import * as R from 'ramda'
import { hasGroup, hasPermission } from '@Common/Utils/Permissions'
import { ROLES } from '@Auth/Costants'

export const canAccessBuildingsView = R.anyPass([
  R.prop('isSuperUser'),
  hasGroup(ROLES.HQ_IT),
  hasGroup(ROLES.HQ_MAIN_OFFICE),
  hasGroup(ROLES.HQ_ADMINISTRATIVE),
  hasGroup(ROLES.HQ_MARKETING),
])

export const BuildingPermissions = {
  read: hasPermission('arcadia.view_building'),
  create: hasPermission('arcadia.add_building'),
  update: hasPermission('arcadia.change_building'),
}

export const BranchBuildingPermissions = {
  read: hasPermission('company.view_branchbuilding'),
  create: hasPermission('company.add_branchbuilding'),
  update: hasPermission('company.change_branchbuilding'),
  updateNotes: R.compose(
    R.not,
    R.isEmpty,
    R.intersection(['company.change_branchbuilding', 'company.change_branchbuilding_notes']),
    R.prop('permissions'),
  ),
  delete: hasPermission('company.delete_branchbuilding'),
}
