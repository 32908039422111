import { FormControl } from '@mui/material'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FileField from '@Common/Components/FileField'
import Modal from '@Common/Components/Modal'
import { Box, Label, Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { CategoryType } from '@Services/Models/Category'

import CategorySelect from '../Components/admin/CategorySelect'
import { categoryValidate } from './Validation'
import Loader from '@Common/Components/Loader'

const childrenOf = (category) => {
  let array = []
  if (category?.id) {
    array.push(category?.id)
    for (let child of category?.children) {
      array = array.concat(childrenOf(child, array))
    }
  }
  return array
}

const CategoryForm = ({ category, parent, onClose, onSave }) => {
  const { t } = useTranslation()
  const children = childrenOf(category)
  const title = t(`services:ui.${category?.id ? 'Modify' : 'Add'}Category`)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const changeParent = (parent) => {
    setField('parentId')(parent.target.value)
  }
  const { fields, setField, errors, setErrors } = useForm(
    {
      name: R.defaultTo('', category?.name),
      parentId: R.defaultTo('', parent?.id),
      logo: [],
      orderingNumber: R.defaultTo('', category?.orderingNumber),
      slug: R.defaultTo('', category?.slug),
    },
    true,
  )
  const onDrop = (acceptedFiles) => {
    setField('logo', 'array')(acceptedFiles)
  }

  const submit = async () => {
    logger.debug('Employee form submission, fields:', fields)
    if (categoryValidate(fields, setErrors, category)) {
      setIsSubmitting(true)
      logger.debug('Employee form submission, validation passed, saving')
      const { error, isSuccessful } = await onSave(fields)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('Employee form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('Employee form submission, validation failed')
    }
  }

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              required
              label={t('services:fields.category.Name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <CategorySelect
              value={fields.parentId}
              category={category}
              label={t('services:fields.category.ParentName')}
              onChange={changeParent}
              error={!!errors.parentId}
              helperText={errors.parentId}
              exclude={children}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              required
              type="number"
              label={t('services:fields.category.OrderingNumber')}
              value={fields.orderingNumber}
              onChange={setStateFromEvent(setField('orderingNumber'))}
              error={!!errors.orderingNumber}
              helperText={errors.orderingNumber ? errors.orderingNumber : t('services:ui.OrderCategoryHelper')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Margin bottom="1rem" top="1rem">
              <Label>{t('services:fields.category.Logo')} *</Label>
            </Margin>
            {category?.logo && (
              <Box margin="0 0 1rem">
                {t('common:ui.CurrentImage')}
                {': '}
                <Margin top="1rem">
                  <img src={category.logo} style={{ maxWidth: '100%' }} />
                </Margin>
              </Box>
            )}
            <FileField
              onDrop={onDrop}
              files={fields.logo}
              accept={'image/jpeg,image/png'}
              warningText={t('news:info.ImageLimit')}
              error={!!errors.logo}
              helperText={errors.logo}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  )
}

CategoryForm.propTypes = {
  category: CategoryType,
  parent: CategoryType,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default CategoryForm
