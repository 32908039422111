import MuiAutocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'

import { useDebounce } from '@Common/Utils/Hooks'

import { FieldType } from '../Types/Field'
import TextField from './TextField'

const ServerAutoCompleteField = ({
  label,
  value,
  onChange,
  required,
  error,
  helperText,
  multiple,
  disabled,
  queryFn,
  queryOrdering,
  querySearchObjCb,
  queryOptionsObjCb,
  width,
  ...other
}) => {
  const [qs, setQs] = React.useState({
    base: {
      limit: 10,
      offset: 0,
      ordering: queryOrdering,
    },
    qsAdditions: querySearchObjCb(''),
  })
  const data = queryFn(qs)

  const [inputValue, setInputValue] = React.useState('')
  const debouncedInputValue = useDebounce(inputValue, 300)
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    setQs({
      ...qs,
      qsAdditions: querySearchObjCb(debouncedInputValue),
    })
  }, [debouncedInputValue])

  React.useEffect(() => {
    setOptions(R.defaultTo([], data.data?.results?.map(queryOptionsObjCb)))
  }, [data?.data?.results])

  return (
    <FormControl style={{ width: width || '100%' }}>
      <MuiAutocomplete
        multiple={multiple}
        value={value}
        inputValue={inputValue}
        disabled={disabled}
        onChange={(_, option) =>
          onChange(option ? (multiple ? option.map((o) => o.value) : option.value) : multiple ? [] : null)
        }
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        getOptionLabel={(option) => option.label || ''}
        options={data.isFetching ? [] : options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={required}
            error={error}
            helperText={helperText}
            disabled={disabled}
          />
        )}
        loading={data.isFetching}
        {...other}
      />
    </FormControl>
  )
}

ServerAutoCompleteField.propTypes = {
  ...FieldType,
  requestAction: PropTypes.func,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  overrideOnchange: PropTypes.bool,
  loading: PropTypes.bool,
  queryFn: PropTypes.func,
  queryOrdering: PropTypes.string,
  querySearchObjCb: PropTypes.func,
  queryOptionsObjCb: PropTypes.func,
}

export default ServerAutoCompleteField
