import { api, apiQueryString } from '@Core/Services/Api'

const acPrefix = 'accounting/installments'
const accountPrefix = 'accounts'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    residents: builder.query({
      query: (qs) => `${accountPrefix}/residents?${apiQueryString(qs)}`,
      providesTags: ['Resident'],
    }),
    updateResident: builder.mutation({
      query: ({ id, ...resident }) => ({
        url: `${accountPrefix}/residents/${id}`,
        body: resident,
        method: 'PATCH',
      }),
      invalidatesTags: ['Resident', 'Anagraphic'],
    }),
    deleteResident: builder.mutation({
      query: (residentId) => ({
        url: `${accountPrefix}/residents/${residentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Resident', 'Anagraphic'],
    }),
    residentInstallments: builder.query({
      query: ({ anagraphicArcadiaId, qs }) => {
        return `${acPrefix}?${apiQueryString(qs)}&anagrafica__id=${anagraphicArcadiaId}`
      },
      providesTags: (_, __, { anagraphicArcadiaId }) => [{ type: 'ResidentInstallment', id: anagraphicArcadiaId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useResidentsQuery,
  useUpdateResidentMutation,
  useDeleteResidentMutation,
  useResidentInstallmentsQuery,
} = extendedApi
