import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

import config from '@Config'
import Bg from '@Assets/Images/bg.png'
import { setComplete } from '../Redux/Startup'
import { history } from '../Redux/Store'
import { useWhoamiMutation } from '@Auth/Services/Api'
import { setCurrentUser } from '@Auth/Redux/Login'
import { Margin } from '@Common/Components/Styles'
import Logo from '@Assets/Logo'

const Wrapper = styled.div`
  align-items: center;
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`

const Container = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.lightOne.main};
    box-shadow: 0 0 10px ${theme.palette.darkOne.main};
    color: ${theme.palette.lightOne.contrastText};
  `}
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
  padding: 2rem;
`

const isGuestRoute = (route) => {
  return !/^\/(register|confirm-registration|forgot-password|reset-password)(\/.+)?/.test(route.pathname)
}

const isUnderMaintenance = (err) => {
  return err.originalStatus === 503
}

const StartupView = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [whoami] = useWhoamiMutation()

  React.useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await whoami().unwrap()
        dispatch(setCurrentUser(user))
      } catch (err) {
        // prevent redirect in guest routes
        if (isGuestRoute(window.location) && !isUnderMaintenance(err)) {
          history.push(config.urls.login)
        }
      }
      dispatch(setComplete())
    }
    fetchCurrentUser()
  }, []) // component did mount

  return (
    <Wrapper>
      <Container>
        <Margin bottom="3rem">
          <Logo width="250px" />
        </Margin>
        <CircularProgress color="primary" />
        <Typography color="text.primary" mt="1rem" paragraph>
          {t('common:ui.Loading')}
        </Typography>
      </Container>
    </Wrapper>
  )
}

export default StartupView
