import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'
import { flattenTasks } from '@Tms/Utils'

const getSelectFields = (mainTask, taskId, fieldId) => {
  const field = flattenTasks(mainTask).find((t) => t.id === taskId).fields.find(f => f.id === fieldId)
  return field?.choices.map(({ id, value }) => ({ id, value }))
}

const TaskCustomFieldChoiceSelect = ({ name, control, taskId, fieldId, ...other }) => {
  const { getValues } = useFormContext()
  const values = getValues()
  const [choices, setChoices] = useState([])

  useEffect(() => {
    setChoices(getSelectFields(values.mainTask, taskId, fieldId) ?? [])
  }, [JSON.stringify(values)])

  return <SelectField name={name} control={control} options={choices} labelKey="value" valueKey="id" {...other} />
}

TaskCustomFieldChoiceSelect.propTypes = FieldType

export default TaskCustomFieldChoiceSelect
