import { Navigate, Route, Routes } from 'react-router-dom'

import { BuildingLayout } from '@Buildings/Layouts/BuildingLayout'
import BuildingDocumentsView from '@Buildings/Views/BuildingDocumentsView'
import BuildingFacilitiesView from '@Buildings/Views/BuildingFacilitiesView'
import BuildingInstallmentsView from '@Buildings/Views/BuildingInstallmentsView'
import BuildingMainView from '@Buildings/Views/BuildingMainView'
import BuildingResidentsView from '@Buildings/Views/BuildingResidentsView'
import BuildingStructureView from '@Buildings/Views/BuildingStructureView'
import { BuildingsListView } from '@Buildings/Views/BuildingsListView'
import { InsurancePolicyRouter } from '@Condo/Router/InsurancePolicyRouter'
import { PodPdrRouter } from '@Condo/Router/PodPdrRouter'
import { ReconciliationReminderRouter } from '@Condo/Router/ReconciliationReminderRouter'
import config from '@Config'
import { SupplierRouter } from '@Supplier/Router/SupplierRouter'

export const BuildingsRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<BuildingsListView />} />
        <Route path=":buildingId" element={<BuildingLayout />}>
          {/** building detail */}
          <Route index element={<BuildingMainView />} />
          <Route path="main" element={<BuildingMainView />} />

          {/** subsection of building */}
          <Route path="structure" element={<BuildingStructureView />} />
          <Route path="facilities" element={<BuildingFacilitiesView />} />
          <Route path="installments" element={<BuildingInstallmentsView />} />
          <Route path="residents" element={<BuildingResidentsView />} />
          <Route path="documents" element={<BuildingDocumentsView />} />

          <Route path="podpdrs/*" element={<PodPdrRouter />} />
          <Route path="insurance-policy/*" element={<InsurancePolicyRouter />} />
          <Route path="reconciliation-reminders/*" element={<ReconciliationReminderRouter />} />
          <Route path="supplier/*" element={<SupplierRouter />} />
        </Route>

        <Route path="*" element={<Navigate to={config.urls.pageNotFound} replace />} />
      </Route>
    </Routes>
  )
}
