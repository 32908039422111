import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Typography } from '@mui/material'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StatusText = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ status, theme: { palette } }) =>
    status === 0 ? palette.primary.main : status === 1 ? palette.warning.main : palette.success.main};
`

const ReportTimeline = ({ logs }) => {
  const { t } = useTranslation()

  return (
    <Timeline>
      {logs.map((log, idx) => (
        <TimelineItem key={idx}>
          <TimelineOppositeContent sx={{ display: 'flex', justifyContent: 'space-between', py: '12px', px: 2 }}>
            <StatusText status={log.newStatus}>{t(`tickets:fields.status.${log.newStatus}`)}</StatusText>
            <Typography textAlign="right" fontSize={12}>
              {log.newStatus !== 0 ? log.user.username : ''}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={log.newStatus === 0 ? 'primary' : log.newStatus === 1 ? 'warning' : 'success'} />
            {idx < logs.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{py: '12px', px: 2}}>
            <Typography fontSize={12} textAlign="left" >
              {moment(log.created).format('DD/MM/YYYY')}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

ReportTimeline.propTypes = {
  logs: PropTypes.array,
}

export default ReportTimeline
