import i18next from 'i18next'
import PropTypes from 'prop-types'

export const ReconciliationReminderType = PropTypes.shape({
  building: PropTypes.number.isRequired,
  bank: PropTypes.string.isRequired,
  legal_practices_presence: PropTypes.string.isRequired,
  last_reconciliation_date: PropTypes.string.isRequired,
  last_reminder_date: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
})

export const LegalPracticesPresenceTypes = [
  { id: 'N', label: i18next.t('condo:reconciliationReminder.legalPracticesPresence.N') },
  { id: 'Y', label: i18next.t('condo:reconciliationReminder.legalPracticesPresence.Y') },
]

export const ReconciliationReminder = {
  primaryKey: 'id',
  columns: [
    {
      id: 'bank',
      label: i18next.t('condo:reconciliationReminder.fields.bank'),
    },
    {
      id: 'legalPracticesPresence',
      label: i18next.t('condo:reconciliationReminder.fields.legalPracticesPresence'),
    },
    {
      id: 'lastReconciliationDate',
      label: i18next.t('condo:reconciliationReminder.fields.lastReconciliationDate'),
      date: true,
    },
    {
      id: 'lastReminderDate',
      label: i18next.t('condo:reconciliationReminder.fields.lastReminderDate'),
      date: true,
    },
    {
      id: 'notes',
      label: i18next.t('condo:reconciliationReminder.fields.notes'),
      ordering: false,
      longtext: true,
    },
    {
      id: 'status',
      label: i18next.t('common:ui.State'),
      ordering: false,
      width: 'auto',
    },
    {
      id: 'buildingDescription',
      label: i18next.t('condo:reconciliationReminder.fields.buildingDescription'),
      ordering: 'building__description',
    },
    {
      id: 'buildingFiscalCode',
      label: i18next.t('condo:reconciliationReminder.fields.buildingFiscalCode'),
      ordering: 'building__fiscal_code',
    },
    {
      id: 'buildingAcquisitionMode',
      label: i18next.t('condo:reconciliationReminder.fields.buildingAcquisitionMode.__field'),
      ordering: false,
    },
  ],
}
