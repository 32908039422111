import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ItemContainer } from '@Common/Components/ItemContainer'
import { ItemField } from '@Common/Components/ItemField'
import { ItemCardTable } from '@Common/Components/SectionCard'
import { SupplierCompany } from '@Supplier/Models/SupplierCompany'

import { ChipStatusSupplierCompany } from './ChipStatusSupplierCompany'
import { SupplierServiceItemField } from './SupplierServiceItemField'

export const SupplierCompanyDetailAnagrafic = (props) => {
  const { buildingId } = useParams()

  // eslint-disable-next-line react/prop-types
  const item = props?.item

  const [isBuildingDetail, setIsBuildingDetail] = useState(false)

  useEffect(() => {
    if (buildingId) {
      setIsBuildingDetail(true)
    }
  }, [buildingId])

  return (
    <ItemContainer item={item} model={SupplierCompany}>
      <Grid spacing={3} container>
        <Grid item md={6}>
          <ItemCardTable title="Anagrafica">
            <ItemField name="name" />
            <ItemField name="vatNumber" />
            <ItemField name="companyType" />
            <ItemField name="status" template={ChipStatusSupplierCompany} />
            <ItemField name="gracePeriod" hideEmpty />
            <ItemField name="isInstitutional" />
            <ItemField name="nightIntervention" />
            <ItemField name="solidity" />
            <ItemField name="quality" />
            <ItemField name="sustainability" />
            <ItemField name="notes" />
          </ItemCardTable>
        </Grid>
        <Grid item md={6}>
          <ItemCardTable title="Contatti">
            <ItemField name="telephones" containerType={Grid} />
            <ItemField name="mobiles" containerType={Grid} />
            <ItemField name="emails" containerType={Grid} />
            <ItemField name="pec" containerType={Grid} />
          </ItemCardTable>
        </Grid>
        {!isBuildingDetail && <SupplierServiceItemField />}
      </Grid>
    </ItemContainer>
  )
}
