import * as React from 'react'

const PersonalIcon = (props) => (
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="-40 -40 280 280"
    style={{
      enableBackground: 'new 0 0 202 200',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.st0{fill:#ffca0c}'}</style>
    <path
      className="st0"
      d="M75.9 142.9c-18.5 4.2-19.7 11.5-5.7 29.3-1.5-4.5-3.5-8.8-4.2-13.5-.5-3 .2-8.8 1.7-9.3 3-1 8.2-.5 10.2 1.5 24.4 27.3 56.4 49.3 65.9 87.8 2 8.2 5.5 16.3 8.7 26-11 0-21 .3-30.9-.2-2 0-4.2-1.5-5.5-3.2-14.7-18-28.4-37-44.4-54-12.7-13.5-21-27.5-22-46.3-1.2-23.8-4-47.5-5.2-71.3-.2-4.8 2.7-9.8 4.5-14.5 4.2 3.5 10.7 6.5 12 10.8 5.7 19.2 10 38.2 14.9 56.9zM166.2 264.4c9-30 17.7-59.5 42.4-81 10-8.8 18-19.8 27.4-28.8 3.5-3.2 9.2-4 13.7-6 .2 5 .7 10 .5 15-.2 3-1.5 5.7-3 10.2 14.7-17.8 13-27.5-5.5-30.8 5.2-19.5 10-39 15.7-58.3 1.2-4 7-6.5 10.7-9.5 1.7 4.8 5.2 9.5 5 14-1.7 28.5-3.7 57-7.2 85.3-1 8.5-6.7 17.5-12.5 24.3-13.5 16.3-31.7 29.3-41.7 47.3s-22.2 20.5-39.2 18.8c-1.8-.2-3.5-.2-6.3-.5z"
      transform="matrix(.88297 0 0 .88297 -39.291 -34.335)"
    />
    <path
      d="M200.2 158.2h-82.6v-22.3c0-17.5 10.7-33 27.2-39.3 1-.5 2.7-1.5 2.7-2.5s-1.2-2.3-2.2-2.8c-10.5-6.3-15.5-17-14-28 1.5-12.3 10.5-21.8 22.2-24 15.2-3 30.7 6.8 33.2 23.3 1.7 12-3.2 22.5-14 28.8-1 .5-2.2 1.7-2.2 3 0 .8 1.5 1.8 2.5 2.3 14.5 5.8 23.4 16.5 26.4 31.8.7 4 .5 8.3.5 12.3.5 5.6.3 11.4.3 17.4z"
      style={{
        fill: '#4071b6',
      }}
      transform="matrix(.88297 0 0 .88297 -39.291 -34.335)"
    />
  </svg>
)

export default PersonalIcon
