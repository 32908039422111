import i18next from 'i18next'
import PropTypes from 'prop-types'

export const TicketType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.string,
    title: PropTypes.string,
    tipologia: PropTypes.string,
    stato: PropTypes.string,
})

export default {
    primaryKey: 'id',
    columns: [
        {
            id: 'created',
            label: i18next.t('tickets:ticketList.dateField'),
            date: true,
        },
        {
            id: 'title',
            label: i18next.t('tickets:ticketList.titleField')
        },
        {
            id: 'reportTypeObj.name',
            label: i18next.t('tickets:ticketList.typeField')
        },
        {
            id: 'stato',
            label: i18next.t('tickets:ticketList.statusField')
        },
        {
            id: 'dettaglio',
        }
    ]
}
