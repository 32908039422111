import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from 'react-leaflet'
import * as R from 'ramda'

const DraggableMarker = ({ initialPosition, onChangePosition, children }) => {
  const [position, setPosition] = React.useState(initialPosition)
  const markerRef = React.useRef(null)
  const eventHandlers = React.useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current
        if (marker !== null) {
          setPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )

  React.useEffect(() => {
    if (onChangePosition) {
      const newPosition = R.and(R.has('lat', position), R.has('lng', position))
        ? [parseFloat(position.lat), parseFloat(position.lng)]
        : position
      onChangePosition(newPosition)
    }
  }, [position])

  // update position programmatically
  React.useEffect(() => {
    setPosition(initialPosition)
  }, [initialPosition[0], initialPosition[1]])

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
      {children}
    </Marker>
  )
}

DraggableMarker.propTypes = {
  initialPosition: PropTypes.array,
  onChangePosition: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};



export default DraggableMarker
