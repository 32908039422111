import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import BuildingAnagraphic from '@Auth/Models/BuildingAnagraphic'
import { useBuildingAnagraphicsQuery } from '@Buildings/Services/Api'
import DataTable from '@Common/Components/DataTable'
import Dropdown from '@Common/Components/Dropdown'
import { setStateFromEvent } from '@Common/Utils/Events'

const DISPLAY_COLUMNS = [
  'extra.id',
  'extra.user.username',
  'extra.user.isActive',
  'extra.phone',
  'nominativo',
  'nome',
  'cognome',
  'codiceFiscale',
  'email',
  'extra.user.id',
]

const SEARCH_FIELDS = ['nominativo', 'cognome', 'nome', 'ragioneSociale', 'partitaIva', 'codiceFiscale']

const filters = {
  0: R.always(),
  1: R.hasPath(['extra', 'id']),
  2: R.complement(R.hasPath(['extra', 'id'])),
}

const FilterContainer = styled.div`
  padding-left: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  > * {
    padding-right: 1rem;
  }
`

const BuildingResidentsList = ({ arcadiaId }) => {
  const { t } = useTranslation()
  const [registeredFilter, setRegisteredFilter] = React.useState(0)
  const [residents, setResidents] = React.useState([])
  const { data, isFetching } = useBuildingAnagraphicsQuery(arcadiaId)

  React.useEffect(() => {
    setResidents(data?.filter(filters[registeredFilter]) ?? [])
  }, [registeredFilter, data])

  return (
    <div>
      <DataTable
        name="operational-building-residents"
        selectable={false}
        data={residents}
        model={BuildingAnagraphic}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        sortField="id"
        sortDirection="desc"
        loading={isFetching}
        noBulkSelection
        noExport
      >
        <FilterContainer>
          <Dropdown
            width="170px"
            options={[
              { id: 1, label: t('common:ui.Registered') },
              { id: 2, label: t('common:ui.Unregistered') },
            ]}
            labelKey="label"
            valueKey="id"
            emptyValue={0}
            emptyLabel={t('common:ui.All')}
            onChange={setStateFromEvent(setRegisteredFilter)}
            value={registeredFilter}
            label={t('common:ui.State')}
          />
        </FilterContainer>
      </DataTable>
    </div>
  )
}

BuildingResidentsList.propTypes = {
  arcadiaId: PropTypes.string,
}

export default BuildingResidentsList
