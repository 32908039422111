/* eslint-disable react/prop-types */

/**
 * This component allow us to share item with every children through context.
 * This is useful to avoid pass props manully.
 */
import { createContext } from 'react'

export const ItemContainerContext = createContext()

export const ItemContainer = (props) => {
  const { item, model, children } = props
  const context = { model, item }
  return <ItemContainerContext.Provider value={context}>{children}</ItemContainerContext.Provider>
}
