import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import { FormControl, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import EmployeeByRoleSelect from '@Auth/Components/EmployeeByRoleSelect'
import { ROLES } from '@Auth/Costants'
import BranchSelect from '@Branches/Components/BranchSelect'
import BuildingMapFormField from '@Buildings/Forms/BranchBuildingForm/BuildingMapFormField'
import Building, { BuildingType } from '@Buildings/Models/Building'
import Alert from '@Common/Components/Alert'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Margin } from '@Common/Components/Styles'
import Table from '@Common/Components/Table'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import StripeAccountConnectExternalAccountsSelect from '@Payments/Components/StripeAccountConnectExternalAccountsSelect'
import StripeAccountConnectSelect from '@Payments/Components/StripeAccountConnectSelect'

import { validate } from './Validation'

const DISPLAY_COLUMNS = ['nominativo', 'codiceFiscale', 'indirizzo.via', 'indirizzo.civico', 'indirizzo.comune']

const BranchBuildingForm = ({ buildings, isEditing, onClose, onSave }) => {
  const { t } = useTranslation()
  const title = t(`buildings:ui.${isEditing ? 'Edit' : 'Create'}BranchBuilding`)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  let contiCorrenti = []

  let form
  if (isEditing) {
    const building = buildings[0]
    contiCorrenti = building.contiCorrenti ?? []

    form = {
      arcadiaId: building.id,
      administrator: R.defaultTo(null, building.extra?.administrator),
      branch: R.defaultTo(null, building.extra?.branch),
      stripeAccountConnectId: R.defaultTo(null, building.extra?.stripeAccountConnectId),
      lat: R.defaultTo(null, building.extra?.lat),
      lng: R.defaultTo(null, building.extra?.lng),
      contiCorrenti: R.defaultTo(null, contiCorrenti),
    }
  } else {
    form = {
      arcadiaIds: buildings.map((b) => b.id),
      administrator: null,
      branch: null,
      stripeAccountConnectId: null,
      lat: null,
      lng: null,
      contiCorrenti: null,
    }
  }

  const { fields, setField, setFields, errors, setErrors } = useForm(form, true)

  const submit = async () => {
    logger.debug('BranchBuilding form submission, fields:', fields)
    if (validate(fields, setErrors, isEditing)) {
      setIsSubmitting(true)
      logger.debug('BranchBuilding form submission, validation passed, saving')
      const { error, isSuccessful } = await onSave(buildBranchBuildingPayload(fields))
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('BranchBuilding form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('BranchBuilding form submission, validation failed')
    }
  }

  const buildBranchBuildingPayload = (fields) => (isEditing ? { id: buildings[0].extra.id, ...fields } : fields)

  const handleGuessAdminByName = (users) => {
    if (isEditing) return

    // Search for users that matches by both firstName and lastName
    const lastName = R.path(['referente', 'cognome'], buildings[0])
    if (users && lastName) {
      const firstName = R.path(['referente', 'nome'], buildings[0])
      const reLastName = new RegExp(lastName, 'i')
      const reFirstName = new RegExp(firstName, 'i')
      const guessedUser = users.find((user) => {
        return reLastName.test(user.employee?.lastName) && reFirstName.test(user.employee?.firstName)
      })
      setFields({ ...fields, administrator: guessedUser?.id, branch: '' })
    }
  }

  const handleGuessBranch = (branch) => !fields.branch && setFields({ ...fields, branch: branch.id })

  const handleGuessStripeAccount = (stripeAccount) =>
    !fields.stripeAccountConnectId && setFields({ ...fields, stripeAccountConnectId: stripeAccount.id })

  const handlePositionChange = (position) => setFields({ ...fields, lat: position[0], lng: position[1] })

  const handleAdminChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('administrator'),
    R.mergeRight({ branch: '' }),
    R.mergeRight({ stripeAccountConnectId: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const handleStripeChangeAccount = (acc) => {
    setFields({ ...fields, stripeAccountConnectId: acc.target.value ?? null })
  }

  const handleStopLoad = (event) => {
    console.log(event)
    setIsLoading(event)
  }

  function handleStripeChangeBankAccount(arcadia_id, stripe_id) {
    let contiCopy = []

    contiCorrenti.forEach((x) => {
      let copy = JSON.parse(JSON.stringify(x))

      if (copy.id === arcadia_id) {
        copy.stripeBankAccountId = stripe_id
      }

      contiCopy.push(copy)
    })
    setFields({ ...fields, contiCorrenti: contiCopy ?? null })
  }

  let stripeSection = null

  if (contiCorrenti && contiCorrenti.length > 0) {
    let formAccountStripeDynamic = (
      <FormControl fullWidth>
        <StripeAccountConnectSelect
          value={fields.stripeAccountConnectId}
          label={t('payments:ui.selectStripeAccount')}
          onChange={handleStripeChangeAccount}
          selectedUserId={fields.stripeAccountConnectId || null}
          onStripeAccountsFilter={handleGuessStripeAccount}
          error={!!errors.stripeAccountConnectId}
          helperText={errors.stripeAccountConnectId}
          onStopLoad={handleStopLoad}
        />
      </FormControl>
    )

    let elementsDynamicContiCorrenti = []

    fields.contiCorrenti.forEach((element, index) => {
      elementsDynamicContiCorrenti.push(
        <Grid container item xs={12} sm={12} key={index}>
          <Grid item xs={5} sm={5} sx={{ m: 'auto 0 4px' }}>
            <span>{t('payments:ui.contoArcadia')}</span>
            <Typography mb={0}>{element.codiceIBAN}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ m: 'auto 0 4px' }}>
            <ArrowForwardIos fontSize="2"></ArrowForwardIos>
          </Grid>
          <Grid item xs={5} sm={5}>
            <StripeAccountConnectExternalAccountsSelect
              value={element.stripeBankAccountId}
              stripeAccountId={fields.stripeAccountConnectId}
              onChange={(e) => handleStripeChangeBankAccount(element.id, e.target.value)}
            />
          </Grid>
        </Grid>,
      )
    })

    if (isLoading) {
      stripeSection = <>{formAccountStripeDynamic}</>
    } else {
      stripeSection = (
        <>
          {formAccountStripeDynamic}
          {fields.stripeAccountConnectId && (
            <Grid item container spacing={2} mt={1}>
              {elementsDynamicContiCorrenti}
            </Grid>
          )}
        </>
      )
    }
  } else {
    stripeSection = <Alert severity="info">{t('payments:ui.noContiArcadia')}</Alert>
  }

  return (
    <Modal
      title={title}
      size="md"
      onClose={onClose}
      onSubmit={submit}
      disableSubmit={!buildings[0].indirizzo && !buildings[0].extra}
    >
      {isSubmitting && <Loader overlay />}
      {errors.__form && <Alert level="error">{errors.__form}</Alert>}
      <Margin bottom="4rem">
        <Table data={buildings} model={Building} listDisplay={DISPLAY_COLUMNS} />
      </Margin>
      <Grid container rowSpacing={0} spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" component="h6">
            {t('payments:ui.sectionPayment')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          {stripeSection}
        </Grid>
        <Grid item container spacing={2} mt={1}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" component="h6">
              {t('payments:ui.sectionAmministratore')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <EmployeeByRoleSelect
                value={fields.administrator}
                roles={[ROLES.BRANCH_DIRECTOR, ROLES.SENIOR_ADMIN]}
                label={t('buildings:ui.SelectAdmin')}
                guessByName={handleGuessAdminByName}
                onChange={handleAdminChange}
                error={!!errors.administrator}
                helperText={errors.administrator}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <BranchSelect
                value={fields.branch}
                label={t('buildings:fields.branch.__field')}
                onChange={setStateFromEvent(setField('branch'))}
                selectedUserId={fields.administrator || null}
                onBranchesFilter={handleGuessBranch}
                error={!!errors.branch}
                helperText={errors.branch}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {buildings.length === 1 && (
        <Margin top="2rem">
          <Typography variant="body1" mb={1}>
            {t('buildings:info.DragMarkerToAdjust')}
          </Typography>
          <BuildingMapFormField building={buildings[0]} onChangePosition={handlePositionChange} />
        </Margin>
      )}
    </Modal>
  )
}

BranchBuildingForm.propTypes = {
  buildings: PropTypes.arrayOf(BuildingType),
  isEditing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default BranchBuildingForm
