import { Box, Chip } from '@mui/material'
import PropTypes from 'prop-types'

import { CondoStateActive, CondoStateLost } from '@Condo/Models/Condo'

const ChipCondoState = ({ state }) => {
  let result = null

  switch (state) {
    case CondoStateActive.id:
      result = <Chip label={CondoStateActive.label} color="primary" size="small" />
      break
    case CondoStateLost.id:
      result = <Chip label={CondoStateLost.label} color="warning" size="small" />
      break
    default:
      result = '-'
      break
  }

  return <Box>{result}</Box>
}

ChipCondoState.propTypes = {
  state: PropTypes.string.isRequired,
}

export default ChipCondoState
