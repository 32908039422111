import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  let err = {}

  let required = ['stairN', 'podNumber', 'podSupplyType', 'podSupplier']

  checkRequired(err, required, fields)

  setErrors(err)
  return R.isEmpty(err)
}

export const clearError = (field, errors, setErrors) => {
  setErrors({ ...errors, ...{ [field]: null } })
}
