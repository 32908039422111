import * as R from 'ramda'

import { HQ_ROLES } from '@Auth/Costants'
import { logsToOperationalDashboard } from '@Common/Utils/Permissions'
import { makePath } from '@Config'

export const getLoginRedirectUrl = (user) => {
  // resident
  if (user.resident?.id) {
    return makePath('myEstia.home')
  } else if (logsToOperationalDashboard(user)) {
    return makePath('home')
  } else {
    return makePath('admin.dashboard')
  }
}

export const isRoleSupervisable = R.flip(R.includes)(R.values(HQ_ROLES))

export const hasRoledata = R.equals(HQ_ROLES.HQ_ADMINISTRATIVE)

export const canViewAllBranches = R.either(
  R.propEq('isSuperUser', true),
  R.pipe(
    R.prop('groups'),
    R.filter(R.pipe(R.prop('id'), R.flip(R.includes)(R.values(HQ_ROLES)))),
    R.length,
    R.flip(R.gt)(0),
  ),
)

export const canAssignOwners = R.either(
  R.prop('isSuperUser'),
  R.pipe(
    R.prop('groups'),
    R.defaultTo([]),
    R.head,
    R.prop('id'),
    R.flip(R.includes)(R.values([HQ_ROLES.HQ_IT, HQ_ROLES.HQ_MAIN_OFFICE, HQ_ROLES.HQ_ADMINISTRATIVE])),
  ),
)

export const getEmployeeDisplayName = R.pipe(
  R.defaultTo({ firstName: '-' }),
  R.pick(['firstName', 'lastName']),
  R.values,
  R.join(' '),
)

export const getEmployeeEmail = R.pipe(R.path(['user', 'username']))

export const getEmployeeInfo = R.ifElse(
  R.isNil,
  R.always('-'),
  R.converge(R.concat, [R.pipe(getEmployeeDisplayName, (v) => `${v} - `), getEmployeeEmail]),
)
