import { SupplierBranch } from '@Supplier/Models/SupplierBranch'

export const SupplierReferent = {
  name: 'SupplierReferent',
  url: '/condo/bicert/suppliers/:supplierId/referents/:id',
  schema: {
    lastName: { required: true },
    firstName: { required: true },
    role: {},
    status: { required: true, type: 'options', choices: ['A', 'I'], defaultValue: 'A' },
    email: { required: true, type: 'email' },
    otherEmails: { multiple: true, type: 'email' },
    telephones: { multiple: true, type: 'phone' },
    mobiles: { multiple: true, type: 'phone' },
    branches: { type: 'foreign_key', multiple: true, model: SupplierBranch },
  },
}
