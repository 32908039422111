import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors, isEditing) => {
  const err = {}

  const required = [
    isEditing ? 'arcadiaId' : 'arcadiaIds',
    'administrator',
    'branch',
  ]

  checkRequired(err, required, fields)

  setErrors(err)
  return R.isEmpty(err)
}
