import { Drawer, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import styled, { css } from 'styled-components'

import AdminSidebar from '@Common/Components/AdminSidebar'
import Navbar from '@Common/Components/Navbar'
import { selectMobileMenuIsOpen, toggleMobileMenu } from '@Core/Redux/Ui'

import { version } from '../../../package.json'
import AdminBreadcrumbs from '../Components/AdminBreadcrumbs'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: 60px;
`

const Main = styled.main`
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    color: ${theme.palette.background.constrastText};
  `}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

const Content = styled.section`
  flex-grow: 1;
  padding: 2rem;
`

const Footer = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.footer.main};
  `}
  padding: 1rem;
`

const Software = styled.span`
  font-size: 0.9rem;
`

const AdminLayout = ({ children, aside, contentStyle }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const mobileSidebarIsOpen = useSelector(selectMobileMenuIsOpen)
  const handleMobileMenuToggle = () => dispatch(toggleMobileMenu())

  return (
    <>
      <Wrapper>
        <Navbar onHamburgerClick={handleMobileMenuToggle} variant="admin-area" />
        <Drawer
          anchor="left"
          open={mobileSidebarIsOpen}
          onClose={handleMobileMenuToggle}
          sx={{ '.MuiPaper-root': { height: 'calc(100% - 60px)', top: 60 } }}
        >
          <AdminSidebar fill onItemClick={handleMobileMenuToggle} />
        </Drawer>
        <Container>
          {!isSmallDevice && <AdminSidebar />}
          <Main>
            <AdminBreadcrumbs />
            <Content style={contentStyle}>
              <Outlet />
              {children}
            </Content>
            <Footer>
              <Software>{t('common:ui.SoftwareVersion', { version })}</Software>
            </Footer>
          </Main>
          {aside}
        </Container>
      </Wrapper>
    </>
  )
}

AdminLayout.defaultProps = {
  breadcrumbs: [],
  aside: null,
  contentStyle: {},
}

AdminLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  aside: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  contentStyle: PropTypes.object,
}

export default AdminLayout
