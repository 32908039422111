import { isNil } from 'ramda'

export const timeRangeToMinutes = (value, unit) => {
  if (isNil(value)) return value
  switch (unit) {
    case 'm':
      return value
    case 'h':
      return value * 60
    case 'd':
      return value * 60 * 24
    case 'w':
      return value * 60 * 24 * 7
    default:
      return value
  }
}

export const minutesToTimeRange = (value, unit) => {
  if (isNil(value)) return value
  switch (unit) {
    case 'm':
      return value
    case 'h':
      return value / 60
    case 'd':
      return value / (60 * 24)
    case 'w':
      return value / (60 * 24 * 7)
    default:
      return value

  }
}
