import React from 'react'
import { useBreadcrumbs, useCurrentUser } from '../../../Common/Utils/Hooks'
import { useTranslation } from 'react-i18next'
import { EmployeesPermissions } from '../../Permissions'
import {
  useChangeUserPasswordMutation,
  useCreateEmployeeMutation,
  useDeleteUserMutation,
  useUpdateEmployeeMutation,
} from '../../Services/Api'
import AddFab from '@Common/Components/AddFab'
import EmployeeForm from '../../Forms/EmployeeForm'
import ChangePasswordForm from '../../Forms/ChangePasswordForm'
import EmployeesList from '../../Components/EmployeesList'
import styled from 'styled-components'
import config from '../../../Config'
import { responseStringError } from '../../../Common/Utils/Api'
import { toast } from 'react-toastify'
import * as R from 'ramda'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminEmployeesView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  // api
  const [createEmployee] = useCreateEmployeeMutation()
  const [updateEmployee] = useUpdateEmployeeMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [changeUserPassword] = useChangeUserPasswordMutation()
  // form
  const [form, setForm] = React.useState({ isOpen: false, employee: null })
  const [changePasswordForm, setChangePasswordForm] = React.useState({ isOpen: false, employee: null })

  useBreadcrumbs([{ label: t('common:navigation.Employees'), path: config.urls.admin.employees, Icon: 'Employee' }])

  const handleOpenForm = () => setForm({ isOpen: true, employee: null })
  const handleCloseForm = () => setForm({ isOpen: false, employee: null })

  const handleOpenEditForm = (employee) => setForm({ isOpen: true, employee })
  const handleCloseChangePasswordForm = () => setChangePasswordForm({ isOpen: false, employee: null })

  const handleSave = (fields) => (form.employee?.id ? handleUpdateEmployee(fields) : handleCreateEmployee(fields))

  const handleCreateEmployee = async (fields) => {
    try {
      const response = await createEmployee(fields).unwrap()
      toast.success(t('auth:success.EmployeeCreated', { name: fields.user.username }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('auth:errors.CreateEmployeeError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateEmployee = async (fields) => {
    try {
      const response = await updateEmployee(fields).unwrap()
      toast.success(t('auth:success.EmployeeUpdated', { name: fields.user.username }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('auth:errors.UpdateEmployeeError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (employees) => {
    try {
      const ids = employees.map(R.path(['user', 'id']))
      const promises = ids.map(async (id) => {
        return deleteUser(id).unwrap()
      })
      await Promise.all(promises)
      toast.success(
        t('auth:success.EmployeeDeleted', {
          count: employees.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('auth:errors.DeleteEmployeeError', {
          error: err.data?.detail || t('common.errors.UnknownError'),
          count: employees.length,
        }),
      )
    }
  }

  const handleSaveChangePassword = async (userId, { password }) => {
    try {
      const response = await changeUserPassword({ userId, password }).unwrap()
      toast.success(t('auth:success.EmployeePasswordChanged'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('auth:errors.ChangeUserPasswordError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleChangePassword = (employee) => {
    setChangePasswordForm({ isOpen: true, employee: employee })
  }

  return (
    <Main>
      <EmployeesList onEdit={handleOpenEditForm} onDelete={handleDelete} onChangePassword={handleChangePassword} />
      {form.isOpen && <EmployeeForm employee={form.employee} onClose={handleCloseForm} onSave={handleSave} />}
      {changePasswordForm.isOpen && (
        <ChangePasswordForm
          user={changePasswordForm.employee.user}
          onClose={handleCloseChangePasswordForm}
          onSave={handleSaveChangePassword}
        />
      )}
      {EmployeesPermissions.create(user) && <AddFab onClick={handleOpenForm} />}
    </Main>
  )
}

export default AdminEmployeesView
