import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useLocalStorage } from '@Common/Utils/Hooks'

import { CondoSupplierCompanyByServiceListView } from './CondoSupplierCompanyByServiceListView'
import { SupplierCompanyListView } from './SupplierCompanyListView'

export const SupplierCompanyListViewMain = () => {
  const handleTabChange = (event, newValue) => setTab(newValue)
  const [tab, setTab] = useLocalStorage(' SupplierCompanyListView-tabSelected', 'default')
  const { buildingId } = useParams()
  const [isBuildingDetail, setIsBuildingDetail] = useState(false)

  useEffect(() => {
    if (buildingId) {
      setIsBuildingDetail(true)
    }
  }, [buildingId])

  if (!isBuildingDetail) {
    return <SupplierCompanyListView />
  }

  return (
    <TabContext value={tab}>
      <TabList onChange={handleTabChange}>
        <Tab label="Visualizzazione" value="default" />
        <Tab label="Per servizi" value="services" />
      </TabList>

      <TabPanel value="default" sx={{ px: 0 }}>
        <SupplierCompanyListView />
      </TabPanel>
      <TabPanel value="services" sx={{ px: 0 }}>
        <CondoSupplierCompanyByServiceListView />
      </TabPanel>
    </TabContext>
  )
}
