import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// namespaces
import i18nAuth from '@Auth/Locales/i18n.json'
import i18nBranches from '@Branches/Locales/i18n.json'
import i18nBuildings from '@Buildings/Locales/i18n.json'
import i18nCommon from '@Common/Locales/i18n.json'
import i18nCondo from '@Condo/Locales/i18n.json'
import i18nCustomerCare from '@CustomerCare/Locales/i18n.json'
import i18nDocuments from '@Documents/Locales/i18n.json'
import i18nMyEstia from '@MyEstia/Locales/i18n.json'
import i18nMyHome from '@MyHome/Locales/i18n.json'
import i18nNews from '@News/Locales/i18n.json'
import i18nNewsletter from '@Newsletter/Locales/i18n.json'
import i18nNotifications from '@Notifications/Locales/i18n.json'
import i18nPayments from '@Payments/Locales/i18n.json'
import i18nResidents from '@Residents/Locales/i18n.json'
import i18nServices from '@Services/Locales/i18n.json'
import i18nCertificationDocumentType from '@Supplier/Locales/i18n.CertificationDocumentType.json'
import i18nCityModel from '@Supplier/Locales/i18n.CityModel.json'
import i18nCondoSupplierCompany from '@Supplier/Locales/i18n.CondoSupplierCompany.json'
import i18nCondoSupplierCompanyByService from '@Supplier/Locales/i18n.CondoSupplierCompanyByService.json'
import i18nProvinceModel from '@Supplier/Locales/i18n.ProvinceModel.json'
import i18nRegionModel from '@Supplier/Locales/i18n.RegionModel.json'
import i18nSupplierAssociationContract from '@Supplier/Locales/i18n.SupplierAssociationContract.json'
import i18nSupplierAssociationService from '@Supplier/Locales/i18n.SupplierAssociationService.json'
import i18nSupplierBranch from '@Supplier/Locales/i18n.SupplierBranch.json'
import i18nSupplierCompany from '@Supplier/Locales/i18n.SupplierCompany.json'
import i18nSupplierCompanyType from '@Supplier/Locales/i18n.SupplierCompanyType.json'
import i18nSupplierDocument from '@Supplier/Locales/i18n.SupplierDocument.json'
import i18nSupplierReferent from '@Supplier/Locales/i18n.SupplierReferent.json'
import i18nSupplierServiceType from '@Supplier/Locales/i18n.SupplierServiceType.json'
import i18nTickets from '@Tickets/Locales/i18n.json'
import i18nTms from '@Tms/Locales/i18n.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  it: {
    common: i18nCommon,
    auth: i18nAuth,
    branches: i18nBranches,
    buildings: i18nBuildings,
    condo: i18nCondo,
    customerCare: i18nCustomerCare,
    documents: i18nDocuments,
    news: i18nNews,
    myEstia: i18nMyEstia,
    myHome: i18nMyHome,
    newsletter: i18nNewsletter,
    residents: i18nResidents,
    notifications: i18nNotifications,
    services: i18nServices,
    suppliercompany: i18nSupplierCompany,
    supplierreferent: i18nSupplierReferent,
    supplierdocument: i18nSupplierDocument,
    supplierbranch: i18nSupplierBranch,
    certificationdocumenttype: i18nCertificationDocumentType,
    supplierservicetype: i18nSupplierServiceType,
    suppliercompanytype: i18nSupplierCompanyType,
    city: i18nCityModel,
    province: i18nProvinceModel,
    region: i18nRegionModel,
    payments: i18nPayments,
    tickets: i18nTickets,
    tms: i18nTms,
    condosuppliercompany: i18nCondoSupplierCompany,
    condosuppliercompanybyservice: i18nCondoSupplierCompanyByService,
    supplierassociationservice: i18nSupplierAssociationService,
    supplierassociationcontract: i18nSupplierAssociationContract,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // language to use, you can use the i18n.changeLanguage function
    // to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    lng: 'it',
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
