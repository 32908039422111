import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FieldType } from '@Common/Types/Field'
import { useProvincesQuery } from '../../Services/Api/LocationApi'
import Dropdown from '../Dropdown'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'

const ProvinceSelect = ({ regionId, helperText, value, ...other }) => {
  const { t } = useTranslation()
  const { data } = useProvincesQuery()
  const [provinces, setProvinces] = useState([])

  useEffect(() => {
    if (data && regionId) {
      setProvinces(data.results.filter(R.propEq('region', regionId)).map(R.pick(['id', 'name'])))
    } else {
      setProvinces([])
    }
  }, [data, regionId])

  return (
    <Dropdown
      options={provinces}
      searchable
      labelKey="name"
      valueKey="id"
      value={provinces.length > 0 ? value : ''}
      helperText={!regionId ? t('auth:validation.SelectRegionFirst') : helperText}
      {...other}
    />
  )
}

export default ProvinceSelect

ProvinceSelect.propTypes = {
  regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...FieldType,
}
