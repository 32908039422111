/**
 * A series of utilities for build forms
 */
import { useFormContext } from 'react-hook-form'

import { getItemFieldFromContext } from '@Common/Utils/Hooks'

// get default field props
export const useDefaultFieldProps = (name, props) => {
  const form = useFormContext()
  const { error } = form.getFieldState(name)
  const { label, schema } = getItemFieldFromContext(name)

  const required = props?.required || schema?.required || false

  return {
    fullWidth: true,
    label: label || props?.label,
    control: form.control || props?.control,
    error: !!error?.message || props?.message,
    helperText: error?.message || props?.message,
    required: required,
    inputProps: {
      maxLength: schema?.maxLength,
      defaultValue: schema?.defaultValue || props?.defaultValue,
      defaultValues: schema?.defaultValues || props?.defaultValues,
    },
    defaultChecked: schema?.defaultChecked || props?.defaultChecked || false,
  }
}
