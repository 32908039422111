/* eslint-disable react/prop-types */

/**
 * Simple React hook form wrapper with grid
 *
 * We added grid here and in all fields component because
 * all form need do be display inside a grid
 * and so we reduce a lot of code in ours component
 */
import { DialogActions, DialogContent, Grid } from '@mui/material'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { checkValueIsObject } from '@Common/Utils/Types'

import { DialogContext } from '../ButtonDialog'
import { ItemContainer } from '../ItemContainer'
import { FormFooterV2 } from './FormFooterV2'

export const FormV2 = (props) => {
  const { t } = useTranslation()
  const { children, footer, form, onSubmit, model, isTableField = false } = props

  /**
   * Since we often use forms in modals, we check here if we are in the context of a modal.
   * If we are in a modal, we display the cancel button by default.
   */
  const dialogContext = useContext(DialogContext)
  const isRenderInDialog = !!dialogContext

  const setErrorsToField = (errorValue, errors, name, prefixName = null) => {
    if (checkValueIsObject(errorValue)) {
      let parseErrors = {}

      Object.keys(errorValue).map((keyObj) => {
        if (!parseErrors[errorValue?.[keyObj]]) {
          parseErrors[errorValue?.[keyObj]] = []
        }

        let position = Number(keyObj) + 1
        if (!isNaN(position)) parseErrors[errorValue?.[keyObj]].push(position)
      })

      let message = []

      Object.keys(parseErrors || {}).map((errorName) => {
        message.push(
          errorName +
            (Array.isArray(parseErrors[errorName]) && !parseErrors[errorName] && parseErrors[errorName].length > 0
              ? ` (posizione ${parseErrors[errorName].join(', ')})`
              : ''),
        )
      })

      const nameForm = (prefixName ? `${prefixName}.` : null) + name

      form.setError(nameForm, { type: 'custom', message: message.join(', ') })
    } else {
      form.setError(name, { type: 'custom', message: errors?.[name] })
    }
  }

  const parserData = (data) => {
    Object.keys(data).map((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((_, index) => {
          parserData(data[key][index])
        })
      }

      if (dayjs.isDayjs(data[key])) {
        data[key] = data[key].format('YYYY-MM-DD')
      }
    })

    return data
  }

  const handleSubmit = async (data) => {
    /**
     * transform all dayjs dates in string
     * i'm not sure that this is the right place to do this
     * in future maybe it's good do this in different ways
     */
    data = parserData(data)

    try {
      const dataResult = await onSubmit(data).unwrap()
      // try to close dialog if we are in dialog context
      dialogContext?.handleClose()
      toast.success(t('common:success.GeneralServerSuccess'))
      return Promise.resolve(dataResult)
    } catch (response) {
      const errors = response.data

      Object.keys(response?.data || {}).map((name) => {
        try {
          let errorValue = errors?.[name]

          if (Array.isArray(errorValue)) {
            errorValue.forEach((element, index) => {
              if (typeof element === 'string') {
                setErrorsToField(element, errors, name)
              } else {
                Object.keys(element || {}).map((elementName) => {
                  setErrorsToField(element, errors, elementName, `${name}[${index}]`)
                })
              }
            })
          } else {
            setErrorsToField(errorValue, errors, name)
          }
        } catch (err) {
          form.setError(name, { type: 'custom', message: 'Formato non valido' })
        }
      })

      return Promise.reject()
    }
  }

  const renderFooter = footer || <FormFooterV2 />

  const renderContent = () => {
    if (isRenderInDialog) {
      return (
        <>
          <DialogContent dividers>
            {!isTableField ? (
              <Grid component="form" container rowSpacing={3} spacing={3} onSubmit={form.handleSubmit(handleSubmit)}>
                {children}
              </Grid>
            ) : (
              children
            )}
          </DialogContent>
          {/** let's create another form with the buttons inside otherwise the footer is not seen well in the modal */}
          <DialogActions>
            <form onSubmit={form.handleSubmit(handleSubmit)}>{renderFooter}</form>
          </DialogActions>
        </>
      )
    }

    return (
      <Grid component="form" container rowSpacing={3} spacing={3} onSubmit={form.handleSubmit(handleSubmit)}>
        {children}
        {renderFooter}
      </Grid>
    )
  }

  return (
    <ItemContainer model={model}>
      <FormProvider {...form}>{renderContent()}</FormProvider>
    </ItemContainer>
  )
}
