import { Send } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Anagraphic from '@Auth/Models/Anagraphic'
import { useAnagraphicsQuery } from '@Auth/Services/Api'
import { getAllEmailsFromContacts } from '@Buildings/Utils'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { useConfirm } from '@Common/Utils/Hooks'

import { ResidentsPermissions } from '../Permissions'

const DISPLAY_COLUMNS = [
  'extra.id',
  'extra.user.username',
  'extra.user.isActive',
  'extra.phone',
  'nome',
  'cognome',
  'codiceFiscale',
  'ragioneSociale',
  'partitaIva',
  'immobili',
]

const SEARCH_FIELDS = ['nominativo', 'cognome', 'nome', 'ragioneSociale', 'partitaIva', 'codiceFiscale']

const FIELDS_MAPPING = {
  emails: (anagraphic) => getAllEmailsFromContacts(anagraphic.contatti),
  immobili: (record) => record.immobili?.map(R.prop('denominazione')).join(', '),
}

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${field}__icontains__or`] = qs.qsAdditions.search
    })
    delete qs.qsAdditions.search
  }

  return qs
}

const qsAdditions = { riferimentoEsterno__isnull: false }

const ResidentsList = ({ onEdit, onDelete, onChangePassword, onNotify }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])

  const handleDeleteUser = async () => {
    const residents = R.pluck('extra', selected)
    onDelete(residents)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteUser)
  const bulkActions = React.useMemo(
    () => [
      {
        id: 'DELETE',
        icon: <DeleteIcon size="small" />,
        label: t('common:actions.Delete'),
        perm: ResidentsPermissions.delete,
      },
      {
        id: 'NOTIFY',
        icon: <Send size="small" />,
        label: t('common:actions.Notify'),
        perm: ResidentsPermissions.notify,
      },
    ],
    [],
  )
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: ResidentsPermissions.update,
      },
      {
        id: 'CHANGE_PASSWORD',
        label: t('auth:actions.ChangePassword'),
        icon: <LockIcon size="small" />,
        perm: ResidentsPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: ResidentsPermissions.delete,
      },
      {
        id: 'NOTIFY',
        label: t('common:actions.Notify'),
        icon: <Send size="small" />,
        perm: ResidentsPermissions.notify,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, anagraphic) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('auth:ui.DeleteUserConfirmationTitle'),
          t('auth:ui.DeleteUserConfirmationText', { count: selected.length }),
          { selected: selected, field: 'extra.user.username' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(anagraphic.extra)
      } else if (actionId === 'NOTIFY') {
        onNotify(selected)
      } else if (actionId === 'CHANGE_PASSWORD') {
        onChangePassword(anagraphic.extra)
      }
    },
    [openDeleteConfirm, selected],
  )

  const checkSelectable = React.useCallback((user) => !user.isSuperUser, [])

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-residents'),
      offset: 0,
      ordering: '-id',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useAnagraphicsQuery(convertSearchQuery(qs))

  return (
    <div>
      <DataTable
        name="admin-residents"
        selectable={checkSelectable}
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Anagraphic}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        fieldsMapping={FIELDS_MAPPING}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        bulkActions={bulkActions}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      />
      {DeleteConfirm}
    </div>
  )
}

ResidentsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onNotify: PropTypes.func.isRequired,
}

export default ResidentsList
