import { Box, Button, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ActionsFiltersForm = () => {
  const filterForm = useFormContext()
  const { t } = useTranslation()

  return (
    <Grid
      justifyContent={'flex-end'}
      container
      sx={{
        mt: 2,
      }}
    >
      <Grid component={Box} item>
        <Button
          size="small"
          color="error"
          onClick={() => {
            filterForm.reset()
            document.getElementById('filters_table').reset()
          }}
        >
          {t('common:actions.ClearFilters')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ActionsFiltersForm
