import { ForwardToInbox } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Anagraphic from '@Auth/Models/Anagraphic'
import { useAnagraphicsQuery } from '@Auth/Services/Api'
import { getAllEmailsFromContacts } from '@Buildings/Utils'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'

const DISPLAY_COLUMNS = [
  'tipo.displayValue',
  'nominativo',
  'cognome',
  'nome',
  'ragioneSociale',
  'partitaIva',
  'codiceFiscale',
  'emails',
  'immobili',
]

const SEARCH_FIELDS = ['nominativo', 'cognome', 'nome', 'ragioneSociale', 'partitaIva', 'codiceFiscale']

const FIELDS_MAPPING = {
  emails: (anagraphic) => getAllEmailsFromContacts(anagraphic.contatti),
  immobili: (record) => record.immobili.map(prop('denominazione')).join(', '),
}

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${field}__icontains__or`] = qs.qsAdditions.search
    })
    delete qs.qsAdditions.search
  }

  return qs
}

const qsAdditions = { classe__id__exact: 0, gruppoEsterno__isnull: true }

const AnagraphicsList = ({ onCreateInvitation, onCreateBulkInvitation }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-anagraphics'),
      offset: 0,
      ordering: 'titolo',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useAnagraphicsQuery(convertSearchQuery(qs))

  const actions = React.useMemo(
    () => [
      {
        id: 'INVITE',
        label: t('auth:actions.Invite'),
        icon: <ForwardToInbox size="small" />,
      },
    ],
    [],
  )

  const bulkActions = React.useMemo(
    () => [
      {
        id: 'BULK_INVITE',
        label: t('auth:actions.InviteSelected'),
        icon: <ForwardToInbox size="small" />,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, anagraphic) => {
      switch (actionId) {
        case 'INVITE':
          onCreateInvitation(anagraphic)
          break
        case 'BULK_INVITE':
          onCreateBulkInvitation(selected)
          break
      }
    },
    [selected],
  )

  return (
    <div>
      <DataTable
        name="admin-anagraphics"
        selected={selected}
        onSelect={setSelected}
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Anagraphic}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        fieldsMapping={FIELDS_MAPPING}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        bulkActions={bulkActions}
        useDjangoOrdering
        noExport
      />
    </div>
  )
}

AnagraphicsList.propTypes = {
  onCreateInvitation: PropTypes.func.isRequired,
  onCreateBulkInvitation: PropTypes.func.isRequired,
}

export default AnagraphicsList
