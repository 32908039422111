import Carousel from 'react-material-ui-carousel'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import NewsModal from '@News/Components/NewsModal'
import { Grid } from '@mui/material'
import { Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useResizeDetector } from 'react-resize-detector'
import Button from '@MyEstia/Components/Button'
import { useMediaQuery } from '@mui/material'
import Right from '@Assets/Images/news_arrow_right.svg'
import Left from '@Assets/Images/news_arrow_left.svg'
import { useNavigate } from 'react-router'
import { makePath } from '@Config'
import dayjs from 'dayjs'

const SubButton = styled(Button)`
  width: 200px;
  height: 48px;
  border-radius: 100px;
`
const Title= styled.h1`
h1 {
  color: ${({ theme }) => theme.palette.text.main};
  font-size: 39px;
  margin-top: 0px;
  margin-bottom:5px;
}
`

const NewsCarousel = styled(Carousel)`
  background-color: ${({ theme }) => theme.palette.lightTwo.main};
  min-height: 200px;
   padding-top: 30px;
  .margin-left {
    margin-left: 60px;
    height: 50px;
  }
  .margin-right {
    margin-right: 60px;
    height: 50px;
  }
  h1 {
    color: ${({ theme }) => theme.palette.text.main};
    font-size: 39px;
    margin-top: 0px;
    margin-bottom:5px;
  }
  svg {
    font-size: 25px;
  }
  .bold-text {
    font-weight: bold;
  }
  .sc-hRMWxn klQhSo {
    min-height: 160px;
  }
`

const News = styled.div`
  background: ${({ theme }) => theme.palette.lightTwo.main};
  p {
    margin-bottom: 0px;
  }
  .date-title {
    font-size: 20px;
  }
`
const ImgGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  

`
const Image = styled.div`
-webkit-border-radius: 500px;
-moz-border-radius: 500px;
border-radius: 5000px;
border: none;
background-position:center;
background-size:cover;
`

const CenterGrid = styled(Grid)`
  text-align: center;
`
const CarouselContainer = styled.div`
  background: ${({ theme }) => theme.palette.lightTwo.main};
`

const Showcase = ({ data, loading }) => {
  const navigate = useNavigate()
  const [modal, setModal] = React.useState(false)
  const [modalNews, setModalNews] = React.useState({})
  const handleOpenModal = (news) => {
    setModal(true)
    setModalNews(news)
  }

  const handleCloseModal = () => {
    setModal(false)
    setModalNews({})
  }

  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isVerySmallDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isBigDevice = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const isVeryLargeDevice = useMediaQuery((theme) => theme.breakpoints.down('xl'))
  const theme = useTheme()

  const { t } = useTranslation()

  const onResize = React.useCallback(() => {
    // on resize logic
  }, [])

  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 1000,
    onResize,
  })
  return (
    <CarouselContainer ref={ref} width={width}>
      {modal && <NewsModal news={modalNews} onClose={handleCloseModal} />}

      {!loading && (
        <NewsCarousel
          height={isVerySmallDevice ? 350 :isSmallDevice?300: 180}
          autoPlay={false}
          animation="fade"
          navButtonsProps={{
            style: {
              backgroundColor: 'transparent',
              borderRadius: 0,
            },
          }}
          NextIcon={
            <img
              src={Right}
              style={{ height: '50px', marginRight: isBigDevice ? '60px' : isVeryLargeDevice ? '120px' : '200px' }}
            />
          }
          PrevIcon={
            <img
              src={Left}
              style={{ height: '50px', marginLeft: isBigDevice ? '60px' : isVeryLargeDevice ? '120px' : '200px' }}
            />
          }
          navButtonsAlwaysVisible={isSmallDevice ? false : true}
          navButtonsAlwaysInvisible={isSmallDevice ? true : false}
          indicatorContainerProps={{
            style: {
              marginTop: '0px',
              paddingBottom: isSmallDevice ? '10px' : '25px',
              background: `${theme.palette.lighTwo}`,
            },
          }}
          indicatorIconButtonProps={{
            style: {
              color: `${theme.palette.navigationCircle.main}`,
              paddingLeft: '8px',
              opacity: '0.5',
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: `${theme.palette.navigationCircle.main}`,
              opacity: '1',
            },
          }}
        >
          {data.map((item, i) => (
            <Item key={i} item={item} onClick={handleOpenModal}
            />
          ))}
        </NewsCarousel>
      )}
      {isSmallDevice && !loading && (
        <ImgGrid>
          <SubButton
            component={Link}
            onClick={() => navigate(makePath('myEstia.news'))}
            fullWidth
            variant="contained"
            style={{
              textDecorationColor: "#fff0",
              visibility: isSmallDevice ? 'visible' : 'hidden',
              height: !isSmallDevice ? '0px' : '48px',
              marginTop: '0px',
              marginBottom: '25px',
            }}
          >
            {t('news:ui.ShowNews')}
          </SubButton>
        </ImgGrid>
      )}
    </CarouselContainer>
  )
}

const Item = ({ item, onClick }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  let abstract = item.abstract.toString()
  abstract = abstract.split(' ').slice(0, 15).join(' ')
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isBigDevice = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const theme = useTheme()

  return (
    <News>
      <Grid container style={{justifyContent:isSmallDevice ? 'center' :'left'}}>
      <Grid item xs={12} md={0}
        style={{textAlign:'center',
        height:isSmallDevice ? 'auto' : '0px',
      }}
        >
        <Title style={{visibility: isSmallDevice ? 'visible' : 'hidden',}}>ESTIA today</Title>
        </Grid>
        <Grid item xs={0} md={1.5}>
          {' '}
        </Grid>
        
        <ImgGrid
          item
          xs={12}
          md={2}
          lg={1.8}
          style={{
            justifyContent:isSmallDevice?'center' : 'right',
            


          }}
        >
          <Image
            style={{
              // visibility: isSmallDevice ? 'hidden' : 'visible',
              height: isSmallDevice ? '135px' : isBigDevice ? '135px' : '150px',
              width: isBigDevice ? '135px' : '150px',
              backgroundImage:'url(' + item.image + ')',
            backgroundSize: '300px',
            backgroundRepeat: "no-repeat",
            }}
          ></Image>
        </ImgGrid>
        <Grid item xs={12} md={0.2}></Grid>
        <Grid item xs={12} md={6.5}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                textAlign: isSmallDevice ? 'center' : 'left',
                visibility: isSmallDevice ? 'hidden' : 'visible',
                height: isSmallDevice ? '0px':'auto'
              }}
            >
              <h1>
                ESTIA today
              </h1>
            </Grid>
            <CenterGrid item xs={12} md={6}>
              <SubButton
                component={Link}
                onClick={() => navigate(makePath('myEstia.news'))}
                fullWidth
                variant="contained"
                style={{
                  textDecorationColor: "#fff0",
                  visibility: isSmallDevice ? 'hidden' : 'visible',
                  height: isSmallDevice ? '0px' : '48px',
                }}
              >
                {t('news:ui.ShowNews')}
              </SubButton>
            </CenterGrid>

            <Grid item xs={0} md={2}></Grid>
          </Grid>
          <p
            className="date-title"
            style={{
              marginTop: isSmallDevice ? '0px' : '5px',
              textAlign: isSmallDevice ? 'center' : 'left',
            }}
          >
            {dayjs(item.date).format('DD/MM/YYYY')} -

            <Link className="bold-text"
              onClick={() => onClick(item)}
              style={{
                textDecoration: 'none',
                color: `${theme.palette.blackWhite.main}`
              }}
            >{' ' + item.title}</Link>
          </p>

          <p
            style={{
              textAlign: isSmallDevice ? 'center' : 'left',
              marginRight: isSmallDevice ? '10px' : '0px',
              marginLeft: isSmallDevice ? '10px' : '0px',
              marginTop: isSmallDevice ? '3px' : '5px',

            }}
          >
            {abstract}
          </p>
        </Grid>
      </Grid>
    </News>
  )
}

Showcase.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
}

Item.propTypes = {
  item: PropTypes.any,
  onClick: PropTypes.func,
}
export default Showcase
