import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { setActiveBranch } from '@Branches/Redux'
import PrivateRoute from '@Common/Components/PrivateRoute'
import AdminLayout from '@Common/Layouts/AdminLayout'
import OperationalLayout from '@Common/Layouts/OperationalLayout'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { isBranchEmployee } from '@Common/Utils/Permissions'
import config from '@Config'

import AnagraphicView from '../Views/AnagraphicView'
import CustomerCareView from '../Views/CustomerCareView'

const CustomerCareRoutes = () => (
  <Routes>
    <Route
      path={config.urls.customerCare.main}
      element={
        <PrivateRoute>
          <CustomerCareView />
        </PrivateRoute>
      }
    />
    <Route
      path={config.urls.customerCare.anagraphic}
      element={
        <PrivateRoute>
          <AnagraphicView />
        </PrivateRoute>
      }
    />
  </Routes>
)

export const CustomerCareRouter = () => {
  const dispatch = useDispatch()
  const user = useCurrentUser()
  if (isBranchEmployee(user)) {
    useEffect(() => {
      dispatch(setActiveBranch(null))
    }, [])
    return (
      <OperationalLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
        <CustomerCareRoutes />
      </OperationalLayout>
    )
  }
  return (
    <AdminLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
      <CustomerCareRoutes />
    </AdminLayout>
  )
}

export default CustomerCareRouter
