import { FormControl, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import CategorySelect from '@Tms/Forms/Selects/CategorySelect'

import { ReportType } from '../Models/ReportType'

export const ReportTypeForm = ({ onClose, onSave, reportType }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fields, setField, errors, setErrors } = useForm(
    {
      name: R.defaultTo('', reportType?.name),
      code: R.defaultTo('', reportType?.code),
      taskCategory: R.defaultTo('', reportType?.taskCategory),
    },
    true,
  )

  const submit = async () => {
    logger.debug('Report Type form submission, fields:', fields)
    setIsSubmitting(true)
    const { error, isSuccessful } = await onSave(fields)
    if (!isSuccessful && error?.status === 400) {
      setIsSubmitting(false)
      setErrors(convertServerErrors(error))
    } else {
      onClose()
    }
  }

  const title = reportType?.id ? t('tickets:ui.ModifyReportType') : t('tickets:ui.AddReportType')

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              required
              label={t('tickets:fields.code')}
              value={fields.code}
              onChange={setStateFromEvent(setField('code'))}
              error={!!errors.code}
              helperText={errors.code}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              multiline
              required
              label={t('tickets:fields.name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <CategorySelect
              value={fields.taskCategory}
              label={t('tms:ui.TaskCategory')}
              onChange={setStateFromEvent(setField('taskCategory'))}
              error={!!errors.taskCategory}
              helperText={errors.taskCategory}
              notRHF
            />
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  )
}

ReportTypeForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  reportType: ReportType,
}

export default ReportTypeForm
