import React from 'react'

import { AddButton } from '@Common/Components/Buttons/AddButton'
import { DataTableV3 } from '@Common/Components/DataTableV3'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { SearchField } from '@Common/Components/FormsV2/SearchField'
import { CertificationDocumentTypeActions } from '@Supplier/Components/CertificationDocumentTypeActions'
import { CertificationDocumentType } from '@Supplier/Models/CertificationDocumentType'

export const CertificationDocumentTypeListView = () => {
  const renderFilters = (
    <>
      <SearchField xs={3} />
      <InputFieldV2 name="status" xs={2} required={false} />
    </>
  )

  return (
    <>
      <DataTableV3
        url={CertificationDocumentType.url}
        model={CertificationDocumentType}
        renderFilters={renderFilters}
        renderActions={<CertificationDocumentTypeActions />}
        hidableColumns={['expiration']}
      />
      <AddButton model={CertificationDocumentType} />
    </>
  )
}
