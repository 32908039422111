import styled from 'styled-components'
import { Box } from '@Common/Components/Styles'

export const Nav = styled(Box)`
  padding: ${({padding}) => padding || '1rem 1rem'};
  width: ${({ width, $fill }) => $fill ? '100%' : width};
  min-width: ${({ width, $fill }) => $fill ? '100%' : width};
  height: ${({ $fill }) => $fill ? '100%' : 'auto'};
  background-color: ${({ theme, background }) => theme.palette[background].main};
  box-sizing: border-box;
`

export const Item = styled(Box)`
  box-sizing: border-box;
  cursor: pointer;
  justify-content: ${props => props.justify};
  padding: .6rem 1rem;
  position: relative;
  width: 100%;

  ${({active, theme}) => active && `
    background: ${theme.palette.sidebarActive.main} !important;

    &::before {
      content: '';
      position: absolute;
      background: ${theme.palette.primary.main} !important;
      opacity: 1 !important;
      left: 0;
      height: 100%;
      width: 2px;
    }
  `}

  ${({hasActive, theme}) => hasActive && `
    background: ${theme.palette.sidebarHasActive.main};

    &::before {
      content: '';
      position: absolute;
      background: ${theme.palette.primary.main};
      opacity: .3;
      left: 0;
      height: 100%;
      width: 2px;
    }
  `}

  ${({sub}) => sub && `

    svg {
      margin: 0 0 0 5px;
      min-width: 20px !important;
      width: 20px !important;
    }
  `}
`

export const Separator = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.sidebar.contrastText};
  margin: .5rem 1rem;
`

export const Link = styled.a`
  font-size: ${({ sub }) => sub ? '1rem' : '1.1rem'};
  margin: ${props => props.margin};
  white-space: nowrap;
`
