/* eslint-disable react/prop-types */

/**
 * This component display create button.
 * When user click over button
 * dialog is show with item form inside
 */
import { Add } from '@mui/icons-material'
import { createElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonDialog } from '../ButtonDialog'

export const AddButton = (props) => {
  const { model, form, defaultValues, title, dialogTitle = null, type = 'fab', variant = 'contained' } = props
  const { t } = useTranslation()

  return (
    <ButtonDialog
      title={title || `${t('common:actions.Add')} ${t(`${model.name.toLowerCase()}:model.verbose_name`)}`}
      type={type}
      icon={<Add />}
      variant={variant}
      color={'primary'}
      sx={type === 'fab' ? { position: 'fixed', right: 32, bottom: 32 } : null}
      dialogTitle={dialogTitle}
    >
      {createElement(form || model.form, { defaultValues })}
    </ButtonDialog>
  )
}
