/* eslint-disable react/prop-types */

/**
 * Sometimes more, sometimes less, we build the same table.
 * The global features is sortable, searchable, etc etc.
 * We want to add this feature by defautl creating this wrapper for datatable.
 */
import { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import DataTable from '@Common/Components/DataTable/Server'
import { getItemField, getItemFieldSchema } from '@Common/Utils/Hooks'

import ActionsFiltersForm from './ActionsFiltersForm'
import { FiltersForm } from './FiltersForm'
import { ItemContainer } from './ItemContainer'
import { Field } from './ItemField'
import { ItemsLoader, ItemsLoaderContext } from './ItemsLoader'

export const DataTableV3 = (props) => {
  const { url, defaultOrdering, ...other } = props

  return (
    <ItemsLoader url={url} model={props.model} ordering={defaultOrdering}>
      <DataTableV3Content {...other} />
    </ItemsLoader>
  )
}

const DataTableV3Content = (props) => {
  const { pathname } = useLocation()
  const { model, fieldsMapping, renderFilters, renderActions, name, hidableColumns, ...other } = props

  // if columns is empty show all model columns
  let columns = props.columns || Object.keys(model.schema)

  if (!props?.columns && hidableColumns && Array.isArray(hidableColumns)) {
    hidableColumns.forEach((element) => {
      columns = columns.filter((e) => e !== element)
    })
  }

  const itemsLoader = useContext(ItemsLoaderContext)

  const [selected, setSelected] = useState([])

  /**
   * Load data directly inside the table
   * This allow us to avoid write the same code more times
   */
  const modelForV1 = { columns: columns.map((key) => getItemFieldSchema(key, model)) }

  const getFieldsMapping = () => {
    let mapping = {}
    modelForV1.columns.map((column) => {
      const renderFunction = (data) => {
        // pick value visitng obj with dotted notation
        const { value, formattedValue, schema } = getItemField(column.path, data, model)
        return (
          <ItemContainer model={model} item={data}>
            <Field value={value} type={schema.type} formattedValue={formattedValue} />
          </ItemContainer>
        )
      }

      mapping[column.id] = fieldsMapping?.[column.id] || renderFunction
    })
    return mapping
  }

  const qsAdditionFilters = itemsLoader.filters
  const { limit, offset, ordering } = itemsLoader.filtersBase

  const qs = {
    base: { limit: limit || 10, offset: offset || 0, ordering: ordering || '' },
    qsAdditions: qsAdditionFilters,
  }

  const renderActionsV2 = (
    <ItemContainer model={model} item={selected[0]}>
      {selected.length === 1 && renderActions}
    </ItemContainer>
  )

  const tableName = [pathname, model.name, name].join('-')

  return (
    <DataTable
      name={tableName}
      data={itemsLoader.items}
      dataCount={itemsLoader.count}
      loading={itemsLoader.isFetching}
      useDjangoOrdering
      noExport
      qs={qs}
      refreshData={(data) => {
        itemsLoader.onFilterBaseChange({
          ...data.base,
        })
      }}
      listDisplay={columns}
      fieldsMapping={getFieldsMapping()}
      selected={selected}
      onSelect={setSelected}
      selectable
      actionsV2={renderActionsV2}
      {...other}
      model={modelForV1}
    >
      <FiltersForm>
        {renderFilters}
        <ActionsFiltersForm />
      </FiltersForm>
    </DataTable>
  )
}
