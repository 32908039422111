import LocationCityIcon from '@mui/icons-material/LocationCity'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import styled from 'styled-components'

import Alert from '@Common/Components/Alert'
import { Box } from '@Common/Components/Styles'

const Container = styled.div`
  margin-bottom: 2rem;
`

const BuildingMap = ({ building, height }) => {
  const { t } = useTranslation()

  const mapAlert = () => <Alert level="warning">{t('buildings:ui.CannotFetchMapCoordinates')}</Alert>
  const mapComponent = (latLng) => (
    <>
      <MapContainer center={latLng} zoom={13} scrollWheelZoom={false} style={{ height }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={latLng}>
          <Popup>{building.popupDenominazione}</Popup>
        </Marker>
      </MapContainer>
      <Box direction="row" align="center" pad="1rem" background="lightTwo">
        <LocationCityIcon style={{ marginRight: '.5rem' }} /> {building.address}
      </Box>
    </>
  )

  const map = R.ifElse(R.compose(R.not, R.isNil, R.head), mapComponent, mapAlert)

  return <Container>{map([building.lat, building.lng])}</Container>
}

BuildingMap.defaultProps = {
  height: '250px',
}

BuildingMap.propTypes = {
  building: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    popupDenominazione: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }),
  height: PropTypes.string,
}

export default BuildingMap
