import dayjs from 'dayjs'
import i18next from 'i18next'
import * as R from 'ramda'
import React from 'react'

import { FalseIcon, TrueIcon } from './Styled'

export const createColumnsProps = (columns, listDisplay) =>
  columns
    .map((column) => ({
      ...column,
      visible: listDisplay.indexOf(column.id) !== -1,
    }))
    .sort((a, b) => {
      if (listDisplay.indexOf(a.id) !== -1 && listDisplay.indexOf(b.id) !== -1) {
        return listDisplay.indexOf(a.id) - listDisplay.indexOf(b.id)
      } else if (listDisplay.indexOf(a.id) !== -1) {
        return -1
      } else {
        return 1
      }
    })

export const getDisplayValue = (fieldsMapping, record, field, column, index) => {
  return fieldsMapping[field] ? fieldsMapping[field](record, 'jsx', index) : getValue(record, field, column)
}

export const getCsvValue = (fieldsMapping, record, field, column) => {
  return fieldsMapping[field] ? fieldsMapping[field](record, 'csv') : getValue(record, field, column, 'csv')
}

export const getValue = (record, field, column = null, format = 'jsx') => {
  const raw = R.defaultTo('', field)
    .split('.')
    .reduce((acc, curr) => (acc ? acc[curr] : null), record)
  if (column && column.datetime) {
    return raw ? dayjs(raw).format('DD/MM/YYYY HH:mm') : null
  } else if (column && column.date) {
    return raw ? dayjs(raw).format('DD/MM/YYYY') : null
  } else if (column && column.currency) {
    if (!raw) return null
    return `${Number(raw).toLocaleString(i18next.language, { minimumFractionDigits: 2, currency: 'EUR' })} €`
  } else if (column && column.array) {
    return raw ? getArrayValue(raw, column) : null
  } else if (column && column.boolean) {
    if (format === 'jsx') {
      return raw ? <TrueIcon /> : <FalseIcon />
    } else {
      return raw ? 'true' : 'false'
    }
  } else if (column && column.longtext) {
    const text = raw?.replace(/[\n\r]+/g, ' ')
    const maxChar = 160

    if (text && text.length > maxChar) {
      return `${text.slice(0, maxChar)}...`
    }
    return text
  }
  if (column && column.emptyValue) {
    if (!raw) return column.emptyValue
  }
  return raw
}

const getArrayValue = (raw, column) => {
  return column.callback ? column.callback(raw) : raw.map((i) => i[column.fieldName]).join(', ')
}
