import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReportTypesPermissions } from '../Permissions'
import { useReportTypesQuery } from '../Services/Api'
import { useConfirm } from '@Common/Utils/Hooks'
import DataTable from '@Common/Components/DataTable'
import ReportType from '../Models/ReportType'

const DISPLAY_COLUMNS = ['id', 'code', 'name']

const SEARCH_FIELDS = ['name']

const ReportTypesList = ({ onEdit, onDelete }) => {
  const handleDelete = async () => onDelete(selected)
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDelete)

  // new qs triggers refetch
  const { data, isFetching } = useReportTypesQuery()

  const bulkActions = React.useMemo(() => [{ id: 'DELETE', label: t('tickets:ui.Delete') }], [])
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: ReportTypesPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: ReportTypesPermissions.delete,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, labels) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('tickets:ui.DeleteTypeConfirmationTitle', { count: selected.length }),
          t('tickets:ui.DeleteTypeConfirmationText', { count: selected.length }),
          { selected: selected, field: 'name' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(labels)
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      <DataTable
        name="admin-providers"
        selectable
        data={data?.results || []}
        model={ReportType}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        bulkActions={bulkActions}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      />
      {DeleteConfirm}
    </div>
  )
}

ReportTypesList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default ReportTypesList
