import i18next from 'i18next'
import * as R from 'ramda'

export const wrap =
  (fn, ...args) =>
  () =>
    fn(...args)

export const nothing = () => {}
export const checkRequired = (err, required, fields) =>
  required.forEach(
    R.ifElse(R.compose(R.not, R.either(R.isEmpty, R.isNil), R.flip(R.prop)(fields)), nothing, setRequired(err)),
  )
export const setRequired = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.RequiredField')
})

export const setLengthError = R.curry((err, length, field) => {
  err[field] = i18next.t('common:validation.InvalidLength', { length })
})
export const checkLength = (err, field, length, fields) =>
  R.ifElse(
    R.both(
      R.compose(R.not, R.isEmpty, R.prop(field)),
      R.compose(R.not, R.equals(length), R.prop('length'), R.prop(field)),
    ),
    wrap(setLengthError, err, length, field),
    nothing,
  )(fields)

export const setIntGteError = R.curry((err, threshold, field) => {
  err[field] = i18next.t('common:validation.LowIntError', { threshold })
})
export const checkIntGte = (err, field, threshold, fields) =>
  R.ifElse(
    R.both(R.compose(R.not, R.isEmpty, R.prop(field)), R.compose(R.not, R.flip(R.gte)(threshold), R.prop(field))),
    wrap(setIntGteError, err, threshold, field),
    nothing,
  )(fields)

export const setEmailError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.EmailFormatError')
})

export const setFiscalCodeError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.FiscalCodeFormatError')
})

export const setPhoneError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.PhoneFormatError')
})

export const setNumberError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.GeneralFormatError')
})

export const setCurrencyError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.CurrencyFormatError')
})

export const setDateError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.DateFormatError')
})

export const checkEmailAddress = (err, fields, fieldName) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  return R.ifElse(
    R.compose(R.not, R.test(re), R.toLower, R.prop(fieldName)),
    wrap(setEmailError, err, fieldName),
    nothing,
  )(fields)
}

export const checkFiscalCode = (err, fields, fieldName) => {
  const re =
    /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/ // eslint-disable-line
  return R.ifElse(
    R.compose(R.not, R.test(re), R.prop(fieldName)),
    wrap(setFiscalCodeError, err, fieldName),
    nothing,
  )(fields)
}

export const checkZipCode = (err, fields, fieldName) => {
  const re = /^[0-9]{5}$/
  return R.ifElse(
    R.compose(R.not, R.test(re), R.toLower, R.prop(fieldName)),
    wrap(setZipCodeError, err, fieldName),
    nothing,
  )(fields)
}

export const setZipCodeError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.ZipCodeError')
})

export const checkColor = (err, fields, fieldName) => {
  const re = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return R.ifElse(
    R.compose(R.not, R.test(re), R.toLower, R.prop(fieldName)),
    wrap(setColorError, err, fieldName),
    nothing,
  )(fields)
}

export const setColorError = R.curry((err, field) => {
  err[field] = i18next.t('services:validation.ColorFormatError')
})

export const checkPhoneNumber = (err, fields, fieldName) => {
  const re = /^(\+?)[0-9]+$/
  return R.ifElse(R.compose(R.not, R.test(re), R.prop(fieldName)), wrap(setPhoneError, err, fieldName), nothing)(fields)
}

export const checkNumber = (err, fields, fieldName) => {
  const re = /^\d+$/
  return R.ifElse(
    R.compose(R.not, R.test(re), R.prop(fieldName)),
    wrap(setNumberError, err, fieldName),
    nothing,
  )(fields)
}

export const checkCurrency = (err, fields, fieldName) => {
  const re = /^(\+?)[0-9]+$/
  return R.ifElse(
    R.compose(R.not, R.test(re), R.prop(fieldName)),
    wrap(setCurrencyError, err, fieldName),
    nothing,
  )(fields)
}

export const checkDate = (err, fields, fieldName) => {
  const re =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  return R.ifElse(R.compose(R.not, R.test(re), R.prop(fieldName)), wrap(setDateError, err, fieldName), nothing)(fields)
}
// isValidNumber :: Any -> Boolean
export const isValidNumber = R.both(R.is(Number), R.complement(R.equals(NaN)));
