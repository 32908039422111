import Logger from '@Common/Utils/Logger'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Alert from '@Common/Components/Alert'
import { Box } from '@Common/Components/Styles'
import { MapContainer, Popup, TileLayer } from 'react-leaflet'
import DraggableMarker from '../../../Common/Components/DraggableMarker'
import { withLoader } from '@Common/Utils/HOC'
import { getBuildingAddress, getBuildingAddressWithoutNumber, getBuildingShortAddress } from '../../Utils'
import styled from 'styled-components'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import * as R from 'ramda'
import { BuildingType } from '@Buildings/Models/Building'
import PropTypes from 'prop-types'
import { fetchGeoLocation } from '@Common/Utils/Geolocation'

const Container = styled.div`
  margin-bottom: 2rem;
`

const getAddressByAttempt = (building, attemptCount) => {
  switch (attemptCount) {
    case 1:
      return getBuildingAddress(building)
    case 2:
      return getBuildingAddressWithoutNumber(building)
    case 3:
      return getBuildingShortAddress(building)
    default:
      return ''
  }
}

const BuildingMapFormField = ({ building, onChangePosition }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState(true)
  const [latLng, setLatLng] = React.useState(null)

  const initMap = (b) => {
    setLatLng([parseFloat(b.lat), parseFloat(b.lng)])
    setIsLoading(false)
  }

  const mapAlert = () => <Alert level="warning">{t('buildings:ui.CannotFetchMapCoordinates')}</Alert>
  const mapComponent = (latLng) => (
    <>
      <MapContainer center={latLng} zoom={15} scrollWheelZoom={false} style={{ height: '250px' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker initialPosition={latLng} onChangePosition={onChangePosition}>
          <Popup>{building.denominazione}</Popup>
        </DraggableMarker>
      </MapContainer>
      <Box direction="row" align="center" pad="1rem" background="lightTwo">
        <LocationCityIcon style={{ marginRight: '.5rem' }} /> {getBuildingAddress(building)}
      </Box>
    </>
  )

  React.useEffect(() => {
    // geocoder rulez
    const guessBuildingAddress = async () => {
      setIsLoading(true)
      let data = []
      const maxAttempts = 3
      let attemptCount = 0
      try {
        while (!data.length && attemptCount < maxAttempts) {
          const address = getAddressByAttempt(building, ++attemptCount)
          data = await fetchGeoLocation(address)
        }
      } catch (e) {
        Logger.error('Fetch geolocation error', e)
      }

      if (data.length) {
        setLatLng([parseFloat(data[0].lat), parseFloat(data[0].lon)])
      } else {
        Logger.warning('Cannot fetch geolocation coordinates')
      }
      setIsLoading(false)
    }

    R.ifElse(
      R.either(R.pipe(R.prop('lat'), R.isNil), R.pipe(R.prop('lng'), R.isNil)),
      guessBuildingAddress,
      initMap,
    )(building?.extra)
  }, [building])

  const map = R.ifElse(R.compose(R.not, R.isNil), mapComponent, mapAlert)

  return <Container>{withLoader(map(latLng), isLoading)}</Container>
}

DraggableMarker.propTypes = {
  initialPosition: PropTypes.arrayOf(PropTypes.number),
  onChangePosition: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

BuildingMapFormField.propTypes = {
  building: BuildingType,
  onChangePosition: PropTypes.func,
}

export default BuildingMapFormField
