import * as React from 'react'

const InvoicesIcon = (props) => (
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="-40 -40 230 280"
    style={{
      enableBackground: 'new 0 0 153 200',
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{'.st0{fill:#ffca0c}'}</style>
    <path
      className="st0"
      d="M201.5 232.5H76l3.8 4.2 3.3 3.5h126.2V54.5l-7.8-7.3v185.3z"
      transform="matrix(.94563 0 0 .94562 -56.268 -39.908)"
    />
    <path
      className="st0"
      d="M213.5 58.2v186.3H86.3l3 3.5 3.8 4.2h128.2V65.5l-7.8-7.3z"
      transform="matrix(.94563 0 0 .94562 -56.268 -39.908)"
    />
    <path
      className="st0"
      d="M73.3 229h124.5V42.2h-128s-8.8 4-10 9c0 .5-.3.8-.3 1.3v166.8c0 5.5 4.5 10 10 10h3.8v-.3zm4-127.5c0-8.5 7-15.5 15.5-15.5H170c8.5 0 15.5 7 15.5 15.5v17.3c0 8.5-7 15.5-15.5 15.5H93.1c-8.5 0-15.5-7-15.5-15.5v-17.3h-.3zM83.5 248l-7-7.8-3.3-3.5-3-3.5h-7l6 7 6.5 7.8 1 1c2 1.5 3.8 2.5 5.5 3.2 3.5 1.5 6.3 1.5 6.3 1.5l-1.3-1.5-3.7-4.2z"
      transform="matrix(.94563 0 0 .94562 -56.268 -39.908)"
    />
  </svg>
)

export default InvoicesIcon
