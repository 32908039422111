import { useBuildingQuery } from '@Buildings/Services/Api'
import { Cursor, Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { withLoader } from '@Common/Utils/HOC'
import { selectThemeMode } from '@Core/Redux/Ui'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { FormHelperText, Input, List, ListItem, ListItemText, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Dropdown from '@Common/Components/Dropdown'
import SuccessModal from '@MyEstia/Components/SuccessModal'
import { selectActiveBuildingId, selectActiveHousingUnitId } from '@MyEstia/Redux'
import { useCreateReportMutation, useReportTypesQuery } from '../../Tickets/Services/Api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled, { css } from 'styled-components'
import { responseStringError } from '@Common/Utils/Api'
import { useForm } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import * as R from 'ramda'
import { apiList } from '@Core/Services/Api'
import logger from '@Common/Utils/Logger'
import { validate, validateAttachments } from '../Forms/NewTicketForm/Validation'
import { convertServerErrors } from '@Common/Utils/Form'
import { humanizeSize } from '@Common/Utils/Files'

const Main = styled.div`
  margin: auto;
  padding: 2rem 1.25rem;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
         max-width: 600px;
         width: 100%;
    }
    
    ${theme.breakpoints.up('lg')} {
         max-width: 800px;
    }
  `}
`

const FileUpload = styled.div`
  ${({ theme }) => css`
    border: 2px dashed ${theme.palette.neutralTwo.main};
    padding: 10px;
    border-radius: 8px;
    background-color: ${theme.palette.lightThree.light};
  `}
`

const Footer = styled.div`
  ${({ theme, ...props }) => css`
    width: 100vw;
    padding: 25px;
    height: 110px;
    align-items: flex-end;
    background-color: ${props.themeMode === 'light'
      ? `${theme.palette.lightTwo.light}`
      : `${theme.palette.lightTwo.dark}`};
  `}
`

const Wrapper = styled.div`
  padding-top: 1rem;
  padding-left: 30%;
  padding-right: 30%;
`

const WarningText = styled.div`
  text-align: center;
  margin: auto;
  font-size: 0.8rem;
  ${({ theme }) =>
    css`
      color: ${theme.palette.darkThree.main};
    `}
`

const MyHomeTicketView = () => {
  const { t } = useTranslation()
  const themeMode = useSelector(selectThemeMode)
  const buildingId = useSelector(selectActiveBuildingId)
  const housingUnitId = useSelector(selectActiveHousingUnitId)
  const { data: building, isFetching } = useBuildingQuery(buildingId)

  const { fields, setErrors, errors, setField } = useForm(
    {
      reportType: null,
      title: '',
      description: '',
    },
    true,
  )

  const [ticketFiles, setTicketFiles] = useState([])
  const [successModal, setSuccessModal] = useState({ isOpen: false, report: null })
  const { data: reportTypes } = apiList(useReportTypesQuery())
  const [createReportMutation, { isLoading }] = useCreateReportMutation()

  const dropHandler = function (files, e) {
    e && e.preventDefault()
    const filesTmp = [...ticketFiles]
    Object.keys(files).map((index) => {
      filesTmp.push(files[index])
    })
    if (validateAttachments(filesTmp, errors, setErrors)) {
      setTicketFiles(filesTmp)
    }
    return false
  }

  const addReport = async () => {
    logger.debug('Ticket form submission, fields:', { ...fields, buildingId, housingUnitId })

    if (validate(fields, setErrors)) {
      const body = new FormData()
      body.append('buildingArcadiaId', buildingId)
      body.append('housingUnitArcadiaId', housingUnitId)
      body.append('reportType', fields.reportType)
      body.append('title', fields.title)
      body.append('description', fields.description)
      ticketFiles?.map((file) => body.append('attachments', file))

      try {
        const { data, error } = await createReportMutation(body)
        if (error && error?.originalStatus >= 400) {
          setErrors(convertServerErrors(error))
          toast.error(t('myHome:errors.reportCreationError', { error: responseStringError(error) }))
        } else {
          setSuccessModal({ isOpen: true, report: data })
        }
      } catch (error) {
        setErrors(convertServerErrors(error))
        toast.error(t('common:errors:SubmitError'), { error: responseStringError(error) })
      }
    } else {
      logger.debug('Ticket form submission, client validation failed')
    }
  }

  return (
    <>
      <Main>
        {withLoader(
          <Margin bottom="2rem">
            <Typography
              variant="h1"
              fontSize={14}
              margin="1rem"
              marginBottom="0.5rem"
              textAlign="center"
              color="blackWhite"
            >
              {t('tickets:newTicket.title')}
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={20}
              margin="1rem"
              marginBottom="0.5rem"
              textAlign="center"
              color="accentOne"
            >
              {building?.denominazione}
            </Typography>
            <Typography
              variant="h1"
              fontSize={16}
              margin="2rem"
              marginBottom="0.5rem"
              textAlign="center"
              color="blackWhite"
            >
              {t('tickets:newTicket.titleTicket')}
            </Typography>

            <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0 }}>
              <Dropdown
                required
                value={fields.reportType}
                onChange={setStateFromEvent(setField('reportType'))}
                label={t('tickets:newTicket.labelTicket')}
                error={!!errors.reportType}
                helperText={errors.reportType}
                options={R.defaultTo([], reportTypes).map((r) => ({ value: r.id, label: r.name }))}
              />
            </FormControl>

            <Typography
              variant="h1"
              fontSize={16}
              marginTop="2rem"
              marginBottom="0.5rem"
              textAlign="center"
              color="blackWhite"
            >
              {t('tickets:newTicket.titleInputField')}
            </Typography>
            <TextField
              required
              label={t('tickets:newTicket.labelInputField')}
              variant="standard"
              fullWidth
              value={fields.title}
              onChange={setStateFromEvent(setField('title'))}
              error={!!errors.title}
              helperText={errors.title}
            />

            <Typography
              variant="h1"
              fontSize={16}
              marginTop="2rem"
              marginBottom="0.5rem"
              textAlign="center"
              color="blackWhite"
            >
              {t('tickets:newTicket.titleTextField')}
            </Typography>
            <TextField
              required
              label={t('tickets:newTicket.labelTextField')}
              variant="standard"
              multiline
              fullWidth
              value={fields.description}
              onChange={setStateFromEvent(setField('description'))}
              error={!!errors.description}
              helperText={errors.description}
            />

            <Typography
              variant="h1"
              fontSize={16}
              marginTop="2rem"
              marginBottom="0.5rem"
              textAlign="center"
              color="blackWhite"
            >
              {t('tickets:newTicket.titleUploadField')}
            </Typography>

            <label
              htmlFor="upload-photo"
              onDrop={(event) => dropHandler(event.dataTransfer.files, event)}
              onDragOver={(e) => e.preventDefault()}
            >
              <FileUpload themeMode={themeMode}>
                <Input
                  type="file"
                  id="upload-photo"
                  style={{ display: 'none' }}
                  onChange={(event) => dropHandler(event.target.files)}
                  inputProps={{ multiple: true }}
                />
                <Typography
                  variant="h1"
                  fontSize={14}
                  fontWeight={'400'}
                  marginTop="2rem"
                  marginBottom="1rem"
                  textAlign="center"
                  color={'blackWhite.light'}
                >
                  {t('tickets:newTicket.labelUploadField')}
                </Typography>
                <WarningText>{t('tickets:newTicket.AttachmentsLimitText')}</WarningText>
              </FileUpload>
            </label>

            {errors.attachments && <FormHelperText error>{errors.attachments}</FormHelperText>}

            {ticketFiles.length > 0 && (
              <Typography
                variant="h1"
                fontSize={14}
                fontWeight={'400'}
                marginTop="2rem"
                marginBottom="0.5rem"
                textAlign={'left'}
                color="blackWhite"
              >
                {t('tickets:newTicket.uploadedFiles')}
              </Typography>
            )}
            <List dense sx={{ listStyleType: 'disc', paddingLeft: '30px', paddingTop: 0 }}>
              {ticketFiles.map((file, index) => (
                <ListItem key={index} sx={{ display: 'list-item', padding: 0, flexDirection: 'row' }}>
                  <ListItemText>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography
                        variant="h1"
                        marginBottom={'0.3rem'}
                        fontSize={12}
                        textAlign={'left'}
                        color="blackWhite"
                      >
                        {`${file.name} (${humanizeSize(file.size)})`}
                      </Typography>
                      <div
                        onClick={() => {
                          const filesTmp = [...ticketFiles]
                          const filteredFiles = filesTmp.filter((f) => f.name !== file.name)
                          setTicketFiles(filteredFiles)
                        }}
                      >
                        <Margin left="0.25rem">
                          <Cursor type="pointer">
                            <CloseIcon color="error" style={{ width: '15px', height: '15px' }} />
                          </Cursor>
                        </Margin>
                      </div>
                    </div>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Margin>,
          isFetching,
        )}
      </Main>
      <Footer themeMode={themeMode}>
        <Wrapper>
          <LoadingButton
            loading={isLoading}
            loadingPosition="center"
            variant="contained"
            color="secondary"
            sx={{ float: 'right', borderRadius: '20px', padding: '3px 12px', textTransform: 'none' }}
            onClick={addReport}
          >
            {t('tickets:newTicket.confirm')}
          </LoadingButton>
        </Wrapper>
      </Footer>

      {successModal.isOpen && (
        <SuccessModal open housingUnitId={housingUnitId} hemeMode={themeMode} report={successModal.report} />
      )}
    </>
  )
}

export default MyHomeTicketView
