import PropTypes from 'prop-types'

import { RoleType } from '@Auth/Models/User'
import { TaskCloseConditionType } from '@Tms/Models/TaskCloseCondition'
import { TaskFieldType } from '@Tms/Models/TaskField'
import { TaskOpenConditionType } from '@Tms/Models/TaskOpenCondition'

const task = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  description: PropTypes.string,
  defaultPriority: PropTypes.oneOf([0, 1, 2]),
  role: PropTypes.number,
  roleObj: RoleType,
  roleData: PropTypes.string,
  assignTo: PropTypes.oneOf([
    'admin',
    'role',
    'branch_administrative',
    'who_opens_it',
    'administration_owner',
    'accidents_owner',
    'accounting_owner',
  ]),
  showInInfographic: PropTypes.bool,
  minutesToDeadline: PropTypes.number,
  deadlineUnit: PropTypes.oneOf(['m', 'h', 'd', 'w']),
  deadlineCondition: PropTypes.oneOf([0, 1, 2, 5]),
  deadlineTask: PropTypes.number,
  deadlineField: PropTypes.number,
  fields: PropTypes.arrayOf(TaskFieldType),
  openConditions: PropTypes.arrayOf(TaskOpenConditionType),
  closeConditions: PropTypes.arrayOf(TaskCloseConditionType),
}

task.children = PropTypes.arrayOf(PropTypes.shape(task))

export const TaskType = PropTypes.shape(task)
