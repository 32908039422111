import { Box, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

import BuildingAside, { isArcadiaBuilding } from '@Buildings/Components/BuildingAside'
import BuildingBottombar from '@Buildings/Components/BuildingBottombar'
import { resetFilters, setFilters } from '@Tms/Redux'

export const BuildingLayout = () => {
  const dispatch = useDispatch()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const handleTmsFilters = (path, building) => {
    if (path === '/tms/tasks') {
      dispatch(resetFilters())
      const buildingType = isArcadiaBuilding(building) ? 'branchbuilding' : 'building'
      const buildingId = isArcadiaBuilding(building) ? building.branchbuildingId : building.id
      dispatch(setFilters({ building: `${buildingId}_${buildingType}` }))
    }
  }

  const aside = !isSmallDevice ? <BuildingAside onItemClick={handleTmsFilters} /> : undefined

  return (
    <Box height={1} display="flex" flexDirection="row" w={1}>
      <Box pr={4} flexGrow={1} pb={isSmallDevice ? '60px' : 0} sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Outlet />
      </Box>
      {/** used this ugly solution for compensate container margin  */}
      <Box background="buildingNav" mt={-10.5} mr={-4} mb={-4}>
        {aside}
      </Box>
      {isSmallDevice && <BuildingBottombar />}
    </Box>
  )
}
