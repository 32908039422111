import { Button, Menu, MenuItem, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '@Common/Components/Styles'

const CustomButton = styled(Button)`
  ${({ theme, ...props }) => css`
    color: ${theme.palette.primary.main};
    text-transform: none;
    font-weight: bold;
    justify-content: flex-start;
    border-radius: 5rem;
    border-width: 2px;
    padding: 0;
    background-color: ${theme.palette.sidebarButton.main};
    border-color: ${props.selected ? theme.palette.secondary.main : 'transparent'};

    :hover {
      border-color: ${theme.palette.secondary.main};
      border-width: 2px;
      background-color: ${theme.palette.onHoverButton.main};
    }
  `}
`

const ArrowBox = styled(Box)`
  ${({ theme, ...props }) => css`
    border-style: none none solid none;
    border-bottom-color: ${theme.palette.secondary.main};
    border-bottom-width: ${props.hasSubmenu ? '3px' : '0'};
  }
  `}
`

const ArrowDown = styled.div`
  ${({ theme }) => `border-top: 10px solid ${theme.palette.text.main};`}
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin: 5px auto;
  cursor: pointer;
`

const MyHomeRoundButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ArrowBox align="center" hasSubmenu={props.hasSubmenu}>
        <CustomButton
          variant="outlined"
          startIcon={props.icon}
          sx={{
            [`& .MuiButton-startIcon`]: { margin: 0 },
          }}
          onClick={props.hasSubmenu ? handleClick : props.onSelect}
          selected={props.selected}
        />
        <Typography
          variant="subtitle2"
          color={props.labelColor ? props.labelColor : 'primary.main'}
          fontWeight="bold"
          fontSize={14}
          maxWidth="5rem"
          textAlign="center"
        >
          {props.label}
        </Typography>
      </ArrowBox>
      {props.hasSubmenu && props.submenuItems && (
        <>
          <ArrowDown onClick={handleClick} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {props.submenuItems &&
              props.submenuItems.map((element, index) => (
                <MenuItem sx={{ padding: 0 }} key={'mi' + index} onClick={handleClose}>
                  {element}
                </MenuItem>
              ))}
          </Menu>
        </>
      )}
    </>
  )
}

MyHomeRoundButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  hasSubmenu: PropTypes.bool,
  submenuItems: PropTypes.arrayOf(PropTypes.node),
}

export default MyHomeRoundButton
