import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCurrentUser } from '../Utils/Hooks'
import { logout } from '@Auth/Redux/Login'
import Logo from '@Assets/Logo'
import ChangePasswordForm from '@Auth/Forms/ChangePasswordForm'
import { useChangeUserPasswordMutation } from '@Auth/Services/Api'
import { history } from '@Core/Redux/Store'
import config, { makePath } from '@Config'
import styled, { css } from 'styled-components'
import { selectThemeMode, toggleThemeMode } from '@Core/Redux/Ui'
import { responseStringError } from '../Utils/Api'
import { toast } from 'react-toastify'
import { Grid, IconButton, useMediaQuery } from '@mui/material'
import { AdminPanelSettings, DarkMode, LightMode, Lock, Logout, Menu as MenuIcon, Person } from '@mui/icons-material'
import * as R from 'ramda'
import { Box } from '@Common/Components/Styles'
import Menu from '@Common/Components/Menu'
import OperationalBranchSwitch from '@Branches/Components/OperationalBranchSwitch'
import { canAccessAdminView } from '@Auth/Permissions'

const Header = styled.header`
  position: fixed;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.navbar.main};
  box-shadow: ${({ theme }) => theme.palette.navbar.shadow} 0px 0px 1rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  width: 100%;
  max-width: 100%;
  height: 60px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`

const Pointer = styled.div`
  cursor: pointer;
`

const RightArea = styled.div`
  align-items: center;
  display: flex;
`

const UserArea = styled(Menu)`
  min-width: initial;
`

const Separator = styled.div`
  ${({ theme }) =>
    css`
      background: ${theme.palette.white.main};
    `}
  height: 40px;
  width: 1px;
  margin: 0 ${(props) => props.margin || 1}rem;
`

const Badge = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.accentThree.main};
    color: ${theme.palette.white.main};
  `}
  border-radius: 20px;
  margin: 0 1rem;
  padding: 0.2rem 1rem;
`

const Navbar = ({ onHamburgerClick, variant }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const themeMode = useSelector(selectThemeMode)
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const user = useCurrentUser()
  const [changeUserPassword] = useChangeUserPasswordMutation()
  const [changePasswordForm, setChangePasswordForm] = useState({
    isOpen: false,
    user: null,
  })

  const handleChangePassword = () => {
    setChangePasswordForm({ isOpen: true, user: user })
  }
  const handleCloseChangePasswordForm = () => setChangePasswordForm({ isOpen: false, user: null })

  const handleLogout = () => {
    dispatch(logout())
    location.assign(config.urls.login)
  }

  const handleSaveChangePassword = async (userId, { password }) => {
    try {
      const response = await changeUserPassword({ userId, password }).unwrap()
      toast.success(t('auth:success.EmployeePasswordChanged'))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t('auth:errors.ChangeUserPasswordError', {
          error: responseStringError(error),
        }),
      )
      return { error, isSuccessful: false }
    }
  }

  const navigateToHome = () => history.push(config.urls.home)
  const navigateToAdmin = () => history.push(makePath('admin.dashboard'))
  const toggleTheme = () => dispatch(toggleThemeMode())

  const userMenuItems = [{ label: t('auth:actions.ChangePassword'), onClick: handleChangePassword, icon: <Lock /> }]
  const userMenuItemsMobile = [
    { label: t('auth:actions.ChangePassword'), onClick: handleChangePassword, icon: <Lock /> },
    {
      label: t('auth:actions.SwitchMode', {
        mode: themeMode === 'light' ? 'dark' : 'light',
      }),
      onClick: toggleTheme,
      icon: themeMode === 'light' ? <DarkMode /> : <LightMode />,
    },
    { label: t('auth:actions.Logout'), onClick: handleLogout, icon: <Logout /> },
  ]

  if (canAccessAdminView(user)) {
    const adminArea = {
      label: t('common:navigation.Administration'),
      onClick: navigateToAdmin,
      icon: <AdminPanelSettings />,
    }
    userMenuItems.unshift(adminArea)
    userMenuItemsMobile.unshift(adminArea)
  }

  return (
    <>
      <Header background="navbar" gridArea="navbar">
        <Grid container alignItems="center" wrap="nowrap">
          {isSmallDevice && (
            <IconButton onClick={onHamburgerClick} sx={{ mr: 2 }}>
              <MenuIcon color="white" />
            </IconButton>
          )}
          <Pointer onClick={navigateToHome}>
            <Logo fill="white" height="40px" />
          </Pointer>
        </Grid>
        <RightArea>
          {variant === 'default' && <OperationalBranchSwitch />}
          {variant === 'default' && <Separator />}
          {!isSmallDevice && (
            <IconButton onClick={toggleTheme} color="white">
              {R.ifElse(R.equals('light'), R.always(<DarkMode />), R.always(<LightMode />))(themeMode)}
            </IconButton>
          )}
          <Box>
            <UserArea
              label={
                <Box direction="row" align="center">
                  <Person color="white" />
                  {!isSmallDevice && <Badge>{user?.username}</Badge>}
                </Box>
              }
              items={isSmallDevice ? userMenuItemsMobile : userMenuItems}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            />
          </Box>
          {!isSmallDevice && (
            <>
              <Separator />
              <IconButton onClick={handleLogout}>
                <Logout color="white" />
              </IconButton>
            </>
          )}
        </RightArea>
      </Header>
      {changePasswordForm.isOpen && (
        <ChangePasswordForm
          user={changePasswordForm.user}
          onClose={handleCloseChangePasswordForm}
          onSave={handleSaveChangePassword}
        />
      )}
    </>
  )
}

Navbar.defaultProps = {
  variant: 'default',
}

Navbar.propTypes = {
  onHamburgerClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['default', 'admin-area']).isRequired,
}

export default Navbar
