import { Navigate, Route, Routes } from 'react-router-dom'
import { HistoryRouter as Router } from 'redux-first-history/rr6'

import ForgotPasswordForm from '@Auth/Forms/ForgotPasswordForm'
import LoginForm from '@Auth/Forms/LoginForm'
import ResetPasswordForm from '@Auth/Forms/ResetPasswordForm'
import { canAccessAdminView, canAccessMyEstiaView, canAccessOperativeView, canAccessTmsView } from '@Auth/Permissions'
import ConfirmRegistrationView from '@Auth/Views/ConfirmRegistrationView'
import { GenericCardView } from '@Auth/Views/GenericCardView'
import RegistrationView from '@Auth/Views/RegistrationView'
import { BranchBuildingPermissions } from '@Buildings/Permissions'
import { BuildingsRouter } from '@Buildings/Router/BuildingsRouter'
import GuestRoute from '@Common/Components/GuestRoute'
import PrivateRoute from '@Common/Components/PrivateRoute'
import AdminLayout from '@Common/Layouts/AdminLayout'
import OperationalLayout from '@Common/Layouts/OperationalLayout'
import { InsurancePolicyRouter } from '@Condo/Router/InsurancePolicyRouter'
import { PodPdrRouter } from '@Condo/Router/PodPdrRouter'
import { ReconciliationReminderRouter } from '@Condo/Router/ReconciliationReminderRouter'
import config from '@Config'
import { AdminRoutes } from '@Core/Router/routes'
import ForbiddenView from '@Core/Views/ForbiddenView'
import HomeView from '@Core/Views/HomeView'
import MaintenanceView from '@Core/Views/MaintenanceView'
import PageNotFoundView from '@Core/Views/PageNotFoundView'
import PageSuccessPaymentView from '@Core/Views/PageSuccessPaymentView'
import ServerErrorView from '@Core/Views/ServerErrorView'
import { CustomerCarePermissions } from '@CustomerCare/Permissions'
import CustomerCareRouter from '@CustomerCare/Router/CustomerCareRouter'
import MyEstiaRouter from '@MyEstia/Router/MyEstiaRouter'
import { SupplierRouter } from '@Supplier/Router/SupplierRouter'
import TmsRouter from '@Tms/Router/TmsRouter'

import { history } from '../Redux/Store'

const AdminRouter = () => (
  <PrivateRoute permFunction={canAccessAdminView}>
    <AdminLayout>
      <AdminRoutes />
    </AdminLayout>
  </PrivateRoute>
)

const AppRouter = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route
          exact
          path={config.urls.login}
          element={
            <GuestRoute>
              <GenericCardView>
                <LoginForm />
              </GenericCardView>
            </GuestRoute>
          }
        />
        <Route
          exact
          path={config.urls.register}
          element={
            <GuestRoute>
              <RegistrationView />
            </GuestRoute>
          }
        />
        <Route
          exact
          path={config.urls.confirmRegistration}
          element={
            <GuestRoute>
              <ConfirmRegistrationView />
            </GuestRoute>
          }
        />
        <Route
          exact
          path={config.urls.password.forgot}
          element={
            <GuestRoute>
              <GenericCardView>
                <ForgotPasswordForm />
              </GenericCardView>
            </GuestRoute>
          }
        />
        <Route
          exact
          path={config.urls.password.reset}
          element={
            <GuestRoute>
              <GenericCardView>
                <ResetPasswordForm />
              </GenericCardView>
            </GuestRoute>
          }
        />

        <Route exact path={config.urls.admin.base} element={<AdminRouter />} />

        <Route
          exact
          path={config.urls.myEstia.base}
          element={
            <PrivateRoute permFunction={canAccessMyEstiaView}>
              <MyEstiaRouter />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={config.urls.customerCare.base}
          element={
            <PrivateRoute permFunction={CustomerCarePermissions.read}>
              <CustomerCareRouter />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={config.urls.tms.base}
          element={
            <PrivateRoute permFunction={canAccessTmsView}>
              <TmsRouter />
            </PrivateRoute>
          }
        />
        <Route exact path={config.urls.pageNotFound} element={<PageNotFoundView />} />
        <Route exact path={config.urls.successPayment} element={<PageSuccessPaymentView />} />
        <Route exact path={config.urls.errorPayment} element={<PageSuccessPaymentView />} />
        <Route exact path={config.urls.forbidden} element={<ForbiddenView />} />
        <Route path={config.urls.serverError} element={<ServerErrorView />} />
        <Route path="*" element={<OperativeRoutes />} />
        <Route path={config.urls.maintenance} element={<MaintenanceView />} />
      </Routes>
    </Router>
  )
}

const OperativeRoutes = () => {
  return (
    <Routes>
      <Route element={<OperationalLayout />}>
        <Route element={<PrivateRoute permFunction={canAccessOperativeView} />}>
          <Route path="/">
            <Route index element={<HomeView />} />

            <Route element={<PrivateRoute permFunction={BranchBuildingPermissions.read} />}>
              <Route path="buildings/*" element={<BuildingsRouter />} />
              <Route path="podpdrs/*" element={<PodPdrRouter />} />
              <Route path="reconciliation-reminders/*" element={<ReconciliationReminderRouter />} />
              <Route path="insurance-policy/*" element={<InsurancePolicyRouter />} />
              <Route path="supplier/*" element={<SupplierRouter />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to={config.urls.pageNotFound} replace />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRouter
