/* eslint-disable react/prop-types */
import { useParams } from 'react-router-dom'

import { DataTableV3 } from '@Common/Components/DataTableV3'
import { SearchField } from '@Common/Components/FormsV2/SearchField'
import { SupplierReferent } from '@Supplier/Models/SupplierReferent'

export const SupplierReferentListView = ({ url }) => {
  const { supplierId } = useParams()
  const localUrl = url || `/condo/bicert/suppliers/${supplierId}/referents`

  const renderFilters = <SearchField xs={12} />

  return (
    <>
      <DataTableV3
        selectable={false}
        url={localUrl}
        defaultOrdering="last_name"
        model={SupplierReferent}
        renderFilters={renderFilters}
      />
    </>
  )
}
