import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'

const DeadlineConditionsSelect = ({ name, control, ...other }) => {
  const { t } = useTranslation()
  const deadlineConditions = [
    { value: 5, label: t('tms:fields.deadlineCondition.currentTaskOpening') },
    { value: 0, label: t('tms:fields.deadlineCondition.ancestorTaskOpening') },
    { value: 1, label: t('tms:fields.deadlineCondition.ancestorTaskClosing') },
    { value: 2, label: t('tms:fields.deadlineCondition.ancestorTaskDateField') },
  ]

  return <SelectField name={name} control={control} options={deadlineConditions} {...other} />
}

DeadlineConditionsSelect.propTypes = FieldType

export default DeadlineConditionsSelect
