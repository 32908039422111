import { Badge, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Box } from '@Common/Components/Styles'
import { makePath } from '@Config'
import { ServiceType } from '@Services/Models/Service'

const Container = styled(Box)`
  position: relative;
  height: 230px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    background-color: ${theme.palette.whiteBlack.main};
    box-shadow: 0 0 10px ${theme.palette.darkFour.main};
    color: ${theme.palette.lightOne.contrastText};
  `}
`

const Cover = styled.div`
  position: relative;
  width: 100%;
  height: 58%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ProviderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000055;
`

const Provider = styled.img`
  width: 100px;
  max-width: 70%;
  height: auto;
  max-height: 80%;
`

const InfoContainer = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
`

const Title = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blackWhite.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`

const Caption = styled(Typography)`
  font-size: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.palette.blackWhite.main};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
`

const ServiceCard = ({ service }) => {
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Link
      to={makePath(`myEstia.myServices.serviceDetail.main`, {
        category: service.categoryObj.slug,
        service: service.slug,
      })}
      style={{ textDecoration: 'none', position: 'relative', minWidth: '300px' }}
    >
      <Container isSmallDevice={isSmallDevice}>
        {service && service.labelObj && (
          <Badge
            sx={{
              backgroundColor: service.labelObj?.color,
              borderRadius: '20px',
              paddingInline: '13px',
              paddingTop: '5px',
              paggingBottom: '5px',
              zIndex: 1,
              position: 'absolute',
              right: 3,
              top: 3,
            }}
          >
            <Typography style={{ color: 'black', fontWeight: '500', fontSize: '13px' }}>
              {service.labelObj.name.toUpperCase()}
            </Typography>
          </Badge>
        )}
        <Cover>
          <Image src={service.listImage} />
          <ProviderOverlay>
            <Provider src={service.providerObj.logo} />
          </ProviderOverlay>
        </Cover>
        <InfoContainer pad="0.75rem">
          <Title>{service.title}</Title>
          <Caption>{service.listDescription}</Caption>
        </InfoContainer>
      </Container>
    </Link>
  )
}

ServiceCard.propTypes = {
  service: ServiceType,
}

export default ServiceCard
