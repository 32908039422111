import { Container } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'

import { withLoader } from '@Common/Utils/HOC'
import ServicesFilters from '@MyEstia/Components/Services/ServicesFilters'
import ServicesGrid from '@MyEstia/Components/Services/ServicesGrid'
import { useServicesQuery } from '@Services/Services/Api'
import { Margin } from '@Common/Components/Styles'

const Main = styled.div`
  padding-inline: 10px;
  margin-top: 3rem;
  margin-bottom: 6.5rem;
`

const base = {
  limit: 100,
  offset: 0,
  ordering: 'ordering_number',
}

const convertSearchQuery = (search) => ({
  list_description__icontains__or: search,
  title__icontains__or: search,
})

const convertFiltersQuery = ({ search, category }) => {
  if (category !== 'all') {
    return { base, qsAdditions: { category__slug: category, ...convertSearchQuery(search) } }
  } else {
    return { base, qsAdditions: convertSearchQuery(search) }
  }
}

export const ServicesView = () => {
  const [qs, setQs] = useState(convertFiltersQuery({ category: 'all' }))
  const { data, isFetching } = useServicesQuery(qs)
  const handleServicesFilter = (filters) => setQs(convertFiltersQuery(filters))

  return (
    <Main>
      <Margin bottom="3.5rem">
        <ServicesFilters onFilter={handleServicesFilter} />
      </Margin>
      {withLoader(
        <Container maxWidth="lg">
          <ServicesGrid services={data?.results} />
        </Container>,
        isFetching,
      )}
    </Main>
  )
}
