import i18next from 'i18next'

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'name',
      label: i18next.t('documents:fields.description'),
      filter: {
        type: 'text',
      },
    },
    {
      id: 'supplier',
      label: i18next.t('documents:fields.supplier'),
      filter: {
        type: 'text',
      },
    },
    {
      id: 'filesize',
      label: i18next.t('documents:fields.filesize'),
    },
    {
      id: 'id',
      label: i18next.t('common:actions.Download'),
    },
  ],
}
