import { Stack, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { defaultTo, pick } from 'ramda'
import { useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import EmployeeByRoleSelect from '@Auth/Components/EmployeeByRoleSelect'
import { useBranchesBuildingsQuery } from '@Buildings/Services/Api'
import DatePickerField from '@Common/Components/Forms/DatePickerField'
import EditorField from '@Common/Components/Forms/EditorField'
import FileDropzoneField from '@Common/Components/Forms/FileDropzoneField'
import SelectField from '@Common/Components/Forms/SelectField'
import TextField from '@Common/Components/Forms/TextField'
import { Box, Cursor, Label } from '@Common/Components/Styles'
import logger from '@Common/Utils/Logger'
import ArcadiaAnagraphicAutocomplete from '@Residents/Components/ArcadiaAnagraphicAutocomplete'
import { getAnagraphicInfo } from '@Residents/Utils'
import BuildingSelect from '@Tms/Forms/Selects/BuildingSelect'
import SuppliersField from '@Tms/Forms/TaskCustomField/SuppliersField'
import UrlField from '@Tms/Forms/TaskCustomField/UrlField'
import UtilitiesField from '@Tms/Forms/TaskCustomField/UtilitiesField'
import { RunningTaskFieldType } from '@Tms/Models/RunningTask'
import { TaskFieldType } from '@Tms/Models/TaskField'
import { REQUIRED_TYPES, TASK_FIELD_TYPE_IDS } from '@Tms/Utils'


const TaskCustomFieldInput = ({ control, field, value, disabled, isTaskUpdate }) => {
  const { t } = useTranslation()
  const { errors, watch } = useFormContext()
  const { id, type, name, helperText, choices, isDateInFuture, isDateInPast } = field
  const residentValue = value?.resident ? { label: getAnagraphicInfo(value.residentObj), value: value.resident } : null
  const required =
    field.isRequired &&
    (field.requiredType === REQUIRED_TYPES.TASK_OPENING ||
      (isTaskUpdate && field.requiredType === REQUIRED_TYPES.TASK_CLOSING))

  /*
   * Values used for supplier custom field
   */
  const [isCondo, setIsCondo] = useState(true)
  const buildingValue = watch('building', null) // Retrieve building value from form

  // This query triggers a fetch only when updating task that do not have custom fields of type building
  // in other cases, the query is already triggered by the BuildingSelect component and the result is cached
  const { data } = useBranchesBuildingsQuery(
    {},
    {
      // skip query if not supplier custom field or if buildingId is not set or the selected building is of type condo
      skip: isCondo || type !== TASK_FIELD_TYPE_IDS.supplier || !buildingValue,
    },
  )

  // Calculate selected buildingId using buildingType from buildingValue
  const selectedLocalBuildingId = useMemo(() => {
    let id = null
    if (TASK_FIELD_TYPE_IDS.supplier === type) {
      if (buildingValue) {
        const [buildingSelectId, buildingType] = buildingValue?.split('_') ?? []
        if (buildingType === 'branchbuilding') {
          setIsCondo(false)
          const building = data?.results?.find((b) => b.id === parseInt(buildingSelectId))
          id = building?.localBuildingId
        } else {
          setIsCondo(true)
          id = buildingSelectId
        }
      } else {
        setIsCondo(true)
      }
    } else {
      setIsCondo(true)
    }
    return id
  }, [buildingValue, data?.results])

  /*
   * End of values used for supplier custom field
   */

  switch (type) {
    case TASK_FIELD_TYPE_IDS.text:
      return (
        <TextField
          fullWidth
          disabled={disabled}
          required={required}
          {...(!isTaskUpdate && { rules: { required } })}
          name={`customFields.${id.toString()}.textValue`}
          control={control}
          label={name}
          error={!!errors?.customFields?.[id.toString()]?.textValue}
          helperText={helperText}
          defaultValue={defaultTo('', value?.textValue)}
        />
      )
    case TASK_FIELD_TYPE_IDS.textarea:
      return (
        <EditorField
          required={required}
          name={`customFields.${id.toString()}.textValue`}
          control={control}
          label={name}
          {...(!isTaskUpdate && { rules: { required } })}
          error={!!errors?.customFields?.[id.toString()]?.textValue}
          helperText={helperText}
          defaultValue={defaultTo('', value?.textValue)}
        />
      )
    case TASK_FIELD_TYPE_IDS.select:
      return (
        <Stack spacing={0}>
          <SelectField
            fullWidth
            disabled={disabled}
            required={required}
            {...(!isTaskUpdate && { rules: { required } })}
            error={!!errors?.customFields?.[id.toString()]?.choice}
            name={`customFields.${id.toString()}.choice`}
            control={control}
            label={name}
            helperText={helperText}
            valueKey="id"
            labelKey="value"
            options={defaultTo([], choices?.map(pick(['id', 'value'])))}
            defaultValue={defaultTo('', value?.choice)}
          />
          {choices &&
            choices.map((c) => {
              if (c.isJustificationRequired) {
                const choiceValue = useWatch({ control, name: `customFields.${id.toString()}.choice` })
                return choiceValue === c.id || (!choiceValue && value?.justification) ? (
                  <TextField
                    disabled={disabled}
                    key={c.id}
                    required
                    error={!!errors?.customFields?.[id.toString()]?.choice}
                    rules={{ required: true }}
                    name={`customFields.${id.toString()}.justification`}
                    control={control}
                    label={t('tms:fields.justification')}
                    defaultValue={defaultTo('', value?.justification)}
                  />
                ) : null
              }
            })}
        </Stack>
      )
    case TASK_FIELD_TYPE_IDS.date:
      return (
        <DatePickerField
          fullWidth
          disabled={disabled}
          required={required}
          {...(!isTaskUpdate && { rules: { required } })}
          error={!!errors?.customFields?.[id.toString()]?.dateValue}
          name={`customFields.${id.toString()}.dateValue`}
          control={control}
          label={name}
          {...(isDateInFuture && { minDate: dayjs() })}
          {...(isDateInPast && { maxDate: dayjs() })}
          helperText={helperText}
          defaultValue={value ? defaultTo('', dayjs(value?.dateValue)) : ''}
        />
      )
    case TASK_FIELD_TYPE_IDS.attachment:
      return (
        <>
          <Box align="center" margin="0 0 .5rem">
            <Label>
              {name}
              {required && ' *'}
            </Label>
          </Box>
          {value && (
            <Box direction="row" margin="0 0 1rem">
              {!disabled && <Typography mr="1rem">{t('common:ui.CurrentFile')}:</Typography>}
              <a href={value.file} rel="noreferrer" target="_blank" style={{ maxWidth: '100%', fontSize: '0.9rem' }}>
                {value.fileName}
              </a>
              <Tooltip title={t('common:actions.Delete')}>
                <Typography onClick={() => {}} marginLeft="0.75rem" color="error" variant="span">
                  <Cursor type="pointer">{/* &times; */}</Cursor>
                </Typography>
              </Tooltip>
            </Box>
          )}
          {!disabled && (
            <FileDropzoneField
              name={`customFields.${id.toString()}.file`}
              {...(!isTaskUpdate && { rules: { required } })}
              error={!!errors?.customFields?.[id.toString()]?.file}
              defaultValue={[]}
              control={control}
              maxSize={10 * 1024 * 1024}
              warningText={t('tms:info.AttachmentCustomFieldLimitText')}
              helperText={helperText}
            />
          )}
        </>
      )
    case TASK_FIELD_TYPE_IDS.resident:
      return (
        <Controller
          name={`customFields.${id.toString()}.resident`}
          control={control}
          {...(!isTaskUpdate && { rules: { required } })}
          defaultValue={residentValue?.value}
          render={({ field: { onChange, value } }) => (
            <ArcadiaAnagraphicAutocomplete
              disabled={disabled}
              label={name}
              required={required}
              onChange={onChange}
              value={value?.value}
              error={!!errors?.customFields?.[id.toString()]?.resident}
              defaultValue={residentValue}
              helperText={helperText}
            />
          )}
        />
      )
    case TASK_FIELD_TYPE_IDS.employee:
      return (
        <Controller
          name={`customFields.${id.toString()}.employee`}
          control={control}
          {...(!isTaskUpdate && { rules: { required } })}
          defaultValue={defaultTo('', value?.employee)}
          render={({ field: { onChange, value } }) => (
            <EmployeeByRoleSelect
              searchable
              disabled={disabled}
              required={required}
              roles={[]}
              label={name}
              onChange={onChange}
              error={!!errors?.customFields?.[id.toString()]?.employee}
              value={value}
              helperText={helperText}
            />
          )}
        />
      )
    case TASK_FIELD_TYPE_IDS.supplier:
      return (
        <SuppliersField
          name={`customFields.${id.toString()}.suppliers`}
          control={control}
          buildingId={selectedLocalBuildingId}
          {...(!isTaskUpdate && { rules: { required } })}
          error={!!errors?.customFields?.[id.toString()]?.suppliers}
          defaultValue={defaultTo([], value?.suppliers)}
          disabled={disabled}
          required={required}
          helperText={helperText}
          label={name}
        />
      )
    case TASK_FIELD_TYPE_IDS.url:
      return (
        <UrlField
          fullWidth
          disabled={disabled}
          required={required}
          isTaskUpdate={isTaskUpdate}
          {...(!isTaskUpdate && { rules: { required } })}
          error={!!errors?.customFields?.[id.toString()]?.urlValue}
          helperText={errors?.customFields?.[id.toString()]?.urlValue?.message ?? helperText}
          defaultValue={defaultTo('', value?.urlValue)}
          name={`customFields.${id.toString()}.urlValue`}
          control={control}
          label={name}
        />
      )
    case TASK_FIELD_TYPE_IDS.utilities:
      return (
        <UtilitiesField
          name={`customFields.${id.toString()}.utilities`}
          required={required}
          control={control}
          value={defaultTo(null, value?.utilities)}
          helperText={helperText}
          disabled={disabled}
          isTaskUpdate={isTaskUpdate}
        />
      )
    case TASK_FIELD_TYPE_IDS.building:
      return (
        <BuildingSelect
          required={required}
          name={`customFields.${id.toString()}.building`}
          disabled={disabled}
          control={control}
          {...(!isTaskUpdate && { rules: { required } })}
          helperText={helperText}
          error={!!errors?.customFields?.[id.toString()]?.building}
          label={t('tms:fields.building')}
          defaultValue={
            value?.buildingId && value?.buildingTypeValue ? `${value?.buildingId}_${value.buildingTypeValue}` : null
          }
        />
      )
    default:
      logger.debug(id, type, name, helperText)
      return null
  }
}

TaskCustomFieldInput.propTypes = {
  control: PropTypes.object,
  field: TaskFieldType,
  value: RunningTaskFieldType,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  isTaskUpdate: PropTypes.bool,
}

const TaskCustomField = ({ control, field, value, disabled, isTaskUpdate, errors }) => {
  return (
    <>
      {value && (
        <Controller
          control={control}
          name={`customFields.${field.id.toString()}.id`}
          defaultValue={value.id}
          render={({ field: { value } }) => <input type="hidden" value={value} />}
        />
      )}
      <TaskCustomFieldInput
        disabled={disabled}
        control={control}
        field={field}
        value={value}
        errors={errors}
        isTaskUpdate={isTaskUpdate}
      />
    </>
  )
}

TaskCustomField.defaultProps = {
  value: null,
  isTaskUpdate: false,
}

TaskCustomField.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  field: TaskFieldType,
  value: RunningTaskFieldType,
  isTaskUpdate: PropTypes.bool,
  errors: PropTypes.object,
}

export default TaskCustomField
