import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as R from 'ramda'

import { Button } from '@mui/material'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { makePath } from '@Config'
import { Box } from '@Common/Components/Styles'
import MyHomeIcon from '@Assets/MyHomeIcon'
import DocumentsIcon from '@Assets/MyEstiaDocuments/DocumentsIcon'
import InstalmentsIcon from '@Assets/MyEstiaDocuments/InstalmentsIcon'

const NavigationButton = styled(Button)`
  ${({ theme, ...props }) => css`
    color: ${theme.palette.primary.main};
    text-transform: none;
    padding: 1px 10px 1px 5px;
    font-weight: bold;
    font-size: 1.1rem;
    justify-content: flex-start;
    border-radius: 15px;
    border-width: 2px;
    border-color: ${props.selected ? theme.palette.secondary.main : 'transparent'};
    background-color: ${theme.palette.sidebarButton.main};
    min-width: 250px;

    :hover {
      border-color: ${theme.palette.secondary.main};
      border-width: 2px;
      background-color: ${theme.palette.sidebarButton.main};
    }
  `}
`

const MyHomeSidebar = () => {
  const { housingUnitId } = useParams()

  const pathname = useSelector(R.path(['router', 'location', 'pathname']))

  const { t } = useTranslation()

  return (
    <Box pad="0 0 4rem">
      <Box direction="column" margin="2rem 0 2rem 2rem" height="18rem" justify="space-between">
        <NavigationButton
          selected={pathname === makePath('myEstia.myHome.main', { housingUnitId })}
          variant="outlined"
          component={Link}
          to={makePath('myEstia.myHome.main', { housingUnitId })}
          startIcon={<MyHomeIcon height="5rem" width="5rem" />}
        >
          {t('myHome:ui.MyHome')}
        </NavigationButton>
        <NavigationButton
          selected={pathname === makePath('myEstia.myHome.installments', { housingUnitId })}
          variant="outlined"
          component={Link}
          to={makePath('myEstia.myHome.installments', { housingUnitId })}
          startIcon={<InstalmentsIcon height="5rem" width="5rem" />}
        >
          {t('myHome:ui.Installments')}
        </NavigationButton>
        <NavigationButton
          selected={pathname === makePath('myEstia.myHome.documents', { housingUnitId })}
          variant="outlined"
          component={Link}
          to={makePath('myEstia.myHome.documents', { housingUnitId, type: 'personal' })}
          startIcon={<DocumentsIcon height="5rem" width="5rem" />}
        >
          {t('myHome:ui.Documents')}
        </NavigationButton>
      </Box>
    </Box>
  )
}

export default MyHomeSidebar
