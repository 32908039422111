import { ChevronRight, Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link as MuiLink } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { ExportExcelButton } from '@Common/Components/ExportExcelButton'
import { ImportExcelButton } from '@Common/Components/ImportExcelButton'
import { useConfirm, useCurrentUser } from '@Common/Utils/Hooks'
import { isAdminRoute } from '@Common/Utils/Url'
import { PodPdr, PodSupplyTypes } from '@Condo/Models/PodPdr'
import { PodPdrPermissions } from '@Condo/Permissions'
import { useCondoPodPdrQuery } from '@Condo/Services/Api'
import { getDetailPathEntity, getDisplayColumnsCondo, getFieldsMappingCondo } from '@Condo/Utils'
import { makePath } from '@Config'

import { selectActiveBranch } from '../../Branches/Redux'
import ButtonDialogWithOutCondoReference from './ButtonDialogWithOutCondoReference'

const SEARCH_FIELDS = [
  'stair_n',
  'pod_number',
  'pod_supply_type',
  'pod_supplier',
  'pdr_number',
  'pdr_supplier',
  'notes',
  'building__description',
  'building__branch__name',
]

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${field}__icontains__or`] = qs.qsAdditions.search
    })

    delete qs.qsAdditions.search
  }

  return qs
}

const BuildingPodPdrList = ({ onEdit, onDelete, onCreate, buildingId = null }) => {
  const user = useCurrentUser()
  const isAdmin = isAdminRoute()

  const basePath = isAdmin ? 'admin.' : ''

  const FIELDS_MAPPING = {
    podSupplyType: (record) => {
      return PodSupplyTypes.find((x) => x.id === record.podSupplyType).label
    },
    // eslint-disable-next-line react/display-name
    podNumber: (record) => {
      return (
        <MuiLink
          component={Link}
          underline="always"
          to={getDetailPathEntity(isAdmin, record.id, 'podpdrs', buildingId)}
        >
          {record?.podNumber}
        </MuiLink>
      )
    },
    ...getFieldsMappingCondo(isAdmin),
  }

  const showImport = buildingId ? false : isAdmin
  const showExport = !buildingId

  const branch = useSelector(selectActiveBranch)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [selected, setSelected] = React.useState([])

  const DISPLAY_COLUMNS = React.useMemo(() => {
    const columns = ['stairN', 'podNumber', 'podSupplyType', 'podSupplier', 'pdrNumber', 'pdrSupplier']

    if (!buildingId) {
      return [...columns, ...getDisplayColumnsCondo(), 'notes']
    }

    return columns
  })

  const dtName = showExport ? 'all-podpdrs' : 'condo-building-podpdrs'

  // default qs
  const qsAdditions = React.useMemo(() => {
    if (branch) {
      return { building__branch__in: [branch.id] }
    }
    return null
  }, [branch])

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage(dtName),
      offset: 0,
      ordering: 'stair_n',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useCondoPodPdrQuery({ condo_id: buildingId ?? null, qs: convertSearchQuery(qs) })

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        icon: <ChevronRight />,
        label: t('common:actions.Detail'),
        perm: PodPdrPermissions.read,
      },
      {
        id: 'EDIT',
        icon: <Edit />,
        label: t('common:actions.Edit'),
        perm: PodPdrPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: PodPdrPermissions.delete,
      },
    ],
    [data?.count],
  )

  const handleAction = React.useCallback(
    (actionId, podpdr) => {
      let detailPath = null

      switch (actionId) {
        case 'EDIT':
          onEdit(podpdr)
          break
        case 'DETAIL':
          if (buildingId) {
            detailPath = makePath(`${basePath}buildings.detail.podpdrs.detail.main`, {
              buildingId: buildingId,
              id: podpdr.id,
            })
          } else {
            detailPath = makePath(`${basePath}podpdrs.detail.main`, { id: podpdr.id })
          }

          navigate(detailPath)

          break
        case 'DELETE':
          openDeleteConfirm(
            selected, // payload
            t('condo:ui.DeletePodPdrConfirmationTitle'),
            t('condo:ui.DeletePodPdrConfirmationText', { count: selected.length }),
          )
          break
      }
    },
    [selected],
  )

  const handleDeleteProvider = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteProvider)

  const toolbar = (
    <>
      {PodPdrPermissions.create(user) && showExport && (
        <ButtonDialogWithOutCondoReference
          onCreate={onCreate}
          referenceType={'pod_pdr'}
          title={t('condo:ui.CondoWithoutPodPdr')}
          titleBtnCreate={t('condo:ui.CreatePodPdr')}
        />
      )}
      {showExport && <ExportExcelButton defaultFilename="pod-pdr" exportApi="condo/podpdrs/excel" qs={qs} />}
      {PodPdrPermissions.create(user) && showImport && <ImportExcelButton importApi="condo/podpdrs/excel" />}
    </>
  )

  return (
    <div>
      <DataTable
        name={dtName}
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={PodPdr}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
        toolbarContent={toolbar}
      ></DataTable>

      {DeleteConfirm}
    </div>
  )
}

BuildingPodPdrList.propTypes = {
  buildingId: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default BuildingPodPdrList
