export const HQ_ROLES = {
  HQ_CUSTOMER_CARE: 4,
  HQ_ADMINISTRATIVE: 5,
  HQ_MAIN_OFFICE: 6,
  HQ_IT: 7,
  HQ_HR: 8,
  HQ_MARKETING: 9,
  HQ_SUPPLIERS: 11,
  HQ_EMERGENCY_WORKS: 12,
  HQ_DEVELOPMENT: 13,
  HQ_BUSINESS_DEVELOPMENT: 14,
  HQ_TECH_OFFICE: 15,
}

export const EMPLOYEE_ROLES = {
  BRANCH_DIRECTOR: 1,
  SENIOR_ADMIN: 2,
  JUNIOR_ADMIN: 3,
  ...HQ_ROLES,
}

export const ROLES = {
  BRANCH_DIRECTOR: 1,
  SENIOR_ADMIN: 2,
  JUNIOR_ADMIN: 3,
  ...HQ_ROLES,
  RESIDENT: 10,
}

export const INVITATION_RETRY_STATUSES = {
  1: "PENDING",
  2: "INVITATION_ERROR",
  3: "INVITATION_SENT",
  6: "MISSING_EMAIL",
  7: "BOUNCED_EMAIL",
  8: "MISSING_REQUIRED_DATA",
}

export const INVITATION_STATUSES = {
  4: "INVITATION_CONFIRMED",
  5: "INVITATION_SUCCESS",
  ...INVITATION_RETRY_STATUSES,
}
