import PropTypes from 'prop-types'
import { AddressType, BuildingSummaryType } from '@Buildings/Models/Building'
import i18next from 'i18next'
import { ContactType } from '@Buildings/Models/Contact'
import { getSuppliersSummary } from '@Buildings/Utils'

export const SupplierType = PropTypes.shape({
  id: PropTypes.string,
  classe: PropTypes.number,
  tipo: PropTypes.number,
  titolo: PropTypes.string,
  nominativo: PropTypes.string,
  cognome: PropTypes.string,
  nome: PropTypes.string,
  ragioneSociale: PropTypes.string,
  partitaIva: PropTypes.string,
  codiceFiscale: PropTypes.string,
  contatti: PropTypes.arrayOf(ContactType),
  residenzaSedeLegale: AddressType,
  domicilioSedeOperativa: AddressType,
})

export const FacilityType = PropTypes.shape({
  id: PropTypes.string,
  immobile: BuildingSummaryType,
  edificio: PropTypes.string,
  scala: PropTypes.string,
  descrizione: PropTypes.string,
  tipologia: PropTypes.string,
  matricola: PropTypes.string,
  fornitori: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      priorita: PropTypes.number,
      fornitore: SupplierType,
    }),
  ),
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('buildings:fields.id'),
    },
    {
      id: 'tipologia',
      label: i18next.t('buildings:fields.typology'),
    },
    {
      id: 'descrizione',
      label: i18next.t('buildings:fields.description'),
    },
    {
      id: 'matricola',
      label: i18next.t('buildings:fields.serialNumber'),
    },
    {
      id: 'edificio',
      label: i18next.t('buildings:fields.edifice.__field'),
    },
    {
      id: 'scala',
      label: i18next.t('buildings:fields.stair'),
    },
    {
      id: 'fornitori',
      label: i18next.t('buildings:fields.suppliers'),
      array: true,
      callback: getSuppliersSummary,
    },
  ],
}
