import { Grid, Typography } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import PropTypes from 'prop-types'
import { defaultTo } from 'ramda'
import React from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxField from '@Common/Components/Forms/CheckboxField'
import TextField from '@Common/Components/Forms/TextField'
import LabeledBox from '@Tms/Components/LabeledBox'
import BillingCycleField from '@Tms/Forms/TaskCustomField/UtilitiesField/BillingCycleField'
import LastBillDateField from '@Tms/Forms/TaskCustomField/UtilitiesField/LastBillDateField'
import { RunningTaskFieldType } from '@Tms/Models/RunningTask'

const UtilitiesField = ({ name, control, required, value, helperText, disabled }) => {
  const { t } = useTranslation()
  const noGasUtility = useWatch({ control, name: `${name}.noGasUtility`, defaultValue: value?.noGasUtility })
  const noWaterUtility = useWatch({ control, name: `${name}.noWaterUtility`, defaultValue: value?.noWaterUtility })

  return (
    <>
      {value && (
        <Controller
          control={control}
          name={`${name}.id`}
          defaultValue={value.id}
          render={({ field: { value } }) => <input type="hidden" value={value} />}
        />
      )}
      <Grid container>
        <Grid item xs={12} mb={2}>
          <Typography fontWeight={600} mb={0}>
            {t('tms:fields.utilities.__field')}
          </Typography>
          <FormHelperText>{helperText}</FormHelperText>
        </Grid>
        <Grid container item xs={12} mb={1}>
          <Grid item xs={12} sm={4}>
            <LabeledBox label={t('tms:fields.utilities.type')}>{t('tms:labels.Power')}</LabeledBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LastBillDateField
              required={required}
              name={`${name}.powerLastBillDate`}
              {...(value && { defaultValue: value.powerLastBillDate })}
              disabled={disabled}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BillingCycleField
              required={required}
              name={`${name}.powerBillingCycle`}
              {...(value && { defaultValue: value.powerBillingCycle })}
              disabled={disabled}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={1}>
          <Grid item xs={12} sm={4}>
            <LabeledBox label={t('tms:fields.utilities.type')}>{t('tms:labels.Gas')}</LabeledBox>
            <CheckboxField
              size="small"
              name={`${name}.noGasUtility`}
              control={control}
              disabled={disabled}
              defaultValue={defaultTo(false, value?.noGasUtility)}
              label={t('tms:fields.utilities.noUtility')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LastBillDateField
              required={!noGasUtility && required}
              name={`${name}.gasLastBillDate`}
              {...(value && { defaultValue: value.gasLastBillDate })}
              disabled={disabled}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BillingCycleField
              required={!noGasUtility && required}
              name={`${name}.gasBillingCycle`}
              {...(value && { defaultValue: value.gasBillingCycle })}
              disabled={disabled}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={1}>
          <Grid item xs={12} sm={4}>
            <LabeledBox label={t('tms:fields.utilities.type')}>{t('tms:labels.Water')}</LabeledBox>
            <CheckboxField
              size="small"
              name={`${name}.noWaterUtility`}
              control={control}
              disabled={disabled}
              defaultValue={defaultTo(false, value?.noWaterUtility)}
              label={t('tms:fields.utilities.noUtility')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LastBillDateField
              required={!noWaterUtility && required}
              name={`${name}.waterLastBillDate`}
              {...(value && { defaultValue: value.waterLastBillDate })}
              disabled={disabled}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BillingCycleField
              required={!noWaterUtility && required}
              name={`${name}.waterBillingCycle`}
              {...(value && { defaultValue: value.waterBillingCycle })}
              disabled={disabled}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              name={`${name}.customOneType`}
              control={control}
              {...(value && { defaultValue: value.customOneType })}
              disabled={disabled}
              label={t('tms:fields.utilities.type')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LastBillDateField
              name={`${name}.customOneLastBillDate`}
              {...(value && { defaultValue: value.customOneLastBillDate })}
              disabled={disabled}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BillingCycleField
              name={`${name}.customOneBillingCycle`}
              {...(value && { defaultValue: value.customOneBillingCycle })}
              disabled={disabled}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              name={`${name}.customTwoType`}
              control={control}
              {...(value && { defaultValue: value.customTwoType })}
              disabled={disabled}
              label={t('tms:fields.utilities.type')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LastBillDateField
              name={`${name}.customTwoLastBillDate`}
              {...(value && { defaultValue: value.customTwoLastBillDate })}
              disabled={disabled}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BillingCycleField
              name={`${name}.customTwoBillingCycle`}
              disabled={disabled}
              {...(value && { defaultValue: value.customTwoBillingCycle })}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              name={`${name}.customThreeType`}
              control={control}
              {...(value && { defaultValue: value.customThreeType })}
              disabled={disabled}
              label={t('tms:fields.utilities.type')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LastBillDateField
              name={`${name}.customThreeLastBillDate`}
              disabled={disabled}
              {...(value && { defaultValue: value.customThreeLastBillDate })}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BillingCycleField
              name={`${name}.customThreeBillingCycle`}
              {...(value && { defaultValue: value.customThreeBillingCycle })}
              disabled={disabled}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

UtilitiesField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: RunningTaskFieldType,
  isTaskUpdate: PropTypes.bool,
  errors: PropTypes.object,
  helperText: PropTypes.string,
}

export default UtilitiesField
