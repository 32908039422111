import i18next from 'i18next'
import PropTypes from 'prop-types'

import { EmployeeType } from '@Auth/Models/Employee'
import { BranchType } from '@Branches/Models/Branch'

import { Building as ArcadiaBuilding } from './Building'

export const BranchBuilding = {
  id: PropTypes.number.isRequired,
  arcadiaId: PropTypes.string.isRequired,
  administrator: PropTypes.number,
  administratorObj: EmployeeType,
  accountingOwner: PropTypes.number,
  accountingOwnerObj: EmployeeType,
  administrationOwner: PropTypes.number,
  administrationOwnerObj: EmployeeType,
  accidentsOwner: PropTypes.number,
  accidentsOwnerObj: EmployeeType,
  branch: PropTypes.number.isRequired,
  branchObj: BranchType,
  localBuildingId: PropTypes.number,
}

export const BranchBuildingType = PropTypes.shape({
  ...BranchBuilding,
  raw: PropTypes.shape(ArcadiaBuilding),
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'branchObj.name',
      label: i18next.t('buildings:fields.branch.__field'),
    },
    {
      id: 'administratorFullName',
      label: i18next.t('buildings:fields.administrator.__field'),
    },
    {
      id: 'accountingOwnerFullName',
      label: i18next.t('buildings:fields.accountingOwner'),
    },
    {
      id: 'administrationOwnerFullName',
      label: i18next.t('buildings:fields.administrationOwner'),
    },
    {
      id: 'accidentsOwnerFullName',
      label: i18next.t('buildings:fields.accidentsOwner'),
    },
    {
      id: 'raw.denominazione',
      label: i18next.t('buildings:fields.name'),
    },
    {
      id: 'raw.indirizzo.via',
      label: i18next.t('buildings:fields.address'),
    },
    {
      id: 'raw.indirizzo.civico',
      label: i18next.t('buildings:fields.houseNumber'),
    },
    {
      id: 'raw.indirizzo.cap',
      label: i18next.t('buildings:fields.cap'),
    },
    {
      id: 'raw.indirizzo.comune',
      label: i18next.t('buildings:fields.city'),
    },
    {
      id: 'raw.indirizzo.provincia',
      label: i18next.t('buildings:fields.province'),
    },
    {
      id: 'raw.codiceFiscale',
      label: i18next.t('buildings:fields.fiscalCode'),
    },
    {
      id: 'raw.id',
      label: i18next.t('buildings:fields.arcadiaId'),
    },
    {
      id: 'iban',
      label: i18next.t('buildings:fields.defaultBankAccount.iban'),
    },
    {
      id: 'raw.nrEdifici',
      label: i18next.t('buildings:fields.edificesNumber'),
      numeric: true,
    },
    {
      id: 'raw.nrScale',
      label: i18next.t('buildings:fields.stairsNumber'),
      numeric: true,
    },
    {
      id: 'raw.nrUi',
      label: i18next.t('buildings:fields.housingUnitNumber'),
      numeric: true,
    },
  ],
}
