import React from 'react'
import { BranchBuildingType } from '@Buildings/Models/BranchBuilding'
import { useBuildingInstallmentsQuery } from '@Buildings/Services/Api'
import { Margin } from '@Common/Components/Styles'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Installment from '@Buildings/Models/Installment'
import InstallmentsSearchForm from '@Buildings/Forms/InstallmentsSearchForm'
import * as R from 'ramda'
import dayjs from 'dayjs'

const DISPLAY_COLUMNS = [
  'ui.edificio.descrizione',
  'ui.scala.descrizione',
  'ui.descrizione',
  'anagrafica.nominativo',
  'incassato',
  'residuo',
  'scadenza',
  'descrizione',
]

const FIELDS_MAPPING = {
  residuo: R.pipe(R.prop('residuo'), R.toString, R.concat('€')),
  incassato: R.pipe(R.prop('incassato'), R.toString, R.concat('€')),
  importo: R.pipe(R.prop('importo'), R.toString, R.concat('€')),
  incassi: (record) => record.incassi.map(i => `${dayjs(i.data).format('DD/MM/YYYY')} - € ${i.importo}`).join('\n'),
}

const DISABLED_SORT_FIELDS = ['incassi']

const BuildingInstallmentsList = ({ building }) => {
  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('building-installments'),
      offset: 0,
      ordering: 'ui.edificio.descrizione',
    },
  })
  const refreshData = (data) => {
    setQs(data)
  }
  const [qsAdditions, setQsAdditions] = React.useState({})

  // new qs triggers refetch
  const { data: installments, isFetching } = useBuildingInstallmentsQuery(
    { buildingArcadiaId: building.arcadiaId, qs },
  )

  const handleSearch = (fields) => {
    const params = {}
    if (fields.name) params.anagrafica__nominativo__icontains = fields.name
    if (fields.edifice) params.ui__edificio__id = fields.edifice
    if (fields.stair) params.ui__scala__id = fields.stair
    if (fields.housingUnit) params.ui__id = fields.housingUnit
    setQsAdditions(params)
  }

  return (
    <div>
      <Margin bottom="2rem">
        <InstallmentsSearchForm buildingArcadiaId={building.arcadiaId} onSearch={handleSearch} />
      </Margin>
      <DataTable
        name="building-installments"
        qs={qs}
        qsAdditions={qsAdditions}
        data={installments?.results || []}
        dataCount={installments?.count || 0}
        refreshData={refreshData}
        model={Installment}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        disableSorting={DISABLED_SORT_FIELDS}
        loading={isFetching}
        noExport
      />
    </div>
  )
}

BuildingInstallmentsList.propTypes = {
  building: BranchBuildingType,
}

export default BuildingInstallmentsList
