import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import config from '../../../Config'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import ReportsList from '../../Components/ReportsList'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminReportsView = () => {
  const { t } = useTranslation()

  useBreadcrumbs([{ label: t('common:navigation.Tickets'), path: config.urls.admin.tickets.reports, Icon: 'Tickets' }])

  return (
    <Main>
      <ReportsList />
    </Main>
  )
}

export default AdminReportsView
