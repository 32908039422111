import PropTypes from 'prop-types'

export const ReportMessageType = PropTypes.shape({
  id: PropTypes.number,
  created: PropTypes.string,
  message: PropTypes.string,
  sender: PropTypes.number,
  sender_name: PropTypes.string,
  report: PropTypes.number,
})
