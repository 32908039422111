import { Download } from '@mui/icons-material'
import { Button } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'

import DataTable from '@Common/Components/DataTable'
import { Margin } from '@Common/Components/Styles'
import { formatBytes } from '@Common/Utils/Strings'
import DocumentsSearchForm from '@Documents/Forms/DocumentsSearchForm'
import BaseDocument from '@Documents/Models/BaseDocument'
import BuildingInvoiceDocument from '@Documents/Models/BuildingInvoiceDocument'
import ContractDocument from '@Documents/Models/ContractDocument'
import FacilityDocument from '@Documents/Models/FacilityDocument'
import FinancialStatementDocument from '@Documents/Models/FinancialStatementDocument'
import ResidentFinancialDocument from '@Documents/Models/ResidentFinancialDocument'
import ResidentPersonalDocument from '@Documents/Models/ResidentPersonalDocument'
import SupplierDocument from '@Documents/Models/SupplierDocument'
import WithDateDocument from '@Documents/Models/WithDateDocument'
import WithYearDocument from '@Documents/Models/WithYearDocument'
import { handleDownload } from '@Documents/Utils/DownloadDocuments'

const MODELS_MAP = {
  meetings: WithYearDocument,
  supplierQuotes: SupplierDocument,
  facilities: FacilityDocument,
  financialStatements: FinancialStatementDocument,
  bankStatements: WithYearDocument,
  invoices: BuildingInvoiceDocument,
  residentsReceipts: ResidentFinancialDocument,
  residentsBankStatements: ResidentFinancialDocument,
  financial: WithYearDocument,
  generics: BaseDocument,
  contracts: ContractDocument,
  leases: BaseDocument,
  employees: BaseDocument,
  shipmentSlips: WithDateDocument,
  accidents: WithDateDocument,
  legalPractices: WithDateDocument,
  residentsPersonal: ResidentPersonalDocument,
  security: FacilityDocument,
}

const DOWNLOAD_PATH_MAP = {
  meetings: 'meetings',
  supplierQuotes: 'supplier-quotes',
  facilities: 'general/facilities',
  financialStatements: 'accounting/building-financialstatements',
  bankStatements: 'building-bank-statements',
  invoices: 'invoices',
  residentsReceipts: 'residents-receipts',
  residentsBankStatements: 'residents-bank-statements',
  financial: 'accounting/financial',
  generics: 'general/generics',
  contracts: 'contracts',
  leases: 'general/leases',
  employees: 'employees',
  shipmentSlips: 'shipment-slips',
  accidents: 'general/accidents',
  legalPractices: 'general/legalpractices',
  residentsPersonal: 'residents-personal',
  security: 'security',
}

const getModelColumns = R.pipe(R.prop('columns'), R.map(R.prop('id')))

const BuildingDocumentsList = ({ buildingId: arcadiaId, type, lazyFetchFn }) => {
  const [fetchData, data] = lazyFetchFn()
  const [filteredData, setFilteredData] = useState([])
  const [filters, setFilters] = useState()

  // fetch on component did mount
  React.useEffect(() => {
    fetchData({ arcadiaId /* todo , ...(filters?.year && { year: filters.year }) */ })
  }, [lazyFetchFn, arcadiaId])

  const FIELDS_MAPPING = {
    // eslint-disable-next-line react/display-name
    id: (document) => (
      <Button
        variant="contained"
        size="small"
        onClick={() =>
          handleDownload(
            DOWNLOAD_PATH_MAP[type],
            arcadiaId,
            document.id,
            document.fileFormat,
            (document?.name || document?.supplier || 'download').replace(/[ &/\\#,+()$~%.'":*?<>{}]/g, ''),
          )
        }
      >
        <Download fontSize="small" color="white" />
      </Button>
    ),
    filesize: (document) => (document?.filesize ? formatBytes(document.filesize) : ''),
  }

  React.useEffect(() => {
    const rawData = R.compose(R.flatten, R.values, R.defaultTo({}))(data?.data)
    const filterFields = R.zipObj(
      getModelColumns(MODELS_MAP[type]),
      R.pipe(R.prop('columns'), R.pluck('filter'))(MODELS_MAP[type]),
    )
    setFilteredData(
      rawData.filter((record) => {
        const entries = Object.entries(filters)
        let isValid = true
        entries.forEach(([field, value]) => {
          const filterField = filterFields[field]
          if (!R.isNil(value) && !filterField?.serverSide) {
            if (filterField.type === 'dateRange') {
              const recordDate = dayjs(record[field])
              isValid = isValid && recordDate.isAfter(dayjs(value[0])) && recordDate.isBefore(dayjs(value[1]))
            } else {
              isValid = isValid && record[field]?.toString().toLowerCase().includes(value.toString().toLowerCase())
            }
          }
        })
        return isValid
      }),
    )
  }, [data?.data, filters])

  return (
    <div>
      <Margin bottom="2rem">
        <DocumentsSearchForm model={MODELS_MAP[type]} onSearch={setFilters} />
      </Margin>
      <DataTable
        name={`building-documents-${type}`}
        data={filteredData}
        model={MODELS_MAP[type]}
        listDisplay={getModelColumns(MODELS_MAP[type])}
        fieldsMapping={FIELDS_MAPPING}
        loading={data.isFetching}
        noExport
      />
    </div>
  )
}

BuildingDocumentsList.propTypes = {
  buildingId: PropTypes.string,
  lazyFetchFn: PropTypes.func,
  type: PropTypes.string,
}

export default BuildingDocumentsList
