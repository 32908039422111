import React from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'

const FieldTypeSelect = ({ value, ...other }) => {
  const { t } = useTranslation()

  const priorities = [
    { value: 0, label: t(`tms:fields.type.text`) },
    { value: 1, label: t('tms:fields.type.textarea') },
    { value: 2, label: t('tms:fields.type.select') },
    { value: 3, label: t('tms:fields.type.date') },
    { value: 4, label: t('tms:fields.type.attachment') },
    { value: 5, label: t('tms:fields.type.resident') },
    { value: 6, label: t('tms:fields.type.employee') },
    { value: 7, label: t('tms:fields.type.supplier') },
    { value: 8, label: t('tms:fields.type.url') },
    { value: 9, label: t('tms:fields.type.utilities') },
    { value: 10, label: t('tms:fields.type.building') },
  ]

  return <Dropdown width="auto" options={priorities} labelKey="label" valueKey="value" value={value} {...other} />
}

export default FieldTypeSelect

FieldTypeSelect.propTypes = {
  ...FieldType,
}
