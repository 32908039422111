/**
 * Default formatters for share
 * logic between all app components
 */
import { Check } from '@mui/icons-material'
import dayjs from 'dayjs'
import i18next from 'i18next'

export const formatForeignKeyValue = (value) => {
  return value?.metadata?.name || value?.name
}

export const formatBoolean = (value) => {
  return value ? <Check color="success" /> : '-'
}

export const formatDate = (date, format) => {
  if (!date) return null
  return dayjs(date).format(format || 'L')
}

export const formatCurrency = (value) => {
  if (Number(value) === 'NaN') return value
  return new Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(value)
}

export const formatter = (value, type) => {
  let formattedValue = value

  if (Array.isArray(value)) {
    formattedValue = value.map((v) => formatter(v, type))
  }

  // choose right formatter
  switch (type) {
    case 'currency':
      formattedValue = formatCurrency(value)
      break
    case 'date':
      formattedValue = formatDate(value)
      break
    case 'boolean':
      formattedValue = formatBoolean(value)
      break
    case 'foreign_key':
      formattedValue = formatForeignKeyValue(value)
      break
  }

  // if value is missing add placeholder
  if (!formattedValue) formattedValue = '-'

  return formattedValue
}
