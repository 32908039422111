import { Button } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTable from '@Common/Components/DataTable'
import { formatBytes } from '@Common/Utils/Strings'
import Document from '@Documents/Models/Document'
import InvoiceDocument from '@Documents/Models/InvoiceDocument'
import { handleDownload } from '@Documents/Utils/DownloadDocuments'

const DocumentsList = ({ type, lazyFetchFn, fetchId }) => {
  const { t } = useTranslation()
  const [fetchData, data] = lazyFetchFn()

  // fetch on component did mount
  React.useEffect(() => {
    fetchData({ arcadiaId: fetchId })
  }, [lazyFetchFn, fetchId])

  const LIST_DISPLAY =
    type === 'invoices'
      ? ['supplier', 'invoiceNumber', 'documentDate', 'amount', 'fileSize', 'id']
      : ['name', 'year', 'filesize', 'id']

  const FIELDS_MAPPING = {
    // eslint-disable-next-line react/display-name
    id: (document) => (
      <Button
        disableElevation
        variant="contained"
        color="secondary"
        sx={{ borderRadius: '20px', padding: '3px 12px 3px 12px', textTransform: 'none' }}
        onClick={() =>
          handleDownload(
            type,
            fetchId,
            document.id,
            document.fileFormat,
            (document?.name || document?.supplier || 'download')
              .replace(/ /g, '_')
              .replace(/[ &/\\#,+()$~%.'":*?<>{}]/g, ''),
          )
        }
      >
        {t('myHome:fields.Download')}
      </Button>
    ),
    year: (document) => (document?.documentDate ? dayjs(document?.documentDate).year() : ''),
    filesize: (document) => (document?.filesize ? formatBytes(document.filesize) : ''),
  }

  return (
    <DataTable
      name={`documents-${type}`}
      data={R.compose(R.flatten, R.values, R.defaultTo({}))(data?.data)}
      model={type === 'invoices' ? InvoiceDocument : Document}
      sortField="year"
      sortDirection="desc"
      listDisplay={LIST_DISPLAY}
      fieldsMapping={FIELDS_MAPPING}
      loading={data.isFetching}
      noExport
      noSettings
    />
  )
}

DocumentsList.propTypes = {
  type: PropTypes.string,
  lazyFetchFn: PropTypes.func.isRequired,
  fetchId: PropTypes.string.isRequired,
}

export default DocumentsList
