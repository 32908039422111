import MuiDateRangePicker from '@mui/lab/DateRangePicker'
import { TextField } from '@mui/material'
import React from 'react'

import { Box } from '@Common/Components/Styles'

import { FieldType } from '../Types/Field'

const DateRangePicker = ({ value, onChange, maxDate, minDate, startText, endText }) => {
  return (
    <MuiDateRangePicker
      value={value}
      inputFormat='DD/MM/YYYY'
      maxDate={maxDate}
      minDate={minDate}
      startText={startText}
      endText={endText}
      onChange={(newValue) => {
        onChange(newValue)
      }}
      renderInput={(startProps, endProps) => (
        <>
          <Box pad="0 0.5rem 0 0">
            <TextField {...startProps} variant="standard" />
          </Box>
          <Box pad="0 0 0 0.5rem">
            <TextField {...endProps} variant="standard" />
          </Box>
        </>
      )}
    />
  )
}

DateRangePicker.propTypes = {
  ...FieldType,
}

export default DateRangePicker
