import { Box, Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import { ItemContainer } from '@Common/Components/ItemContainer'
import Loader from '@Common/Components/Loader'
import { Page } from '@Common/Components/Page'
import { ItemCard } from '@Common/Components/SectionCard'
import { TabContainer, TabPanel } from '@Common/Components/TabContainer'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { api } from '@Core/Services/Api'
import { SupplierActions } from '@Supplier/Components/SupplierCompanyActions'
import { SupplierCompanyDetailAnagrafic } from '@Supplier/Components/SupplierCompanyDetailAnagrafic'
import { SupplierCompany } from '@Supplier/Models/SupplierCompany'

import { CondoSupplierCompanyDetailView } from './CondoSupplierCompanyDetailView'
import { SupplierBranchListView } from './SupplierBranchListView'
import { SupplierReferentListView } from './SupplierReferentListView'

export const SupplierCompanyDetailView = () => {
  const { buildingId, supplierId } = useParams()
  const { t } = useTranslation()

  const { data, isLoading, error } = api.useGetItemQuery(`/condo/bicert/suppliers/${supplierId}`)

  const [isBuildingDetail, setIsBuildingDetail] = useState(false)

  const baseBreadcrumbs = getCondoBreadcrumbFromId(buildingId)
  const breadcrumbItem = [
    {
      label: t('common:navigation.SupplierManagement'),
    },
    {
      label: data?.name,
      Icon: !buildingId && 'EngineeringIcon',
    },
  ]

  useBreadcrumbs(
    isBuildingDetail ? [...baseBreadcrumbs, ...breadcrumbItem] : breadcrumbItem,
    isBuildingDetail ? baseBreadcrumbs : {},
  )

  useEffect(() => {
    if (buildingId) {
      setIsBuildingDetail(true)
    }
  }, [buildingId])

  if (isLoading) {
    return <Loader />
  }

  if (isBuildingDetail) {
    return <CondoSupplierCompanyDetailView supplierData={data} />
  }

  return (
    <Page loader={isLoading} error={error}>
      <Stack>
        <ItemContainer item={data} model={SupplierCompany}>
          <Stack direction="row">
            <Box flexGrow={1}>
              <Typography variant="h5">
                <strong>{data?.name}</strong>
              </Typography>
            </Box>

            <SupplierActions />
          </Stack>
        </ItemContainer>

        <TabContainer>
          <TabPanel title="Anagrafica">
            <Grid spacing={3} container>
              <Grid item md={12}>
                <SupplierCompanyDetailAnagrafic item={data} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel title="Sedi">
            <ItemCard>
              <SupplierBranchListView />
            </ItemCard>
          </TabPanel>
          <TabPanel title="Referenti">
            <ItemCard>
              <SupplierReferentListView />
            </ItemCard>
          </TabPanel>
        </TabContainer>
      </Stack>
    </Page>
  )
}
