import * as React from 'react'

const MeetingsIcon = (props) => (
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="-24 -24 280 280"
    style={{
      enableBackground: 'new 0 0 280 280',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.stmeet1{fill:#4071b6}'}</style>
    <path
      d="M295 126 176.6 43.5c-4.5-3.3-12-3.3-16.5 0L41.7 126c-4.5 3.3-4.5 8.3 0 11.5 4.5 3.3 12 3.3 16.5 0L168.4 61l110.1 76.5c2.3 1.5 5.3 2.5 8.3 2.5 3 0 6-.7 8.3-2.5 4.4-3.3 4.4-8.5-.1-11.5z"
      style={{
        fill: '#ffca0c',
      }}
      transform="translate(-34.952 -37.393) scale(.91142)"
    />
    <path
      className="stmeet1"
      d="M214.4 260.5h-92.6v-24.8c0-19.8 12-37 30.5-44 1.3-.5 3-1.8 3-2.8 0-1-1.5-2.5-2.5-3.2-11.8-7-17.3-19-15.8-31.3 1.8-13.5 11.8-24.3 25-26.8 17-3.2 34.3 7.5 37.3 26 2 13.5-3.8 25.3-15.5 32.3-1.3.8-2.5 2-2.5 3.3 0 .7 1.8 2 2.8 2.5 16.3 6.5 26.3 18.3 29.8 35.5 1 4.5.5 9 .8 13.8-.3 6.2-.3 12.7-.3 19.5z"
      transform="translate(-34.952 -37.393) scale(.91142)"
    />
    <path
      className="stmeet1"
      d="M141.1 188.7c-6.3 3-11.8 7-16.5 12-8.3 9.3-13 20-13.3 32.5-.3 8 0 16.3 0 24.3v3h-30v-12c0-6.2-.3-12.8 1.5-19C86.5 217.2 94.3 209 106 204c1.3-.5 3-1.8 3-2.8s-1.3-2.5-2.5-3.2c-9.8-5.8-14-16.3-12.8-24.8 1.8-12.5 10-21 21-22.5 3-.5 6.3 0 9.3 0 2.8 0 2.5 1.5 2.3 3.5-1.3 9.5 1 18 6.5 25.5 2.3 3.3 5.3 5.8 8.3 9zM195.9 188.7c6.3 3 11.8 7 16.5 12 8.3 9.3 13 20 13.3 32.5.3 8 0 16.3 0 24.3v3h30v-12c0-6.2.3-12.8-1.5-19-3.8-12.3-11.5-20.5-23.3-25.5-1.3-.5-3-1.8-3-2.8s1.3-2.5 2.5-3.2c9.8-5.8 14-16.3 12.8-24.8-1.8-12.5-10-21-21-22.5-3-.5-6.3 0-9.3 0-2.8 0-2.5 1.5-2.3 3.5 1.3 9.5-1 18-6.5 25.5-2.4 3.3-5.5 5.8-8.2 9z"
      transform="translate(-34.952 -37.393) scale(.91142)"
    />
  </svg>
)

export default MeetingsIcon
