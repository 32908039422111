import SendIcon from '@mui/icons-material/Send'
import { IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import WebSocket from '@Common/Components/WebSocket'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useForm } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import config from '@Config'
import { getDisplayName } from '@Residents/Utils'
import { ReportType } from '@Tickets/Models/Report'
import { ReportMessageType } from '@Tickets/Models/ReportMessage'

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 0.2s width linear;
`

const ChatBody = styled.div`
  flex-grow: 1;
  background: ${({ theme: { palette } }) => palette.lightTwo.main};
  padding: 1rem;
  overflow: auto;
`

const ChatFooter = styled.div`
  display: flex;
  background: ${({ theme: { palette } }) => palette.chatItems.main};
  padding: 0.5rem 1rem;
  align-items: flex-end;
`

const ChatReplyField = styled(TextField)`
  flex-grow: 1;
  margin-right: 1rem;
`

const ChatMessage = styled.div`
  background: ${({ theme: { palette } }) => palette.chatItems.main};
  padding: 1rem;
  border-radius: 20px;
  max-width: 70%;
  min-width: 40%;
  transition: 0.2s width linear;
  margin-bottom: 1rem;
`

export const ReportChat = ({ report, isResident }) => {
  const { t } = useTranslation()
  const reportChatPath = config.wsUrls.reportChat.replace(':reportId', report.id)
  const [messages, setMessages] = useState([])
  const isFromReportResident = (message) => message.sender === report.userObj.id
  const chatEndRef = useRef()
  const { fields, setField } = useForm({ reply: '' }, true)
  const wsRef = useRef()

  const handleNewMessage = (event) => {
    Logger.debug('WS event', event)
    switch (event.type) {
      case 'chat_history':
        setMessages(event.messages)
        break
      case 'chat_message':
        setMessages((prev) => [...prev, event.message])
        setField('reply')('')
        break
    }
  }

  const handleError = (err) => {
    Logger.error('WS error', err)
  }

  const handleReadMessages = () => {
    wsRef.current.sendJson({ type: 'read_messages' })
  }

  const handleSubmit = () => {
    wsRef.current.sendJson({ type: 'chat_message', message: fields.reply })
  }

  const formatMessage = (message) =>
    message.split('\n').map((row, idx) => (
      <span key={idx}>
        {row}
        <br />
      </span>
    ))

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <ChatContainer>
      <ChatBody>
        {messages.map((message) => (
          <Box
            key={message.id}
            direction={isResident ? 'row-reverse' : 'row'}
            justify={isFromReportResident(message) ? 'flex-start' : 'flex-end'}
          >
            <ChatMessage>
              <Typography fontSize={12} color="neutralOne.main">
                {isFromReportResident(message) ? getDisplayName(report.anagraphic) : message.sender_name}
              </Typography>
              <Typography py={1}>{formatMessage(message.message)}</Typography>
              <Typography fontSize={12} textAlign="right" color="neutralOne.main">
                {dayjs(message.created).format('DD/MM/YYYY HH:mm')}
              </Typography>
            </ChatMessage>
          </Box>
        ))}
        <div ref={chatEndRef} />
      </ChatBody>
      <ChatFooter>
        <ChatReplyField
          required
          placeholder={t('tickets:ui.WriteAMessage')}
          variant="standard"
          multiline
          value={fields.reply}
          maxRows={5}
          disabled={report.status === 2}
          onChange={setStateFromEvent(setField('reply'))}
        />
        <IconButton
          color="primary"
          aria-label="reply"
          size="medium"
          disabled={!fields.reply || report.status === 2}
          onClick={handleSubmit}
        >
          <SendIcon />
        </IconButton>
      </ChatFooter>
      <WebSocket
        ref={wsRef}
        name="report-chat"
        path={reportChatPath}
        onError={handleError}
        onMessage={handleNewMessage}
        onOpen={handleReadMessages}
      />
    </ChatContainer>
  )
}

ReportChat.propTypes = {
  messages: PropTypes.arrayOf(ReportMessageType),
  onSubmit: PropTypes.func,
  report: ReportType,
  isResident: PropTypes.bool,
}
