import i18next from 'i18next'
import PropTypes from 'prop-types'

import { TaskType } from '@Tms/Models/Task'

export const TaskCategoryParentType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const TaskCategoryListItemType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  parent: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isBuildingEnabled: PropTypes.bool,
  isResidentEnabled: PropTypes.bool,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string.isRequired,
  hasTaskFlows: PropTypes.bool,
  openPrivileges: PropTypes.arrayOf(PropTypes.number),
})

const TaskCategoryDraft = PropTypes.shape({
  payload: PropTypes.object,
  taskCategory: PropTypes.number,
  created: PropTypes.string,
  modified: PropTypes.string,
})

export const TaskCategoryType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  parent: PropTypes.number, // parent category id (write only)
  isActive: PropTypes.bool.isRequired,
  parentObj: TaskCategoryParentType, // parent category expanded (read only)
  isBuildingEnabled: PropTypes.bool,
  isResidentEnabled: PropTypes.bool,
  isAdminSelectEnabled: PropTypes.bool,
  openPrivileges: PropTypes.arrayOf(PropTypes.number),
  mainTask: TaskType,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string.isRequired,
  draft: TaskCategoryDraft,
  hasTaskFlows: PropTypes.bool,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('tms:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'name',
      label: i18next.t('tms:fields.name'),
    },
    {
      id: 'parent.name',
      label: i18next.t('tms:fields.parent'),
    },
    {
      id: 'created',
      label: i18next.t('tms:fields.created'),
      date: true,
    },
    {
      id: 'modified',
      label: i18next.t('tms:fields.modified'),
      date: true,
    },
    {
      id: 'isActive',
      label: i18next.t('tms:fields.status'),
    },
  ],
}
