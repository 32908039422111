import i18next from 'i18next'
import PropTypes from 'prop-types'

export const InstallmentType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  descrizione: PropTypes.string,
  importo: PropTypes.number,
  residuo: PropTypes.number,
  incassato: PropTypes.number,
  scadenza: PropTypes.string,
  status: PropTypes.node,
  canPay: PropTypes.bool,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'descrizione',
      label: i18next.t('myHome:fields.Description'),
    },
    {
      id: 'importo',
      label: i18next.t('myHome:fields.Amount'),
      numeric: true,
      currency: true,
    },
    {
      id: 'residuo',
      label: i18next.t('myHome:fields.Residue'),
      numeric: true,
    },
    {
      id: 'incassato',
      label: i18next.t('myHome:fields.Paid'),
      numeric: true,
    },
    {
      id: 'scadenza',
      label: i18next.t('myHome:fields.Deadline'),
      date: true,
      numeric: true,
    },
    {
      id: 'status',
      label: i18next.t('myHome:fields.Status'),
      centered: true,
    },
    {
      id: 'canPay',
      label: i18next.t('myHome:fields.CanPay'),
      centered: true,
    },
  ],
}
