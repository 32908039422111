import PropTypes from 'prop-types'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Email, Phone, MailLock, Fax, PhoneAndroid, HourglassFull } from '@mui/icons-material'
import { isPhoneContact, isEmailContact, isPecContact, isFaxContact, isMobileContact, isH24Contact } from '../../Common/Utils/Enums'

const ResidentContacts = ({contacts}) => {
  return (
    <List>
      {contacts.map((contact, idx) => (
      <ListItem key={idx}>
        <ListItemIcon>
          {isPhoneContact(contact.tipo.id) && <Phone />}
          {isEmailContact(contact.tipo.id) && <Email />}
          {isPecContact(contact.tipo.id) && <MailLock />}
          {isFaxContact(contact.tipo.id) && <Fax />}
          {isMobileContact(contact.tipo.id) && <PhoneAndroid />}
          {isH24Contact(contact.tipo.id) && <HourglassFull />}
        </ListItemIcon>
        <ListItemText primary={contact.riferimento}></ListItemText>
      </ListItem>
      ))}
    </List>
  )
}

ResidentContacts.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      tipo: PropTypes.shape({
        id: PropTypes.number.isRequired,
        displayValue: PropTypes.string,
      }),
      riferimento: PropTypes.string.isRequired,
      pubblico: PropTypes.bool,
    }),
  ),
}

export default ResidentContacts
