import * as R from 'ramda'

export const buildCategoriesPaths = (categories) => {
  const map = R.groupBy(R.prop('parent'))
  return mapIterator(map(categories), null, '')
}

const mapIterator = (map, index, string) => {
  let result = []
  for (let category in map[index]) {
    let temporary = {}
    for (let attr in map[index][category]) {
      if (attr === 'name') {
        temporary[attr] = string + map[index][category][attr]
      } else {
        temporary[attr] = map[index][category][attr]
      }
    }
    result.push(temporary)
    result = result.concat(mapIterator(map, map[index][category].id, temporary.name + '/'))
  }
  return result
}
