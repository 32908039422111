import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ROLES } from '@Auth/Costants'
import Employee, { EmployeeType } from '@Auth/Models/Employee'
import { useEmployeesQuery } from '@Auth/Services/Api'
import DataTable from '@Common/Components/DataTable'

const DISPLAY_COLUMNS = ['id', 'user.username', 'firstName', 'lastName', 'user.isActive']
const SEARCH_FIELDS = ['firstName', 'lastName', 'user.username']
const qs = {
  base: {
    user__groups__id: ROLES.JUNIOR_ADMIN,
    jradmin_branches__empty: true,
  },
}

const JuniorAdminsList = ({ branchJuniorAdmins, selected, onSelect }) => {
  const { t } = useTranslation()
  const { data, isFetching } = useEmployeesQuery(qs)
  const [availableJuniorAdmins, setAvailableJuniorAdmins] = React.useState([])

  React.useMemo(() => {
    if (data?.results.length || branchJuniorAdmins.length) {
      const availableUnique = R.uniqBy(R.prop('id'), [...branchJuniorAdmins, ...R.defaultTo([], data?.results)])
      setAvailableJuniorAdmins(availableUnique)
    }
  }, [data?.results, branchJuniorAdmins])

  React.useEffect(() => onSelect(branchJuniorAdmins), [availableJuniorAdmins])

  return (
    <DataTable
      name="branch-junior-admin"
      selectable
      data={availableJuniorAdmins}
      model={Employee}
      sortField="id"
      sortDirection="desc"
      listDisplay={DISPLAY_COLUMNS}
      searchFields={SEARCH_FIELDS}
      selected={selected}
      onSelect={onSelect}
      loading={isFetching}
      customEmptyText={t('branches:ui.NoJuniorAdminsAvailable')}
      noBulkSelection
      noExport
    />
  )
}

JuniorAdminsList.propTypes = {
  branchJuniorAdmins: PropTypes.arrayOf(EmployeeType),
  selected: PropTypes.arrayOf(EmployeeType),
  onSelect: PropTypes.func.isRequired,
}

export default JuniorAdminsList
