import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { Box } from '@Common/Components/Styles'
import { useConfirm } from '@Common/Utils/Hooks'

import Label from '../../Models/Label'
import { LabelPermissions } from '../../Permissions'
import { useServiceLabelsQuery } from '../../Services/Api'

const ColorSquare = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color || 'transparent'};
  margin-right: 0.5rem;
  border-radius: 3px;
`

const DISPLAY_COLUMNS = ['id', 'name', 'color']

const SEARCH_FIELDS = ['name']

const FIELDS_MAPPING = {
  //eslint-disable-next-line
  color: (label) => (
    <Box direction="row" align="center">
      <ColorSquare color={label.color} /> {label.color}
    </Box>
  ),
}

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    qs.qsAdditions.name__icontains = qs.qsAdditions.search
    delete qs.qsAdditions.search
  }

  return qs
}

const LabelList = ({ onEdit, onDelete }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-label'),
      offset: 0,
      ordering: '-id',
    },
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useServiceLabelsQuery(convertSearchQuery(qs))

  const handleDeleteLabel = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteLabel)
  const bulkActions = React.useMemo(() => [{ id: 'DELETE', label: t('services:ui.DeleteSelectedLabel') }], [])
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: LabelPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: LabelPermissions.delete,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, labels) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('services:ui.DeleteLabelsConfirmationTitle'),
          t('services:ui.DeleteLabelConfirmationText', { count: selected.length }),
          { selected: selected, field: 'name' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(labels)
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      <DataTable
        name="admin-labels"
        selectable
        qs={qs}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Label}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        fieldsMapping={FIELDS_MAPPING}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        bulkActions={bulkActions}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      />
      {DeleteConfirm}
    </div>
  )
}

LabelList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default LabelList
