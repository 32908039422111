import { Button, Tab, Tabs, Tooltip, useMediaQuery } from '@mui/material'
import MuiBox from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { ifElse, pluck } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useBranchesQuery } from '@Branches/Services/Api'
import Card from '@Common/Components/Card'
import { FixedColumnLeft, FixedColumnRight } from '@Common/Components/FixedColumn'
import FixedFooter from '@Common/Components/FixedFooter'
import Loader from '@Common/Components/Loader'
import { Margin } from '@Common/Components/Styles'
import { TabPanel } from '@Common/Components/TabPanel'
import { responseStringError } from '@Common/Utils/Api'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { isBranchEmployee } from '@Common/Utils/Permissions'
import { isValidNumber } from '@Common/Utils/Validation'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { selectAdminSidebar } from '@Core/Redux/Ui'
import { SectionTitle as _SectionTitle } from '@Tms/Components/Styled'
import TaskActivityList from '@Tms/Components/TaskActivityList'
import TaskFlowFields from '@Tms/Components/TaskFlowFields'
import TaskFlowIndex from '@Tms/Components/TaskFlowIndex'
import TaskFlowInfographic from '@Tms/Components/TaskFlowInfographic'
import UpdateTaskForm from '@Tms/Forms/UpdateTaskForm'
import useUpdateTaskForm from '@Tms/Forms/UpdateTaskForm/useUpdateTaskForm'
import { RunningTaskType } from '@Tms/Models/RunningTask'
import { useActivateProcessingMutation, useTaskQuery, useUpdateTaskMutation } from '@Tms/Services/Api'
import { canAccessAllTasks, TASK_FIELD_TYPE_IDS } from '@Tms/Utils'

const Main = styled.main`
  height: 100%;
  padding: 16px 0 0;
`

const SectionTitle = styled(_SectionTitle)`
  padding-left: 1rem;
`

const taskDetailTop =
  60 /* header height */ + 52 /* breadcrumbs height */ + 28.6 /* Title height */ + 16 /* container padding */ + 'px'

const validateRequiredUtilities = (utilities) => {
  return (
    isValidNumber(utilities.powerBillingCycle) &&
    utilities.powerLastBillDate &&
    (utilities.noGasUtility || (isValidNumber(utilities.gasBillingCycle) && utilities.gasLastBillDate)) &&
    (utilities.noWaterUtility || (isValidNumber(utilities.waterBillingCycle) && utilities.waterLastBillDate))
  )
}

const isCustomFieldEmpty = (template, field) => {
  switch (template.type) {
    case TASK_FIELD_TYPE_IDS.textarea:
    case TASK_FIELD_TYPE_IDS.text:
      return !field.textValue
    case TASK_FIELD_TYPE_IDS.select:
      return !field.choice
    case TASK_FIELD_TYPE_IDS.date:
      return !field.dateValue
    case TASK_FIELD_TYPE_IDS.attachment:
      return !field.id && Array.isArray(field.file) && field.file?.length < 1
    case TASK_FIELD_TYPE_IDS.resident:
      return !field.resident
    case TASK_FIELD_TYPE_IDS.employee:
      return !field.employee
    case TASK_FIELD_TYPE_IDS.supplier:
      return !field.suppliers || (Array.isArray(field.suppliers) && field.suppliers.length < 1)
    case TASK_FIELD_TYPE_IDS.url:
      return !field.urlValue
    case TASK_FIELD_TYPE_IDS.utilities:
      return !validateRequiredUtilities(field.utilities)
    case TASK_FIELD_TYPE_IDS.building:
      return !field.building
  }
}

const Footer = ({
  task: {
    isCompleted,
    isProcessing,
    taskTemplateObj: { fields: fieldTemplates },
  },
  sidebarWidth,
  onCancel,
  onSubmit,
  onActivateProcessing,
  disableSubmit,
}) => {
  const { t } = useTranslation()
  const { watch, clearErrors } = useFormContext()
  const fields = watch('customFields')
  const [blockSaveAndComplete, setBlockSaveAndComplete] = useState(false)

  useEffect(() => {
    logger.debug('fields', fields)
    if (fieldTemplates.length < 1) {
      // there are not custom fields
      setBlockSaveAndComplete(false)
      return
    }
    if (fields) {
      setBlockSaveAndComplete(
        fieldTemplates.find((f) => {
          if (!f.isRequired) {
            return false
          }
          const isEmpty = isCustomFieldEmpty(f, fields[f.id])
          logger.debug(isEmpty)
          return isEmpty
        }) !== undefined,
      )
    } else {
      setBlockSaveAndComplete(true)
    }
  }, [JSON.stringify(fields)])

  const saveAndCompleteFn = onSubmit(true)
  const saveFn = onSubmit()

  const handleSubmit = (isCompleted) => () => {
    clearErrors()
    return isCompleted ? saveAndCompleteFn() : saveFn()
  }

  return (
    <FixedFooter sidebarWidth={sidebarWidth}>
      <Button onClick={onCancel} key="cancel" color="darkThree">
        {t('common:actions.Cancel')}
      </Button>
      <Button disabled={disableSubmit} onClick={handleSubmit()} key="submit" color="primary">
        {t('common:actions.Save')}
      </Button>
      {!isProcessing && !isCompleted && (
        <Button onClick={onActivateProcessing} key="activate-is-processing" color="primary">
          {t('tms:actions.ActivateIsProcessing')}
        </Button>
      )}
      {isProcessing && (
        <Tooltip title={blockSaveAndComplete ? t('tms:info.CompileBeforeComplete') : ''} placement="top">
          <span>
            <Button
              disabled={blockSaveAndComplete || disableSubmit}
              onClick={handleSubmit(true)}
              key="submit-and-complete"
              color="primary"
            >
              {t('tms:actions.SaveAndComplete')}
            </Button>
          </span>
        </Tooltip>
      )}
    </FixedFooter>
  )
}

Footer.propTypes = {
  task: RunningTaskType,
  sidebarWidth: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onActivateProcessing: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
}

const TaskDetailView = () => {
  const { t } = useTranslation()
  const { taskId } = useParams()
  const user = useCurrentUser()
  const { data: task, isFetching } = useTaskQuery(taskId)
  const { data: branches } = useBranchesQuery()
  const [updateTask] = useUpdateTaskMutation()
  const [activateProcessing] = useActivateProcessingMutation()
  const [activeTab, setActiveTab] = useState(0)
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { expanded, expandedWidth, collapsedWidth } = useSelector(selectAdminSidebar)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useBreadcrumbs([
    { label: t('common:navigation.Tms'), path: makePath('tms.taskList'), Icon: 'Tms' },
    { label: t(`tms:ui.TaskDetail`) },
  ])

  const isTaskReadOnly = useCallback(
    (task) => {
      if (!task?.processingAt) {
        return true
      }
      if (canAccessAllTasks(user) || task?.assignedToObj?.user?.id === user.id) {
        return task?.isCompleted
      }
      if (isBranchEmployee(user)) {
        return task?.isCompleted || !pluck('id', branches?.result ?? []).includes(task?.taskFlowObj.branch.id)
      }
      if (task?.assignedToObj?.user?.role?.supervisor?.id === user.id) {
        return task?.isCompleted
      }
      return true
    },
    [user, branches],
  )

  const getSidebarWidth = ifElse(
    () => isSmallDevice,
    () => 0,
    () => (expanded ? expandedWidth : collapsedWidth),
  )

  const onCancel = () => history.push(makePath('tms.taskList'))
  const onSave = async (formData) => {
    try {
      const response = await updateTask({ id: taskId, formData }).unwrap()
      toast.success(t('tms:success.TaskUpdated', { name: formData.get('title') }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('tms:errors.UpdateTaskError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }
  const onActivateProcessing = async () => {
    try {
      const response = await activateProcessing({ id: taskId }).unwrap()
      toast.success(t('tms:success.TaskUpdated', { name: task?.title }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('tms:errors.UpdateTaskError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const { onSubmit, errors, control, register, ...methods } = useUpdateTaskForm(null, onSave, onCancel, setIsSubmitting)

  useEffect(() => {
    if (task?.taskFlowObj?.building) {
      const { building, buildingType } = task?.taskFlowObj
      methods.setValue('building', `${building.id}_${buildingType}`)
    }
  }, [task])

  return (
    <Main>
      {isSubmitting && <Loader overlay />}
      {withLoader(
        <FormProvider register={register} errors={errors} control={control} {...methods}>
          <SectionTitle>
            {task?.title}{' '}
            <Typography variant="span" color="neutralThree.main">
              ({task?.code})
            </Typography>
          </SectionTitle>
          <FixedColumnLeft top={taskDetailTop} footerHeight="70px" sidebarWidth={getSidebarWidth()}>
            <Margin bottom="1.5rem">
              <Card pad="1rem" variant="outlined">
                <TaskFlowFields task={task} />
              </Card>
            </Margin>
            <Card pad="1rem" variant="outlined">
              <UpdateTaskForm task={task} isTaskReadOnly={isTaskReadOnly(task)}></UpdateTaskForm>
            </Card>
          </FixedColumnLeft>
          <FixedColumnRight top={taskDetailTop} footerHeight="70px" sidebarWidth={getSidebarWidth()}>
            <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
              <Tabs
                variant="scrollable"
                value={activeTab}
                onChange={(_, idx) => setActiveTab(idx)}
                style={{ overflow: 'auto' }}
              >
                <Tab label={t('tms:ui.TasksIndex')} />
                <Tab label={t('tms:ui.TaskInfographic')} />
                <Tab label={t('tms:ui.TasksActivityLog')} />
              </Tabs>
            </MuiBox>
            <TabPanel activeIndex={activeTab} index={0}>
              <TaskFlowIndex task={task}></TaskFlowIndex>
            </TabPanel>
            <TabPanel activeIndex={activeTab} index={1}>
              <TaskFlowInfographic task={task}></TaskFlowInfographic>
            </TabPanel>
            <TabPanel activeIndex={activeTab} index={2}>
              <TaskActivityList task={task}></TaskActivityList>
            </TabPanel>
          </FixedColumnRight>
          <Footer
            disableSubmit={isTaskReadOnly(task)}
            sidebarWidth={getSidebarWidth()}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onActivateProcessing={onActivateProcessing}
            task={task}
          />
        </FormProvider>,
        taskId && (!task?.id || isFetching),
      )}
    </Main>
  )
}

TaskDetailView.propTypes = {}

export default TaskDetailView
