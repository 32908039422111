import { pluck } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import AnagraphicsList from '@Auth/Components/AnagraphicsList'
import { useCreateInvitationBulkMutation, useCreateInvitationMutation } from '@Auth/Services/Api'
import Loader from '@Common/Components/Loader'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { makePath } from '@Config'

const AdminAnagraphicsInvitationsView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [createInvitation] = useCreateInvitationMutation()
  const [createInvitationBulk] = useCreateInvitationBulkMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useBreadcrumbs([
    { label: t('common:navigation.Invitations'), path: makePath('admin.invitations'), Icon: 'Invitation' },
    { label: t('common:ui.Anagraphics') },
  ])

  const handleAnagraphicInvitation = async (anagraphic) => {
    setIsSubmitting(true)
    logger.debug('Anagraphic invitation submit, arcadiaId:', anagraphic.id)
    try {
      await createInvitation(anagraphic.id).unwrap()
      logger.debug('Anagraphic invitation success')
      toast.success(t('auth:success.InvitationCreated'))
      navigate(makePath('admin.invitations'))
    } catch (error) {
      setIsSubmitting(false)
      logger.debug('Anagraphic invitation failure', error)
      toast.error(t('auth:errors.CreateInvitationError', { error: responseStringError(error) }))
    }
  }

  const handleAnagraphicBulkInvitation = async (anagraphics) => {
    setIsSubmitting(true)
    const arcadiaIds = pluck('id', anagraphics)
    logger.debug('Anagraphic bulk invitation submit, arcadiaIds:', arcadiaIds)
    try {
      await createInvitationBulk({ arcadiaIds }).unwrap()
      logger.debug('Anagraphic bulk invitation success')
      toast.success(t('auth:success.InvitationBulkCreated'))
      navigate(makePath('admin.invitations'))
    } catch (error) {
      setIsSubmitting(false)
      logger.debug('Anagraphic bulk invitation failure', error)
      toast.error(t('auth:errors.CreateInvitationBulkError', { error: responseStringError(error) }))
    }
  }

  return (
    <div>
      {isSubmitting && <Loader overlay />}
      <AnagraphicsList
        onCreateInvitation={handleAnagraphicInvitation}
        onCreateBulkInvitation={handleAnagraphicBulkInvitation}
      />
    </div>
  )
}

export default AdminAnagraphicsInvitationsView
