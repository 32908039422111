/* eslint-disable react/prop-types */
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { SelectFieldV2 } from '@Common/Components/FormsV2/SelectFieldV2'
import Loader from '@Common/Components/Loader'
import { useMuiGrid } from '@Common/Utils/Hooks'
import { api } from '@Core/Services/Api'

export const SupplierServiceField = (props) => {
  const gridProps = useMuiGrid(props)
  const urlServiceTypes = '/condo/bicert/service-types'

  const form = useFormContext()

  const { data, isLoading } = api.useGetItemQuery(urlServiceTypes, filtersQs)

  let filtersQs = {
    limit: -1,
    fields: 'id,metadata',
  }

  if (props.ordering) {
    filtersQs = {
      ...filtersQs,
      ...{ ordering: props.ordering },
    }
  }

  const serviceActivity = form.watch('servicesActivity')

  useEffect(() => {
    if (!serviceActivity) form.setValue('services', null)
  }, [form.watch, serviceActivity])

  if (isLoading) return <Loader />

  const options = data?.results?.map((x) => x?.metadata || x) || []

  const optionsServiceActivity = options
    .map((x) => {
      return { id: x.description, name: x.description }
    })
    .filter((item, i, a) => a.findIndex((item2) => JSON.stringify(item2) === JSON.stringify(item)) === i)

  const getServices = () => {
    return options?.filter((x) => {
      if (serviceActivity && x.description === serviceActivity) {
        return x
      }
    })
  }

  return (
    <>
      <SelectFieldV2
        labelKey="name"
        valueKey="id"
        {...props}
        name="servicesActivity"
        ordering={'activity,name'}
        showDescription={true}
        searchable
        options={optionsServiceActivity}
        {...gridProps}
        multiple={false}
      />
      <SelectFieldV2
        labelKey="name"
        valueKey="id"
        {...props}
        name="services"
        ordering={'activity,name'}
        showDescription={true}
        searchable
        options={getServices()}
        disabled={!serviceActivity}
        multiple={true}
        {...gridProps}
      />
    </>
  )
}
