import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Controller } from 'react-hook-form'

import { FieldType } from '@Common/Types/ReactHookFormField'

const RadioGroupField = ({ name, control, options, disabled, ...other }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <RadioGroup {...field} {...other}>
        {options.map((option, i) => (
          <FormControlLabel key={i} value={option.value} label={option.label} control={<Radio />} disabled={disabled} />
        ))}
      </RadioGroup>
    )}
  />
)

RadioGroupField.propTypes = FieldType

export default RadioGroupField
