/* eslint-disable react/prop-types */

/**
 * This component picks the value of the inputs inside it
 * and forwards them to the items loader.
 *
 * This is to simplify filter management eg. above the tables
 */
import { Grid } from '@mui/material'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ItemContainer } from './ItemContainer'
import { ItemsLoaderContext } from './ItemsLoader'

export const FiltersForm = (props) => {
  const { children } = props

  const itemsLoader = useContext(ItemsLoaderContext)
  const filterForm = useForm({ defaultValues: itemsLoader.filters })
  const { watch } = filterForm

  useEffect(() => {
    // Set additional filters
    const subscription = watch((value) => itemsLoader.onFilterChange(value))
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <ItemContainer model={itemsLoader.model}>
      <FormProvider {...filterForm}>
        <Grid container rowSpacing={3} spacing={3} component="form" id="filters_table">
          {children}
        </Grid>
      </FormProvider>
    </ItemContainer>
  )
}
