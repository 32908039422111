import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

import StandardTimeRangeField from '@Common/Components/TimeRangeField'
import { FieldType } from '@Common/Types/ReactHookFormField'

const TimeRangeField = ({ name, control, rules, unitFieldName, ...other }) => {
  const { setValue, watch } = useFormContext()

  const handleChange = (onChange) => (minutes, unit) => {
    onChange(minutes)
    if (unitFieldName) {
      setValue(unitFieldName, unit)
    }
  }

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <StandardTimeRangeField
            onChange={handleChange(onChange)}
            value={value}
            defaultUnit={watch(unitFieldName) || 'd'}
            {...other}
          />
        )
      }}
    />
  )
}

TimeRangeField.propTypes = FieldType & { unitFieldName: PropTypes.bool }

export default TimeRangeField
