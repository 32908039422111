import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { FormControl } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '@Common/Components/Checkbox'
import DataTable from '@Common/Components/DataTable'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromCheckboxEvent, setStateFromEvent } from '@Common/Utils/Events'
import { TaskFieldChoice, TaskFieldChoiceType } from '@Tms/Models/TaskField'
import { TaskCategoryPermissions } from '@Tms/Permissions'
import logger from '@Common/Utils/Logger'

const DISPLAY_COLUMNS = ['value', 'isJustificationRequired', 'notesText']

const TaskFieldChoiceList = ({ choices, onAdd, onChange, onDelete }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState()

  useEffect(() => logger.debug(choices), [choices])

  const FIELD_MAPPING = useMemo(
    () => ({
      // eslint-disable-next-line react/display-name
      value: (choice) => (
        <FormControl>
          <TextField
            InputProps={{ sx: { fontSize: '0.85rem', lineHeight: 1 } }}
            value={choice.value}
            required
            onChange={setStateFromEvent(onChange('value', choice))}
            error={!!choice.error}
            helperText={choice.error}
          />
        </FormControl>
      ),
      // eslint-disable-next-line react/display-name
      isJustificationRequired: (choice) => (
        <Box align="center">
          <Checkbox
            sx={{ padding: 0 }}
            label=""
            checked={choice.isJustificationRequired}
            onChange={setStateFromCheckboxEvent(onChange('isJustificationRequired', choice))}
          />
        </Box>
      ),
      // eslint-disable-next-line react/display-name
      notesText: (choice) => (
        <FormControl>
          <TextField
            InputProps={{ sx: { fontSize: '0.85rem', lineHeight: 1 } }}
            value={choice.notesText}
            onChange={setStateFromEvent(onChange('notesText', choice))}
            error={!!choice.error}
            helperText={choice.error}
          />
        </FormControl>
      ),
    }),
    [choices],
  )

  const actions = React.useMemo(
    () => [
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: R.either(TaskCategoryPermissions.update, TaskCategoryPermissions.create),
      },
    ],
    [],
  )

  const handleAction = React.useCallback((_, choice) => onDelete(choice), [selected])

  return (
    <div>
      <Box margin="0 0 0.4rem" align="end">
        <Button size="small" disableElevation variant="contained" onClick={onAdd} startIcon={<AddIcon />}>
          {t('tms:actions.AddTaskFieldChoice')}
        </Button>
      </Box>
      <DataTable
        name="tms-task-field-choices"
        data={choices}
        model={TaskFieldChoice}
        sortField="id"
        sortDirection="asc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELD_MAPPING}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noExport
        noSettings
        noBulkSelection
      />
    </div>
  )
}

TaskFieldChoiceList.propTypes = {
  choices: PropTypes.arrayOf(TaskFieldChoiceType),
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default TaskFieldChoiceList
