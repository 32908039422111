/* eslint-disable react/prop-types */

/**
 * This component get item from the context
 * and display edit button. When user click over button
 * dialog is show with item form inside
 */
import EditIcon from '@mui/icons-material/Edit'
import { createElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonDialog } from '../ButtonDialog'
import { ItemContainerContext } from '../ItemContainer'

export const EditButton = (props) => {
  const { form, formProps, title, dialogTitle } = props
  const { t } = useTranslation()
  const { item, model } = useContext(ItemContainerContext)

  return (
    <ButtonDialog
      size="small"
      disableElevation
      variant="contained"
      sx={{ marginLeft: '.5rem' }}
      icon={<EditIcon />}
      title={title || `${t('common:actions.Edit')} ${t(`${model.name.toLowerCase()}:model.verbose_name`)}`}
      dialogTitle={dialogTitle}
    >
      {createElement(form || model.form, { defaultValues: item, ...formProps })}
    </ButtonDialog>
  )
}
