import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Box } from '@Common/Components/Styles'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { selectThemeMode } from '@Core/Redux/Ui'

const ButtonWrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
`

const Figure = styled.div`
  ${({ theme, ...props }) => css`
    background-color: ${theme.palette.lightFive.main};
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: relative;
    border: 3px solid ${props.selected ? theme.palette.secondary.main : 'transparent'};
    padding: 5px;
    background-clip: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const Image = styled.img`
  position: relative;
  width: 60px;
  height: 60px;
  object-fit: contain;
`

const Caption = styled.div`
  ${({ theme, ...props }) => css`
    color: ${props.selected ? theme.palette.secondary.main : theme.palette.accentOne.main};
  `}
  box-sizing: border-box;
  margin-top: 0.75rem;
  text-align: center;
  text-decoration: none;
  border-bottom: none;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
`

const navigateTo = (path, params = {}) => {
  return () => history.push(makePath(path, params))
}

const CategoryButton = ({ to, caption, selected, icon }) => {
  const themeMode = useSelector(selectThemeMode)

  return (
    <ButtonWrapper onClick={navigateTo(to.path, { service: to.category })}>
      <Figure themeMode={themeMode} selected={selected}>
        <Image src={icon} style={{ width: '100px', height: '100px' }} />
      </Figure>
      <Caption selected={selected}>{caption}</Caption>
    </ButtonWrapper>
  )
}

export default CategoryButton

CategoryButton.propTypes = {
  to: PropTypes.shape({
    path: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }),
  caption: PropTypes.string,
  icon: PropTypes.node,
  selected: PropTypes.bool,
}
