import { FormControl } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@Common/Components/Alert'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'

const ResidentNoteForm = ({ note, onClose, onSave }) => {
  const { t } = useTranslation()
  const title = t('customerCare:ui.AddNote')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { fields, setField, errors, setErrors } = useForm({ note: R.defaultTo('', note?.note) }, true)

  const submit = async () => {
    setIsSubmitting(true)
    logger.debug('ResidentNote form submission, fields:', fields)
    const { error, isSuccessful } = await onSave(fields)
    if (!isSuccessful && error?.status === 400) {
      setIsSubmitting(false)
      logger.debug('ResidentNote form submission api error', error)
      setErrors(convertServerErrors(error))
    } else {
      onClose()
    }
  }

  return (
    <Modal title={title} size="xs" onClose={onClose} onSubmit={submit} disableSubmit={!fields.note}>
      {isSubmitting && <Loader overlay />}
      <Box>
        {errors.__form && <Alert level="error">{errors.__form}</Alert>}
        <FormControl fullWidth>
          <TextField
            multiline
            rows={10}
            value={fields.note}
            label={t('customerCare:fields.note')}
            onChange={setStateFromEvent(setField('note'))}
            error={!!errors.note}
            helperText={errors.note}
          />
        </FormControl>
      </Box>
    </Modal>
  )
}

ResidentNoteForm.propTypes = {
  note: PropTypes.shape({
    note: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ResidentNoteForm
