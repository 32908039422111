import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { responseStringError } from '@Common/Utils/Api'
import CreteTaskForm from '@Tms/Forms/CreateTaskForm'
import { useCreateTaskFlowMutation } from '@Tms/Services/Api'

export const CreateTask = ({ defaultValues, buttonProps }) => {
  const { t } = useTranslation()
  const [taskForm, setTaskForm] = useState({ isOpen: false })
  const [createTaskFlow] = useCreateTaskFlowMutation()

  const handleOpenTmsTask = () => setTaskForm({ isOpen: true })
  const handleCloseForm = () => setTaskForm({ isOpen: false })
  const handleSave = async (formData) => {
    try {
      const response = await createTaskFlow(formData).unwrap()
      toast.success(t('tms:success.TaskCreated', { name: formData.get('title') }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('tms:errors.CreateTaskError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <>
      <Button {...buttonProps} onClick={handleOpenTmsTask}>
        {t('tms:ui.OpenTask')}
      </Button>
      {taskForm.isOpen && <CreteTaskForm defaultValues={defaultValues} onClose={handleCloseForm} onSave={handleSave} />}
    </>
  )
}

CreateTask.propTypes = {
  defaultValues: PropTypes.object,
  buttonProps: PropTypes.any,
}

export default CreateTask
