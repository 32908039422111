import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, useDebounce } from '@Common/Utils/Hooks'
import { useBuildingStructureQuery } from '@Buildings/Services/Api'
import { setStateFromEvent } from '@Common/Utils/Events'
import { Grid, FormControl } from '@mui/material'
import TextField from '@Common/Components/TextField'
import Dropdown from '@Common/Components/Dropdown'
// import styled from 'styled-components'
import * as R from 'ramda'

const InstallmentsSearchForm = ({ buildingArcadiaId, onSearch }) => {
  const isInitialMount = React.useRef(true) // avoid double request cause by onSearch call
  const { t } = useTranslation()
  const { data: structure } = useBuildingStructureQuery(buildingArcadiaId)
  const { fields, setField, setFields } = useForm(
    {
      edifice: '',
      stair: '',
      housingUnit: '',
      name: '',
    },
    true,
  )
  const debouncedFields = useDebounce(fields, 300)

  const edifices = structure?.edifici.map(R.pick(['id', 'descrizione'])) || [{ id: 1, descrizione: 'suca' }]
  const stairs = fields.edifice
    ? R.find(R.propEq('id', fields.edifice), structure.edifici).scale.map(R.pick(['id', 'descrizione']))
    : []
  const housingUnits = fields.stair
    ? R.find(R.propEq('id', fields.stair), R.find(R.propEq('id', fields.edifice), structure.edifici).scale).unita.map(
      R.pick(['id', 'descrizione']),
    )
    : []

  // just run on update, no mount to avoid calling onSearch if not needed
  // because it causes a new request to be performed (changing qsAdditions)
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      onSearch(fields)
    }
  }, [JSON.stringify(debouncedFields)])

  const handleEdificeChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('edifice'),
    R.mergeRight({ stair: '', housingUnit: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const handleStairChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('stair'),
    R.mergeRight({ housingUnit: '' }),
    R.mergeRight(fields),
    setFields,
  )

  return (
    <Grid container rowSpacing={3} spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth>
          <Dropdown
            options={edifices.map(R.pick(['id', 'descrizione']))}
            labelKey="descrizione"
            valueKey="id"
            onChange={handleEdificeChange}
            value={fields.edifice}
            label={t('buildings:ui.Edifice')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth>
          <Dropdown
            options={stairs.map(R.pick(['id', 'descrizione']))}
            labelKey="descrizione"
            valueKey="id"
            onChange={handleStairChange}
            value={fields.stair}
            label={t('buildings:ui.Stair')}
            helperText={fields.edifice ? null : t('buildings:ui.SelectEdifice')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth>
          <Dropdown
            options={housingUnits.map(R.pick(['id', 'descrizione']))}
            labelKey="descrizione"
            valueKey="id"
            onChange={setStateFromEvent(setField('housingUnit'))}
            value={fields.housingUnit}
            label={t('buildings:ui.HousingUnit')}
            helperText={fields.stair ? null : t('buildings:ui.SelectStair')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth>
          <TextField
            label={t('buildings:fields.registryName')}
            value={fields.name}
            onChange={setStateFromEvent(setField('name'))}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

InstallmentsSearchForm.propTypes = {
  buildingArcadiaId: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default InstallmentsSearchForm
