import { checkEmailAddress, checkPhoneNumber, checkRequired } from '@Common/Utils/Validation'
import * as R from 'ramda'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = ['username', 'phone']

  checkRequired(err, required, fields)
  checkEmailAddress(err, fields, 'username')
  checkPhoneNumber(err, fields, 'phone')

  setErrors(err)
  return R.isEmpty(err)
}
