import dayjs from 'dayjs'
import { defaultTo } from 'ramda'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { convertServerErrors } from '@Common/Utils/Form'
import logger from '@Common/Utils/Logger'

const useCreateTaskForm = (fields, onSave, onCancel, setIsSubmitting = null) => {
  const { control, setError, ...methods } = useForm()

  const setErrors = useCallback((errors) => {
    Object.entries(errors).map(([k, v]) => setError(k, { type: 'custom', message: v }))
    logger.debug(errors)
  }, [])

  const onInvalid = useCallback((errors) => {
    logger.debug('Task form submission, validation failed, errors:', errors)
  }, [])

  const onSubmit = useCallback(async (fields) => {
    logger.debug('Task form submission, validation passed, fields:', fields)

    const formData = new FormData()
    for (let key in fields) {
      switch (key) {
        case 'deadline':
          formData.append(`mainRunningTask.deadline`, fields[key]?.format('YYYY-MM-DDThh:mm'))
          break
        case 'assignedTo':
        case 'notes':
        case 'priority':
        case 'title':
          formData.append(`mainRunningTask.${key}`, defaultTo('', fields[key]))
          break
        case 'customFields':
          for (let customFieldKey in fields[key]) {
            formData.append(`mainRunningTask.fields[${customFieldKey}]taskFieldTemplate`, customFieldKey)
            for (let customValueKey in fields[key][customFieldKey]) {
              let customValue = fields[key][customFieldKey][customValueKey]
              let fieldKey = `mainRunningTask.fields[${customFieldKey}]${customValueKey}`
              switch (customValueKey) {
                case 'dateValue':
                  customValue = dayjs(customValue).isValid() ? dayjs(customValue).format('YYYY-MM-DD') : ''
                  formData.append(fieldKey, customValue)
                  break
                case 'file':
                  formData.append(fieldKey, defaultTo('', customValue[0]))
                  break
                case 'utilities':
                  for (let utilityValueKey in customValue) {
                    let utilityValue = customValue[utilityValueKey]
                    if (utilityValueKey.endsWith('LastBillDate')) {
                      utilityValue = dayjs(utilityValue).isValid() ? dayjs(utilityValue).format('YYYY-MM-DD') : ''
                    }
                    if (utilityValue) {
                      formData.append(`${fieldKey}.${utilityValueKey}`, defaultTo('', utilityValue))
                    } else {
                      // formData.delete(`mainRunningTask.fields[${customFieldKey}]taskFieldTemplate`)
                    }
                  }
                  break
                case 'building':
                  if (customValue) {
                    formData.append(`${fieldKey}_id`, defaultTo('', customValue.split('_')[0]))
                    formData.append(`${fieldKey}_type`, defaultTo('', customValue.split('_')[1]))
                  }
                  break
                case 'suppliers':
                  for (let supplierValueKey in customValue) {
                    formData.append(`${fieldKey}[${supplierValueKey}]`, customValue[supplierValueKey])
                  }
                  break
                default:
                  formData.append(fieldKey, defaultTo('', customValue))
              }
            }
          }
          break
        case 'building':
          if (fields[key]) {
            const buildingInfos = fields[key].split('_')
            formData.append('building_id', buildingInfos[0])
            formData.append('building_type', buildingInfos[1])
          }
          break
        default:
          formData.append(key, defaultTo('', fields[key]))
      }
    }

    if (setIsSubmitting) setIsSubmitting(true)
    const { error, isSuccessful } = await onSave(formData)
    if (!isSuccessful && error?.status === 400) {
      if (setIsSubmitting) setIsSubmitting(false)
      logger.debug('Task form submission api error', error)
      setErrors(convertServerErrors(error))
    } else {
      onCancel()
    }
  }, [])

  return {
    ...methods,
    control,
    errors: methods.formState.errors,
    onSubmit: methods.handleSubmit(onSubmit, onInvalid),
  }
}

export default useCreateTaskForm
