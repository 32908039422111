import { Box as MuiBox, Tab, Tabs } from '@mui/material'
import Grid from '@mui/material/Grid'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { defaultTo } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import EmployeeByRoleSelect from '@Auth/Components/EmployeeByRoleSelect'
import { useLazyEmployeesByRolesQuery } from '@Auth/Services/Api'
import BranchSelect from '@Branches/Components/BranchSelect'
import { selectActiveBranch } from '@Branches/Redux'
import { useBranchesQuery } from '@Branches/Services/Api'
import { useLazyBranchBuildingQuery } from '@Buildings/Services/Api'
import Alert from '@Common/Components/Alert'
import DatePickerField from '@Common/Components/Forms/DatePickerField'
import EditorField from '@Common/Components/Forms/EditorField'
import SelectField from '@Common/Components/Forms/SelectField'
import TextField from '@Common/Components/Forms/TextField'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { snakeToCamelCase } from '@Common/Utils/Strings'
import { useLazyCondoByIdQuery } from '@Condo/Services/Api'
import { apiList } from '@Core/Services/Api'
import ArcadiaAnagraphicAutocomplete from '@Residents/Components/ArcadiaAnagraphicAutocomplete'
import LabeledBox from '@Tms/Components/LabeledBox'
import TaskTreeInfographic from '@Tms/Components/TaskTreeInfographic'
import useCreateTaskForm from '@Tms/Forms/CreateTaskForm/useCreateTaskForm'
import BuildingSelect from '@Tms/Forms/Selects/BuildingSelect'
import CategorySelect from '@Tms/Forms/Selects/CategorySelect'
import PrioritySelect from '@Tms/Forms/Selects/PrioritySelect'
import TaskCustomField from '@Tms/Forms/TaskCustomField'
import { useLazyCategoryQuery } from '@Tms/Services/Api'
import { TASK_FIELD_TYPE_IDS } from '@Tms/Utils'

const CUSTOM_FIELDS_GRID_MAP = {
  [TASK_FIELD_TYPE_IDS.text]: 6,
  [TASK_FIELD_TYPE_IDS.textarea]: 12,
  [TASK_FIELD_TYPE_IDS.select]: 12,
  [TASK_FIELD_TYPE_IDS.date]: 6,
  [TASK_FIELD_TYPE_IDS.attachment]: 12,
  [TASK_FIELD_TYPE_IDS.resident]: 6,
  [TASK_FIELD_TYPE_IDS.employee]: 6,
  [TASK_FIELD_TYPE_IDS.supplier]: 12,
  [TASK_FIELD_TYPE_IDS.branchAdmin]: 6,
  [TASK_FIELD_TYPE_IDS.url]: 6,
  [TASK_FIELD_TYPE_IDS.utilities]: 12,
  [TASK_FIELD_TYPE_IDS.building]: 6,
}

const employeePickFn = ({ id, firstName, lastName }) => ({ id, label: firstName + ' ' + lastName })

const TabPanel = ({ children, activeIndex, index }) => {
  return (
    <div role="tabpanel" hidden={activeIndex !== index}>
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
}

const getActiveAdministrative = (branches, branchId) => {
  const branch = branches.find((b) => b.id === branchId)
  if (branch?.administrativeObj?.isOoo) {
    return branch?.secondaryAdministrative
  }
  return branch?.administrative
}

const CreteTaskForm = ({ onClose, onSave, defaultValues }) => {
  const { t } = useTranslation()
  const activeBranch = useSelector(selectActiveBranch)
  const title = t('tms:ui.NewTask')
  const required = t('common:validation.RequiredField')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const { data: branches } = apiList(useBranchesQuery()) // using this instead of detail query because it is already in cache
  const user = useCurrentUser()
  const [fetchData, { data: category, isFetching }] = useLazyCategoryQuery()
  const [branchAdmins, setBranchAdmins] = useState([])
  const [fetchEmployees] = useLazyEmployeesByRolesQuery()
  const [fetchBranchBuilding] = useLazyBranchBuildingQuery()
  const [fetchCondoBuilding] = useLazyCondoByIdQuery()
  const { onSubmit, errors, control, watch, reset, getValues, setValue, ...methods } = useCreateTaskForm(
    null,
    onSave,
    onClose,
    setIsSubmitting,
  )
  const categoryId = watch('taskCategory')
  const branchId = watch('branch')
  const buildingId = watch('building')

  const handleBranchChange = (onChange) => (branchId) => {
    const branch = branches.find((b) => b.id === branchId)
    setBranchAdmins([...branch?.seniorAdministrativesObjs.map(employeePickFn), employeePickFn(branch?.managerObj)])
    setValue('admin', null)
    setValue('building', null)
    onChange(branchId)
  }

  const getOwner = useCallback(async (buildingId, ownerType) => {
    const buildingInfos = buildingId.split('_')
    let building
    switch (buildingInfos[1]) {
      case 'branchbuilding':
        building = await fetchBranchBuilding(buildingInfos[0]).unwrap()
        break
      case 'building':
        building = await fetchCondoBuilding(buildingInfos[0]).unwrap()
        break
    }
    return building[snakeToCamelCase(ownerType)]
  }, [])

  const buildTaskFields = async (fields, category = null) => {
    let assignedTo = null
    let employees = null
    if (!category) {
      return {
        ...fields,
        branch: fields.branch || (activeBranch?.id ?? ''),
      }
    }

    switch (category.mainTask.assignTo) {
      case 'role':
        employees = await fetchEmployees([category.mainTask.role]).unwrap()
        assignedTo = employees.results[0].id
        break
      case 'branch_administrative':
        assignedTo = getActiveAdministrative(branches, fields.branch)
        break
      case 'administration_owner':
      case 'accidents_owner':
      case 'accounting_owner':
        if (fields.building) {
          setIsSubmitting(true)
          assignedTo = await getOwner(fields.building, category.mainTask.assignTo)
          setIsSubmitting(false)
          if (!assignedTo) {
            assignedTo = getActiveAdministrative(branches, fields.branch)
          }
        }
        break
      case 'admin':
        assignedTo = fields.admin
        break
      case 'who_opens_it':
        assignedTo = user.employeeId
        break
    }
    return {
      ...fields,
      priority: category.mainTask.defaultPriority,
      deadline: dayjs().add(category.mainTask.minutesToDeadline, 'm'),
      branch: fields.branch || (activeBranch?.id ?? ''),
      building: category.isBuildingEnabled ? fields.building : '',
      residentArcadiaId: category.isResidentEnabled ? fields.residentArcadiaId : '',
      assignedTo,
    }
  }

  const handleEmployeeLabel = ({ firstName, lastName, user: { role } }) => {
    return `${firstName} ${lastName} - ${role.name}`
  }

  useEffect(async () => {
    if (!category && activeBranch && !defaultValues?.branch) {
      const fields = await buildTaskFields(getValues())
      reset(fields, { keepTouched: true, keepDirty: true })
    }
    if (category && !defaultValues) {
      const fields = await buildTaskFields(getValues(), category)
      reset(fields, { keepTouched: false, keepDirty: false })
    }
  }, [category, activeBranch, branchId, buildingId])

  useEffect(() => {
    if (categoryId) fetchData(categoryId)
  }, [categoryId])

  useEffect(() => {
    if (defaultValues) {
      let values = { ...defaultValues, residentArcadiaId: defaultValues.resident?.value }
      delete values.resident
      reset(values, { keepTouched: true, keepDirty: true })
    }
  }, [defaultValues])

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={onSubmit}>
      {(isSubmitting || isFetching) && <Loader overlay />}
      <FormProvider
        errors={errors}
        control={control}
        watch={watch}
        reset={reset}
        getValues={getValues}
        setValue={setValue}
        {...methods}
      >
        <Box>
          {errors.__form && (
            <Alert level="error" sx={{ width: '100%', boxSizing: 'border-box' }}>
              {errors.__form}
            </Alert>
          )}
          <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
            <Tabs value={activeTab} onChange={(_, idx) => setActiveTab(idx)} style={{ overflow: 'auto' }}>
              <Tab label={t('tms:tabs.TaskData')} />
              {categoryId && <Tab label={t('tms:tabs.TaskTree')} />}
            </Tabs>
          </MuiBox>
          <TabPanel activeIndex={activeTab} index={0}>
            <Grid container rowSpacing={3} spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  name="title"
                  control={control}
                  label={t('tms:fields.title')}
                  rules={{ required }}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CategorySelect
                  required
                  {...(!user.isSuperUser && {
                    qs: { open_privileges__id__in: R.prop('id', R.head(user.groups)), ordering: 'name' },
                  })}
                  name="taskCategory"
                  control={control}
                  label={t('tms:ui.SelectCategory')}
                  error={!!errors.taskCategory}
                  rules={{ required }}
                  helperText={errors.taskCategory?.message}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="branch"
                  rules={{ required }}
                  render={({ field: { onChange, value } }) => (
                    <BranchSelect
                      required
                      onChange={setStateFromEvent(handleBranchChange(onChange))}
                      value={value}
                      label={t('buildings:fields.branch.__field')}
                      error={!!errors.branch}
                      showOnlyActive
                      helperText={errors.branch?.message}
                    />
                  )}
                />
              </Grid>
              {category?.isAdminSelectEnabled && (
                <Grid item xs={12} sm={4}>
                  <SelectField
                    required
                    rules={{ required }}
                    control={control}
                    valueKey="id"
                    options={branchAdmins}
                    label={t('tms:fields.admin')}
                    name="admin"
                    helperText={
                      errors.admin ? errors.admin.message : branchId ? null : t('tms:validation.SelectBranchFirst')
                    }
                    error={!!errors.admin}
                  />
                </Grid>
              )}
              {category?.isBuildingEnabled && (
                <Grid item container xs={12} sm={category?.isAdminSelectEnabled ? 4 : 6}>
                  <BuildingSelect
                    required
                    name="building"
                    control={control}
                    branchId={defaultTo(0, parseInt(watch('branch')))}
                    helperText={
                      errors.building
                        ? errors.building.message
                        : branchId
                        ? null
                        : t('tms:validation.SelectBranchFirst')
                    }
                    rules={{ required }}
                    error={!!errors.building}
                    label={t('tms:fields.building')}
                  />
                </Grid>
              )}
              {category?.isResidentEnabled && (
                <Grid item xs={12} sm={category?.isAdminSelectEnabled ? 4 : 6}>
                  <Controller
                    name="residentArcadiaId"
                    rules={{ required }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ArcadiaAnagraphicAutocomplete
                        required={true}
                        value={value?.value}
                        onChange={onChange}
                        {...(errors.residentArcadiaId && { helperText: required })}
                        error={!!errors.residentArcadiaId}
                        defaultValue={defaultValues?.resident}
                        label={t('residents:fields.resident')}
                      />
                    )}
                  />
                </Grid>
              )}
              {category && (
                <Grid item xs={12}>
                  <LabeledBox label={t('tms:fields.description')}>
                    <Box background="lightOne" width="100%" pad="1rem 0.5rem" radius="6px">
                      <span dangerouslySetInnerHTML={{ __html: category.mainTask.description }} />
                    </Box>
                  </LabeledBox>
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <Controller
                  name="assignedTo"
                  control={control}
                  rules={{ required }}
                  render={({ field: { onChange, value } }) => (
                    <EmployeeByRoleSelect
                      searchable
                      required
                      roles={[]}
                      label={t('tms:ui.AssignTo')}
                      labelCallback={handleEmployeeLabel}
                      onChange={onChange}
                      value={value}
                      error={!!errors.assignedTo}
                      helperText={errors.assignedTo?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PrioritySelect
                  required
                  rules={{ required }}
                  name="priority"
                  control={control}
                  label={t('tms:fields.priority.__field')}
                  error={!!errors.priority}
                  helperText={errors.priority?.message}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePickerField
                  required
                  name="deadline"
                  rules={{ required }}
                  control={control}
                  label={t('tms:fields.deadline')}
                  error={!!errors.deadline}
                  helperText={errors.deadline?.message}
                  defaultValue={dayjs()}
                />
              </Grid>
              {category &&
                category.mainTask.fields.map((f) => (
                  <Grid item xs={12} sm={CUSTOM_FIELDS_GRID_MAP[f.type]} key={f.id}>
                    <TaskCustomField control={control} errors={errors} field={f} />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <EditorField name="notes" control={control} label={t('tms:fields.notes')} />
              </Grid>
            </Grid>
          </TabPanel>
          {category && (
            <TabPanel activeIndex={activeTab} index={1}>
              <Box width="100%">
                <TaskTreeInfographic task={category.mainTask} />
              </Box>
            </TabPanel>
          )}
        </Box>
      </FormProvider>
    </Modal>
  )
}

CreteTaskForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
}

export default CreteTaskForm
