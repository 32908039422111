import FlagIcon from '@mui/icons-material/Flag'
import { MobileDateRangePicker } from '@mui/lab'
import { Chip, TextField } from '@mui/material'
import dayjs from 'dayjs'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import BuildingAutocomplete from '@Buildings/Components/BuildingAutocomplete'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Dropdown from '@Common/Components/Dropdown'
import { Box } from '@Common/Components/Styles'
import { responseStringError } from '@Common/Utils/Api'
import { setStateFromEvent } from '@Common/Utils/Events'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import ResidentAutocomplete from '@Residents/Components/ResidentAutocomplete'
import { getDisplayName } from '@Residents/Utils'
import { useUpdateReportMutation } from '@Tickets/Services/Api'

import Report from '../Models/Report'
import { useReportsQuery } from '../Services/Api'
import ReportTypeAutocomplete from './ReportTypeAutocomplete'

const DISPLAY_COLUMNS = [
  'code',
  'created',
  'userObj',
  'building',
  'title',
  'reportTypeObj.name',
  'status',
  'lastUserCC',
]

const DISABLE_SORTING = ['userObj', 'building', 'lastUserCC']

const FIELDS_MAPPING = {
  // eslint-disable-next-line react/display-name
  lastUserCC: (record) => (record.logs.length !== 0 ? record.logs.at(-1).user.username : '-'),
  // eslint-disable-next-line react/display-name
  status: (r) => (
    <Chip
      size="small"
      label={r.statusLabel}
      color={r.status === 0 ? 'error' : r.status === 1 ? 'warning' : 'success'}
      style={{ color: '#fff' }}
    />
  ),
  // eslint-disable-next-line react/display-name
  userObj: (record) => getDisplayName(record.anagraphic),
  // eslint-disable-next-line react/display-name
  building: (record) => record.building?.denominazione,
}

const SEARCH_FIELDS = ['code']

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    qs.qsAdditions.code__icontains = qs.qsAdditions.search
    delete qs.qsAdditions.search
  }

  return qs
}

const ReportsList = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [updateReport] = useUpdateReportMutation()
  const [status, setStatus] = React.useState(-1)
  const [dateRange, setDateRange] = React.useState([null, null])
  const [buildingId, setBuildingId] = React.useState(null)
  const [residentId, setResidentId] = React.useState(null)
  const [type, setType] = React.useState(null)

  const qsAdditions = React.useMemo(() => {
    let params = {}
    if (status > -1) {
      params.status = status
    }
    if (dateRange[0] !== null) {
      params.created_after = dayjs(dateRange[0]).format('YYYY-MM-DD')
    }
    if (dateRange[1] !== null) {
      params.created_before = dayjs(dateRange[1]).format('YYYY-MM-DD')
    }
    if (buildingId !== null) {
      params.building_arcadia_id = buildingId
    }
    if (residentId !== null) {
      params.user__resident__id = residentId
    }
    if (type !== null) {
      params.report_type = type
    }
    return params
  }, [status, type, dateRange, buildingId, residentId])

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-reports'),
      offset: 0,
      ordering: '-created',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  const { data, isFetching } = useReportsQuery(convertSearchQuery(qs))

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        label: t('common:actions.Detail'),
        icon: <FlagIcon />,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(async (actionId, report) => {
    if (actionId === 'DETAIL') {
      if (report.status === 0) {
        const apiResponse = await updateReport({ id: report.id, status: 1 })
        if (!apiResponse.error) {
          history.push(makePath('admin.tickets.report', { reportId: report.id }), { report })
        } else {
          toast.error(t('tickets:errors:openError'), { error: responseStringError(apiResponse.error) })
        }
      } else {
        history.push(makePath('admin.tickets.report', { reportId: report.id }), { report })
      }
    }
  }, [])

  return (
    <div>
      <DataTable
        name="admin-reports"
        selectable
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        listDisplay={DISPLAY_COLUMNS}
        model={Report}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={R.compose(setSelected, R.ifElse(R.isNil, R.always([]), R.of), R.last)}
        loading={isFetching}
        disableSorting={DISABLE_SORTING}
        useDjangoOrdering
        noExport
        actions={actions}
        onAction={handleAction}
      >
        <Dropdown
          style={{ marginLeft: '.5rem' }}
          width="470px"
          options={[
            { id: 0, label: t('tickets:detail.inserita') },
            { id: 1, label: t('tickets:detail.in carico') },
            { id: 2, label: t('tickets:detail.chiusa') },
          ]}
          labelKey="label"
          valueKey="id"
          emptyValue={-1}
          emptyLabel={t('myHome:ui.All')}
          onChange={setStateFromEvent(setStatus)}
          value={status}
          label={t('tickets:fields.status.__field')}
        />
        <BuildingAutocomplete
          label={t('buildings:fields.building.__field')}
          onChange={(building) => {
            setBuildingId(building)
          }}
          width="850px"
          style={{ marginLeft: '.5rem' }}
        />
        <ResidentAutocomplete
          label={t('residents:fields.resident')}
          onChange={(resident) => {
            setResidentId(resident)
          }}
          width="750px"
          style={{ marginLeft: '.5rem' }}
        ></ResidentAutocomplete>
        <ReportTypeAutocomplete
          label={t('tickets:fields.type')}
          onChange={(type) => {
            setType(type)
          }}
          style={{ marginLeft: '.5rem', width: '200px' }}
        ></ReportTypeAutocomplete>
        <MobileDateRangePicker
          toolbarTitle={t('common:ui.SelectDateRange')}
          startText={t('tickets:fields.dateFrom')}
          endText={t('tickets:fields.dateTo')}
          value={dateRange}
          onChange={(newValue) => {
            if (newValue[0] !== null && newValue[0] !== null) {
              setDateRange(newValue)
            } else {
              setDateRange([null, null])
            }
          }}
          clearable
          clearText={t('common:ui.ClearMe')}
          cancelText={t('common:actions.Cancel')}
          renderInput={(startProps, endProps) => (
            <>
              <TextField variant="standard" style={{ width: '180px' }} {...startProps} />
              <Box margin="0 0rem 0 2rem"></Box>
              <TextField variant="standard" style={{ width: '180px' }} {...endProps} />
            </>
          )}
        />
      </DataTable>
    </div>
  )
}

ReportsList.propTypes = {}

export default ReportsList
