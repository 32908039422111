import config from '@Config'
import { api } from '@Core/Services/Api'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    importExcel: builder.mutation({
      query: ({ path, file }) => {
        return {
          url: `${path}`,
          body: file,
          method: 'POST',
        }
      },
      invalidatesTags: ['PodPdr', 'Condo', 'InsurancePolicy', 'InsurancePolicyPaymentsLogs'],
    }),
  }),
  overrideExisting: false,
})

export const downloadFile = (accessToken, path, filename) => {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + accessToken },
  }

  return fetch(`${config.apiBasePath}/${path}`, requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error downloading file: ${res.statusText}. Status code: ${res.status}`)
      }
      return res.blob()
    })
    .then((blob) => {
      const href = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return Promise.resolve()
    })
}

export const { useImportExcelMutation } = extendedApi
