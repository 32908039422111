import { useMediaQuery } from '@mui/material'
import i18next from 'i18next'
import * as R from 'ramda'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectAccessToken, selectCurrentUser } from '@Auth/Redux/Login'
import { ItemContainerContext } from '@Common/Components/ItemContainer'
import storageFactory from '@Common/Services/Storage'
import { setBreadcrumbs, setThemeMode } from '@Core/Redux/Ui'

import ConfirmationDialog from '../Components/ConfirmationDialog'
import { formatter } from './Formatters'

export const useCurrentUser = () => useSelector(selectCurrentUser)
export const useAccessToken = () => useSelector(selectAccessToken)

export const useBreadcrumbs = (breadcrumbs, cond = true, deps = []) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (cond) {
      dispatch(setBreadcrumbs(breadcrumbs))
    }
  }, [cond, ...deps, breadcrumbs])
}

export const useGuessThemeMode = () => {
  const dispatch = useDispatch()
  const Storage = storageFactory()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  if (!Storage.get('themeMode')) {
    dispatch(setThemeMode(prefersDarkMode ? 'dark' : 'light'))
  }
}

export const useConfirm = (onConfirm) => {
  const [state, setState] = React.useState({ open: false })
  const open = React.useCallback(
    (payload, title, content, options) => {
      if (options?.selected) {
        content = (
          <>
            {content}
            {options.selected.length > 0 && (
              <ul>
                {options.selected.map((item, idx) => {
                  const val = options.field.split('.').reduce((acc, curr) => (acc ? acc[curr] : null), item)
                  return <li key={idx}>{val}</li>
                })}
              </ul>
            )}
          </>
        )
      }
      setState({ open: true, title, content, payload })
    },
    [setState],
  )
  const close = () => {
    setState({ open: false })
  }
  const handleConfirm = React.useCallback(() => {
    close()
    return onConfirm(state.payload)
  }, [onConfirm, state.payload])

  const confirm = (
    <ConfirmationDialog
      open={state.open}
      title={state.title}
      onCancel={() => setState({ open: false })}
      onOk={handleConfirm}
    >
      {state.content}
    </ConfirmationDialog>
  )

  return [open, confirm, close]
}

export const useForm = (initFields, asObj = false, onSetField = null) => {
  const [fields, setFields] = React.useState(initFields)
  const [errors, setErrors] = React.useState({})
  const setField =
    (field, type, dft = null) =>
    (value) => {
      switch (type) {
        case 'int':
          value = R.either(R.isEmpty, R.isNil)(value) ? dft : parseInt(value)
          break
        case 'float':
          value = R.either(R.isEmpty, R.isNil)(value) ? dft : parseFloat(value)
          break
        case 'array':
          value = R.isNil(value) ? dft : value
          break
        default:
          value = R.isEmpty(value) ? dft : value
      }

      setFields({ ...fields, [field]: value })
      onSetField && onSetField(field, value)
    }

  const clearError = (field) => {
    setErrors({ ...errors, ...{ [field]: null } })
  }

  return asObj
    ? { fields, setFields, setField, errors, setErrors, clearError }
    : [fields, setFields, errors, setErrors, clearError]
}

/**
 * Usage:
 * const [filterName, setFilterName] = React.useState('')
 * const debouncedName = useDebounce(filterName, 300)
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

/**
 * Custom Hook for store a value into localStorage
 **/
export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.error(error)
    }
  }
  return [storedValue, setValue]
}

/**
 * Helper to use the Mui grid
 * in our custom components
 */
export const useMuiGrid = (props) => {
  const xs = props.xs || 12
  const sm = props.sm || props.xs || 12
  const md = props.md || props.sm || props.xs || 6
  const lg = props.lg || props.md || props.sm || props.xs || 4
  const xl = props.xl || props.lg || props.md || props.sm || props.xs || 4

  return { lg, md, sm, xl, xs }
}

// Retrive field label by field name from model translations
export const getFieldLabel = (name, model) => {
  return i18next.t(`${model.name.toLowerCase()}:model.fields.${name}`)
}

// Retrive value by field name from model translations
export const getFieldValue = (name, item, schema) => {
  const { type, multiple } = schema

  return getKeyWithSuffixModel(name, type, multiple)
    .split('.')
    .reduce((prev, curr) => (prev ? prev[curr] : null), item)
}

// Retrive item field data
export const getItemField = (name, item, model) => {
  const schema = getItemFieldSchema(name, model)
  const label = getFieldLabel(name, model)
  const value = getFieldValue(name, item, schema)

  let formattedValue = formatter(value, schema.type)

  if (schema.type === 'options') {
    formattedValue = getItemFieldChoices(name, model).find((o) => o.value === value)?.label
  }

  return { schema, label, value, formattedValue }
}

// Retrive item field data inside context
export const getItemFieldFromContext = (name) => {
  const { item, model } = useContext(ItemContainerContext)
  return getItemField(name, item, model)
}

// Retrive list of options by field name from model translations
export const getItemFieldChoices = (name, model) => {
  const fieldPath = `${model.name.toLowerCase()}:model.fields.${name}_choices`

  return model.schema[name]?.choices.map((value) => {
    const label = i18next.t(`${fieldPath}.${value}`)
    return { label, value }
  })
}

/**
 * In model we have foreign_key type, that's identify
 * the external relation with other models.
 * This is help us to build datatables because
 * we don't repeat the esternal field in our model
 * We need only create the referce to the model and after
 * we able to access directly externa model
 * properties by dotted notation.
 *
 * Here we search recursively the column name in children models
 */
export const getItemFieldSchema = (fullPath, currentModel, parentLabel, parentPath, parentKey = null) => {
  // split dotted notation
  const id = fullPath.split('.')
  const key = id[0]
  const columnSchema = currentModel.schema[key]

  // the base case, do nothing
  if (!id || id.length === 0) return

  // build column label joining the parent label
  const label = i18next.t(`${currentModel.name?.toLowerCase()}:model.fields.${key}`)

  const path = [parentPath, getKeyWithSuffixModel(key, columnSchema?.type, columnSchema?.multiple)]
    .filter((i) => i)
    .join('.')

  // we found the column
  if (id.length === 1) {
    return {
      id: parentKey ? [parentKey, key].join('.') : key,
      ...columnSchema,
      label,
      path,
      model: columnSchema?.model || currentModel,
    }
  }

  // rebuild key with dotted notation for recursively
  const remainingKey = id.slice(1).join('.')

  // recursive call, with current model or foreign model if it's set
  return getItemFieldSchema(remainingKey, columnSchema?.model || currentModel, label, path, key)
}

function getKeyWithSuffixModel(key, type, multiple) {
  // By default foreign_key in stored in itemNameObj, if is multiple is stored itemNameList
  if (type === 'foreign_key') return `${key}${multiple ? 'List' : 'Obj'}`

  return key
}


export const useAutoSave = (callback, delay = 1000, cond = true, deps = []) => {

  const savedCallback = React.useRef() // to save the current "fresh" callback

  // keep callback ref up to date
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // create the interval
  React.useEffect(() => {
    // function to call the callback
    function runCallback() {
      if (!cond) return
      savedCallback.current()
    }
    if (typeof delay === 'number') {
      // run the interval
      let interval = setInterval(runCallback, delay)
      // clean up on unmount or dependency change
      return () => clearInterval(interval)
    }
  }, [delay, ...deps])
}
