import React from 'react'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { InvitationsPermissions } from '@Auth/Permissions'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import InvitationsList from '../../Components/InvitationsList'
import ChooseInvitationBuildingModal from '@Auth/Components/ChooseInvitationBuildingModal'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { makePath } from '@Config'
import { pluck } from 'ramda'
import logger from '@Common/Utils/Logger'
import { toast } from 'react-toastify'
import { responseStringError } from '@Common/Utils/Api'
import { useRetryInvitationBulkMutation } from '@Auth/Services/Api'

const AdminInvitationsView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const navigate = useNavigate()
  const [form, setForm] = React.useState({ isOpen: false })
  const [retryInvitationBulk] = useRetryInvitationBulkMutation()

  useBreadcrumbs([
    { label: t('common:navigation.Invitations'), path: makePath('admin.invitations'), Icon: 'Invitation' },
  ])

  const handleOpenModal = () => setForm({ isOpen: true })
  const handleCloseModal = () => setForm({ isOpen: false })

  const actions = [
    {
      icon: <PeopleAltIcon onClick={() => navigate(makePath('admin.anagraphicsInvitations'))} />,
      name: t('auth:ui.anagraphics'),
    },
    { icon: <ApartmentIcon onClick={handleOpenModal} />, name: t('auth:ui.building') },
  ]

  const handleInvitationRetry = async (invitations) => {
    const ids = pluck('id', invitations)
    logger.debug('Bulk invitation retry submit, ids:', ids)
    try {
      await retryInvitationBulk({ ids }).unwrap()
      logger.debug('Bulk invitation retry success')
      toast.success(t('auth:success.InvitationRetried', { count: ids.length }))
    } catch (error) {
      logger.debug('Anagraphic bulk invitation failure', error)
      toast.error(t('auth:errors.RetryInvitationError', { count: ids.length, error: responseStringError(error) }))
    }
  }

  return (
    <>
      <InvitationsList onRetryInvitation={handleInvitationRetry} />
      {form.isOpen && <ChooseInvitationBuildingModal onClose={handleCloseModal} />}
      {InvitationsPermissions.create(user) && (
        <SpeedDial
          ariaLabel="Add invitation"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
          ))}
        </SpeedDial>
      )}
    </>
  )
}

export default AdminInvitationsView
