import * as R from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { useCondoByIdQuery } from '@Condo/Services/Api'
import BuildingDocumentsList from '@Documents/Components/BuildingDocumentsList'
import BuildingDocumentsTypeSelect from '@Documents/Components/BuildingDocumentsTypeSelect'
import {
  useLazyAccidentsDocumentsQuery,
  useLazyBuildingBankStatementsDocumentsQuery,
  useLazyBuildingFinancialStatementsDocumentsQuery,
  useLazyContractsDocumentsQuery,
  useLazyEmployeesDocumentsQuery,
  useLazyFacilitiesDocumentsQuery,
  useLazyFinancialDocumentsQuery,
  useLazyGenericsDocumentsQuery,
  useLazyInvoicesDocumentsQuery,
  useLazyLeasesDocumentsQuery,
  useLazyLegalPracticesDocumentsQuery,
  useLazyMeetingsDocumentsQuery,
  useLazyResidentsBankStatementsDocumentsQuery,
  useLazyResidentsPersonalDocumentsQuery,
  useLazyResidentsReceiptsDocumentsQuery,
  useLazySecurityDocumentsQuery,
  useLazyShipmentSlipsDocumentsQuery,
  useLazySupplierQuotesDocumentsQuery,
} from '@Documents/Services/Api'

// The order of keys defines the order of documents type select options
const FETCH_MAP = {
  meetings: useLazyMeetingsDocumentsQuery,
  supplierQuotes: useLazySupplierQuotesDocumentsQuery,
  facilities: useLazyFacilitiesDocumentsQuery,
  financialStatements: useLazyBuildingFinancialStatementsDocumentsQuery,
  bankStatements: useLazyBuildingBankStatementsDocumentsQuery,
  invoices: useLazyInvoicesDocumentsQuery,
  residentsReceipts: useLazyResidentsReceiptsDocumentsQuery,
  residentsBankStatements: useLazyResidentsBankStatementsDocumentsQuery,
  financial: useLazyFinancialDocumentsQuery,
  generics: useLazyGenericsDocumentsQuery,
  contracts: useLazyContractsDocumentsQuery,
  leases: useLazyLeasesDocumentsQuery,
  employees: useLazyEmployeesDocumentsQuery,
  shipmentSlips: useLazyShipmentSlipsDocumentsQuery,
  accidents: useLazyAccidentsDocumentsQuery,
  legalPractices: useLazyLegalPracticesDocumentsQuery,
  residentsPersonal: useLazyResidentsPersonalDocumentsQuery,
  security: useLazySecurityDocumentsQuery,
}

const Main = styled.section``

const BuildingDocumentsView = () => {
  const { t } = useTranslation()
  const { buildingId } = useParams()
  const documentTypes = R.map((k) => ({ id: k, label: t(`documents:types.${k}`) }), R.keys(FETCH_MAP))
  const { data: building, isFetching } = useCondoByIdQuery(buildingId)
  const [selectedType, setSelectedType] = useState(R.compose(R.prop('id'), R.head)(documentTypes))

  useBreadcrumbs(
    [...getCondoBreadcrumbFromId(buildingId), { label: t('common:navigation.BuildingDocuments') }],
    building?.description,
    [building],
  )

  return (
    <Main>
      {withLoader(
        <>
          <BuildingDocumentsTypeSelect options={documentTypes} selected={selectedType} setSelected={setSelectedType} />
          <BuildingDocumentsList
            type={selectedType}
            lazyFetchFn={FETCH_MAP[selectedType]}
            buildingId={building?.arcadiaId}
          />
        </>,
        isFetching,
      )}
    </Main>
  )
}

export default BuildingDocumentsView
