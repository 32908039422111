import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTable from '@Common/Components/DataTable'
import { Box } from '@Common/Components/Styles'
import { useConfirm } from '@Common/Utils/Hooks'
import TaskField, { TaskFieldType } from '@Tms/Models/TaskField'
import { TaskCategoryPermissions } from '@Tms/Permissions'
import { TASK_FIELD_TYPE_MAP } from '@Tms/Utils'

const DISPLAY_COLUMNS = ['name', 'type', 'isRequired', 'isInternal']

const FIELD_MAPPING = {
  type: ({ type }) => i18next.t(`tms:fields.type.${TASK_FIELD_TYPE_MAP[type]}`),
}

const TaskFieldList = ({ fields, onAdd, onEdit, onDelete, isReadOnly }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState()

  const handleDeleteField = (field) => {
    onDelete(field)
  }
  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteField)

  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon />,
        perm: R.either(TaskCategoryPermissions.update, TaskCategoryPermissions.create),
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: R.either(TaskCategoryPermissions.update, TaskCategoryPermissions.create),
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, field) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          field, // payload
          t('tms:ui.DeleteFieldConfirmationTitle'),
          t('tms:ui.DeleteFieldConfirmationText'),
          { selected: selected, field: 'name' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(field)
      } else if (actionId === 'ADD') {
        onAdd()
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      {!isReadOnly && (
        <Box margin="0 0 0.4rem" align="end">
          <Button size="small" disableElevation variant="contained" onClick={onAdd} startIcon={<AddIcon />}>
            {t('tms:actions.AddTaskField')}
          </Button>
        </Box>
      )}
      <DataTable
        name="tms-task-fields"
        data={fields}
        model={TaskField}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELD_MAPPING}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        selectable={!isReadOnly}
        noPagination
        noExport
        noSettings
        noBulkSelection
      />
      {DeleteConfirm}
    </div>
  )
}

TaskFieldList.propTypes = {
  fields: PropTypes.arrayOf(TaskFieldType),
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
}

export default TaskFieldList
