import MuiTextField from '@mui/material/TextField'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'

import { FieldType } from '../Types/Field'

/**
 * A wrpper to make it easy to add functionality, i.e. clearable
 */
const TextField = ({ value, variant, ...props }) => {
  // get rid of "I don't want null" fucking message from MUI
  const val = R.ifElse(R.isNil, () => '', R.identity)(value)
  return (
    <MuiTextField value={val} {...props} variant={variant} sx={{ pointerEvents: props.disabled ? 'none' : 'auto' }} />
  )
}

TextField.defaultProps = {
  variant: 'standard',
}

TextField.propTypes = FieldType & { variant: PropTypes.string }

export default TextField
