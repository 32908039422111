import * as R from 'ramda'

import { hasPermission, isBranchEmployee } from '@Common/Utils/Permissions'

import { EMPLOYEE_ROLES, HQ_ROLES } from './Costants'

export const canAccessOperativeView = R.either(
  R.pipe(R.prop('groups'), R.defaultTo([]), R.head, R.prop('id'), R.flip(R.includes)(R.values(EMPLOYEE_ROLES))),
  R.prop('isSuperUser'),
)

export const canAccessAdminView = R.either(
  R.pipe(R.prop('groups'), R.defaultTo([]), R.head, R.prop('id'), R.flip(R.includes)(R.values(HQ_ROLES))),
  R.prop('isSuperUser'),
)

export const canAccessTmsView = R.either(
  R.pipe(R.prop('groups'), R.defaultTo([]), R.head, R.prop('id'), R.flip(R.includes)(R.values(HQ_ROLES))),
  R.either(R.prop('isSuperUser'), isBranchEmployee)
)

export const canAccessMyEstiaView = R.path(['resident', 'id'])

export const EmployeesPermissions = {
  read: hasPermission('accounts.view_employee'),
  create: hasPermission('accounts.add_employee'),
  update: (user, obj) =>
    R.and(hasPermission('accounts.change_employee')(user), R.compose(R.not, R.path(['user', 'isSuperUser']))(obj)),
  delete: (user, obj) =>
    R.and(hasPermission('accounts.delete_employee')(user), R.compose(R.not, R.path(['user', 'isSuperUser']))(obj)),
}

export const InvitationsPermissions = {
  read: hasPermission('accounts.view_invitation'),
  create: hasPermission('accounts.add_invitation'),
  update: hasPermission('accounts.change_invitation'),
  delete: hasPermission('accounts.delete_invitation'),
}
