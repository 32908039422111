import { Typography } from '@mui/material'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Composition, HousingUnitDetailType } from '@Buildings/Models/HousingUnit'
import DataTable from '@Common/Components/DataTable'
import { getDisplayName } from '@Residents/Utils'

const LIST_DISPLAY = [
  'anagrafica.nominativo',
  'ingresso',
  'ruolo',
  'quotaConduzione',
  'quotaOrdinarie',
  'quotaStraordinarie',
]
const FIELDS_MAPPING = {
  ruolo: (record) => {
    return record.ruoli.map((r) => `${r.ruoloComposizione.descrizione} (${r.quota}%)`).join(' - ')
  },
  ['anagrafica.nominativo']: R.pipe(R.prop('anagrafica'), getDisplayName),
}

const HousingUnitCompositions = ({ unit }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h5" color="primary" mt="1rem" mb="1rem">
        {t('buildings:ui.Registries')}
      </Typography>
      <DataTable
        name="housing-unit-registries"
        data={unit.composizioni}
        model={Composition}
        sortField="id"
        sortDirection="desc"
        listDisplay={LIST_DISPLAY}
        fieldsMapping={FIELDS_MAPPING}
        noBulkSelection
      />
    </>
  )
}

HousingUnitCompositions.propTypes = {
  unit: HousingUnitDetailType,
}

export default HousingUnitCompositions
