import { CardContent, Grid, CardHeader } from '@mui/material'
import PropTypes from 'prop-types'

import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'

const GenericCardOutlinedDetail = ({ grid, title }) => {
  return (
    <Card variant="outlined">
      <CardHeader title={title} />
      <CardContent>
        <Grid container spacing={2} mt={0} style={{ paddingTop: 0 }}>
          {grid.map((element, i) => (
            <Grid key={i} item xs={12} sm={6} lg={6} style={{ paddingTop: 0 }}>
              <NameValueList data={element.data} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}

GenericCardOutlinedDetail.propTypes = {
  grid: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

export default GenericCardOutlinedDetail
