import { checkEmailAddress, checkPhoneNumber, checkRequired, nothing, wrap } from '@Common/Utils/Validation'
import i18next from 'i18next'
import * as R from 'ramda'
import { isLegalPerson } from '@Residents/Utils'

export const validate = (fields, setErrors, invitation) => {
  const err = {}

  const required = ['username', 'phone', 'password', 'passwordRepeat']

  if (isLegalPerson(invitation.extra)) {
    required.push('vatNumber')
  } else {
    required.push('fiscalCode')
  }

  checkRequired(err, required, fields)
  checkEmailAddress(err, fields, 'username')
  checkPhoneNumber(err, fields, 'phone')

  if (fields['terms'] !== true) {
    err['terms'] = i18next.t('auth:validation.TermsRequired')
  }

  if (fields['privacy'] !== true) {
    err['privacy'] = i18next.t('auth:validation.PrivacyRequired')
  }

  const setPasswordDoesntMatch = (err, field) => {
    err[field] = i18next.t('auth:validation.PasswordsDoesntMatch')
  }

  R.ifElse(
    R.compose(R.not, R.equals(R.prop('passwordRepeat', fields)), R.prop('password')),
    wrap(setPasswordDoesntMatch, err, 'password'),
    nothing,
  )(fields)

  setErrors(err)
  return R.isEmpty(err)
}
