import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'

const getAncestors = (task, taskDepth, exclude = []) => {
  const tasks = []
  if (taskDepth > 0) {
    if (!exclude.includes(task.id)) {
      tasks.push({ value: task.id, label: `${task.name} (id: ${task.id})` })
    }
    task.children.forEach((t) => {
      const ancestorNames = getAncestors(t, taskDepth - 1)
      tasks.push(...ancestorNames)
    })
  }
  return tasks
}

const AncestorTasksSelect = ({ name, control, taskDepth, exclude = [], ...other }) => {
  const { getValues } = useFormContext()
  const values = getValues()
  const [tasks, setTasks] = useState([])

  useEffect(() => {
    const ancestors = getAncestors(values.mainTask, taskDepth, exclude)
    setTasks(ancestors)
  }, [JSON.stringify(values)])

  return <SelectField name={name} control={control} options={tasks} {...other} />
}

AncestorTasksSelect.propTypes = FieldType

export default AncestorTasksSelect
