import { Card, CardContent, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { EditButton } from '@Common/Components/Buttons/EditButton'
import DataTable from '@Common/Components/DataTable'
import { ItemContainer } from '@Common/Components/ItemContainer'
import { FieldValue } from '@Common/Components/ItemField'
import { api } from '@Core/Services/Api'
import { CondoSupplierCompanyServicesForm } from '@Supplier/Forms/CondoSupplierCompanyServicesForm'
import { CondoSupplierCompany, SupplierAssociationService } from '@Supplier/Models/CondoSupplierCompany'

export const SupplierCompanyServiceListView = () => {
  const { buildingId, condoSupplierId } = useParams()
  const { t } = useTranslation()

  const { data, isLoading } = api.useGetItemQuery(`/condo/buildings/${buildingId}/suppliers/${condoSupplierId}`)

  const dataResult = data?.serviceList || []

  const FIELDS_MAPPING = {
    hasContract: (r) => <FieldValue value={r.hasContract} type="boolean" />,
    hasMandateExclusivity: (r) => <FieldValue value={r.hasMandateExclusivity} type="boolean" />,
    isPrivileged: (r) => <FieldValue value={r.isPrivileged} type="boolean" />,
  }

  const modelV1 = [
    {
      id: 'name',
      label: t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.name`),
    },
    {
      id: 'activity',
      label: t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.activity`),
    },
    {
      id: 'hasContract',
      label: t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.hasContract`),
    },
    {
      id: 'isPrivileged',
      label: t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.isPrivileged`),
    },
    {
      id: 'hasMandateExclusivity',
      label: t(`${SupplierAssociationService.name.toLowerCase()}:model.fields.hasMandateExclusivity`),
    },
  ]

  return (
    <Card variant="outlined" sx={{ height: 1 }}>
      <CardContent>
        <Grid sx={{ textAlign: 'end', pb: 2 }}>
          <ItemContainer item={data} model={CondoSupplierCompany}>
            <EditButton
              title={`${t('common:actions.Edit')} ${t(`${CondoSupplierCompany.name.toLowerCase()}:tabsForm.services`)}`}
              form={CondoSupplierCompanyServicesForm}
              formProps={{ isForm: true }}
            />
          </ItemContainer>
        </Grid>

        <DataTable
          name={SupplierAssociationService.name.toLowerCase()}
          data={dataResult || []}
          dataCount={dataResult?.length || 0}
          loading={isLoading}
          useDjangoOrdering
          noExport
          qs={null}
          refreshData={() => {}}
          listDisplay={Object.values(modelV1).map((x) => x.id)}
          columns={Object.values(modelV1).map((x) => x.id)}
          model={{
            columns: modelV1,
          }}
          noSettings={true}
          fieldsMapping={FIELDS_MAPPING}
        />
      </CardContent>
    </Card>
  )
}
