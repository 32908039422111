import { Grid, Stack, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectActiveBranch } from '@Branches/Redux'
import { useBranchesBuildingsQuery } from '@Buildings/Services/Api'
import DashboardCard from '@Common/Components/DashboardCard'
import DashboardMapWidget from '@Common/Components/DashboardMapWidget'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { resetFilters, setAllFilters, setFilters } from '@Tms/Redux'
import { useLazyTaskStatsQuery } from '@Tms/Services/Api'
import { STATUS_ENUM } from '@Tms/Utils'

const HomeView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filteredBuildings, setFilteredBuildings] = useState([])
  const isMediumDevice = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const activeBranch = useSelector(selectActiveBranch)
  const { data: branchBuildings, isLoading } = useBranchesBuildingsQuery({})
  const [fetchTaskStats, { data: taskStats, isLoading: isTaskStatsLoading }] = useLazyTaskStatsQuery()

  useBreadcrumbs([{ label: t('common:navigation.Dashboard'), Icon: 'Dashboard' }])

  const handleFilteredBuildings = () => {
    if (activeBranch?.id && branchBuildings?.results) {
      setFilteredBuildings(branchBuildings.results.filter((b) => b.branch === activeBranch.id))
    } else {
      setFilteredBuildings(branchBuildings?.results ?? [])
    }
  }

  const handleFiltersAndNavigate = (filters) => (link) => {
    dispatch(resetFilters())
    dispatch(setAllFilters(filters))
    history.push(link)
  }

  const taskFilters = {
    openTasks: { filters: { status: STATUS_ENUM.OPEN } },
    myOpenTasks: { quickFilters: { onlyMyTasks: true }, filters: { status: STATUS_ENUM.OPEN } },
    expiredTasks: { filters: { status: STATUS_ENUM.EXPIRED } },
    myExpiredTasks: { quickFilters: { onlyMyTasks: true }, filters: { status: STATUS_ENUM.EXPIRED } },
  }

  const handleChangeBranch = () => {
    if (activeBranch?.id) {
      dispatch(setFilters({ branch: activeBranch?.id }))
      fetchTaskStats({ task_flow__branch__id: activeBranch?.id })
    } else {
      dispatch(setFilters({ branch: '' }))
      fetchTaskStats()
    }
  }

  useEffect(handleFilteredBuildings, [activeBranch?.id, branchBuildings])
  useEffect(handleChangeBranch, [activeBranch?.id])

  return (
    <>
      {withLoader(
        <>
          <Grid container spacing={2} direction={isMediumDevice ? 'column-reverse' : 'row'}>
            <Grid item xs={12} md={6}>
              <DashboardMapWidget />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row" flexWrap="wrap" spacing={0} gap={1}>
                <DashboardCard
                  label={t('buildings:ui.Buildings')}
                  value={filteredBuildings?.length}
                  link={makePath('buildings.list')}
                />
                <DashboardCard
                  background="neutralTwo"
                  label={t('tms:ui.OpenTasks')}
                  value={taskStats?.openTasksCount ?? 0}
                  link={makePath('tms.taskList')}
                  onLinkClick={handleFiltersAndNavigate(taskFilters.openTasks)}
                />
                <DashboardCard
                  background="accentThree"
                  label={t('tms:ui.MyOpenTasks')}
                  value={taskStats?.myOpenTasksCount ?? 0}
                  link={makePath('tms.taskList')}
                  onLinkClick={handleFiltersAndNavigate(taskFilters.myOpenTasks)}
                />
                <DashboardCard
                  background="error"
                  label={t('tms:ui.ExpiredTasks')}
                  value={taskStats?.expiredTasksCount ?? 0}
                  link={makePath('tms.taskList')}
                  onLinkClick={handleFiltersAndNavigate(taskFilters.expiredTasks)}
                />
                <DashboardCard
                  background="warning"
                  label={t('tms:ui.MyExpiredTasks')}
                  value={taskStats?.myExpiredTasksCount ?? 0}
                  link={makePath('tms.taskList')}
                  onLinkClick={handleFiltersAndNavigate(taskFilters.myExpiredTasks)}
                />
              </Stack>
            </Grid>
          </Grid>
        </>,
        isLoading || isTaskStatsLoading,
      )}
    </>
  )
}

export default HomeView
