import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { BranchBuildingType } from '@Buildings/Models/BranchBuilding'
import { CondoType } from '@Condo/Models/Condo'
import { makePath } from '@Config'

export const BuildingLink = ({ building, type }) => {
  if (!building) return '-'
  return type === 'branchbuilding' ? (
    <Link to={makePath('buildings.detail.main', { buildingId: building.localBuildingId })}>
      {building.raw?.denominazione ?? '-'}
    </Link>
  ) : (
    <Link to={makePath('buildings.detail.main', { buildingId: building.id })}>{building.description ?? '-'}</Link>
  )
}

BuildingLink.propTypes = {
  building: PropTypes.oneOfType([CondoType, BranchBuildingType]),
  type: PropTypes.oneOf(['building', 'branchbuilding']),
}

export default BuildingLink
