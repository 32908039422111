import PropTypes from 'prop-types'
import { Avatar as MuiAvatar } from '@mui/material'
import { getAvatarText } from '../Utils'
import { useCurrentUser } from '@Common/Utils/Hooks'
import styled from 'styled-components'


const StyledAvatar = styled(MuiAvatar)`
  background: ${({color, theme}) => theme.palette[color].main};
  font-size: 14px;
`

const Avatar = ({ color }) => {
  const user = useCurrentUser()
  return (
    <StyledAvatar color={color}>{getAvatarText(user.resident.raw)}</StyledAvatar>
  )
}

Avatar.propTypes = {
  color: PropTypes.string,
}

export default Avatar
