import * as R from 'ramda'

import { checkLength, checkRequired, checkZipCode } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  let err = {}

  let required = [
    'description',
    'address',
    'region',
    'province',
    'city',
    'acquisitionMode',
    'appointmentDate',
    'houseNumber',
    'branch',
    'referringAdministrator',
    'cap',
    'state',
  ]

  if (!checkIsOrganicAcquisitionMode(fields)) {
    required.push('acquisitionDate')
  }

  checkRequired(err, required, fields)

  if (fields.cap) checkZipCode(err, fields, 'cap')

  err = validateFiscalCode(fields, err)

  // if (fields.acquisitionDate) {
  //   checkDate(err, fields, 'acquisitionDate')
  // }
  setErrors(err)
  return R.isEmpty(err)
}

export const validateFiscalCode = (fields, errors) => {
  const err = {}

  if (!fields.fiscalCodeNotAvailable) {
    checkLength(err, 'fiscalCode', 11, fields)
    checkRequired(err, ['fiscalCode'], fields)
  }

  return { ...errors, ...err }
}

export const getErroredFieldTab = (errors) => {
  const fieldToTabIndexMap = [
    // Anagrafica Condo
    { tab: 0, field: 'description' },
    { tab: 0, field: 'region' },
    { tab: 0, field: 'province' },
    { tab: 0, field: 'city' },
    { tab: 0, field: 'cap' },
    { tab: 0, field: 'address' },
    { tab: 0, field: 'houseNumber' },
    { tab: 0, field: 'fiscalCode' },

    // Acquisiszione
    { tab: 1, field: 'acquisitionMode' },
    { tab: 1, field: 'appointmentDate' },
    { tab: 1, field: 'state' },
    { tab: 1, field: 'acquisitionDate' },
    { tab: 1, field: 'managementClosingDate' },
    { tab: 1, field: 'lossDate' },
    // Filiale
    { tab: 2, field: 'currentAdministrator' },
    { tab: 2, field: 'branch' },
    { tab: 2, field: 'referringAdministrator' },
    { tab: 2, field: 'dedicatedAdministrator' },
  ]

  const { tab } = R.head(
    R.sort(
      (a, b) => a.tab - b.tab,
      R.filter((f) => R.includes(f.field, R.keys(errors)), fieldToTabIndexMap),
    ),
  ) ?? { tab: 0 }

  return tab
}

export const clearError = (field, errors, setErrors) => {
  setErrors({ ...errors, ...{ [field]: null } })
}

export function checkIsOrganicAcquisitionMode(fields) {
  return fields?.acquisitionMode === 'O'
}
