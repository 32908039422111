import PropTypes from 'prop-types'
import Modal from '@Common/Components/Modal'
import { FormControl, Grid } from '@mui/material'
import TextField from '@Common/Components/TextField'
import { useTranslation } from 'react-i18next'
import { useForm } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { Toolbar } from '@Config/Editor'
import TopicSelect from '@Notifications/Components/TopicSelect'
import { Label, Margin } from '@Common/Components/Styles'
import { Help } from '@Common/Components/Forms'
import Logger from '@Common/Utils/Logger'
import { validate } from './Validation'
import { convertServerErrors } from '@Common/Utils/Form'
import React, { useState } from 'react'
import Loader from '@Common/Components/Loader'

const NotificationForm = ({ onClose, onSave }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fields, setField, errors, setErrors } = useForm({
    topic: null,
    date: null,
    title: '',
    text: EditorState.createEmpty(),
    link: '',
    linkText: '',
  }, true)

  const submit = async () => {
    Logger.debug('Notification form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      setIsSubmitting(true)
      Logger.debug('Notification form submission, validation passed, saving')
      const { error, isSuccessful } = await onSave(fields)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        Logger.debug('Notification form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      Logger.debug('Notification form submission, validation failed')
    }
  }

  return (
    <Modal title={t('common:ui.Notification')} size="md" onClose={onClose} onSubmit={submit} submitLabel={t('common:actions.Send')}>
      {isSubmitting && <Loader overlay />}
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              required
              label={t('notifications:fields.title')}
              value={fields.title}
              onChange={setStateFromEvent(setField('title'))}
              error={!!errors.title}
              helperText={errors.title}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TopicSelect
              label={t('notifications:fields.topic')}
              value={fields.topic}
              onChange={setStateFromEvent(setField('topic'))}
              error={!!errors.topic}
              helperText={errors.topic}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label={t('notifications:fields.link')}
              value={fields.link}
              onChange={setStateFromEvent(setField('link'))}
              error={!!errors.link}
              helperText={errors.link}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label={t('notifications:fields.linkText')}
              value={fields.linkText}
              onChange={setStateFromEvent(setField('linkText'))}
              error={!!errors.linkText}
              helperText={errors.linkText}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Margin bottom="1rem" top="1rem">
            <Label>{t('notifications:fields.text')} *</Label>
          </Margin>
          <FormControl fullWidth>
            <Editor
              editorState={fields.text}
              wrapperClassName="wysiwyg-wrapper"
              editorClassName="wysiwyg-editor"
              onEditorStateChange={setField('text')}
              toolbar={Toolbar}
            />
            {errors.text && <Help error={errors.text}>{errors.text}</Help>}
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  )
}

NotificationForm.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

export default NotificationForm
