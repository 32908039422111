import { FormControl } from '@mui/material'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@Common/Components/Alert'
import Checkbox from '@Common/Components/Checkbox'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromCheckboxEvent, setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { ResidentType } from '@Residents/Models/Resident'

import { validate } from './Validation'

const ResidentForm = ({ resident, onClose, onSave }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const title = t(`residents:ui.EditResident`)

  const { fields, setField, errors, setErrors } = useForm(
    {
      username: R.defaultTo('', resident?.user?.username),
      isActive: R.defaultTo(false, resident?.user?.isActive),
      phone: R.defaultTo('', resident?.phone),
    },
    true,
  )

  const submit = async () => {
    logger.debug('Resident form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      setIsSubmitting(true)
      logger.debug('Resident form submission, validation passed, saving')
      const obj = buildPayload(fields)
      const { error, isSuccessful } = await onSave(obj)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('Resident form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('Resident form submission, validation failed')
    }
  }

  const buildPayload = ({ username, isActive, phone }) => {
    const payload = { phone, user: { username, isActive } }

    // workaround: delete username if it is not changed to avoid DRF's nested serializer unique validator bug
    if (username === resident.user.username) {
      delete payload.user.username
    }

    return { id: resident.id, ...payload }
  }

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Box>
        {errors.__form && (
          <Alert level="error" sx={{ width: '100%', boxSizing: 'border-box' }}>
            {errors.__form}
          </Alert>
        )}
        <Box margin="0 0 1rem">
          <Checkbox
            checked={fields.isActive}
            onChange={setStateFromCheckboxEvent(setField('isActive'))}
            label={t('residents:fields.active')}
          />
        </Box>
        <Grid container rowSpacing={3} spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                label={t('residents:fields.email')}
                value={fields.username}
                type="email"
                onChange={setStateFromEvent(setField('username'))}
                error={!!errors.username}
                helperText={errors.username}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                required
                label={t('residents:fields.phone')}
                value={fields.phone}
                onChange={setStateFromEvent(setField('phone'))}
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

ResidentForm.propTypes = {
  resident: ResidentType,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ResidentForm
