import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import * as R from 'ramda'

import { Margin } from './Styles'

// Testing modals in general is a pain, because are portals.
// Testing grommet layers is like a finger in the ass, so
// we define a wrapper used only with test env. It's simply
// a div wrapping the modal contents.
const TestLayer = ({ children }) => <div>{children}</div>
TestLayer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

const Modal = ({ title, children, onClose, onSubmit, submitLabel, disableSubmit, cancelLabel, footer, size }) => {
  const dftFooter = [
    <Button onClick={onClose} key="cancel" color="darkThree">
      {cancelLabel}
    </Button>,
  ]
  if (onSubmit)
    dftFooter.push(
      <Button onClick={onSubmit} key="submit" color="primary" disabled={disableSubmit}>
        {submitLabel}
      </Button>,
    )
  return (
    <Dialog open onClose={onClose} scroll="paper" maxWidth={size} fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>
        <Margin top="1rem">{children}</Margin>
      </DialogContent>
      <DialogActions>{R.defaultTo(dftFooter, footer)}</DialogActions>
    </Dialog>
  )
}

Modal.defaultProps = {
  submitLabel: i18next.t('common:actions.Save'),
  cancelLabel: i18next.t('common:actions.Cancel'),
  size: 'md',
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  disableSubmit: PropTypes.bool,
  cancelLabel: PropTypes.string,
  size: PropTypes.string,
}

export default Modal
