/* eslint-disable react/prop-types */
import React from 'react'
import { useForm } from 'react-hook-form'

import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { api } from '@Core/Services/Api'
import { CertificationDocumentType } from '@Supplier/Models/CertificationDocumentType'
import { SupplierCompanyType } from '@Supplier/Models/SupplierCompanyType'

export const SupplierCompanyTypeForm = ({ defaultValues }) => {
  const form = useForm({ defaultValues })

  const [update] = api.useUpdateOrCreateItemMutation()

  const handleSave = (item) => {
    if (!item.requiredDocuments) {
      item.requiredDocuments = []
    }
    // FIXME: Remove description attr from api
    item.description = item.name

    return update({ url: SupplierCompanyType.url, item })
  }

  return (
    <FormV2 form={form} onSubmit={handleSave} model={SupplierCompanyType}>
      <InputFieldV2 name="name" xs={8} />
      {/* <InputFieldV2 name="description" /> */}
      <InputFieldV2 name="status" xs={4} />
      <InputFieldV2 name="requiredDocuments" source={CertificationDocumentType.url} xs={12} />
    </FormV2>
  )
}
