import { SupplierCompanyTypeForm } from '@Supplier/Forms/SupplierCompanyTypeForm'

import { CertificationDocumentType } from './CertificationDocumentType'

export const SupplierCompanyType = {
  name: 'SupplierCompanyType',
  url: '/condo/bicert/supplier-types',
  form: SupplierCompanyTypeForm,
  schema: {
    name: { required: true },
    requiredDocuments: { type: 'foreign_key', multiple: true, model: CertificationDocumentType },
    status: { required: true, type: 'options', choices: ['A', 'S'], defaultValue: 'A' },
  },
}
