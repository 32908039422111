/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCitiesQuery, useProvincesQuery, useRegionsQuery } from '@Common/Services/Api/LocationApi'
import { usePrevious } from '@Common/Utils/Hooks'

import { InputFieldV2 } from '../FormsV2/InputFieldV2'
import { SelectFieldV2 } from '../FormsV2/SelectFieldV2'

// TODO dieffe: Refactor this

export const RegionProvinceCitySelectV2 = (props) => {
  const {
    name,
    defaultValueCity = null,
    defaultValueProvince = null,
    defaultValueRegion = null,
    xs = 6,
    sm = 6,
    autocompleteFromCity = true,
    nameCityProvince = 'cityProvince',
    nameCityRegion = 'cityRegion',
    nameCityCap = null,
    required = true,
  } = props

  const form = useFormContext()

  const { t } = useTranslation()

  const { data: dataRegion } = useRegionsQuery()
  const { data: dataProvince } = useProvincesQuery()
  const { data: dataCity } = useCitiesQuery()

  const [regions, setRegions] = useState([])
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])

  const getMetaData = (response) => response?.results?.map((x) => x?.metadata || x)

  useEffect(() => {
    setRegions(getMetaData(dataRegion))
    setProvinces(getMetaData(dataProvince))
    setCities(getMetaData(dataCity))
  }, [dataRegion, dataProvince, dataCity])

  const getProvinceByCityId = (id) => {
    return cities?.find((x) => x.id === id)?.province || null
  }

  const getRegionByProvinceId = (id) => {
    return provinces?.find((x) => x.id === id)?.region || null
  }

  const city = form.watch(name)
  const cityProvince = form.watch(nameCityProvince)
  const cityRegion = form.watch(nameCityRegion)

  useEffect(() => {
    if (!cityProvince && autocompleteFromCity) form.setValue(nameCityProvince, getProvinceByCityId(city))
  }, [city, cities])

  const prevProvince = usePrevious(cityProvince)
  const prevRegion = usePrevious(cityRegion)

  useEffect(() => {
    if (cityProvince && autocompleteFromCity) form.setValue(nameCityRegion, getRegionByProvinceId(cityProvince))

    if (prevProvince && cityProvince && prevProvince !== cityProvince && autocompleteFromCity) {
      form.setValue(name, null)
      if (nameCityCap) form.setValue(nameCityCap, null)
    }
  }, [cityProvince])

  useEffect(() => {
    if (prevRegion && cityRegion && prevRegion !== cityRegion && autocompleteFromCity) {
      form.setValue(nameCityProvince, null)
      form.setValue(name, null)
      if (nameCityCap) form.setValue(nameCityCap, null)
    }
  }, [cityRegion])

  const getProvinces = () => {
    return provinces?.filter((x) => x.region === cityRegion)
  }

  const getCities = () => {
    return cities?.filter((x) => x.province === cityProvince)
  }

  return (
    <>
      <SelectFieldV2
        label={t('auth:fields.region')}
        valueKey="id"
        name={nameCityRegion}
        labelKey="name"
        descriptionKey=""
        options={regions}
        searchable
        defaultValues={defaultValueRegion}
        xs={xs}
        sm={sm}
        required={required}
      />

      <SelectFieldV2
        label={t('auth:fields.province')}
        valueKey="id"
        name={nameCityProvince}
        labelKey="name"
        descriptionKey=""
        options={getProvinces()}
        searchable
        disabled={!cityRegion}
        defaultValues={defaultValueProvince}
        xs={xs}
        sm={sm}
        required={required}
      />

      <SelectFieldV2
        label={t('auth:fields.city')}
        valueKey="id"
        name={name}
        labelKey="name"
        descriptionKey=""
        options={getCities()}
        searchable
        disabled={!cityProvince}
        defaultValues={defaultValueCity}
        xs={xs}
        sm={sm}
        required={required}
      />

      {nameCityCap && <InputFieldV2 disabled={!city} name={nameCityCap} label={'Cap'} xs={xs} required={required} />}
    </>
  )
}
