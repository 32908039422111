import { CardContent, CardHeader, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BuildingType } from '@Buildings/Models/Building'
import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'
import { Margin } from '@Common/Components/Styles'

const CadastralData = ({ building }) => {
  const { t } = useTranslation()

  return (
    <Margin bottom="2rem">
      <Card variant="outlined">
        <CardHeader title={t('buildings:ui.CadastralData')} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  {
                    name: t('buildings:fields.cadastralData.municipality'),
                    value: building.datiCatastali.comuneCatastale,
                  },
                  {
                    name: t('buildings:fields.cadastralData.municipalityCode'),
                    value: building.datiCatastali.codiceComune,
                  },
                  { name: t('buildings:fields.urbanSection'), value: building.datiCatastali.sezioneUrbana },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  { name: t('buildings:fields.cadastralData.sheet'), value: building.datiCatastali.foglio },
                  { name: t('buildings:fields.cadastralData.parcel'), value: building.datiCatastali.particella },
                  {
                    name: t('buildings:fields.cadastralData.parcelExt'),
                    value: building.datiCatastali.estensioneParticella,
                  },
                  {
                    name: t('buildings:fields.cadastralData.subordinate'),
                    value: building.datiCatastali.subalterno,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Margin>
  )
}

CadastralData.propTypes = {
  building: BuildingType.isRequired,
}

export default CadastralData
