import React from 'react'
import {
  useCreateServiceProvidersMutation,
  useUpdateServiceProvidersMutation,
  useDeleteServiceProvidersMutation,
} from '../Services/Api'
import AddFab from '@Common/Components/AddFab'
import styled from 'styled-components'
import { useCurrentUser, useBreadcrumbs } from '../../Common/Utils/Hooks'
import { ProviderPermissions } from '../Permissions'
import ProviderList from '../Components/admin/AdminProviderList'
import { ProviderForm } from '../Forms/ProviderForm'
import { useTranslation } from 'react-i18next'
import { responseStringError } from '../../Common/Utils/Api'
import { toast } from 'react-toastify'
import * as R from 'ramda'
import { makePath } from '@Config'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminProviderView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [createProviders] = useCreateServiceProvidersMutation()
  const [updateProviders] = useUpdateServiceProvidersMutation()
  const [deleteProviders] = useDeleteServiceProvidersMutation()
  const [form, setForm] = React.useState({ isOpen: false, provider: null })
  const handleOpenForm = (provider) => () => setForm({ isOpen: true, provider })
  const handleCloseForm = () => setForm({ isOpen: false, provider: null })


  useBreadcrumbs([{ label: t('common:navigation.Services'), path: makePath('admin.services.services'), Icon: 'Service' },
  { label: t('common:navigation.Providers'),  Icon: 'Wrench' }]
  )
  const handleSave = async (fields) => {
    const formData = new FormData()
    formData.append('name', fields.name)
    formData.append('description', fields.description)
    if (fields.logo.length) {
      formData.append('logo', fields.logo[0])
    }
    try {
      const response = form.provider
        ? await updateProviders({ id: form.provider.id, provider: formData }).unwrap()
        : await createProviders(formData).unwrap()
      toast.success(t(`services:success.Provider${form.provider ? 'Updated' : 'Created'}`, { title: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t(`services:errors.${form.provider ? 'Update' : 'Create'}ProviderError`, { error: responseStringError(error) }),
      )
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (providers) => {
    try {
      const ids = providers.map(R.prop('id'))
      const promises = ids.map(async (id) => {
        return deleteProviders(id).unwrap()
      })

      await Promise.all(promises)
      toast.success(
        t('services:success.ProvidersDeleted', {
          count: providers.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('services:errors.DeleteProvidersError', {
          error: err.data?.detail || t('common:errors.UnknownError'),
          count: providers.length,
        }),
      )
    }
  }

  return (
    <Main>
      <ProviderList onEdit={(provider) => setForm({ isOpen: true, provider })} onDelete={handleDelete} />
      {form.isOpen && <ProviderForm provider={form.provider} onClose={handleCloseForm} onSave={handleSave} />}
      {ProviderPermissions.create(user) && <AddFab onClick={handleOpenForm(null)} />}
    </Main>
  )
}

export { AdminProviderView }
