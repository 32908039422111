/**
 * 
 * IMPORTANT!!!!!!!!!!!!!
 * Don't format this file because crash all app
 * 
 */
import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import { Provider } from 'react-redux'
import { store } from './Core/Redux/Store'
import App from './Core/App'
import reportWebVitals from './reportWebVitals'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { version } from '../package.json'
import { isProductionEnv } from '@Common/Utils/Env'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

if (!isProductionEnv) {
  document.title = `Estia v${version}`
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()