import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FieldType } from '@Common/Types/Field'

import { useCitiesQuery } from '../../Services/Api/LocationApi'
import Dropdown from '../Dropdown'

const CitySelect = ({ provinceId, allCity, helperText, value, ...other }) => {
  const { t } = useTranslation()
  const { data } = useCitiesQuery()
  const [cities, setCities] = useState([])

  useEffect(() => {
    if (data) {
      if (allCity) {
        setCities(data.results)
      } else if (provinceId) {
        setCities(data.results.filter(R.propEq('province', provinceId)).map(R.pick(['id', 'name'])))
      }
    } else {
      setCities(data?.results)
    }
  }, [data, provinceId])

  return (
    <Dropdown
      options={cities}
      labelKey="name"
      searchable
      valueKey="id"
      value={cities?.length > 0 ? value : ''}
      helperText={!provinceId ? t('auth:validation.SelectProvinceFirst') : helperText}
      {...other}
    />
  )
}

export default CitySelect

CitySelect.propTypes = {
  provinceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allCity: PropTypes.bool,
  ...FieldType,
}
