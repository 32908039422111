import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'
import i18next from 'i18next'
import { humanizeSize } from '@Common/Utils/Files'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = ['reportType', 'title', 'description']

  checkRequired(err, required, fields)

  setErrors(err)
  return R.isEmpty(err)
}

export const validateAttachments = (files, errors, setErrors) => {
  const err = {}

  if (files.length > 5) {
    err.attachments = i18next.t('myHome:errors.attachmentsCountError')
    setErrors({ ...errors, ...err })
    return false
  }

  files.forEach((file) => {
    if (err.attachments) return
    if (file.size > 200 * 1024 * 1024) {
      err.attachments = i18next.t('myHome:errors.attachmentsSizeError', {
        name: file.name,
        size: humanizeSize(file.size),
      })
      setErrors({ ...errors, ...err })
    }
  })

  if (R.isEmpty(err)) {
    delete errors.attachments
    return true
  }

  return false
}
