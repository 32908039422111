import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'

const CloseConditionTypeSelect = ({ name, control, ...other }) => {
  const { t } = useTranslation()
  const deadlineConditions = [
    { value: 4, label: t('tms:fields.closeCondition.otherTaskClosed') },
    { value: 3, label: t('tms:fields.openCondition.taskFieldChoice') },
  ]

  return <SelectField name={name} control={control} options={deadlineConditions} {...other} />
}

CloseConditionTypeSelect.propTypes = FieldType

export default CloseConditionTypeSelect
