import { Grid, Link, Pagination, useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

import Loader from '@Common/Components/Loader'
import { Box, Margin } from '@Common/Components/Styles'
import { apiList } from '@Core/Services/Api'
import NewsModal from '@News/Components/NewsModal'
import NewsSearch from '@News/Components/NewsSearch'

import { useNewsQuery } from '../Services/Api'

const NewsContainer = styled.div`
  margin-left: 30px;
  margin-right: 30px;
`

const ImgGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const News = styled.div`
  margin-bottom: 15px;
  border-bottom: solid;
  border-width: thin;
  border-color: #8884;
  padding-bottom: 15px;
`

const ITEMS_PER_PAGE = 10

const NewsList = () => {
  const [modal, setModal] = React.useState({ open: false, news: {} })
  const handleOpenModal = (news) => setModal({ open: true, news })
  const handleCloseModal = () => setModal({ open: false, news: {} })

  const [qs, setQs] = useState({
    base: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      ordering: '-date',
    },
    qsAdditions: { published: true },
  })

  const handleChange = (page) => {
    setCurrentPage(page)
    setQs({
      base: {
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
        ordering: '-date',
      },
      qsAdditions: {
        published: true,
      },
    })
  }

  const { data, isFetching, orData } = apiList(useNewsQuery(qs))
  const [currentPage, setCurrentPage] = useState(1)
  const getPageCount = () => Math.ceil(orData.count / ITEMS_PER_PAGE)

  const handleSearch = (searchString) =>
    setQs((prevState) => ({
      ...prevState,
      qsAdditions: {
        ...prevState.qsAdditions,
        title__icontains__or: searchString,
        abstract__icontains__or: searchString,
        content__icontains__or: searchString,
      },
    }))

  return (
    <NewsContainer>
      <Margin bottom="3.5rem">
        <NewsSearch onSearch={handleSearch} />
      </Margin>

      {data.map((news, index) => (
        <Item key={index} item={news} onClick={handleOpenModal}></Item>
      ))}
      {modal.open && <NewsModal news={modal.news} onClose={handleCloseModal} />}

      {isFetching && <Loader minHeight="100px" />}
      {!isFetching && (
        <Box align="end">
          <Pagination
            count={getPageCount()}
            onChange={(_, newPage) => handleChange(newPage)}
            page={currentPage}
            style={{ marginLeft: '5%' }}
          />
        </Box>
      )}
    </NewsContainer>
  )
}

const Item = ({ item, onClick }) => {
  let abstract = item.abstract.toString()
  abstract = abstract.split(' ').slice(0, 15).join(' ')
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <News>
      <Grid container style={{ flexDirection: isSmallDevice ? 'column' : 'row', alignItems: 'center' }}>
        <Grid item xs={0} md={1.5}>
          {' '}
        </Grid>
        <ImgGrid
          item
          xs={4}
          md={2}
          style={{
            justifyContent: isSmallDevice ? 'center' : 'right',
          }}
        >
          <img
            src={item.image}
            style={{
              width: isSmallDevice ? '220px' : '220px',
            }}
          />
        </ImgGrid>
        <Grid item xs={12} md={0.2}></Grid>
        <Grid item xs={12} md={6.5}>
          <Grid container>
            <Grid item xs={0} md={2}></Grid>
          </Grid>
          <div
            style={{
              marginTop: isSmallDevice ? '0px' : '5px',
              textAlign: isSmallDevice ? 'center' : 'left',
              marginBottom: '10px',
            }}
          >
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => onClick(item)}
              style={{
                textDecoration: 'none',
                fontSize: '20px',
              }}
            >
              {item.title}
            </Link>
          </div>
          <p
            className="date-title"
            style={{
              marginTop: isSmallDevice ? '0px' : '5px',
              textAlign: isSmallDevice ? 'center' : 'left',
            }}
          >
            {dayjs(item.date).format('DD/MM/YYYY')}
          </p>

          <p
            style={{
              textAlign: isSmallDevice ? 'center' : 'left',
              marginRight: isSmallDevice ? '15%' : '0px',
              marginLeft: isSmallDevice ? '15%' : '0px',
            }}
          >
            {abstract}
          </p>
        </Grid>
      </Grid>
    </News>
  )
}

Item.propTypes = {
  item: PropTypes.any,
  onClick: PropTypes.func,
}

export default NewsList
