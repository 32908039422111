import React from 'react'

import { AddButton } from '@Common/Components/Buttons/AddButton'
import { DataTableV3 } from '@Common/Components/DataTableV3'
import { SearchField } from '@Common/Components/FormsV2/SearchField'
import { SupplierServiceTypeActions } from '@Supplier/Components/SupplierServiceTypeActions'
import { SupplierServiceType } from '@Supplier/Models/SupplierServiceType'

export const SupplierServiceTypeListView = () => {
  const renderFilters = (
    <>
      <SearchField xs={3} />
    </>
  )

  return (
    <>
      <DataTableV3
        url={SupplierServiceType.url}
        model={SupplierServiceType}
        renderFilters={renderFilters}
        renderActions={<SupplierServiceTypeActions />}
      />
      <AddButton model={SupplierServiceType} />
    </>
  )
}
