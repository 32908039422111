import { isArray } from '@craco/craco/lib/utils'
import { Alert, AlertTitle, Button, FormControl, Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import FileField from '@Common/Components/FileField'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import { useImportExcelMutation } from '@Common/Services/Api/Api'
import { useForm } from '@Common/Utils/Hooks'

export const ImportExcelButton = ({ importApi }) => {
  const { t } = useTranslation()

  const accepted = [
    '.csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ]

  const [importExcel] = useImportExcelMutation()

  const { fields, setField, errors, setErrors } = useForm({ files: [] }, true)

  const [dialog, setDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const cleanState = () => {
    setErrors([])
    setField('files', 'array')([])
  }

  const handleClose = () => {
    cleanState()
    setDialog(false)
  }

  const handleOpen = () => {
    cleanState()
    setDialog(true)
  }

  const onFileDrop = (acceptedFiles) => {
    setErrors([])
    setField('files', 'array')(acceptedFiles)
  }

  const submitImport = () => {
    setIsSubmitting(true)
    const formData = new FormData()
    formData.append('file', fields.files[0])

    return importExcel({ path: importApi, file: formData })
      .unwrap()
      .then((response) => {
        handleClose()
        toast.success(t('common:success.ImportSuccessMessage', { count: response.imported }))
      })
      .catch((error) => {
        setField('files', 'array')([])
        setErrors(error.data.errors)
      })
      .finally(() => setIsSubmitting(false))
  }

  const renderErrors = () => {
    /**
     * we need to add this because errors sometimes
     * is object and sometimes is array
     */
    const currentErrors = isArray(errors) ? errors : []

    return (
      <Grid item xs={12} sm={12}>
        <Stack spacing={1}>
          {Boolean(currentErrors.length) && (
            <Alert severity="error">
              <AlertTitle> {t('common:errors.ImportError')}</AlertTitle>
              {currentErrors?.map((item) => {
                return (
                  <>
                    {item.row ? t('common:errors.ImportRowError', { ...item }) : item.error}
                    <br />
                  </>
                )
              })}
            </Alert>
          )}
        </Stack>
      </Grid>
    )
  }

  const renderDialog = (
    <Modal
      title={t('common:actions.Import')}
      size="lg"
      disableSubmit={!fields.files.length || isSubmitting}
      onClose={handleClose}
      onSubmit={submitImport}
      submitLabel={t('common:actions.Import')}
      sx={{ paddingTop: 0 }}
    >
      {isSubmitting && <Loader overlay />}
      <Box>
        <Grid container rowSpacing={3} spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <FileField
                onDrop={onFileDrop}
                files={fields.files}
                accept={accepted.join(',')}
                warningText={t('condo:info.ImportFileLimit', { dimension: '100MB' })}
              />
            </FormControl>
          </Grid>
          {renderErrors()}
        </Grid>
      </Box>
    </Modal>
  )

  return (
    <>
      <Button onClick={handleOpen}>{t('common:actions.Import')}</Button>
      {dialog && renderDialog}
    </>
  )
}

ImportExcelButton.propTypes = {
  importApi: PropTypes.string.isRequired,
}
