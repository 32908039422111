import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'news'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    news: builder.query({
      query: (qs) => `${prefix}?${apiQueryString(qs)}`,
      providesTags: ['News'],
    }),
    featuredNews: builder.query({
      query:()=>`${prefix}?featured=true&published=true`,
      providesTags: ['News'],
    }),
    createNews: builder.mutation({
      query: (news) => ({
        url: `${prefix}`,
        body: news,
        method: 'POST',
      }),
      invalidatesTags: ['News'],
    }),
    updateNews: builder.mutation({
      query: ({ id, news }) => ({
        url: `${prefix}/${id}`,
        body: news,
        method: 'PATCH',
      }),
      invalidatesTags: ['News'],
    }),
    deleteNews: builder.mutation({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['News'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useNewsQuery,
  useFeaturedNewsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
} = extendedApi
