import PropTypes from 'prop-types'
import { BuildingSummaryType } from '@Buildings/Models/Building'
import i18next from 'i18next'
import { ArcadiaEnumType } from '@Common/Types/EnumType'
import { AnagraphicType } from '@Auth/Models/Anagraphic'

export const RepresentativeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  immobile: BuildingSummaryType,
  anagrafica: AnagraphicType,
  tipo: ArcadiaEnumType,
  inizio: PropTypes.string,
  fine: PropTypes.string,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'tipo.displayValue',
      label: i18next.t('buildings:fields.type'),
      ordering: false,
    },
    {
      id: 'fullName',
      label: i18next.t('buildings:fields.fullName'),
      ordering: false,
    },
    {
      id: 'email',
      label: i18next.t('buildings:fields.email'),
      ordering: false,
    },
    {
      id: 'phone',
      label: i18next.t('buildings:fields.phone'),
      ordering: false,
    },
  ],
}
