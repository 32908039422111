import PropTypes from 'prop-types'

export const TaskOpenConditionType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  conditionType: PropTypes.oneOf([0, 1, 3]),
  conditionTask: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  conditionField: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  conditionFieldChoice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'conditionType',
      label: '',
    },
    {
      id: 'conditionTask',
      label: '',
    },
    {
      id: 'conditionField',
      label: '',
    },
    {
      id: 'conditionFieldChoice',
      label: '',
    },
  ],
}
