import { Box as MuiBox, FormControl, Tab, Tabs, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EmployeeType, RoledataType } from '@Auth/Models/Employee'
import { hasRoledata, isRoleSupervisable } from '@Auth/Utils'
import BranchSelect from '@Branches/Components/BranchSelect'
import { useBranchesQuery } from '@Branches/Services/Api'
import Alert from '@Common/Components/Alert'
import Checkbox from '@Common/Components/Checkbox'
import DatePicker from '@Common/Components/DatePicker'
import Loader from '@Common/Components/Loader'
import CitySelect from '@Common/Components/Location/CitySelect'
import ProvinceSelect from '@Common/Components/Location/ProvinceSelect'
import RegionSelect from '@Common/Components/Location/RegionSelect'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import { TabPanel } from '@Common/Components/TabPanel'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent, toUpperCaseFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { apiList } from '@Core/Services/Api'

import RoleSelect from '../../Components/RoleSelect'
import { getErroredFieldTab, validate } from './Validation'

const roledataNames = Object.keys(RoledataType).filter((name) => name !== 'isRoleSupervisor')

const pullRoledataFromEmployee = (employee) => {
  return roledataNames.reduce((acc, value) => {
    const newAcc = { ...acc, [value]: employee[value] }
    delete employee[value]
    return newAcc
  }, {})
}

const getSupervisorFullName = (user) => `${user.firstName} ${user.lastName}`

const EmployeeForm = ({ employee, onClose, onSave }) => {
  const { t } = useTranslation()
  const title = t(`auth:ui.${employee?.id ? 'Edit' : 'Create'}Employee`)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [selectedRole, setSelectedRole] = React.useState()
  const [selectedBranch, setSelectedBranch] = React.useState()
  const [disableAddress, setDisableAddress] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: branches } = apiList(useBranchesQuery())

  const { fields, setField, setFields, errors, setErrors } = useForm(
    {
      username: R.defaultTo('', employee?.user?.username),
      roleId: R.defaultTo(null, employee?.user?.role?.id),
      isActive: R.defaultTo(false, employee?.user?.isActive),
      firstName: R.defaultTo('', employee?.firstName),
      lastName: R.defaultTo('', employee?.lastName),
      phone: R.defaultTo('', employee?.phone),
      fiscalCode: R.defaultTo('', employee?.fiscalCode),
      burdenCenter: R.defaultTo('', employee?.burdenCenter),
      city: R.defaultTo('', employee?.city),
      province: R.defaultTo('', employee?.cityObj?.province),
      region: R.defaultTo('', employee?.cityObj?.provinceObj.region),
      address: R.defaultTo('', employee?.address),
      houseNumber: R.defaultTo('', employee?.houseNumber),
      cap: R.defaultTo('', employee?.cap),
      dateIn: employee?.dateIn ? dayjs(employee.dateIn) : '',
      dateOut: employee?.dateOut ? dayjs(employee.dateOut) : '',
      oooSince: employee?.oooSince ? dayjs(employee.oooSince) : '',
      oooUntil: employee?.oooUntil ? dayjs(employee.oooUntil) : '',
      isRoleSupervisor: R.defaultTo(false, employee?.roledata?.isRoleSupervisor),
      hqLegalPractice: R.defaultTo(false, employee?.roledata?.hqLegalPractice),
      hqAccidentPractice: R.defaultTo(false, employee?.roledata?.hqAccidentPractice),
      hqBudget: R.defaultTo(false, employee?.roledata?.hqBudget),
      hqFinalBalance: R.defaultTo(false, employee?.roledata?.hqFinalBalance),
      hqPaymentNotices: R.defaultTo(false, employee?.roledata?.hqPaymentNotices),
      hqPaymentReminders: R.defaultTo(false, employee?.roledata?.hqPaymentReminders),
      hqInvoicesReceiptsRegistration: R.defaultTo(false, employee?.roledata?.hqInvoicesReceiptsRegistration),
      hqTaxProceduresDeclarationsDeductions: R.defaultTo(
        false,
        employee?.roledata?.hqTaxProceduresDeclarationsDeductions,
      ),
      hqFtfPayment: R.defaultTo(false, employee?.roledata?.hqF24Payment),
      hqBuildingEmployeeManagement: R.defaultTo(false, employee?.roledata?.hqBuildingEmployeeManagement),
      hqAssurance: R.defaultTo(false, employee?.roledata?.hqAssurance),
      hqBankReconciliation: R.defaultTo(false, employee?.roledata?.hqBankReconciliation),
      hqBuildingBankAccounts: R.defaultTo(false, employee?.roledata?.hqBuildingBankAccounts),
      hqBuildingFiscalCodes: R.defaultTo(false, employee?.roledata?.hqBuildingFiscalCodes),
      hqChangeOwnershipCounts: R.defaultTo(false, employee?.roledata?.hqChangeOwnershipCounts),
      hqEInvoicingActivation: R.defaultTo(false, employee?.roledata?.hqEInvoicingActivation),
      hqMeetingCall: R.defaultTo(false, employee?.roledata?.hqMeetingCall),
      hqMeetingReportSend: R.defaultTo(false, employee?.roledata?.hqMeetingReportSend),
      hqPatrimonialSquaring: R.defaultTo(false, employee?.roledata?.hqPatrimonialSquaring),
      hqPecSend: R.defaultTo(false, employee?.roledata?.hqPecSend),
      hqSecurityDossier: R.defaultTo(false, employee?.roledata?.hqSecurityDossier),
      hqWaterUtilitiesSplit: R.defaultTo(false, employee?.roledata?.hqWaterUtilitiesSplit),
    },
    true,
  )

  const handleActiveTabIndex = () => {
    if (errors && R.keys(errors).length) setActiveTabIndex(getErroredFieldTab(errors))
  }

  const handleRoleChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('roleId'),
    R.mergeRight({ isRoleSupervisor: false }),
    R.mergeRight(fields),
    setFields,
  )

  const handleRegionChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('region'),
    R.mergeRight({ province: '', city: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const handleProvinceChange = R.pipe(
    R.path(['target', 'value']),
    R.objOf('province'),
    R.mergeRight({ city: '' }),
    R.mergeRight(fields),
    setFields,
  )

  const handleAddressCopy = ({ target }) => {
    setSelectedBranch(target.value)
    const branch = branches.find(R.propEq('id', target.value))
    if (branch) {
      // copy branch address to address fields
      setFields({
        ...fields,
        region: branch.cityObj.provinceObj.region,
        province: branch.cityObj.province,
        city: branch.city,
        address: branch.address,
        houseNumber: branch.houseNumber,
        cap: branch.cap,
      })
      setDisableAddress(true)
    } else {
      // clear address fields
      setFields({
        ...fields,
        region: '',
        province: '',
        city: '',
        address: '',
        houseNumber: '',
        cap: '',
      })
      setDisableAddress(false)
    }
  }

  const submit = async () => {
    logger.debug('Employee form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      setIsSubmitting(true)
      logger.debug('Employee form submission, validation passed, saving')
      const obj = buildUserPayload(fields, employee)
      const { error, isSuccessful } = await onSave(obj)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('Employee form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('Employee form submission, validation failed')
    }
  }

  const buildUserPayload = (fields) => {
    const { username, roleId, isActive, isRoleSupervisor, ...employeeFields } = fields
    const roledata = pullRoledataFromEmployee(employeeFields)
    const payload = {
      ...employeeFields,
      dateIn: employeeFields.dateIn ? employeeFields.dateIn.format('YYYY-MM-DD') : null,
      dateOut: employeeFields.dateOut ? employeeFields.dateOut.format('YYYY-MM-DD') : null,
      oooSince: employeeFields.oooSince ? employeeFields.oooSince.format('YYYY-MM-DD') : null,
      oooUntil: employeeFields.oooUntil ? employeeFields.oooUntil.format('YYYY-MM-DD') : null,
      user: {
        username,
        roleId,
        isActive,
      },
    }

    if (isRoleSupervisable(roleId)) {
      payload.roledata = hasRoledata(roleId) ? { isRoleSupervisor, ...roledata } : { isRoleSupervisor }
    } else {
      delete payload.roledata
    }

    if (employee) {
      // we are editing an existing user

      // workaround: delete username if it is not changed to avoid DRF's nested serializer unique validator bug
      if (username === employee.user.username) {
        delete payload.user.username
      }

      return { id: employee.id, ...payload }
    }

    // we are creating a new user
    return { ...payload, user: { ...payload.user } }
  }

  React.useEffect(handleActiveTabIndex, [errors])

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Box>
        {errors.__form && (
          <Alert level="error" sx={{ width: '100%', boxSizing: 'border-box' }}>
            {errors.__form}
          </Alert>
        )}
        <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
          <Tabs value={activeTabIndex} onChange={(_, idx) => setActiveTabIndex(idx)} style={{ overflow: 'auto' }}>
            <Tab label={t('auth:tabs.userData')} />
            <Tab label={t('auth:tabs.employeeData')} />
            <Tab label={t('auth:tabs.addressData')} />
            {hasRoledata(fields.roleId) && <Tab label={t('auth:tabs.roleData')} />}
          </Tabs>
        </MuiBox>
        <TabPanel activeIndex={activeTabIndex} index={0}>
          <Box margin="0 0 1rem">
            <Checkbox
              checked={fields.isActive}
              onChange={R.pipe(R.prop('target'), R.prop('checked'), setField('isActive'))}
              label={t('auth:fields.active')}
            />
          </Box>
          <Grid container rowSpacing={3} spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  required
                  label={t('auth:fields.email')}
                  value={fields.username}
                  type="email"
                  onChange={setStateFromEvent(setField('username'))}
                  error={!!errors.username}
                  helperText={errors.username}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <RoleSelect
                  value={fields.roleId}
                  label={t('auth:fields.role')}
                  onChange={handleRoleChange}
                  onSelectRole={setSelectedRole}
                  error={!!errors.roleId}
                  helperText={errors.roleId}
                />
              </FormControl>
              {isRoleSupervisable(fields.roleId) && (
                <Box>
                  {/* Se il ruolo non ha un supervisore oppure il supervisore è l'utente in modifica mostro checkbox */}
                  {R.ifElse(
                    R.either(
                      R.compose(R.isNil, R.prop('supervisor')),
                      R.pipe(R.path(['supervisor', 'id']), R.equals(employee?.id)),
                    ),
                    () => (
                      <Checkbox
                        checked={fields.isRoleSupervisor}
                        onChange={R.pipe(R.prop('target'), R.prop('checked'), setField('isRoleSupervisor'))}
                        label={t('auth:fields.roledata.isRoleSupervisor')}
                      />
                    ),
                    () => (
                      <Typography mt={1}>
                        {t('auth:info.RoleSupervisor', { name: getSupervisorFullName(selectedRole.supervisor) })}
                      </Typography>
                    ),
                  )(selectedRole)}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  label={t('auth:fields.dateIn')}
                  value={fields.dateIn}
                  onChange={setField('dateIn')}
                  error={!!errors.dateIn}
                  helperText={errors.dateIn}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  label={t('auth:fields.dateOut')}
                  value={fields.dateOut}
                  onChange={setField('dateOut')}
                  error={!!errors.dateOut}
                  helperText={errors.dateOut}
                  minDate={fields.dateIn || undefined}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  label={t('auth:fields.oooSince')}
                  value={fields.oooSince}
                  onChange={setField('oooSince')}
                  error={!!errors.oooSince}
                  helperText={errors.oooSince}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  label={t('auth:fields.oooUntil')}
                  value={fields.oooUntil}
                  onChange={setField('oooUntil')}
                  error={!!errors.oooUntil}
                  helperText={errors.oooUntil}
                  minDate={fields.oooSince || undefined}
                />
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel activeIndex={activeTabIndex} index={1}>
          <Grid container rowSpacing={3} spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  label={t('auth:fields.firstName')}
                  value={fields.firstName}
                  onChange={setStateFromEvent(setField('firstName'))}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  label={t('auth:fields.lastName')}
                  value={fields.lastName}
                  onChange={setStateFromEvent(setField('lastName'))}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  label={t('auth:fields.phone')}
                  value={fields.phone}
                  onChange={setStateFromEvent(setField('phone'))}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  label={t('auth:fields.fiscalCode')}
                  value={fields.fiscalCode}
                  onChange={setStateFromEvent(setField('fiscalCode'))}
                  error={!!errors.fiscalCode}
                  helperText={errors.fiscalCode}
                  onInput={toUpperCaseFromEvent}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  label={t('auth:fields.burdenCenter')}
                  value={fields.burdenCenter}
                  onChange={setStateFromEvent(setField('burdenCenter'))}
                  error={!!errors.burdenCenter}
                  helperText={errors.burdenCenter}
                />
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel activeIndex={activeTabIndex} index={2}>
          <Grid container rowSpacing={3} spacing={2}>
            <Grid item xs={12}>
              <Typography align="center">{t('auth:ui.ChooseBranchAddress')}</Typography>
              <FormControl fullWidth>
                <BranchSelect
                  emptyLabel={t('auth:ui.ChooseAddressManually')}
                  value={selectedBranch}
                  label={t('buildings:fields.branch.__field')}
                  onChange={handleAddressCopy}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">{t('auth:ui.orChooseAddressManually')}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <RegionSelect
                  disabled={disableAddress}
                  value={fields.region}
                  onChange={handleRegionChange}
                  error={!!errors.region}
                  helperText={errors.region}
                  label={t('auth:fields.region')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <ProvinceSelect
                  disabled={disableAddress}
                  value={fields.province}
                  onChange={handleProvinceChange}
                  error={!!errors.province}
                  helperText={errors.province}
                  label={t('auth:fields.province')}
                  regionId={fields.region}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <CitySelect
                  disabled={disableAddress}
                  value={fields.city}
                  onChange={setStateFromEvent(setField('city'))}
                  error={!!errors.city}
                  helperText={errors.city}
                  label={t('auth:fields.city')}
                  provinceId={fields.province}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled={disableAddress}
                  label={t('auth:fields.address')}
                  value={fields.address}
                  onChange={setStateFromEvent(setField('address'))}
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled={disableAddress}
                  label={t('auth:fields.houseNumber')}
                  value={fields.houseNumber}
                  onChange={setStateFromEvent(setField('houseNumber'))}
                  error={!!errors.houseNumber}
                  helperText={errors.houseNumber}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled={disableAddress}
                  label={t('auth:fields.cap')}
                  value={fields.cap}
                  onChange={setStateFromEvent(setField('cap'))}
                  error={!!errors.cap}
                  helperText={errors.cap}
                />
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        {hasRoledata(fields.roleId) && (
          <TabPanel activeIndex={activeTabIndex} index={3}>
            <Grid container rowSpacing={1} spacing={2}>
              {roledataNames.map((roledataName, i) => (
                <Grid item xs={12} sm={6} key={i}>
                  <Checkbox
                    checked={fields[roledataName]}
                    onChange={R.pipe(R.prop('target'), R.prop('checked'), setField(roledataName))}
                    label={t(`auth:fields.roledata.${roledataName}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        )}
      </Box>
    </Modal>
  )
}

EmployeeForm.propTypes = {
  employee: EmployeeType,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default EmployeeForm
