import HomeButtons from '../Components/HomeButtons'
import NewsletterWidget from '@Newsletter/Components/Widget'
import styled from 'styled-components'
import { useFeaturedNewsQuery } from '@News/Services/Api'
import Showcase from '@News/Components/Showcase'
import React from 'react'

const Main = styled.div`
  padding: 2rem;
`

export const HomeView = () => {
  const { data, isFetching } = useFeaturedNewsQuery()

  return (
    <div>
      <Main>
        <HomeButtons />
      </Main>

      <Showcase data={data?.results || []} loading={isFetching} />

      <NewsletterWidget />
    </div>
  )
}
