import { ScreenRotation } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { Children, Component } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Bg from '@Assets/Images/bg.png'
import Card from '@Common/Components/Card'
import { Box, Margin } from '@Common/Components/Styles'
import logger from '@Common/Utils/Logger'

/**
 * orientation media query fails on some devices when a soft-keyboard is opened
 * trying this workaround:
 * @link https://web.archive.org/web/20160509220835/http://blog.abouthalf.com/development/orientation-media-query-challenges-in-android-browsers/
 */

const Landscape = styled.div`
  display: none;
  @media screen and (min-aspect-ratio: 13/9) /*and (orientation: landscape)*/ {
    display: inherit;
  }
`

const Portrait = styled.div`
  display: none;
  @media screen and (max-aspect-ratio: 13/9) /*and (orientation: portrait)*/ {
    display: inherit;
  }
`

export class Orientation extends Component {
  render() {
    const { orientation, children } = this.props
    return orientation === 'landscape' ? <Landscape>{children}</Landscape> : <Portrait>{children}</Portrait>
  }
}

Orientation.propTypes = {
  alwaysRender: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['portrait', 'landscape']).isRequired,
}

Orientation.defaultProps = {
  className: '',
  alwaysRender: true,
}

const noop = () => false

window.screen.lockOrientationUniversal =
  window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation

const lock = (orientation) => {
  const { screen } = window
  if (screen.orientation && typeof screen.orientation.lock === 'function') {
    return window.screen.orientation.lock(orientation)
  } else if (screen.lockOrientationUniversal) {
    return new Promise((resolve, reject) => {
      if (screen.lockOrientationUniversal(orientation)) {
        resolve()
      } else {
        reject()
      }
    })
  } else {
    return new Promise((resolve, reject) => reject())
  }
}

export default class DeviceOrientation extends Component {
  constructor(props) {
    super(props)
    this.lockOrientation(props)
    this.onOrientationChange = this.onOrientationChange.bind(this)
    this.onOrientationChange(null)

    this.state = {
      orientation: null,
      type: null,
      angle: null,
    }
  }

  componentDidMount() {
    logger.debug('DeviceOrientation', 'componentDidMount')
    if (window.screen.orientation && 'onchange' in window.screen.orientation) {
      logger.debug('Using screen.orientation.onchange')
      window.screen.orientation.addEventListener('change', this.onOrientationChange)
    } else if ('onorientationchange' in window) {
      logger.debug('Using window.onorientationchange')
      window.addEventListener('orientationchange', this.onOrientationChange)
    } else {
      console.warn('No orientationchange events')
    }
  }

  componentWillUnmount() {
    logger.debug('DeviceOrientation', 'componentWillUnmount')
    if (window.screen.orientation && 'onchange' in window.screen.orientation) {
      logger.debug('Removing screen.orientation.onchange')
      window.screen.orientation.removeEventListener('change', this.onOrientationChange)
    } else if ('onorientationchange' in window) {
      logger.debug('Removing window.onorientationchange')
      window.removeEventListener('orientationchange', this.onOrientationChange)
    }
  }

  // eslint-disable-next-line no-unused-vars
  onOrientationChange(event) {
    const onOrientationChange = this.props.onOrientationChange || noop
    var orientation = 'portrait'
    var type = 'primary'
    var angle = 0
    if (window.orientation) {
      angle = window.orientation
      orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait'
    }

    if (window.screen.orientation) {
      [orientation, type] = window.screen.orientation.type.split('-')
      angle = window.screen.orientation
    }
    this.setState({
      orientation,
      type,
      angle,
    })
    onOrientationChange(orientation, type, angle)
  }

  lockOrientation({ lockOrientation }) {
    if (typeof lockOrientation !== 'string') {
      return
    }
    const onLockOrientation = this.props.onLockOrientation || noop
    return lock(lockOrientation)
      .then(function () {
        onLockOrientation(true)
      })
      .catch(function () {
        onLockOrientation(false)
      })
  }

  render() {
    const { children, className } = this.props
    const { orientation } = this.state
    return (
      <div className={`${className}`}>
        {Children.map(children, (child) => {
          const { props } = child
          if (props.alwaysRender || props.orientation === orientation) {
            return child
            // } else {
            //   logger.debug('Skipping child', child)
          }
        })}
      </div>
    )
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/screen/lockOrientation
const LOCK_ORIENTATIONS = [
  'portrait-primary',
  'portrait-secondary',
  'landscape-primary',
  'landscape-secondary',
  'portrait',
  'landscape',
  'default',
]

const isOrientation = (props, propName, componentName, location, propFullName) => {
  const propValue = props[propName]
  if (propValue.type !== Orientation) {
    return new Error(
      `Invalid ${location} '${propFullName}' supplied to '${componentName}', expected 'Orientation' component.`,
    )
  }
}

DeviceOrientation.propTypes = {
  children: PropTypes.oneOfType([isOrientation, PropTypes.arrayOf(isOrientation)]).isRequired,
  className: PropTypes.string,
  lockOrientation: PropTypes.oneOfType([
    PropTypes.oneOf(LOCK_ORIENTATIONS),
    PropTypes.arrayOf(PropTypes.oneOf(LOCK_ORIENTATIONS)),
  ]),
  onLockOrientation: PropTypes.func,
  onOrientationChange: PropTypes.func,
}

DeviceOrientation.defaultProps = {
  className: '',
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999999999999999999999999999;
  left: 0;
  top: 0;
  width: 100%;
  align-items: center;
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
`

export const OnlyPortraitAlert = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Card $maxWidth="350px">
        <Box pad="2rem" align="center" justify="center">
          <ScreenRotation fontSize="large" />
          <Margin top="1rem">{t('common:ui.CannotUseInLandscape')}</Margin>
        </Box>
      </Card>
    </Wrapper>
  )
}
