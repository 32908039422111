import { CardContent, Grid, CardHeader } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'
import { Margin } from '@Common/Components/Styles'

const UrbanPlanningData = ({ urbanData }) => {
  const { t } = useTranslation()

  return (
    <Margin bottom="2rem">
      <Card variant="outlined">
        <CardHeader title={t('common:components.UrbanPlanningData.title')} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  { name: t('common:components.UrbanPlanningData.address'), value: urbanData?.address || '-' },
                  { name: t('common:components.UrbanPlanningData.houseNumber'), value: urbanData?.houseNumber || '-' },
                  { name: t('common:components.UrbanPlanningData.internal'), value: urbanData?.internal || '-' },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  { name: t('common:components.UrbanPlanningData.cap'), value: urbanData?.cap || '-' },
                  { name: t('common:components.UrbanPlanningData.city'), value: urbanData?.city || '-' },
                  {
                    name: t('common:components.UrbanPlanningData.locality'),
                    value: urbanData?.locality || '-',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Margin>
  )
}

UrbanPlanningData.propTypes = {
  urbanData: PropTypes.shape({
    address: PropTypes.any,
    internal: PropTypes.any,
    houseNumber: PropTypes.any,
    city: PropTypes.any,
    province: PropTypes.any,
    locality: PropTypes.any,
    cap: PropTypes.any,
  }),
}

export default UrbanPlanningData
