import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { getEmployeeDisplayName } from '@Auth/Utils'
import { Box, Margin } from '@Common/Components/Styles'
import { makePath } from '@Config'
import { getAnagraphicInfo } from '@Residents/Utils'
import BuildingLink from '@Tms/Components/BuildingLink'
import LabeledBox from '@Tms/Components/LabeledBox'
import { SubSectionTitle } from '@Tms/Components/Styled'
import { RunningTaskType } from '@Tms/Models/RunningTask'

const TaskFlowFields = ({ task }) => {
  const { t } = useTranslation()

  return (
    <Margin bottom="1rem">
      <SubSectionTitle>{t('tms:ui.TaskCategoryData')}</SubSectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={5}>
          <LabeledBox label={t('tms:fields.category')}>{task.taskFlowObj.taskCategory.name}</LabeledBox>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          <LabeledBox label={t('tms:labels.MainTask')}>
            {task.id === task.taskFlowObj.mainRunningTask.id && t('tms:info.CurrentTask')}
            {task.id !== task.taskFlowObj.mainRunningTask.id && (
              <Link to={makePath('tms.taskDetail', { taskId: task.taskFlowObj.mainRunningTask.id })}>
                {task.taskFlowObj.mainRunningTask.title}
              </Link>
            )}
          </LabeledBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <LabeledBox label={t('tms:labels.Branch')}>{task.taskFlowObj.branch.name}</LabeledBox>
        </Grid>
        {task.taskFlowObj.taskCategory.isBuildingEnabled && (
          <Grid item xs={12} md={6} lg={4}>
            <LabeledBox label={t('tms:fields.building')}>
              <BuildingLink building={task.taskFlowObj.building} type={task.taskFlowObj.buildingType} />
            </LabeledBox>
          </Grid>
        )}
        {task.taskFlowObj.taskCategory.isResidentEnabled && (
          <Grid item xs={12} md={6} lg={4}>
            <LabeledBox label={t('tms:fields.resident')}>
              {task.taskFlowObj.resident ? (
                <Link to={makePath('customerCare.anagraphic', { residentId: task.taskFlowObj.resident.id })}>
                  {getAnagraphicInfo(task.taskFlowObj.resident)}
                </Link>
              ) : (
                '-'
              )}
            </LabeledBox>
          </Grid>
        )}
        {task.taskFlowObj?.admin && (
          <Grid item xs={12}>
            <LabeledBox label={t('tms:fields.admin')}>{getEmployeeDisplayName(task.taskFlowObj.admin)}</LabeledBox>
          </Grid>
        )}
        {task.taskFlowObj.report && (
          <Grid item xs={12}>
            <LabeledBox label={t('tms:labels.RelatedTicketReport')}>
              <Link to={makePath('admin.tickets.report', { reportId: task.taskFlowObj.report.id })}>
                <Box direction="row" align="center">
                  <Margin right="5px">
                    {task.taskFlowObj.report.title} ({task.taskFlowObj.report.code})
                  </Margin>
                  <OpenInNewIcon fontSize="1rem" />
                </Box>
              </Link>
            </LabeledBox>
          </Grid>
        )}
      </Grid>
    </Margin>
  )
}

TaskFlowFields.propTypes = {
  task: RunningTaskType,
}

export default TaskFlowFields
