import React from 'react'
import PropTypes from 'prop-types'
import EdificeDetail from './EdificeDetail'

import { Tabs, Tab, Box as MuiBox } from '@mui/material'
import { TabPanel } from '@Common/Components/TabPanel'
import { useBuildingStructureQuery } from '@Buildings/Services/Api'
import { withLoader } from '@Common/Utils/HOC'
import * as R from 'ramda'

const EdificesTabs = ({ buildingArcadiaId }) => {
  const { data: structure, isFetching } = useBuildingStructureQuery(buildingArcadiaId)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  return withLoader(
    () => (
      <>
        <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
          <Tabs value={activeTabIndex} onChange={(_, idx) => setActiveTabIndex(idx)}>
            {structure.edifici.map((edifice, idx) => (
              <Tab label={edifice.descrizione} key={idx} />
            ))}
          </Tabs>
        </MuiBox>
        {structure.edifici.map((edifice, idx) => (
          <TabPanel activeIndex={activeTabIndex} index={idx} key={idx}>
            <EdificeDetail buildingArcadiaId={buildingArcadiaId} edifice={edifice} />
          </TabPanel>
        ))}
      </>
    ),
    isFetching || R.isEmpty(structure),
  )
}

EdificesTabs.propTypes = {
  buildingArcadiaId: PropTypes.string.isRequired,
}

export default EdificesTabs
