import { useTranslation } from 'react-i18next'
import { FacilityType } from '@Buildings/Models/Facility'
import { Typography } from '@mui/material'
import NameValueList from '@Common/Components/NameValueList'
import { useTheme } from '@mui/material/styles'

const FacilityDetailData = ({ facility }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Typography variant="h6" fontWeight="bold" color={theme.palette.darkFour.main}>
        {t('buildings:ui.GeneralData')}
      </Typography>
      <NameValueList
        data={[
          { name: t('buildings:fields.building.__field'), value: facility.immobile.denominazione },
          { name: t('buildings:fields.edifice.__field'), value: facility.edificio ?? '-' },
          { name: t('buildings:fields.stair'), value: facility.scala ?? '-' },
          { name: t('buildings:fields.typology'), value: facility.tipologia ?? '-' },
          { name: t('buildings:fields.serialNumber'), value: facility.matricola ?? '-' },
        ]}
      />
    </>
  )
}

FacilityDetailData.propTypes = {
  facility: FacilityType.isRequired,
}

export default FacilityDetailData
