import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DataTable from '@Common/Components/DataTable'
import { Box } from '@Common/Components/Styles'
import logger from '@Common/Utils/Logger'
import { useTaskCloseConditionFormField } from '@Tms/Forms/CategoryForm/useCategoryForm'
import CloseConditionTypeSelect from '@Tms/Forms/CloseConditionForm/CloseConditionTypeSelect'
import AncestorAndSiblingTasksSelect from '@Tms/Forms/Selects/AncestorAndSiblingTasksSelect'
import TaskCustomFieldChoiceSelect from '@Tms/Forms/TaskFieldForm/TaskCustomFieldChoiceSelect'
import TaskCustomFieldSelect from '@Tms/Forms/TaskFieldForm/TaskCustomFieldSelect'
import TaskCloseCondition from '@Tms/Models/TaskCloseCondition'
import { TaskCategoryPermissions } from '@Tms/Permissions'
import { getSelectTypeFields } from '@Tms/Utils'

const DISPLAY_COLUMNS = ['conditionType', 'conditionTask', 'conditionField', 'conditionFieldChoice']

const TaskCloseConditionList = ({ prefix, taskDepth, taskId, isReadOnly }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState()
  const { fields, addCloseCondition, removeCloseCondition, control, inputPath } = useTaskCloseConditionFormField(prefix)
  const { watch } = useFormContext()

  useEffect(() => logger.debug(fields), [fields])

  const FIELD_MAPPING = /* useMemo(
    () => ( */ {
    // eslint-disable-next-line react/display-name
    conditionType: (_, __, index) => (
      <Stack direction="row" alignItems="end" spacing={1}>
        <Typography fontWeight="bold">{t('tms:ui.If').toUpperCase()}</Typography>
        <CloseConditionTypeSelect
          control={control}
          name={`${inputPath}.${index}.conditionType`}
          disabled={isReadOnly}
          required
        />
      </Stack>
    ),
    // eslint-disable-next-line react/display-name
    conditionTask: (_, __, index) => {
      const mainTaskId = watch('mainTask.id')
      const taskId = watch(`${prefix}id`)
      const conditionType = watch(`${inputPath}.${index}.conditionType`)
      return (
        conditionType !== 3 && (
          <Stack direction="row" alignItems="end" spacing={1}>
            <Typography fontWeight="bold">{t('tms:ui.Task').toUpperCase()}</Typography>
            <AncestorAndSiblingTasksSelect
              disabled={isReadOnly}
              width="100%"
              control={control}
              exclude={[taskId, mainTaskId]}
              name={`${inputPath}.${index}.conditionTask`}
              taskDepth={taskDepth}
              required
              // error={!!condition.error}
              // helperText={condition.error}
            />
          </Stack>
        )
      )
    },
    // eslint-disable-next-line react/display-name
    conditionField: (record, _, index) => {
      const conditionType = watch(`${inputPath}.${index}.conditionType`)
      return (
        conditionType === 3 &&
        taskId && (
          <Stack direction="row" alignItems="end" spacing={1}>
            <Typography fontWeight="bold">{t('tms:ui.Field').toUpperCase()}</Typography>
            <TaskCustomFieldSelect
              disabled={isReadOnly}
              name={`${inputPath}.${index}.conditionField`}
              selectFieldsFn={getSelectTypeFields}
              taskId={taskId}
              control={control}
              valueKey="id"
              labelKey="name"
            />
          </Stack>
        )
      )
    },
    // eslint-disable-next-line react/display-name
    conditionFieldChoice: (record, _, index) => {
      const taskId = watch(`${prefix}id`)
      const fieldId = watch(`${inputPath}.${index}.conditionField`)
      const conditionType = watch(`${inputPath}.${index}.conditionType`)
      return (
        conditionType === 3 &&
        taskId &&
        fieldId && (
          <Stack direction="row" alignItems="end" spacing={1}>
            <Typography fontWeight="bold">{t('tms:ui.Choice').toUpperCase()}</Typography>
            <TaskCustomFieldChoiceSelect
              disabled={isReadOnly}
              name={`${inputPath}.${index}.conditionFieldChoice`}
              control={control}
              taskId={taskId}
              fieldId={fieldId}
            />
          </Stack>
        )
      )
    },
  } /* ),
    [fields, taskDepth],
  ) */

  const actions = React.useMemo(
    () => [
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: R.either(TaskCategoryPermissions.update, TaskCategoryPermissions.create),
      },
    ],
    [],
  )

  const handleAction = React.useCallback((_, { id }) => removeCloseCondition(id), [selected])

  return (
    <div>
      {!isReadOnly && (
        <Box margin="0 0 0.4rem" align="end">
          <Button size="small" disableElevation variant="contained" onClick={addCloseCondition} startIcon={<AddIcon />}>
            {t('tms:actions.AddCondition')}
          </Button>
        </Box>
      )}
      <DataTable
        noHeader
        name="tms-task-field-close-conditions"
        data={fields}
        model={TaskCloseCondition}
        sortField="id"
        sortDirection="asc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELD_MAPPING}
        actions={actions}
        onAction={handleAction}
        selectable={!isReadOnly}
        selected={selected}
        onSelect={setSelected}
        noPagination
        noExport
        noSettings
        noBulkSelection
      />
    </div>
  )
}

TaskCloseConditionList.propTypes = {
  prefix: PropTypes.string,
  taskDepth: PropTypes.number,
  taskId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isReadOnly: PropTypes.bool,
}

export default TaskCloseConditionList
