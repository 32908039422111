import PropTypes from 'prop-types'
import i18next from 'i18next'
import { ArcadiaEnumType } from '@Common/Types/EnumType'

export const HousingUnitType = PropTypes.shape({
  codice: PropTypes.string,
  descrizione: PropTypes.string,
  id: PropTypes.string,
  interno: PropTypes.string,
  ordinamento: PropTypes.number,
})

export const HousingUnitDetailType = PropTypes.shape({
  classificazione: PropTypes.shape({
    descrizione: PropTypes.string,
    pertinenza: PropTypes.bool,
    studiSettore: ArcadiaEnumType,
    utilizzo: ArcadiaEnumType,
  }),
  codice: PropTypes.string,
  composizioni: PropTypes.arrayOf(PropTypes.shape({
    anagrafica: PropTypes.shape({
      nominativo: PropTypes.string,
      id: PropTypes.string,
    }),
    bilancio: PropTypes.any,
    id: PropTypes.string,
    ingresso: PropTypes.string,
    quotaConduzione: PropTypes.number,
    quotaOrdinarie: PropTypes.number,
    quotaStraordinarie: PropTypes.number,
    residenteInImmobile: PropTypes.bool,
    ruoli: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      quota: PropTypes.number,
      ruoloComposizione: PropTypes.shape({
        codice: PropTypes.string,
        descrizione: PropTypes.string,
        id: PropTypes.string,
      }),
    })),
    uscita: PropTypes.any,
  })),
  datiCatastali: PropTypes.shape({
    codiceComune: PropTypes.string,
    comuneCatastale: PropTypes.string,
    estensioneParticella: PropTypes.string,
    foglio: PropTypes.string,
    interno: PropTypes.string,
    particella: PropTypes.string,
    sezioneUrbana: PropTypes.string,
    subalterno: PropTypes.string,
  }),
  descrizione: PropTypes.string,
  edificio: PropTypes.string,
  id: PropTypes.string,
  immobile: PropTypes.shape({
    codiceUnivoco: PropTypes.number,
    denominazione: PropTypes.string,
    id: PropTypes.string,
  }),
  ordinamento: PropTypes.number,
  piano: PropTypes.string,
  scala: PropTypes.string,
})

export const Composition = {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('buildings:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'anagrafica.nominativo',
      label: i18next.t('buildings:fields.registryName'),
    },
    {
      id: 'bilancio',
      label: i18next.t('buildings:fields.financialStatement.__field'),
    },
    {
      id: 'ingresso',
      label: i18next.t('buildings:fields.entryDate'),
      date: true,
    },
    {
      id: 'quotaConduzione',
      label: i18next.t('buildings:fields.operatingCostRate'),
    },
    {
      id: 'quotaOrdinarie',
      label: i18next.t('buildings:fields.ordinaryCostRate'),
    },
    {
      id: 'quotaStraordinarie',
      label: i18next.t('buildings:fields.extraCostRate'),
    },
    {
      id: 'residenteInImmobile',
      label: i18next.t('buildings:fields.residentInBuilding'),
      boolean: true,
    },
    {
      id: 'ruolo',
      label: i18next.t('buildings:fields.role'),
    },
    {
      id: 'uscita',
      label: i18next.t('buildings:fields.exitDate'),
      date: true,
    },
  ],
}

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('buildings:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'descrizione',
      label: i18next.t('buildings:ui.HousingUnit'),
    },
    {
      id: 'anagraphics',
      label: i18next.t('buildings:ui.Registries'),
    },
    {
      id: 'codice',
      label: i18next.t('buildings:fields.code') + ' ' + i18next.t('buildings:ui.HousingUnitAbbr'),
    },
    {
      id: 'interno',
      label: i18next.t('buildings:fields.internal'),
    },
  ],
}
