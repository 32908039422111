import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import config from '../../../Config'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import ReportTypesList from '../../Components/ReportTypesList'
import AddFab from '@Common/Components/AddFab'
import { ReportTypesPermissions } from '../../Permissions'
import ReportTypeForm from '../../Forms/ReportTypeForm'
import {
  useCreateReportTypeMutation,
  useDeleteReportTypeMutation,
  useUpdateReportTypeMutation,
} from '../../Services/Api'
import { toast } from 'react-toastify'
import { responseStringError } from '@Common/Utils/Api'
import * as R from 'ramda'

const Main = styled.main`
  padding-bottom: 3rem;
`

const AdminReportTypesView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [openForm, setOpenForm] = useState(false)
  const [createReportType] = useCreateReportTypeMutation()
  const [updateReportType] = useUpdateReportTypeMutation()
  const [deleteReportType] = useDeleteReportTypeMutation()
  const [reportType, setReportType] = React.useState({})

  const handleSave = async (fields) => {
    try {
      const response = reportType?.id
        ? await updateReportType({ id: reportType?.id, reportType: fields }).unwrap()
        : await createReportType(fields).unwrap()
      toast.success(t(`tickets:success.reportType${reportType?.id ? 'Updated' : 'Created'}`, { name: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t(`tickets:errors.${reportType?.id ? 'update' : 'create'}ReportTypeError`, {
          error: responseStringError(error),
        }),
      )
      return { error, isSuccessful: false }
    }
  }

  const handleEdit = (reportType) => {
    setReportType(reportType)
    setOpenForm(true)
  }

  const handleDelete = async (reportTypes) => {
    try {
      const ids = reportTypes.map(R.prop('id'))
      const promises = ids.map(async (id) => {
        return deleteReportType(id).unwrap()
      })

      await Promise.all(promises)
      toast.success(
        t('tickets:success.reportTypesDeleted', {
          count: reportTypes.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('tickets:errors.DeleteReportTypeError', {
          error: err.data?.detail || t('common:errors.UnknownError'),
          count: reportTypes.length,
        }),
      )
    }
  }

  useBreadcrumbs([{ label: t('common:navigation.Tickets'), path: config.urls.admin.tickets.reports, Icon: 'Tickets' }])

  return (
    <Main>
      <ReportTypesList onEdit={handleEdit} onDelete={handleDelete} />
      {openForm && (
        <ReportTypeForm
          reportType={reportType}
          onClose={() => {
            setOpenForm(false)
            setReportType({})
          }}
          onSave={handleSave}
        />
      )}
      {ReportTypesPermissions.create(user) && <AddFab onClick={() => setOpenForm(true)} />}
    </Main>
  )
}

export default AdminReportTypesView
