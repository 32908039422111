import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import { Link, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Offerta from '@Assets/Images/01_offerta_scontata.svg'
import Attivazione from '@Assets/Images/02_attivazione.svg'
import Modal from '@Common/Components/Modal'
import { withLoader } from '@Common/Utils/HOC'
import { makePath } from '@Config'
import { selectThemeMode } from '@Core/Redux/Ui'
import Button from '@MyEstia/Components/Button'
import { useServiceQuery } from '@Services/Services/Api'
import { history } from '@Core/Redux/Store'

const Main = styled.div`
  position: relative;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.palette.dataTableTr.main};
`

const Title = styled.div`
  display: flex;
  height: 100px;
  padding-inline: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const ImageContainer = styled.div`
  height: 250px;
  width: 100%;
  background: url('${({ background }) => background}') no-repeat center;
  background-size: cover;
`

const SubImage = styled.img`
  height: 200px;
  margin-bottom: 20px;
  max-width: 100%;
`

const Caption = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blackWhite.main};
`

const ContainerBlue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 20%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 20px;
`

const Container = styled.div`
  margin-top: -8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 20%;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: ${({ theme }) => theme.palette.background.main};
`

const Logo = styled.img`
  height: 50px;
  object-fit: contain;
  cursor: pointer;
`

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
`

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.accentOne.main};
`

export const ServiceDetailView = () => {
  const { t } = useTranslation()
  const { service: slug, category: categorySlug } = useParams()
  const { data: service, isFetching } = useServiceQuery(slug)
  const [openModal, setOpenModal] = useState(false)
  const themeMode = useSelector(selectThemeMode)

  const handleClick = () => {
    window.open(
      service?.saleMethod.url.includes('http') ? service?.saleMethod.url : 'https://' + service?.saleMethod.url,
      '_blank',
    )
  }

  return (
    <div>
      {withLoader(
        <>
          <Title>
            <Text fontSize={20} fontWeight={'600'} color="primary">
              <Link
                onClick={() => history.push(makePath('myEstia.myServices.main', { service: 'all' }))}
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer'
                }}
                color={themeMode === 'light' ? 'accentOne.light' : 'accentOne.dark'}
              >
                {t('myEstia:services.Services')}
              </Link>
              {' / '}
              <Link
                onClick={() => history.push(makePath('myEstia.myServices.main', { service: categorySlug }))}
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                color={themeMode === 'light' ? 'accentOne.light' : 'accentOne.dark'}
              >
                {service?.categoryObj.name}
              </Link>
            </Text>
          </Title>
          <Main>
            <ImageContainer background={service?.detailImage}>
              {service?.providerObj && (
                <LogoContainer>
                  <Logo src={service?.providerObj.logo} onClick={() => setOpenModal(true)} />
                </LogoContainer>
              )}
            </ImageContainer>
            <Container>
              <Text fontSize={20} fontWeight={'600'} textAlign={'center'} marginY={'10px'}>
                {service?.title.toUpperCase()}
              </Text>
              {service?.descriptionText && <Caption>{ReactHtmlParser(service?.descriptionText)}</Caption>}
            </Container>
            {service?.flier && (
              <ContainerBlue>
                <Text textAlign={'center'}>{t('myEstia:services.Brochure')}</Text>
                <Button
                  variant={'contained'}
                  style={{ width: '150px', marginTop: '15px' }}
                  startIcon={<CloudDownloadIcon />}
                  href={service?.flier}
                  target="_blank"
                  download
                >
                  {t('myEstia:services.Download')}
                </Button>
              </ContainerBlue>
            )}
            <Container>
              <SubImage src={Offerta} />
              <Text fontSize={20} fontWeight={'600'} textAlign={'center'}>
                {t('myEstia:services.Why')}
              </Text>
              <Text textAlign={'center'}>{ReactHtmlParser(service?.whyText)}</Text>
            </Container>
            <ContainerBlue>
              <SubImage src={Attivazione} />
              <Text fontSize={20} fontWeight={'600'} textAlign={'center'}>
                {t('myEstia:services.How')}
              </Text>
              <Text textAlign={'center'}>{ReactHtmlParser(service?.howText)}</Text>
              <Button
                variant={service?.saleMethod.url ? 'contained' : 'disabled'}
                style={{ width: '200px', marginTop: '15px' }}
                startIcon={<PointOfSaleIcon />}
                onClick={handleClick}
              >
                {t('myEstia:services.Go')}
              </Button>
            </ContainerBlue>
          </Main>
        </>,
        isFetching,
      )}
      {openModal && (
        <Modal size="xs" onClose={() => setOpenModal(false)} title={service.providerObj.name}>
          {service.providerObj.description}
        </Modal>
      )}
    </div>
  )
}
