import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'

const OpenConditionTypeSelect = ({ name, control, excluded, ...other }) => {
  const { t } = useTranslation()
  const openConditions = [
    { value: 0, label: t('tms:fields.openCondition.otherTaskOpening') },
    { value: 1, label: t('tms:fields.openCondition.otherTaskClosing') },
    { value: 3, label: t('tms:fields.openCondition.taskFieldChoice') },
  ].filter(({ value }) => !excluded.includes(value))

  return <SelectField name={name} control={control} options={openConditions} {...other} />
}

OpenConditionTypeSelect.propTypes = FieldType

export default OpenConditionTypeSelect
