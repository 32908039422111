import React from 'react'
import Modal from '@Common/Components/Modal'
import styled from 'styled-components'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

const NewModal=styled(Modal)`
div{
    margin-top:0px;
}
h2{
    padding-bottom:0px;

}
.jhTYTd{
    color:white;
}
`
const NewsModal = ({ onClose, news }) => {
  const { t } = useTranslation()

  return (
    <NewModal cancelLabel={t('common:actions.Close')} title={
        `${dayjs(news.date).format('DD/MM/YYYY')}`+" | "+ news.title}  size="md" onClose={onClose}>
      {/* {isFetching && <Loader overlay />}
      {!isFetching && */}
       <NewsItem news={news} ></NewsItem>
       {/* } */}
    </NewModal>
  )
}
const NewsItem=({news})=>{

    const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))

    return(
        <>
          <img
            src={news.image}
            style={{
              //   height: isSmallDevice ? '110px' : '150px',
              width: isSmallDevice ? '100%' : '80%',
              marginLeft: isSmallDevice ? '0%' : '10%',
            }}
          />


          {/* <Typography color="text.primary"> */}
          <div
            style={{ color: 'text.primary' }}
            dangerouslySetInnerHTML={{ __html: news.content }}
          ></div>
          {/* </Typography> */}
        </>
    )

}



NewsModal.propTypes = {
  onClose: PropTypes.func,
  news: PropTypes.any,
}
NewsItem.propTypes={
    news:PropTypes.any
}
export default NewsModal
