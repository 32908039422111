import i18next from 'i18next'
import PropTypes from 'prop-types'
import { EmployeeType } from '@Auth/Models/Employee'

export const CondoType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.any,
  fiscalCode: PropTypes.any,
  address: PropTypes.any,
  houseNumber: PropTypes.any,
  acquisitionMode: PropTypes.any,
  managementClosingDate: PropTypes.any,
  city: PropTypes.any,
  province: PropTypes.any,
  cap: PropTypes.any,
  acquisitionDate: PropTypes.any,
  appointmentDate: PropTypes.any,
  lossDate: PropTypes.any,
  state: PropTypes.any,
  lat: PropTypes.any,
  lng: PropTypes.any,
  accountingOwner: PropTypes.number,
  accountingOwnerObj: EmployeeType,
  administrationOwner: PropTypes.number,
  administrationOwnerObj: EmployeeType,
  accidentsOwner: PropTypes.number,
  accidentsOwnerObj: EmployeeType,
  branchbuildingId: PropTypes.number,
})

export const CondoAcquisitionMode = [
  { id: 'O', label: i18next.t('condo:fields.acquisitionMode.organic') },
  { id: 'I', label: i18next.t('condo:fields.acquisitionMode.internal') },
]

export const CondoCurrentAdministrator = [
  { id: 'E', label: i18next.t('condo:fields.currentAdministrator.type_e') },
  { id: 'P', label: i18next.t('condo:fields.currentAdministrator.type_p') },
]

export const CondoStateActive = { id: 'A', label: i18next.t('condo:fields.status.active') }
export const CondoStateLost = { id: 'P', label: i18next.t('condo:fields.status.lost') }

export const CondoState = [CondoStateActive, CondoStateLost]

// Info utilizzati per le impostazioni dentro la tabella
export const Condo = {
  primaryKey: 'id',
  columns: [
    {
      id: 'description',
      label: i18next.t('condo:fields.name'),
    },
    {
      id: 'fiscalCode',
      label: i18next.t('condo:fields.fiscalCode'),
    },
    {
      id: 'address',
      label: i18next.t('condo:fields.address'),
    },
    {
      id: 'houseNumber',
      label: i18next.t('condo:fields.houseNumber'),
    },
    {
      id: 'cityObj.name',
      label: i18next.t('condo:fields.city'),
    },
    {
      id: 'cityObj.provinceObj.name',
      label: i18next.t('condo:fields.province'),
    },
    {
      id: 'cap',
      label: i18next.t('condo:fields.cap'),
    },
    {
      id: 'acquisitionMode',
      label: i18next.t('condo:fields.acquisitionMode.__field'),
    },
    {
      id: 'managementClosingDate',
      label: i18next.t('condo:fields.managementClosingDate'),
      date: true,
    },
    {
      id: 'acquisitionDate',
      label: i18next.t('condo:fields.acquisitionDate'),
      date: true,
    },
    {
      id: 'appointmentDate',
      label: i18next.t('condo:fields.appointmentDate'),
      date: true,
    },
    {
      id: 'lossDate',
      label: i18next.t('condo:fields.lossDate'),
      date: true,
    },
    {
      id: 'administratorFullName',
      label: i18next.t('condo:fields.seniorAdmins'),
      ordering: false,
    },
    {
      id: 'referente',
      label: i18next.t('condo:fields.juniorAdmins'),
      ordering: false,
    },
    {
      id: 'currentAdministrator',
      label: i18next.t('condo:fields.currentAdministrator.__field'),
      ordering: false,
    },
    {
      id: 'branchBuildingName',
      label: i18next.t('condo:fields.branch.__field'),
      ordering: 'branch__name',
    },
    {
      id: 'state',
      label: i18next.t('condo:fields.state'),
    },
    {
      id: 'accountingOwnerObj.fullName',
      label: i18next.t('buildings:fields.accountingOwner'),
    },
    {
      id: 'administrationOwnerObj.fullName',
      label: i18next.t('buildings:fields.administrationOwner'),
    },
    {
      id: 'accidentsOwnerObj.fullName',
      label: i18next.t('buildings:fields.accidentsOwner'),
    },
  ],
}
