import MuiSettingsIcon from '@mui/icons-material/Settings'
import Link from '@mui/material/Link'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { CSVLink } from 'react-csv'
import styled, { css } from 'styled-components'

import AlertChip from '../AlertChip'
import TrueChip from '../TrueChip'

export const Toolbar = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.lightTwo.main};
    color: ${theme.palette.lightTwo.contrastText};
  `}
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  padding: 0.5rem 1rem;
  width: 100%;

  div {
    font-weight: bold;
    font-size: 0.9rem !important;
  }
`
export const BulkActions = styled.div`
  align-items: flex-end;
  display: flex;
  padding: 1rem 0;
`

export const SelectAll = styled(Typography)`
  border-left: 1px solid #eee;
  margin-left: 1rem !important;
  padding-left: 1rem;
`

export const SelectAllLink = styled(Link)`
  cursor: pointer;
`

export const SettingsIcon = styled(MuiSettingsIcon)`
  cursor: pointer;
  margin-left: auto;
`

export const ExportLink = styled(CSVLink)`
  color: #000;
  text-decoration: none;
`

export const TrueIcon = TrueChip

export const AlertIcon = AlertChip

export const FalseIcon = styled.span``

export const Tr = styled(TableRow)`
  ${(props) => props['data-no-border'] && '> * { border-bottom: 0px solid !important; }'};
  background: ${({ $even, theme }) => ($even ? theme.palette.dataTableTr.main : 'transparent')};
`
