import { CondoSupplierCompanyForm } from '@Supplier/Forms/CondoSupplierCompanyForm'

import { SupplierCompany } from './SupplierCompany'
import { SupplierServiceType } from './SupplierServiceType'

export const CondoSupplierCompany = {
  name: 'CondoSupplierCompany',
  url: '/condo/buildings/:buildingId/suppliers',
  form: CondoSupplierCompanyForm,
  schema: {
    bicertSupplierId: { type: 'foreign_key', model: SupplierCompany },
    bicertSupplierBranchIds: { type: 'foreign_key', multiple: true },
    blacklist: { type: 'boolean' },
    notes: { type: 'longtext' },
    contractList: {
      type: 'array',
      model: SupplierAssociationContract,
    },
    serviceList: {
      type: 'array',
      model: SupplierAssociationService,
    },
  },
}

export const CondoSupplierCompanyByService = {
  ...CondoSupplierCompany,
  name: 'CondoSupplierCompanyByService',
  schema: {
    bicertServiceId: { type: 'foreign_key', model: SupplierServiceType },
    name: {},
    activity: {},
    hasContract: { type: 'boolean' },
    isPrivileged: { type: 'boolean' },
    hasMandateExclusivity: { type: 'boolean' },
    supplier: {},
  },
}

export const SupplierAssociationContract = {
  name: 'SupplierAssociationContract',
  schema: {
    arxivarUrl: {},
    expirationDate: { type: 'date' },
  },
}

export const SupplierAssociationService = {
  name: 'SupplierAssociationService',
  schema: {
    bicertServiceId: { type: 'foreign_key', model: SupplierServiceType },
    name: {},
    activity: {},
    hasContract: { type: 'boolean' },
    isPrivileged: { type: 'boolean' },
    hasMandateExclusivity: { type: 'boolean' },
  },
}
