import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'

import BuildingInfoSection from '@Buildings/Components/BuildingInfoSection'
import CadastralData from '@Buildings/Components/CadastralData'
import { BuildingType } from '@Buildings/Models/Building'
import { getBuildingAddress, getIban } from '@Buildings/Utils'
import BuildingData from '@Common/Components/BuildingDetail/BuildingData'
import BuildingMap from '@Common/Components/BuildingDetail/BuildingMap'
import UrbanPlanningData from '@Common/Components/BuildingDetail/UrbanPlanningData'

const BuildingDetail = ({ building, taskInitValues }) => {
  const { t } = useTranslation()

  return (
    <>
      <BuildingMap
        building={{
          lat: building.lat ?? 47,
          lng: building.lng ?? 7,
          popupDenominazione: building?.denominazione,
          address: getBuildingAddress(building),
        }}
        height="300px"
      />

      <BuildingData
        taskInitValues={taskInitValues}
        data={[
          { name: t('buildings:fields.name'), value: R.defaultTo('--', building.denominazione) },
          { name: t('buildings:fields.fiscalCode'), value: R.defaultTo('--', building.codiceFiscale) },
          { name: t('buildings:fields.defaultBankAccount.iban'), value: R.defaultTo('--', getIban(building)) },
        ]}
      />
      <UrbanPlanningData
        urbanData={{
          address: building.indirizzo.via || null,
          houseNumber: building.indirizzo.civico || null,
          city: building.indirizzo.comune || null,
          cap: building.indirizzo.cap || null,
          locality: building.indirizzo.localita || null,
        }}
      />
      <CadastralData building={building} />
      <BuildingInfoSection building={building} />
    </>
  )
}

BuildingDetail.propTypes = {
  building: BuildingType.isRequired,
  taskInitValues: PropTypes.object,
}

export default BuildingDetail
