import * as R from 'ramda'

import { checkEmailAddress, checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = ['username', 'roleId', 'firstName', 'lastName', 'fiscalCode', 'phone', 'dateIn', 'burdenCenter']

  checkRequired(err, required, fields)
  checkEmailAddress(err, fields, 'username')

  setErrors(err)
  return R.isEmpty(err)
}

export const getErroredFieldTab = (errors) => {
  const fieldToTabIndexMap = [
    {
      tab: 0,
      field: 'username',
    },
    {
      tab: 0,
      field: 'roleId',
    },
    {
      tab: 0,
      field: 'dateIn',
    },
    {
      tab: 0,
      field: 'dateOut',
    },
    {
      tab: 1,
      field: 'firstName',
    },
    {
      tab: 1,
      field: 'lastName',
    },
    {
      tab: 1,
      field: 'fiscalCode',
    },
    {
      tab: 1,
      field: 'phone',
    },
    {
      tab: 1,
      field: 'burdenCenter',
    },
    {
      tab: 2,
      field: 'region',
    },
    {
      tab: 2,
      field: 'province',
    },
    {
      tab: 2,
      field: 'city',
    },
    {
      tab: 2,
      field: 'cap',
    },
    {
      tab: 2,
      field: 'address',
    },
    {
      tab: 2,
      field: 'houseNumber',
    },
  ]

  const erroredFields = R.filter((f) => R.includes(f.field, R.keys(errors)), fieldToTabIndexMap)

  const { tab } = R.head(R.sort((a, b) => a.tab - b.tab, R.isEmpty(erroredFields) ? [{ tab: 0 }] : erroredFields))

  return tab
}
