import { CardContent, CardHeader, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getEmployeeDisplayName } from '@Auth/Utils'
import { BranchBuildingType } from '@Buildings/Models/BranchBuilding'
import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'
import { Margin } from '@Common/Components/Styles'
import { CondoCurrentAdministrator, CondoType } from '@Condo/Models/Condo'

const BranchData = ({ branchData }) => {
  const { t } = useTranslation()

  return (
    <Margin bottom="2rem">
      <Card variant="outlined">
        <CardHeader title={t('common:components.BranchData.title')} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  {
                    name: t('common:components.BranchData.currentAdministrator'),
                    value: branchData?.currentAdministrator
                      ? CondoCurrentAdministrator.find((x) => x.id === branchData?.currentAdministrator)?.label
                      : null || '-',
                  },
                  {
                    name: t('common:components.BranchData.branch'),
                    value: branchData?.branchObj?.name || '-',
                  },
                  {
                    name: t('common:components.BranchData.seniorAdmin'),
                    value: branchData?.referringAdministratorObj
                      ? `${branchData?.referringAdministratorObj?.firstName || '-'} ${
                          branchData?.referringAdministratorObj?.lastName || '-'
                        }`
                      : null || '-',
                  },
                  {
                    name: t('common:components.BranchData.juniorAdmin'),
                    value: branchData?.dedicatedAdministratorObj
                      ? `${branchData?.dedicatedAdministratorObj?.firstName || '-'} ${
                          branchData?.dedicatedAdministratorObj?.lastName || '-'
                        } `
                      : null || '-',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  {
                    name: t('buildings:fields.accountingOwner'),
                    value: getEmployeeDisplayName(branchData?.accountingOwnerObj),
                  },
                  {
                    name: t('buildings:fields.administrationOwner'),
                    value: getEmployeeDisplayName(branchData?.administrationOwnerObj),
                  },
                  {
                    name: t('buildings:fields.accidentsOwner'),
                    value: getEmployeeDisplayName(branchData?.accidentsOwnerObj),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Margin>
  )
}

BranchData.propTypes = {
  branchData: PropTypes.oneOfType([CondoType, BranchBuildingType]),
}

export default BranchData
