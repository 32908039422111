import {
  AccountTree,
  Apartment,
  Ballot,
  Build,
  CircleNotifications,
  CleaningServices,
  Dashboard,
  DomainAdd,
  Engineering,
  Flag,
  Forum,
  ForwardToInbox,
  GasMeter,
  Group,
  HealthAndSafety,
  List,
  LocalOffer,
  Newspaper,
  Paid,
  PersonPinCircle,
  SupportAgent,
} from '@mui/icons-material'
import * as R from 'ramda'
import { useSelector } from 'react-redux'

import { selectBreadcrumbs } from '@Core/Redux/Ui'

import Breadcrumbs from './Breadcrumbs'
import { Box } from './Styles'

export const iconsMap = {
  Dashboard: Dashboard,
  Employee: Group,
  Resident: Ballot,
  Branch: PersonPinCircle,
  BuildingManagement: DomainAdd,
  Invitation: ForwardToInbox,
  SupportAgent: SupportAgent,
  Newspaper: Newspaper,
  Service: CleaningServices,
  List: List,
  Tree: AccountTree,
  Offer: LocalOffer,
  Wrench: Build,
  Notifications: CircleNotifications,
  Tickets: Flag,
  Building: Apartment,
  GasMeter: GasMeter,
  HealthAndSafety: HealthAndSafety,
  Paid: Paid,
  EngineeringIcon: Engineering,
  Tms: Forum,
}

const AdminBreadcrumbs = () => {
  const breadcrumbs = useSelector(selectBreadcrumbs)

  return (
    <Box pad="1rem" background="lightOne">
      <Breadcrumbs items={breadcrumbs.map(R.modify('Icon', (iconName) => iconsMap[iconName]))} />
    </Box>
  )
}

export default AdminBreadcrumbs
