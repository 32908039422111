import { FormControl, Grid } from '@mui/material'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useTranslation } from 'react-i18next'

import Checkbox from '@Common/Components/Checkbox'
import DatePicker from '@Common/Components/DatePicker'
import FileField from '@Common/Components/FileField'
import { Help } from '@Common/Components/Forms'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Box, Cursor, Label, Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'

import { NewsType } from '../../Models/News'
import { validate } from './Validation'

const NewsForm = ({ onClose, onSave, news }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fields, setField, errors, setErrors } = useForm(
    {
      title: R.defaultTo('', news?.title),
      date: R.defaultTo(null, news?.date),
      abstract: R.defaultTo('', news?.abstract),
      content: news
        ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(news.content)))
        : EditorState.createEmpty(),
      image: [],
      featured: R.defaultTo(false, news?.featured),
      published: R.defaultTo(false, news?.published),
    },
    true,
  )

  const onDrop = (acceptedFiles) => {
    setField('image', 'array')(acceptedFiles)
  }

  const onEditorStateChange = setField('content')

  const submit = async () => {
    logger.debug('News form submission, fields:', fields)
    if (validate(fields, setErrors, news)) {
      logger.debug('News form submission, validation passed, saving')
      setIsSubmitting(true)
      const { error, isSuccessful } = await onSave(fields)
      if (!isSuccessful && error?.status >= 400 && error?.status <= 499) {
        setIsSubmitting(false)
        logger.debug('News form submission api error', error)
        setErrors(convertServerErrors(error))
      } else {
        onClose()
      }
    } else {
      logger.debug('News form submission, validation failed')
    }
  }

  const title = news?.id ? t('news:ui.ModifyNews') : t('news:ui.AddNews')

  return (
    <Modal title={title} size="md" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              required
              label={t('news:fields.title')}
              value={fields.title}
              onChange={setStateFromEvent(setField('title'))}
              error={!!errors.title}
              helperText={errors.title}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <DatePicker
              label={t('news:fields.date')}
              value={fields.date}
              onChange={setField('date')}
              error={!!errors.date}
              helperText={errors.date}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              multiline
              required
              label={t('news:fields.abstract')}
              value={fields.abstract}
              onChange={setStateFromEvent(setField('abstract'))}
              error={!!errors.abstract}
              helperText={errors.abstract}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            checked={fields.featured}
            onChange={R.pipe(R.prop('target'), R.prop('checked'), setField('featured'))}
            label={t('news:fields.featured')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            checked={fields.published}
            onChange={R.pipe(R.prop('target'), R.prop('checked'), setField('published'))}
            label={t('news:fields.published')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Margin bottom="1rem" top="1rem">
              <Label>{t('news:fields.image')} *</Label>
            </Margin>
            {news?.image && (
              <Box margin="0 0 1rem">
                {t('common:ui.CurrentImage')}:
                <Margin top="1rem">
                  <img src={news.image} style={{ maxWidth: '100%' }} />
                </Margin>
              </Box>
            )}
            <Cursor type="pointer">
              <FileField
                onDrop={onDrop}
                files={fields.image}
                accept={'image/jpeg,image/png'}
                warningText={t('news:info.ImageLimit')}
                error={!!errors.image}
                helperText={errors.image}
              />
            </Cursor>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Margin bottom="1rem" top="1rem">
            <Label>{t('news:fields.content')} *</Label>
          </Margin>
          <FormControl fullWidth>
            <Editor
              editorState={fields.content}
              wrapperClassName="wysiwyg-wrapper"
              editorClassName="wysiwyg-editor"
              onEditorStateChange={onEditorStateChange}
            />
            {errors.content && <Help error={errors.content}>{errors.content}</Help>}
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  )
}

NewsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  news: NewsType,
}

export default NewsForm
