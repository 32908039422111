import * as R from 'ramda'
import { HQ_ROLES } from '@Auth/Costants'
import { hasPermission } from '@Common/Utils/Permissions'

export const ResidentsPermissions = {
  read: hasPermission('accounts.view_resident'),
  create: hasPermission('accounts.add_resident'),
  update: (user, obj) =>
    R.and(hasPermission('accounts.change_resident')(user), R.compose(R.not, R.path(['user', 'isSuperUser']))(obj)),
  delete: (user, obj) =>
    R.and(hasPermission('accounts.delete_resident')(user), R.compose(R.not, R.path(['user', 'isSuperUser']))(obj)),
  notify: R.either(
    R.pipe(R.prop('groups'), R.defaultTo([]), R.head, R.prop('id'), R.flip(R.includes)(R.values(HQ_ROLES))),
    R.prop('isSuperUser'),
  ),
}
