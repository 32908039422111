import React from 'react'
import { useBreadcrumbs } from '../../../Common/Utils/Hooks'
import { useTranslation } from 'react-i18next'
import { Divider, Chip, Alert } from '@mui/material'
import SearchAnagraphicForm from '../../Forms/SearchAnagraphicForm'
import SearchBuildingForm from '../../Forms/SearchBuildingForm'
import AnagraphicSearchResults from '../../Components/AnagraphicSearchResults'
import BuildingSearchResults from '../../Components/BuildingSearchResults'
import styled from 'styled-components'
import config from '@Config'
import { Box } from '../../../Common/Components/Styles'

const Main = styled(Box)`
  flex-grow: 1;
`

const SearchColumn = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.lightTwo.main};
  min-height: 100%;
  padding: 1rem;
  width: 240px;
`
const ResultsColumn = styled.div`
  flex: 1;
`

const CustomerCareView = () => {
  const { t } = useTranslation()
  const [form, setForm] = React.useState({ type: null, fields: null })

  useBreadcrumbs([
    { label: t('common:navigation.CustomerCare'), path: config.urls.admin.customerCare, Icon: 'SupportAgent' },
  ])

  const handleSearchAnagraphicSubmit = fields => setForm({ type: 'anagraphic', fields })
  const handleSearchBuildingSubmit = fields => setForm({ type: 'building', fields })

  return (
    <Main direction="row">
      <SearchColumn>
        <Divider style={{ marginBottom: '1rem' }}>
          <Chip color='secondary' style={{ fontWeight: 700 }} label={t('customerCare:ui.SearchAnagraphic')} />
        </Divider>
        <SearchAnagraphicForm onSubmit={handleSearchAnagraphicSubmit} />
        <Divider style={{ margin: '2rem 0 1rem' }}>
          <Chip color='secondary' style={{ fontWeight: 700 }} label={t('customerCare:ui.SearchBuilding')} />
        </Divider>
        <SearchBuildingForm onSubmit={handleSearchBuildingSubmit} />
      </SearchColumn>
      <ResultsColumn>
        {form.type === 'anagraphic' && <AnagraphicSearchResults fields={form.fields} />}
        {form.type === 'building' && <BuildingSearchResults fields={form.fields} />}
        {form.type === null && <Alert severity='info'>{t('customerCare:ui.PleasePerformSearch')}</Alert>}
      </ResultsColumn>
    </Main>
  )
}

export default CustomerCareView
