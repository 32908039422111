import i18next from 'i18next'
import PropTypes from 'prop-types'
import { ArcadiaEnumType } from '@Common/Types/EnumType'
import { ContactType } from '@Buildings/Models/Contact'
import { AddressType, BuildingSummaryType } from '@Buildings/Models/Building'
import { ResidentType } from '@Residents/Models/Resident'

const AnagraphicHousingUnitType = PropTypes.shape({
  codice: PropTypes.string,
  descrizione: PropTypes.string,
  edificio: PropTypes.shape({
    descrizione: PropTypes.string,
    id: PropTypes.string,
  }),
  id: PropTypes.string,
  immobileId: PropTypes.string,
  interno: PropTypes.string,
  piano: PropTypes.string,
  scala: PropTypes.shape({
    descrizione: PropTypes.string,
    id: PropTypes.string,
  }),
})

export const AnagraphicType = PropTypes.shape({
  id: PropTypes.string,
  classe: ArcadiaEnumType,
  tipo: ArcadiaEnumType,
  titolo: PropTypes.string,
  nominativo: PropTypes.string,
  cognome: PropTypes.string,
  nome: PropTypes.string,
  ragioneSociale: PropTypes.string,
  partitaIva: PropTypes.string,
  codiceFiscale: PropTypes.string,
  contatti: PropTypes.arrayOf(ContactType),
  residenzaSedeLegale: AddressType,
  domicilioSedeOperativa: AddressType,
  immobili: PropTypes.arrayOf(BuildingSummaryType),
  unitaImmobiliari: PropTypes.arrayOf(AnagraphicHousingUnitType),
  extra: ResidentType,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('auth:fields.anagraphicId'),
    },
    {
      id: 'classe.displayValue',
      label: i18next.t('auth:fields.anagraphic.class'),
      ordering: false,
    },
    {
      id: 'tipo.displayValue',
      label: i18next.t('auth:fields.anagraphic.type'),
      ordering: false,
    },
    {
      id: 'titolo',
      label: i18next.t('auth:fields.anagraphic.title'),
    },
    {
      id: 'nominativo',
      label: i18next.t('auth:fields.anagraphic.name'),
    },
    {
      id: 'cognome',
      label: i18next.t('auth:fields.lastName'),
    },
    {
      id: 'nome',
      label: i18next.t('auth:fields.firstName'),
    },
    {
      id: 'ragioneSociale',
      label: i18next.t('auth:fields.anagraphic.companyName'),
    },
    {
      id: 'partitaIva',
      label: i18next.t('auth:fields.anagraphic.vat'),
    },
    {
      id: 'codiceFiscale',
      label: i18next.t('auth:fields.fiscalCode'),
    },
    {
      id: 'emails',
      label: i18next.t('auth:fields.anagraphicEmails'),
      ordering: false,
    },
    {
      id: 'extra.id',
      label: i18next.t('residents:fields.id'),
      numeric: true,
      width: '40px',
      ordering: false,
    },
    {
      id: 'extra.user.username',
      label: i18next.t('residents:fields.email'),
      ordering: false,
    },
    {
      id: 'extra.phone',
      label: i18next.t('residents:fields.phone'),
      ordering: false,
    },
    {
      id: 'extra.user.isActive',
      label: i18next.t('residents:fields.active'),
      boolean: true,
      ordering: false,
    },
    {
      id: 'immobili',
      label: i18next.t('residents:fields.buildings'),
      ordering: false,
    },
  ],
}
