import { Button, useMediaQuery } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import PropTypes from 'prop-types'
import { ifElse } from 'ramda'
import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FixedFooter from '@Common/Components/FixedFooter'
import CheckboxField from '@Common/Components/Forms/CheckboxField'
import TextField from '@Common/Components/Forms/TextField'
import Loader from '@Common/Components/Loader'
import { Box } from '@Common/Components/Styles'
import { useAutoSave } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import logger from '@Common/Utils/Logger'
import { selectAdminSidebar } from '@Core/Redux/Ui'
import {
  CategoryPanel,
  CategoryPanelContainer,
  FormControl,
  SectionTitle,
  TaskFlowContainer,
} from '@Tms/Components/Styled'
import RestoreChangesDialog from '@Tms/Forms/CategoryForm/RestoreChangesDialog'
import UnsavedChangesDialog from '@Tms/Forms/CategoryForm/UnsavedChangesDialog'
import { TaskCategoryType } from '@Tms/Models/TaskCategory'

import CategorySelect from '../Selects/CategorySelect'
import TaskForm from '../TaskForm'
import CategoryValidationAlert from './CategoryValidationAlert'
import RolesCheckboxes from './RolesCheckboxes'
import useCategoryForm from './useCategoryForm'

const CategoryForm = ({ category, onCancel, onSave, onAutoSave, onDeleteDraft }) => {
  const { t } = useTranslation()
  const isEditing = !!category?.id
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { expanded, expandedWidth, collapsedWidth } = useSelector(selectAdminSidebar)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [draft, setDraft] = useState(category?.draft ?? null)
  const [restoreDraftDialogOpen, setRestoreDraftDialogOpen] = useState(false)
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false)

  const getSidebarWidth = ifElse(
    () => isSmallDevice,
    () => 0,
    () => (expanded ? expandedWidth : collapsedWidth),
  )

  const { register, onSubmit, errors, control, ...methods } = useCategoryForm(
    category,
    onSave,
    onCancel,
    setIsSubmitting,
  )

  const handleCancel = () => {
    // if there is an unsaved draft, open restore changes dialog
    if (
      isEditing &&
      // methods.formState.isDirty &&
      draft?.modified &&
      category?.modified &&
      draft?.modified > category?.modified
    ) {
      setUnsavedChangesDialogOpen(true)
    } else {
      onCancel()
    }
  }

  const handleRestoreDraft = () => {
    // populate form with draft values
    methods.reset({ ...methods.getValues(), ...draft.payload }, { keepTouched: false, keepDirty: false })
    setRestoreDraftDialogOpen(false)
  }

  const handleDiscardRestore = async () => {
    await onDeleteDraft()
    setRestoreDraftDialogOpen(false)
  }

  const handleSaveForLater = async () => {
    await handleAutoSave()
    onCancel()
  }

  const handleDiscardUnsaved = async () => {
    await onDeleteDraft()
    setUnsavedChangesDialogOpen(false)
    onCancel()
  }

  const handleAutoSave = async () => {
    const fields = methods.getValues()
    logger.debug('Category form autosave:', fields)
    const { response } = await onAutoSave(fields)
    setDraft(response)
  }

  useEffect(() => {
    if (isEditing && draft?.modified && category?.modified && draft?.modified > category?.modified) {
      // check if there is an unsaved draft and open restore changes dialog
      setRestoreDraftDialogOpen(true)
    }
  }, [])

  useAutoSave(handleAutoSave, 60 * 1000, isEditing && !restoreDraftDialogOpen && !unsavedChangesDialogOpen)

  Logger.debug('CategoryForm errors', errors)

  return (
    <>
      <FormProvider register={register} errors={errors} control={control} {...methods}>
        <form>
          {isSubmitting && <Loader overlay />}
          <CategoryPanelContainer>
            <CategoryPanel>
              <SectionTitle>{t('tms:ui.CategoryConfiguration')}</SectionTitle>
              <Box margin="0 0 1rem">
                <FormControl>
                  <TextField
                    required
                    name="name"
                    control={control}
                    label={t('tms:fields.categoryName')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </FormControl>
                <FormControl>
                  <CategorySelect
                    qs={{ ordering: 'name' }}
                    id={category?.id || 0}
                    name="parent"
                    control={control}
                    label={t('tms:fields.parent')}
                  />
                </FormControl>
                <FormControl>
                  <CheckboxField name="isActive" control={control} label={t('tms:fields.isActive')} />
                </FormControl>
              </Box>
              <SectionTitle>{t('tms:ui.Fields')}</SectionTitle>
              <Box margin="0 0 1rem">
                <FormControl margin="none">
                  <CheckboxField name="isResidentEnabled" control={control} label={t('tms:fields.isResidentEnabled')} />
                </FormControl>
                <FormControl margin="none">
                  <CheckboxField name="isBuildingEnabled" control={control} label={t('tms:fields.isBuildingEnabled')} />
                </FormControl>
                <FormControl margin="none">
                  <CheckboxField
                    name="isAdminSelectEnabled"
                    control={control}
                    label={t('tms:fields.isAdminSelectEnabled')}
                  />
                </FormControl>
              </Box>
              <SectionTitle>{t('tms:ui.OpenPrivileges')}*</SectionTitle>
              <FormHelperText error={!!errors.openPrivileges}>{t('tms:info.ChooseAtLeastOneRole')}</FormHelperText>
              <Box margin="0 0 1rem">
                <RolesCheckboxes />
              </Box>
            </CategoryPanel>
          </CategoryPanelContainer>
          <TaskFlowContainer sidebarWidth={getSidebarWidth()}>
            <SectionTitle>{t('tms:ui.TaskFlowConfiguration')}</SectionTitle>
            {errors?.mainTask && <CategoryValidationAlert errors={errors.mainTask} />}
            <TaskForm prefix="mainTask." isMainTask isReadOnly={category?.hasTaskFlows} />
          </TaskFlowContainer>
          <FixedFooter sidebarWidth={getSidebarWidth()}>
            <Button onClick={handleCancel} key="cancel" color="darkThree">
              {t('common:actions.Cancel')}
            </Button>
            <Button disabled={isSubmitting} onClick={onSubmit} key="submit" color="primary">
              {t('common:actions.Save')}
            </Button>
          </FixedFooter>
        </form>
      </FormProvider>
      <RestoreChangesDialog
        onDiscard={handleDiscardRestore}
        onRestore={handleRestoreDraft}
        open={restoreDraftDialogOpen}
      />
      <UnsavedChangesDialog
        onSaveForLater={handleSaveForLater}
        onCancel={() => setUnsavedChangesDialogOpen(false)}
        onDiscardChanges={handleDiscardUnsaved}
        open={unsavedChangesDialogOpen}
      />
    </>
  )
}

CategoryForm.propTypes = {
  category: TaskCategoryType,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onAutoSave: PropTypes.func.isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
}

export default CategoryForm
