import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { useConfirm } from '@Common/Utils/Hooks'
import { camelToSnakeCase } from '@Common/Utils/Strings'
import { apiList } from '@Core/Services/Api'

import Employee from '../Models/Employee'
import { EmployeesPermissions } from '../Permissions'
import { useEmployeesQuery } from '../Services/Api'

const DISPLAY_COLUMNS = ['id', 'firstName', 'lastName', 'user.username', 'role.name', 'cityObj.name', 'user.isActive']

const SEARCH_FIELDS = ['user.username', 'lastName']

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${camelToSnakeCase(field).replace('.', '__')}__icontains__or`] = qs.qsAdditions.search
    })
    delete qs.qsAdditions.search
  }

  return qs
}

const EmployeesList = ({ onEdit, onDelete, onChangePassword }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-employees'),
      offset: 0,
      ordering: '-id',
    },
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { count: employeesCount, data: employees, isFetching } = apiList(useEmployeesQuery(convertSearchQuery(qs)))

  const handleDeleteUser = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteUser)
  const bulkActions = React.useMemo(
    () => [{ id: 'DELETE', label: t('auth:ui.DeleteSelectedUsers'), perm: EmployeesPermissions.delete }],
    [],
  )
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: EmployeesPermissions.update,
      },
      {
        id: 'CHANGE_PASSWORD',
        label: t('auth:actions.ChangePassword'),
        icon: <LockIcon size="small" />,
        perm: EmployeesPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: EmployeesPermissions.delete,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, employee) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('auth:ui.DeleteUserConfirmationTitle'),
          t('auth:ui.DeleteUserConfirmationText', { count: selected.length }),
          { selected: selected, field: 'user.username' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(employee)
      } else if (actionId === 'CHANGE_PASSWORD') {
        onChangePassword(employee)
      }
    },
    [openDeleteConfirm, selected],
  )

  return (
    <div>
      <DataTable
        name="admin-employees"
        qs={qs}
        data={employees}
        dataCount={employeesCount}
        refreshData={refreshData}
        model={Employee}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        bulkActions={bulkActions}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      />
      {DeleteConfirm}
    </div>
  )
}

EmployeesList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
}

export default EmployeesList
