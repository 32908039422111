import { Box as MuiBox, Button, FormControl, Grid, Tab, Tabs } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BranchSelect from '@Branches/Components/BranchSelect'
import { useBranchesQuery } from '@Branches/Services/Api'
import Alert from '@Common/Components/Alert'
import Checkbox from '@Common/Components/Checkbox'
import DatePicker from '@Common/Components/DatePicker'
import Dropdown from '@Common/Components/Dropdown'
import Loader from '@Common/Components/Loader'
import RegionProvinceCitySelect from '@Common/Components/Location/RegionProvinceCitySelect'
import MapField from '@Common/Components/MapField'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import { TabPanel } from '@Common/Components/TabPanel'
import TextField from '@Common/Components/TextField'
import { useCitiesQuery } from '@Common/Services/Api/LocationApi'
import { setStateFromCheckboxEvent, setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { fetchGeoLocation } from '@Common/Utils/Geolocation'
import { useForm } from '@Common/Utils/Hooks'
import { CondoAcquisitionMode, CondoCurrentAdministrator, CondoState, CondoType } from '@Condo/Models/Condo'
import { apiList } from '@Core/Services/Api'

import { validate, getErroredFieldTab, checkIsOrganicAcquisitionMode } from './Validation'

const emptyAdmin = { id: '', label: '--' }

const CondoForm = ({ condo, onClose, onSave }) => {
  const { t } = useTranslation()

  const { data: cities } = apiList(useCitiesQuery())

  const name = fields?.description || condo?.description || ''
  const title = condo.id ? t('condo:ui.EditCondo', { name }) : t('condo:ui.CreateCondo')

  // app state
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // handle form tab
  const handleTabChange = (_, idx) => setActiveTabIndex(idx)

  // create array of administrative items
  const mapAdministrativesObjForDropdown = (data) => {
    return data?.map((item) => ({
      id: item.id,
      label: `${item.firstName} ${item.lastName}`,
    }))
  }

  // hooks
  const { fields, setField, errors, setErrors, setFields } = useForm(
    {
      ...condo,
      fiscalCodeNotAvailable: R.defaultTo(false, condo?.id ? (condo.fiscalCode ? false : true) : false),
      state: R.defaultTo(CondoState[0].id, condo?.state),
      ...{
        region: condo?.cityObj?.provinceObj?.region || null,
        province: condo?.cityObj?.province || null,
        city: condo?.cityObj?.id || null,
        branch: condo?.branchObj?.id || null,
        lng: condo?.lng || null,
        lat: condo?.lat || null,
      },
    },
    true,
  )

  useEffect(() => {
    if (errors && R.keys(errors).length) {
      setActiveTabIndex(getErroredFieldTab(errors))
    }
  }, [errors])

  const { data: branches } = apiList(useBranchesQuery())

  // list of senior and junior administratives based on selected branch
  const [seniorAdministratives, setSeniorAdministratives] = useState(
    condo?.branchObj?.seniorAdministrativesObjs
      ? mapAdministrativesObjForDropdown([...condo?.branchObj?.seniorAdministrativesObjs, condo?.branchObj?.managerObj])
      : [],
  )

  const [juniorAdministratives, setJuniorAdministratives] = useState(
    condo?.branchObj?.juniorAdministrativesObjs
      ? mapAdministrativesObjForDropdown(condo?.branchObj?.juniorAdministrativesObjs)
      : [],
  )

  const handleCalculateLatLng = async () => {
    const cityName = R.prop('name', R.find(R.propEq('id', fields.city), cities))

    let search = ['city', 'address', 'houseNumber', 'cap']

    let address = R.pipe(
      R.pick(search),
      R.modify('city', R.always(cityName)),
      R.values,
      R.filter(R.compose(R.not, R.either(R.isEmpty, R.isNil))),
      R.join(' '),
    )(fields)

    let count = search.length

    for (let index = 0; index < count; index++) {
      let data = await getDataLatLng(address)

      if (data) {
        setFields({ ...fields, ...data })
        break
      } else {
        search.pop()

        address = R.pipe(
          R.pick(search),
          R.modify('city', R.always(cityName)),
          R.values,
          R.filter(R.compose(R.not, R.either(R.isEmpty, R.isNil))),
          R.join(' '),
        )(fields)
      }
    }
  }

  const getDataLatLng = async (address) => {
    const data = await fetchGeoLocation(address)

    if (data.length > 0) {
      return { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) }
    }

    return null
  }

  const handleChangeLatLng = (position) => {
    setFields({ ...fields, lat: position[0], lng: position[1] })
  }

  // handle submit form
  const submit = async () => {
    const isValid = validate(fields, setErrors)

    if (!isValid) return

    setIsSubmitting(true)

    const condo = buildCondoPayload(fields)
    const { error, isSuccessful } = await onSave(condo)

    setIsSubmitting(false)

    if (!isSuccessful) {
      return setErrors(convertServerErrors(error))
    }

    return onClose()
  }

  // format date
  const formatDate = (date) => (date ? dayjs(date).format('YYYY-MM-DD') : null)

  // transform fields for API
  const buildCondoPayload = (fields) => {
    const { acquisitionDate, appointmentDate, managementClosingDate, lossDate } = fields

    const payload = {
      ...fields,
      acquisitionDate: acquisitionDate ? formatDate(acquisitionDate) : null,
      managementClosingDate: managementClosingDate ? formatDate(managementClosingDate) : null,
      appointmentDate: appointmentDate ? formatDate(appointmentDate) : null,
      lossDate: lossDate ? formatDate(lossDate) : null,
    }

    if (checkIsOrganicAcquisitionMode(fields)) {
      payload.acquisitionDate = null
      payload.currentAdministrator = null
    }

    if (fields?.fiscalCodeNotAvailable) {
      payload.fiscalCode = null
    }

    return payload
  }

  const populateSeniorAdministratives = (branch) => {
    // set senior administratives and manager
    const seniorAdministratives = mapAdministrativesObjForDropdown([
      ...branch?.seniorAdministrativesObjs,
      branch?.managerObj,
    ])

    setSeniorAdministratives(seniorAdministratives)
  }

  const populateJuniorAdministratives = (branch) => {
    // set junior administratives
    const juniorAdministratives = mapAdministrativesObjForDropdown(branch?.juniorAdministrativesObjs)
    setJuniorAdministratives(juniorAdministratives)
  }

  const handleBranchChange = (event) => {
    // get current branch
    const branch = branches.find(R.propEq('id', event.target.value))

    populateSeniorAdministratives(branch)

    populateJuniorAdministratives(branch)

    setStateFromEvent(setField('branch'))(event)
  }

  const renderTab0 = (
    <TabPanel activeIndex={activeTabIndex} index={0}>
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              value={fields?.fiscalCode}
              label={t('condo:fields.fiscalCode')}
              onChange={setStateFromEvent(setField('fiscalCode'))}
              error={!!errors.fiscalCode}
              helperText={errors.fiscalCode}
              required={!fields?.fiscalCodeNotAvailable}
              disabled={fields?.fiscalCodeNotAvailable}
              inputProps={{ maxLength: 11 }}
            />
          </FormControl>

          <Checkbox
            checked={fields?.fiscalCodeNotAvailable}
            onChange={setStateFromCheckboxEvent(setField('fiscalCodeNotAvailable'))}
            label={t('condo:fields.notPresentFiscalCode')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              value={fields?.description}
              label={t('condo:fields.name')}
              onChange={setStateFromEvent(setField('description'))}
              error={!!errors.description}
              helperText={errors.description}
              required
            />
          </FormControl>
        </Grid>

        <RegionProvinceCitySelect fields={fields} errors={errors} setField={setField} setFields={setFields} required />

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              value={fields?.cap}
              label={t('condo:fields.cap')}
              onChange={setStateFromEvent(setField('cap'))}
              error={!!errors.cap}
              helperText={errors.cap}
              inputProps={{ maxLength: 5 }}
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={9}>
          <FormControl fullWidth>
            <TextField
              value={fields?.address}
              label={t('condo:fields.address')}
              onChange={setStateFromEvent(setField('address'))}
              error={!!errors.address}
              helperText={errors.address}
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <TextField
              value={fields?.houseNumber}
              label={t('condo:fields.houseNumber')}
              onChange={setStateFromEvent(setField('houseNumber'))}
              error={!!errors.houseNumber}
              helperText={errors.houseNumber}
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Box direction="row" justify="center">
            <Button
              onClick={handleCalculateLatLng}
              variant="contained"
              style={{ marginBottom: '1rem' }}
              disabled={!fields.city}
            >
              {t('common:ui.CalculateLatLng')}
            </Button>
          </Box>
          {errors.lat && <Alert severity="error">{t('common:validation:LatLngRequired')}</Alert>}
          <MapField latLng={fields.lat ? [fields.lat, fields.lng] : undefined} onChangePosition={handleChangeLatLng} />
        </Grid>
      </Grid>
    </TabPanel>
  )

  const renderTab1 = (
    <TabPanel activeIndex={activeTabIndex} index={1}>
      <Grid container rowSpacing={3} spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Dropdown
              options={CondoState}
              labelKey="label"
              valueKey="id"
              onChange={setStateFromEvent(setField('state'))}
              error={!!errors.state}
              helperText={errors.state}
              value={fields.state}
              label={t('condo:fields.status.__field')}
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Dropdown
              options={CondoAcquisitionMode}
              labelKey="label"
              valueKey="id"
              onChange={setStateFromEvent(setField('acquisitionMode'))}
              value={fields.acquisitionMode}
              label={t('condo:fields.acquisitionMode.__field')}
              error={!!errors.acquisitionMode}
              helperText={errors.acquisitionMode}
              required
            />
          </FormControl>
        </Grid>

        {!checkIsOrganicAcquisitionMode(fields) && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <DatePicker
                fullWidth
                label={t('condo:fields.acquisitionDate')}
                value={fields.acquisitionDate || null}
                onChange={setField('acquisitionDate')}
                error={!!errors.acquisitionDate}
                helperText={errors.acquisitionDate}
                required={!checkIsOrganicAcquisitionMode(fields)}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <DatePicker
              fullWidth
              label={t('condo:fields.managementClosingDate')}
              value={fields.managementClosingDate || null}
              onChange={setField('managementClosingDate')}
              error={!!errors.managementClosingDate}
              helperText={errors.managementClosingDate}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <DatePicker
              fullWidth
              label={t('condo:fields.appointmentDate')}
              value={fields.appointmentDate || null}
              onChange={setField('appointmentDate')}
              error={!!errors.appointmentDate}
              helperText={errors.appointmentDate}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <DatePicker
              fullWidth
              label={t('condo:fields.lossDate')}
              value={fields.lossDate || null}
              onChange={setField('lossDate')}
              error={!!errors.lossDate}
              helperText={errors.lossDate}
            />
          </FormControl>
        </Grid>
      </Grid>
    </TabPanel>
  )

  const renderTab2 = (
    <TabPanel activeIndex={activeTabIndex} index={2}>
      <Grid container rowSpacing={3} spacing={2}>
        {!checkIsOrganicAcquisitionMode(fields) && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Dropdown
                options={CondoCurrentAdministrator}
                labelKey="label"
                valueKey="id"
                onChange={setStateFromEvent(setField('currentAdministrator'))}
                value={fields.currentAdministrator}
                label={t('condo:fields.currentAdministrator.__field')}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <BranchSelect
              required
              value={fields.branch}
              label={t('condo:tabsForm.branch')}
              onChange={handleBranchChange}
              error={!!errors.branch}
              helperText={errors.branch}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Dropdown
              options={[emptyAdmin, ...seniorAdministratives]}
              labelKey="label"
              valueKey="id"
              onChange={setStateFromEvent(setField('referringAdministrator'))}
              value={fields.referringAdministrator}
              label={t('condo:fields.seniorAdmins')}
              error={!!errors.referringAdministrator}
              helperText={errors.referringAdministrator}
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Dropdown
              options={juniorAdministratives}
              labelKey="label"
              valueKey="id"
              onChange={setStateFromEvent(setField('dedicatedAdministrator'))}
              value={fields.dedicatedAdministrator}
              label={t('condo:fields.juniorAdmins')}
              error={!!errors.dedicatedAdministrator}
              helperText={errors.dedicatedAdministrator}
            />
          </FormControl>
        </Grid>
      </Grid>
    </TabPanel>
  )

  return (
    <Modal title={title} size="lg" onClose={onClose} onSubmit={submit} sx={{ paddingTop: 0 }}>
      {isSubmitting && <Loader overlay />}
      <Box>
        <MuiBox sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: '1rem' }}>
          <Tabs value={activeTabIndex} onChange={handleTabChange} style={{ overflow: 'auto' }}>
            <Tab label={t('condo:tabsForm.anagrafic')} />
            <Tab label={t('condo:tabsForm.acquisition')} />
            <Tab label={t('condo:tabsForm.branch')} />
          </Tabs>
        </MuiBox>

        {renderTab0}
        {renderTab1}
        {renderTab2}
      </Box>
    </Modal>
  )
}

CondoForm.propTypes = {
  condo: CondoType.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default CondoForm
