import i18next from 'i18next'
import PropTypes from 'prop-types'

export const CategoryType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  slug: PropTypes.string,
  children: PropTypes.any,
  parent: PropTypes.any,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'parentName',
      label: i18next.t('services:fields.category.ParentName'),
      ordering: false,
    },

    {
      id: 'name',
      label: i18next.t('services:fields.category.Name'),
      ordering: false,
    },

    {
      id: 'slug',
      label: i18next.t('services:fields.category.Slug'),
      ordering: false,
    },

    {
      id: 'logo',
      label: i18next.t('services:fields.category.Logo'),
      ordering: false,
    },
    {
      id: 'orderingNumber',
      label: i18next.t('services:fields.category.OrderingNumber'),
      ordering: false,
    },
  ],
}
