export const CityModel = {
  name: 'City',
  url: '/location/cities',
  schema: {
    name: { required: true },
    province: {
      type: 'foreign_key',
      model: ProvinceModel,
    },
  },
}

export const ProvinceModel = {
  name: 'Province',
  url: '/location/provinces',
  schema: {
    name: { required: true },
    region: {
      type: 'foreign_key',
      model: RegionModel,
    },
  },
}

export const RegionModel = {
  name: 'Region',
  url: '/location/regions',
  schema: {
    name: { required: true },
  },
}
