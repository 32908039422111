import PropTypes from 'prop-types'
import React from 'react'

import { useLazyPersonalDocumentsQuery } from '@Documents/Services/Api'
import DocumentsList from '@MyHome/Components/DocumentsList'

const ResidentDocuments = ({ resident }) => {
  return <DocumentsList type="personal" lazyFetchFn={useLazyPersonalDocumentsQuery} fetchId={resident?.id} />
}

ResidentDocuments.propTypes = {
  resident: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default ResidentDocuments
