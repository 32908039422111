import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Error503 from '@Assets/Images/error503.svg'
import Logo from '@Assets/Logo'
import { Box, Cursor } from '@Common/Components/Styles'
import config from '@Config'

import { Container, Header, Image, Text, Title } from '../Components/ErrorPages'

const MaintenanceView = () => {
  const { t } = useTranslation()

  const navigateToHome = () => {
    window.location.replace(config.urls.home)
  }

  return (
    <Container>
      <Header>
        <Cursor type="pointer" onClick={navigateToHome}>
          <Logo fill="white" height="40px" />
        </Cursor>
      </Header>
      <Title>{t('common:ui.Maintenance')}</Title>
      <Text>{t(`common:errors.Maintenance`)}</Text>
      <Box direction="row" justify="center">
        <Image src={Error503} />
      </Box>
      <Box direction="row" justify="center" margin="3rem 0">
        <Button variant="contained" onClick={navigateToHome}>
          {t('common:ui.ReturnHome')}
        </Button>
      </Box>
    </Container>
  )
}

export default MaintenanceView
