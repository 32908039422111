import { SupplierBranchForm } from '@Supplier/Forms/SupplierBranchForm'

import { CityModel, ProvinceModel, RegionModel } from './CityModel'
import { ServiceType } from './ServiceType'
import { SupplierReferent } from './SupplierReferent'

export const SupplierBranch = {
  name: 'SupplierBranch',
  form: SupplierBranchForm,
  url: '/condo/bicert/suppliers/:supplierId/branches/:id',
  schema: {
    branchReferents: { type: 'foreign_key', multiple: true, model: SupplierReferent },
    branchType: { required: true, type: 'options', choices: ['L', 'O'] },
    emails: { multiple: true, required: true, type: 'email' },
    telephones: { multiple: true, type: 'phone' },
    mobiles: { multiple: true, type: 'phone' },
    city: { type: 'foreign_key', required: true, model: CityModel },
    cap: { required: true, maxLength: 5 },
    address: { required: true },
    houseNumber: { required: true },
    services: { type: 'foreign_key', multiple: true, model: ServiceType },
    inheritServices: { type: 'boolean', defaultChecked: false },
    competenceAreaList: {
      type: 'array',
      model: SupplierBranchCompetenceArea,
      defaultValue: {
        country: 1,
        region: '',
        province: '',
        city: '',
        cap: '',
      },
    },
  },
}

export const SupplierBranchCompetenceArea = {
  name: 'SupplierBranchCompetenceArea',
  schema: {
    country: {},
    region: { type: 'foreign_key', model: RegionModel },
    province: { type: 'foreign_key', model: ProvinceModel },
    city: { type: 'foreign_key', model: CityModel },
    cap: { maxLength: 5 },
  },
}
