import { SvgIcon } from '@mui/material'
import React from 'react'

const TmsMyTasksIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <path d="m16.5 6.3h-0.6v11.4l-4.6-4.6h-1.8l-1.3 1.7c-0.2 0.3 0 0.7 0.4 0.7h1.4v2.8c0 1 0.8 1.9 1.9 1.9h0.9v2.8h6.5v-4.4c2.2-1 3.7-3.3 3.7-5.9 0-3.5-2.9-6.4-6.5-6.4z"/>
    <path d="M1,10.1c0,0.5,0.4,0.9,0.9,0.9h9.1l3.7,3.7V1.9c0-0.5-0.4-0.9-0.9-0.9H1.9C1.4,1,1,1.4,1,1.9V10.1"/>
  </SvgIcon>
))

TmsMyTasksIcon.displayName = 'TmsMyTasksIcon'

export default TmsMyTasksIcon;
