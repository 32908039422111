import { Grid, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import HomeBuildingIcon from '@Assets/Images/home_building_icon.svg'
import HomeHomeIcon from '@Assets/Images/home_home_icon.svg'
import HomePersonIcon from '@Assets/Images/home_person_icon.svg'
import { Box } from '@Common/Components/Styles'
import { makePath } from '@Config'
import { selectThemeMode } from '@Core/Redux/Ui'

import { selectActiveHousingUnitId } from '../Redux'

const Figure = styled.div`
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0 0 ${({ themeMode }) => (themeMode === 'light' ? '20px' : '0')}
    ${({ theme }) => theme.palette.darkThree.main};
  width: 290px;
  position: relative;
  background-color: ${({ themeMode, theme }) => (themeMode === 'light' ? 'transparent' : theme.palette.lightFive.main)};
`
const Image = styled.img`
  position: relative;
  top: -30px;
`
const Caption = styled.div`
  box-sizing: border-box;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: ${({ bottom }) => bottom || '14%'};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0 68px;
`

const HomeButtons = () => {
  const { t } = useTranslation()
  const housingUnitId = useSelector(selectActiveHousingUnitId)
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const themeMode = useSelector(selectThemeMode)

  return (
    <Grid container rowSpacing={isSmallDevice ? 4 : 1} columnSpacing={4}>
      <Grid item xs={12}>
        <Link to={makePath('myEstia.myServices.main', { service: 'servizi-persona' })}>
          <Box align="center">
            <Figure themeMode={themeMode}>
              <Image src={HomePersonIcon} />
              <Caption>{t('myEstia:ui.ForYou')}</Caption>
            </Figure>
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Link to={makePath('myEstia.myServices.main', { service: 'servizi-casa' })}>
          <Box align={isSmallDevice ? 'center' : 'flex-end'}>
            <Figure themeMode={themeMode}>
              <Image src={HomeHomeIcon} />
              <Caption bottom="6%">{t('myEstia:ui.ForYourHome')}</Caption>
            </Figure>
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Link to={makePath('myEstia.myHome.main', { housingUnitId })}>
          <Box align={isSmallDevice ? 'center' : 'flex-start'}>
            <Figure themeMode={themeMode}>
              <Image src={HomeBuildingIcon} />
              <Caption bottom="8%">{t('myEstia:ui.YourBuilding')}</Caption>
            </Figure>
          </Box>
        </Link>
      </Grid>
    </Grid>
  )
}
export default HomeButtons
