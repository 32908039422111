import PropTypes from 'prop-types'
import { BuildingSummaryType } from '@Buildings/Models/Building'
import i18next from 'i18next'

export const RepresentativeType = PropTypes.shape({
  immobile: BuildingSummaryType,
  descrizione: PropTypes.string,
  tipo: PropTypes.number,
  stato: PropTypes.number,
  inizio: PropTypes.string,
  fine: PropTypes.string,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'tipo.displayValue',
      label: i18next.t('buildings:fields.type'),
      ordering: false,
    },
    {
      id: 'descrizione',
      label: i18next.t('buildings:fields.description'),
      ordering: false,
    },
    {
      id: 'inizio',
      label: i18next.t('buildings:fields.startDate'),
      ordering: false,
      date: true,
    },
    {
      id: 'fine',
      label: i18next.t('buildings:fields.endDate'),
      ordering: false,
      date: true,
    },
    {
      id: 'stato.displayValue',
      label: i18next.t('buildings:fields.status'),
      ordering: false,
    },
  ],
}
