import React from 'react'
import styled from 'styled-components'

import ReportDetailView from '@Tickets/Views/ReportDetailView'

const Main = styled.div`
  margin: 1rem 2rem 0;
`

const MyHomeTicketsView = () => {
  return (
    <Main>
      <ReportDetailView isResident footerHeight="0px" />
    </Main>
  )
}

export default MyHomeTicketsView
