import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import FacilitiesList from '@Buildings/Components/FacilitiesList'
import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import { withLoader } from '@Common/Utils/HOC'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { useCondoByIdQuery } from '@Condo/Services/Api'

const Main = styled.section``

const BuildingFacilitiesView = () => {
  const { t } = useTranslation()
  const { buildingId } = useParams()
  const { data: building, isLoading } = useCondoByIdQuery(buildingId)

  useBreadcrumbs(
    [...getCondoBreadcrumbFromId(buildingId), { label: t('common:navigation.BuildingFacilities') }],
    building?.description,
    [building],
  )

  return <Main>{withLoader(<FacilitiesList buildingArcadiaId={building?.arcadiaId} />, isLoading)}</Main>
}

export default BuildingFacilitiesView
