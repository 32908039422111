import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = ['name']

  checkRequired(err, required, fields)

  setErrors(err)
  return R.isEmpty(err)
}
