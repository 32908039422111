// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Box, Margin } from '@Common//Components/Styles'
import { HousingUnitDetailType } from '@Buildings/Models/HousingUnit'
import * as R from 'ramda'

const HousingUnitCadastralData = ({ unit }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h6" color="primary" mb="1rem" mt='1rem'>
        {t('buildings:ui.CadastralData')}
      </Typography>
      <Box direction="row">
        <Box margin="0 3rem 0 0">
          <Margin bottom=".8rem">
            <strong>{t('buildings:fields.cadastralData.sheet')}</strong>: {R.defaultTo('--', unit.datiCatastali.foglio)}
          </Margin>
          <Margin bottom=".8rem">
            <strong>{t('buildings:fields.cadastralData.parcel')}</strong>:{' '}
            {R.defaultTo('--', unit.datiCatastali.particella)}
          </Margin>
        </Box>
        <Box margin="0 3rem 0 0">
          <Margin bottom=".8rem">
            <strong>{t('buildings:fields.cadastralData.parcelExt')}</strong>:{' '}
            {R.defaultTo('--', unit.datiCatastali.estensioneParticella)}
          </Margin>
          <Margin bottom=".8rem">
            <strong>{t('buildings:fields.cadastralData.subordinate')}</strong>:{' '}
            {R.defaultTo('--', unit.datiCatastali.subalterno)}
          </Margin>
        </Box>
      </Box>
    </>
  )
}

HousingUnitCadastralData.propTypes = {
  unit: HousingUnitDetailType,
}

export default HousingUnitCadastralData
