import i18next from 'i18next'
import * as R from 'ramda'
import { flatten, map, pipe, pluck } from 'ramda'

import { HQ_ROLES } from '@Auth/Costants'

export const canAccessAllTasks = R.either(
  R.prop('isSuperUser'),
  R.pipe(
    R.prop('groups'),
    R.defaultTo([]),
    R.head,
    R.prop('id'),
    R.flip(R.includes)(R.values([HQ_ROLES.HQ_IT, HQ_ROLES.HQ_MAIN_OFFICE])),
  ),
)

export const flattenTasks = (task, index = '0.') => {
  const tasks = []
  tasks.push({ ...task, index })
  if (task.children && task.children.length > 0) {
    task.children.forEach((t, i) => {
      const chidren = flattenTasks(t, `${index}${i}.`)
      tasks.push(...chidren)
    })
  }
  return tasks
}

export const getNewTaskId = (mainTask) => {
  const taskIds = mainTask ? pipe(flattenTasks, pluck('id'), map(String))(mainTask) : []
  const notStoredFieldIds = taskIds.filter((id) => id.startsWith('new_task_')).map((id) => parseInt(id.substring(9)))
  const key = notStoredFieldIds.length ? Math.max(...notStoredFieldIds) + 1 : 1
  return `new_task_${key}`
}

export const getNewFieldId = (mainTask) => {
  const fieldIds = mainTask ? pipe(flattenTasks, pluck('fields'), flatten, pluck('id'), map(String))(mainTask) : []
  const notStoredFieldIds = fieldIds.filter((id) => id.startsWith('new_field_')).map((id) => parseInt(id.substring(10)))
  const key = notStoredFieldIds.length ? Math.max(...notStoredFieldIds) + 1 : 1
  return `new_field_${key}`
}

export const getNewChoiceId = (choices) => {
  const notStoredFieldIds = choices
    .filter((c) => c.key.toString().includes('new_choice_'))
    .map((c) => parseInt(c.key.substring(11)))
  const key = notStoredFieldIds.length ? Math.max(...notStoredFieldIds) + 1 : 1
  return `new_choice_${key}`
}

export const getEmptyChoice = () => ({
  value: null,
  isJustificationRequired: false,
  notesText: null,
})

export const getEmptyTask = () => ({
  name: '',
  description: '',
  defaultPriority: 0,
  role: null,
  roleData: '',
  assignTo: 'who_opens_it',
  showInInfographic: false,
  minutesToDeadline: null,
  deadlineUnit: 'd',
  deadlineCondition: 5,
  deadlineTask: null,
  deadlineField: null,
  openConditions: [],
  closeConditions: [],
  fields: [],
  children: [],
})

export const getTaskStatus = (task) => {
  return task.status
  // return task.isCompleted
  //   ? STATUS_ENUM.COMPLETED
  //   : dayjs(task.deadline).isBefore(dayjs(), 'day')
  //   ? STATUS_ENUM.EXPIRED
  //   : task.isProcessing
  //   ? STATUS_ENUM.PROCESSING
  //   : STATUS_ENUM.OPEN
}

export const TASK_FIELD_TYPE_MAP = {
  0: 'text',
  1: 'textarea',
  2: 'select',
  3: 'date',
  4: 'attachment',
  5: 'resident',
  6: 'employee',
  7: 'supplier',
  8: 'url',
  9: 'utilities',
  10: 'building',
}

export const TASK_FIELD_TYPE_IDS = Object.fromEntries(
  Object.entries(TASK_FIELD_TYPE_MAP).map(([k, v]) => [v, parseInt(k)]),
)

export const getSelectTypeFields = (mainTask, taskId) => {
  const task = flattenTasks(mainTask).find((t) => t.id === taskId)
  return task?.fields.filter((f) => f.type === TASK_FIELD_TYPE_IDS.select).map(({ id, name }) => ({ id, name }))
}

export const REQUIRED_TYPES = {
  TASK_OPENING: 0,
  TASK_CLOSING: 1,
}

export const PRIORITY_ENUM = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
}

export const STATUS_ENUM = {
  OPEN: 0,
  EXPIRED: 1,
  COMPLETED: 2,
  PROCESSING: 3,
}

export const TASK_STATUSES = [
  { value: STATUS_ENUM.OPEN, label: i18next.t('tms:ui.StatusOpen') },
  { value: STATUS_ENUM.EXPIRED, label: i18next.t('tms:ui.StatusExpired') },
  { value: STATUS_ENUM.COMPLETED, label: i18next.t('tms:ui.StatusCompleted') },
  { value: STATUS_ENUM.PROCESSING, label: i18next.t('tms:ui.StatusProcessing') },
]

export const PRIORITIES = [
  { value: PRIORITY_ENUM.LOW, label: i18next.t('tms:fields.priority.low') },
  { value: PRIORITY_ENUM.MEDIUM, label: i18next.t('tms:fields.priority.medium') },
  { value: PRIORITY_ENUM.HIGH, label: i18next.t('tms:fields.priority.high') },
]

export const BILLING_CYCLES = [
  { value: 0, label: i18next.t('tms:fields.utilities.billingCycle.monthly') },
  { value: 1, label: i18next.t('tms:fields.utilities.billingCycle.bimontly') },
  { value: 2, label: i18next.t('tms:fields.utilities.billingCycle.quarterly') },
  { value: 3, label: i18next.t('tms:fields.utilities.billingCycle.fourmontly') },
  { value: 4, label: i18next.t('tms:fields.utilities.billingCycle.biannual') },
  { value: 5, label: i18next.t('tms:fields.utilities.billingCycle.yearly') },
]
