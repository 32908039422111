import { checkRequired } from '@Common/Utils/Validation'
import i18next from 'i18next'
import { convertToRaw } from 'draft-js';
import * as R from 'ramda'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = ['title']

  checkRequired(err, required, fields)

  const rawContent = convertToRaw(fields.text.getCurrentContent())
  if (rawContent.blocks.length === 1 && rawContent.blocks[0].text === '') {
    err['text'] = i18next.t('common:validation.RequiredField')
  }

  setErrors(err)
  return R.isEmpty(err)
}
