import { api } from '@Core/Services/Api'
const prefix = 'newsletter/subscribe'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    subscribe: builder.mutation({
      query: (fields) => ({
          url: `${prefix}`,
          body: { email:fields.email, gdprConsent:fields.terms },
          method: 'POST',
        })
      ,
      invalidatesTags: [{ type: 'Email', id: 'LIST' }],
    }),
  }),
})

export const { useSubscribeMutation } = extendedApi
