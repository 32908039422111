import { Button, FormControl, Link, Typography } from '@mui/material'
import * as R from 'ramda'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Alert from '@Common/Components/Alert'
import Loader from '@Common/Components/Loader'
import { Box, Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { responseStringError } from '@Common/Utils/Api'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { checkEmailAddress, checkRequired } from '@Common/Utils/Validation'
import config from '@Config'

import { useForgotPasswordMutation } from '../Services/Api'

const Form = styled.div``

export const validate = (fields) => {
  const errors = {}
  checkRequired(errors, ['email'], fields)
  checkEmailAddress(errors, fields, 'email')
  return { success: R.isEmpty(errors), errors }
}

const ForgotPasswordForm = () => {
  const { t } = useTranslation()
  const [requestDone, setRequestDone] = useState(false)
  const { fields, setField, errors, setErrors } = useForm({ email: '' }, true)

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()

  const submit = async () => {
    const { success, errors } = validate(fields)
    if (success) {
      try {
        await forgotPassword(fields.email).unwrap()
        logger.debug('Forgot password success')
        setRequestDone(true)
      } catch (err) {
        logger.debug('Forgot password error', err)
        if (err.status === 400) {
          setErrors(convertServerErrors(err))
        } else {
          setErrors({ ...errors, __form: responseStringError(err) })
        }
      }
    } else {
      setErrors(errors)
    }
  }

  const handleKeyDown = (evt) => {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      submit()
    }
  }

  return (
    <Box>
      {requestDone && (
        <Margin bottom="2rem">
          <Typography fontSize={18} align="center">
            {t('auth:success.ForgotPasswordLinkSent', { email: fields.email })}
          </Typography>
        </Margin>
      )}
      {!requestDone && (
        <Form>
          {errors.__form && <Alert level="error">{errors.__form}</Alert>}
          <FormControl fullWidth margin="normal">
            <TextField
              value={fields.email}
              label={t('auth:fields.email')}
              onChange={setStateFromEvent(setField('email'))}
              onKeyDown={handleKeyDown}
              error={!!errors.email}
              helperText={errors.email}
            />
          </FormControl>

          <Margin top="2rem" bottom="2rem">
            <Box direction="row" justify="center">
              {isLoading && <Loader overlay />}
              {!isLoading && (
                <Button fullWidth variant="contained" onClick={submit}>
                  {t('auth:buttons.ResetPassword')}
                </Button>
              )}
            </Box>
          </Margin>
          <Typography textAlign="center" color="text.primary">
            <Trans
              i18nKey="auth:ui.LoginSupport"
              values={{ email: config.contacts.supportEmail }}
              components={[<Link key="support-email" href={`mailto:${config.contacts.supportEmail}`} />]}
            />
          </Typography>
        </Form>
      )}
    </Box>
  )
}

export default ForgotPasswordForm
