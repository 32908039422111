import { ContentCopy } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { CircularProgress, DialogActions, DialogContent, Grid, IconButton, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import { blue } from '@mui/material/colors'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import config from '@Config'
import { useAvailablePaymentMethodsMutation, usePaySessionStripeMutation } from '@Payments/Services/Api'

const PaymentMethodSelectButton = ({ onPay, rata }) => {
  const [paymentMethods, setPaymentMethods] = useState(null)
  const { t } = useTranslation()

  const [createSessionStripe] = usePaySessionStripeMutation()
  const [availablePaymentMethods] = useAvailablePaymentMethodsMutation()

  const [openDialogSelect, setOpenDialogSelect] = React.useState(false)
  const [openDialogBank, setOpenDialogBank] = React.useState(false)
  const [openDialogPaymentDetail, setOpenDialogPaymentDetail] = React.useState(false)
  const [paymentDetail, setPaymentDetail] = React.useState(null)

  const location = useLocation()

  const handleClickOpenDialogSelect = () => {
    setOpenDialogSelect(true)
  }

  const handleClickOpenDialogBank = () => {
    setOpenDialogSelect(false)
    setOpenDialogBank(true)
  }

  useEffect(async () => {
    if (!paymentMethods) {
      let val = await availablePaymentMethods().unwrap()

      console.log('availablePaymentMethods', val.methods)

      if (val.methods) {
        let filterdMethods = config.payment.methods.filter((item) => {
          if (val.methods.find((x) => x.id === item.id)) {
            return item
          }
        })

        setPaymentMethods(filterdMethods)
      }
    }
  }, [paymentMethods])

  useEffect(() => {
    if (!paymentDetail) return
  }, [paymentDetail])

  const handleClickOpenDialogPaymentDetail = async (value) => {
    setOpenDialogSelect(false)
    setOpenDialogBank(false)
    setOpenDialogPaymentDetail(true)

    let val = await createSessionStripe({
      anagraphicArcadiaId: rata.anagrafica.id,
      installmentId: rata.id,
      method: value,
      redirect: location.pathname,
    }).unwrap()

    setPaymentDetail(val)
  }

  const handleCloseDialogBank = () => {
    setOpenDialogBank(false)
  }

  const handleOnPay = (value) => {
    setOpenDialogPaymentDetail(false)
    setOpenDialogBank(false)
    setOpenDialogSelect(false)
    onPay(value)
  }

  const handleCloseDialogPaymentDetail = () => {
    setOpenDialogPaymentDetail(false)
    setOpenDialogBank(false)
    setOpenDialogSelect(true)
  }

  const handleCloseDialogSelect = () => {
    setOpenDialogSelect(false)
  }

  const handleListItemClick = (value) => {
    setPaymentDetail(null)

    if (value !== 'bank') {
      handleClickOpenDialogPaymentDetail(value)
    } else {
      handleClickOpenDialogBank()
    }
  }

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const [isCopied, setIsCopied] = useState(false)

  const handleCopyClick = (value, type = null) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(value).then(() => {
      if (type !== 'causale') {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 2000)
      } else {
        setIsCopiedCausale(true)
        setTimeout(() => {
          setIsCopiedCausale(false)
        }, 2000)
      }
    })
  }

  const [isCopiedCausale, setIsCopiedCausale] = useState(false)

  return (
    <>
      <Button
        disableElevation
        variant="contained"
        color="secondary"
        sx={{ borderRadius: '20px', padding: '3px 12px 3px 12px', textTransform: 'none' }}
        onClick={handleClickOpenDialogSelect}
      >
        {t('payments:ui.payNow')}
      </Button>

      {/* Dialog List Payments */}
      <Dialog onClose={handleCloseDialogSelect} open={openDialogSelect} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {t('payments:ui.payRata')}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialogSelect}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Typography variant="h5" textAlign="center" sx={{ verticalAlign: 'middle' }}>
              {rata.descrizione}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h4" textAlign="center">
              € {rata.importo}
            </Typography>
            <Typography variant="body" textAlign="center">
              *
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'left', pl: 2, pt: 2 }}>
            <Typography variant="h5" textAlign="left">
              {t('payments:ui.payWith')}
            </Typography>
          </Grid>
          <List sx={{ pt: 1 }}>
            {paymentMethods?.map((method) => (
              <ListItem
                button
                onClick={() => handleListItemClick(method.id)}
                key={method.id}
                secondaryAction={method.popover ? method.popover : null}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>{method.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={t(method.name)} secondary={t(method.subtitle)} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      {/* /Dialog List Payments */}

      {/* Dialog Bank */}
      <Dialog onClose={handleCloseDialogBank} open={openDialogBank} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {t('payments:dialogBonifico.title')}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialogBank}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{
                verticalAlign: 'middle',
                color: (theme) => theme.palette.text.secondary,
                textTransform: 'uppercase',
              }}
            >
              {t('payments:dialogBonifico.beneficario')}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h5" textAlign="center">
              {rata.immobile.denominazione ?? t('payments:dialogBonifico.notFound')}
            </Typography>
          </Grid>

          <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{ verticalAlign: 'middle', color: (theme) => theme.palette.text.secondary }}
            >
              {t('payments:dialogBonifico.iban')}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h5" textAlign="center" sx={{ color: (theme) => theme.palette.text.primary }}>
              {rata.contoCorrenteIncasso.codiceIBAN}
            </Typography>
          </Grid>

          <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{
                verticalAlign: 'middle',
                color: (theme) => theme.palette.text.secondary,
                textTransform: 'uppercase',
              }}
            >
              {t('payments:dialogBonifico.causale')}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h5" textAlign="center" sx={{ color: (theme) => theme.palette.text.primary }}>
              {rata.anagrafica.nominativo}: {rata.bilancio.descrizione} {rata.descrizione}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{
                verticalAlign: 'middle',
                color: (theme) => theme.palette.text.secondary,
                textTransform: 'uppercase',
              }}
            >
              {t('payments:paymentInfo.totalToPay')}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
            <Typography variant="h4" textAlign="center">
              € {rata.importo}
            </Typography>
            <Typography variant="body" textAlign="center"></Typography>
          </Grid>

          <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
            <Typography variant="body" textAlign="center" sx={{ color: (theme) => theme.palette.text.secondary }}>
              {t('payments:dialogBonifico.subTitle')}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: 3 }}>
          <Button onClick={handleCloseDialogPaymentDetail}> {t('common:actions.Back')}</Button>

          <Button
            color={isCopied ? 'success' : 'primary'}
            onClick={() => (!isCopied ? handleCopyClick(rata.contoCorrenteIncasso.codiceIBAN) : {})}
            variant={'outlined'}
            disableElevation
          >
            {isCopied ? (
              <>
                <TaskAltIcon sx={{ fontSize: 16, verticalAlign: 'middle', mr: 1 }} />
                <span style={{ marginTop: 2 }}>{t('payments:dialogBonifico.copiaIbanSuccess')}</span>
              </>
            ) : (
              <>
                <ContentCopy sx={{ fontSize: 16, verticalAlign: 'middle', mr: 1 }} />
                <span style={{ marginTop: 2 }}>{t('payments:dialogBonifico.copiaIban')}</span>
              </>
            )}
          </Button>

          <Button
            color={isCopiedCausale ? 'success' : 'primary'}
            onClick={() =>
              !isCopiedCausale
                ? handleCopyClick(
                    `${rata.anagrafica.nominativo}: ${rata.bilancio.descrizione} ${rata.descrizione}`,
                    'causale',
                  )
                : {}
            }
            variant={'outlined'}
            disableElevation
          >
            {isCopiedCausale ? (
              <>
                <TaskAltIcon sx={{ fontSize: 16, verticalAlign: 'middle', mr: 1 }} />
                <span style={{ marginTop: 2 }}>{t('payments:dialogBonifico.copiaCausaleSuccess')}</span>
              </>
            ) : (
              <>
                <ContentCopy sx={{ fontSize: 16, verticalAlign: 'middle', mr: 1 }} />
                <span style={{ marginTop: 2 }}>{t('payments:dialogBonifico.copiaCausale')}</span>
              </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {/* /Dialog Bank */}

      {/* Dialog Card o Sepa */}
      <Dialog onClose={handleCloseDialogPaymentDetail} open={openDialogPaymentDetail} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {paymentDetail
            ? t(paymentMethods.find((x) => x.id === paymentDetail.paymentMethodTypeSelected).short_name)
            : ''}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialogPaymentDetail}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {paymentDetail ? (
            <>
              <Grid container sx={{ justifyContent: 'center' }}>
                <Typography
                  variant="body1"
                  textAlign="center"
                  sx={{
                    verticalAlign: 'middle',
                    color: (theme) => theme.palette.text.secondary,
                    textTransform: 'uppercase',
                  }}
                >
                  {t('payments:paymentInfo.amount')}
                </Typography>
              </Grid>
              <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
                <Typography variant="h4" textAlign="center">
                  € {paymentDetail?.amount ?? ''}
                </Typography>
              </Grid>

              <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
                <Typography
                  variant="body1"
                  textAlign="center"
                  sx={{
                    verticalAlign: 'middle',
                    color: (theme) => theme.palette.text.secondary,
                    textTransform: 'uppercase',
                  }}
                >
                  {t('payments:paymentInfo.commissions')}
                </Typography>
              </Grid>
              <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
                <Typography variant="h4" textAlign="center">
                  € {paymentDetail?.feeAmount ?? ''}
                </Typography>
              </Grid>

              <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
                <Typography
                  variant="body1"
                  textAlign="center"
                  sx={{
                    verticalAlign: 'middle',
                    color: (theme) => theme.palette.text.secondary,
                    textTransform: 'uppercase',
                  }}
                >
                  {t('payments:paymentInfo.totalToPay')}
                </Typography>
              </Grid>
              <Grid container sx={{ justifyContent: 'center', pt: 1 }}>
                <Typography variant="h4" textAlign="center">
                  € {paymentDetail?.totalAmount ?? ''}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleCloseDialogPaymentDetail}>{t('common:actions.Back')}</Button>
          {paymentDetail ? (
            <Button onClick={() => handleOnPay(paymentDetail?.url)} variant={'contained'} disableElevation>
              {t('payments:ui.payNow')}
            </Button>
          ) : (
            <></>
          )}
        </DialogActions>
        <Grid container sx={{ justifyContent: 'center', pb: 2 }}>
          <Typography variant="body" textAlign="center" sx={{ color: '#aaa' }}>
            {t('payments:paymentInfo.securePaymentWith')}
          </Typography>
          <Typography variant="body" textAlign="center" sx={{ color: '#aaa', ml: 0.4 }}>
            <svg
              focusable="false"
              width="33"
              height="15"
              role="img"
              aria-labelledby="stripe-title"
              style={{ color: (theme) => theme.palette.grey[500], verticalAlign: 'middle' }}
              fill="#aaa"
            >
              <g fillRule="evenodd">
                <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
              </g>
            </svg>
          </Typography>
        </Grid>
      </Dialog>
      {/* /Dialog Card o Sepa */}
    </>
  )
}

PaymentMethodSelectButton.propTypes = {
  onPay: PropTypes.func,
  rata: PropTypes.object,
}

export default PaymentMethodSelectButton
