import React from 'react'
import { Box, Margin } from '@Common/Components/Styles'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ContactCard from '@MyEstia/Components/ContactCard'
import { displayAddress, getDisplayName, isLegalPerson } from '@Residents/Utils'
import PhoneContactForm from '@MyEstia/Forms/PhoneContactForm'
import { useUpdateResidentMutation } from '@Residents/Services/Api'
import { toast } from 'react-toastify'
import { responseStringError } from '@Common/Utils/Api'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '@Auth/Redux/Login'
import { useWhoamiMutation } from '@Auth/Services/Api'

const Container = styled.div`
  padding: 2rem 2rem 6rem;
`

const Title = styled.h1`
  text-align: center;
`

const SubTitle = styled.h2`
  margin-top: 1rem;
  text-align: center;
`

const ProfileView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useCurrentUser()
  const [phoneForm, setPhoneForm] = React.useState({ isOpen: false })
  const [updateResident] = useUpdateResidentMutation()
  const [whoami] = useWhoamiMutation()
  const { raw, phone } = user.resident
  const handleOpenPhoneForm = () => setPhoneForm({ isOpen: true })
  const handleClosePhoneForm = () => setPhoneForm({ isOpen: false })
  const handleSavePhoneForm = async ({ phone: newPhone }) => {
    try {
      const response = await updateResident({ id: user.resident.id, phone: newPhone }).unwrap()
      toast.success(t('myEstia:success.PhoneContactChanged'))
      const updatedUser = await whoami().unwrap()
      dispatch(setCurrentUser(updatedUser))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('myEstia:errors.ChangePhoneContactError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <Container>
      <Title>{getDisplayName(raw)}</Title>
      {!isLegalPerson(raw) && (
        <Box align="center">
          <span>
            <strong>{t('myEstia:ui.FiscalCode')}</strong>: {raw.codiceFiscale || '-'}
          </span>
        </Box>
      )}
      {isLegalPerson(raw) && (
        <Box align="center">
          <span>
            <strong>{t('myEstia:ui.VatNumber')}</strong>: {raw.partitaIva || '-'}
          </span>
        </Box>
      )}
      {raw.residenzaSedeLegale && (
        <Box align="center" margin="1.5rem auto 0">
          <strong>{t('myEstia:ui.Address')}:</strong>
          <p>{displayAddress(raw.residenzaSedeLegale)}</p>
        </Box>
      )}
      <SubTitle>{t('myEstia:ui.MainContacts')}</SubTitle>
      <Box direction="row" align="center" justify="center" pad="0 0 1rem" $wrap>
        <Margin right=".5rem" bottom="1rem">
          <ContactCard contact={{ riferimento: user.username, tipo: { id: 20 } }} />
        </Margin>
        <Margin left=".5rem" bottom="1rem">
          <ContactCard contact={{ riferimento: phone, tipo: { id: 10 } }} onEdit={handleOpenPhoneForm} />
        </Margin>
      </Box>
      {phoneForm.isOpen && (
        <PhoneContactForm onClose={handleClosePhoneForm} onSave={handleSavePhoneForm} phone={phone} />
      )}
      {raw.contatti?.length && (
        <>
          <SubTitle>{t('myEstia:ui.OtherContacts')}</SubTitle>
          <Box direction="row" justify="center" $wrap>
            {raw.contatti.map((c, idx) => (
              <Margin key={idx} left=".5rem" right=".5rem" bottom="1rem">
                <ContactCard contact={c} />
              </Margin>
            ))}
          </Box>
        </>
      )}
    </Container>
  )
}

export default ProfileView
