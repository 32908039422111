import { createTheme } from '@mui/material'
import * as R from 'ramda'

import colors from './Colors'

const Theme = {
  typography: {
    fontFamily: '"Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        padding: '4px 8px',
      },
    },
  },
  components: {
    MuiStack: {
      defaultProps: {
        spacing: 2,
      },
    },
  },
}

const calculateColors = (mode) => {
  const calculateColor = R.ifElse(
    R.has('main'),
    (c) => ({ ...c }),
    (c) => ({ ...c, main: mode === 'light' ? c.light : c.dark }),
  )
  return R.map((value) => calculateColor(value), colors)
}

const getPalette = (mode) => ({
  mode,
  ...calculateColors(mode),
  constrastThreshold: 3,
})

const calculateContrastText = (palette) => {
  return R.mapObjIndexed((value, key) => {
    if (R.has(key, colors) && R.not(R.has('contrastText', colors[key]))) {
      value.constrastText = palette.getContrastText(value.main)
    }
    return value
  }, palette)
}

const getTheme = (mode) => {
  const theme = createTheme({ ...Theme, palette: getPalette(mode) })
  return createTheme(theme, {
    palette: {
      ...calculateContrastText(theme.palette),
    },
  })
}

export default getTheme
