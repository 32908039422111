import PropTypes from 'prop-types'
import { Marker as RLMarker, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import React from 'react'
import ReactDOMServer from 'react-dom/server'

const Marker = ({ iconSize, iconAnchor, popupAnchor, icon, children, title, ...props }) => {
  return (
    <RLMarker
      icon={L.divIcon({
        className: 'custom icon',
        iconSize,
        iconAnchor,
        popupAnchor,
        html: ReactDOMServer.renderToString(icon),
      })}
      {...props}
    >
      {title && <Tooltip>{title}</Tooltip>}
      {children}
    </RLMarker>
  )
}

Marker.propTypes = {
  title: PropTypes.string,
  iconSize: PropTypes.arrayOf(PropTypes.number),
  iconAnchor: PropTypes.arrayOf(PropTypes.number),
  popupAnchor: PropTypes.arrayOf(PropTypes.number),
  icon: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

};


export default Marker
