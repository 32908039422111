import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'

import { UserType } from '../../Models/User'
import Modal from '@Common/Components/Modal'
import { useForm } from '@Common/Utils/Hooks'
import Alert from '@Common/Components/Alert'
import logger from '@Common/Utils/Logger'
import { validate } from './Validation'
import PasswordGrid from '@Auth/Components/PasswordGrid'
import PasswordRules from '@Auth/Components/PasswordRules'
import { FormControl, Grid } from '@mui/material'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import React, { useState } from 'react'
import { Margin } from '@Common/Components/Styles'
import Loader from '@Common/Components/Loader'

const ChangePasswordForm = ({ onClose, onSave, user }) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fields, setField, errors, setErrors } = useForm(
    {
      password: '',
      passwordRepeat: '',
    },
    true,
  )

  const submit = async () => {
    logger.debug('User change password submission')
    if (validate(fields, setErrors)) {
      setIsSubmitting(true)
      logger.debug('User change password submission, validation passed, saving')
      const { error, isSuccessful } = await onSave(user.id, fields)
      if (!isSuccessful && error?.status === 400) {
        setIsSubmitting(false)
        logger.debug('User change password submission api error', error)
        setErrors(R.ifElse(R.is(Object), R.map(R.head), R.always({}))(error.data))
      } else {
        onClose()
      }
    } else {
      logger.debug('User change password submission, validation failed')
    }
  }

  return (
    <Modal
      title={t('auth:ui.ChangePassword', { username: user.username })}
      width="large"
      onClose={onClose}
      onSubmit={submit}
    >
      {isSubmitting && <Loader overlay />}
      <Margin top="1rem">
        {errors.__form && <Alert level="error">{errors.__form}</Alert>}
        <Grid container spacing={5} alignItems="center">
          <PasswordGrid
            fields={[
              <FormControl fullWidth key="password" sx={{ marginBottom: '1.5rem' }}>
                <TextField
                  required
                  fullWidth
                  value={fields.password}
                  onChange={setStateFromEvent(setField('password'))}
                  error={!!errors.password}
                  helperText={errors.password}
                  label={t('auth:fields.password')}
                  type="password"
                />
              </FormControl>,
              <FormControl fullWidth key="passwordRepeat">
                <TextField
                  required
                  fullWidth
                  value={fields.passwordRepeat}
                  onChange={setStateFromEvent(setField('passwordRepeat'))}
                  error={!!errors.passwordRepeat}
                  helperText={errors.passwordRepeat}
                  label={t('auth:fields.repeatPassword')}
                  type="password"
                />
              </FormControl>,
            ]}
            rules={<PasswordRules />}
          />
        </Grid>
      </Margin>
    </Modal>
  )
}

ChangePasswordForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  user: UserType,
}

export default ChangePasswordForm
