import LinkIcon from '@mui/icons-material/Link'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import { makePath } from '@Config'

import ServiceList from '../Components/admin/AdminServiceList'
import { types } from '../Constants'
import ServiceForm from '../Forms/ServiceForm'
import { ServicePermissions } from '../Permissions'
import { useCreateServicesMutation, useDeleteServicesMutation, useUpdateServicesMutation } from '../Services/Api'

const Main = styled.main`
  padding-bottom: 3rem;
`
const AdminServiceView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [createServices] = useCreateServicesMutation()
  const [updateServices] = useUpdateServicesMutation()
  const [deleteServices] = useDeleteServicesMutation()
  const [form, setForm] = React.useState({ isOpen: false, service: null, method: null })
  const handleOpenForm = (service, method) => () => setForm({ isOpen: true, service, method })
  const handleCloseForm = () => setForm({ isOpen: false, service: null, method: null })

  const actions = [
    {
      icon: <LinkIcon onClick={handleOpenForm(null, 'external')} />,
      name: t('services:fields.external.External'),
    },
  ]
  useBreadcrumbs([
    { label: t('common:navigation.Services'), path: makePath('admin.services.services'), Icon: 'Service' },
  ])

  const handleSave = async (fields) => {
    const formData = new FormData()
    formData.append('isActive', fields.isActive)
    formData.append('title', fields.title)
    formData.append('listDescription', fields.listDescription)
    formData.append('category', fields.category)
    formData.append('provider', fields.provider)
    formData.append('label', fields.label)
    formData.append('orderingNumber', fields.orderingNumber)
    formData.append('descriptionText', draftToHtml(convertToRaw(fields.descriptionText.getCurrentContent())))
    formData.append('howText', draftToHtml(convertToRaw(fields.howText.getCurrentContent())))
    formData.append('whyText', draftToHtml(convertToRaw(fields.whyText.getCurrentContent())))
    formData.append('removeFlier', fields.removeFlier)
    if (fields?.saleMethodType === types.external) {
      const saleMethod = { name: fields?.saleMethodName, url: fields?.saleMethodUrl, type: fields?.saleMethodType }
      const saleJSON = JSON.stringify(saleMethod)
      formData.append('saleMethodJson', saleJSON)
    }

    // formData.append('saleMethod', fields.saleMethod)
    if (fields.listImage.length) {
      formData.append('listImage', fields.listImage[0])
    }
    if (fields.detailImage.length) {
      formData.append('detailImage', fields.detailImage[0])
    }
    if (fields.flier.length) {
      formData.append('flier', fields.flier[0])
    }

    try {
      const response = form.service?.id
        ? await updateServices({ slug: form.service.slug, service: formData }).unwrap()
        : await createServices(formData).unwrap()
      toast.success(t(`services:success.Service${form.service ? 'Updated' : 'Created'}`, { title: fields.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(
        t(`services:errors.${form.service ? 'Update' : 'Create'}ServiceError`, { error: responseStringError(error) }),
      )
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (services) => {
    try {
      const slugs = services.map(R.prop('slug'))
      const promises = slugs.map(async (slug) => {
        return deleteServices(slug).unwrap()
      })

      await Promise.all(promises)
      toast.success(
        t('services:success.ServicesDeleted', {
          count: services.length,
        }),
      )
    } catch (err) {
      toast.error(
        t('services:errors.DeleteServicesError', {
          error: err.data?.detail || t('common:errors.UnknownError'),
          count: services.length,
        }),
      )
    }
  }

  return (
    <Main>
      <ServiceList onEdit={(service, method) => setForm({ isOpen: true, service, method })} onDelete={handleDelete} />
      {form.isOpen && (
        <ServiceForm service={form.service} method={form.method} onClose={handleCloseForm} onSave={handleSave} />
      )}
      {ServicePermissions.create(user) && (
        <SpeedDial
          ariaLabel="Add service"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
          ))}
        </SpeedDial>
      )}{' '}
    </Main>
  )
}

export { AdminServiceView }
