/* eslint-disable react/prop-types */

/**
 * We build often tabs and every time we need to use state,
 * manage tab labels, etc.
 *
 * This drive us to build this component to simplify the tabs creation.
 * Active tab is store in local storage for preserve last visited tab.
 */
import { TabContext, TabList, TabPanel as MuiTabPanel } from '@mui/lab'
import { Tab as MuiTab } from '@mui/material'
import { useState } from 'react'

export const TabContainer = (props) => {
  const { children } = props
  const tabs = children.filter((x) => x).map((child) => child.props.title)

  const [tab, setTab] = useState(tabs[0])
  const handleTabChange = (_, newValue) => setTab(newValue)

  return (
    <TabContext value={tab}>
      <TabList onChange={handleTabChange}>
        {tabs.map((tab) => (
          <MuiTab key={tab} label={tab} value={tab} />
        ))}
      </TabList>
      {children}
    </TabContext>
  )
}

export const TabPanel = (props) => {
  const { title, children } = props

  return (
    <MuiTabPanel value={title} sx={{ p: 0 }}>
      {children}
    </MuiTabPanel>
  )
}
