/* eslint-disable react/prop-types */
import AddIcon from '@mui/icons-material/Add'
import { Button, Grid, Stack } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getItemFieldFromContext } from '@Common/Utils/Hooks'

export const ArrayField = (props) => {
  const { t } = useTranslation()
  const {
    name,
    template: Template,
    defaultValues,
    showBtnAdd = true,
    showBtnDelete = true,
    isTableField = false,
  } = props

  const labelBtnAdd = props?.labelBtnAdd || t('common:actions:Append')
  const labelBtnRemove = props?.labelBtnRemove || t('common:actions:Remove')

  const { control } = useFormContext()
  const field = getItemFieldFromContext(name)

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    defaultValues: defaultValues || { [name]: [] },
  })

  const renderInputs = () => {
    return fields.map((field, index) => {
      const component = <Template index={index} value={field} />
      {
        showBtnDelete && (
          <Button color="error" onClick={() => remove(index)}>
            {labelBtnRemove}
          </Button>
        )
      }

      if (isTableField) {
        return component
      }

      return (
        <Stack direction="row" key={field.id}>
          {component}
        </Stack>
      )
    })
  }

  const result = (
    <>
      {renderInputs()}
      {showBtnAdd && (
        <Button
          onClick={() => {
            append(field?.schema?.defaultValue || {})
          }}
          startIcon={<AddIcon />}
        >
          {labelBtnAdd}
        </Button>
      )}
    </>
  )

  if (isTableField) {
    return result
  } else {
    return (
      <Grid item xs={12}>
        <Stack direction="column">{result}</Stack>
      </Grid>
    )
  }
}
