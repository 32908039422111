import styled from 'styled-components'

import NewsList from '@News/Components/EstiaNewsList'

const Main = styled.div`
  padding-inline: 10px;
  margin-top: 3rem;
  margin-bottom: 6.5rem;
`

export const NewsListView = () => {
  return (
    <Main>
      <NewsList />
    </Main>
  )
}
