import { FormControl, Grid } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DatePicker from '@Common/Components/DatePicker'
import DateRangePicker from '@Common/Components/DateRangePicker'
import { Margin } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useDebounce, useForm } from '@Common/Utils/Hooks'

const getModelFilters = R.pipe(R.prop('columns'), R.filter(R.has('filter')))

const FilterField = ({ label, filter, value, onChangeFilter }) => {
  const { t } = useTranslation()
  switch (filter.type) {
    case 'date':
      return (
        <DatePicker
          views={['year']}
          inputFormat={filter.format}
          onChange={onChangeFilter}
          maxDate={dayjs()}
          value={value}
          label={label}
        />
      )
    case 'text':
      return <TextField label={label} value={value} onChange={onChangeFilter} />
    case 'dateRange':
      return (
        <DateRangePicker
          startText={t('common:fields.from')}
          endText={t('common:fields.to')}
          maxDate={dayjs()}
          value={value || filter.default}
          inputFormat={filter.format}
          onChange={onChangeFilter}
        />
      )
  }
}

FilterField.propTypes = {
  label: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    type: PropTypes.oneOf(['text', 'dateRange', 'date']).isRequired,
    format: PropTypes.string,
    default: PropTypes.any,
    serverSide: PropTypes.bool,
  }).isRequired,
  value: PropTypes.any,
  onChangeFilter: PropTypes.func.isRequired,
}

const getInitialFields = (filterFields) =>
  R.zipObj(R.pluck('id', filterFields), R.map(R.pipe(R.path(['filter', 'default']), R.defaultTo(null)), filterFields))

const DocumentsSearchForm = ({ model, onSearch }) => {
  const filterFields = getModelFilters(model)
  const { fields, setField, setFields } = useForm(getInitialFields(filterFields), true)
  const debouncedFields = useDebounce(fields, 300)

  React.useEffect(() => {
    setFields(getInitialFields(filterFields))
  }, [model])

  const handleChangeFilter = (field) => (e) => {
    switch (field.filter.type) {
      case 'text':
        setStateFromEvent(setField(field.id))(e)
        break
      case 'date':
        setField(field.id)(dayjs(e).format(field.filter.format))
        break
      case 'dateRange':
        setField(field.id)(e)
    }
  }

  React.useEffect(() => {
    onSearch(fields)
  }, [JSON.stringify(debouncedFields)])

  return (
    <Margin top="0.5rem">
      <Grid container rowSpacing={3} spacing={2}>
        {filterFields.map((field, index) => (
          <Grid key={field.id + index} item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <FilterField {...field} value={fields[field.id]} onChangeFilter={handleChangeFilter(field)} />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Margin>
  )
}

DocumentsSearchForm.propTypes = {
  model: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  data: PropTypes.array,
}

export default DocumentsSearchForm
