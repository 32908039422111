import * as React from 'react'

const SecurityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-35 -40 220 300"
    style={{
      enableBackground: 'new 0 0 153 200',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M225.7 73.6v120c-2.9 11.8-7.8 22.2-16.5 31.6-11.1 11.8-23.4 22-37.4 30-10.5 6.2-21.4 11.6-32.3 17.3h-2.7c-.9-.7-2-1.1-2.9-1.8-16.3-7.1-31.6-16.2-45.9-26.9-7.6-5.8-14.3-12.9-21.2-19.6-10.2-9.8-15.8-22-16-36-.2-36.9 0-73.8 0-110.7 0-5.1.7-5.3 5.8-6 12.7-1.1 25.6-1.8 38.3-4 15.6-2.9 31-8 40.8-22.2 1.6-2.4 4-2 5.3.2 6.2 10.2 16.5 14.4 27 18.2 16.7 6.2 34.3 7.1 51.7 7.8 1.9.4 4 1.5 6 2.1zm-155.6 66c0 13.6.2 27.1 0 40.7 0 8 2 15.3 6.2 22 5.3 8.4 12.5 15.3 20.3 21.3 11.8 9.1 24.5 16.7 38.3 22.7 1.8.9 4.5.7 6.5 0 3.8-1.3 7.4-3.6 10.9-5.3 16.9-8.9 32.8-19.1 44.8-34.4 6.2-8 9.4-17.1 9.4-27.6-.2-27.1 0-54 0-81.1 0-7.8 0-7.3-7.6-8-10.7-.9-21.2-2-31.6-4.2-10.5-2.2-20.5-6-26.7-15.8-1.6-2.4-3.1-1.1-4.9.4-3.8 3.6-7.8 7.6-12.3 10-14.7 8-31.2 9.3-47.7 9.6-4.2 0-5.6 1.3-5.6 5.6v44.1z"
      style={{
        fill: '#fdbd2e',
      }}
      transform="matrix(.87428 0 0 .87428 -44.29 -38.26)"
    />
    <path
      d="M140.5 85.4c8.2 10.7 20.1 13.1 31.6 15.3 6.5 1.3 13.4 1.3 20.1 1.8 2.9.2 4 1.1 3.8 4 0 23.6.2 47.3-.2 70.9-.2 10.2-4.9 18.7-13.1 25.1-7.4 5.8-14.3 12-22.1 17.3-6 4.2-12.9 6.9-20.3 10.9.2-49.3.2-97.3.2-145.3z"
      style={{
        fill: '#3f71b6',
      }}
      transform="matrix(.87428 0 0 .87428 -44.29 -38.26)"
    />
  </svg>
)

export default SecurityIcon
