import React from 'react'

import { Box } from '@Common/Components/Styles'
import TaskTreeInfographic from '@Tms/Components/TaskTreeInfographic'
import { RunningTaskType } from '@Tms/Models/RunningTask'
import { flattenTasks } from '@Tms/Utils'

const TaskFlowInfographic = ({ task }) => {
  const tree = flattenTasks(task.taskFlowObj.mainRunningTask)

  return (
    <Box width="100%">
      <TaskTreeInfographic task={task.taskFlowObj.mainRunningTaskTemplate} flattenTasks={tree} />
    </Box>
  )
}

TaskFlowInfographic.propTypes = {
  task: RunningTaskType,
}

export default TaskFlowInfographic
