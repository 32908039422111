import { api, apiQueryString } from '@Core/Services/Api'

export const prefix = 'condo/buildings'
const prefixPodPdrs = 'condo/podpdrs'
const prefixReconciliationReminders = 'condo/reconciliationreminders'

/**
 * For Pod/pdr and reconciliation reminder,
 * we put in invalidatesTags the condo item.
 * It's wanted because for example when user delete Pod/pdr we need to list
 * this item in section "Condo without pod/pdr"
 */

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    //#region Condo
    condos: builder.query({
      query: (qs) => {
        let query = apiQueryString(qs)

        if (query) {
          return `${prefix}?${query}`
        }

        return `${prefix}`
      },
      providesTags: ['Condo'],
    }),
    condoById: builder.query({
      query: (condoId) => `${prefix}/${condoId}`,
    }),
    deleteCondo: builder.mutation({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Condo'],
    }),
    createCondo: builder.mutation({
      query: (condo) => ({
        url: `${prefix}`,
        body: condo,
        method: 'POST',
      }),
      invalidatesTags: ['Condo'],
    }),
    updateCondo: builder.mutation({
      query: ({ id, ...condo }) => ({
        url: `${prefix}/${id}`,
        body: condo,
        method: 'PATCH',
      }),
      invalidatesTags: ['Condo'],
    }),
    importCondos: builder.mutation({
      query: (condos) => ({
        url: `${prefix}/excel`,
        body: condos,
        method: 'POST',
      }),
      invalidatesTags: ['Condo'],
    }),
    exportCondos: builder.mutation({
      query: (condos) => ({
        url: `${prefix}/excel`,
        body: condos,
        method: 'GET',
      }),
      invalidatesTags: ['Condo'],
    }),
    assignCondoOwners: builder.mutation({
      query: (body) => ({
        url: `${prefix}/owners`,
        body: body,
        method: 'POST',
      }),
      invalidatesTags: ['Condo', 'BranchBuilding', 'Building'],
    }),
    //#endregion Condo

    //#region PodPdr
    condoPodPdr: builder.query({
      query: ({ qs, condo_id = null }) => {
        if (condo_id) {
          return `${prefix}/${condo_id}/podpdrs?${apiQueryString(qs)}`
        } else {
          return `${prefixPodPdrs}?${apiQueryString(qs)}`
        }
      },
      providesTags: ['PodPdr'],
    }),
    podPdrById: builder.query({
      query: (podpdrId) => `${prefixPodPdrs}/${podpdrId}`,
      providesTags: ['PodPdr'],
    }),
    createPodPdr: builder.mutation({
      query: (podpdr) => ({
        url: `${prefix}/${podpdr.building}/podpdrs`,
        body: podpdr,
        method: 'POST',
      }),
      invalidatesTags: ['PodPdr', 'Condo'],
    }),
    updatePodPdr: builder.mutation({
      query: (podpdr) => ({
        url: `${prefix}/${podpdr.building}/podpdrs/${podpdr.id}`,
        body: podpdr,
        method: 'PATCH',
      }),
      invalidatesTags: ['PodPdr', 'Condo'],
    }),
    updateAllFieldPodPdr: builder.mutation({
      query: (podpdr) => ({
        url: `${prefixPodPdrs}/${podpdr.id}`,
        body: podpdr,
        method: 'PATCH',
      }),
      invalidatesTags: ['PodPdr', 'Condo'],
    }),
    deletePodPdr: builder.mutation({
      query: (id) => ({
        url: `${prefixPodPdrs}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PodPdr', 'Condo'],
    }),
    importPodPdrs: builder.mutation({
      query: (podpdr) => ({
        url: `${prefixPodPdrs}/excel`,
        body: podpdr,
        method: 'POST',
      }),
      invalidatesTags: ['PodPdr', 'Condo'],
    }),
    //#endregion PodPdr

    //#region ReconciliationReminder
    condoReconciliationReminder: builder.query({
      query: ({ qs, condo_id = null }) => {
        if (condo_id) {
          return `${prefix}/${condo_id}/reconciliationreminders?${apiQueryString(qs)}`
        } else {
          return `${prefixReconciliationReminders}?${apiQueryString(qs)}`
        }
      },
      providesTags: ['ReconciliationReminder'],
    }),
    reconciliationReminderById: builder.query({
      query: (reconciliationReminderId) => `${prefixReconciliationReminders}/${reconciliationReminderId}`,
      providesTags: ['ReconciliationReminder'],
    }),
    createReconciliationReminder: builder.mutation({
      query: (reconciliationReminder) => ({
        url: `${prefix}/${reconciliationReminder.building}/reconciliationreminders`,
        body: reconciliationReminder,
        method: 'POST',
      }),
      invalidatesTags: ['ReconciliationReminder', 'Condo'],
    }),
    updateReconciliationReminder: builder.mutation({
      query: (reconciliationReminder) => ({
        url: `${prefix}/${reconciliationReminder.building}/reconciliationreminders/${reconciliationReminder.id}`,
        body: reconciliationReminder,
        method: 'PATCH',
      }),
      invalidatesTags: ['ReconciliationReminder', 'Condo'],
    }),
    updateAllFieldReconciliationReminder: builder.mutation({
      query: (reconciliationReminder) => ({
        url: `${prefixReconciliationReminders}/${reconciliationReminder.id}`,
        body: reconciliationReminder,
        method: 'PATCH',
      }),
      invalidatesTags: ['ReconciliationReminder', 'Condo'],
    }),
    deleteReconciliationReminder: builder.mutation({
      query: (id) => ({
        url: `${prefixReconciliationReminders}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ReconciliationReminder', 'Condo'],
    }),
    //#endregion ReconciliationReminder
  }),
  overrideExisting: false,
})

export const {
  //#region Condo
  useCondosQuery,
  useLazyCondosQuery,
  useCondoByIdQuery,
  useLazyCondoByIdQuery,
  useDeleteCondoMutation,
  useCreateCondoMutation,
  useUpdateCondoMutation,
  useImportCondosMutation,
  useExportCondosMutation,
  useAssignCondoOwnersMutation,
  //#endregion Condo
  //#region PodPdr
  useCondoPodPdrQuery,
  usePodPdrByIdQuery,
  useCreatePodPdrMutation,
  useUpdatePodPdrMutation,
  useUpdateAllFieldPodPdrMutation,
  useDeletePodPdrMutation,
  useImportPodPdrsMutation,
  //#endregion PodPdr
  //#region ReconciliationReminder
  useCondoReconciliationReminderQuery,
  useReconciliationReminderByIdQuery,
  useCreateReconciliationReminderMutation,
  useUpdateReconciliationReminderMutation,
  useUpdateAllFieldReconciliationReminderMutation,
  useDeleteReconciliationReminderMutation,
  //#endregion ReconciliationReminder
} = extendedApi
