import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import AddFab from '@Common/Components/AddFab'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import config, { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import TaskCategoryList from '@Tms/Components/TaskCategoryList'
import { TaskCategoryPermissions } from '@Tms/Permissions'
import { useDeleteCategoryMutation, useDuplicateCategoryMutation, useIsActiveCategoryMutation } from '@Tms/Services/Api'
import Loader from '@Common/Components/Loader'

const Main = styled.main`
  padding: 2rem 2rem 3rem;
`

const AdminTaskCategoriesView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // api
  const [deleteCategory] = useDeleteCategoryMutation()
  const [changeIsActiveCategory] = useIsActiveCategoryMutation()
  const [duplicateCategory] = useDuplicateCategoryMutation()

  useBreadcrumbs([
    { label: t('common:navigation.Tms'), path: makePath('tms.taskList'), Icon: 'Tms' },
    { label: t('common:navigation.TmsCategories') },
  ])

  const handleCreateCategory = () => history.push(config.urls.tms.createCategory)
  const handleEditCategory = ({ id }) => history.push(makePath('tms.editCategory', { category: id }))
  const handleDuplicateCategory = async (id) => {
    try {
      setIsSubmitting(true)
      const response = await duplicateCategory(id).unwrap()
      setIsSubmitting(false)
      toast.success(t('tms:success.CategoryCreated', { name: response.name }))
      return { response, isSuccessful: true }
    } catch (error) {
      setIsSubmitting(false)
      toast.error(t('tms:errors.CreateCategoryError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDeleteCategory = async ({ id, name }) => {
    try {
      setIsSubmitting(true)
      const response = await deleteCategory(id).unwrap()
      setIsSubmitting(false)
      toast.success(t('tms:success.CategoryDeleted', { name }))
      return { response, isSuccessful: true }
    } catch (error) {
      setIsSubmitting(false)
      toast.error(t('tms:errors.DeleteCategoryError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleChangeIsActive = async (id, isActive) => {
    try {
      setIsSubmitting(true)
      const response = await changeIsActiveCategory({ id, isActive }).unwrap()
      setIsSubmitting(false)
      toast.success(t(`tms:success.Category${isActive ? 'Activated' : 'Deactivated'}`, { name }))
      return { response, isSuccessful: true }
    } catch (error) {
      setIsSubmitting(false)
      toast.error(t('tms:errors.ChangeIsActiveCategoryError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <Main>
      {isSubmitting && <Loader overlay />}
      <TaskCategoryList
        onEdit={handleEditCategory}
        onDuplicate={handleDuplicateCategory}
        onDelete={handleDeleteCategory}
        onChangeIsActive={handleChangeIsActive}
      />
      {TaskCategoryPermissions.create(user) && <AddFab onClick={handleCreateCategory} />}
    </Main>
  )
}

export default AdminTaskCategoriesView
