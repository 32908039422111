import { Person } from '@mui/icons-material'
import { Alert, Link } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useBranchesQuery } from '@Branches/Services/Api'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { isBranchEmployee } from '@Common/Utils/Permissions'
import { makePath } from '@Config'
import { apiList } from '@Core/Services/Api'

import { AnagraphicList } from '../Models/Anagraphic'
import { useCcSearchAnagraphicsQuery } from '../Services/Api'

const DISPLAY_COLUMNS = ['nome', 'cognome', 'ragioneSociale', 'email', 'telefono', 'housingUnits']
const FIELDS_MAPPING = {
  immobili: (record) => record.immobili.map(R.prop('denominazione')).join(', '),
  housingUnits: R.pipe(
    R.prop('composizioni'),
    R.map(
      R.pipe(
        R.paths([
          ['immobile', 'denominazione'],
          ['ui', 'codice'],
        ]),
        R.join(' - '),
      ),
    ),
    R.join('<br>'),
    (fieldContent) => <span dangerouslySetInnerHTML={{ __html: fieldContent }}></span>,
  ),
  email: (record) =>
    record.contatti.filter(R.pipe(R.path(['tipo', 'id']), R.equals(20))).map(
      R.pipe(R.prop('riferimento'), (email) => (
        <Link style={{ display: 'block' }} href={`mailto:${email}`} key={email}>
          {email}
        </Link>
      )),
    ),
  telefono: (record) =>
    record.contatti
      .filter(R.pipe(R.path(['tipo', 'id']), R.equals(20), R.not))
      .map(R.prop('riferimento'))
      .join(', '),
}

const AnagraphicSearchResults = ({ fields }) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [selected, setSelected] = React.useState()
  const { data: branches } = apiList(useBranchesQuery())

  // default qs
  const qsAdditions = {
    nominativo__icontains: R.defaultTo('', fields.name),
    telefono__icontains: R.defaultTo('', fields.phone),
    email__icontains: R.defaultTo('', fields.email),
  }
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('customercare-search-anagraphics'),
      offset: 0,
      ordering: 'id',
    },
    qsAdditions,
  })
  const refreshData = (data) => {
    setQs(data)
  }

  // new qs triggers refetch
  const { data: anagraphics, isFetching } = useCcSearchAnagraphicsQuery(qs)

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        label: t('common:actions.Detail'),
        icon: <Person size="small" />,
      },
    ],
    [],
  )

  const handleAction = React.useCallback((actionId, anagraphic) => {
    if (actionId === 'DETAIL') {
      window.open(makePath('customerCare.anagraphic', { residentId: anagraphic.id }))
    }
  }, [])

  const checkSelectable = React.useCallback(
    R.either(
      () => !isBranchEmployee(user),
      R.pipe(
        R.prop(['immobili']),
        R.pluck('gruppoEsterno'),
        R.map(parseInt),
        R.filter(R.flip(R.includes)(R.pluck('id', branches))),
        R.isEmpty,
        R.not,
      ),
    ),
    [user, branches],
  )

  if (!fields.name && !fields.phone && !fields.email) {
    return <Alert severity="warning">{t('customerCare:ui.PleaseFillSomeSearchFields')}</Alert>
  }

  return (
    <>
      <DataTable
        name="customercare-search-anagraphics"
        qs={qs}
        qsAdditions={qsAdditions}
        data={anagraphics?.results || []}
        dataCount={anagraphics?.count || 0}
        refreshData={refreshData}
        model={AnagraphicList}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        selectable={checkSelectable}
        noExport
      />
    </>
  )
}

AnagraphicSearchResults.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
}

export default AnagraphicSearchResults
