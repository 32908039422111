import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'

const TaskCustomFieldSelect = ({ name, control, taskId, selectFieldsFn, ...other }) => {
  const { getValues } = useFormContext()
  const values = getValues()
  const [fields, setFields] = useState([])

  useEffect(() => {
    setFields(selectFieldsFn(values.mainTask, taskId) ?? [])
  }, [JSON.stringify(values)])

  return <SelectField name={name} control={control} options={fields} {...other} />
}

TaskCustomFieldSelect.propTypes = FieldType

export default TaskCustomFieldSelect
