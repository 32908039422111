import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import BallotIcon from '@mui/icons-material/Ballot'
import EngineeringIcon from '@mui/icons-material/Engineering'
import EuroIcon from '@mui/icons-material/Euro'
import ForumIcon from '@mui/icons-material/Forum'
import GasMeterIcon from '@mui/icons-material/GasMeter'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import InboxIcon from '@mui/icons-material/Inbox'
import PaidIcon from '@mui/icons-material/Paid'
import PlumbingIcon from '@mui/icons-material/Plumbing'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import DocumentsIcon from '@Assets/Icons/DocumentsIcon'
import { Nav as BaseNav, Item, Link, Separator } from '@Common/Components/Navigation/Styled'
import { isAdminRoute } from '@Common/Utils/Url'
import { useCondoByIdQuery } from '@Condo/Services/Api'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { selectBuildingAside, toggleBuildingAside } from '@Core/Redux/Ui'

const Fixed = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  width: ${({ width }) => width};
  transition: 0.2s width linear;
`

const Nav = styled(BaseNav)`
  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  padding: 1rem 0;
  overflow: hidden;
  transition: 0.2s all linear;
  height: 100%;
`

export const isArcadiaBuilding = (building) => building?.arcadiaId

export const getCondoAsideMenu = (building) => {
  let items = [{ path: 'buildings.detail.main', label: 'common:navigation.Buildings', Icon: InboxIcon }]

  const arcadiaItems = [
    { path: 'buildings.detail.structure', label: 'common:navigation.BuildingStructure', Icon: HomeWorkIcon },
    { path: 'buildings.detail.facilities', label: 'common:navigation.BuildingFacilities', Icon: PlumbingIcon },
    { path: 'buildings.detail.installments', label: 'common:navigation.BuildingInstallments', Icon: EuroIcon },
    { path: 'buildings.detail.residents', label: 'common:navigation.Residents', Icon: BallotIcon },
    { path: 'buildings.detail.documents', label: 'common:navigation.BuildingDocuments', Icon: DocumentsIcon },
  ]

  const condoItems = [
    { path: 'buildings.detail.podpdrs.list', label: 'common:navigation.BuildingPodPdr', Icon: GasMeterIcon },
    {
      path: 'buildings.detail.insurancePolicy.list',
      label: 'common:navigation.BuildingInsurancePolicy',
      Icon: HealthAndSafetyIcon,
    },
    {
      path: 'buildings.detail.reconciliationReminders.list',
      label: 'common:navigation.ReconciliationReminderManagementShortLabel',
      Icon: PaidIcon,
    },
    {
      path: 'buildings.detail.supplier.supplier',
      label: 'common:navigation.SupplierCompanyLabel',
      Icon: EngineeringIcon,
    },
  ]

  const tmsItems = [{ path: 'tms.taskList', label: 'common:navigation.Tms', Icon: ForumIcon }]

  if (isArcadiaBuilding(building)) {
    items = items.concat(arcadiaItems)
  }

  items = items.concat(condoItems)

  items = items.concat(tmsItems)

  return items
}

const BuildingAside = ({ onItemClick }) => {
  const { buildingId } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const status = useSelector(selectBuildingAside)

  const ToggleIcon = status.expanded ? ArrowForwardIosIcon : ArrowBackIosNewIcon
  const toggleStatus = () => dispatch(toggleBuildingAside())

  const { data: building } = useCondoByIdQuery(buildingId)
  const items = getCondoAsideMenu(building)
  const location = useLocation()
  const basePath = isAdminRoute() ? 'admin.' : ''
  const makeBuildingPath = (path) => makePath(`${basePath}${path}`, { buildingId })

  const navigateTo = (path) => () => {
    onItemClick && onItemClick(path, building)
    history.push(path)
  }

  return (
    <Nav background="buildingNav" width={status.expanded ? status.expandedWidth : status.collapsedWidth}>
      <Fixed width={status.expanded ? status.expandedWidth : status.collapsedWidth}>
        <Item direction="row" justify="flex-end" expanded={status.expanded} style={{ marginTop: 0 }}>
          <ToggleIcon onClick={toggleStatus} style={{ fontSize: '30px' }} />
        </Item>
        <Separator />
        {items.map(({ path: p, label, Icon }, idx) => {
          const path = makeBuildingPath(p)
          // TODO dieffe: maybe it's better to use matchPattern, for eg. see <OperationalSidebar />
          const isActive = location.pathname.includes(path)
          return (
            <Item
              key={idx}
              direction="row"
              align="center"
              justify="flex-end"
              expanded={status.expanded}
              hasActive={isActive}
            >
              {status.expanded && (
                <Link onClick={navigateTo(path)} margin="0 .5rem 0 0">
                  {t(label)}
                </Link>
              )}
              <Tooltip title={status.expanded ? '' : t(label)} placement="left">
                <Icon style={{ fontSize: '30px' }} onClick={navigateTo(path)} />
              </Tooltip>
            </Item>
          )
        })}
      </Fixed>
    </Nav>
  )
}

BuildingAside.propTypes = {
  onItemClick: PropTypes.func,
}

export default BuildingAside
