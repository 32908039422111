import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'



import { setCurrentUser } from '@Auth/Redux/Login';
import { useVerifyEmailMutation, useWhoamiMutation } from '@Auth/Services/Api';
import { getLoginRedirectUrl } from '@Auth/Utils';
import RegistrationLayout from '@Common/Layouts/RegistrationLayout';
import storageFactory from '@Common/Services/Storage';
import logger from '@Common/Utils/Logger';
import { history } from '@Core/Redux/Store';


const Storage = storageFactory()

const Container = styled.div`
  margin: auto;
  max-width: 500px;
  padding: 3rem 0;
  flex: 1 0 auto;
`

const ConfirmRegistrationView = () => {
  const { token } = useParams()
  const decodedToken = atob(token)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [verifyEmail] = useVerifyEmailMutation()
  const [whoami] = useWhoamiMutation()

  React.useEffect(() => {
    const verifyEmailAndLogin = async () => {
      try {
        let token = decodedToken
        logger.debug('Verifying email...', token)
        await verifyEmail({ token: token }).unwrap()
        Storage.save('refreshToken', token)
        logger.debug('Verify email successful, logging in')
        const user = await whoami().unwrap()
        dispatch(setCurrentUser(user))
        const redirectUrl = getLoginRedirectUrl(user)
        logger.debug('Login success, redirecting to ', redirectUrl)
        history.push(redirectUrl)
        toast.success(t('auth:success.ConfirmRegistrationSuccessful'))
      } catch (err) {
        logger.debug('Verify email error', err)
        toast.error(t('auth:errors.ConfirmRegistrationError'))
      }
    }
    verifyEmailAndLogin()
  }, []) // component did mount

  return (
    <RegistrationLayout>
      <Container>
        <CircularProgress color="primary" />
      </Container>
    </RegistrationLayout>
  )
}

export default ConfirmRegistrationView
