import styled from 'styled-components'

const FixedColumn = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: ${({ top }) => top};
  overflow: hidden auto;
  width: ${({ sidebarWidth }) => (sidebarWidth ? `calc(50% - ${sidebarWidth} / 2)` : '50%')};
  height: ${({ top, footerHeight }) => `calc(100vh - ${top || 0} - ${footerHeight || 0})`};
  transition: 0.2s width linear;
`

export const FixedColumnLeft = styled(FixedColumn)`
  padding: 1rem;
`

export const FixedColumnRight = styled(FixedColumn)`
  right: 0;
  padding: 0 1rem;
`
