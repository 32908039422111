import { Euro } from '@mui/icons-material'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useBranchesQuery } from '@Branches/Services/Api'
import { apiList } from '@Core/Services/Api'

import { getEmployeeDisplayName } from '../../Auth/Utils'
import { useBuildingQuery } from '../../Buildings/Services/Api'
import { getBuildingAddress } from '../../Buildings/Utils'
import { withLoader } from '../../Common/Utils/HOC'
import { makePath } from '../../Config'
import { hasBuildingUnpaidInstallments } from '../Utils/Resident'

const ResidentBuildings = ({ immobile, ui, rate }) => {
  const { t } = useTranslation()
  const { data: building, isFetching } = useBuildingQuery(immobile.id)
  const { data: branches } = apiList(useBranchesQuery())
  const goToBuilding = () => {
    window.open(makePath('buildings.detail.main', { buildingId: building?.extra?.localBuildingId }))
  }

  const canAccessBuildingDetail = useCallback(
    R.both(R.path(['extra', 'id']), R.pipe(R.path(['extra', 'branch']), R.flip(R.includes)(R.pluck('id', branches)))),
    [branches],
  )

  return (
    <Card sx={{ maxWidth: '320px' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: hasBuildingUnpaidInstallments(rate) ? 'red' : 'green' }}>
            <Euro />
          </Avatar>
        }
        variant="h5"
        title={immobile.denominazione}
        subheader={getBuildingAddress(immobile)}
      />
      <CardContent>
        <div>
          <strong>{t('customerCare:ui.Edifice')}</strong>: {ui.edificio.descrizione}
        </div>
        <div>
          <strong>{t('customerCare:ui.Stair')}</strong>: {ui.scala.descrizione}
        </div>
        {ui.piano && (
          <div>
            <strong>{t('customerCare:ui.Floor')}</strong>: {ui.piano}
          </div>
        )}
        <div>
          <strong>{t('customerCare:ui.HousingUnit')}</strong>: {ui.descrizione}
        </div>
        {withLoader(
          <>
            <div>
              <strong>{t('common:ui.Branch')}</strong>: {building?.extra?.branchObj.name || '--'}
            </div>
            <div>
              <strong>{t('common:ui.BuildingAdmin')}</strong>:{' '}
              {building?.extra?.branchObj ? getEmployeeDisplayName(building.extra.branchObj.administrativeObj) : '--'}
            </div>
          </>,
          isFetching,
          'inline',
          { minHeight: '60px', size: 20 },
        )}
      </CardContent>
      {canAccessBuildingDetail(building) && building?.extra?.localBuildingId && (
        <CardActions>
          <Button size="small" onClick={goToBuilding}>
            {t('common:actions.Detail')}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

ResidentBuildings.propTypes = {
  immobile: PropTypes.shape({
    id: PropTypes.string,
    codiceFiscale: PropTypes.string,
    denominazione: PropTypes.string.isRequired,
    indirizzo: PropTypes.shape({
      cap: PropTypes.string,
      civico: PropTypes.string,
      comune: PropTypes.string,
      localita: PropTypes.string,
      nazione: PropTypes.string,
      presso: PropTypes.string,
      provincia: PropTypes.string,
      via: PropTypes.string,
    }),
  }),
  ui: PropTypes.shape({
    codice: PropTypes.string,
    descrizione: PropTypes.string,
    interno: PropTypes.string,
    piano: PropTypes.string,
    edificio: PropTypes.shape({
      descrizione: PropTypes.string,
    }),
    scala: PropTypes.shape({
      descrizione: PropTypes.string,
    }),
  }),
  rate: PropTypes.arrayOf(
    PropTypes.shape({
      importo: PropTypes.number,
      residuo: PropTypes.number,
    }),
  ),
}

export default ResidentBuildings
