import React from 'react'
import styled from 'styled-components'
import MyEstiaLogo from '@Assets/MyEstiaLogo'
import { css } from '@mui/material'

const Header = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
  `}
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3e6fb4;
    border-radius: 0 0 100% 100%;
    transform: scaleX(1.1);
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 1.5rem;
`

const ResidentNavbar = () => (
  <Header>
    <Content>
      <MyEstiaLogo style={{ width: '200px' }} />
    </Content>
  </Header>
)

export default ResidentNavbar
