import React from 'react'
import PropTypes from 'prop-types'

import { createColumnsProps, getDisplayValue } from '@Common/Components/DataTable/Utils'
import { useTranslation } from 'react-i18next'
import { Table as MuiTable, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import { Tr } from '@Common/Components/DataTable/Styled'

const Table = ({ listDisplay, model, data, autoWidth }) => {
  const { t } = useTranslation()
  // prepare column props
  const columnsProps = createColumnsProps(model.columns, listDisplay)
  // columns to be displayed
  const displayColumns = columnsProps.filter((c) => c.visible)

  return (
    <TableContainer style={{ maxWidth: '100%', overflow: 'auto' }}>
      <MuiTable style={autoWidth ? { width: 'auto' } : {}} size="small">
        <TableHead key="thead">
          <TableRow key="thead-row">
            {displayColumns.map((column) => {
              return (
                <TableCell
                  key={`col-${column.id}`}
                  align={column.numeric ? 'right' : 'left'}
                  style={{ width: column.width ? column.width : 'auto' }}
                >
                  {column.label}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody key="tbody">
          {data.map((record, index) => {
            return (
              <React.Fragment key={`row-${index}`}>
                <Tr>
                  {displayColumns.map((column) => {
                    return (
                      <TableCell key={`col-${column.id}`} align={column.numeric ? 'right' : 'left'}>
                        {getDisplayValue({}, record, column.id, column)}
                      </TableCell>
                    )
                  })}
                </Tr>
              </React.Fragment>
            )
          })}
          {data.length === 0 && (
            <TableRow>
              <TableCell colSpan={displayColumns.length} align="center">
                <Typography variant="span" color="darkFour">{t('common:ui.NoResults')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  listDisplay: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  autoWidth: PropTypes.bool,
}

export default Table
