/* eslint-disable react/prop-types */
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'

import { DatePickerV2 } from '@Common/Components/FormsV2/DatePickerV2'
import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { api } from '@Core/Services/Api'
import { CondoSupplierCompany, SupplierAssociationContract } from '@Supplier/Models/CondoSupplierCompany'

export const CondoSupplierCompanyContractsForm = ({ defaultValues, isForm = false }) => {
  const form = useForm({ defaultValues })
  const { t } = useTranslation()

  const { buildingId } = useParams()

  const urlAssociation = generatePath(CondoSupplierCompany.url, {
    buildingId: buildingId,
  })

  const [update] = api.useUpdateOrCreateItemMutation()

  const handleSave = (item) => {
    let data = {
      ...item,
      bicertSupplierId: defaultValues.bicertSupplierId,
      bicertSupplierBranchIds: defaultValues.bicertSupplierBranchIds,
    }

    return update({ url: urlAssociation, item: data })
  }

  const result = (children) => {
    if (isForm) {
      return (
        <FormV2 form={form} onSubmit={handleSave} model={CondoSupplierCompany}>
          {children}
        </FormV2>
      )
    }

    return children
  }

  return result(
    <InputFieldV2
      name="contractList"
      template={InputsContractListFields}
      labelBtnAdd={t('condosuppliercompany:addNewContract')}
    />,
  )
}

export const InputsContractListFields = ({ index }) => {
  const { t } = useTranslation()

  return (
    <>
      <InputFieldV2
        name={`contractList[${index}].arxivarUrl`}
        label={t(`${SupplierAssociationContract.name.toLowerCase()}:model.fields.arxivarUrl`)}
        type={SupplierAssociationContract.schema.arxivarUrl.type || null}
        inputProps={{ type: 'url' }}
        required={false}
        xs={6}
        sm={6}
        md={6}
        size={'small'}
      />
      <DatePickerV2
        name={`contractList[${index}].expirationDate`}
        label={t(`${SupplierAssociationContract.name.toLowerCase()}:model.fields.expirationDate`)}
      />
    </>
  )
}
