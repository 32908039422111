import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import * as R from 'ramda'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'

import authReducer from '@Auth/Redux'
import branchesReducer from '@Branches/Redux'
import myEstiaReducer from '@MyEstia/Redux'
import tmsReducer from '@Tms/Redux'

// import { setupListeners } from '@reduxjs/toolkit/query'
// api
import { api } from '../Services/Api'
import { rtkQueryErrorNotifier, rtkQueryMaintenanceCheck } from './ApiErrorMiddleware'
// reducers
import startupReducer from './Startup'
import uiReducer from './Ui'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
})

export const reducer = combineReducers({
  router: routerReducer,
  api: api.reducer,
  startup: startupReducer,
  ui: uiReducer,
  auth: authReducer,
  branches: branchesReducer,
  myEstia: myEstiaReducer,
  tms: tmsReducer,
})

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware, api.middleware, rtkQueryErrorNotifier, rtkQueryMaintenanceCheck),
})

// setupListeners(store.dispatch)

export const history = createReduxHistory(store)

export const selectPathname = R.path(['router', 'location', 'pathname'])

export const selectLocationState = R.path(['router', 'location', 'state'])
