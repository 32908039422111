import { MobileDateRangePicker as MuiMobileDateRangePicker } from '@mui/lab'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@Common/Components/Styles'
import { FieldType } from '@Common/Types/Field'

const MobileDateRangePicker = ({ startText, endText, range, onChange }) => {
  const { t } = useTranslation()

  return (
    <MuiMobileDateRangePicker
      toolbarTitle={t('common:ui.SelectDateRange')}
      startText={startText}
      endText={endText}
      value={range}
      onChange={onChange}
      clearable
      clearText={t('common:ui.ClearMe')}
      cancelText={t('common:actions.Cancel')}
      renderInput={(startProps, endProps) => (
        <Box width="100%" direction="row" justify="between">
          <TextField fullWidth variant="standard" sx={{ mr: 2 }} {...startProps} />
          <TextField fullWidth variant="standard" {...endProps} />
        </Box>
      )}
    />
  )
}

MobileDateRangePicker.propTypes = {
  ...FieldType,
  range: PropTypes.array.isRequired,
}

export default MobileDateRangePicker
