/* eslint-disable react/prop-types */
import {
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Box,
  IconButton,
  Tooltip,
  Typography,
  ListSubheader,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import Loader from '@Common/Components/Loader'
import { FieldType } from '@Common/Types/Field'

const SearchableList = ({ options, actions }) => {
  const { t } = useTranslation()

  // Search
  const [search, setSearch] = useState('')
  const handleSearch = (event) => setSearch(event.target.value)

  // format and filter options
  const regex = new RegExp(search, 'gi')
  const isMatchWithSearch = (option) => option?.label?.match(regex) || option?.description?.match(regex)
  const optionsFiltered = options?.filter(isMatchWithSearch)

  // show loader
  if (!optionsFiltered) {
    return (
      <Stack direction="row">
        <Box>
          <Loader size={18} />
        </Box>
        <Typography>{t('common:ui.LoadingDots')}</Typography>
      </Stack>
    )
  }

  const renderSecondaryAction = (item) => {
    return actions?.map((action, index) => {
      return (
        <Tooltip key={index} title={action.tooltip}>
          <IconButton onClick={() => action.onClick(item)}>{action.icon}</IconButton>
        </Tooltip>
      )
    })
  }

  // render virtualized list
  const renderList = ({ height, width }) => (
    <FixedSizeList height={height} width={width} itemSize={72} itemCount={optionsFiltered?.length} overscanCount={5}>
      {(props) => {
        const { index, style } = props
        const option = optionsFiltered[index]
        return (
          <ListItem
            divider
            style={style}
            key={index}
            sx={{ pr: (actions?.length || 0) * 6 }}
            secondaryAction={renderSecondaryAction(option)}
          >
            <ListItemText primary={option.label} secondary={option.description} />
          </ListItem>
        )
      }}
    </FixedSizeList>
  )

  return (
    <Stack>
      <TextField fullWidth size="small" label={t('common:actions.Search')} value={search} onChange={handleSearch} />
      <Box>
        <ListSubheader disableGutters>{t('common:ui.TotalResults', { total: optionsFiltered.length })}</ListSubheader>
        <Box height={320}>
          <AutoSizer>{renderList}</AutoSizer>
        </Box>
      </Box>
    </Stack>
  )
}

SearchableList.propTypes = { ...FieldType }

export { SearchableList }
