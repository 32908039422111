import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import AddFab from '@Common/Components/AddFab'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import TaskList from '@Tms/Components/TaskList'
import CreteTaskForm from '@Tms/Forms/CreateTaskForm'
import { useCreateTaskFlowMutation, useDeleteTaskMutation } from '@Tms/Services/Api'

const Main = styled.main`
  padding: 2rem 2rem 3rem;
`

const TasksView = () => {
  const { t } = useTranslation()
  const [form, setForm] = React.useState({ isOpen: false })

  // api
  const [createTaskFlow] = useCreateTaskFlowMutation()
  const [deleteTask] = useDeleteTaskMutation()

  useBreadcrumbs([{ label: t('common:navigation.Tms'), path: makePath('tms.taskList'), Icon: 'Tms' }])

  const handleTaskDetail = ({ id }) => history.push(makePath('tms.taskDetail', { taskId: id }))

  const handleOpenForm = () => setForm({ isOpen: true })
  const handleCloseForm = () => setForm({ isOpen: false })
  const handleSave = async (formData) => {
    try {
      const response = await createTaskFlow(formData).unwrap()
      toast.success(t('tms:success.TaskCreated', { name: formData.get('title') }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('tms:errors.CreateTaskError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDeleteTask = async ({ id, title }) => {
    try {
      const response = await deleteTask(id).unwrap()
      toast.success(t('tms:success.TaskDeleted', { name: title }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('tms:errors.DeleteTaskError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <Main>
      <TaskList onShow={handleTaskDetail} onDelete={handleDeleteTask} />
      {form.isOpen && <CreteTaskForm onClose={handleCloseForm} onSave={handleSave} />}
      <AddFab onClick={handleOpenForm} />
    </Main>
  )
}

TasksView.propTypes = {}

export default TasksView
