/* eslint-disable react/prop-types */
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { RegionProvinceCitySelectV2 } from '@Common/Components/LocationV2/RegionProvinceCitySelectV2'
import { api } from '@Core/Services/Api'
import { SupplierBranch } from '@Supplier/Models/SupplierBranch'

export const SupplierBranchForm = ({ defaultValues }) => {
  const { t } = useTranslation()
  const form = useForm({ defaultValues })
  const { supplierId } = useParams()

  const isEditMode = !!defaultValues?.id || false

  const url = `/condo/bicert/suppliers/${supplierId}/branches`
  const urlReferents = `/condo/bicert/suppliers/${supplierId}/referents`

  const [update] = api.useUpdateOrCreateItemMutation()
  const handleSave = (item) => {
    if (item.inheritServices) {
      item.services = []
    }

    if (!item.branchReferents) {
      item.branchReferents = []
    }

    return update({ url, item })
  }

  const inheritServices = form.watch('inheritServices', false) || false

  return (
    <FormV2 form={form} onSubmit={handleSave} model={SupplierBranch}>
      <InputFieldV2 name="branchType" xs={12} sm={7} />
      <InputFieldV2 name="emails" xs={12} sm={5} />
      <InputFieldV2 name="telephones" xs={12} sm={6} />
      <InputFieldV2 name="mobiles" xs={12} sm={6} />
      <InputFieldV2 name="city" xs={12} sm={4} />
      <InputFieldV2 name="cap" xs={2} sm={2} />
      <InputFieldV2 name="address" xs={4} sm={8} />
      <InputFieldV2 name="houseNumber" xs={6} sm={2} />
      {!isEditMode && <InputFieldV2 name="inheritServices" xs={12} />}
      {(isEditMode || !inheritServices) && (
        <InputFieldV2 name="services" source="/condo/bicert/service-types" xs={12} />
      )}
      <InputFieldV2 name="branchReferents" source={urlReferents} xs={12} />
      <InputFieldV2
        name="competenceAreaList"
        template={InputsRegionProvinceCity}
        labelBtnAdd={t('supplierbranch:addBtnCompetenceAreaList')}
      />
    </FormV2>
  )
}

const InputsRegionProvinceCity = ({ index, value }) => {
  const { t } = useTranslation()

  return (
    <>
      <InputFieldV2
        name={`competenceAreaList[${index}].county`}
        type="options"
        options={[{ label: 'Italia', value: 1 }]}
        label={t('city:model.fields.county')}
        disabled={true}
        inputProps={{ defaultValue: 1 }}
      />
      <RegionProvinceCitySelectV2
        name={`competenceAreaList[${index}].city`}
        nameCityProvince={`competenceAreaList[${index}].province`}
        nameCityRegion={`competenceAreaList[${index}].region`}
        defaultValueRegion={value.region}
        defaultValueProvince={value.province}
        defaultValueCity={value.city}
        autocompleteFromCity={false}
        required={false}
      />
      <InputFieldV2 name={`competenceAreaList[${index}].cap`} label={t('city:model.fields.cap')} required={false} />
    </>
  )
}
