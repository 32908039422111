import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useAllNotificationsQuery } from '../Services/Api'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Dropdown from '@Common/Components/Dropdown'
import Notification from '../Models/Notification'
import { AddCircleOutline } from '@mui/icons-material'
import { NotificationsPermissions } from '@Notifications/Permissions'
import { setStateFromEvent } from '@Common/Utils/Events'
import { Chip } from '@mui/material'
import NotificationModal from './NotificationModal'
import * as R from 'ramda'

const DISPLAY_COLUMNS = [
  'title',
  'created',
  'topicObj.name',
  'senderObj.username',
  'recipientObj.username',
  'status',
  'canceled',
]

const FIELDS_MAPPING = {
  status: ( // eslint-disable-line 
    r,
    format,
  ) =>
    format === 'jsx' ? (
      <Chip size="small" label={r.statusLabel} color={r.status === 1 ? 'info' : 'success'} style={{ color: '#fff' }} />
    ) : (
      r.statusLabel
    ),
  'senderObj.username': R.pathOr('system', ['senderObj', 'username']),
}

const SEARCH_FIELDS = ['title', 'senderObj.username', 'recipientObj.username']

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    qs.qsAdditions.title__icontains__or = qs.qsAdditions.search
    qs.qsAdditions.sender__username__icontains__or = qs.qsAdditions.search
    qs.qsAdditions.recipient__username__icontains__or = qs.qsAdditions.search
    delete qs.qsAdditions.search
  }

  return qs
}

const NotificationsList = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [status, setStatus] = React.useState(0)
  const [detailModal, setDetailModal] = React.useState({ isOpen: false, notification: null })

  const handleClodeDetailModal = React.useCallback(() => {
    setDetailModal({ isOpen: false, notification: null })
  }, [setDetailModal])

  // default qs
  const qsAdditions = React.useMemo(() => {
    if (status) return { status }
    return {}
  }, [status])
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-notifications'),
      offset: 0,
      ordering: '-created',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useAllNotificationsQuery(convertSearchQuery(qs))

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        label: t('common:actions.Detail'),
        icon: <AddCircleOutline size="small" />,
        perm: NotificationsPermissions.read,
      },
    ],
    [],
  )

  const handleAction = React.useCallback((actionId, notification) => {
    if (actionId === 'DETAIL') {
      setDetailModal({ isOpen: true, notification })
    }
  }, [])

  return (
    <div>
      <DataTable
        name="admin-notifications"
        selectable
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Notification}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={R.compose(setSelected, R.ifElse(R.isNil, R.always([]), R.of), R.last)}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      >
        <Dropdown
          style={{ marginLeft: '.5rem', width: '140px' }}
          options={[
            { id: 1, label: t('notifications:ui.Sent') },
            { id: 2, label: t('notifications:ui.Read') },
          ]}
          labelKey="label"
          valueKey="id"
          emptyValue={0}
          emptyLabel={t('notifications:ui.All')}
          onChange={setStateFromEvent(setStatus)}
          value={status}
          label={t('notifications:fields.status')}
        />
      </DataTable>
      {detailModal.isOpen && (
        <NotificationModal notification={detailModal.notification} onClose={handleClodeDetailModal} />
      )}
    </div>
  )
}

NotificationsList.propTypes = {}

export default NotificationsList
