import styled from 'styled-components'
import { Box } from '@Common/Components/Styles'

const FixedFooter = styled(Box)`
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  bottom: 0;
  right: 0;
  height: 60px;
  padding: 0 1rem;
  box-sizing: border-box;
  width: ${({ sidebarWidth }) => (sidebarWidth ? `calc(100% - ${sidebarWidth})` : '100%')};
  background-color: ${({ theme }) => theme.palette.lightThree.main};
  transition: 0.2s width linear;
`

export default FixedFooter
