import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Chip from '@mui/material/Chip'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { defaultTo, propEq } from 'ramda'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '@Common/Components/Styles'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { RunningTreeTaskType } from '@Tms/Models/RunningTask'
import { TaskType } from '@Tms/Models/Task'
import { getTaskStatus, STATUS_ENUM } from '@Tms/Utils'

const Ul = styled.div`
  padding-top: 5px;

  &:first-child > div {
    padding-left: 0;

    &:before,
    &:after {
      content: none;
    }
  }

  &:last-child > div:after {
    height: calc(100% - 7px);
  }
`

const Li = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-top: 5px;
  padding-left: 30px;

  &:before {
    position: absolute;
    top: 18px;
    left: 15px;
    width: 15px;
    height: 1px;
    margin: auto;
    content: '';
    background-color: #bdbdbd;
  }

  &:after {
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 15px;
    width: 1px;
    height: calc(100% + 5px);
    content: '';
    background-color: #bdbdbd;
  }
`

const InfographicChip = styled(Chip)`
  text-align: center;

  ${({ theme, status }) => css`
    ${(status === STATUS_ENUM.OPEN || status === STATUS_ENUM.PROCESSING) && `color: white;`}
    ${status === STATUS_ENUM.OPEN && `background-color: ${theme.palette.neutralTwo.main};`}
    ${status === STATUS_ENUM.COMPLETED && `background-color: ${theme.palette.success.main};`}
    ${status === STATUS_ENUM.EXPIRED && `background-color: ${theme.palette.warning.main};`}
    ${status === STATUS_ENUM.PROCESSING && `background-color: ${theme.palette.primary.main};`}
    
    &:hover {
      ${status === STATUS_ENUM.OPEN && `background-color: ${theme.palette.neutralTwo.main}BC !important;`}
      ${status === STATUS_ENUM.COMPLETED && `background-color: ${theme.palette.success.main}BC !important;`}
      ${status === STATUS_ENUM.EXPIRED && `background-color: ${theme.palette.warning.main}BC !important;`}
      ${status === STATUS_ENUM.PROCESSING && `background-color: ${theme.palette.primary.main}BC !important;`}
    }
  `}
`

const TaskTreeInfographic = ({ task, flattenTasks }) => {
  const user = useCurrentUser()
  const runningTask = useMemo(() => flattenTasks.find(propEq('taskTemplate', task.id)), [task])
  const deadlineDate = dayjs(runningTask?.deadline ?? 'invalid')
  const isUserTask = user.employeeId === runningTask?.assignedTo
  const deadline = deadlineDate.isValid() ? deadlineDate.format('YYYY-MM-DD') : ''
  let status
  let handleOpenTask
  if (runningTask) {
    status = getTaskStatus(runningTask)
    handleOpenTask = () => history.push(makePath('tms.taskDetail', { taskId: runningTask?.id }))
  }
  const title = defaultTo(task.name, runningTask?.title)

  return (
    <Ul>
      <Li>
        <InfographicChip
          onClick={handleOpenTask}
          status={status}
          variant="outlined"
          size="small"
          label={
            <Box direction="row" align="center">
              {title} {deadline && '|'} {deadline}
              {isUserTask && <AccountCircleIcon sx={{ ml: 1 }} fontSize="small" />}
            </Box>
          }
        />
        {task.children.map((child, index) => (
          <TaskTreeInfographic key={index} task={child} flattenTasks={flattenTasks} />
        ))}
      </Li>
    </Ul>
  )
}

TaskTreeInfographic.defaultProps = {
  flattenTasks: [],
}

TaskTreeInfographic.propTypes = {
  task: TaskType,
  flattenTasks: PropTypes.arrayOf(RunningTreeTaskType),
}

export default TaskTreeInfographic
