import React from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'

const RequiredTypeSelect = ({ value, ...other }) => {
  const { t } = useTranslation()
  const priorities = [
    { value: -1, label: t('tms:fields.isRequired.no') },
    { value: 0, label: t('tms:fields.isRequired.opening') },
    { value: 1, label: t('tms:fields.isRequired.closing') },
  ]

  return <Dropdown width="100%" options={priorities} labelKey="label" valueKey="value" value={value} {...other} />
}

export default RequiredTypeSelect

RequiredTypeSelect.propTypes = {
  ...FieldType,
}
