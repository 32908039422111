import React from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useParams } from 'react-router-dom'

import PrivateRoute from '@Common/Components/PrivateRoute'
import config from '@Config'
import { setActiveHousingUnitId } from '@MyEstia/Redux'
// Views
import MyHomeTmsLayout from '@MyHome/Layouts/MyHomeTmsLayout'
import MyHomeNewTicketView from '@MyHome/Views/MyHomeNewTicketView'
import MyHomeTicketDetailView from '@MyHome/Views/MyHomeTicketDetailView'
import MyHomeTicketsView from '@MyHome/Views/MyHomeTicketsView'

export const MyHomeTicketsRouter = () => {
  const { housingUnitId } = useParams()
  const dispatch = useDispatch()

  React.useMemo(() => {
    dispatch(setActiveHousingUnitId(housingUnitId))
  }, [housingUnitId])

  return (
    <MyHomeTmsLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
      <Routes>
        <Route
          path={config.urls.myEstia.myHome.ticket.list}
          exact
          element={
            <PrivateRoute>
              <MyHomeTicketsView />
            </PrivateRoute>
          }
        />
        <Route
          path={config.urls.myEstia.myHome.ticket.detail}
          exact
          element={
            <PrivateRoute>
              <MyHomeTicketDetailView />
            </PrivateRoute>
          }
        />
        <Route
          path={config.urls.myEstia.myHome.ticket.new}
          exact
          element={
            <PrivateRoute>
              <MyHomeNewTicketView />
            </PrivateRoute>
          }
        />
      </Routes>
    </MyHomeTmsLayout>
  )
}

export default MyHomeTicketsRouter
