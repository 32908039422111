import React from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import PrivateRoute from '@Common/Components/PrivateRoute'
import config from '@Config'
import { setActiveHousingUnitId } from '@MyEstia/Redux'
// Views
import MyHomeLayout from '@MyHome/Layouts/MyHomeLayout'
import MyHomeDocumentsView from '@MyHome/Views/MyHomeDocumentsView'
import MyHomeInstallmentsView from '@MyHome/Views/MyHomeInstallmentsView'
import MyHomeMainView from '@MyHome/Views/MyHomeMainView'

import MyHomeTicketsRouter from './MyHomeTicketsRouter'

export const MyHomeRouter = () => {
  const { housingUnitId } = useParams()
  const dispatch = useDispatch()

  React.useMemo(() => {
    dispatch(setActiveHousingUnitId(housingUnitId))
  }, [housingUnitId])

  const installmentsComponentsPage = (
    <PrivateRoute>
      <MyHomeLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
        <MyHomeInstallmentsView />
      </MyHomeLayout>
    </PrivateRoute>
  )

  return (
    <Routes>
      <Route
        path={config.urls.myEstia.myHome.main}
        exact
        element={
          <PrivateRoute>
            <MyHomeLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
              <MyHomeMainView />
            </MyHomeLayout>
          </PrivateRoute>
        }
      />
      <Route path={config.urls.myEstia.myHome.installments}>
        <Route index element={installmentsComponentsPage} />
      </Route>
      <Route
        path={config.urls.myEstia.myHome.documents}
        exact
        element={
          <PrivateRoute>
            <MyHomeLayout contentStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
              <MyHomeDocumentsView />
            </MyHomeLayout>
          </PrivateRoute>
        }
      />
      <Route path={config.urls.myEstia.myHome.ticket.base} element={<MyHomeTicketsRouter />} />
      <Route path="*" element={<Navigate to={config.urls.pageNotFound} replace />} />
    </Routes>
  )
}

export default MyHomeRouter
