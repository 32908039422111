import { CardContent, Grid, CardHeader } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Card from '@Common/Components/Card'
import NameValueList from '@Common/Components/NameValueList'
import { Margin } from '@Common/Components/Styles'
import ChipCondoState from '@Condo/Components/ChipCondoState'
import { CondoAcquisitionMode } from '@Condo/Models/Condo'

const AcquisitionData = ({ acquisitionData }) => {
  const { t } = useTranslation()

  return (
    <Margin bottom="2rem">
      <Card variant="outlined">
        <CardHeader title={t('common:components.AcquisitionData.title')} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  {
                    name: t('common:components.AcquisitionData.state'),
                    value: acquisitionData?.state ? <ChipCondoState state={acquisitionData.state} /> : null || '-',
                  },
                  {
                    name: t('common:components.AcquisitionData.acquisitionMode'),
                    value: acquisitionData?.acquisitionMode
                      ? CondoAcquisitionMode.find((x) => x.id === acquisitionData?.acquisitionMode).label
                      : null || '-',
                  },
                  {
                    name: t('common:components.AcquisitionData.acquisitionDate'),
                    value: acquisitionData?.acquisitionDate
                      ? dayjs(acquisitionData?.acquisitionDate).format('DD/MM/YYYY')
                      : null || '-',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <NameValueList
                data={[
                  {
                    name: t('common:components.AcquisitionData.lossDate'),
                    value: acquisitionData?.lossDate
                      ? dayjs(acquisitionData?.lossDate).format('DD/MM/YYYY')
                      : null || '-',
                  },
                  {
                    name: t('common:components.AcquisitionData.managementClosingDate'),
                    value: acquisitionData?.managementClosingDate
                      ? dayjs(acquisitionData?.managementClosingDate).format('DD/MM/YYYY')
                      : null || '-',
                  },
                  {
                    name: t('common:components.AcquisitionData.appointmentDate'),
                    value: acquisitionData?.appointmentDate
                      ? dayjs(acquisitionData?.appointmentDate).format('DD/MM/YYYY')
                      : null || '-',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Margin>
  )
}

AcquisitionData.propTypes = {
  acquisitionData: PropTypes.shape({
    state: PropTypes.any,
    acquisitionMode: PropTypes.any,
    acquisitionDate: PropTypes.any,
    lossDate: PropTypes.any,
    managementClosingDate: PropTypes.any,
    appointmentDate: PropTypes.any,
  }),
}

export default AcquisitionData
