import React, { useEffect, useState } from 'react'
import { FieldType } from '@Common/Types/Field'

import Dropdown from '@Common/Components/Dropdown'
import { useRolesQuery } from '../Services/Api'
import * as R from 'ramda'
import { ROLES } from '@Auth/Costants'


const filterRoles = R.pipe(
  R.defaultTo([]),
  R.filter(R.complement(R.propEq('id', ROLES.RESIDENT))),
  R.map(R.pick(['id', 'name'])),
)

const RoleSelect = ({ onSelectRole, value, ...props }) => {
  const { data } = useRolesQuery()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    setRoles(filterRoles(data?.results))
    if (onSelectRole && R.not(R.isNil(data?.results))) {
      onSelectRole(R.find(R.propEq('id', value), data.results))
    }
  }, [data?.results, value])

  return (
    <Dropdown options={roles} labelKey="name" valueKey="id" value={roles.length > 0 ? value : ''} {...props} />
  )
}

RoleSelect.propTypes = {
  ...FieldType,
}

export default RoleSelect
