/* eslint-disable react/prop-types */
import { useForm } from 'react-hook-form'

import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { api } from '@Core/Services/Api'
import { SupplierCompanyRating } from '@Supplier/Models/SupplierCompanyRating'

export const SupplierCompanyRatingForm = ({ defaultValues }) => {
  const form = useForm({ defaultValues })

  const [update] = api.useUpdateOrCreateItemMutation()

  const handleSave = (item) => {
    return update({
      url: SupplierCompanyRating.url,
      item: {
        bicertSupplierId: item.id,
        solidity: item.solidity,
        quality: item.quality,
        sustainability: item.sustainability,
      },
    })
  }

  return (
    <FormV2 form={form} onSubmit={handleSave} model={SupplierCompanyRating}>
      <InputFieldV2 name="solidity" />
      <InputFieldV2 name="quality" />
      <InputFieldV2 name="sustainability" />
    </FormV2>
  )
}
