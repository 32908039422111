import { SvgIcon } from '@mui/material'
import React from 'react'

const DocumentsIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <path d="M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4M15 7H20.5L15 1.5V7M8 0H16L22 6V18C22 19.11 21.11 20 20 20H8C6.89 20 6 19.1 6 18V2C6 .89 6.89 0 8 0M17 16V14H8V16H17M20 12V10H8V12H20Z" />
  </SvgIcon>
))

DocumentsIcon.displayName = 'DocumentsIcon'

export default DocumentsIcon;
