/* eslint-disable react/prop-types */
import { Grid } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormV2 } from '@Common/Components/FormsV2/FormV2'
import { InputFieldV2 } from '@Common/Components/FormsV2/InputFieldV2'
import { api } from '@Core/Services/Api'
import { CertificationDocumentType } from '@Supplier/Models/CertificationDocumentType'

export const CertificationDocumentTypeForm = ({ defaultValues }) => {
  const form = useForm({ defaultValues })
  const { t } = useTranslation()

  const expiration = form.watch('expiration', !!defaultValues?.expirationDays || false)
  const expirationNotification = form.watch('expirationNotification')

  if (!expirationNotification) {
    form.setValue('reminderFrequency', '')
  }

  if (!expiration) {
    form.setValue('expirationDays', '')
  }

  const [update] = api.useUpdateOrCreateItemMutation()
  const handleSave = (item) => {
    /**
     * TODO ZORAN: Refactor this
     * Cancello e setto la validazione solo per questa tipologia, da unificare dentro la sezione del formv2 e
     * gestire le i required lato app e non solo lato api
     */
    if (!form.getValues('expirationDays')) {
      if (expiration) {
        form.setError('expirationDays', { type: 'required', message: t('common:validation.RequiredField') })
      }

      // Setto a null perchè react hook form form.setValue non puoi mettere a null (strano)
      item['expirationDays'] = null
    }

    if (expirationNotification && !form.getValues('reminderFrequency')) {
      form.setError('reminderFrequency', { type: 'required', message: t('common:validation.RequiredField') })
    }

    if (form.formState.errors && Object.keys(form.formState.errors).length > 0) {
      return
    }

    return update({ url: CertificationDocumentType.url, item })
  }

  return (
    <FormV2 form={form} onSubmit={handleSave} model={CertificationDocumentType}>
      <InputFieldV2 name="name" xs={6} />
      <InputFieldV2 name="status" xs={3} />
      <InputFieldV2 name="required" xs={3} defaultChecked={false} />
      <InputFieldV2 name="description" xs={12} />
      <Grid item xs={12} sm={6}>
        <InputFieldV2
          name="reminderFrequency"
          disabled={!expirationNotification}
          required={expirationNotification}
          xs={12}
        />
        <InputFieldV2 name="expirationNotification" xs={12} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputFieldV2 name="expirationDays" disabled={!expiration} required={expiration} xs={12} />
        <InputFieldV2 name="expiration" label="Scadenza" xs={12} defaultChecked={expiration} />
      </Grid>
    </FormV2>
  )
}
