import { Add, ChevronRight, Edit, Remove, Send } from '@mui/icons-material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { getEmployeeDisplayName } from '@Auth/Utils'
import { getIban } from '@Buildings/Utils'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Dropdown from '@Common/Components/Dropdown'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useConfirm } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import { NotificationsPermissions } from '@Notifications/Permissions'

import Building from '../Models/Building'
import { BranchBuildingPermissions } from '../Permissions'
import { useBuildingsQuery } from '../Services/Api'

const FilterContainer = styled.div`
  padding-left: 0.5rem;
  width: 100%;
`

const DISPLAY_COLUMNS = [
  'denominazione',
  'codiceFiscale',
  'indirizzo.via',
  'indirizzo.civico',
  'indirizzo.comune',
  'indirizzo.cap',
  'extra.branchObj.name',
]

const FIELDS_MAPPING = {
  administratorFullName: R.pipe(R.path(['extra', 'administratorObj']), getEmployeeDisplayName),
  iban: (building) => getIban(building),
  referente: R.compose(
    R.join(' '),
    R.paths([
      ['referente', 'nome'],
      ['referente', 'cognome'],
    ]),
  ),
}

const SEARCH_FIELDS = ['denominazione', 'codiceFiscale', 'indirizzo.comune', 'indirizzo.cap']

const convertSearchQuery = (qs) => {
  const search = qs.qsAdditions?.search || ''
  const assignedFilter = qs.qsAdditions?.assignedFilter || 0
  const orFilters = search
    ? {
        logic: 'or',
        filters: [
          {
            field: 'denominazione',
            operator: 'contains',
            value: search,
          },
          {
            field: 'codiceFiscale',
            operator: 'contains',
            value: search,
          },
          {
            field: 'indirizzo.comune',
            operator: 'contains',
            value: search,
          },
          {
            field: 'indirizzo.cap',
            operator: 'contains',
            value: search,
          },
        ],
      }
    : null
  const showAssignedFilter =
    assignedFilter === 0 ? null : { field: 'gruppoEsterno', operator: assignedFilter === 1 ? 'isnotnull' : 'isnull' }

  const filters = []
  if (orFilters) filters.push(orFilters)
  if (showAssignedFilter) filters.push(showAssignedFilter)
  const andFilters = {
    logic: 'and',
    filters: filters,
  }

  const qsClone = JSON.parse(JSON.stringify(qs))

  qsClone.qsAdditions.filter = btoa(JSON.stringify(andFilters))

  delete qsClone.qsAdditions.search
  delete qsClone.qsAdditions.showAssigned

  return qsClone
}

const ArcadiaBuildingsList = ({ onCreate, onEdit, onDelete, onNotify }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [assignedFilter, setAssignedFilter] = React.useState(0)
  const navigate = useNavigate()

  // default qs
  const qsAdditions = React.useMemo(
    () => ({
      assignedFilter,
    }),
    [assignedFilter],
  )

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-buildings'),
      offset: 0,
      ordering: 'denominazione',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useBuildingsQuery(convertSearchQuery(qs))

  // const bulkActions = React.useMemo(() => [{ id: 'CREATE', label: t('buildings:ui.AssignSelectedBuildings') }], [])
  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        icon: <ChevronRight />,
        label: t('common:actions.Detail'),
        cond: (record) => record?.extra?.id,
        perm: BranchBuildingPermissions.read,
        disabled: (record) => !record?.extra?.localBuildingId,
        tooltip: (record) => (record?.extra?.localBuildingId ? '' : t('buildings:ui.ArcadiaSync')),
      },
      {
        id: 'CREATE',
        label: t('buildings:actions.CreateBranchBuilding'),
        icon: <Add size="small" />,
        cond: (record) => !record?.extra?.id,
        perm: BranchBuildingPermissions.create,
      },
      {
        id: 'EDIT',
        label: t('buildings:actions.EditBranchBuilding'),
        icon: <Edit size="small" />,
        cond: (record) => record?.extra?.id,
        perm: BranchBuildingPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('buildings:actions.RemoveBranchBuilding'),
        icon: <Remove size="small" />,
        cond: (record) => record?.extra?.id,
        perm: BranchBuildingPermissions.delete,
      },
      {
        id: 'NOTIFY',
        label: t('common:actions.Notify'),
        icon: <Send size="small" />,
        cond: (record) => record?.extra?.id,
        perm: NotificationsPermissions.create,
      },
    ],
    [data?.count],
  )

  const handleAction = React.useCallback(
    (actionId, building) => {
      switch (actionId) {
        case 'CREATE':
          onCreate(building ? [building] : selected)
          break
        case 'EDIT':
          onEdit([building])
          break
        case 'NOTIFY':
          onNotify([building])
          break
        case 'DELETE':
          openDeleteConfirm(
            building, // payload
            t('buildings:ui.DeleteBranchBuildingConfirmationTitle'),
            t('buildings:ui.DeleteBranchBuildingConfirmationText', { branch: building.extra.branchObj.name }),
            { selected: [building], field: 'denominazione' },
          )
          break
        case 'DETAIL':
          navigate(makePath('admin.buildings.detail.main', { buildingId: building?.extra?.localBuildingId }))
          break
      }
    },
    [selected],
  )

  // const checkSelectable = React.useCallback((building) => !building.extra, [])
  const handleDeleteBranchBuilding = async (building) => {
    onDelete(building)
  }
  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteBranchBuilding)

  return (
    <div>
      <DataTable
        name="admin-buildings"
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Building}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        noExport
      >
        <FilterContainer>
          <Dropdown
            style={{ width: '150px' }}
            options={[
              { id: 0, label: t('common:ui.All') },
              { id: 1, label: t('common:ui.Assigned') },
              { id: 2, label: t('common:ui.Unassigned') },
            ]}
            labelKey="label"
            valueKey="id"
            onChange={setStateFromEvent(setAssignedFilter)}
            value={assignedFilter}
            label={t('common:ui.State')}
          />
        </FilterContainer>
      </DataTable>
      {DeleteConfirm}
    </div>
  )
}

ArcadiaBuildingsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onNotify: PropTypes.func.isRequired,
}

export default ArcadiaBuildingsList
