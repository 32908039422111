import i18next from 'i18next'
import PropTypes from 'prop-types'

export const ReportType = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  taskCategory: PropTypes.number,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('tickets:fields.id'),
      width: '40px',
    },
    {
      id: 'code',
      label: i18next.t('tickets:fields.code'),
      width: '40px',
    },
    {
      id: 'name',
      label: i18next.t('tickets:fields.name'),
    },
  ],
}
