import { Apartment } from '@mui/icons-material'
import { Alert } from '@mui/material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useBranchesQuery } from '@Branches/Services/Api'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { makePath } from '@Config'
import { apiList } from '@Core/Services/Api'

import { BuildingList } from '../Models/Building'
import { useCcSearchBuildingsQuery } from '../Services/Api'

const DISPLAY_COLUMNS = ['denominazione', 'codiceFiscale', 'comune', 'indirizzo', 'extra.branchObj.name']
const FIELDS_MAPPING = {
  indirizzo: R.pipe(R.prop('indirizzo'), R.defaultTo({}), R.pick(['via', 'civico']), R.values, R.join(' ')),
  comune: R.path(['indirizzo', 'comune']),
}

const BuildingSearchResults = ({ fields }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const { data: branches } = apiList(useBranchesQuery())

  // default qs
  const qsAdditions = {
    nominativo__icontains: R.defaultTo('', fields.buildingName),
    codiceFiscale__icontains: R.defaultTo('', fields.fiscalCode),
    comune__icontains: R.defaultTo('', fields.city),
    indirizzo__icontains: R.defaultTo('', fields.address),
  }
  if (fields.branch) {
    qsAdditions.gruppoEsterno = fields.branch
  }
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('customercare-search-buildings'),
      offset: 0,
      ordering: 'id',
    },
    qsAdditions,
  })
  const refreshData = (data) => {
    setQs(data)
  }

  // new qs triggers refetch
  const { data: buildings, isFetching } = useCcSearchBuildingsQuery(qs)

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        label: t('common:actions.Detail'),
        icon: <Apartment size="small" />,
      },
    ],
    [],
  )

  const checkSelectable = React.useCallback(
    R.both(R.path(['extra', 'id']), R.pipe(R.path(['extra', 'branch']), R.flip(R.includes)(R.pluck('id', branches)))),
    [branches],
  )

  const handleAction = React.useCallback((actionId, building) => {
    if (actionId === 'DETAIL') {
      window.open(makePath('buildings.detail.main', { buildingId: building?.extra?.localBuildingId }))
    }
  }, [])

  if (!fields.buildingName && !fields.fiscalCode && !fields.city && !fields.address && !fields.branch) {
    return <Alert severity="warning">{t('customerCare:ui.PleaseFillSomeSearchFields')}</Alert>
  }

  return (
    <>
      <DataTable
        name="customercare-search-buildings"
        selectable={checkSelectable}
        qs={qs}
        qsAdditions={qsAdditions}
        data={buildings?.results || []}
        dataCount={buildings?.count || 0}
        refreshData={refreshData}
        model={BuildingList}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noExport
      />
    </>
  )
}

BuildingSearchResults.propTypes = {
  fields: PropTypes.shape({
    buildingName: PropTypes.string,
    fiscalCode: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    branch: PropTypes.string,
  }),
}

export default BuildingSearchResults
