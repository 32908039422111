import React from 'react'
import ServerAutoCompleteField from '@Common/Components/ServerAutocompleteField'
import { FieldType } from '@Common/Types/Field'
import { useBuildingsQuery } from '@Buildings/Services/Api'
import * as R from 'ramda'

/**
 * Usage:
 *
 *  <BuildingAutocomplete
 *    label={t('buildings:fields.building.__field')}
 *    onChange={setValue} />
 */
const BuildingAutocomplete = ({ ...props }) => {
  return (
    <ServerAutoCompleteField
      queryFn={useBuildingsQuery}
      queryOrdering='denominazione'
      querySearchObjCb={R.objOf('denominazione__icontains__or')}
      queryOptionsObjCb={(b) => ({ label: b.denominazione, value: b.id })}
      {...props}
    />
  )
}

BuildingAutocomplete.propTypes = {
  ...FieldType,
}

export default BuildingAutocomplete
