import { yupResolver } from '@hookform/resolvers/yup'
import { defaultTo } from 'ramda'
import { useCallback, useMemo } from 'react'
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'

import logger from '@Common/Utils/Logger'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { getEmptyTask, getNewTaskId } from '@Tms/Utils'

import { validationSchema as categoryValidationSchema } from './Validation'

const useCategoryForm = (category, onSave, onCancel, setIsSubmitting = null) => {
  const validationSchema = useMemo(() => categoryValidationSchema, [])

  const { control, ...methods } = useForm({
    defaultValues: {
      id: defaultTo(null, category?.id),
      name: defaultTo('', category?.name),
      isActive: defaultTo(false, category?.isActive),
      parent: defaultTo('', category?.parent),
      isBuildingEnabled: defaultTo(false, category?.isBuildingEnabled),
      isResidentEnabled: defaultTo(false, category?.isResidentEnabled),
      isAdminSelectEnabled: defaultTo(false, category?.isAdminSelectEnabled),
      openPrivileges: defaultTo([], category?.openPrivileges),
      mainTask: defaultTo({ id: getNewTaskId(), ...getEmptyTask() }, category?.mainTask),
    },
    resolver: yupResolver(validationSchema),
  })

  const setErrors = useCallback((errors) => {
    const convertServerErrors = (errors, refPath) => {
      const transformed = {}

      const keys = Object.keys(errors)

      if (keys.length) {
        Object.keys(errors).forEach((key) => {
          if (key === 'children') {
            transformed[key] = errors[key].map((x, i) => convertServerErrors(x, `${refPath}.${key}.${i}`))
          } else if (key === 'openConditions') {
            transformed[key] = errors[key].map((x, i) => convertServerErrors(x, `${refPath}.${key}.${i}`))
          } else if (key === 'closeConditions') {
            transformed[key] = errors[key].map((x, i) => convertServerErrors(x, `${refPath}.${key}.${i}`))
          } else {
            methods.setError(`${refPath}.${key}`, { type: 'server', message: errors[key][0], ref: { name: `${refPath}.${key}` } })
            transformed[key] = {
              message: errors[key][0],
              type: 'server',
              ref: {
                name: `${refPath}.${key}`,
              },
            }
          }
        })
      } else {
        return null
      }
      return transformed
    }

    convertServerErrors(errors.mainTask, 'mainTask')

  }, [])

  const onInvalid = useCallback((errors) => {
    logger.debug('Category form submission, validation failed, errors:', errors)
  }, [])

  const onSubmit = useCallback(async (fields) => {
    logger.debug('Category form submission, validation passed, fields:', fields)
    if (setIsSubmitting) setIsSubmitting(true)
    const isEditing = !!fields.id
    const { error, isSuccessful, response } = await onSave(fields)
    if (!isSuccessful && error?.status === 400) {
      logger.debug('Category form submission api error', error)
      setErrors(error.data)
    }

    if (!isEditing && isSuccessful) {
      history.push(makePath('tms.editCategory', { category: response.id }))
    }

    if (setIsSubmitting) setIsSubmitting(false)
  }, [])

  return {
    ...methods,
    control,
    errors: methods.formState.errors,
    onSubmit: methods.handleSubmit(onSubmit, onInvalid),
  }
}

export const useTaskChildrenFormField = (prefix = '') => {
  const inputPath = prefix
  const { control, register, getValues } = useFormContext()
  const { fields, append, remove, ...methods } = useFieldArray({
    control,
    name: `${inputPath}children`,
  })

  const addNewTask = () => {
    const newTask = { id: getNewTaskId(getValues('mainTask')), ...getEmptyTask() }
    append(newTask)
  }

  const removeTask = (taskIndex) => {
    remove(taskIndex)
  }

  return {
    fields,
    control,
    register,
    inputPath,
    addNewTask,
    removeTask,
    ...methods,
  }
}

export const useTaskFieldsFormField = (prefix = '') => {
  const inputPath = prefix
  const { control, getValues } = useFormContext()
  const { fields, append, remove, insert, ...methods } = useFieldArray({
    control,
    name: `${inputPath}fields`,
  })

  const addNewTaskField = (field) => {
    append(field)
  }

  const updateTaskField = (field) => {
    const index = fields.findIndex((f) => f.id === field.id)
    // workaround to avoid override of database id
    const { id } = getValues(`${inputPath}fields.${index}`)
    remove(index)
    insert(index, { ...field, id })
  }

  const removeTaskField = (id) => {
    const index = fields.findIndex((f) => f.id === id)
    remove(index)
  }

  return {
    fields,
    inputPath,
    addNewTaskField,
    updateTaskField,
    removeTaskField,
    ...methods,
  }
}

export const useTaskOpenConditionFormField = (prefix = '') => {
  const inputPath = `${prefix}openConditions`
  const { control, register } = useFormContext()
  const { fields, append, remove, ...methods } = useFieldArray({
    control,
    name: inputPath,
  })

  const isFirstChildren = useCallback(() => prefix === 'mainTask.children.0.', [])

  const addOpenCondition = () => {
    append({
      conditionType: isFirstChildren() ? 0 : 1,
      conditionTask: '',
      conditionField: '',
      conditionFieldChoice: '',
    })
  }

  const removeOpenCondition = (taskIndex) => {
    remove(taskIndex)
  }

  return {
    fields,
    control,
    register,
    inputPath,
    addOpenCondition,
    removeOpenCondition,
    ...methods,
  }
}

export const useTaskCloseConditionFormField = (prefix = '') => {
  const inputPath = `${prefix}closeConditions`
  const { control, register } = useFormContext()
  const { fields, append, remove, ...methods } = useFieldArray({
    control,
    name: inputPath,
  })

  const addCloseCondition = () => {
    append({
      conditionType: 4,
      conditionTask: '',
      conditionField: '',
      conditionFieldChoice: '',
    })
  }

  const removeCloseCondition = (taskIndex) => {
    remove(taskIndex)
  }

  return {
    fields,
    control,
    register,
    inputPath,
    addCloseCondition,
    removeCloseCondition,
    ...methods,
  }
}

export default useCategoryForm
