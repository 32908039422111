import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RestoreChangesDialog = ({ open, onRestore, onDiscard }) => {
  const { t } = useTranslation()
  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle>{t('tms:ui.RestoreChangesTitle')}</DialogTitle>
      <DialogContent dividers>{t('tms:ui.RestoreChangesText')}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDiscard} color="primary">
          {t('tms:actions.Ignore')}
        </Button>
        <Button onClick={onRestore} color="primary">
          {t('tms:actions.RestoreDraft')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RestoreChangesDialog.propTypes = {
  open: PropTypes.bool,
  onRestore: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
}

export default RestoreChangesDialog
