/* eslint-disable react/prop-types */
// TODO FEDE: add component description
import { api } from '@Core/Services/Api'

import { SelectFieldV2 } from './SelectFieldV2'

export const SelectFromApiV2 = (props) => {
  const { source, qs, ordering } = props
  const { data } = api.useGetItemsQuery({
    url: source,
    filters: { ...{ base: { limit: -1, fields: ['id', 'metadata'], ordering }, ...qs } },
  })

  const options = data?.results?.map((x) => x?.metadata || x) || []

  return (
    <SelectFieldV2 labelKey="name" descriptionKey="description" valueKey="id" {...props} searchable options={options} />
  )
}
