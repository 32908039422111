import styled, { css } from 'styled-components';



import Bg from '@Assets/Images/bg.png';
import { Box } from '@Common/Components/Styles';


export const Wrapper = styled.div`
  align-items: center;
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`

export const Container = styled.div`
  margin: auto;

  @media (min-width: 991px) {
    width: 100%;
    max-width: 900px;
  }

  @media (min-width: 1300px) {
    max-width: 1330px;
  }
`

export const FormCard = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.lightOne.main};
    box-shadow: 0 0 10px ${theme.palette.darkOne.main};
  `}
  border-radius: 20px;
  margin: 2rem;
  padding: 2rem;
  max-width: 280px;
`
