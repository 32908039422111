import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EmployeeByRoleSelect from '@Auth/Components/EmployeeByRoleSelect'
import { ROLES } from '@Auth/Costants'
import Building, { BuildingType } from '@Buildings/Models/Building'
import Alert from '@Common/Components/Alert'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Margin } from '@Common/Components/Styles'
import Table from '@Common/Components/Table'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { Condo, CondoType } from '@Condo/Models/Condo'

const MODEL = {
  condo: Condo,
  branchbuilding: Building,
}

const DISPLAY_COLUMNS = {
  branchbuilding: [
    'nominativo',
    'codiceFiscale',
    'indirizzo.via',
    'indirizzo.civico',
    'indirizzo.comune',
    'extra.branchObj.name',
    'extra.administratorObj.fullName',
    'extra.accountingOwnerObj.fullName',
    'extra.administrationOwnerObj.fullName',
    'extra.accidentsOwnerObj.fullName',
  ],
  condo: [
    'description',
    'fiscalCode',
    'address',
    'houseNumber',
    'cityObj.name',
    'cityObj.provinceObj.name',
    'cap',
    'state',
    'administratorFullName',
    'branchBuildingName',
  ],
}

const BuildingOwnersForm = ({ buildings, buildingType, onClose, onSave }) => {
  const { t } = useTranslation()
  const title = t(`buildings:ui.AssignBranchBuildingOwners`)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isSingleBuilding = buildings.length === 1

  const singleBuilding = useMemo(() => (buildingType === 'condo' ? buildings[0] : buildings[0].extra), [])

  const { fields, setField, errors, setErrors } = useForm(
    {
      ids: buildings.map((b) => (buildingType === 'condo' ? b.id : b.extra.id)),
      accountingOwner: isSingleBuilding ? singleBuilding.accountingOwner : null,
      administrationOwner: isSingleBuilding ? singleBuilding.administrationOwner : null,
      accidentsOwner: isSingleBuilding ? singleBuilding.accidentsOwner : null,
    },
    true,
  )

  const submit = async () => {
    logger.debug('Building owners form submission, fields:', fields)
    setIsSubmitting(true)
    const { error, isSuccessful } = await onSave(fields)
    if (!isSuccessful && error?.status === 400) {
      setIsSubmitting(false)
      logger.debug('Building owners form submission api error', error)
      setErrors(convertServerErrors(error))
    } else {
      onClose()
    }
  }

  return (
    <Modal title={title} size="lg" onClose={onClose} onSubmit={submit}>
      {isSubmitting && <Loader overlay />}
      {errors.__form && <Alert level="error">{errors.__form}</Alert>}
      <Margin bottom="2rem">
        <Table data={buildings} model={MODEL[buildingType]} listDisplay={DISPLAY_COLUMNS[buildingType]} />
      </Margin>
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={500}>{t('buildings:ui.SelectOwners')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <EmployeeByRoleSelect
            value={fields.accountingOwner}
            roles={[ROLES.HQ_ADMINISTRATIVE]}
            label={t('buildings:fields.accountingOwner')}
            onChange={setStateFromEvent(setField('accountingOwner'))}
            error={!!errors.accountingOwner}
            helperText={errors.accountingOwner}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <EmployeeByRoleSelect
            value={fields.administrationOwner}
            roles={[ROLES.HQ_ADMINISTRATIVE]}
            label={t('buildings:fields.administrationOwner')}
            onChange={setStateFromEvent(setField('administrationOwner'))}
            error={!!errors.administrationOwner}
            helperText={errors.administrationOwner}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <EmployeeByRoleSelect
            value={fields.accidentsOwner}
            roles={[ROLES.HQ_ADMINISTRATIVE]}
            label={t('buildings:fields.accidentsOwner')}
            onChange={setStateFromEvent(setField('accidentsOwner'))}
            error={!!errors.accidentsOwner}
            helperText={errors.accidentsOwner}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

BuildingOwnersForm.propTypes = {
  buildings: PropTypes.arrayOf(PropTypes.oneOfType([BuildingType, CondoType])).isRequired,
  buildingType: PropTypes.oneOf(['condo', 'branchbuilding']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default BuildingOwnersForm
