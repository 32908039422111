import { Edit } from '@mui/icons-material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import DeleteIcon from '@mui/icons-material/Delete'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { selectCurrentUser } from '@Auth/Redux/Login'
import { canAssignOwners, canViewAllBranches } from '@Auth/Utils'
import { BranchType } from '@Branches/Models/Branch'
import OwnerSelect from '@Buildings/Components/OwnerSelect'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { ExportExcelButton } from '@Common/Components/ExportExcelButton'
import { Box } from '@Common/Components/Styles'
import { useConfirm } from '@Common/Utils/Hooks'
import { Condo } from '@Condo/Models/Condo'
import { CondosPermissions } from '@Condo/Permissions'
import { useCondosQuery } from '@Condo/Services/Api'
import { makePath } from '@Config'
import { apiList } from '@Core/Services/Api'

import { convertSearchQuery, FIELDS_MAPPING, SEARCH_FIELDS } from './CondosList'

const BranchCondoList = ({ onEdit, onDelete, branches, onAssignOwners }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const [selected, setSelected] = useState([])
  const [owner, setOwner] = useState(-1)

  const DISABLED_SORT_FIELDS = []

  const DISPLAY_COLUMNS = React.useMemo(() => {
    // default columns for condo
    return [
      'description',
      'fiscalCode',
      'address',
      'houseNumber',
      'cityObj.name',
      'cityObj.provinceObj.name',
      'cap',
      'state',
      'administratorFullName',
      'branchBuildingName',
    ]
  }, [branches.length])

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        icon: <CorporateFareIcon />,
        label: t('common:actions.Detail'),
        perm: CondosPermissions.read,
      },
      {
        id: 'EDIT',
        icon: <Edit />,
        label: t('common:actions.Edit'),
        perm: CondosPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: CondosPermissions.delete,},
        {
        id: 'OWNERS',
        icon: <SupervisorAccountIcon />,
        label: t('buildings:actions.Owners'),
        perm: canAssignOwners,
      },
    ],
    [],
  )

  const bulkActions = React.useMemo(
    () => [
      {
        id: 'OWNERS',
        icon: <SupervisorAccountIcon />,
        label: t('buildings:actions.Owners'),
        perm: canAssignOwners,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, branchBuilding) => {
      switch (actionId) {
        case 'EDIT':
          onEdit(branchBuilding)
          break
        case 'DETAIL':
          navigate(makePath('buildings.detail.main', { buildingId: branchBuilding.id }))
          break
        case 'DELETE':
          openDeleteConfirm(
            selected, // payload
            t('condo:ui.DeleteCondoConfirmationTitle'),
            t('condo:ui.DeleteCondoConfirmationText', { count: selected.length }),
            { selected: selected, field: 'description' },
          )
          break
        case 'OWNERS':
          onAssignOwners(selected)
          break
      }
    },
    [selected],
  )

  // default qs
  const qsAdditions = React.useMemo(() => {
    if (canViewAllBranches(user) && branches.length > 1) {
      // optimization, do not perform a select in with hundreds of ids
      return {
        record_type: 'LOCAL',
      }
    }

    return { branch__in: branches.map(R.prop('id')).join(','), record_type: 'LOCAL' }
  }, [branches.map(R.prop('id')).join('-'), user?.id])

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('condo-buildings'),
      offset: 0,
      ordering: 'description',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, count, isFetching } = apiList(useCondosQuery(convertSearchQuery(qs)))

  const toolbar = <ExportExcelButton defaultFilename="condomini" exportApi="condo/buildings/excel" qs={qs} />

  const handleDeleteProvider = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteProvider)
  const buildings = useMemo(() => {
    if (owner === -1) {
      // all buildings
      return data
    } else if (owner === 0) {
      // buildings w/o owner
      return data.filter((b) => [b.administrationOwner, b.accidentsOwner, b.accountingOwner].includes(null))
    } else {
      // buildings with selected owner
      return data.filter((b) => [b.administrationOwner, b.accidentsOwner, b.accountingOwner].includes(owner))
    }
  }, [data, owner])

  return (
    <div>
      <DataTable
        name={`branch-condo`}
        qs={qs}
        qsAdditions={qsAdditions}
        data={buildings}
        dataCount={count}
        refreshData={refreshData}
        model={Condo}
        sortField="id"
        sortDirection="desc"
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        disableSorting={DISABLED_SORT_FIELDS}
        loading={isFetching}
        actions={actions}
        bulkActions={bulkActions}
        onAction={handleAction}
        selected={selected}
        onSelect={setSelected}
        noExport
        useDjangoOrdering
        toolbarContent={toolbar}
      >
        <Box margin="0 1rem" width="100%" direction="row" justify="start">
          <Box width="200px">
            <OwnerSelect label={t('buildings:actions.FilterByOwner')} value={owner} setValue={setOwner} />
          </Box>
        </Box>
      </DataTable>
      {DeleteConfirm}
    </div>
  )
}

BranchCondoList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(BranchType),
  onAssignOwners: PropTypes.func,
}

export default BranchCondoList
