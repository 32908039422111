import { ChevronRight, Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getEmployeeDisplayName } from '@Auth/Utils'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Dropdown from '@Common/Components/Dropdown'
import { ExportExcelButton } from '@Common/Components/ExportExcelButton'
import { ImportExcelButton } from '@Common/Components/ImportExcelButton'
import { GeneralFilterContainer } from '@Common/Components/Styles'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useConfirm, useCurrentUser } from '@Common/Utils/Hooks'
import { Condo, CondoAcquisitionMode, CondoCurrentAdministrator, CondoState } from '@Condo/Models/Condo'
import { CondosPermissions } from '@Condo/Permissions'
import { useCondosQuery } from '@Condo/Services/Api'
import { makePath } from '@Config'

import ChipCondoState from './ChipCondoState'

export const FIELDS_MAPPING = {
  acquisitionMode: (record) => CondoAcquisitionMode.find((x) => x.id === record.acquisitionMode)?.label,
  // eslint-disable-next-line react/display-name
  state: (record) => <ChipCondoState state={record.state} />,
  administratorFullName: R.pipe(R.path(['referringAdministratorObj']), getEmployeeDisplayName),
  branchBuildingName: (record) => {
    return record?.branchObj?.name || null
  },
  referente: R.pipe(R.path(['dedicatedAdministratorObj']), getEmployeeDisplayName),
  currentAdministrator: (record) => {
    return CondoCurrentAdministrator.find((x) => x.id === record?.currentAdministrator)?.label
  },
}

export const SEARCH_FIELDS = ['description', 'fiscal_code', 'city__name', 'city__province__name', 'cap', 'state']

export const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${field}__icontains__or`] = qs.qsAdditions.search
    })
    delete qs.qsAdditions.search
  }

  return qs
}

const CondosList = ({ onEdit, onDelete }) => {
  const user = useCurrentUser()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [selected, setSelected] = React.useState([])
  const [assignedFilter, setAssignedFilter] = React.useState(0)

  const DISPLAY_COLUMNS = React.useMemo(() => {
    return [
      'description',
      'fiscalCode',
      'branchBuildingName',
      'address',
      'houseNumber',
      'acquisitionMode',
      'cityObj.name',
      'cityObj.provinceObj.name',
      'cap',
      'administratorFullName',
      'state',
    ]
  })

  const qsAdditions = React.useMemo(() => {
    let obj = {
      state: assignedFilter,
      record_type: 'LOCAL',
    }

    if (assignedFilter === 0) {
      delete obj.state
    }

    return obj
  }, [assignedFilter])

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-condo-buildings'),
      offset: 0,
      ordering: 'description',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useCondosQuery(convertSearchQuery(qs))

  const actions = React.useMemo(
    () => [
      {
        id: 'DETAIL',
        icon: <ChevronRight />,
        label: t('common:actions.Detail'),
        perm: CondosPermissions.read,
      },
      {
        id: 'EDIT',
        icon: <Edit />,
        label: t('common:actions.Edit'),
        perm: CondosPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: CondosPermissions.delete,
      },
    ],
    [data?.count],
  )

  const handleAction = React.useCallback(
    (actionId, building) => {
      switch (actionId) {
        case 'EDIT':
          onEdit(building)
          break
        case 'DETAIL':
          navigate(makePath('admin.buildings.detail.main', { buildingId: building.id }))
          break
        case 'DELETE':
          openDeleteConfirm(
            selected, // payload
            t('condo:ui.DeleteCondoConfirmationTitle'),
            t('condo:ui.DeleteCondoConfirmationText', { count: selected.length }),
            { selected: selected, field: 'description' },
          )
          break
      }
    },
    [selected],
  )

  const handleDeleteProvider = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteProvider)

  const toolbar = (
    <>
      {<ExportExcelButton defaultFilename="condomini" exportApi="condo/buildings/excel" qs={qs} />}
      {CondosPermissions.create(user) && <ImportExcelButton importApi="condo/buildings/excel" />}
    </>
  )

  return (
    <div>
      <DataTable
        name="admin-condos"
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Condo}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        noExport
        useDjangoOrdering
        toolbarContent={toolbar}
      >
        <GeneralFilterContainer>
          <Dropdown
            style={{ width: '150px' }}
            options={CondoState}
            labelKey="label"
            valueKey="id"
            onChange={setStateFromEvent(setAssignedFilter)}
            value={assignedFilter}
            emptyValue={0}
            emptyLabel={t('common:ui.All')}
            label={t('common:ui.State')}
          />
        </GeneralFilterContainer>
      </DataTable>

      {DeleteConfirm}
    </div>
  )
}
CondosList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}
export default CondosList
