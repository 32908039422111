import { api } from '@Core/Services/Api'

const prefix = 'buildings/stripe'
const payPrefix = 'payments/installments'
const availablePaymentMethodsPrefix = 'payments/methods'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    stripeAccounts: builder.query({
      query: () => `${prefix}`,
      providesTags: [{ type: 'StripeAccount', id: 'LIST' }],
    }),
    stripeAccountExternalAccounts: builder.query({
      query: (stripeId) => `${prefix}/${stripeId}`,
      providesTags: (_, __, stripeId) => [{ type: 'StripeAccountExternalAccounts', id: stripeId }],
    }),
    paySessionStripe: builder.mutation({
      query: ({ installmentId, anagraphicArcadiaId, method = null, redirect = null }) => ({
        url: `${payPrefix}/${installmentId}?anagrafica__id=${anagraphicArcadiaId}&method=${method}&redirect=${redirect}`,
        method: 'GET',
      }),
      invalidatesTags: ['PaySessionStripe'],
    }),
    availablePaymentMethods: builder.mutation({
      query: () => ({
        url: `${availablePaymentMethodsPrefix}`,
        method: 'GET',
      }),
      invalidatesTags: ['AvailablePaymentMethods'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useStripeAccountsQuery,
  useStripeAccountExternalAccountsQuery,
  usePaySessionStripeMutation,
  useAvailablePaymentMethodsMutation,
} = extendedApi
