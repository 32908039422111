import { Card, CardContent, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { EditButton } from '@Common/Components/Buttons/EditButton'
import DataTable from '@Common/Components/DataTable'
import { ItemContainer } from '@Common/Components/ItemContainer'
import { FieldValue } from '@Common/Components/ItemField'
import { api } from '@Core/Services/Api'
import { CondoSupplierCompanyContractsForm } from '@Supplier/Forms/CondoSupplierCompanyContractsForm'
import { CondoSupplierCompany, SupplierAssociationContract } from '@Supplier/Models/CondoSupplierCompany'

export const SupplierCompanyContractListView = () => {
  const { buildingId, condoSupplierId } = useParams()
  const { t } = useTranslation()

  const { data, isLoading } = api.useGetItemQuery(`/condo/buildings/${buildingId}/suppliers/${condoSupplierId}`)

  const dataResult = data?.contractList || null

  const FIELDS_MAPPING = {
    arxivarUrl: (r) => <FieldValue value={r.arxivarUrl} type="url" />,
    expirationDate: (r) => <FieldValue value={r.expirationDate} type="date" />,
  }

  const modelV1 = [
    {
      id: 'arxivarUrl',
      label: t(`${SupplierAssociationContract.name.toLowerCase()}:model.fields.arxivarUrl`),
    },
    {
      id: 'expirationDate',
      label: t(`${SupplierAssociationContract.name.toLowerCase()}:model.fields.expirationDate`),
    },
  ]

  return (
    <Card variant="outlined" sx={{ height: 1 }}>
      <CardContent>
        <Grid sx={{ textAlign: 'end', pb: 2 }}>
          <ItemContainer item={data} model={CondoSupplierCompany}>
            <EditButton
              title={`${t('common:actions.Edit')} ${t(
                `${CondoSupplierCompany.name.toLowerCase()}:tabsForm.contracts`,
              )}`}
              form={CondoSupplierCompanyContractsForm}
              formProps={{ isForm: true }}
            />
          </ItemContainer>
        </Grid>

        {dataResult && dataResult?.length > 0 && (
          <DataTable
            name={SupplierAssociationContract.name.toLowerCase()}
            data={dataResult}
            dataCount={dataResult?.length}
            loading={isLoading}
            useDjangoOrdering
            noExport
            qs={null}
            refreshData={() => {}}
            listDisplay={Object.values(modelV1).map((x) => x.id)}
            columns={Object.values(modelV1).map((x) => x.id)}
            model={{
              columns: modelV1,
            }}
            noSettings={true}
            fieldsMapping={FIELDS_MAPPING}
          />
        )}
        {!dataResult ||
          (dataResult?.length === 0 && <p>{t(`${SupplierAssociationContract.name.toLowerCase()}:noItems`)}</p>)}
      </CardContent>
    </Card>
  )
}
