import React from 'react'
import PropTypes from 'prop-types'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster/src/react-leaflet-markercluster'
import L from 'leaflet'
import { useLazyBuildingsQuery } from '../../../Buildings/Services/Api'
import { useSelector } from 'react-redux'
import { selectActiveBranch } from '../../../Branches/Redux'

import Loader from '../Loader'
import styled from 'styled-components'
import { apiList } from '../../../Core/Services/Api'
import { useBranchesQuery } from '../../../Branches/Services/Api'
import { createBranchMarker, createBuildingMarker } from './Helpers'
import * as R from 'ramda'

const MapWrapper = styled.div`
  min-height: 600px;
  position: relative;
  height: 600px;
  width: 100%;
`

const Map = ({ bounds, branchMarkers, buildingMarkers }) => {
  const map = useMap()
  if (bounds) map.fitBounds(bounds)

  return (
    <>
      {branchMarkers}
      <MarkerClusterGroup>{buildingMarkers}</MarkerClusterGroup>
    </>
  )
}

Map.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
  bounds: PropTypes.object,
  branchMarkers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  buildingMarkers: PropTypes.array,
}

const DashboardMapWidget = () => {
  const romeLatLng = [41.902782, 12.496366] // Rome coordinates
  const activeBranch = useSelector(selectActiveBranch)
  const { data: branches } = apiList(useBranchesQuery())
  const [fetchBuildings, buildings] = useLazyBuildingsQuery()
  const tileRef = React.useRef(null)

  // auto expand a single branch
  React.useEffect(() => {
    if (activeBranch?.id) handleBranchClick(activeBranch)
  }, [activeBranch?.id])

  const handleBranchClick = (branch) => {
    const qs = {
      base: { limit: 1000, offset: 0, ordering: 'id' },
      qsAdditions: { gruppoEsterno: branch.id },
    }
    fetchBuildings(qs)
  }

  const branchMarkers = R.ifElse(
    R.isNil,
    R.always(branches.map(createBranchMarker(handleBranchClick))),
    R.pipe(createBranchMarker(handleBranchClick)),
  )(activeBranch)

  const buildingMarkers = R.ifElse(
    R.isNil,
    R.always([]),
    () => R.defaultTo([], buildings.data?.results).map(createBuildingMarker)
  )(buildings.data?.results)

  const markersLatLng = R.concat(
    R.map(R.props(['lat', 'lng']), branches),
    R.defaultTo([], buildings.data?.results).map(
      R.paths([
        ['extra', 'lat'],
        ['extra', 'lng'],
      ]),
    ),
  ).filter(R.compose(R.not, R.isNil, R.nth(0))) // remove null latLng
  let bounds
  if (branches.length + R.defaultTo(0, buildings.data?.results?.length) > 0) {
    bounds = new L.LatLngBounds(markersLatLng)
  }

  return (
    <MapWrapper>
      <MapContainer center={romeLatLng} zoom={6} scrollWheelZoom style={{ height: '100%', width: '100%' }}>
        <TileLayer
          ref={tileRef}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Map branchMarkers={branchMarkers} buildingMarkers={buildingMarkers} bounds={bounds} />
        {buildings.status === 'pending' && <Loader overlay absolute />}
      </MapContainer>
    </MapWrapper>
  )
}

export default DashboardMapWidget
