import { FormControl as MuiFormControl } from '@mui/material'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { PRIORITY_ENUM, STATUS_ENUM } from '@Tms/Utils'

export const FormControl = ({ children, ...props }) => (
  <MuiFormControl fullWidth margin="dense" {...props}>
    {children}
  </MuiFormControl>
)

FormControl.propTypes = { children: PropTypes.node }

export const CategoryPanelContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: calc(60px /* header height */ + 52px /* breadcrumbs height */);
  height: calc(100vh - 60px /* header height */ - 52px /* breadcrumbs height */ - 60px /* footer height */);
  width: 300px;
  padding: 1rem 0;
`

export const CategoryPanel = styled.div`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.palette.lightFour.main};
  overflow-y: auto;
  padding: 0 1rem;
`

export const TaskFlowContainer = styled.div`
  position: fixed;
  top: calc(60px /* header height */ + 52px /* breadcrumbs height */);
  box-sizing: border-box;
  right: 0;
  width: ${({ sidebarWidth }) => (sidebarWidth ? `calc(100% - 300px - ${sidebarWidth})` : 'calc(100% - 300px)')};
  padding: 1rem;
  overflow-y: auto;
  height: calc(100vh - 60px /* header height */ - 52px /* breadcrumbs height */ - 60px /* footer height */);
  transition: 0.2s width linear;
`

export const SectionTitle = styled.div`
  font-weight: bold;
  overflow: hidden;
  margin-bottom: 0.6rem;

  &:after {
    content: '';
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 1px solid ${({ theme }) => theme.palette.lightFour.main};
  }
`

export const SubSectionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.6rem;
`

export const StatusBadge = styled(Chip)`
  ${({ theme, status }) => css`
    text-align: center;
    ${(status === STATUS_ENUM.OPEN || status === STATUS_ENUM.PROCESSING) && `color: white;`}
    ${status === STATUS_ENUM.OPEN && `background-color: ${theme.palette.neutralTwo.main};`}
    ${status === STATUS_ENUM.COMPLETED && `background-color: ${theme.palette.success.main};`}
    ${status === STATUS_ENUM.EXPIRED && `background-color: ${theme.palette.warning.main};`}
    ${status === STATUS_ENUM.PROCESSING && `background-color: ${theme.palette.primary.main};`}
  `}
`

export const PriorityBadge = styled(Chip)`
  ${({ theme, priority }) => css`
    text-align: center;
    ${(priority === PRIORITY_ENUM.HIGH) && `color: white;`}
    ${priority === PRIORITY_ENUM.LOW && `background-color: ${theme.palette.success.main};`}
    ${priority === PRIORITY_ENUM.MEDIUM && `background-color: ${theme.palette.warning.main};`}
    ${priority === PRIORITY_ENUM.HIGH && `background-color: ${theme.palette.error.main};`}
  `}
`
