import { NavigateNext } from '@mui/icons-material'
import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { history } from '@Core/Redux/Store'

const Link = styled(MuiLink)`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  font-size: 1.1rem;
`

const BreadcrumbsNav = styled(MuiBreadcrumbs)`
  line-height: 1;

  .MuiBreadcrumbs-separator {
    margin: 0;
  }

  .MuiBreadcrumbs-li:last-child a {
    font-weight: 500;
  }
`

/**
 *  TODO: future implementation to be made to handle breadcrumb as per react router documentation (https://reactrouter.com/en/main/hooks/use-matches)
 **/

const Breadcrumbs = ({ items }) => {
  return (
    <BreadcrumbsNav color="inherit" maxItems={5} separator={<NavigateNext fontSize="small" />}>
      {items.map(({ label, path, Icon }, idx) => (
        <Link
          underline="hover"
          key={`breadcrumb-${idx}`}
          onClick={path ? () => history.push(path) : undefined}
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        >
          {Icon && <Icon sx={{ mr: 0.5, mb: 0.3 }} fontSize="inherit" />}
          <p style={{ marginTop: '0px', marginBottom: '-0.4px' }}>{label}</p>
        </Link>
      ))}
    </BreadcrumbsNav>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
}

export default Breadcrumbs
