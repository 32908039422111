import * as R from 'ramda'
import React, { useEffect, useState } from 'react'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'
import { useUnpaginatedServiceCategoriesQuery } from '@Services/Services/Api'
import { buildCategoriesPaths } from '@Services/Utils'

const CategorySelect = ({ value, id, exclude, ...props }) => {
  const { data } = useUnpaginatedServiceCategoriesQuery()
  const filterCategories = R.pipe(
    R.defaultTo([]),
    R.filter(R.complement(R.propEq('id', id))),
    R.map(R.pick(['id', 'name', 'parent'])),
  )

  const [categories, setCategories] = useState([])
  useEffect(() => {
    if (exclude && exclude.length) {
      let allCategories = filterCategories(data?.results)
      const excluded = new Set(exclude)
      setCategories(
        buildCategoriesPaths(
          allCategories.filter((category) => {
            return !excluded.has(category?.id)
          }),
        ),
      )
    } else {
      setCategories(buildCategoriesPaths(filterCategories(data?.results)))
    }
  }, [data?.results, value])

  return (
    <Dropdown
      options={categories}
      labelKey='name'
      valueKey='id'
      value={categories.length > 0 ? value : ''}
      {...props}
    />
  )
}

CategorySelect.propTypes = {
  ...FieldType,
}

export default CategorySelect
