import { Grid } from '@mui/material'
import i18next from 'i18next'
import PropTypes from 'prop-types'

import Alert from '@Common/Components/Alert'
import { Margin } from '@Common/Components/Styles'
import { ServiceType } from '@Services/Models/Service'

import ServiceCard from './ServiceCard'

const ServicesGrid = ({ services }) => (
  <Grid container rowSpacing={5} columnSpacing={6} justifyContent={'center'} sx={{ flexGrow: 1 }}>
    {services.map((service, idx) => (
      <Grid key={idx} item xs={12} sm={6} md={4}>
        <ServiceCard service={service} />
      </Grid>
    ))}
    {services.length === 0 && (
      <Margin top="2rem">
        <Alert level="info">{i18next.t('services:info.NoResults')}</Alert>
      </Margin>
    )}
  </Grid>
)

ServicesGrid.propTypes = {
  services: PropTypes.arrayOf(ServiceType),
}

export default ServicesGrid
