import { isRejectedWithValue } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { toast } from 'react-toastify'

import logger from '@Common/Utils/Logger'
import config from '@Config'

import { history } from '../Redux/Store'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorNotifier = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    logger.warning('We got a rejected action!', action)
    const { error, payload, meta, type } = action
    // Do not automatically notify mutation errors.
    // Each mutation error is handled in place.
    // Notify GET errors instead, because GETs are
    // not managed directly
    if (!/executeMutation/.test(type)) {
      toast.warn(
        i18next.t('common:errors.ApiErrorMessage', {
          status: payload.originalStatus || payload.status,
          message: payload?.data?.detail || `${error.message}.`,
          endpoint: meta.arg.endpointName,
        }),
      )
    }
  }

  return next(action)
}

/**
 * Check for maintenance mode
 */
export const rtkQueryMaintenanceCheck = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action) && action.payload.originalStatus === 503) {
    // // Maintenance mode detection
    logger.debug('API 503 detected')
    history.push(config.urls.maintenance)
  }

  return next(action)
}
