import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Dropdown from '@Common/Components/Dropdown'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useConfirm } from '@Common/Utils/Hooks'
import { camelToSnakeCase } from '@Common/Utils/Strings'
import CategorySelect from '@Services/Components/admin/CategorySelect'
import { buildCategoriesPaths } from '@Services/Utils'

import Service from '../../Models/Service'
import { ServicePermissions } from '../../Permissions'
import { useServicesQuery, useUnpaginatedServiceCategoriesQuery } from '../../Services/Api'

const FilterContainer = styled.div`
  padding-left: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  > * {
    padding-right: 1rem;
  }
`

const DISPLAY_COLUMNS = ['id', 'isActive', 'title', 'listDescription', 'slug', 'orderingNumber', 'categoryObj.name', 'saleMethod']

const SEARCH_FIELDS = ['title', 'listDescription', 'slug']

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${camelToSnakeCase(field).replace('.', '__')}__icontains__or`] = qs.qsAdditions.search
    })
    delete qs.qsAdditions.search
  }

  if (qs.qsAdditions?.saleMethodFilter) {
    if (qs.qsAdditions?.saleMethodFilter === 'external') qs.qsAdditions.external_sale__isnull = false
  }

  if (qs.qsAdditions?.categoryFilter) {
    qs.qsAdditions.category__id = qs.qsAdditions.categoryFilter
  }

  delete qs.qsAdditions.saleMethodFilter
  delete qs.qsAdditions.categoryFilter

  return qs
}
const ServiceList = ({ onEdit, onDelete }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState([])
  const [categoriesPath, setCategoriesPath] = React.useState([])
  const [saleMethodFilter, setSaleMethodFilter] = React.useState(null)
  const [categoryFilter, setCategoryFilter] = React.useState(null)

  const { data: categories, isFetching: categoriesIsFetching } = useUnpaginatedServiceCategoriesQuery()
  React.useEffect(() => {
    if (categories && categories?.results) {
      setCategoriesPath(buildCategoriesPaths(categories?.results))
    }
  }, [categories])

  const qsAdditions = React.useMemo(
    () => ({
      saleMethodFilter,
      categoryFilter,
    }),
    [saleMethodFilter, categoryFilter],
  )

  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('admin-service'),
      offset: 0,
      ordering: '-id',
    },
    qsAdditions,
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  // new qs triggers refetch
  const { data, isFetching } = useServicesQuery(convertSearchQuery(qs))
  const handleDeleteService = async () => {
    onDelete(selected)
  }

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteService)
  const bulkActions = React.useMemo(() => [{ id: 'DELETE', label: t('services:ui.DeleteSelectedService') }], [])
  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <EditIcon size="small" />,
        perm: ServicePermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <DeleteIcon size="small" />,
        perm: ServicePermissions.delete,
      },
    ],
    [],
  )
  const handleAction = React.useCallback(
    (actionId, services) => {
      if (actionId === 'DELETE') {
        openDeleteConfirm(
          selected, // payload
          t('services:ui.DeleteServicesConfirmationTitle'),
          t('services:ui.DeleteServiceConfirmationText', { count: selected.length }),
          { selected: selected, field: 'title' },
        )
      } else if (actionId === 'EDIT') {
        onEdit(services, services.saleMethod.type)
      }
    },
    [openDeleteConfirm, selected],
  )

  const FIELDS_MAPPING = React.useMemo(
    () => ({
      // eslint-disable-next-line react/display-name
      detailImage: (record) => <img src={record.detailImage} style={{ height: '40px' }} />,
      // eslint-disable-next-line react/display-name
      listImage: (record) => <img src={record.listImage} style={{ height: '40px' }} />,
      // eslint-disable-next-line react/display-name
      saleMethod: (record) => t('services:fields.service.type.' + [record.saleMethod.type]),
      // eslint-disable-next-line react/display-name
      ['categoryObj.name']: (record) =>
        R.pipe(R.find(R.propEq('id', record.categoryObj.id)), R.prop('name'))(categoriesPath),
    }),
    [categoriesPath],
  )

  return (
    <div>
      <DataTable
        name="admin-services"
        selectable
        qs={qs}
        qsAdditions={qsAdditions}
        data={data?.results || []}
        dataCount={data?.count || 0}
        refreshData={refreshData}
        model={Service}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        selected={selected}
        onSelect={setSelected}
        loading={isFetching || categoriesIsFetching}
        bulkActions={bulkActions}
        actions={actions}
        fieldsMapping={FIELDS_MAPPING}
        onAction={handleAction}
        useDjangoOrdering
        noExport
      >
        <FilterContainer>
          <Dropdown
            width="170px"
            options={[{ id: 'external', label: t('services:fields.service.type.external') }]}
            labelKey="label"
            valueKey="id"
            onChange={setStateFromEvent(setSaleMethodFilter)}
            value={saleMethodFilter}
            label={t('services:fields.service.SaleMethod')}
          />
          <CategorySelect
            width="170px"
            value={categoryFilter}
            label={t('services:fields.service.Category')}
            onChange={setStateFromEvent(setCategoryFilter)}
          />
        </FilterContainer>
      </DataTable>
      {DeleteConfirm}
    </div>
  )
}

ServiceList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default ServiceList
