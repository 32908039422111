import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { getCondoBreadcrumbFromId } from '@Buildings/Utils'
import { AddButton } from '@Common/Components/Buttons/AddButton'
import { DataTableV3 } from '@Common/Components/DataTableV3'
import { SearchField } from '@Common/Components/FormsV2/SearchField'
import { RegionProvinceCitySelectV2 } from '@Common/Components/LocationV2/RegionProvinceCitySelectV2'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { ChipStatusSupplierCompany } from '@Supplier/Components/ChipStatusSupplierCompany'
import { SupplierActions } from '@Supplier/Components/SupplierCompanyActions'
import { SupplierServiceField } from '@Supplier/Components/SupplierServiceField'
import { SupplierCompany } from '@Supplier/Models/SupplierCompany'

import { CondoSupplierCompanyListView } from './CondoSupplierCompanyListView'

export const SupplierCompanyListView = () => {
  const { t } = useTranslation()
  const { buildingId } = useParams()
  const [isBuildingDetail, setIsBuildingDetail] = useState(false)

  const baseBreadcrumbs = getCondoBreadcrumbFromId(buildingId)
  const breadcrumbItem = {
    label: t('common:navigation.SupplierManagement'),
    Icon: !buildingId && 'EngineeringIcon', // show icon only in root view
  }

  useEffect(() => {
    if (buildingId) {
      setIsBuildingDetail(true)
    }
  }, [buildingId])

  useBreadcrumbs(
    isBuildingDetail ? [...baseBreadcrumbs, breadcrumbItem] : [breadcrumbItem],
    isBuildingDetail ? baseBreadcrumbs : {},
  )

  const FIELDS_MAPPING = {
    status: (record) => {
      return <ChipStatusSupplierCompany value={record.status} />
    },
  }

  const renderFilters = (
    <>
      {!isBuildingDetail && (
        <RegionProvinceCitySelectV2
          name={`city`}
          nameCityProvince={`province`}
          nameCityRegion={`region`}
          defaultValueRegion={null}
          defaultValueProvince={null}
          defaultValueCity={null}
          autocompleteFromCity={true}
          nameCityCap="cap"
          xs={3}
          sm={3}
          required={false}
        />
      )}
      <SupplierServiceField />
      <SearchField xs={4} />
    </>
  )

  if (isBuildingDetail) {
    return <CondoSupplierCompanyListView renderFilters={renderFilters} />
  }

  const toolbar = (
    <AddButton
      type={null}
      variant={'text'}
      model={SupplierCompany}
      title={`${t('common:actions.Report')} ${t(`${SupplierCompany.name.toLowerCase()}:model.verbose_name`)}`}
      dialogTitle={t(`${SupplierCompany.name.toLowerCase()}:reportSupplier`)}
    />
  )

  return (
    <>
      <DataTableV3
        url={'/condo/bicert/suppliers'}
        model={SupplierCompany}
        renderFilters={renderFilters}
        renderActions={<SupplierActions />}
        defaultOrdering="name"
        fieldsMapping={FIELDS_MAPPING}
        toolbarContent={toolbar}
        columns={[
          'name',
          'vatNumber',
          'companyType.name',
          'pec',
          'emails',
          'telephones',
          'mobiles',
          'nightIntervention',
          'isInstitutional',
          'solidity',
          'quality',
          'sustainability',
          'services',
        ]}
        disableSorting={['solidity', 'quality', 'sustainability', 'services']}
      />
    </>
  )
}
