import React from 'react'
import PropTypes from 'prop-types'
import { useResidentInstallmentsQuery } from '@Residents/Services/Api'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Installment from '@Buildings/Models/Installment'
import DatePicker from '@Common/Components/DatePicker'
import * as R from 'ramda'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Box, Margin } from '../../Common/Components/Styles'
import moment from 'moment'

const DISPLAY_COLUMNS = [
  'anagrafica.nominativo',
  'ui.edificio.descrizione',
  'ui.scala.descrizione',
  'ui.descrizione',
  'importo',
  'incassato',
  'residuo',
  'scadenza',
  'descrizione',
]

const FIELDS_MAPPING = {
  residuo: R.pipe(R.prop('residuo'), R.toString, R.concat('€ ')),
  incassato: R.pipe(R.prop('incassato'), R.toString, R.concat('€ ')),
  importo: R.pipe(R.prop('importo'), R.toString, R.concat('€ ')),
  incassi: (record) => record.incassi.map(i => `${dayjs(i.data).format('DD/MM/YYYY')} - € ${i.importo}`).join('\n'),
}

const DISABLED_SORT_FIELDS = ['incassi']

const ResidentInstallments = ({ resident }) => {
  const { t } = useTranslation()
  const [expiresFrom, setExpiresFrom] = React.useState(null)
  const [expiresTo, setExpiresTo] = React.useState(null)
  // default qs
  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage('resident-installments'),
      offset: 0,
      ordering: 'ui.edificio.descrizione',
    },
  })
  const refreshData = (data) => {
    setQs(data)
  }
  const [qsAdditions, setQsAdditions] = React.useState({})

  // search
  React.useEffect(() => {
    const additions = {}
    if (expiresFrom) {
      additions.scadenza__gte = moment(expiresFrom).format('YYYY-MM-DD')
    }
    if (expiresTo) {
      additions.scadenza__lte = moment(expiresTo).format('YYYY-MM-DD')
    }
    setQsAdditions(additions)
  }, [expiresFrom, expiresTo])

  // new qs triggers refetch
  const { data: installments, isFetching } = useResidentInstallmentsQuery(
    { anagraphicArcadiaId: resident.id, qs },
  )

  return (
    <div>
      <DataTable
        name="building-installments"
        qs={qs}
        qsAdditions={qsAdditions}
        data={installments?.results || []}
        dataCount={installments?.count || 0}
        refreshData={refreshData}
        model={Installment}
        listDisplay={DISPLAY_COLUMNS}
        fieldsMapping={FIELDS_MAPPING}
        disableSorting={DISABLED_SORT_FIELDS}
        loading={isFetching}
        noExport
      >
        <Box direction='row'>
          <Margin right='1rem'>
            <DatePicker
              label={t('customerCare:fields.expiresFrom')}
              value={expiresFrom}
              onChange={setExpiresFrom}
            />
          </Margin>
          <DatePicker
            label={t('customerCare:fields.expiresTo')}
            value={expiresTo}
            onChange={setExpiresTo}
          />
        </Box>
      </DataTable>
    </div>
  )
}

ResidentInstallments.propTypes = {
  resident: PropTypes.shape({
    id: PropTypes.string, 
  }),
}

export default ResidentInstallments
