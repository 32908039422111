import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import Modal from '@Common/Components/Modal'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'

const PhoneContactForm = ({ phone, onClose, onSave }) => {
  const { t } = useTranslation()
  const { fields, setField, setErrors, errors} = useForm({ phone }, true)
  const submit = async () => {
    Logger.debug('Phone contact form submission, fields:', fields)
    const { error, isSuccessful } = await onSave(fields)
    if (!isSuccessful && error?.status === 400) {
      Logger.debug('Phone contact form submission api error', error)
      setErrors(convertServerErrors(error))
    } else {
      onClose()
    }
  }

  return (
    <Modal
      title={t('myEstia:ui.EditPhoneNumber')}
      size="sm"
      onClose={onClose}
      onSubmit={submit}
      disableSubmit={!fields.phone}
    >
      <TextField
        fullWidth
        label={t('myEstia:fields.phone')}
        value={fields.phone}
        onChange={setStateFromEvent(setField('phone'))}
        error={!!errors.phone}
        helperText={errors.phone}
      />
    </Modal>
  )
}

PhoneContactForm.propTypes = {
  phone: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
}

export default PhoneContactForm
