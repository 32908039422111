import PropTypes from 'prop-types'
import React from 'react'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { InsurancePolicyPaymentsLogs } from '@Condo/Models/InsurancePolicy'
import { useInsurancePaymentsLogsQuery } from '@Condo/Services/ApiInsurancePolicy'

const SEARCH_FIELDS = []

const convertSearchQuery = (qs) => {
  if (qs.qsAdditions?.search) {
    SEARCH_FIELDS.forEach((field) => {
      qs.qsAdditions[`${field}__icontains__or`] = qs.qsAdditions.search
    })

    delete qs.qsAdditions.search
  }

  return qs
}

const InsurancePaymentsLogsList = ({ insuranceId }) => {
  const DISPLAY_COLUMNS = React.useMemo(() => {
    return ['premiumPaymentDueDate', 'premiumPaymentEffectiveDate', 'amount', 'user']
  })

  const FIELDS_MAPPING = {
    user: (record) => record?.userObj?.username || '-',
  }

  const dtName = 'all-insurance-payments-logs'

  const [qs, setQs] = React.useState({
    base: {
      limit: getDefaultListPerPage(dtName),
      offset: 0,
      ordering: 'premiumPaymentDueDate',
    },
  })

  const refreshData = React.useCallback(
    (data) => {
      setQs(data)
    },
    [setQs],
  )

  const { data, isFetching } = useInsurancePaymentsLogsQuery({
    insurance_id: insuranceId,
    qs: convertSearchQuery(qs),
  })

  return (
    <DataTable
      name={dtName}
      qs={qs}
      data={data?.results || []}
      dataCount={data?.count || 0}
      refreshData={refreshData}
      model={InsurancePolicyPaymentsLogs}
      listDisplay={DISPLAY_COLUMNS}
      fieldsMapping={FIELDS_MAPPING}
      searchFields={[]}
      loading={isFetching}
      noExport={true}
      noSettings
      noBulkSelection
      sortable={false}
    />
  )
}

InsurancePaymentsLogsList.propTypes = {
  insuranceId: PropTypes.number.isRequired,
}

export default InsurancePaymentsLogsList
