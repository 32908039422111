import * as R from 'ramda'
import { ROLES } from '@Auth/Costants'
import { hasGroup } from '@Common/Utils/Permissions'

const canAccessCustomerCare = R.anyPass([
  R.prop('isSuperUser'),
  hasGroup(ROLES.HQ_CUSTOMER_CARE),
  hasGroup(ROLES.HQ_IT),
  hasGroup(ROLES.HQ_ADMINISTRATIVE),
  hasGroup(ROLES.HQ_MAIN_OFFICE),
  hasGroup(ROLES.HQ_HR),
  hasGroup(ROLES.HQ_MARKETING),
  hasGroup(ROLES.BRANCH_DIRECTOR),
  hasGroup(ROLES.JUNIOR_ADMIN),
  hasGroup(ROLES.SENIOR_ADMIN),
])

export const CustomerCarePermissions = {
  read: canAccessCustomerCare,
  create: canAccessCustomerCare,
  update: canAccessCustomerCare,
  delete: canAccessCustomerCare,
}
