import React, { useEffect, useState } from 'react'
import { FieldType } from '@Common/Types/Field'

import Dropdown from '@Common/Components/Dropdown'
import { useUnpaginatedServiceLabelsQuery } from '@Services/Services/Api'
import * as R from 'ramda'

const LabelSelect = ({ value, ...props }) => {
  const { data } = useUnpaginatedServiceLabelsQuery()

  const filterLabels = R.pipe(
    R.defaultTo([]),
    R.map(R.pick(['id', 'name'])),
    R.sortBy(R.prop('name'))
  )

  const [labels, setLabels] = useState([])
  useEffect(() => {
    setLabels(filterLabels(data?.results))
  }, [data?.results, value])

  return (
    <Dropdown
      options={labels}
      labelKey="name"
      valueKey="id"
      value={data?.results.length > 0 ? value : ''}
      {...props}
    />
  )
}

LabelSelect.propTypes = {
  ...FieldType,
}

export default LabelSelect
