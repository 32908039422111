import i18next from 'i18next'
import PropTypes from 'prop-types'

export const DocumentType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  amount: PropTypes.number,
  supplier: PropTypes.string,
  invoiceNumber: PropTypes.string,
  documentDate: PropTypes.string,
  filesize: PropTypes.string,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'supplier',
      label: i18next.t('myHome:fields.provider'),
    },
    {
      id: 'invoiceNumber',
      label: i18next.t('myHome:fields.invoiceNumber'),
    },
    {
      id: 'documentDate',
      label: i18next.t('myHome:fields.invoiceDate'),
      date: true,
    },
    {
      id: 'amount',
      label: i18next.t('myHome:fields.Amount'),
      currency: true,
    },
    {
      id: 'filesize',
      label: i18next.t('myHome:fields.Size'),
    },
    {
      id: 'id',
      label: i18next.t('myHome:fields.Download'),
    },
  ],
}
