/* eslint-disable react/prop-types */

/**
 * This component allow us to share items and filters with every children through context.
 * This is useful to avoid pass props manully.
 */
import { createContext, useState } from 'react'

import { useDebounce } from '@Common/Utils/Hooks'
import { api } from '@Core/Services/Api'

export const ItemsLoaderContext = createContext()

export const ItemsLoader = (props) => {
  const { url, model, children, limit, ordering } = props

  /**
   * Loader filters
   * for eg. datatables search, ...
   */
  const [filtersBase, setFiltersBase] = useState({
    limit: limit || 10,
    offset: 0,
    ordering: ordering || '',
  })

  const [filters, setFilters] = useState({})

  const handlerFilter = (data) => {
    return setFilters({ ...data })
  }

  const handlerFilterBase = (data) => {
    return setFiltersBase({ ...data })
  }

  // Used the debounced because the user make a search make many network calls
  let debouncedFields = useDebounce(filters, 300)

  // Clear a object keys when the value is null because passing a null query params the api go to 500 error
  for (let key of Object.keys(debouncedFields)) {
    if (!debouncedFields[key] || (Array.isArray(debouncedFields[key]) && debouncedFields[key].length === 0)) {
      delete debouncedFields[key]
    }
  }

  // loader
  const { data, isFetching, error } = api.useGetItemsQuery({
    url,
    filters: { base: filtersBase, qsAdditions: debouncedFields },
  })

  // context for sharing data
  const context = {
    model,
    items: data?.results || [],
    count: data?.count || 0,
    isFetching,
    filters: filters,
    filtersBase: filtersBase,
    error,
    onFilterChange: handlerFilter,
    onFilterBaseChange: handlerFilterBase,
  }

  return <ItemsLoaderContext.Provider value={context}>{children}</ItemsLoaderContext.Provider>
}
