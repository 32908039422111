import * as R from 'ramda'

import { ROLES } from '@Auth/Costants'
import { hasGroup, hasPermission } from '@Common/Utils/Permissions'

export const canAccessCondosView = R.anyPass([
  R.prop('isSuperUser'),
  hasGroup(ROLES.HQ_IT),
  hasGroup(ROLES.HQ_MAIN_OFFICE),
  hasGroup(ROLES.HQ_ADMINISTRATIVE),
  hasGroup(ROLES.HQ_MARKETING),
])

export const CondosPermissions = {
  create: hasPermission('condo.add_building'),
  read: hasPermission('condo.view_building'),
  delete: hasPermission('condo.delete_building'),
  update: hasPermission('condo.change_building'),
}

export const PodPdrPermissions = {
  create: hasPermission('condo.add_podpdr'),
  read: hasPermission('condo.view_podpdr'),
  delete: hasPermission('condo.delete_podpdr'),
  update: hasPermission('condo.change_podpdr'),
}

export const InsurancePolicyPermissions = {
  create: hasPermission('condo.add_insurancepolicy'),
  read: hasPermission('condo.view_insurancepolicy'),
  delete: hasPermission('condo.delete_insurancepolicy'),
  update: hasPermission('condo.change_insurancepolicy'),
}

export const InsurancePolicyPaymentsLogPermissions = {
  create: hasPermission('condo.add_insurancepolicypaymentslog'),
  read: hasPermission('condo.view_insurancepolicypaymentslog'),
  delete: hasPermission('condo.delete_insurancepolicypaymentslog'),
  update: hasPermission('condo.change_insurancepolicypaymentslog'),
}

export const ReconciliationReminderPermissions = {
  create: hasPermission('condo.add_reconciliationreminder'),
  read: hasPermission('condo.view_reconciliationreminder'),
  delete: hasPermission('condo.delete_reconciliationreminder'),
  update: hasPermission('condo.change_reconciliationreminder'),
}
