import { Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'

import ArrowFab from '@Common/Components/ArrowFab'
import Card from '@Common/Components/Card'
import { Box } from '@Common/Components/Styles'

const DashboardCard = ({ label, value, link, onLinkClick, navigateOptions, background, color }) => {
  const navigate = useNavigate()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleArrowClick = () => {
    if (onLinkClick) {
      onLinkClick(link, navigateOptions)
    } else {
      navigate(link, navigateOptions)
    }
  }

  return (
    <Card
      background={background}
      minwidth={isSmallDevice ? '100%' : '12rem'}
      $maxWidth={isSmallDevice ? '100%' : '12rem'}
    >
      <Box pad="1rem 1rem" align="center" position="relative">
        <Typography textTransform="uppercase" align="center" color={color}>
          {label}
        </Typography>
        <Typography fontSize="3rem" fontWeight="bold" color="white.main">
          {value}
        </Typography>
        {link && <ArrowFab onClick={handleArrowClick} absolute right=".75rem" bottom=".75rem" size="35px" />}
      </Box>
    </Card>
  )
}

DashboardCard.defaultProps = {
  link: null,
  onLinkClick: null,
  navigateOptions: {},
  background: 'primary',
  color: 'white.main',
}

DashboardCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string,
  onLinkClick: PropTypes.func,
  navigateOptions: PropTypes.object,
  background: PropTypes.string,
  color: PropTypes.string,
}

export default DashboardCard
