import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Box, Margin } from '@Common//Components/Styles'
import { HousingUnitDetailType } from '@Buildings/Models/HousingUnit'
import * as R from 'ramda'

const HousingUnitGeneralData = ({ unit }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h6" color="primary" mb="1rem">
        {t('buildings:ui.GeneralData')}
      </Typography>
      <Box direction="row">
        <Box margin='0 3rem 0 0'>
          <Margin bottom=".8rem">
            <strong>{t('buildings:ui.Building')}</strong>: {R.defaultTo('--', unit.immobile.denominazione)}
          </Margin>
          <Margin bottom=".8rem">
            <strong>{t('buildings:ui.Edifice')}</strong>: {R.defaultTo('--', unit.edificio)}
          </Margin>
        </Box>
        <Box>
          <Margin bottom=".8rem">
            <strong>{t('buildings:ui.Stair')}</strong>: {R.defaultTo('--', unit.scala)}
          </Margin>
          <Margin bottom=".8rem">
            <strong>{t('buildings:ui.Floor')}</strong>: {R.defaultTo('--', unit.piano)}
          </Margin>
        </Box>
      </Box>
    </>
  )
}

HousingUnitGeneralData.propTypes = {
  unit: HousingUnitDetailType,
}

export default HousingUnitGeneralData
