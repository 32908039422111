import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
  filters: {
    deadlineGte: null,
    deadlineLte: null,
    createdGte: null,
    createdLte: null,
    category: '',
    priority: '',
    status: '',
    branch: '',
    building: '',
    resident: '',
    assignedTo: '',
  },
  quickFilters: {
    showCompleted: false,
    onlyMainTasks: false,
    onlyMyTasks: false,
  },
  search: '',
  sorting: '-created',
}

const slice = createSlice({
  name: 'tms',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    },
    setQuickFilters: (state, { payload }) => {
      state.quickFilters = { ...state.quickFilters, ...payload }
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    },
    setAllFilters: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload.filters }
      state.quickFilters = { ...state.quickFilters, ...payload.quickFilters }
      state.search = payload.search
    },
    setSorting: (state, { payload }) => {
      state.sorting = payload
    },
    resetFilters: (state) => {
      state.filters = initialState.filters
      state.quickFilters = initialState.quickFilters
      state.search = initialState.search
    },
  },
})

export const { setFilters, setQuickFilters, setSearch, setSorting, setAllFilters, resetFilters } = slice.actions

export default slice.reducer

export const selectFilters = (state) => state.tms.filters

export const selectQuickFilters = (state) => state.tms.quickFilters

export const selectSearch = (state) => state.tms.search

export const selectSorting = (state) => state.tms.sorting

export const selectAllFilters = createSelector(
  [selectFilters, selectQuickFilters],
  (filters, quickFilters) => ({ filters, quickFilters }),
)

export const selectAllFiltersFlattened = createSelector(
  [selectFilters, selectQuickFilters],
  (filters, quickFilters) => ({ ...filters, ...quickFilters }),
)
