import ApartmentIcon from '@mui/icons-material/Apartment'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ForumIcon from '@mui/icons-material/Forum'
import GasMeterIcon from '@mui/icons-material/GasMeter'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import PaidIcon from '@mui/icons-material/Paid'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { MenuList, Paper, useMediaQuery } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import TmsMyTasksIcon from '@Assets/Icons/TmsMyTasksIcon'
import TmsTaskCategoryIcon from '@Assets/Icons/TmsTaskCategoryIcon'
import { BranchBuildingPermissions } from '@Buildings/Permissions'
import { isBranchEmployee, logsToOperationalDashboard } from '@Common/Utils/Permissions'
import { InsurancePolicyPermissions, PodPdrPermissions, ReconciliationReminderPermissions } from '@Condo/Permissions'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { CustomerCarePermissions } from '@CustomerCare/Permissions'
import { TaskCategoryPermissions, TaskFlowPermissions, TaskPermissions } from '@Tms/Permissions'

import config from '../../Config'
import { selectOperationalSidebar, toggleOperationalSidebar } from '../../Core/Redux/Ui'
import { useCurrentUser } from '../Utils/Hooks'
import { Nav as BaseNav, Item, Link, Separator } from './Navigation/Styled'

const Fixed = styled.div`
  display: contents;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  width: ${({ width }) => width};
  transition: 0.2s width linear;
`

const Nav = styled(BaseNav)`
  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  padding: 1rem 0;
  transition: 0.2s all linear;
`

export const PopoverMenu = styled(Paper)`
  position: absolute !important;
  top: 0;
  z-index: 100;
  ${(props) => (props.right ? 'right: 100%' : 'left: 100%')};
`

export const PopoverIcon = styled.div`
  margin-right: 0.5rem;

  svg {
    margin: 0;
    min-width: 20px !important;
    width: 20px !important;
  }
`

const items = [
  {
    path: config.urls.home,
    label: 'common:navigation.Dashboard',
    perm: () => true,
    Icon: DashboardIcon,
    exact: true,
  },
  {
    path: makePath('buildings.list'),
    label: 'common:navigation.Buildings',
    perm: BranchBuildingPermissions.read,
    Icon: ApartmentIcon,
    matchPattern: '/buildings/*',
  },
  {
      path: makePath('podpdrs.list'),
      label: 'common:navigation.PodPdrManagement',
      perm: PodPdrPermissions.read,
      Icon: GasMeterIcon,
      matchPattern: '/podpdrs/*',
    },
    {
      path: makePath('insurancePolicy.list'),
      label: 'common:navigation.InsurancePolicyManagement',
      perm: InsurancePolicyPermissions.read,
      Icon: HealthAndSafetyIcon,
      matchPattern: '/insurance-policy/*',
    },
    {
      path: makePath('reconciliationReminders.list'),
      label: 'common:navigation.ReconciliationReminderManagementShortLabel',
      perm: ReconciliationReminderPermissions.read,
      Icon: PaidIcon,
      matchPattern: '/reconciliation-reminders/*',
    },
    {
      path: makePath('supplier.list'),
      label: 'common:navigation.SupplierCompanyLabel',
      perm: BranchBuildingPermissions.read,
      Icon: EngineeringIcon,
      matchPattern: '/supplier/suppliers/*',
    },
  {
    path: makePath('customerCare.main'),
    label: 'common:navigation.CustomerCare',
    perm: R.both(CustomerCarePermissions.read, isBranchEmployee),
    Icon: SupportAgentIcon,
    matchPattern: '/customer/care/*',
  },
  {
    path: makePath('tms.root'),
    label: 'common:navigation.Tms',
    perm: R.both(
      R.anyPass([TaskCategoryPermissions.read, TaskFlowPermissions.read, TaskPermissions.read]),
      logsToOperationalDashboard,
    ),
    Icon: ForumIcon,
    isActive: (pathname) => pathname.includes(makePath('tms.root')),
    children: [
      {
        path: makePath('tms.taskList'),
        label: 'common:navigation.TmsTasks',
        perm: TaskPermissions.read,
        Icon: TmsMyTasksIcon,
      },
      {
        path: makePath('tms.categories'),
        label: 'common:navigation.TmsCategories',
        perm: R.anyPass([TaskCategoryPermissions.create, TaskCategoryPermissions.update, TaskCategoryPermissions.delete]),
        Icon: TmsTaskCategoryIcon,
      },
    ],
  },
]

const OperationalSidebar = ({ fill, onItemClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const user = useCurrentUser()
  const pathname = useSelector(R.path(['router', 'location', 'pathname']))
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [hoverMenu] = React.useState({})
  const status = useSelector(selectOperationalSidebar)

  const ToggleIcon = status.expanded ? ArrowBackIosNewIcon : ArrowForwardIosIcon
  const toggleStatus = () => dispatch(toggleOperationalSidebar())

  const navigateTo = (path) => () => {
    onItemClick && onItemClick()
    history.push(path)
  }

  return (
    <Nav
      $fill={fill}
      background="sidebar"
      width={status.expanded ? status.expandedWidth : status.collapsedWidth}
      mobile={isSmallDevice}
    >
      <Fixed width={status.expanded ? status.expandedWidth : status.collapsedWidth}>
        <Item direction="row" expanded={status.expanded} style={{ marginTop: 0 }}>
          <ToggleIcon onClick={toggleStatus} style={{ fontSize: '30px' }} />
        </Item>
        <Separator />
        {items.map(({ path, label, perm, Icon, exact, children, isActive }, idx) => {
          const active = exact ? pathname === path : pathname.includes(path)
          const hasActive = isActive && isActive(pathname)
          return perm(user) ? (
            <Fragment key={idx}>
              <Item
                direction="row"
                align="center"
                justify="flex-start"
                expanded={status.expanded}
                active={!children?.length && active}
                hasActive={hasActive}
                astyle={idx >= items.length - 1 ? { bottom: 57, position: 'fixed', width: 'inherit' } : {}}
              >
                <Tooltip title={status.expanded ? '' : t(label)} placement="right">
                  <Icon style={{ fontSize: '30px' }} onClick={navigateTo(path)} />
                </Tooltip>
                {status.expanded && (
                  <Link onClick={navigateTo(path)} margin="0 0 0 .5rem">
                    {t(label)}
                  </Link>
                )}
                {children?.length && hoverMenu[path]?.isOpen && !isActive(pathname) && (
                  <PopoverMenu elevation={3}>
                    <MenuList>
                      {children
                        .filter((child) => child.perm(user))
                        .map(({ path, Icon, label }, idx) => (
                          <MenuItem onClick={navigateTo(path)} dense key={idx}>
                            <PopoverIcon>
                              <Icon onClick={navigateTo(path)} />
                            </PopoverIcon>{' '}
                            {t(label)}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </PopoverMenu>
                )}
              </Item>
              {children?.length &&
                isActive(pathname) &&
                children
                  .filter((child) => child.perm(user))
                  .map(({ path, Icon, label, exact }, idx) => {
                    const active = exact ? pathname === path : pathname.includes(path)
                    return (
                      <Tooltip title={status.expanded ? '' : t(label)} placement="right" key={idx}>
                        <Item
                          sub
                          active={active}
                          hasActive={hasActive}
                          direction="row"
                          align="center"
                          justify="flex-start"
                          onClick={navigateTo(path)}
                        >
                          <Icon style={{ fontSize: '24px' }} />
                          {status.expanded && (
                            <Link sub onClick={navigateTo(path)} margin="0 0 0 .5rem">
                              {t(label)}
                            </Link>
                          )}
                        </Item>
                      </Tooltip>
                    )
                  })}
            </Fragment>
          ) : null
        })}
      </Fixed>
    </Nav>
  )
}

OperationalSidebar.defaultProps = {
  fill: false,
}

OperationalSidebar.propTypes = {
  fill: PropTypes.bool,
  onItemClick: PropTypes.func,
}

export default OperationalSidebar
