import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from '@Common/Components/Modal'
import { NotificationType } from '@Notifications/Models/Notification'
import dayjs from 'dayjs'
import * as R from 'ramda'


const NotificationModal = ({ notification, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={notification.title}
      width="medium"
      onClose={onClose}
      cancelLabel={t('common:actions.Close')}
    >
      <p><strong>{t('notifications:fields.date')}</strong>: {dayjs(notification.date).format('LL')}</p>
      <p><strong>{t('notifications:fields.topic')}</strong>: {notification.topicObj.name}</p>
      <p><strong>{t('notifications:fields.sender')}</strong>: {R.defaultTo(t('notifications:ui.System'), notification.senderObj?.username)}</p>
      <p><strong>{t('notifications:fields.recipient')}</strong>: {notification.recipientObj?.username}</p>
      <p><strong>{t('notifications:fields.link')}</strong>: {R.defaultTo('--', notification.link)}</p>
      <p><strong>{t('notifications:fields.linkText')}</strong>: {R.defaultTo('--', notification.linkText)}</p>
      <div dangerouslySetInnerHTML={{ __html: notification.text }} />
    </Modal>
  )
}

NotificationModal.propTypes = {
  notification: NotificationType,
  onClose: PropTypes.func.isRequired,
}

export default NotificationModal
