import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, FormControl } from '@mui/material'
import TextField from '../../Common/Components/TextField'
import { setStateFromEvent } from '../../Common/Utils/Events'
import styled from 'styled-components'
import { useForm } from '../../Common/Utils/Hooks'

const Section = styled.section``
const SubmitRow = styled.div`
  margin-top: 1rem;
  text-align: center;
`

const SearchAnagraphicForm = ({ onSubmit }) => {
  const { t } = useTranslation()
  const { fields, setField } = useForm(
    {
      name: '',
      phone: '',
      email: '',
    },
    true,
  )

  const handleKeyDown = (evt) => {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      submit()
    }
  }

  const submit = () => onSubmit(fields)

  return (
    <Section onKeyDown={handleKeyDown}>
      <FormControl fullWidth>
        <TextField
          label={t('customerCare:fields.firstName')}
          value={fields.name}
          onChange={setStateFromEvent(setField('name'))}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t('customerCare:fields.phone')}
          value={fields.phone}
          onChange={setStateFromEvent(setField('phone'))}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t('customerCare:fields.email')}
          value={fields.email}
          onChange={setStateFromEvent(setField('email'))}
        />
      </FormControl>
      <SubmitRow>
        <Button variant="outlined" size="small" onClick={submit}>
          {t('common:actions.Search')}
        </Button>
      </SubmitRow>
    </Section>
  )
}

SearchAnagraphicForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SearchAnagraphicForm
