/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next'

import { ChipStatusItem } from '@Common/Components/ChipStatusItem'
import { SupplierCompany } from '@Supplier/Models/SupplierCompany'

export const ChipStatusSupplierCompany = ({ value }) => {
  const { t } = useTranslation()

  let typeColor = 'primary.dark'

  switch (value) {
    case 'DV':
      typeColor = 'warning'
      break
    case 'NC':
      typeColor = 'error'
      break
    case 'CT':
      typeColor = 'info'
      break
    case 'C':
      typeColor = 'success'
      break
    case 'NI':
      typeColor = 'error'
      break
  }

  const label = t(`${SupplierCompany.name.toLowerCase()}:model.fields.status_choices.${value}`)

  return <ChipStatusItem color={typeColor} text={label} />
}
