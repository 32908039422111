import PropTypes from 'prop-types'
import React from 'react'

import Dropdown from '@Common/Components/Dropdown'
import SelectField from '@Common/Components/Forms/SelectField'
import { FieldType } from '@Common/Types/ReactHookFormField'
import { PRIORITIES } from '@Tms/Utils'

const PrioritySelect = ({ name, control, notRHF, ...other }) => {
  return (
    <>
      {!notRHF && <SelectField name={name} control={control} width="100%" options={PRIORITIES} {...other} />}
      {notRHF && (
        <Dropdown
          options={PRIORITIES}
          onChange={other.onChange}
          width="100%"
          value={PRIORITIES.length ? other.value : ''}
          {...other}
        />
      )}
    </>
  )
}

PrioritySelect.defailtProps = { notRHF: false }

PrioritySelect.propTypes = {
  ...FieldType,
  notRHF: PropTypes.bool,
}

export default PrioritySelect
