import BlockIcon from '@mui/icons-material/Block'
import ErrorIcon from '@mui/icons-material/Error'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { Box, Chip } from '@mui/material'
import PropTypes from 'prop-types'

import {
  InsuranceStatusChiusa,
  InsuranceStatusInCorso,
  InsuranceStatusInScadenza,
  InsuranceStatusScaduta,
} from '@Condo/Models/InsurancePolicy'

const ChipInsurancePolicyStatus = ({ status }) => {
  let result = null

  switch (status) {
    case InsuranceStatusChiusa.id:
      result = <Chip label={InsuranceStatusChiusa.label} color="error" size="small" icon={<BlockIcon />} />
      break
    case InsuranceStatusScaduta.id:
      result = <Chip label={InsuranceStatusScaduta.label} color="warning" size="small" icon={<ErrorIcon />} />
      break
    case InsuranceStatusInScadenza.id:
      result = <Chip label={InsuranceStatusInScadenza.label} color="secondary" size="small" icon={<ScheduleIcon />} />
      break
    case InsuranceStatusInCorso.id:
      result = <Chip label={InsuranceStatusInCorso.label} color="primary" size="small" icon={<RotateLeftIcon />} />
      break
    default:
      result = '-'
      break
  }

  return <Box>{result}</Box>
}

ChipInsurancePolicyStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

export default ChipInsurancePolicyStatus
