import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { groupBy, pipe, pluck, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useForm } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import { useServiceTypesQuery } from '@Supplier/Services/Api'

const groupByServiceActivity = pipe(pluck('metadata'), groupBy(prop('description')))

export const SupplierServiceFilter = ({ onFilter }) => {
  const { t } = useTranslation()
  const [allServicesByActivity, setAllServicesByActivity] = useState(null)

  const { data } = useServiceTypesQuery({
    limit: -1,
    fields: 'id,metadata',
  })

  const [fields, setFields] = useForm({
    services: [],
    servicesActivities: [],
    selectedServicesActivity: null,
    selectedServices: [],
  })

  const handleChangeServiceActivity = (servicesActivity) => {
    if (!servicesActivity) {
      setFields({
        ...fields,
        selectedServicesActivity: null,
        services: [],
        selectedServices: [],
      })
    } else {
      const services = allServicesByActivity[servicesActivity]
      setFields({
        ...fields,
        selectedServicesActivity: servicesActivity,
        services: services.map((x) => ({ value: x.id, label: x.name, description: x.description })),
        selectedServices: [],
      })
    }

    onFilter({ servicesActivity, services: null })
  }

  const handleChangeServices = (services) => {
    setFields({
      ...fields,
      selectedServices: services,
    })

    onFilter({ servicesActivity: fields.selectedServicesActivity, services: services.length > 0 ? services : null })
  }

  useEffect(() => {
    logger.debug('SupplierServiceFilter changed results', data?.results)
    if (data?.results) {
      const servicesByActivity = groupByServiceActivity(data?.results)
      setAllServicesByActivity(servicesByActivity)
      setFields({
        ...fields,
        servicesActivities: Object.keys(servicesByActivity).map((x) => ({ value: x, label: x })) || [],
      })
    }
  }, [data?.results])

  return (
    <Stack direction="row" spacing={2}>
      <Dropdown
        emptyValue={null}
        value={fields.selectedServicesActivity}
        label={t('tms:fields.suppliers.servicesActivity')}
        options={fields.servicesActivities}
        onChange={setStateFromEvent(handleChangeServiceActivity)}
        searchable
      />
      <Dropdown
        label={t('tms:fields.suppliers.services')}
        options={fields.services}
        value={fields.selectedServices}
        disabled={!fields.selectedServicesActivity}
        onChange={setStateFromEvent(handleChangeServices)}
        helperText={!fields.selectedServicesActivity ? t('tms:fields.suppliers.selectServiceActivityFirst') : null}
        searchable
        multiple
      />
    </Stack>
  )
}

SupplierServiceFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
}
