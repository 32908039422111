import { ForwardToInbox } from '@mui/icons-material'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BuildingAnagraphic from '@Auth/Models/BuildingAnagraphic'
import { useBuildingAnagraphicsQuery } from '@Buildings/Services/Api'
import { getAllEmailsFromContacts } from '@Buildings/Utils'
import DataTable from '@Common/Components/DataTable'

const DISPLAY_COLUMNS = [
  'classe.displayValue',
  'tipo.displayValue',
  'titolo',
  'nominativo',
  'cognome',
  'nome',
  'ragioneSociale',
  'partitaIva',
  'codiceFiscale',
  'email',
]

const SEARCH_FIELDS = [
  'classe.displayValue',
  'tipo.displayValue',
  'titolo',
  'nominativo',
  'cognome',
  'nome',
  'ragioneSociale',
  'partitaIva',
  'codiceFiscale',
]

const FIELDS_MAPPINGS = {
  email: R.ifElse(
    R.prop('contatti'),
    R.compose(getAllEmailsFromContacts, R.prop('contatti')),
    R.compose(R.join(' - '), R.defaultTo([]), R.prop('email')),
  ),
}

const BuildingAnagraphicsList = ({ arcadiaId, onCreateInvitation, onCreateBulkInvitation }) => {
  const { t } = useTranslation()
  const { data, isFetching } = useBuildingAnagraphicsQuery(arcadiaId)
  const [selected, setSelected] = React.useState([])
  const [anagraphics, setAnagraphics] = useState([])
  let flatAnagraphicsIds = []
  let flatAnagraphics = []

  const addUniqueAnagraphic = (anagraphic) => {
    if (!flatAnagraphicsIds.includes(anagraphic.id)) {
      flatAnagraphicsIds.push(anagraphic.id)
      flatAnagraphics.push(anagraphic)
    }
  }

  // Extract "GruppoAnagrafica" components and remove duplicates
  const handleSetAnagraphics = () => {
    flatAnagraphicsIds = []
    flatAnagraphics = []
    if (data && data.length > 0) {
      data.forEach((anagraphic) => {
        if (anagraphic.classe.id === 64) {
          anagraphic.componentiGruppo.forEach(addUniqueAnagraphic)
        } else {
          addUniqueAnagraphic(anagraphic)
        }
      })
      setAnagraphics(flatAnagraphics)
    }
  }

  useEffect(handleSetAnagraphics, [data])

  const actions = React.useMemo(
    () => [
      {
        id: 'INVITE',
        label: t('auth:actions.Invite'),
        icon: <ForwardToInbox size="small" />,
      },
    ],
    [],
  )

  const bulkActions = React.useMemo(
    () => [
      {
        id: 'BULK_INVITE',
        label: t('auth:actions.InviteSelected'),
        icon: <ForwardToInbox size="small" />,
      },
    ],
    [],
  )

  const handleAction = React.useCallback(
    (actionId, anagraphic) => {
      switch (actionId) {
        case 'INVITE':
          onCreateInvitation(anagraphic)
          break
        case 'BULK_INVITE':
          onCreateBulkInvitation(selected)
          break
      }
    },
    [selected],
  )

  const checkSelectable = R.propEq('gruppoEsterno', null)

  return (
    <div>
      <DataTable
        name="admin-building-anagraphics"
        selectable={checkSelectable}
        data={anagraphics}
        model={BuildingAnagraphic}
        listDisplay={DISPLAY_COLUMNS}
        searchFields={SEARCH_FIELDS}
        fieldsMapping={FIELDS_MAPPINGS}
        sortField="id"
        sortDirection="desc"
        loading={isFetching}
        actions={actions}
        selected={selected}
        onSelect={setSelected}
        bulkActions={bulkActions}
        onAction={handleAction}
        noExport
      />
    </div>
  )
}

BuildingAnagraphicsList.propTypes = {
  arcadiaId: PropTypes.string,
  onCreateInvitation: PropTypes.func.isRequired,
  onCreateBulkInvitation: PropTypes.func.isRequired,
}

export default BuildingAnagraphicsList
