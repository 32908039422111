import { FormControl, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import Loader from '@Common/Components/Loader'
import Modal from '@Common/Components/Modal'
import { Box } from '@Common/Components/Styles'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { convertServerErrors } from '@Common/Utils/Form'
import { useForm } from '@Common/Utils/Hooks'
import CondosSelectField from '@Condo/Components/CondosSelectField'
import { PodPdrType, PodSupplyTypes } from '@Condo/Models/PodPdr'

import { validate } from './Validation'

const PodPdrForm = ({ podpdr, onClose, onSave, showSelectBuilding = true }) => {
  const { t } = useTranslation()

  const name = fields?.podNumber || podpdr?.pdrNumber || ''
  const title = podpdr?.id ? t('condo:ui.EditPodPdr', { name }) : t('condo:ui.CreatePodPdr')

  const [isSubmitting, setIsSubmitting] = useState(false)

  // hooks
  const { fields, setField, errors, setErrors, clearError } = useForm(
    {
      ...podpdr,
    },
    true,
  )

  // handle submit form
  const submit = async () => {
    const isValid = validate(fields, setErrors)

    if (!isValid) return

    setIsSubmitting(true)

    const payload = buildPayload(fields)
    const { error, isSuccessful } = await onSave(payload)

    setIsSubmitting(false)

    if (!isSuccessful) {
      return setErrors(convertServerErrors(error))
    }

    return onClose()
  }

  // transform fields for API
  const buildPayload = (fields) => {
    const payload = {
      ...fields,
    }

    return payload
  }

  // const [setSelectedRole] = React.useState()

  return (
    <Modal title={title} size="lg" onClose={onClose} onSubmit={submit} sx={{ paddingTop: 0 }}>
      {isSubmitting && <Loader overlay />}
      <Box>
        <Grid container rowSpacing={3} spacing={2}>
          {showSelectBuilding && (
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <CondosSelectField
                  value={fields.building}
                  label={t('condo:ui.Buildings')}
                  onChange={setStateFromEvent(setField('building'))}
                  error={!!errors.building}
                  helperText={errors.building}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                value={fields?.stairN}
                label={t('condo:podpdr.fields.stairN')}
                onChange={setStateFromEvent(setField('stairN'))}
                onBlur={() => clearError('stairN')}
                onInput={() => clearError('stairN')}
                error={!!errors.stairN}
                helperText={errors.stairN}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                value={fields?.podNumber}
                label={t('condo:podpdr.fields.podNumber')}
                onChange={setStateFromEvent(setField('podNumber'))}
                onBlur={() => clearError('podNumber')}
                onInput={() => clearError('podNumber')}
                error={!!errors.podNumber}
                helperText={errors.podNumber}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Dropdown
                options={PodSupplyTypes}
                labelKey="label"
                valueKey="id"
                onChange={setStateFromEvent(setField('podSupplyType'))}
                onBlur={() => clearError('podSupplyType')}
                onInput={() => clearError('podSupplyType')}
                error={!!errors.podSupplyType}
                helperText={errors.podSupplyType}
                value={fields.podSupplyType}
                label={t('condo:podpdr.fields.podSupplyType')}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                value={fields?.podSupplier}
                label={t('condo:podpdr.fields.podSupplier')}
                onChange={setStateFromEvent(setField('podSupplier'))}
                onBlur={() => clearError('podSupplier')}
                onInput={() => clearError('podSupplier')}
                error={!!errors.podSupplier}
                helperText={errors.podSupplier}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                value={fields?.pdrNumber}
                label={t('condo:podpdr.fields.pdrNumber')}
                onChange={setStateFromEvent(setField('pdrNumber'))}
                onBlur={() => clearError('pdrNumber')}
                onInput={() => clearError('pdrNumber')}
                error={!!errors.pdrNumber}
                helperText={errors.pdrNumber}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                value={fields?.pdrSupplier}
                label={t('condo:podpdr.fields.pdrSupplier')}
                onChange={setStateFromEvent(setField('pdrSupplier'))}
                onBlur={() => clearError('pdrSupplier')}
                onInput={() => clearError('pdrSupplier')}
                error={!!errors.pdrSupplier}
                helperText={errors.pdrSupplier}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                rows={5}
                value={fields?.notes}
                label={t('condo:podpdr.fields.notes')}
                onChange={setStateFromEvent(setField('notes'))}
                onBlur={() => clearError('notes')}
                onInput={() => clearError('notes')}
                error={!!errors.notes}
                helperText={errors.notes}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

PodPdrForm.propTypes = {
  podpdr: PodPdrType.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showSelectBuilding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
}

export default PodPdrForm
