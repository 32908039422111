import i18next from 'i18next'
import { stripHtml } from 'string-strip-html'
import * as yup from 'yup'

export const requiredMessage = i18next.t('common:validation.RequiredField')

export const validationSchema = yup.object({
  name: yup.string().required(requiredMessage),
  description: yup.string().test(
    'editor-content-required',
    () => requiredMessage,
    (v) => v && stripHtml(v).result.length > 0,
  ),
  assingTo: yup.string(),
  role: yup
    .number()
    .nullable()
    .when('assignTo', {
      is: 'role',
      then: (schema) => schema.required(requiredMessage),
    }),
  minutesToDeadline: yup.mixed().required(requiredMessage),
  deadlineCondition: yup.number().required(requiredMessage)/* .oneOf([0, 1, 2, 5]) */,
  deadlineTask: yup
    .string()
    .nullable()
    .when('deadlineCondition', {
      is: (deadlineCondition) => [0, 1, 2].includes(deadlineCondition),
      then: (schema) => schema.required(requiredMessage),
    }),
  deadlineField: yup
    .string()
    .nullable()
    .when('deadlineCondition', {
      is: 2,
      then: (schema) => schema.required(requiredMessage),
    }),
  children: yup.lazy(() => yup.array().of(validationSchema)),
})
