/* eslint-disable react/prop-types */
import { Autocomplete, Chip, Grid, TextField as MuiTextField } from '@mui/material'
import { Controller } from 'react-hook-form'

import { useMuiGrid } from '@Common/Utils/Hooks'

import TextField from '../Forms/TextField'
import { useDefaultFieldProps } from './FormUtilsV2'

export const TextFieldV2 = (props) => {
  const gridProps = useMuiGrid(props)
  const defaultProps = useDefaultFieldProps(props.name, props)

  if (props.multiple) return <TextFieldMultipleV2 {...props} />

  return (
    <Grid item {...gridProps}>
      <TextField
        {...defaultProps}
        {...props}
        inputProps={{
          ...props.inputProps,
          required: props?.required || defaultProps.required || false,
          ...defaultProps.inputProps,
        }}
      />
    </Grid>
  )
}

/**
 * This component allows us to use text inputs
 * that manage arrays across material Chip
 */
export const TextFieldMultipleV2 = (props) => {
  const gridProps = useMuiGrid(props)
  const defaultProps = useDefaultFieldProps(props.name, props)

  return (
    <Grid item {...gridProps}>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              multiple
              value={value || []}
              options={[]}
              freeSolo
              onChange={(_, tags) => onChange(tags)}
              clearOnBlur
              autoSelect
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip key={index} label={option} size="small" {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => {
                return <MuiTextField {...defaultProps} {...params} variant="standard" />
              }}
            />
          )
        }}
      />
    </Grid>
  )
}
