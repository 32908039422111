import { createSelector, createSlice } from '@reduxjs/toolkit'
import { defaultTo, find, path, propEq } from 'ramda'

const slice = createSlice({
  name: 'myEstia',
  initialState: { activeHousingUnitId: null, notificationsPanel: { isOpen: false } },
  reducers: {
    setActiveHousingUnitId: (state, { payload }) => {
      state.activeHousingUnitId = payload
    },
    toggleNotificationsPanel: (state) => {
      state.notificationsPanel.isOpen = !state.notificationsPanel.isOpen
    },
    closeNotificationsPanel: (state) => {
      state.notificationsPanel.isOpen = false
    }
  },
})

export const { setActiveHousingUnitId, toggleNotificationsPanel, closeNotificationsPanel } = slice.actions

export default slice.reducer

const _selectHousingUnits = (state) => state.auth.login.user.resident.raw.unitaImmobiliari ?? []
export const selectActiveHousingUnitId = (state) => state.myEstia.activeHousingUnitId
export const selectBuildings = (state) => state.auth.login.user.resident.raw.immobili ?? []
export const selectNotificationsPanel = path(['myEstia', 'notificationsPanel'])

export const selectHousingUnits = createSelector([_selectHousingUnits, selectBuildings], (housingUnits, buildings) =>
  housingUnits.map((housingUnit) => ({
    ...housingUnit,
    immobile: find(propEq('id', housingUnit.immobileId), buildings),
  })),
)

export const selectActiveHousingUnit = createSelector(
  [selectActiveHousingUnitId, selectHousingUnits],
  (activeHousingUnitId, housingUnits) => find(propEq('id', activeHousingUnitId), housingUnits),
)

export const selectActiveBuilding = createSelector(selectActiveHousingUnit, (activeHousingUnit) =>
  defaultTo({}, activeHousingUnit?.immobile),
)

export const selectActiveBuildingId = createSelector(selectActiveBuilding, (activeBuilding) =>
  defaultTo(null, activeBuilding?.id),
)
