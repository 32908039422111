import { ArrowCircleRight } from '@mui/icons-material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import i18next from 'i18next';
import * as R from 'ramda';
import { Popup } from 'react-leaflet';
import styled from 'styled-components';



import { makePath } from '@Config';



import { getBuildingAddress } from '../../../Buildings/Utils';
import { history } from '../../../Core/Redux/Store';
import { Box, Cursor } from '../Styles';
import Marker from './Marker';


const PopupTitle = styled.div`
  font-weight: 700;
  margin-bottom: 0rem;
  font-size: 1rem;
  margin-bottom: .5rem;
`
const PopupAddress = styled.div`
  font-size: .8rem;
  margin-bottom: 1rem;
`
const PopupContent = styled.div``


export const createBranchMarker = (onClick) => (branch) => { // eslint-disable-line
  const latLng = R.props(['lat', 'lng'], branch)
  return (
    <Marker
      key={branch.id}
      position={latLng} 
      title={branch.name}
      icon={<PersonPinCircleIcon color="primary" style={{ fontSize: '40px' }} />}
      iconSize={[23, 33]}
      iconAnchor={[12, 33]}
      popupAnchor={[8, -40]}
      eventHandlers={{
        click: () => {
          onClick(branch)
        },
      }}
    >
      <Popup>
        <PopupTitle>{branch.name}</PopupTitle>
        <PopupContent>
          <Chip label={i18next.t('branches:ui.BuildingsNumberChip', {count: branch.totBuildings})} />
        </PopupContent>
      </Popup>
    </Marker>
  )
}

export const createBuildingMarker = (building) => { // eslint-disable-line
  if (!building.extra?.lat) return null

  const latLng = R.props(['lat', 'lng'], building.extra)
  const buildingPath = makePath('buildings.detail.main', { buildingId: building?.extra?.localBuildingId })

  return (
    <Marker
      key={building.id}
      position={latLng} 
      icon={<ApartmentIcon color="primary" style={{ fontSize: '40px' }} />}
      iconSize={[30, 30]}
      iconAnchor={[15, 30]}
      popupAnchor={[12, -40]}
    >
      <Popup>
        <PopupTitle>{building.denominazione}</PopupTitle>
        <PopupAddress>{getBuildingAddress(building)}</PopupAddress>
        <PopupContent>
          <Box direction='row' align='center' justify='space-between'>
            <Chip label={i18next.t('buildings:ui.HousingUnitNumberChip', {value: building.nrUi})} />
            <Cursor type='pointer'> 
              <Tooltip title={i18next.t('common:ui.ViewBuildingDetail')} placement='right'>
                <ArrowCircleRight style= {{ fontSize: '30px' }} onClick={() => history.push(buildingPath)} color='primary' />
              </Tooltip>
            </Cursor>
          </Box>
        </PopupContent>
      </Popup>
    </Marker>
  )
}