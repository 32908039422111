import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import AddFab from '@Common/Components/AddFab'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import CondosList from '@Condo/Components/CondosList'
import CondoForm from '@Condo/Forms/CondoForm/CondoForm'
import { CondosPermissions } from '@Condo/Permissions'
import { useCreateCondoMutation, useDeleteCondoMutation, useUpdateCondoMutation } from '@Condo/Services/Api'
import config from '@Config'

const CondosView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()

  // api
  const [createCondo] = useCreateCondoMutation()
  const [updateCondo] = useUpdateCondoMutation()
  const [deleteCondo] = useDeleteCondoMutation()

  // form
  const [form, setForm] = useState({ isOpen: false, condo: null, isEditing: false })

  useBreadcrumbs([
    {
      label: t('common:navigation.BuildingsManagement'),
      path: config.urls.admin.buildings,
      Icon: 'BuildingManagement',
    },
  ])

  const handleOpenCreateForm = () => setForm({ isOpen: true, condo: {}, isEditing: false })
  const handleOpenEditForm = (condo) => setForm({ isOpen: true, condo, isEditing: true })
  const handleCloseForm = () => setForm({ isOpen: false, condo: null, isEditing: false })

  const handleSave = (fields) => {
    return form.isEditing ? handleUpdateCondo(fields) : handleCreateCondo(fields)
  }

  const handleCreateCondo = async (fields) => {
    try {
      const response = await createCondo(fields).unwrap()
      toast.success(
        t('condo:success.CondoCreated', {
          name: form.condo.description,
        }),
      )
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.CreateCondoError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateCondo = async (fields) => {
    try {
      const response = await updateCondo(fields).unwrap()
      toast.success(t('condo:success.CondoUpdated', { name: fields.description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.UpdateCondoError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleDelete = async (fields) => {
    try {
      const response = await deleteCondo(fields[0].id).unwrap()
      toast.success(t('condo:success.CondoDeleted', { name: fields[0].description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.DeleteCondoError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  return (
    <>
      <CondosList onEdit={handleOpenEditForm} onDelete={handleDelete} />
      {form.isOpen && <CondoForm condo={form.condo} onSave={handleSave} onClose={handleCloseForm} />}
      {CondosPermissions.create(user) && <AddFab onClick={handleOpenCreateForm} />}
    </>
  )
}

export default CondosView
