import PropTypes from 'prop-types'
import React from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'

import DraggableMarker from './DraggableMarker'

const ChangeView = ({ center, onChangePosition }) => {
  const map = useMap()
  if (center) {
    map.setView(center)
    return <DraggableMarker initialPosition={center} onChangePosition={onChangePosition}></DraggableMarker>
  }
  return null
}

ChangeView.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
  onChangePosition: PropTypes.func.isRequired,
}

const MapField = ({ latLng, onChangePosition }) => {
  return (
    <>
      <MapContainer center={latLng || [45, 7]} zoom={15} scrollWheelZoom style={{ height: '250px', width: '100%' }}>
        <ChangeView center={latLng} onChangePosition={onChangePosition} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </>
  )
}

MapField.propTypes = {
  latLng: PropTypes.arrayOf(PropTypes.number),
  onChangePosition: PropTypes.func.isRequired,
}

export default MapField
