import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { useContext } from 'react'

import { ItemContainerContext } from '@Common/Components/ItemContainer'
import { Field } from '@Common/Components/ItemField'

export const SupplierServiceItemField = () => {
  const { item } = useContext(ItemContainerContext)
  const options = item?.servicesList?.map((x) => x?.metadata || x) || []

  const optionsServiceActivity = options
    .map((x) => {
      return { id: x.description, name: x.description }
    })
    .filter((item, i, a) => a.findIndex((item2) => JSON.stringify(item2) === JSON.stringify(item)) === i)

  const getServices = (serviceActivity) => {
    return options?.filter((x) => {
      if (serviceActivity && x.description === serviceActivity) {
        return x
      }
    })
  }

  return optionsServiceActivity.map((element, index) => {
    return (
      <Grid item xs={12} key={`${element.name}-${index}`}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="div">
                  {element.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field value={getServices(element.name)} type={'foreign_key'} containerType={Stack} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  })
}
