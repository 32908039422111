import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import RegistrationForm from '@Auth/Forms/RegistrationForm'
import { withLoader } from '@Common/Utils/HOC'
import { useInvitationByTokenQuery } from '@Auth/Services/Api'
import RegistrationLayout from '@Common/Layouts/RegistrationLayout'

const Container = styled.div`
  margin: auto auto 2rem;
  max-width: 500px;
  padding: 3rem 1.25rem;
  flex: 1 0 auto;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      padding: 3rem 0;
    }
  `}
`

const RegistrationView = () => {
  const { invitationToken } = useParams()
  const { data: invitation, isFetching } = useInvitationByTokenQuery(invitationToken)

  return (
    <RegistrationLayout>
      <Container>
        {withLoader(<RegistrationForm invitation={invitation} invitationToken={invitationToken} />, isFetching)}
      </Container>
    </RegistrationLayout>
  )
}

export default RegistrationView
