import i18next from 'i18next'
import * as R from 'ramda'
import { pluck } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'

import { getEmployeeInfo } from '@Auth/Utils'
import DataTable from '@Common/Components/DataTable'
import { makePath } from '@Config'
import { StatusBadge } from '@Tms/Components/Styled'
import { RunningTaskType, RunningTreeTask } from '@Tms/Models/RunningTask'
import { flattenTasks, getTaskStatus, PRIORITIES, STATUS_ENUM } from '@Tms/Utils'

const FIELDS_MAPPING = {
  status: (task) => {
    const status = getTaskStatus(task)
    return (
      <StatusBadge
        status={status}
        size="small"
        label={i18next.t(
          `tms:ui.Status${
            status === STATUS_ENUM.COMPLETED
              ? 'Completed'
              : status === STATUS_ENUM.EXPIRED
              ? 'Expired'
              : status === STATUS_ENUM.PROCESSING
              ? 'Processing'
              : 'Open'
          }`,
        )}
      />
    )
  },
  // eslint-disable-next-line
  title: ({ id: taskId, title }) => <Link to={makePath('tms.taskDetail', { taskId })}>{title}</Link>,
  assignedTo: R.pipe(R.prop('assignedToObj'), getEmployeeInfo),
  priority: ({ priority }) => R.pipe(R.find(R.propEq('value', priority)), R.prop('label'))(PRIORITIES),
}

const TaskFlowIndex = ({ task }) => {
  const tasksIndex = flattenTasks(task.taskFlowObj.mainRunningTask)

  return (
    <DataTable
      name="tms-task-index"
      data={tasksIndex}
      model={RunningTreeTask}
      listDisplay={pluck('id', RunningTreeTask.columns)}
      fieldsMapping={FIELDS_MAPPING}
      disableSorting={['assignedTo', 'status']}
      noBulkSelection
      noSettings
      noToolbar
    />
  )
}

TaskFlowIndex.propTypes = {
  task: RunningTaskType,
}

export default TaskFlowIndex
