import PropTypes from 'prop-types'

export const FieldType = {
  name: PropTypes.string,
  control: PropTypes.any,
  rules: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
}
