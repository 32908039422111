import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'

import { useBranchesQuery } from '../Services/Api'

const BranchSelect = ({ selectedUserId, onBranchesFilter, value, extraOptions, showOnlyActive, ...other }) => {
  const { t } = useTranslation()
  const { data } = useBranchesQuery(showOnlyActive ? { active: true } : undefined)
  const [branches, setBranches] = useState([])

  const selectedUserFilter = (b) => b.manager === selectedUserId || b.seniorAdministratives.includes(selectedUserId)

  useEffect(() => {
    if (data && selectedUserId) {
      let filteredBranches = data.results.filter(selectedUserFilter).map(R.pick(['id', 'name']))
      if (extraOptions) {
        filteredBranches = extraOptions.concat(filteredBranches)
      }
      setBranches(filteredBranches)
      filteredBranches.length > 0 && onBranchesFilter(filteredBranches[0])
    } else if (data) {
      setBranches(extraOptions ? extraOptions.concat(data.results) : data.results)
    } else {
      setBranches(extraOptions ?? [])
    }
  }, [data, selectedUserId])

  return (
    <Dropdown
      options={branches}
      labelKey="name"
      valueKey="id"
      label={t('branches:ui.SelectBranch')}
      value={branches.length > 0 ? value : ''}
      {...other}
    />
  )
}

BranchSelect.propTypes = {
  selectedUserId: PropTypes.number,
  onBranchesFilter: PropTypes.func,
  extraOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
    }),
  ),
  showOnlyActive: PropTypes.bool,
  ...FieldType,
}

export default BranchSelect
