import PropTypes from 'prop-types'
import i18next from 'i18next'

import { CityType } from '@Common/Models/Address'
import { EmployeeType } from '@Auth/Models/Employee'

export const BranchType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  activationDate: PropTypes.string,
  manager: PropTypes.number.isRequired,
  managerObj: EmployeeType,
  administrative: PropTypes.number,
  administrativeObj: EmployeeType,
  secondaryAdministrative: PropTypes.number,
  secondaryAdministrativeObj: EmployeeType,
  address: PropTypes.string.isRequired,
  houseNumber: PropTypes.string.isRequired,
  cap: PropTypes.string.isRequired,
  city: PropTypes.number.isRequired,
  cityObj: CityType,
  locality: PropTypes.string,
  internalNumber: PropTypes.string,
  juniorAdministratives: PropTypes.array,
  seniorAdministratives: PropTypes.array,
  juniorAdministrativesObjs: PropTypes.arrayOf(EmployeeType),
  seniorAdministrativesObjs: PropTypes.arrayOf(EmployeeType),
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'id',
      label: i18next.t('branches:fields.id'),
      numeric: true,
      width: '40px',
    },
    {
      id: 'name',
      label: i18next.t('branches:fields.name'),
    },
    {
      id: 'code',
      label: i18next.t('branches:fields.code'),
    },
    {
      id: 'phone',
      label: i18next.t('branches:fields.phone'),
    },
    {
      id: 'email',
      label: i18next.t('branches:fields.email'),
    },
    {
      id: 'managerObj.employee.phone',
      label: i18next.t('branches:fields.managerPhone'),
    },
    {
      id: 'managerObj.summary',
      label: i18next.t('branches:fields.manager'),
    },
    {
      id: 'administrativeObj.summary',
      label: i18next.t('branches:fields.administrative'),
    },
    {
      id: 'secondaryAdministrativeObj.summary',
      label: i18next.t('branches:fields.secondaryAdministrative'),
    },
    {
      id: 'activationDate',
      label: i18next.t('branches:fields.activationDate'),
      date: true,
    },
    {
      id: 'cityObj.name',
      label: i18next.t('branches:fields.city'),
    },
    {
      id: 'cityObj.provinceObj.name',
      label: i18next.t('branches:fields.province'),
    },
    {
      id: 'cityObj.provinceObj.regionObj.name',
      label: i18next.t('branches:fields.region'),
    },
    {
      id: 'address',
      label: i18next.t('branches:fields.address'),
    },
    {
      id: 'houseNumber',
      label: i18next.t('branches:fields.houseNumber'),
      numeric: true,
    },
    {
      id: 'internalNumber',
      label: i18next.t('branches:fields.internalNumber'),
      numeric: true,
    },
    {
      id: 'cap',
      label: i18next.t('branches:fields.cap'),
    },
    {
      id: 'locality',
      label: i18next.t('branches:fields.locality'),
    },
    {
      id: 'seniorAdministrativesObjs.summary',
      label: i18next.t('branches:fields.seniorAdmins'),
    },
    {
      id: 'juniorAdministrativesObjs.summary',
      label: i18next.t('branches:fields.juniorAdmins'),
    },
    {
      id: 'active',
      label: i18next.t('branches:fields.active'),
      boolean: true,
    },
  ],
}
