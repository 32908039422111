/* eslint-disable react/prop-types */
import { DatePicker } from '@mui/lab'
import { Grid, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { Controller, useFormContext } from 'react-hook-form'

import { useMuiGrid } from '@Common/Utils/Hooks'

import { useDefaultFieldProps } from './FormUtilsV2'

export const DatePickerV2 = (props) => {
  const gridProps = useMuiGrid(props)
  const defaultProps = useDefaultFieldProps(props.name, props)
  const form = useFormContext()

  return (
    <Grid item {...gridProps}>
      <Controller
        name={props.name}
        control={form.control}
        render={({ field: { onChange, value } }) => {
          return (
            <DatePicker
              label={defaultProps.label}
              value={value ? dayjs(value) : null}
              onChange={onChange}
              {...props}
              renderInput={(params) => <TextField variant="standard" {...defaultProps} {...params} />}
            />
          )
        }}
      />
    </Grid>
  )
}
