import { createSlice } from '@reduxjs/toolkit'
import storageFactory from '../../Common/Services/Storage'

const Storage = storageFactory()

const slice = createSlice({
  name: 'login',
  initialState: { token: Storage.get('accessToken'), user: null },
  reducers: {
    setCredentials: (state, { payload: { access } }) => {
      state.token = access
      Storage.save('accessToken', access)
    },
    setCurrentUser: (state, { payload: user }) => {
      state.user = user
    },
    logout: (state) => {
      Storage.remove('accessToken')
      Storage.remove('refreshToken')
      state.token = null
      state.user = null
    },
  },
})

export const { setCredentials, setCurrentUser, logout } = slice.actions

export default slice.reducer

export const selectAccessToken = (state) => state.auth.login.token
export const selectCurrentUser = (state) => state.auth.login.user
