import dayjs from 'dayjs'
import i18next from 'i18next'

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'supplier',
      label: i18next.t('documents:fields.supplier'),
      filter: {
        type: 'text',
      },
    },
    {
      id: 'amount',
      label: i18next.t('myHome:fields.Amount'),
      currency: true,
    },
    {
      id: 'documentDate',
      label: i18next.t('documents:fields.documentDate'),
      date: true,
      filter: {
        type: 'dateRange',
        format: 'DD/MM/YYYY',
        serverSide: false, // todo true,
        default: [dayjs().startOf('year'), dayjs()],
      },
    },
    {
      id: 'filesize',
      label: i18next.t('documents:fields.filesize'),
    },
    {
      id: 'id',
      label: i18next.t('common:actions.Download'),
    },
  ],
}
